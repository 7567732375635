<template>
  <div :id="this.uid" class="HJPropertyList">
	  <!-- Nico PropertyList -->
	          <!-- Banner HTML -->
        <div class="container-fluid banner-container lazyload" :data-bg="[graphqlObject.desktopbackground?graphqlObject.desktopbackground.src:'']" :style="'margin-top:0;'">
            <div class="banner-overlay mobile-hidden"></div>
			<div class="property-title mobile-hidden hidden"><h3 style="color:white;">{{citytitle}}</h3></div>
			<div class="property-weather mobile-hidden hidden">
                <h4><img :src="timeIcon" class="weather-icon"> <span class="pl_time">{{pltime}}</span></h4>
                <h4><img :src="tempIcon" class="weather-icon2 mr-2"> <span class="pl_celsius">{{plcelsius}}</span>℃ / <span class="pl_fahrenheit">{{plfahrenheit}}</span>℉</h4>
            </div>
			<!--</template>-->
            <!-- Swiper -->
            <div class="banner-swiper-container banner-swiper-container-desktop mobile-hidden">
                
				<template v-if="graphqlObject.children && graphqlObject.children != ''">
				<div :class="'swiper-wrapper ' + [graphqlObject.children.length == 1 && !hasVideo ? 'disabled':'']">
						<!--<div v-for="(slide, index) in graphqlObject.children" class="swiper-slide" :style="'background-image:url('+[slide.image.src && slide.image.src !=''?slide.image.src:'']+');'">-->
					<template v-for="(slide, index) in graphqlObject.children">
					<!-- <template v-if="slide.image.src && slide.image.src != ''">								 -->
					<template v-if="slide.image.src !== ''">
						<template v-if="slide.templatename === 'hjimage'">
							<template v-if="slide.videoInfo && slide.videoInfo.videoSource != ''">	
								<div  class="swiper-slide lazyload" :data-bg="[slide.image.src && slide.image.src !=''?slide.image.src:'']">					
									<!-- <div :id="'placeHolder'+index" :class="'placeHolder ' + [$jss.property()?'long_video':'']" :data-videoid="slide.videoid.value"></div> -->
									<div class='placeHolder' :data-videoid="slide.videoInfo.videoSource">
										<template v-if="!slide.videoInfo.isThird">
											<video 
												:id="'js-video-play-'+index"  
												:controls="false" 
												loop autoplay muted preload="true" 
												class="pl-video property-banner"
												:poster="slide.image.src"
												playsInline="isiPhoneShowPlaysinline" 
												x5-video-player-type="h5-page"
												webkit-playsinline="isiPhoneShowPlaysinline"
											>
											<!-- playsInline webkit-playsinline ios 小窗播放  -->
											<!-- x5-video-player-type 启用h5内核播放器 -->
												<source :src="slide.videoInfo.videoSource" :type="slide.videoInfo.sourceType" />
											</video>
										</template>
										<template v-else>
											<iframe
												:id="'js-video-play-'+index"
												:src="slide.videoInfo.videoSource"
												class="property-banner iframe pl-video"
												width="100%"
												heght="56vw"
												frameBorder="0"
												allow="accelerometer; autoplay; loop; encrypted-media; gyroscope; picture-in-picture"
												allowFullScreen
											/>
										</template>
									</div>
									<div class="player-overlay"></div>
								</div>
							</template>
							<template v-if="slide.image.src !== ''">
								<div  class="swiper-slide lazyload" :data-bg="[slide.image.src && slide.image.src !=''?slide.image.src:'']">	
									<div class="row no-gutters banner-row">
										<div class="col-12 text-center">
											<h3 v-html="slide.title.value"></h3>
											<!--<button type="button" :data-ctalink="slide.buttoncta.value" class="btn btn-outline-dark">{{slide.button.value}}</button>-->
										</div>
									</div>
								</div>
							</template>
						</template>
					</template>
					</template>
				</div>
					

                <!-- Add Pagination -->
                <div class="swiper-pagination disabled"></div>
                <div :class="'swiper-button-next ' + [graphqlObject.children.length == 1 && !hasVideo ? 'hidden':'']"></div>
                <div :class="'swiper-button-prev '+ [graphqlObject.children.length == 1 && !hasVideo ? 'hidden':'']"></div>
				</template>
            </div>
            <div v-if="isproperty('IsBrand')" class="row no-gutters banner-countries mobile-hidden">
                <div class="col-12">
                    <div class="row no-gutters justify-content-center">
                      <nav class="navbar nav-countries">
                          <ul class="navbar-nav">
                            <template v-if="locations && locations.length > 0">
                              <template v-for="(location, index) in locations">
								<template v-if="!(location['isdisabled'] && location['isdisabled'].value && location['isdisabled'].value === '1') && location['children'] && location['children'].length > 0">
                                  <template v-if="location['children'].length > 1">    
                                    <li class="nav-item menu">
                                        <a href="" :class="'nav-link property-selection '+ [!(location['isdisabled'] && location['isdisabled'].value && location['isdisabled'].value === '1') ?'':'disabled']"><h3>{{location["title"].value}}</h3><p v-if="location['description'] && location['description'].value!=''">{{location["description"].value}}</p></a>
                                        <div class="submenu">
                                            <ul>
                                              <template v-for="(hotelname, subindex) in location['children']">
                                                <li class="submenu-item"><a :href="hotelname['cta']">{{hotelname["title"]}}</a></li>											
                                              </template>
                                            </ul>
                                        </div>
                                    </li>
                                  </template>
                                  <template v-else>
                                    <li class="nav-item menu">
									  <template v-for="(hotelname, subindex) in location['children']">
                                        <a :class="'nav-link '+[!(location['isdisabled'] && location['isdisabled'].value && location['isdisabled'].value === '1')?'':'disabled']" :href="hotelname['cta']"><h3>{{location["title"].value}}</h3><p v-if="location['description'] && location['description'].value!=''">{{location["description"].value}}</p></a>
									  </template>
                                    </li>                                 
                                  </template>
                                </template>
                              </template>
                            </template>
                          </ul>
                      </nav>
					
                       
                    </div>
                </div>
            </div>
           
            <div v-if="isproperty('IsBrand')" class="row no-gutters banner-countries-m desktop-hidden">
                <div class="col-12">
                    <div class="container-fluid pl-0 pr-0">
                        <div class="row no-gutters">
                            <div class="col-12">
                                <div class="container-fluid pr-0 pl-0 countries lazyload" :data-bg="[graphqlObject.mobilebackground?graphqlObject.mobilebackground.src:'']">
                                    	<!-- Swiper -->
										<template v-if="graphqlObject.children && graphqlObject.children != ''">
										<div class="banner-swiper-container banner-swiper-container-mobile desktop-hidden">
											<div class="swiper-wrapper">
												 <template v-for="(slide, index) in graphqlObject.children">
																<template v-if="slide.mobileimage && slide.mobileimage.src != ''">
																<div class="swiper-slide lazyload" :data-bg="[slide.mobileimage?slide.mobileimage.src:'']">
																</div>
																</template>
												</template>
												
											</div>
										</div>
										</template>
										<template v-if="locations && locations.length > 0">
											<template v-for="(location, index) in locations">											
												<template v-if="!(location['isdisabled'] && location['isdisabled'].value && location['isdisabled'].value === '1') && location['children'] && location['children'].length > 0">
													<template v-if="location['children'].length > 1">
														<div class="row no-gutters country-plist">
															<div class="col-12">
																<a href="" class="country-plist-title">{{location["title"].value}}</a>
																<div class="property-submenu">
																<a href="#" class="submenu-close-btn" :style="'background-image: url('+ closeBtn +')'"></a>
																	<ul class="plist properties">
																		<template v-for="(hotelname, subindex) in location['children']">								
																			<li class="selected-hotel" :data-hotel="hotelname['cta']" ><a :href="hotelname['cta']" :data-propertycode="hotelname && hotelname['hotelcode'] ? hotelname['hotelcode'] : ''" :data-propertycityalias="hotelname && hotelname['cityalias'] ? hotelname['cityalias'] : ''"  :data-propertyhotelalias="hotelname && hotelname['hotelalias'] ? hotelname['hotelalias'] : ''">{{hotelname['title']}}</a>
																			</li>																	
																		</template>
																		<!--<li class="selected-hotel" data-hotel="Orchard Gateway">Orchard Gateway</li>
																		<li class="selected-hotel" data-hotel="south beach">south beach</li>
																		<li class="selected-hotel" data-hotel="sentosa">sentosa</li>
																		<li class="selected-hotel" data-hotel="tanglin">tanglin</li>-->
																	</ul>
																</div>
																<div class="booking-block">
																	<div class="container-fluid pr-0 pl-0">
																		<div class="row no-gutters justify-content-start">
																			<div class="col-5 book">
																				<template v-if="location['children'] &&
																				location['children'].length > 0 &&
																				location['children'][0]['price'] && location['children'][0]['price'] > 0">
																				<h3>{{getDictionaryPhrase('Book')}}</h3>
																				<p>{{getDictionaryPhrase('From')}} <span>{{location['children'][0]['price'].toLocaleString()}}</span> {{$jss.currency()}} {{getDictionaryPhrase('PerNight')}}</p>
																				</template>
																				<template v-else>
																					<h3 class="book-noprice">{{getDictionaryPhrase('Book')}}</h3>	
																				</template>
																			</div>
																			<div class="col-4 explore">
																				<h3>{{getDictionaryPhrase('Explore')}}</h3>
																			</div>
																			<div class="swiper-button-prev closeSideBooking closepl-m" ></div>
																		</div>
																	</div>
																</div>
															</div>
														
														
													</template>
													<template v-else>
														<template v-for="(hotelname, subindex) in location['children']">
														<div class="row no-gutters country" :data-id="index+1">
															<div class="col-12">
																<a :href="hotelname['cta']" :data-id="index+1" :data-propertycode="hotelname && hotelname['hotelcode'] ? hotelname['hotelcode'] : ''" :data-propertycityalias="hotelname && hotelname['cityalias'] ? hotelname['cityalias'] : ''"  :data-propertyhotelalias="hotelname && hotelname['hotelalias'] ? hotelname['hotelalias'] : ''">{{location["title"].value}}
																<template v-if="location['description'] && location['description'].value!=''">&nbsp;{{location["description"].value}}</template></a>
																<div class="booking-block">
																	<div class="container-fluid pr-0 pl-0">
																		<div class="row no-gutters justify-content-start">
																			<div class="col-5 book">
																				<template v-if="hotelname['price'] && hotelname['price'] > 0">
																				<h3>{{getDictionaryPhrase('Book')}}</h3>
																				<p>{{getDictionaryPhrase('From')}} <span>{{hotelname['price'].toLocaleString()}}</span> {{$jss.currency()}} {{getDictionaryPhrase('PerNight')}}</p>
																				</template>
																				<template v-else>
																					<h3 class="book-noprice">{{getDictionaryPhrase('Book')}}</h3>	
																				</template>

																			</div>
																			<div class="col-4 explore">
																				<h3>{{getDictionaryPhrase('Explore')}}</h3>
																			</div>
																			<!-- <div class="swiper-button-prev closepl-m" onclick="closeSideCBooking(this)"></div> -->
																			<div class="closepl-m"></div>
																		</div>
																	</div>
																</div>
															</div>
														</div>									
														</template>
													</template>
												</template>
											</template>
											</template>
									
                                </div>
                            </div>
                        </div>
						
                            </div>
                        </div>
                    </div>

					<div class="mobile-hidden">
						  <h-j-book />
					</div>

                </div>
<!--<div id="placeHolder"></div>-->
<div id="placeHolder2"></div>

            </div>
        <!--</div>
	  


  </div>-->
</template>


<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import { Image } from '@sitecore-jss/sitecore-jss-vue';
import axios from 'axios';
import { getprice, getminprice } from '../personalise.js';
import { dataFetcher } from '../dataFetcher.js';
import { layoutFetcher, layoutFetchAction } from '../layoutFetcher.js';
import {
getDictionaryPhrase,
	getKeys,
  graphqlRemoveReserved,
  getContentByType,
  graphqlToObject,
  getObjectById,
  getObject,
  graphqlFetcher,
} from '../graphqlFetcher.js';
//import $ from 'jquery';
import ApolloClient from 'apollo-boost';
import gql from 'graphql-tag';
import { uuid } from 'vue-uuid';
import {
  enableExperimentalFragmentVariables,
  disableExperimentalFragmentVariables,
} from 'graphql-tag';
import Vue from 'vue';
import VueMeta from 'vue-meta';
import { trackingApi } from '@sitecore-jss/sitecore-jss-tracking';
import config from '../temp/config';
import HJBook from './HJBook';
import tempicon from '../assets/img/icons/temp_in.png';
import timeicon from '../assets/img/icons/time_in.png';
import closeBtn from '../assets/img/close_btn.png';
import { getWeatherAPI, getVideoInfo } from '../hjutil.js';
import 'lazysizes';
// import a plugin
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import {bookhotel} from '../hjutil.js';



export default {
  name: 'HJPropertyList',
  components: {
    ScText: Text,
    HJBook,
  },
  props: {
    fields: {
      type: Object,
      default: function() {},
    },
    currentcontext: {
      type: Object,
      default: null,
    },
    uid: {
      type: String,
      default: function() {
        return uuid.v1();
      },
    },
  },
  data() {
    return {
      loadingQueriesCount: 0,
      error: null,
      propertyObject: {},
      graphqlObject: {},
      locations: [],
      hotelnames: [],
	  hasVideo: false,
	  tempIcon: tempicon,
	  timeIcon: timeicon,
      closeBtn: closeBtn,
	  citytitle: '-',
	  pltime: '-',
	  plcelsius: '-',
	  plfahrenheit: '-',
	  minpricesobject: null,
    };
  },
  watch: {
	currency : function(){
		this.$jss.store.setMinpricesobject([]); 		
		this.$jss.store.setLocations(null); 		
		this.getLocations();
	}
  },
  computed: {
	currency: function(){
		return this.$jss.currency();
	},
    datasource: function() {
	  if (this.currentcontext != null)
		return this.currentcontext;
	  else
		return this.fields && this.fields.data && this.fields.data.datasource;
    },
    contextItem: function() {
      return this.fields.data && this.fields.data.contextItem;
    },
  },
  mounted: function() {
    // ...

    let vm = this;
	
	$('a[target!="_blank"]').on('click', function(e) {
	e.preventDefault();
	const thisLink = $(this).attr('href');
	const thisTarget = $(this).attr('target');
	if (thisLink !== undefined && thisLink !== '') {
			// ! ID: Redundant double check. Remove if overkill
		if (thisTarget == '_blank') {
		const win = window.open(thisLink, '_blank');
		win.focus();
		return;
		}
		window.location = thisLink;
	}
	});




    if (this.fields && this.fields.data && this.fields.data.datasource) {
      //graphqlRemoveReserved(this.fields.data.datasource);
    }
	else if (this.currentcontext != null)
	{
      //graphqlRemoveReserved(this.currentcontext);
	}

    vm.hotelnames = vm.$jss.hotelnames();
	/* $.getJSON( "/api/gethotelprices", function( data ) {
		vm.hotel_prices = data;
	});	*/
	
	//getWeatherAPI('beijing');
	   

	if (!(vm.$jss.minpricesobject() != null && vm.$jss.minpricesobject().length > 0))
	{
		if (vm.$jss.hotels() != null && vm.$jss.hotels().children.length > 0)
		{
			vm.$jss.store.setMinpricesobject(getminprice(null, vm.$jss.prices(), vm.$jss.currency(), vm.$jss.travellertype(), vm.$jss.hotelnames()['children']));
		}
	}
	vm.minpricesobject = vm.$jss.minpricesobject();
	
	if (vm.$jss.locations() != null && vm.$jss.locations().length > 0)
	{
		vm.locations = vm.$jss.locations();
	}
	else
	{
		if (vm.$jss.locations() == null || (vm.$jss.locations() != null && vm.$jss.locations().length === 0))
		{			
			var _locations = getContentByType(
				vm.$jss.constants(),
				'hjlocation',
				vm,
				false,
				false,
				'other',
				false
				);
			if (vm.hotelnames && vm.hotelnames.children && vm.hotelnames.children.length > 0 && _locations) {
				for (var i = 0; i < _locations.length; i++) {
					var hotel_count = 0;
					_locations[i]['children'] = [];
					for (var j = 0; j < vm.hotelnames['children'].length; j++) {
						if (
							vm.hotelnames['children'][j]['location'] &&
							vm.hotelnames['children'][j]['location'].value
						) {
							if (
								vm.hotelnames['children'][j]['location'].value
								.toLowerCase()
								.replace(/-/g, '')
								.replace(/{/g, '')
								.replace(/}/g, '') ===
							  _locations[i].id
								.toLowerCase()
								.replace(/-/g, '')
								.replace(/{/g, '')
								.replace(/}/g, '')
							) {
								var _price = vm.$jss.minpricesobject().find(x=>x.hotelcode.toLowerCase() === vm.hotelnames['children'][j]['hotelcode'].value.toLowerCase());
								_locations[i]['children'].push({
									title: vm.hotelnames['children'][j]['title'].value,
									id: vm.hotelnames['children'][j]['id'],
									cityalias: vm.hotelnames['children'][j]['cityalias'].value,
									hotelalias: vm.hotelnames['children'][j]['hotelalias'].value,
									hotelcode: vm.hotelnames['children'][j]['hotelcode'].value,
									cta: vm.hotelnames['children'][j]['cta'].value,
									isdisabled: vm.hotelnames['children'][j]['isdisabled'].value,
									adobebutton_name: vm.hotelnames['children'][j]['adobebuttonname'],
									adobelocationid: vm.hotelnames['children'][j]['adobelocationid'],
									adobepreviousbuttonclick: vm.hotelnames['children'][j]['adobepreviousbuttonclick'],
									price: _price != null && _price != undefined ? _price.price : 0
								});
							}
						}
					}
				}
				vm.$jss.store.setLocations(_locations);
			}
		} 
		vm.locations = vm.$jss.locations();
	} // END: vm.$jss.locations() != null && vm.$jss.locations().length > 0

	console.log(vm);
	vm.propertyObject = vm.$jss.hotelnames();
	vm.graphqlObject = getObject(vm.datasource, vm);
	
	if (vm.graphqlObject.children )
	{
		vm.graphqlObject.children.forEach(item => {
			item.videoInfo = getVideoInfo(item.externalvideourl, item.uploadedVideo);
			if (!vm.hasVideo && item.videoInfo && item.videoInfo.videoSource) {
				vm.hasVideo = true;
			}
		})
	}

	
	var propertyid = vm.$jss.property();
	var hotelDetails = {longitude:{value: 0}, latitude:{value:0}, title:{value:'HotelJen Hong Kong'}};
	
	//console.log(vm);
	if (propertyid && propertyid != null){
		propertyid = propertyid.replace(/\-/g,"").toUpperCase();
		hotelDetails = getObject(getObjectById(vm.$jss.hotels(),propertyid), vm);
		var country = "";
		if (hotelDetails)
		{

		if (hotelDetails && hotelDetails.title)

			if (
				vm.$jss.routeData().itemLanguage == 'zh-CN' ||
				vm.$jss.routeData().itemLanguage == 'zh'
			) {
				if (hotelDetails.hotelcode.value === 'HJB') {
					vm.citytitle = hotelDetails.fullname.value;
				} else {
					vm.citytitle = hotelDetails.title.value;
				}
			} else {
				vm.citytitle = hotelDetails.title.value;
			}

			if (hotelDetails && hotelDetails.cityalias)
			{
				country = hotelDetails.cityalias.value;
				$.getJSON( "/api/getweather?city="+country, function( data ) {
					var items = [];
					var temp = {};
					vm.plcelsius = data.temperature.celsius;
					vm.plfahrenheit = data.temperature.fahrenheit;		
				});
			}
			var hours = 0;
			if (hotelDetails.gmt && hotelDetails.gmt.value && hotelDetails.gmt.value >= 0)
				hours = new Date().getUTCHours()+(hotelDetails.gmt.value-0);
			else
				hours = new Date().getUTCHours();
			
			if (hours >= 24)
			{
				hours = hours - 24;
			}
			vm.pltime = hours.toString().paddingLeft("00") + ":" + new Date().getMinutes().toString().paddingLeft("00");
		}		
	}else{
		console.log('no property id found');
		var country = '';
	}
	setTimeout(function(){
	// window.addEventListener('load',()=>{
		bannerInit && typeof bannerInit === 'function' ? bannerInit() : ''
	},3000);
    //var animatedLabel2 =  '#'+popupId+' .animated-chart-label-2';

	$(document).ready(function() {
		$('#' + vm.uid).on('click', '.book', function(){
			var language = 'en'; 
			if ( vm.$jss.routeData().itemLanguage == 'zh-CN' || vm.$jss.routeData().itemLanguage == 'zh'){
				language = 'cn'; 
			}else{
				language = vm.$jss.routeData().itemLanguage.substring(vm.$jss.routeData().itemLanguage.length-2).toLowerCase()			
			} 
			var key = vm.$jss.hjconfig().encryption_key;
			var iv = vm.$jss.hjconfig().encryption_iv;
			var propertycode = "";
			var propertycityalias = "";
			var propertyhotelalias = "";
			if ($(this).parents('.booking-block:first').prev().hasClass('property-submenu'))
			{
				var menu = $(this).parents('.booking-block:first').prev();
				propertycode = $(menu).attr('data-propertycode');
				propertycityalias = $(menu).attr('data-propertycityalias');
				propertyhotelalias = $(menu).attr('data-propertyhotelalias');
			}
			else
			{
				var anchor = $(this).parents('.booking-block:first').prev();
				propertycode = $(anchor).attr('data-propertycode');
				propertycityalias = $(anchor).attr('data-propertycityalias');
				propertyhotelalias = $(anchor).attr('data-propertyhotelalias');
			}
			var book_url = vm.$jss.hjconfig().book_url;
			bookhotel(key, iv, book_url, propertycode, propertycityalias, propertyhotelalias, null, null, null, null, 'None', '', '', '', language);
		});
		$('#' + vm.uid).on('click', '.explore', function(){
			if ($(this).parents('.booking-block:first').prev().hasClass('property-submenu'))
			{
				var menu = $(this).parents('.booking-block:first').prev();
				window.location = $(menu).attr('data-href');
			}
			else
			{
				var anchor = $(this).parents('.booking-block:first').prev();
				window.location = $(anchor).attr('href');
			}
		});
	});

	$('li.selected-hotel').click(function(e){
		e.preventDefault();
	});
  },
  updated: function(){
    let vm = this;

	//add simple support for background images:
	document.addEventListener('lazybeforeunveil', function(e){
		var bg = e.target.getAttribute('data-bg');
		if(bg){
			e.target.style.backgroundImage = 'url(' + bg + ')';
		}
  });
  
  //hover on property function
  $('a.property-selection').mouseover(function(event){
      event.preventDefault()
      event.stopPropagation()
      $(this).css({'opacity':'0','z-index':'-1'})
      $(this).next().addClass('active')
      
      var storeEvent = $(this)
      setTimeout(function(){
          $(storeEvent).css({'opacity':'1','z-index':'1'})
          $(storeEvent).next().removeClass('active')
      },3500)
  })

    //console.log(vm);
    if (vm.isproperty('IsBrand') == '1') {
    } else {
      //$('.pl_list').addClass('hidden');
      //$('.pl_list_m').addClass('hidden');
      //$('.banner-countries-m').addClass('hidden');
      $('.property-title').removeClass('hidden');
      $('.property-weather').removeClass('hidden');
	  $('.banner-swiper-container').removeClass('mobile-hidden');
	  $('.HJPropertyList').addClass('isproperty');
    }
    $('.pl_listitem_m')
      .unbind('click')
      .bind('click', function(e) {
        if (!$(this).hasClass('ctaActive')) {
          //console.log('PL list clicked');
          var allCTA = $('.cta-content');
          var allsubHotels = $('.pl_subhotels_m');
          var tl2 = new TimelineMax({ delay: 0, repeat: 0 });
          var tl3 = new TimelineMax({ delay: 0, repeat: 0 });
          tl2.to(allsubHotels, 2.5, { opacity: 0, ease: Expo.easeOut });
          tl3.to(allCTA, 2.5, { x: '-100%', y: '0%', ease: Expo.easeOut });
          allCTA.removeClass('ctaOpen');
          $('.pl_subhotels_m .pl_subhotels_list_m .pl_subhotels_listitem_m').removeClass(
            'ctaActive'
          );
          $('.pl_listitem_m').removeClass('ctaActive');

          if (!$(this).hasClass('hasSubList')) {
            var animatedEl = $(this).children('.cta-content');
            var tl = new TimelineMax({ delay: 0, repeat: 0 });
            /*tl.set(animatedEl,{opacity:0})
				.to(animatedEl, 3, { opacity:1, ease:Expo.easeOut});*/

            tl.set(animatedEl, { x: '-100%', y: '0%' }).to(animatedEl, 2.5, {
              x: '0%',
              y: '0%',
              ease: Expo.easeOut,
            });
            $(this).addClass('ctaActive');
            animatedEl.addClass('ctaOpen');
          } else {
            var animatedEl = $(this).children('.pl_subhotels_m');
            var tl = new TimelineMax({ delay: 0, repeat: 0 });
            tl.set(animatedEl, { opacity: 0 }).to(animatedEl, 1, {
              opacity: 1,
              ease: Expo.easeOut,
            });
            $(this).addClass('ctaActive');
          }
        }
      });

    $('.pl_subhotels_listitem_m')
      .unbind('click')
      .bind('click', function(e) {
        //console.log('.pl_subhotels_listitem_m Clicked');
        if (!$(this).hasClass('ctaActive')) {
          var animatedEl = $(this)
            .parent('.pl_subhotels_list_m')
            .parent('.pl_subhotels_m')
            .parent('.pl_listitem_m')
            .children('.cta-content');
          //console.log(animatedEl);
          var tl = new TimelineMax({ delay: 0, repeat: 0 });
          tl.set(animatedEl, { x: '-100%', y: '0%' }).to(animatedEl, 2.5, {
            x: '0%',
            y: '0%',
            ease: Expo.easeOut,
          });
          $(this).addClass('ctaActive');
          animatedEl.addClass('ctaOpen');
        }
      });

    $('.back-arrow')
      .unbind('click')
      .bind('click', function(e) {
        //console.log('Back clicked');
        if (
          $(this)
            .parent('.cta-content')
            .parent('.pl_listitem_m')
            .hasClass('ctaActive')
        ) {
          if (
            !$(this)
              .parent('.cta-content')
              .parent('.pl_listitem_m')
              .hasClass('hasSubList')
          ) {
            var animatedEl = $(this).parent('.cta-content');
            var tl = new TimelineMax({ delay: 0, repeat: 0 });
            tl.to(animatedEl, 2.5, { x: '-100%', y: '0%', zIndex: 1, ease: Expo.easeOut });

            setTimeout(function() {

              /*$(this).parent('.cta-content').parent('.pl_listitem_m').removeClass('ctaActive');
				$(this).parent().removeClass('ctaOpen');*/
              $('.pl_listitem_m').removeClass('ctaActive');
              $('.cta-content').removeClass('ctaOpen');
            }, 300);
          } else {
            var animatedEl = $(this).parent('.cta-content');
            var allsubHotels = $('.pl_subhotels_m');
            var tl = new TimelineMax({ delay: 0, repeat: 0 });
            var tl2 = new TimelineMax({ delay: 0, repeat: 0 });
            tl.to(animatedEl, 2.5, { x: '-100%', y: '0%', zIndex: 1, ease: Expo.easeOut });
            tl2.to(allsubHotels, 2.5, { opacity: 0, ease: Expo.easeOut });
            setTimeout(function() {
              //console.log('removeClass');
              /*$(this).parent('.cta-content').parent('.pl_listitem_m').removeClass('ctaActive');
				$(this).parent().removeClass('ctaOpen');*/
              $('.pl_listitem_m').removeClass('ctaActive');
              $('.cta-content').removeClass('ctaOpen');
              $('.pl_subhotels_m .pl_subhotels_list_m .pl_subhotels_listitem_m').removeClass(
                'ctaActive'
              );
            }, 300);
          }

          /*if($(this).parent('.cta-content').parent('.pl_listitem_m').hasClass('hasSubList')){
				var animatedEl = $(this).parent('.cta-content');
				var tl = new TimelineMax({delay:0, repeat:0});
				tl.to(animatedEl, 2.5, { x:"-100%", y: "0%", ease:Expo.easeOut});
				$(this).parent('.cta-content').parent('.pl_listitem_m').removeClass('ctaActive') ;
				$(this).parent('.cta-content').parent('.pl_listitem_m').children('.pl_subhotels').css('opacity', 0);		
			}*/
        }
      });
	  
	  
		$(document).ready(function() {
			$('#' + vm.uid).on('click', '.book', function(){
				var language = 'en'; 
				if ( vm.$jss.routeData().itemLanguage == 'zh-CN' || vm.$jss.routeData().itemLanguage == 'zh'){
					language = 'cn'; 
				}else{
					language = vm.$jss.routeData().itemLanguage.substring(vm.$jss.routeData().itemLanguage.length-2).toLowerCase()			
				} 
				var key = vm.$jss.hjconfig().encryption_key;
				var iv = vm.$jss.hjconfig().encryption_iv;
				var propertycode = "";
				var propertycityalias = "";
				var propertyhotelalias = "";
				if ($(this).parents('.booking-block:first').prev().hasClass('property-submenu'))
				{
					var menu = $(this).parents('.booking-block:first').prev();
					propertycode = $(menu).attr('data-propertycode');
					propertycityalias = $(menu).attr('data-propertycityalias');
					propertyhotelalias = $(menu).attr('data-propertyhotelalias');
				}
				else
				{
					var anchor = $(this).parents('.booking-block:first').prev();
					propertycode = $(anchor).attr('data-propertycode');
					propertycityalias = $(anchor).attr('data-propertycityalias');
					propertyhotelalias = $(anchor).attr('data-propertyhotelalias');
				}
				var book_url = vm.$jss.hjconfig().book_url;
				bookhotel(key, iv, book_url, propertycode, propertycityalias, propertyhotelalias, null, null, null, null, 'None', '', '', '', language);
			});
			$('#' + vm.uid).on('click', '.explore', function(){
				if ($(this).parents('.booking-block:first').prev().hasClass('property-submenu'))
				{
					var menu = $(this).parents('.booking-block:first').prev();
					window.location = $(menu).attr('data-href');
				}
				else
				{
					var anchor = $(this).parents('.booking-block:first').prev();
					window.location = $(anchor).attr('href');
				}
			});
		});

	  
	  
  },
  methods: {
	getLocations: function()
	{
		var hotels = null;
		//if (this.$jss.hotels() == null || (this.$jss.hotels() != null && this.$jss.hotels().length == 0))
		//{
		//	this.$jss.store.setHotels(getObject(this.hotels, this));
		//}
		if (!(this.$jss.minpricesobject() != null && this.$jss.minpricesobject().length > 0))
		{
			if (!(this.$jss.hotels() == null || (this.$jss.hotels() != null && this.$jss.hotels().length == 0)))
			{
				this.$jss.store.setMinpricesobject(getminprice(null, this.$jss.prices(), this.$jss.currency(), this.$jss.travellertype(), this.$jss.hotels()));
			}
		}
		if (this.$jss.locations() != null && this.$jss.locations().length > 0)
		{
			this.locations = this.$jss.locations();
		}
		else
		{
			var _locations = getContentByType(
				graphqlToObject('', this.$jss.constants(), this, '', null, false),
				'hjlocation',
				this,
				false,
				false,
				'other',
				false
			  );	  
			if (this.hotelnames && this.hotelnames.children && this.hotelnames.children.length > 0 && _locations) {
				for (var i = 0; i < _locations.length; i++) {
					var hotel_count = 0;
					_locations[i]['children'] = [];
					for (var j = 0; j < this.hotelnames['children'].length; j++) {
						if (
							this.hotelnames['children'][j]['location'] &&
							this.hotelnames['children'][j]['location'].value
							) {
							if (
							  this.hotelnames['children'][j]['location'].value
								.toLowerCase()
								.replace(/-/g, '')
								.replace(/{/g, '')
								.replace(/}/g, '') ===
							  _locations[i].id
								.toLowerCase()
								.replace(/-/g, '')
								.replace(/{/g, '')
								.replace(/}/g, '')
							) {
								var _price = this.$jss.minpricesobject().find(x => x.hotelcode.toLowerCase().trim() == this.hotelnames['children'][j]['hotelcode'].value.toLowerCase().trim());
							  _locations[i]['children'].push({
								title: this.hotelnames['children'][j]['title'].value,
								titlehtml: this.hotelnames['children'][j]['titlehtml'].value,
								id: this.hotelnames['children'][j]['id'],
								cityalias: this.hotelnames['children'][j]['cityalias'].value,
								hotelalias: this.hotelnames['children'][j]['hotelalias'].value,
								hotelcode: this.hotelnames['children'][j]['hotelcode'].value,
								cta: this.hotelnames['children'][j]['cta'].value,
								isdisabled: this.hotelnames['children'][j]['isdisabled'].value,
								adobebuttonname: this.hotelnames['children'][j]['adobebuttonname'],
								adobelocationid: this.hotelnames['children'][j]['adobelocationid'],
								adobepreviousbuttonclick:
								  this.hotelnames['children'][j]['adobepreviousbuttonclick'],
								price: _price != undefined && _price != null ? _price.price : 0
							  });
							}
						}
					}
				}
				this.$jss.store.setLocations(_locations);
				this.locations = this.$jss.locations();
			}
		}
		return this.$jss.locations();
	},
	getDictionaryPhrase: function(key)
	{
		return getDictionaryPhrase(key, this);
	},
	book_click: function()
	{
		console.log('book_click');
		var key = vm.$jss.hjconfig().encryption_key;
		var iv = vm.$jss.hjconfig().encryption_iv;
		var propertycode = "";
		var propertycityalias = "";
		var propertyhotelalias = "";
		if ($(this).attr('data-bookingurl'))
		{
			propertycode = $(this).attr('data-bookingurl').split('|')[2];
			propertycityalias = $(this).attr('data-bookingurl').split('|')[0];
			propertyhotelalias = $(this).attr('data-bookingurl').split('|')[1];
		}
		var book_url = vm.$jss.hjconfig().book_url;
		bookhotel(key, iv, book_url, propertycode, propertycityalias, propertyhotelalias, null, null, null, null, 'None', '', $(this).attr('data-roomtypecode'), '');
	},
    isproperty: function(name) {
      if (this.fields != null && this.fields[name] != undefined) {
        return this.fields[name];
      } else {
        if (
          this.fields &&
          this.fields.data &&
          this.fields.data.datasource &&
          this.fields.data.datasource.fields
        ) {
          for (var i = 0; i < this.fields.data.datasource.fields.length; i++) {
            if (this.fields.data.datasource.fields[i].name == name) {
              if (
                this.fields.data.datasource.fields[i].targetItems &&
                this.fields.data.datasource.fields[i].targetItems.length > 0
              ) {
                if (this.fields.data.datasource.fields[i].targetItems[0].textField)
                  return this.fields.data.datasource.fields[i].targetItems[0].textField.value;
                else if (this.fields.data.datasource.fields[i].targetItems[0].title)
                  return this.fields.data.datasource.fields[i].targetItems[0].textField.title;
                else return '';
              } else {
                return this.fields.data.datasource.fields[i].value;
              }
            }
          }
        }
		else if (this.currentcontext && this.currentcontext.fields)
		{
			var keys = getKeys(this.currentcontext);
			for (var i=0; i < keys.length; i++)
			{
				if (keys[i].toLowerCase() === name.toLowerCase())
				{
					if (this.currentcontext[keys[i]].targetItems && 
						this.currentcontext[keys[i]].targetItems.length > 0) {
						if (this.currentcontext[keys[i]].targetItems[0].textField)
							return this.currentcontext[keys[i]].targetItems[0].textField.value;
						else if (this.currentcontext[keys[i]].targetItems[0].title)
							return this.currentcontext[keys[i]].targetItems[0].title.value;
						else
							return "";
					}
					else
					{
						if (this.currentcontext[keys[i]].value != undefined)
							return this.currentcontext[keys[i]].value;
						else
							return this.currentcontext[keys[i]];
					}
				}
			}
			for (var i=0; i < this.currentcontext.fields.length; i++)
			{
				if (this.currentcontext.fields[i].name == name)
				{
					if (this.currentcontext.fields[i].targetItems && 
						this.currentcontext.fields[i].targetItems.length > 0) {
						if (this.currentcontext.fields[i].targetItems[0].textField)
							return this.currentcontext.fields[i].targetItems[0].textField.value;
						else if (this.currentcontext.fields[i].targetItems[0].title)
							return this.currentcontext.fields[i].targetItems[0].title.value;
						else
							return "";
					}
					else
					{
						return this.currentcontext.fields[i].value;
					}
				}
			}
		}
      }
      return null;
    },
  },
};
</script>
