<template>
  <div :id="this.uid" class="HJContainer" :data-hjbaseurl="this.hjbaseurl" :data-hjloadinganchor="this.hjloadinganchor" :data-hjhref="this.hjhref" :data-hjcity="this.hjcity" 
  :data-propertyid="hoteldetail && hoteldetail['id'] ? hoteldetail['id'] :''" :data-propertycode="hoteldetail && hoteldetail['hotelcode'] && hoteldetail['hotelcode'].value ? hoteldetail['hotelcode'].value : ''" :data-propertycityalias="hoteldetail && hoteldetail['cityalias'] && hoteldetail['cityalias'].value ? hoteldetail['cityalias'].value : ''"  :data-propertyhotelalias="hoteldetail && hoteldetail['hotelalias'] && hoteldetail['hotelalias'].value ? hoteldetail['hotelalias'].value : ''" 
  :data-propertymaxoccupy="hoteldetail && hoteldetail['maxoccupy'] && hoteldetail['maxoccupy'].value ? hoteldetail['maxoccupy'].value : '3'" :data-geolocation="this.geolocation">
  <template v-for="(item, index) in datasource.children">
  
	<div v-if="item.template.name=='HJContentBlock'">
		<div :id="'contentblock-' + index.toString()"   ></div>
	</div>
	<div v-if="item.template.name=='HJContentBlockList'">
		<div :id="'contentblocklist-' + index.toString()"   ></div>
	</div>
	<div v-if="item.template.name=='HJTabbedContentBlockList'">
		<div :id="'tabbedcontentblocklist-' + index.toString()"   ></div>
	</div>
	<div v-if="item.template.name=='HJForm'">
		<h-j-form :currentcontext="item"    />
	</div>
	
	<div v-if="item.template.name=='HJPropertyList'">
		<div id="propertylist"   ></div>
	</div>
	<div v-if="item.template.name=='HJMap'">
		<div id="mapEl"></div>
	</div>
  </template>
  </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import { Image } from '@sitecore-jss/sitecore-jss-vue';
import axios from 'axios';
import { dataFetcher } from '../dataFetcher.js';
import { layoutFetcher, layoutFetchAction } from '../layoutFetcher.js';
import { searchMoreContentByType, graphqlRemoveReserved, graphqlToObject, getObject, getObjectById, graphqlFetcher } from '../graphqlFetcher.js';
//import $ from 'jquery';
import ApolloClient from 'apollo-boost';
import gql from 'graphql-tag';
import { uuid } from 'vue-uuid';
import {
  enableExperimentalFragmentVariables,
  disableExperimentalFragmentVariables,
} from 'graphql-tag';
import Vue from 'vue';
import VueMeta from 'vue-meta';
import { trackingApi } from '@sitecore-jss/sitecore-jss-tracking';
import config from '../temp/config';
import HJForm from './HJForm';
import HJContentBlock from './HJContentBlock';
import HJContentBlockList from './HJContentBlockList';
import HJTabbedContentBlockList from './HJTabbedContentBlockList';
import HJPropertyList from './HJPropertyList';
import HJMap from './HJMap';
import {appendAuthHeaders} from '../hjutil.js';
import 'lazysizes';
// import a plugin
import 'lazysizes/plugins/parent-fit/ls.parent-fit';


export default {
  name: 'HJContainer',
  components: {
    ScText: Text,
	HJForm,
	HJContentBlock,
	HJContentBlockList,
	HJTabbedContentBlockList,
	HJPropertyList,
	HJMap,
  },
  props: {
    fields: {
      type: Object,
      default: function(){},
    },
	rendering: {
		type: Object,
	},
	uid: {
		type: String,
		default: function(){return uuid.v1();}
	},
    currentcontext: {
      type: Object,
      default: null,
    },
    uid: {
      type: String,
      default: function(){return uuid.v1();},
    }
      /*,hjbaseurl: {
          type: String,
          default: '',
      },
      hjloadinganchor: {
          type: String,
          default: '',
      },
      hjhref: {
          type: String,
          default: '',
      },
      hjcity: {
          type: String,
          default: '',
      },
      hoteldetail: {
          type: String,
          default: null
      },
      geolocation: {
          type: String,
          default: null
      },*/
  },
  data() {
    return {
      loadingQueriesCount: 0,
      error: null,
	  hjbaseurl: '',
	  hjloadinganchor: '',
	  hjhref: '',
	  hjcity: '',
	  hoteldetail: null,
	  geolocation: '',
	  hasdata: null
    };
  },
  computed: { 
	currency: function(){
		return this.$jss.currency();
	},
	ismobile: function(){
		return this.$jss.ismobile();
	},
	  datasource: function () {
		return this.fields.data && this.fields.data.datasource;
    },
    contextItem: function() {
      return this.fields.data && this.fields.data.contextItem;
    },
    datacontext: function() {
      if (this.fields.data && this.fields.data.datasource)
	  {
		if (this.fields.data.datasource.children && this.fields.data.datasource.children.length > 0)
		{
			for (var i=0; i < this.fields.data.datasource.children.length; i++)
			{
				if (this.fields.data.datasource.children[i].template.name.toLowerCase() === "folder" && 
				this.fields.data.datasource.children[i].name.toLowerCase() === "content")
				{
					return this.fields.data.datasource.children[i];
				}
			}
		}
	  }
	  return {};
    },
    hotels: function() {
		if (this.$root.hotels)
			return this.$root.hotels; 
		else
		{
			return {};
		}
    },
    propertydata: function() {
		if (this.$root.propertydata)
			return this.$root.propertydata; 
		else
		{
			return {};
		}
    },
  },
  //async mounted() {
  mounted() {
	let vm = this;
	var type = ""
    var _routedata = vm.$jss.routeData();
	
	var datapath = "";
	if (this.datasource.fields && this.datasource.fields.find(x=>x.name.toLowerCase() === 'datapath') != undefined && this.datasource.fields.find(x=>x.name.toLowerCase() === 'datapath').value != '')
	{
		datapath = this.datasource.fields.find(x=>x.name.toLowerCase() == 'datapath').value;
	}
	else
	{
		if (_routedata.fields.DataPath && _routedata.fields.DataPath.value  && _routedata.fields.DataPath.value != '' )
		{
			datapath = _routedata.fields.DataPath.value;
		}
	}
	if (vm.$jss.hjconfig().use_singlecall == true && datapath != '')
	{
		type = "allsociety";
		searchMoreContentByType(null, type, vm, 
			function(data, _vm, ar, type, property, event, context, isloadmore, graphqlresults, searchresults, targetobject, targetobjectid){
			for (var i=0; i < _vm.datasource.children.length; i++)
			{
				if (_vm.datasource.children[i].template.name.toLowerCase() == "hjcontentblock")
				{
					const ComponentCtor = Vue.extend(HJContentBlock);
					const componentInstance = new ComponentCtor({ propsData: {currentcontext : _vm.getobject(_vm.datasource.children[i]), datacontext : ar } });
					componentInstance.$mount('#contentblock-'+i.toString());
				}
				else if (_vm.datasource.children[i].template.name.toLowerCase() == "hjtabbedcontentblocklist")
				{
					const ComponentCtor = Vue.extend(HJTabbedContentBlockList);
					const componentInstance = new ComponentCtor({ propsData: {currentcontext : _vm.datasource.children[i], datacontext : ar } });
					componentInstance.$mount('#tabbedcontentblocklist-'+i.toString());
				}
				else if (_vm.datasource.children[i].template.name.toLowerCase() === "hjmap")
				{
					
					$('#map').html('');
					if (ar && ar.children && ar.children.length > 0)
					{
						var _nearby = ar.children.find(x=>x.name.toLowerCase() == "nearby");
						var nearby = getObject(_nearby, vm);
						
						if (nearby != undefined && nearby != null)
						{
							const ComponentCtor = Vue.extend(HJMap);
							const componentInstance = new ComponentCtor({ propsData: {currentcontext : _vm.datasource.children[i], nearbylocations: nearby } });
							componentInstance.$mount('#mapEl');
						}
					}
					
				}
			}
				
			}, -1, null, vm.$jss.property(), false, vm.$jss.travellertype(), null, "", null, this.datasource.children[i], null, null, datapath, null, true);
			
		for (var i=0; i < this.datasource.children.length; i++)
		{
			if (this.datasource.children[i].template.name.toLowerCase() == "hjpropertylist")
			{
				const ComponentCtor = Vue.extend(HJPropertyList);
				const componentInstance = new ComponentCtor({ propsData: {currentcontext : this.datasource.children[i] } });
				componentInstance.$mount('#propertylist');
			}
			else if (this.datasource.children[i].template.name.toLowerCase() == "hjcontentblocklist")
			{
				const ComponentCtor = Vue.extend(HJContentBlockList);
				const componentInstance = new ComponentCtor({ propsData: {currentcontext : this.getobject(this.datasource.children[i]) } });
				componentInstance.$mount('#contentblocklist-'+i.toString());
			}
		}
	}
	else
	{
		for (var i=0; i < this.datasource.children.length; i++)
		{
			if (this.datasource.children[i].template.name.toLowerCase() == "hjpropertylist")
			{
				const ComponentCtor = Vue.extend(HJPropertyList);
				const componentInstance = new ComponentCtor({ propsData: {currentcontext : this.datasource.children[i] } });
				componentInstance.$mount('#propertylist');
			}
			else if (this.datasource.children[i].template.name.toLowerCase() == "hjcontentblocklist")
			{
				const ComponentCtor = Vue.extend(HJContentBlockList);
				const componentInstance = new ComponentCtor({ propsData: {currentcontext : this.getobject(this.datasource.children[i]) } });
				componentInstance.$mount('#contentblocklist-'+i.toString());
			}
			else if (this.datasource.children[i].template.name.toLowerCase() == "hjcontentblock")
			{
				const ComponentCtor = Vue.extend(HJContentBlock);
				const componentInstance = new ComponentCtor({ propsData: {currentcontext : vm.getobject(vm.datasource.children[i]), datacontext : null } });
				componentInstance.$mount('#contentblock-'+i.toString());
			}
			else if (this.datasource.children[i].template.name.toLowerCase() == "hjtabbedcontentblocklist")
			{
				const ComponentCtor = Vue.extend(HJTabbedContentBlockList);
				const componentInstance = new ComponentCtor({ propsData: {currentcontext : this.datasource.children[i], datacontext : null } });
				componentInstance.$mount('#tabbedcontentblocklist-'+i.toString());
			}
		}
	}

	if (this.fields && this.fields.data && this.fields.data.datasource)
	{
		//graphqlRemoveReserved(this.fields.data.datasource);
	}
	let current_item = vm.$jss.sitecoreContext();


	vm.geolocation = "";
	if (vm.$jss.hjconfig().geolocation_enabled == true)
	{
		vm.geolocation = vm.$jss.hjgeocookie();
	}
	//else
	//{
	//	vm.$jss.store.setHJGeoCookie(vm.geolocation);
	//}

	if (_routedata.fields.LoadingAnchor && _routedata.fields.LoadingAnchor.value  && _routedata.fields.LoadingAnchor.value != '' )
	{
		this.hjloadinganchor = _routedata.fields.LoadingAnchor.value;
	}
	if (vm.fields.data.datasource != null && vm.fields.data.contextItem != null)
	{
		//var path = '/sitecore/content/hoteljen/home/hotels/jen/beijing/centraldistrict/Page Components/Container';
		var path = vm.fields.data.datasource.path;
		var basepath = path.substring(0, path.indexOf('/Page Components/'));
		/*
		var regex = /(\/sitecore\/content\/hoteljen\/home)?([\/\\""A-Za-z0-9.;!#$%&'*\+\-\=?\^_`{|}~]{1,})?(\/Page Components\/)([\/\\""A-Za-z0-9.;!#$%&'*\+\-\=?\^_`{|}~]{1,})?/gmi; 
		var match = regex.exec(path);
		//console.log(match);
		if (match[2] != undefined)
		{
			//console.log(match[1]);
		}
		*/
		//console.log(path);
		//console.log(basepath);
		var anchorpart = vm.fields.data.contextItem.path.substring(basepath.length);
		var hostname = window.location.hostname;
		var href = basepath.replace('\/sitecore\/content\/hoteljen\/home', hostname);
		//console.log('Domain: ' + hjhostname + ';Href: ' + hjhref);
		if (anchorpart.substring(0,1) == '/');	
			anchorpart = anchorpart.substring(1, anchorpart.length);
		anchorpart = anchorpart.replace(/\//g, "-");
		vm.hjbaseurl = basepath;
		console.log(anchorpart)
		if (href != ''){
			vm.hjhref = href;
		}else{
			vm.hjhref='';
		}
	}
	
	//get city 
	
	if (vm.$jss.property() && vm.$jss.property() != ''){
		var propertyid = vm.$jss.property();
		var hotelDetails = {};
		if (propertyid && propertyid != null){		
		propertyid = propertyid.replace(/\-/g,"").toUpperCase();
		hotelDetails = getObject(getObjectById(vm.$jss.hotels(),propertyid), vm);
		vm.hjcity = hotelDetails.cityalias.value;
		}else{
		vm.hjcity = '';
		}
		vm.hoteldetail = hotelDetails;
	}
	
	
	  //let vm = this;
	

    setTimeout(function() {
	
		var from = document.referrer;
    }, 500);

/*	const trackingApiOptions = {
			host: config.sitecoreApiHost,
			querystringParams: {
			  sc_apikey: config.sitecoreApiKey,
			},
			fetcher: dataFetcher
	};
*/
	//trackingApi
	//  .trackEvent([{ pageId: '{36715B3C-7A6C-5576-9343-F6B504173D74}', url: '/hotels/beijing'}], trackingApiOptions)
	//  .then(() => console.log('Page event pushed'))
	//  .catch((error) => console.error(error));	
	

 	$(document).ready(function(){
		
		$('.navbar-nav li a').each(function(){
			if ($(this).attr('data-urlexcluderegex') && $(this).attr('data-urlexcluderegex') != '')
			{
				var regex = new RegExp($(this).attr('data-urlexcluderegex'));  						
				var baseurl = $('.HJContainer').attr('data-hjbaseurl'); 
				var match = regex.exec(baseurl);	
				//console.log(match);
				if (match != null)
				{
					$(this).parent('li').addClass('regex_hide');
					//console.log($(this).parent('li'));
				}
			}
		});
		$('.mobileMenu .mobile-links').each(function(){
			if ($(this).attr('data-urlexcluderegex') && $(this).attr('data-urlexcluderegex') != '')
			{
				var regex = new RegExp($(this).attr('data-urlexcluderegex'));  						
				var baseurl = $('.HJContainer').attr('data-hjbaseurl'); 
				var match = regex.exec(baseurl);	
				//console.log(match);
				if (match != null)
				{
					$(this).addClass('regex_hide');
					//console.log($(this));
				}
			}
		});
		if ($('.HJContainer').attr('data-hjloadinganchor') != '')
		{
			var anchor_counter = 0
			var checkanchor = window.setInterval(function(){ 
				if ($('.HJContainer').attr('data-hjloadinganchor') != '')
				{
					if ($('#' + $('.HJContainer').attr('data-hjloadinganchor')).length > 0 && $('#' + $('.HJContainer').attr('data-hjloadinganchor')) != '')
					{
						var elem = $('#' + $('.HJContainer').attr('data-hjloadinganchor'));
						// check if tab
						
						if ($(elem).parents('.main-subtabs').length > 0)
						{
							//console.log('found subtabs');	
							var mainSubTab = $('.HJContainer').attr('data-hjloadinganchor');
							mainSubTab = mainSubTab.split('-')[0];
							//console.log(mainSubTab);
							var subtab = $(elem).parents('.main-subtabs:first');
							var maintab = $(elem).parents('.main-tabs:first');
							var maintab2 = $(elem).parents('.main-tabs:nth-of-type(2)');
							var maintab = $('#'+mainSubTab+'-tab');
							//console.log(maintab);
							//console.log($(elem).parents('.main-tabs:nth-of-type(2)'));
							/*if (mainSubTab == 'meet'){
								$(maintab2).trigger('click');						
							}else{
								$(maintab).trigger('click');
							}*/
							$(maintab).trigger('click');
							$(subtab).trigger('click');
						}
						else
						{
							if ($(elem).parents('.main-tabs').length > 0)
							{
								$(elem).parents('.main-tabs:first').trigger('click');
							}
						}
						// END: check if tab
						//console.log('scrolling to :' + '#' + $('.HJContainer').attr('data-hjloadinganchor'));
						$(document).scrollTop($('#' + $('.HJContainer').attr('data-hjloadinganchor')).offset().top - 100);
						clearInterval(checkanchor);
					}
				}
				anchor_counter++;
				if (anchor_counter > 5)
					clearInterval(checkanchor);
			}, 500);
		}
	});
	window.addEventListener("load", function() {
		console.log('containr page fully loaded');
	});
  },
  updated() {
	  console.log('dom updated');
  },
  methods: {
  	visibilityChanged (isVisible, entry) {
	  this.isVisible = isVisible
	  console.log(entry)
	},	
	  getobject: function (o) {
		  var data_datasource = {};
          if (o.template.name.toLowerCase() === "hjtabbedcontentblocklist" || o.template.name.toLowerCase() === "hjcontentblocklist")
		  {
              data_datasource = getObject(o, this);
          }
          else if (o.template.name.toLowerCase() === "hjcontentblock") {
			  var ar = getObject(o, this);
			  data_datasource = ar;
			  //if (_vm.isproperty("layouttype") == "menupagelinks") {
			  //    _vm.links = [];
			  //    _vm.links = getLinks(data_datasource, _vm, false);
			  //}
			  var tempTabs = [];
			  var temp2 = [];
			  var fieldtitle = '';
			  var fielddesc = '';
			  var fieldimage = '';
			  var fieldfooter = '';
			  var fieldbuttontext = '';
			  var fieldbuttoncta = '';
			  var fieldid = '';
			  var formid = '';
			  var hasAnchor = true;
			  var hasDisclaimer = true;

			  if (ar.buttoncta && ar.buttoncta.value && ar.buttoncta.value != '') {
				  var regexp = /(url=\")[\""A-Za-z0-9.;:!#$%&'*+-\/=?^_`{|}~\p{L}\p{Nd}]{1,}(\")/g;
				  var match = ar.buttoncta.value.match(regexp);
				  if (match != null && match.length > 0) {
					  if (ar.adobetrackingcode)
						  data_datasource.buttoncta.value += ar.adobetrackingcode;
				  }
			  }
			  if (ar.captionbuttoncta && ar.captionbuttoncta.value && ar.captionbuttoncta.value != '') {
				  var regexp = /(url=\")[\""A-Za-z0-9.;:!#$%&'*+-\/=?^_`{|}~\p{L}\p{Nd}]{1,}(\")/g;
				  var match = ar.buttoncta.value.match(regexp);
				  if (match != null && match.length > 0) {
					  if (ar.adobetrackingcode)
						  data_datasource.captionbuttoncta.value += ar.adobetrackingcode;
				  }
			  }
		  }
          return data_datasource;
	  },
    isproperty: function(name) {
		if (this.fields != null && this.fields[name] != undefined)
		{
			return this.fields[name];
		}
		else
		{
			if (this.fields.data.datasource && this.fields.data.datasource.fields)
			{
				for (var i=0; i < this.fields.data.datasource.fields.length; i++)
				{
					if (this.fields.data.datasource.fields[i].name == name)
					{
						return this.fields.data.datasource.fields[i].value;
					}
				}
			}
		}
		return null;
	}
  }
}
</script>
