<template>
  <div data-e2e-id="HJParallaxLayout" :class="id + ' parallax-main'"> 
    <template>
    </template>
	<div class="parallax-container" >
	<!--<div data-0="transform:translate(0,0%);" data-100p="transform:translate(0,-100%);">
	<div style="width:100%; height: 100%; text-align: center;">
		<img src='http://placekitten.com/800/600?image=1' alt='placeholder-image1' style="width: 60%; max-width:600px; height: 500px; display: inline-block; transform:translate(0%, -30%);">
	</div>
	</div>
	
	<div data-0="transform:translate(0,100%);" data-100p="transform:translate(0,0%); top:0;" data-_constant-350p="transform:translate(0,-100%)">
			<div style="width:100%; height: 100%; text-align: center;">
		<img src='http://placekitten.com/800/600?image=1' alt='placeholder-image1' style="width: 60%; max-width:600px; height: 500px; display: inline-block; transform:translate(0%, -30%);">
	</div>
	</div>
	
	<div data-0="transform:translate(0,300%);" data-100p="transform:translate(0,100%);" data-_constant-350p="transform:translate(0,0%); top:0;" data-_constant-600p="transform:translate(0,-100%)">
			<div style="width:100%; height: 100%; text-align: center;">
		<img src='http://placekitten.com/800/600?image=1' alt='placeholder-image1' style="width: 60%; max-width:600px; height: 500px; display: inline-block; transform:translate(0%, -30%);">
	</div>
	</div>-->

	<!--<div data-_constant-150p="transform:translate(0,100%);" data-_contant-200p="transform:translate(0,0%);" data-_constant-300p="transform:translate(0,-100%);"></div>-->
   
	<div style="width: 60%; height: 100%; color: white;margin:0 auto;">
	<sc-placeholder name="jss-hjparallax-layout" :rendering="rendering" />
	</div>
	<!--<div data-150-end="transform:translate(0,100%);position:fixed;" data-end="transform:translate(0,7%);" >-->
	<!--<div style="position:relative; transform:translate(0%, 7%);">
		<div style="width: 60%; height: 100%; background-color:#343944;color: white;margin:0 auto;"> <sc-placeholder name="jss-hjparallax-layout" :rendering="rendering" /> </div>
	</div>-->

	
	</div>

	
  </div>
</template>

<script>
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue';

export default {
  name: 'HJParallaxLayout',
  props: {
    fields: {
      type: Object,
    },
    rendering: {
      type: Object,
    },
  },
  components: {
    ScPlaceholder: Placeholder,
  },
  computed: {},
  mounted: function() {
    console.log('Parallax mounted');
    $(document).ready(function() {
      $('nav')
        .parent()
        .addClass('fixedNav');
      var navHeight = $('.nav').height();
      var viewportHeight = $(window).height();

      console.log('jquery Loaded');
      /*$('.parallax-container > div').addClass('afterLoad');*/
      setTimeout(function() {
        skrollr.init({
          constants: {
            //fill the box for a "duration" of 150% of the viewport (pause for 150%)
            //adjust for shorter/longer pause
            box: '400p',
            constant: '150p',
          },
        });
        //$('.parallax-columns').css('top', navHeight +'px');
      }, 500);
      /*setTimeout(function(){
	$('.HJContentBlcok').css('height', viewportHeight);
	}, 200);*/
    });

    /*$(window).on('resize', function(){
    viewportHeight = $(window).height();
  	$('.HJContentBlcok').css('height', viewportHeight);
  });*/

    /*$(document).scroll(function() {
    console.log($(document).scrollTop());
	});*/
  },
  updated: function() {
    console.log('updated Parallax Layout');
  },
};
</script>
