<template>
  <div class="jss-loading" v-if="visible">
    <svg id="sc-loading-logo" version="1.0" xmlns="http://www.w3.org/2000/svg" width="700px" height="413px" viewBox="0 0 700 413" enable-background="new 0 0 340 333">
      <g transform="translate(0.000000,413.000000) scale(0.100000,-0.100000)">
        <path class="border" d="M249 3821 l-24 -19 -3 -1747 -2 -1746 29 -30 29 -29 3227 0 3226 0 24 25 25 24 0 1749 0 1749 -23 21 -23 22 -3231 0 c-3166 0 -3231 0 -3254 -19z m101 -31 c0 -5 -9 -10 -19 -10 -11 0 -27 -10 -35 -22 -14 -20 -16 -199 -16 -1711 l0 -1688 25 -24 24 -25 3173 0 c2856 0 3176 2 3196 16 12 8 22 22 22 30 0 8 5 14 10 14 16 0 12 -42 -7 -62 -16 -17 -129 -18 -3220 -18 -3190 0 -3203 0 -3223 20 -20 20 -20 33 -20 1733 0 1889 -5 1757 61 1757 16 0 29 -4 29 -10z m6378 -2 c13 -13 17 -68 4 -68 -4 0 -12 10 -17 23 -6 12 -20 26 -32 32 -13 5 -23 13 -23 17 0 13 55 9 68 -4z m-48 -1740 l0 -1693 -3177 0 -3178 0 -3 1685 c-1 927 0 1690 2 1695 2 7 1069 9 3180 8 l3176 -3 0 -1692z" />

        <path class="JSS J" d="M3418 2398 l-3 -1003 -184 -3 c-141 -2 -186 1 -193 10 -4 7 -8 115 -8 241 l0 227 -170 0 -170 0 0 -323 c0 -315 1 -324 23 -367 15 -30 35 -50 62 -64 38 -20 59 -21 432 -24 270 -2 406 1 435 8 52 15 103 64 117 115 8 28 11 365 11 1113 l0 1072 -175 0 -175 0 -2 -1002z" />
        <path class="JSS S1" d="M4131 3378 c-53 -27 -79 -60 -91 -114 -13 -55 -12 -582 1 -643 6 -28 20 -65 33 -83 12 -18 180 -183 374 -367 l352 -334 0 -229 0 -228 -207 2 -208 3 -3 233 -2 232 -176 0 -175 0 3 -317 c3 -347 5 -355 64 -402 50 -39 88 -42 517 -39 411 3 412 3 453 26 80 46 79 39 79 452 0 355 -1 366 -22 405 -13 26 -154 165 -382 380 l-361 340 2 205 3 205 205 0 205 0 3 -212 2 -213 175 0 175 0 0 293 c0 333 -4 353 -76 398 l-39 24 -430 3 -430 3 -44 -23z" />
        <path class="JSS S2" d="M5511 3378 c-88 -45 -86 -33 -89 -426 l-3 -347 24 -45 c16 -30 134 -149 378 -380 l354 -336 3 -232 2 -232 -207 2 -208 3 -3 233 -2 232 -171 0 -170 0 3 -329 c3 -317 4 -331 24 -358 12 -15 36 -37 55 -48 33 -19 52 -20 469 -20 419 0 436 1 475 21 27 14 47 34 63 64 22 43 22 51 22 390 0 341 0 346 -24 395 -19 42 -82 106 -362 369 -186 175 -349 330 -362 343 -22 24 -22 29 -20 226 l3 202 208 3 207 2 0 -215 0 -215 175 0 176 0 -3 309 c-3 297 -4 311 -24 338 -12 15 -36 37 -55 48 -32 19 -55 20 -464 23 l-430 3 -44 -23z" />

        <path class="Logo outer-circle" d="M1340 3301 c-101 -24 -204 -59 -265 -89 -143 -70 -306 -208 -404 -341 -103 -139 -178 -337 -188 -493 -3 -54 -10 -98 -15 -98 -4 0 -8 -13 -8 -30 0 -16 4 -30 8 -30 5 0 12 -46 15 -102 3 -57 11 -116 16 -133 60 -180 88 -242 158 -347 63 -93 221 -243 323 -306 146 -90 284 -136 455 -152 286 -27 544 45 765 212 202 152 322 337 396 608 23 83 26 384 6 470 -49 202 -132 361 -262 503 -119 129 -305 247 -465 294 -38 12 -88 26 -110 33 -22 7 -110 14 -195 16 -124 3 -170 0 -230 -15z m462 -54 c348 -91 620 -346 732 -689 71 -218 59 -501 -31 -710 -131 -304 -381 -520 -702 -605 -84 -23 -116 -26 -256 -26 -130 0 -174 4 -235 20 -203 56 -368 149 -499 281 -132 135 -219 287 -269 471 -22 84 -25 115 -25 256 0 141 3 172 25 256 108 401 418 686 831 765 110 21 308 12 429 -19z" />
        <path class="Logo inner-circle" d="M1400 3066 c-266 -57 -480 -225 -594 -467 -59 -123 -71 -185 -71 -349 1 -171 14 -230 81 -365 85 -172 206 -291 379 -375 55 -27 129 -55 165 -64 276 -66 560 10 767 207 423 402 311 1099 -217 1346 -47 21 -112 47 -145 56 -83 22 -283 28 -365 11z m307 -42 c262 -60 449 -203 558 -429 63 -130 78 -197 79 -345 0 -148 -15 -213 -79 -345 -91 -187 -257 -335 -445 -399 -160 -54 -343 -60 -485 -16 -214 67 -393 219 -486 415 -61 126 -74 189 -74 350 0 161 17 232 88 370 75 146 253 303 407 361 139 52 312 67 437 38z" />
        <path class="Logo inner-wave-1" d="M2008 2612 c-70 -43 -127 -81 -128 -85 0 -5 11 -23 25 -42 52 -67 64 -158 35 -263 -33 -120 -73 -187 -169 -282 -98 -99 -189 -150 -303 -172 -91 -17 -87 -33 5 -25 143 12 278 73 388 175 58 53 78 81 115 158 38 78 46 105 52 176 l7 83 130 5 130 5 -2 40 c-2 21 -14 69 -28 105 -26 68 -102 192 -120 196 -5 1 -67 -32 -137 -74z m189 -72 c23 -47 44 -101 45 -120 l3 -35 -105 -6 c-58 -3 -114 -9 -126 -13 -19 -7 -22 -3 -27 31 -4 21 -16 56 -27 78 -11 21 -17 42 -12 46 27 27 171 108 187 106 14 -1 32 -27 62 -87z" />
        <path class="Logo inner-wave-2" d="M2077 2293 c-4 -3 -7 -22 -7 -42 0 -77 -54 -203 -126 -295 -80 -101 -240 -201 -372 -232 -140 -33 -373 2 -474 71 -28 19 -38 22 -38 11 0 -8 27 -31 61 -51 124 -76 266 -110 420 -103 106 5 176 26 265 79 59 34 198 164 246 229 l25 34 67 -42 c37 -23 72 -40 77 -36 10 6 32 54 55 120 16 47 38 240 29 254 -7 11 -218 14 -228 3z m181 -46 c13 -15 -1 -141 -24 -209 -23 -69 -33 -74 -93 -42 -35 18 -59 24 -76 20 -31 -8 -31 2 -5 54 11 22 27 68 36 103 8 34 17 68 19 75 6 17 129 16 143 -1z" />
        <path class="Logo inner-wave-3" d="M847 1983 c24 -59 87 -162 137 -225 80 -100 205 -185 341 -230 61 -20 90 -23 220 -23 130 0 159 3 219 23 103 35 175 74 261 143 64 52 185 183 185 202 0 4 -91 61 -121 75 -3 1 -27 -25 -54 -59 -90 -115 -217 -203 -360 -250 -140 -47 -344 -24 -508 56 -108 53 -235 178 -299 294 -24 44 -40 39 -21 -6z m1281 -99 c12 -8 22 -19 22 -24 0 -18 -125 -142 -185 -185 -138 -98 -253 -135 -420 -135 -62 0 -138 7 -173 15 -62 15 -168 62 -159 71 2 3 37 -4 78 -16 98 -27 237 -36 325 -21 149 26 314 123 423 249 30 34 57 62 60 62 4 0 17 -7 29 -16z" />

        <path class="slogan async a" d="M567 743 c-4 -3 -7 -15 -7 -25 0 -13 7 -18 25 -18 14 0 25 5 25 10 0 6 14 10 30 10 23 0 30 -5 30 -19 0 -17 -8 -20 -52 -23 l-53 -3 0 -50 0 -50 80 0 80 0 3 65 c5 108 4 110 -82 110 -40 0 -76 -3 -79 -7z m103 -113 c0 -16 -7 -20 -30 -20 -23 0 -30 4 -30 20 0 16 7 20 30 20 23 0 30 -4 30 -20z" />
        <path class="slogan async s" d="M777 744 c-4 -4 -7 -20 -7 -36 0 -26 7 -32 55 -53 61 -27 71 -45 25 -45 -17 0 -30 5 -30 13 0 6 -11 12 -25 12 -23 0 -26 -4 -23 -30 3 -29 5 -30 57 -33 85 -5 101 2 101 44 0 33 -3 37 -55 59 -61 27 -71 45 -25 45 17 0 30 -5 30 -12 0 -7 11 -13 25 -13 22 0 25 4 23 25 -3 24 -7 25 -73 28 -39 1 -74 0 -78 -4z" />
        <path class="slogan async y" d="M993 661 c28 -78 30 -94 20 -120 -11 -30 -10 -31 17 -31 30 0 25 -10 99 188 l19 52 -28 0 c-26 0 -30 -6 -48 -57 l-19 -58 -17 58 c-16 52 -20 57 -46 57 l-29 0 32 -89z" />
        <path class="slogan async n" d="M1180 659 l0 -90 28 3 c26 3 27 5 28 67 0 64 8 77 47 80 4 1 7 -33 7 -74 l0 -75 30 0 31 0 -3 88 -3 87 -82 2 -83 2 0 -90z" />
        <path class="slogan async c" d="M1397 744 c-12 -12 -8 -149 5 -162 12 -12 114 -17 142 -6 24 9 21 54 -4 54 -27 0 -36 -3 -32 -12 1 -5 -11 -8 -28 -8 -30 0 -30 1 -30 55 0 52 1 55 25 55 16 0 25 -6 25 -15 0 -10 10 -15 31 -15 27 0 30 3 27 28 -3 26 -4 27 -78 30 -42 1 -79 0 -83 -4z" />

        <path class="slogan Sitecore left-paren" d="M1768 833 c-62 -71 -68 -182 -15 -260 16 -24 30 -33 50 -33 15 0 27 1 27 3 0 1 -11 24 -25 51 -39 77 -30 181 22 238 15 17 14 18 -14 18 -16 0 -37 -8 -45 -17z" />
        <path class="slogan Sitecore S" d="M1877 844 c-9 -10 -9 -76 1 -90 4 -6 35 -29 70 -53 48 -32 62 -47 62 -66 0 -22 -4 -25 -40 -25 -36 0 -40 3 -40 25 0 21 -5 25 -30 25 -27 0 -30 -3 -30 -33 0 -49 14 -57 101 -57 88 0 102 9 97 66 -3 34 -10 43 -68 84 -40 29 -66 55 -68 68 -3 20 1 22 37 22 36 0 41 -3 41 -23 0 -18 5 -22 28 -21 25 1 27 4 27 40 l0 39 -90 3 c-50 1 -94 0 -98 -4z" />
        <path class="slogan Sitecore i-dot" d="M2120 800 c0 -15 7 -20 25 -20 18 0 25 5 25 20 0 15 -7 20 -25 20 -18 0 -25 -5 -25 -20z" />
        <path class="slogan Sitecore i-body" d="M2120 660 c0 -89 0 -90 25 -90 25 0 25 1 25 90 0 89 0 90 -25 90 -25 0 -25 -1 -25 -90z" />
        <path class="slogan Sitecore t" d="M2230 775 c0 -16 -6 -25 -15 -25 -8 0 -15 -7 -15 -15 0 -8 7 -15 15 -15 12 0 15 -14 15 -69 0 -76 4 -81 66 -81 38 0 49 28 14 37 -17 5 -20 14 -20 59 0 46 3 54 18 54 10 0 19 7 19 15 0 8 -9 15 -19 15 -13 0 -18 7 -18 25 0 21 -5 25 -30 25 -25 0 -30 -4 -30 -25z" />
        <path class="slogan Sitecore e1" d="M2367 744 c-4 -4 -7 -42 -7 -85 0 -88 1 -89 85 -89 69 0 85 7 85 36 0 20 -5 24 -30 24 -16 0 -30 -4 -30 -10 0 -5 -11 -10 -25 -10 -18 0 -25 5 -25 20 0 17 7 20 43 20 58 0 69 10 65 56 l-3 39 -75 3 c-42 1 -79 0 -83 -4z m103 -44 c0 -15 -7 -20 -25 -20 -18 0 -25 5 -25 20 0 15 7 20 25 20 18 0 25 -5 25 -20z" />
        <path class="slogan Sitecore c" d="M2584 737 c-3 -8 -4 -47 -2 -88 l3 -74 69 -3 c75 -3 83 0 88 38 3 22 0 25 -22 22 -14 -2 -26 -8 -28 -13 -2 -5 -17 -9 -33 -9 -29 0 -29 1 -29 55 0 54 0 55 30 55 20 0 30 -5 30 -15 0 -10 10 -15 29 -15 28 0 30 5 22 53 0 4 -35 7 -76 7 -54 0 -77 -4 -81 -13z" />
        <path class="slogan Sitecore o" d="M2792 663 l3 -88 60 -3 c105 -5 106 -5 103 91 l-3 82 -83 3 -83 3 3 -88z m108 2 c0 -54 0 -55 -30 -55 -30 0 -30 1 -30 55 0 54 0 55 30 55 30 0 30 -1 30 -55z" />
        <path class="slogan Sitecore r" d="M3000 660 c0 -89 0 -90 25 -90 24 0 25 2 25 69 0 54 4 70 16 75 26 10 34 7 34 -14 0 -16 7 -20 31 -20 28 0 30 2 27 33 l-3 32 -77 2 -78 2 0 -89z" />
        <path class="slogan Sitecore e2" d="M3199 740 c-3 -72 1 -154 8 -161 6 -6 41 -9 79 -7 67 3 69 4 72 31 3 23 0 27 -22 27 -14 0 -26 -4 -26 -10 0 -5 -13 -10 -30 -10 -23 0 -30 5 -30 19 0 17 8 20 53 23 l52 3 0 45 0 45 -77 3 c-49 2 -78 -1 -79 -8z m111 -40 c0 -16 -7 -20 -30 -20 -23 0 -30 4 -30 20 0 16 7 20 30 20 23 0 30 -4 30 -20z" />
        <path class="slogan Sitecore right-paren" d="M3432 794 c40 -81 35 -155 -14 -227 l-19 -27 30 0 c84 0 121 173 58 269 -21 32 -34 41 -56 41 l-28 0 29 -56z" />

        <path class="slogan Arrow equal-top" d="M3680 730 c0 -19 7 -20 100 -20 93 0 100 1 100 20 0 19 -7 20 -100 20 -93 0 -100 -1 -100 -20z" />
        <path class="slogan Arrow equal-bottom" d="M3680 650 c0 -19 7 -20 100 -20 93 0 100 1 100 20 0 19 -7 20 -100 20 -93 0 -100 -1 -100 -20z" />
        <path class="slogan Arrow angle" d="M3930 752 c0 -11 16 -26 42 -39 l43 -20 -43 -22 c-28 -14 -42 -28 -42 -41 0 -18 6 -17 75 17 41 21 75 41 75 45 0 7 -132 78 -145 78 -3 0 -5 -8 -5 -18z" />

        <path class="slogan Experience E1" d="M4230 710 l0 -140 85 0 c78 0 85 2 85 20 0 18 -7 20 -56 20 l-55 0 3 43 c3 42 3 42 46 45 28 2 42 8 42 18 0 10 -12 14 -45 14 l-45 0 0 40 0 40 55 0 c48 0 55 2 55 20 0 18 -7 20 -85 20 l-85 0 0 -140z" />
        <path class="slogan Experience x" d="M4430 744 c0 -3 8 -18 18 -32 10 -15 22 -32 25 -38 4 -6 -7 -30 -23 -53 -17 -22 -30 -43 -30 -46 0 -3 13 -5 28 -5 20 0 33 7 42 25 7 14 16 25 20 25 4 0 13 -11 20 -25 9 -18 22 -25 42 -25 35 0 34 5 -1 55 l-29 40 30 42 29 43 -30 0 c-20 0 -34 -7 -41 -20 -6 -11 -15 -20 -20 -20 -5 0 -12 9 -15 20 -4 14 -15 20 -36 20 -16 0 -29 -3 -29 -6z" />
        <path class="slogan Experience p" d="M4640 630 l0 -120 25 0 c21 0 25 5 25 30 0 28 3 30 34 30 19 0 46 4 61 10 25 10 26 12 23 87 l-3 78 -82 3 -83 3 0 -121z m110 35 c0 -54 0 -55 -30 -55 -29 0 -30 1 -30 49 0 49 7 59 43 60 14 1 17 -8 17 -54z" />
        <path class="slogan Experience e2" d="M4857 744 c-4 -4 -7 -42 -7 -85 0 -88 1 -89 85 -89 69 0 85 7 85 36 0 20 -5 24 -30 24 -16 0 -30 -4 -30 -10 0 -5 -11 -10 -25 -10 -18 0 -25 5 -25 20 0 17 7 20 43 20 58 0 69 10 65 56 l-3 39 -75 3 c-42 1 -79 0 -83 -4z m103 -44 c0 -15 -7 -20 -25 -20 -18 0 -25 5 -25 20 0 15 7 20 25 20 18 0 25 -5 25 -20z" />
        <path class="slogan Experience r" d="M5060 659 l0 -89 30 0 30 0 0 69 c0 54 4 70 16 75 26 10 34 7 34 -14 0 -16 7 -20 30 -20 24 0 30 4 30 23 0 41 -12 46 -93 46 l-77 0 0 -90z" />
        <path class="slogan Experience i-dot" d="M5260 800 c0 -16 7 -20 30 -20 23 0 30 4 30 20 0 16 -7 20 -30 20 -23 0 -30 -4 -30 -20z" />
        <path class="slogan Experience i-body" d="M5260 660 l0 -90 30 0 30 0 0 90 0 90 -30 0 -30 0 0 -90z" />
        <path class="slogan Experience e3" d="M5367 744 c-4 -4 -7 -42 -7 -85 0 -88 1 -89 89 -89 59 0 81 11 81 42 0 13 -8 18 -30 18 -16 0 -30 -4 -30 -10 0 -5 -13 -10 -30 -10 -23 0 -30 4 -30 20 0 17 7 20 48 20 63 0 74 9 70 56 l-3 39 -75 3 c-42 1 -79 0 -83 -4z m103 -44 c0 -16 -7 -20 -30 -20 -18 0 -30 5 -30 13 0 20 10 27 37 27 16 0 23 -6 23 -20z" />
        <path class="slogan Experience n" d="M5580 660 c0 -89 0 -90 25 -90 24 0 25 2 25 70 0 54 3 70 14 70 8 0 21 3 30 6 14 5 16 -5 16 -70 l0 -76 30 0 31 0 -3 88 -3 87 -82 2 -83 2 0 -89z" />
        <path class="slogan Experience c" d="M5797 744 c-4 -4 -7 -42 -7 -85 0 -88 1 -89 85 -89 68 0 85 7 85 35 0 16 -7 24 -26 28 -16 3 -28 0 -31 -9 -3 -8 -16 -14 -29 -14 -22 0 -24 4 -24 55 0 52 1 55 25 55 16 0 25 -6 25 -15 0 -10 10 -15 31 -15 27 0 30 3 27 28 -3 26 -4 27 -78 30 -42 1 -79 0 -83 -4z" />
        <path class="slogan Experience e4" d="M6007 744 c-4 -4 -7 -42 -7 -85 0 -88 1 -89 85 -89 69 0 85 7 85 36 0 20 -5 24 -30 24 -16 0 -30 -4 -30 -10 0 -5 -11 -10 -25 -10 -18 0 -25 5 -25 20 0 17 7 20 43 20 58 0 69 10 65 56 l-3 39 -75 3 c-42 1 -79 0 -83 -4z m103 -44 c0 -15 -7 -20 -25 -20 -18 0 -25 5 -25 20 0 15 7 20 25 20 18 0 25 -5 25 -20z" />
        <path class="slogan Experience left-paren" d="M6243 803 c-39 -67 -39 -149 0 -215 23 -40 33 -48 57 -48 l29 0 -26 50 c-18 36 -25 65 -25 105 0 40 7 69 25 105 l26 50 -29 0 c-24 0 -34 -8 -57 -47z" />
        <path class="slogan Experience right-paren" d="M6396 805 c14 -24 28 -68 31 -97 4 -45 0 -62 -25 -110 l-29 -58 28 0 c32 0 62 33 80 87 29 90 -19 223 -80 223 l-30 0 25 -45z" />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
    };
  },
  props: {
    delay: {
      type: Number,
      default: 300,
    },
  },
  created() {
    // Wait {this.delay}ms before making the loading graphic visible.
    // If whatever other async operation completes within that timeframe,
    // then whichever parent component invoked the loading component will
    // presumably re-render without the loading component.
    // This helps prevent the loading graphic from too briefly "flashing" on the screen
    // causing an undesirable flicker for users.
    setTimeout(() => {
      this.visible = true;
    }, this.delay);
  },
};
</script>

<style scoped>
.jss-loading {
  display: block;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.1);
  text-align: center;
}

path,
rect {
  fill: #e11f27;
}

path {
  stroke: #222;
  stroke-width: 10;
  stroke-miterlimit: 1;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-duration: 5s;
  fill-opacity: 0;
}

path.border,
path.JSS,
path.Logo {
  stroke-dasharray: 26000;
  animation-name: draw;
  animation-delay: 0s;
}

path.slogan {
  stroke-dasharray: 2000;
  animation-name: draw-slogan;
  animation-delay: 0s;
}

@keyframes draw-slogan {
  0% {
    stroke-dashoffset: 2000;
    fill-opacity: 0;
  }
  50% {
    stroke-dashoffset: 0;
    stroke-dasharray: 0;
    fill-opacity: 0.75;
  }
  100% {
    stroke-dashoffset: 2000;
    fill-opacity: 0;
  }
}

@keyframes draw {
  0% {
    stroke-dashoffset: 26000;
    fill-opacity: 0;
  }
  50% {
    stroke-dashoffset: 5000;
    stroke-width: 1;
    fill-opacity: 0.75;
  }
  100% {
    stroke-dashoffset: 26000;
    fill-opacity: 0;
  }
}
</style>
