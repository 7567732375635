<template>
  <div id="app">
    <strong>TODO (static)</strong>
    <input
      type="checkbox"
      :checked="response.data.completed"
    >
    {{ response.data.title }}
    <strong>TODO (polling)</strong>
    <input
      type="checkbox"
      :checked="pollResponse.data.completed"
    >
    {{ pollResponse.data.title }}
  </div>
</template>

<script>
import todoService from '../services/todo';

export default {
  name: 'HJapi',
  data() {
    return {
      response: { data: {} },
      pollResponse: { data: {} },
    };
  },
  async created() {
    this.response = await todoService.fetch();
    this.pollResponse = await todoService.poll();
  },
};
</script>
