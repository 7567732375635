<!--
-->
<template>
  <div>
    <context-view :visible="contextViewVisible" />
    <!-- 'router-view' is a "global" component that is injected into the Vue component registry by vue-router. -->
    <router-view v-if="!languageIsChanging" />
    <div v-if="languageIsChanging" style="display:none" class="alert alert-info"></div>
  </div>
</template>

<script>
import ContextView from './ContextView';
import './assets/jqueryui/jquery-ui.min.css';
import './assets/css/owl.carousel.css';
import './assets/css/slick.css';
import './assets/css/slick-theme.css';
import './assets/css/hjstyle_master_hjstyle1.css';

// import './assets/css/hj_css_bundle2.min.css';
import './assets/css/jenDatePicker.css';
import './assets/css/banner.css';
import './assets/css/brandoverview.css';
import './assets/css/stays.css';
import './assets/css/eat.css';
import './assets/css/recommend.css';
import './assets/css/Slider.css';
import './assets/css/content.css';
// import './assets/css/fit.css';
import './assets/css/contentblock.css';
import './assets/css/society.css';
import './assets/css/rfp.css';
import './assets/css/destination.css';
import './assets/css/mobileSelect.css';
import './assets/css/aos.css';
import './assets/css/societybanner.css';
import './assets/css/deals.css';
// import './assets/css/societyDatePicker.css';
import './assets/css/map.css';
import './assets/css/ourstory.css';


import './assets/css/main.css';
import './assets/css/swiper.min.css';
import './assets/css/mobiscroll.jquery.min.css';
import './assets/css/navigation/header.css';
import './assets/css/navigation/footer.css';
import './assets/css/bootstrap.css';
// import './assets/js/owl.carousel.min.js';
// import './assets/js/skrollr.min.js';
// import './assets/js/slick.min.js';
// import './assets/js/markerclusterer.js';
// import './assets/js/moment.min.js';
// import './assets/js/jquery.daterangepicker.js';
// import './assets/js/navigation/header.js';
// import './assets/js/swiper.min.js';
// import './assets/js/societyparallax/popper.min.js';
// import './assets/js/societyparallax/aos.js';
// import './assets/js/societyparallax/societybanner.js';
// import './assets/js/mobiscroll.jquery.min.js';
// import './assets/js/map.js';
// import './assets/js/ics/ics.js';
// import './assets/js/mobileSelect.min.js';
// import './assets/js/banner.js';
// import './assets/js/rfp.js';
// import './assets/js/society.js';
// import './assets/js/booking.js';
// import './assets/js/blazy.min.js';
// import './assets/js/bootstrap.min.js';

export default {
  name: 'AppRoot',
  data() {
    return {
      languageIsChanging: false,
      contextViewVisible: false,
    };
  },
  methods: {
    changeAppLanguage(language) {
      // Changing languages is an async action, therefore the `languageIsChanging` property can be used
      // to show a loading/switching screen when language is being changed.
      const i18n = this.$i18n.i18next;
      if (i18n.language !== language) {
        this.languageIsChanging = true;

        i18n.changeLanguage(language, () => {
          this.languageIsChanging = false;
        });
      }
    },
    toggleContextView() {
      this.contextViewVisible = !this.contextViewVisible;
    },
  },
  provide() {
    // Use Vue's provide/inject capabilities to "provide" functions to
    // any descendant component that want to use/"inject" the functions.
    return {
      changeAppLanguage: this.changeAppLanguage,
      toggleContextView: this.toggleContextView,
    };
  },
  components: {
    ContextView,
  },
};
</script>
