import { render, staticRenderFns } from "./GraphQL-SSRDemo.vue?vue&type=template&id=3ded7a72&"
import script from "./GraphQL-SSRDemo.vue?vue&type=script&lang=js&"
export * from "./GraphQL-SSRDemo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "GraphQL-SSRDemo.vue"
export default component.exports