import { render, staticRenderFns } from "./HJDataDeals.vue?vue&type=template&id=39817cfe&"
import script from "./HJDataDeals.vue?vue&type=script&lang=js&"
export * from "./HJDataDeals.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "HJDataDeals.vue"
export default component.exports