<template>
  <div :id="this.uid" class="HJHeader">
  </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import { Image } from '@sitecore-jss/sitecore-jss-vue';
import axios from 'axios';
import { dataFetcher } from '../dataFetcher.js';
import { layoutFetcher, layoutFetchAction } from '../layoutFetcher.js';
import { graphqlToObject, test_searchMoreContentByType, searchMoreContentByType, getContentByType, testget, graphqlRemoveReserved, graphqlFetcher } from '../graphqlFetcher.js';
import $ from 'jquery';
import ApolloClient from 'apollo-boost';
import gql from 'graphql-tag';
import { uuid } from 'vue-uuid';
import {
  enableExperimentalFragmentVariables,
  disableExperimentalFragmentVariables,
} from 'graphql-tag';
import Vue from 'vue';
import VueMeta from 'vue-meta';
import { trackingApi } from '@sitecore-jss/sitecore-jss-tracking';
import config from '../temp/config';
import { serverBus } from '../event-bus.js';
import VueCookies from 'vue-cookies';
import sha256 from 'crypto-js/sha256';
import hmacSHA512 from 'crypto-js/hmac-sha512';
import Base64 from 'crypto-js/enc-base64';
//import { Base64 } from 'js-base64';
import { _testfunction } from '../hjutil.js';
import crypto from 'crypto';
 

Vue.use(VueCookies);

// set default config
VueCookies.config('7d');

export default {
  name: 'HJHeader',
  components: {
    ScText: Text,
  },
  props: {
    fields: {
      type: Object,
      default: function() {},
    },
    uid: {
      type: String,
      default: function() {
        return uuid.v1();
      },
    },
  },
  data() {
    return {
      loadingQueriesCount: 0,
      error: null,
    };
  },
  metaInfo() {
    if (
      this.$jss.routeData().fields &&
      this.$jss.routeData().fields.LoadingAnchor &&
      this.$jss.routeData().fields.LoadingAnchor.value &&
      this.$jss.routeData().fields.LoadingAnchor.value != ''
    ) {
      return {
        script: [{
			  innerHTML: `
			  $(document).ready(function(){ 			  
			  	var checkanchor = window.setInterval(function(){ 
					//console.log(this.$jss.routeData().fields.LoadingAnchor.value);
					if ($('#` + this.$jss.routeData().fields.LoadingAnchor.value + `').length > 0)
					{
						//$(document).scrollTop( $('#` + this.$jss.routeData().fields.LoadingAnchor.value + `').offset().top - $('.header-container').height() - 25);  
						//clearInterval(checkanchor);
					}
					
					//$(document).scrollTop($('#stay-suites-jen-150').offset().top);
					//clearInterval(checkanchor);
				});
			  });`,
			  type: 'text/javascript',
			  body: true
			}]
      };
    } else {
      return {
        script: [{
			  innerHTML: `
			  `,
			  type: 'text/javascript',
			  body: true
		}]
      };
    }
  },
  computed: {
    datasource: function() {
	  if (this.currentcontext != null)
		return this.currentcontext;
	  else
		return this.fields && this.fields.data && this.fields.data.datasource;
    },
    contextItem: function() {
      return this.fields.data && this.fields.data.contextItem;
    },
    templates: function() {
      return this.fields.data && this.fields.data.templates;
    },
  },
  mounted: function() {

    let vm = this;
    let currency = vm.$jss.currency();
	if (vm.$jss.prices() == null)
	{
		$.getJSON( "/api/gethotelprices", function( data ) {
			vm.$jss.store.setPrices(data);
			vm.hotel_prices = data;
		});			
	}
	else
	{
		vm.hotel_prices = vm.$jss.prices();
	}
	vm.hjconfig = vm.$jss.hjconfig();

    if (this.fields.data && this.fields.data.datasource) {
      //graphqlRemoveReserved(this.fields.data.datasource);
    vm.$root.hjheader = this.fields.data.datasource;
    }
    //serverBus.$emit('headerMounted', this.fields.data.hotels, this.$root);
    //vm.$root.templates = this.fields.data.templates;
	
	

  if (getCookie('hjsessionid') != null) {
	//console.log('cookie:' + getCookie('hjsessionid'));
  } 
	var routedata = vm.$jss.routeData();
	//console.log(routedata);
	
	var timestamp = Math.ceil(new Date().getTime() / 1000);
	var nonce = Math.ceil(Math.random()*10000000 + 10000000);
	//timestamp = 1584596210;
	nonce = "12345";
	var appId = "HJEN";
	var appSecret = "z4C07YFQ2mgZ8h7tZLZIGyqKGNWclD9U";
	var to = appId + nonce + timestamp + appSecret;
	var sign = Base64.stringify(sha256(to));
	//var sign = Base64.encode('6771aa3b920bac5c548d14268f26ccf80c6ebfeba701fec83892d44e2a9e4e42');
	//var _sign = Base64.encode(sha256(to));
	
const auth = {
        headers: {openapi_appid:appId, openapi_timestamp: timestamp, openapi_nonce: nonce, openapi_signature: sign} 
    }


var sharedSecret = "super-secret";
var query = "key=value";
var signature = crypto.createHmac("sha256", sharedSecret).update(query).digest("hex");
//console.log(signature);
const _auth = {
        headers: {'x-signature': signature, 'x-app-id': query } 
    }

  },
  updated() {},
  methods: {
	getDictionaryPhrase: function(key)
	{
		return getDictionaryPhrase(key, this);
	},
	fetchData: function(){
	},
  },
};
</script>
