<template>
  <div>
    <visitor-identification />
    <navigation />
    <div class="container-fluid pr-0 pl-0">
    <!--<div class="">-->
	  <div>
      <placeholder name="jss-main" :rendering="route" />
      <placeholder name="jss-main-parallax" :rendering="route"/>
	  </div>
    </div>
	<placeholder name="jss-main-footer" :rendering="route" id="jss-main-footer" />
  </div>
</template>

<script>
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue';
import Navigation from './Navigation';
import VisitorIdentification from './VisitorIdentification';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/app.css';

export default {
  name: 'Layout',
  props: {
    route: {
      type: Object,
      default: () => ({}),
    },
  },
  metaInfo() {
	//console.log(this.$jss);
    var a1 = 'aaaa';
    if (
      this.route.fields &&
      this.route.fields.CanonicalUrl &&
      this.route.fields.CanonicalUrl.value &&
      this.route.fields.CanonicalUrl.value != ''
    ) {
      return {
        title:
          (this.route.fields && this.route.fields.PageTitle && this.route.fields.PageTitle.value) ||
          'Page',
        meta: [
          {
            name: 'description',
            content:
              (this.route.fields &&
                this.route.fields.Description &&
                this.route.fields.Description.value) ||
              'description',
          },
          {
            name: 'keywords',
            content:
              (this.route.fields &&
                this.route.fields.Keywords &&
                this.route.fields.Keywords.value) ||
              'keywords',
          },
        ],
        link: [{ rel: 'canonical', href:  this.$jss.hjconfig().protocol + '://' + (this.$jss.hjconfig().domain.charAt(this.$jss.hjconfig().domain.trim().length-1) != '/' ? this.$jss.hjconfig().domain  : this.$jss.hjconfig().domain.substring(0,this.$jss.hjconfig().domain.trim().length-1)) + this.route.fields.CanonicalUrl.value }],
        /*,script: [{
			  innerHTML: `
			  var s_account = "{$t('from')}";
			  var wa = new Object();
			  wa.language = "en";
			  `,
			  type: 'text/javascript',
			  body: true
			}]*/
      };
    } else {
      return {
        title:
          (this.route.fields && this.route.fields.PageTitle && this.route.fields.PageTitle.value) ||
          'Page',
        meta: [
          {
            name: 'description',
            content:
              (this.route.fields &&
                this.route.fields.Description &&
                this.route.fields.Description.value) ||
              'description',
          },
          {
            name: 'keywords',
            content:
              (this.route.fields &&
                this.route.fields.Keywords &&
                this.route.fields.Keywords.value) ||
              'keywords',
          },
        ],
        /*,script: [{
			  innerHTML: `
			  var s_account = "{$t('from')}";
			  var wa = new Object();
			  wa.language = "en";
			  `,
			  type: 'text/javascript',
			  body: true
		}]*/
      };
    }
	},
  components: {
    Placeholder,
    Navigation,
    VisitorIdentification,
  },
};
</script>
