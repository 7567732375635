import axios from 'axios';
import ApolloClient from 'apollo-boost';
import gql from 'graphql-tag';
import {
  enableExperimentalFragmentVariables,
  disableExperimentalFragmentVariables,
} from 'graphql-tag';
//import graphqlToObject from '.\graphqlToObject.js';
import { 
getDictionaryPhrase,
searchMoreContentByType, getMoreContentByType, getTemplateId, getContentByType, getObject, graphqlFetcherTestSSR, graphqlFetcher } from './graphqlFetcher.js';
import $ from 'jquery';
import config from './temp/config';
import { getprice } from './personalise.js';


/**
 * Implements a data fetcher using Axios - replace with your favorite
 * SSR-capable HTTP or fetch library if you like. See HttpJsonFetcher<T> type
 * in sitecore-jss library for implementation details/notes.
 * @param {string} url The URL to request; may include query string
 * @param {any} data Optional data to POST with the request.
 */

export let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export function sitecoredatetodate(datestring)
{
	var date = new Date(datestring.substring(0,4)+"-"+datestring.substring(4,6)+"-"+datestring.substring(6,8));
	return date;
}

export function getContent(result, vm, type, iscarousel, carouselobject, allowaddindividualslides)
{
	if (iscarousel == undefined || iscarousel == null)
		iscarousel = false;
	if (carouselobject == undefined || carouselobject == null)
		carouselobject = null;
	if (allowaddindividualslides == undefined || allowaddindividualslides == null)
		allowaddindividualslides = false;
	
	if (allowaddindividualslides)
	{
		//$(carouselobject).slick('removeSlide', null, null, true);
		//carouselobject.removeAllSlides();
	}
	var s = '';
	if (type == "tabbedcontenttile")
	{
		if (result != undefined && result != null)
		{
			for (var i = 0; i < result.length; i++) {
			 if (carouselobject != null && allowaddindividualslides)
			  {
				//s = "";
				s += '<div class="swiper-slide firstslide">';
				if (result[i]["children"])
				{
					 for (var j = 0; j < result[i]["children"].length; j++) {
				  if (result[i]['children'][j]['templatename'] == 'hjimage')
				  {
					if (result[i]['children'][j]['image'] && result[i]['children'][j]['image'].src){
							s += '	<div class="gallery-container lazyload ';
							if (j>1){
								s += 'hidden';
							}
							//s += '" :data-bg="'+  result[i]['children'][j]['image'].src +'"></div>	';		
							s += '" style="background-image:url('+  result[i]['children'][j]['image'].src +');"></div>	';		
					 }
					}
					 }
				}
					s += '<h4 class="gallery-title"> ';
					s += result[i]['title'].value + '<br> ';	
					if (result[i]['property'] && result[i]['property']['children'] && result[i]['property']['children'].length > 0 && result[i]['property']['children'][0]['title']){
						s += '<span>'+ result[i]['property']['children'][0]['title'].value+ '</span> ';	
					}
					s += '</h4><div class="img-overlay2"></div>';
					s += '</div>';
					
			  }else{
			  
			  if (i%2){
				s += '<div class="col-12 col-md-5 ml-0 ml-md-3 item-block" :data-itemid="'+result[i]['id']+'">';
			  }else{
				s += '<div class="col-12 col-md-5 mr-0 mr-md-3 item-block" :data-itemid="'+result[i]['id']+'">'; 
			  }
				s += '					 <div class="container-fluid pr-0 pl-0">';
				s += '						<div class="row no-gutters justify-content-center">';
												
				s += '								<div class="col-12">';
					if (result[i]['children'] && result[i]['children'].length >0 ){
						for (var j = 0; j < result[i]['children'].length ; j++){
							if (result[i]['children'][j]['templatename'] == 'hjimage'){
								if (result[i]['children'][j]['image'].src != '' && j < 1){
									s += '<div class=" image-container lazyload" style="background-image:url('+ result[i]['children'][j]['image'].src+');"></div>';
								}
							}
						}
					}						
														
									
				s += '	</div></div>	';
				s += ' <div class="row no-gutters justify-content-center">';
				s += ' <div class="col-12 col-xl-7 text-left">';
					if (result[i]['property'] && result[i]['property']['children'] && result[i]['property']['children'].length > 0 && result[i]['property']['children'][0]['title']){
							s += '<h5>'+ result[i]['property']['children'][0]['title'].value+ '</h5> ';
							s += '<h4>'+result[i]['title'].value+'</h4>';						
					}
					else
					{
						s += '<h4 style="margin-top: 1.3rem;">'+result[i]['title'].value+'</h4>';
					}
				
				s += '<p>'+result[i]['description'].value+'</p>';
				if (result[i]['cta'].value && result[i]['cta'].value != '')
				{
					s += '<a href="'+result[i]['cta'].value+'" class="view-details">'+getDictionaryPhrase('ViewDetails', vm)+' <div class="vd-arrow-down-div"></div></a>';
				}
				s += '</div>';
													
				// test commit							
				s += '<div class="col-12 col-md-5 text-right mobile-hidden">';
					if (result[i]["price"] && result[i]["price"].value && result[i]["price"].value >= 0)
					{
						s += '<h3>'+ getDictionaryPhrase('From', vm) +' <span>'+ result[i]["price"].value.toLocaleString() +' <small>'+ vm.$jss.currency() +'</small></span> <br/> '+ getDictionaryPhrase('PerNight', vm) +'</h3>';
						if (result[i]['room'] && result[i]['room']['children'] && result[i]['room']['children'].length > 0)
						{
							s += '<a href="#" class="book btn btn-primary hasPrice" data-urlalias="' + (result[i]['urlalias'] ? result[i]['urlalias'].value : '') + '" data-roomtypecode="' + result[i]['room']['children'][0]['roomtypecode'] + '"  data-rateplancode="' + result[i]['room']['children'][0]['rateplancode'] + '" data-roomcode="' + result[i]['room']['children'][0]['roomcode'] + '" data-bookingurl="' + result[i]['bookingurl'] + '" data-offerurl="' + [result[i]['offerurl'] && result[i]['offerurl'].value ? result[i]['offerurl'].value : ''] + '">' + getDictionaryPhrase('Book', vm) + '</a>';
						}
						else
						{
							if (result[i]['templatename'] && result[i]['templatename'].toLowerCase() === "hjdealstay")
							{
								s += '<a href="#" class="book btn btn-primary hasPrice" data-urlalias="' + (result[i]['urlalias'] ? result[i]['urlalias'].value : '') + '" data-roomtypecode=""  data-rateplancode="" data-roomcode="" data-bookingurl="' + (result[i]['bookingurl'] ? result[i]['bookingurl'] : '') + '" data-offerurl="' + [result[i]['offerurl'] && result[i]['offerurl'].value ? result[i]['offerurl'].value : ''] + '">' + getDictionaryPhrase('Book', vm) + '</a>';
							}
							else
							{
								//s += '<a href="#" class="btn btn-primary hasPrice">'+getDictionaryPhrase('Book', vm) + result[i]['id']+'</a>';
								if (result[i]['templatename'] && result[i]['templatename'].toLowerCase() === "hjdealeat" && result[i]['restaurant'] && result[i]['restaurant']['children']  && result[i]['restaurant']['children'].length > 0 && result[i]['restaurant']['children'][0]['isbooking'] && result[i]['restaurant']['children'][0]['isbooking'] === '1' && result[i]['restaurant']['children'][0]['bookingurl'] && result[i]['restaurant']['children'][0]['bookingurl'] != '')
								{
									s += '<a href="' + (result[i]['restaurant']['children'][0]['bookingurl'] ? result[i]['restaurant']['children'][0]['bookingurl'] : '#') +  '" class="btn btn-primary hasPrice" target="_blank">'+getDictionaryPhrase('Reserve', vm)+'</a>';
								}
							}
						}
						
					}
					else if (result[i]["price"] && result[i]["price"] >= 0)
					{
						s += '<h3>'+ getDictionaryPhrase('From', vm) +' <span>'+ result[i]["price"].toLocaleString() +' <small>'+ vm.$jss.currency() +'</small></span> <br/> '+ getDictionaryPhrase('PerNight', vm) +'</h3>';
						if (result[i]['room'] && result[i]['room']['children'] && result[i]['room']['children'].length > 0 && result[i]['bookingurl'])
						{
							s += '<a href="#" class="book btn btn-primary hasPrice" data-urlalias="' + (result[i]['urlalias'] ? result[i]['urlalias'].value : '') + '" data-roomtypecode="' + result[i]['room']['children'][0]['roomtypecode'] + '"  data-rateplancode="' + result[i]['room']['children'][0]['rateplancode'] + '" data-roomcode="' + result[i]['room']['children'][0]['roomcode'] + '" data-bookingurl="' + result[i]['bookingurl'] + '" data-offerurl="' + (result[i]['offerurl'] && result[i]['offerurl'].value ? result[i]['offerurl'].value : '') + '">' + getDictionaryPhrase('Book', vm) + '</a>';
						}
						else
						{
							if (result[i]['templatename'] && result[i]['templatename'].toLowerCase() === "hjdealstay")
							{
								s += '<a href="#" class="book btn btn-primary hasPrice" data-urlalias="' + (result[i]['urlalias'] ? result[i]['urlalias'].value : '') + '" data-roomtypecode=""  data-rateplancode="" data-roomcode="" data-bookingurl="' + (result[i]['bookingurl'] ? result[i]['bookingurl'] : '') + '" data-offerurl="' + [result[i]['offerurl'] && result[i]['offerurl'].value ? result[i]['offerurl'].value : ''] + '">' + getDictionaryPhrase('Book', vm) + '</a>';
							}
							else
							{
								//s += '<a href="#" class="btn btn-primary hasPrice">'+getDictionaryPhrase('Book', vm)+'</a>';
								if (result[i]['templatename'] && result[i]['templatename'].toLowerCase() === "hjdealeat" && result[i]['restaurant'] && result[i]['restaurant']['children']  && result[i]['restaurant']['children'].length > 0 && result[i]['restaurant']['children'][0]['isbooking'] && result[i]['restaurant']['children'][0]['isbooking'] === '1' && result[i]['restaurant']['children'][0]['bookingurl'] && result[i]['restaurant']['children'][0]['bookingurl'] != '')
								{
									s += '<a href="' + (result[i]['restaurant']['children'][0]['bookingurl'] ? result[i]['restaurant']['children'][0]['bookingurl'] : '#') +  '" class="btn btn-primary hasPrice" target="_blank">'+getDictionaryPhrase('Reserve', vm)+'</a>';
								}
							}
						}
					}
					else 
					{
						if (result[i]['room'] && result[i]['room']['children'] && result[i]['room']['children'].length > 0 && result[i]['bookingurl'])
						{
							s += '<a href="#" class="book btn btn-primary hasPrice book" data-urlalias="' + (result[i]['urlalias'] ? result[i]['urlalias'].value : '') + '" data-roomtypecode="' + (result[i]['room']['children'][0]['roomtypecode'] ? result[i]['room']['children'][0]['roomtypecode'] : '')  + '"  data-rateplancode="' + (result[i]['room']['children'][0]['rateplancode'] ? result[i]['room']['children'][0]['rateplancode'] : '') + '" data-roomcode="' + (result[i]['room']['children'][0]['roomcode'] ? result[i]['room']['children'][0]['roomcode'] : '') + '" data-bookingurl="' + (result[i]['bookingurl'] ? result[i]['bookingurl'] : '') + '" data-offerurl="' + (result[i]['offerurl'] && result[i]['offerurl'].value ? result[i]['offerurl'].value : '') + '">' + getDictionaryPhrase('Book', vm) + '</a>';
						}
						else
						{
							if (result[i]['templatename'] && result[i]['templatename'].toLowerCase() === "hjdealstay")
							{
								s += '<a href="#" class="book btn btn-primary hasPrice" data-urlalias="' + (result[i]['urlalias'] ? result[i]['urlalias'].value : '') + '" data-roomtypecode=""  data-rateplancode="" data-roomcode="" data-bookingurl="' + (result[i]['bookingurl'] ? result[i]['bookingurl'] : '') + '" data-offerurl="' + [result[i]['offerurl'] && result[i]['offerurl'].value ? result[i]['offerurl'].value : ''] + '">' + getDictionaryPhrase('Book', vm) + '</a>';
							}
							else
							{
								//s += '<a href="#" class="btn btn-primary">'+getDictionaryPhrase('Book', vm) +'</a>';
								if (result[i]['templatename'] && result[i]['templatename'].toLowerCase() === "hjdealeat" && result[i]['restaurant'] && result[i]['restaurant']['children']  && result[i]['restaurant']['children'].length > 0 && result[i]['restaurant']['children'][0]['isbooking'] && result[i]['restaurant']['children'][0]['isbooking'] === '1' && result[i]['restaurant']['children'][0]['bookingurl'] && result[i]['restaurant']['children'][0]['bookingurl'] != '')
								{
									s += '<a href="' + (result[i]['restaurant']['children'][0]['bookingurl'] ? result[i]['restaurant']['children'][0]['bookingurl'] : '#') +  '" class="btn btn-primary" target="_blank">'+getDictionaryPhrase('Reserve', vm)+'</a>';
								}
							}
						}
					}
				s += '</div>';

				s += '<div class="col-12 desktop-hidden mt-4 mb-5">';
				
				
					if (result[i]["price"] && result[i]["price"].value && result[i]["price"].value >= 0)
					{
						if (result[i]['room'] && result[i]['room']['children'] && result[i]['room']['children'].length > 0 && result[i]['bookingurl'])
						{
							s += '<a href="#" class="book btn btn-primary hasPrice" data-urlalias="' + (result[i]['urlalias'] ? result[i]['urlalias'].value : '') + '" data-roomtypecode="' + result[i]['room']['children'][0]['roomtypecode'].value + '"  data-rateplancode="' + result[i]['room']['children'][0]['rateplancode'].value + '" data-roomcode="' + result[i]['room']['children'][0]['roomcode'].value + '" data-bookingurl="' + result[i]['bookingurl'] + '" data-offerurl="' + (result[i]['offerurl'] && result[i]['offerurl'].value ? result[i]['offerurl'].value : '') + '">' + getDictionaryPhrase('Book', vm) + '</a>';
							s += '<h3 class="text-left">'+ getDictionaryPhrase('From', vm) +'<br/><span>'+ result[i]["price"].value.toLocaleString() +'<small>'+ vm.$jss.currency() +'</small></span> <br/> '+ getDictionaryPhrase('PerNight', vm) +'</h3>';
						}
						else
						{
							if (result[i]['templatename'] && result[i]['templatename'].toLowerCase() === "hjdealstay")
							{
								s += '<a href="#" class="book btn btn-primary hasPrice" data-urlalias="' + (result[i]['urlalias'] ? result[i]['urlalias'].value : '') + '" data-roomtypecode=""  data-rateplancode="" data-roomcode="" data-bookingurl="' + (result[i]['bookingurl'] ? result[i]['bookingurl'] : '') + '" data-offerurl="' + [result[i]['offerurl'] && result[i]['offerurl'].value ? result[i]['offerurl'].value : ''] + '">' + getDictionaryPhrase('Book', vm) + '</a>';
							}
							else
							{
								//s += '<a href="#" class="btn btn-primary hasPrice">'+getDictionaryPhrase('Book', vm)+'</a>';
								if (result[i]['templatename'] && result[i]['templatename'].toLowerCase() === "hjdealeat" && result[i]['restaurant'] && result[i]['restaurant']['children']  && result[i]['restaurant']['children'].length > 0 && result[i]['restaurant']['children'][0]['isbooking'] && result[i]['restaurant']['children'][0]['isbooking'] === '1' && result[i]['restaurant']['children'][0]['bookingurl'] && result[i]['restaurant']['children'][0]['bookingurl'] != '')
								{
									s += '<a href="' + (result[i]['restaurant']['children'][0]['bookingurl'] ? result[i]['restaurant']['children'][0]['bookingurl'] : '#') +  '" class="btn btn-primary hasPrice" target="_blank">'+getDictionaryPhrase('Reserve', vm)+'</a>';
								}
							}
							s += '<h3 class="text-left">'+ getDictionaryPhrase('From', vm) +'<br/><span>'+ result[i]["price"].value.toLocaleString() +'<small>'+ vm.$jss.currency() +'</small></span> <br/> '+ getDictionaryPhrase('PerNight', vm) +'</h3>';
						}
					}
					else if (result[i]["price"] && result[i]["price"] >= 0)
					{
						if (result[i]['room'] && result[i]['room']['children'] && result[i]['room']['children'].length > 0 && result[i]['bookingurl'])
						{
							s += '<a href="#" class="book btn btn-primary hasPrice" data-urlalias="' + (result[i]['urlalias'] ? result[i]['urlalias'].value : '') + '" data-roomtypecode="' + result[i]['room']['children'][0]['roomtypecode'].value + '"  data-rateplancode="' + result[i]['room']['children'][0]['rateplancode'].value + '" data-roomcode="' + result[i]['room']['children'][0]['roomcode'].value + '" data-bookingurl="' + result[i]['bookingurl'] + '" data-offerurl="' + (result[i]['offerurl'] && result[i]['offerurl'].value ? result[i]['offerurl'].value : '') + '">' + getDictionaryPhrase('Book', vm) + '</a>';
						}
						else
						{
							if (result[i]['templatename'] && result[i]['templatename'].toLowerCase() === "hjdealstay")
							{
								s += '<a href="#" class="book btn btn-primary hasPrice";" data-urlalias="' + (result[i]['urlalias'] ? result[i]['urlalias'].value : '') + '" data-roomtypecode=""  data-rateplancode="" data-roomcode="" data-bookingurl="' + (result[i]['bookingurl'] ? result[i]['bookingurl'] : '') + '" data-offerurl="' + [result[i]['offerurl'] && result[i]['offerurl'].value ? result[i]['offerurl'].value : ''] + '">' + getDictionaryPhrase('Book', vm) + '</a>';
							}
							else
							{
								//s += '<a href="#" class="btn btn-primary hasPrice">'+getDictionaryPhrase('Book', vm)+'</a>';
								if (result[i]['templatename'] && result[i]['templatename'].toLowerCase() === "hjdealeat" && result[i]['restaurant'] && result[i]['restaurant']['children']  && result[i]['restaurant']['children'].length > 0 && result[i]['restaurant']['children'][0]['isbooking'] && result[i]['restaurant']['children'][0]['isbooking'] === '1' && result[i]['restaurant']['children'][0]['bookingurl'] && result[i]['restaurant']['children'][0]['bookingurl'] != '')
								{
									s += '<a href="' + (result[i]['restaurant']['children'][0]['bookingurl'] ? result[i]['restaurant']['children'][0]['bookingurl'] : '#') +  '" class="btn btn-primary hasPrice" target="_blank">'+getDictionaryPhrase('Reserve', vm)+'</a>';
								}
							}
							// s += '<h3 class="text-left">'+ getDictionaryPhrase('From', vm) +'<br/><span>'+ result[i]["price"] >= 0 ? result[i]["price"].value.toLocaleString() : result[i]["price"] +'<small>'+ vm.$jss.currency() +'</small></span> <br/> '+ getDictionaryPhrase('PerNight', vm) +'</h3>';
							s += `<h3 class="text-left">
							${getDictionaryPhrase('From', vm)}<br/>
								<span>
									${
										`${`${result[i].price} >= 0}`}`
										? `${result[i].price.toLocaleString()}`
										: `${result[i].price}`
									}
									<small>${vm.$jss.currency()}</small>
								</span><br/> 
								${getDictionaryPhrase('PerNight', vm)}
							</h3>`;
						}
					}
					else 
					{
						if (result[i]['room'] && result[i]['room']['children'] && result[i]['room']['children'].length > 0 && result[i]['bookingurl'])
						{
							s += '<a href="#" class="book btn btn-primary hasPrice" data-urlalias="' + (result[i]['urlalias'] ? result[i]['urlalias'].value : '') + '" data-roomtypecode="' + result[i]['room']['children'][0]['roomtypecode'].value + '"  data-rateplancode="' + result[i]['room']['children'][0]['rateplancode'].value + '" data-roomcode="' + result[i]['room']['children'][0]['roomcode'].value + '" data-bookingurl="' + result[i]['bookingurl'] + '" data-offerurl="' + (result[i]['offerurl'] && result[i]['offerurl'].value ? result[i]['offerurl'].value : '') + '">' + getDictionaryPhrase('Book', vm) + '</a>';
						}
						else
						{
							if (result[i]['templatename'] && result[i]['templatename'].toLowerCase() === "hjdealstay")
							{
								s += '<a href="#" class="book btn btn-primary hasPrice" data-urlalias="' + (result[i]['urlalias'] ? result[i]['urlalias'].value : '') + '" data-roomtypecode=""  data-rateplancode="" data-roomcode="" data-bookingurl="' + (result[i]['bookingurl'] ? result[i]['bookingurl'] : '') + '" data-offerurl="' + [result[i]['offerurl'] && result[i]['offerurl'].value ? result[i]['offerurl'].value : ''] + '">' + getDictionaryPhrase('Book', vm) + '</a>';
							}
							else
							{
								if (result[i]['templatename'] && result[i]['templatename'].toLowerCase() === "hjdealeat" && result[i]['restaurant'] && result[i]['restaurant']['children']  && result[i]['restaurant']['children'].length > 0 && result[i]['restaurant']['children'][0]['isbooking'] && result[i]['restaurant']['children'][0]['isbooking'] === '1' && result[i]['restaurant']['children'][0]['bookingurl'] && result[i]['restaurant']['children'][0]['bookingurl'] != '')
								{
									s += '<a href="' + (result[i]['restaurant']['children'][0]['bookingurl'] ? result[i]['restaurant']['children'][0]['bookingurl'] : '#') +  '" class="btn btn-primary" target="_blank">'+getDictionaryPhrase('Reserve', vm)+'</a>';
								}
							}
						}
					}
				s += '									</div>';
				s += '								</div>';
				s += '							</div>';
				s += '							<div class="itemid" style="display:none">'+result[i]["id"] +'</div>';
				s += '					</div>';
			  
			  }
			  if (carouselobject != null && allowaddindividualslides)
			  {
				//$(carouselobject).slick('slickAdd', s);
			  }
			}
		
		}
		 if (carouselobject != null && allowaddindividualslides)
		  {
			  carouselobject.html(s); //insert to swiper wrapper
			  if($('#galleryTab0')) $('#galleryTab0').click();
			  carouselobject.addClass('loadcompleted'); 
		  }
	}
	else if (type == "contentblocklist")
	{	
		for (var i = 0; i < result.length; i++) {
		  s += '<div class="contenttile bb" data-itemid="' + result[i]['id'] + '"' + '><div class="hero hovertranslate">';
		  for (var j = 0; j < result[i]["children"].length; j++) {
			  if (result[i]['children'][j]['templatename'] == 'hjimage')
			  {
				if (result[i]['children'][j]['image'] && result[i]['children'][j]['image'].src)
					//s += '<img src="' + result[i]['children'][j]['image'].src + '" alt="" />';
					s += '<img class="lazyload" data-src="' + result[i]['children'][j]['image'].src + '" alt="" />';
				else
					s += '<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8z/C/HgAGgwJ/lK3Q6wAAAABJRU5ErkJggg==" alt="" />';
			  }
			s += '<div class="owl-content">';

			s += '<div class="grid-content">';
			s += '<div class="title" style="line-height: 1em;">$1,099 <span style="font-size:0.8em">HKD</span> Nightly</div>';
			s += '</div>';
			s += '</div>';
			s += '<div class="icon-tags">';
			s += '<div class="fav-btn icon"></div>';
			s += '</div>';
		  }
		  s += '</div>';
		  s += '<div class="description">';
		  s += '<div class="item-sections item-section-left">';
		  s += '<div class="item-section-intro">';
		  if (result[i]['property'] && result[i]['property']['children'] && result[i]['property']['children'].length > 0 && result[i]['property']['children'][0]['title'])
		  {
			s += result[i]['property']['children'][0]['title'];
		  }
		  s += '</div>';
			s+= '<div class="item-section-title">' + result[i]["title"].value + '</div>' ;
			s+= '<div class="item-section-desc">' + result[i]["description"].value + '</div>';
			/*s+= '<a class="viewDetails">' + 'view details' + '</a>' ;*/ //Comment out for details
		s += '</div>';
		s += '<div class="actionbutton item-sections item-section-right">';
		if (result[i]["price"] && result[i]["price"].value && result[i]["price"].value >= 0)
		{
			s += '<div class="pricing-tag">' + getDictionaryPhrase('From', vm) + '<span class="price">' + result[i]["price"].value.toLocaleString() + '</span> <span style="color:#343944;">' +  vm.$jss.currency() + '</span>' +  getDictionaryPhrase('PerNight', vm) + ' </div>' ;
		}
		else if (result[i]["price"] && result[i]["price"] >= 0)
		{
			s += '<div class="pricing-tag">' + getDictionaryPhrase('From', vm) + '<span class="price">' + result[i]["price"].toLocaleString() + '</span> <span style="color:#343944;">' +  vm.$jss.currency() + '</span>' +  getDictionaryPhrase('PerNight', vm) + ' </div>' ;
		}
		s += '<input type="button" value="' + getDictionaryPhrase('Reserve', vm) + '"';
		if (result[i]['templatename'] == 'hjevent'){
		s += ' class="blue-btn"';
		}
		s += ' />';
		s += '</div>';
		  //+result[i]["title"].value + '</div>';	  
		  s += '</div>';
		  s += '</div>';
		}
	}
	return s;
}


export function loadmore(targetdata, e, type, vm, settingspath, overridegql, templatefilter, property, skipparent, pagesize, lastitemid) {
  const client = new ApolloClient({
    uri: config.sitecoreApiHost + '/sitecore/api/' + config.jssAppName + '?sc_apikey=' + config.sitecoreSCApiKey,
    defaultOptions: {
      fetchPolicy: 'no-cache',
    },
  });

  var updateResults = function(data, _vm, _ar, type, property, event, context, isloadmore, graphqlresults) {

	
	var result = getContentByType(_ar, type, _vm, false, true, _vm.$jss.travellertype(), false, 3, property);
	for(var i=0; i < result.length; i++)
	{
		graphqlresults.push(result[i]);
	}
	
	//console.log(targetdata);
	var s = getContent(result, vm, "tabbedcontenttile");
	var tab = $(event.target).parents('.tab-contents')[0];
	if ($(tab).find('.contenttile').length > 0)
	{
		$(s).insertAfter($(tab).find('.contenttile').last());
	}
	else
	{
		if ($(tab).find('.filter_wrapper').length > 0)
		{
			$(s).insertAfter($(tab).find('.filter_wrapper'));
		}
		else
		{
			$(s).insertAfter($(tab));
		}
	
	}
		
	
  };


	var lastpage = parseInt(targetdata.length/pagesize);
	searchMoreContentByType(targetdata, type, vm, updateResults, pagesize, lastpage, property, true, vm.$jss.travellertype(), null, "", e);	
}

export function getContentByProperty(targetdata, e, type, vm, property, eventtype, layouttype, iscarousel) {
  const client = new ApolloClient({
    uri: config.sitecoreApiHost + '/sitecore/api/' + config.jssAppName + '?sc_apikey=' + config.sitecoreSCApiKey,
    defaultOptions: {
      fetchPolicy: 'no-cache',
    },
  });


	if (iscarousel == undefined || iscarousel == null)
		iscarousel = false;
  
  if (layouttype == "tabbedcontentlist")
  {
	  var updateResults = function(data, _vm, _ar, type, property, event, context, isloadmore, graphqlresults) {
		var result = getContentByType(_ar, type, _vm, false, true, _vm.$jss.travellertype(), false, -1, property, eventtype);
		graphqlresults = [];
		for(var i=0; i < result.length; i++)
		{
			if (property != undefined && property != "")
			{
				if (result[i]["property"] != null)
				{
					var result_property = result[i]["property"].value.replace(/[^(0-9A-Za-z)]/g, "");
					if (result_property.toString().trim() === property.toString().trim())
					{
						graphqlresults.push(result[i]);
					}
				}
			}
			else
			{
				graphqlresults.push(result[i]);
			}
		}

		var s = getContent(result, vm, layouttype);
		var tab = $(e.target).parents('.tab-contents')[0];
		$(tab).find('.contenttile').remove();
		$(s).insertAfter($(tab).find('.filter_wrapper').last());
		//$(s).insertAfter($(tab).find('.contenttile').last());
		console.log('sss');
		$(tab).attr("loadcompleted");
	  };		
		if (eventtype != null && eventtype != undefined)
		{
			searchMoreContentByType(targetdata, type, vm, updateResults, 6, null, property, true, vm.$jss.travellertype(), null, "", e);	
		}
			searchMoreContentByType(targetdata, type, vm, updateResults, null, null, property, true, vm.$jss.travellertype(), null, "", e);	
  }
  else if (layouttype == "contentblocklist")
  {
	  var updateResults = function(data, _vm, _ar, type, property, event, context, isloadmore, graphqlresults) {
		var div = $(event.target).parents('.HJContentBlockList')[0];
		var id = $(div).attr('id');
		var eventtype = $('#' + id + ' .filter.eventtype').find("option:selected").val();
		var result = getContentByType(_ar, type, _vm, false, true, _vm.$jss.travellertype(), false, -1, property, eventtype);
		graphqlresults = [];
		
		for(var i=0; i < result.length; i++)
		{
			/*if (property != undefined && property != "")
			{
				if (result[i]["property"] != null)
				{
					var result_property = result[i]["property"].value.replace(/[^(0-9A-Za-z)]/g, "");
					if (result_property.toString().trim() === property.toString().trim())
					{
						targetdata.push(result[i]);
					}
				}
			}
			else
			{*/
				graphqlresults.push(result[i]);
			/*}*/
		}

		var s = getContent(result, vm, layouttype);
		var div = $(event.target).parents('.HJContentBlockList')[0];
		var id = $(div).attr("id");
		if (iscarousel)
		{
			$(div).find('.contenttile').remove();
			$('#' + id + '_owl-carousel').html(s);
		}
		else
		{
			$(div).find('.contenttile').remove();
			$(s).insertAfter($(div).find('.filter_wrapper').last());
		}
		$(div).addClass("loadcompleted");
		if (iscarousel)
		{
			$('#' + id + '_owl-carousel').owlCarousel('destroy');
			$('#' + id + '_owl-carousel').owlCarousel({ 
				loop:true, 
				margin:10, 
				nav:true,
				responsiveClass:true,
				responsive:{
					0:{
						items:1,
						nav:true
					},
					600:{
						items:2,
						nav:true
					},
					1000:{
						items:3,
						loop:true
					}
				}
			})  
		}		
	  };
		// load more items due to search issue
		searchMoreContentByType(targetdata, type, vm, updateResults, null, null, property, true, vm.$jss.travellertype(), null, "", e);	
  }
  else if (layouttype == "tabbedcontenttile")
  {
	  var updateResults = function(data, _vm, _ar, type, property, event, context, isloadmore, graphqlresults) {
		var result = getContentByType(_ar, type, _vm, false, true, _vm.$jss.travellertype(), false, -1, property, eventtype);
		graphqlresults = [];
		var s = "";
		if (result != undefined && result != null)
		{
			for(var i=0; i < result.length; i++)
			{
				if (property != undefined && property != "")
				{
					if (result[i]["property"] != null)
					{
						var result_property = result[i]["property"].value.replace(/[^(0-9A-Za-z)]/g, "");
						if (result_property.toString().trim() === property.toString().trim())
						{
							graphqlresults.push(result[i]);
						}
					}
				}
				else
				{
					graphqlresults.push(result[i]);
				}
			}
			
			s = getContent(result, vm, layouttype);
		}
			
		var tab = $(e.target).parents('.tab-contents')[0];
		$(tab).find('.item-block').remove();
		$(s).insertAfter($(tab).find('.filter_wrapper').last());
		//$(s).insertAfter($(tab).find('.contenttile').last());
		$(tab).addClass("loadcompleted");
	  };		
		if (eventtype != null && eventtype != undefined)
		{
			searchMoreContentByType(targetdata, type, vm, updateResults, 6, null, property, true, vm.$jss.travellertype(), null, "", e);	
		}
			searchMoreContentByType(targetdata, type, vm, updateResults, null, null, property, true, vm.$jss.travellertype(), null, "", e);	
  }
  
}


export function test_loadmore() {
  alert('test_loadmore');
}

