<template>
  <div :id="this.uid" class="_HJCarousel">
  </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import { Image } from '@sitecore-jss/sitecore-jss-vue';
import axios from 'axios';
import { dataFetcher } from '../dataFetcher.js';
import { layoutFetcher, layoutFetchAction } from '../layoutFetcher.js';
import { graphqlToObject, getObjectById, graphqlFetcher } from '../graphqlFetcher.js';
//import $ from 'jquery';
import ApolloClient from 'apollo-boost';
import gql from 'graphql-tag';
import { uuid } from 'vue-uuid';
import {
  enableExperimentalFragmentVariables,
  disableExperimentalFragmentVariables,
} from 'graphql-tag';
import Vue from 'vue';
import VueMeta from 'vue-meta';
import { trackingApi } from '@sitecore-jss/sitecore-jss-tracking';
import config from '../temp/config';

export default {
  name: '_HJCarousel',
  components: {
    ScText: Text,
  },
  props: {
    fields: {
      type: Object,
      default: function(){},
    },
    currentcontext: {
      type: Object,
      default: null,
    },
    uid: {
      type: String,
      default: function(){return uuid.v1();},
    },
  },
  data() {
    return {
      loadingQueriesCount: 0,
      error: null,
    };
  },
  computed: { 
  },
  mounted: function() {
  
  },
  updated() {

    let vm = this;

  },
  methods: {
    isproperty: function(name) {
		if (this.fields != null && this.fields[name] != undefined)
		{
			return this.fields[name];
		}
		else
		{
			if (this.fields && this.fields.data && this.fields.data.datasource && this.fields.data.datasource.fields)
			{
				for (var i=0; i < this.fields.data.datasource.fields.length; i++)
				{
					if (this.fields.data.datasource.fields[i].name == name)
					{
						if (this.fields.data.datasource.fields[i].targetItems && 
							this.fields.data.datasource.fields[i].targetItems.length > 0) {
							if (this.fields.data.datasource.fields[i].targetItems[0].textField)
								return this.fields.data.datasource.fields[i].targetItems[0].textField.value;
							else if (this.fields.data.datasource.fields[i].targetItems[0].title)
								return this.fields.data.datasource.fields[i].targetItems[0].textField.title;
							else
								return "";
						}
						else
						{
							return this.fields.data.datasource.fields[i].value;
						}
					}
				}
			}
		}
		return null;
		var o = {};
		o.value = '';
		return o;
	},
  },
}
</script>
