<template>
  <!-- <div :id="this.uid" class="HJCalendar components" :class="[isbooking == '1'?'':'hidden']"> -->
    <div :id="this.uid" class="HJCalendar components">
   <!--<div class="maxWidth">
    <div :id="uid+'_jenDatePicker'">
      <div class="jenDates">
        <input id="startDate" type="text" name="fromDate" value="25 Nov (Mon)" readonly/>
        <input id="endDate" type="text" name="toDate" value="26 Nov (Tue)" readonly/>
        <span class="jenDays">1 {{getDictionaryPhrase('Book1Night')}}</span>
      </div>
      <div id="jenCalender">
      </div>
      <div id="jenGuests">
        <div class="jenGuestDetails">{{getDictionaryPhrase('Book1Room1Adult0Children')}}</div>
        <input type="hidden" name="rooms" value="1" />
        <input type="hidden" name="adults" value="1" />
        <input type="hidden" name="children" value="0" />
      </div>
      <div id="jenBookingDetails">
        <div>
          <div class="jenAdultDetails">
            <span class="jenAdultLabel">{{getDictionaryPhrase('BookAdults')}}</span>
            <span class="jenAdults">
              <button class="less active">-</button>
              <span class="number" id="adults">1</span>
              <button class="more active">+</button>
          </div>
          <div class="jenChildrenDetails">
            <span class="jenChildrenLabel">{{getDictionaryPhrase('BookChildren')}}</span>
            <span class="jenChildren">
              <button class="less">-</button>
              <span class="number" id="children">0</span>
              <button class="more active">+</button>
            </span>
          </div>
          <div class="jenRoomDetails">
            <span class="jenRoomLabel">{{getDictionaryPhrase('BookRooms')}}</span>
            <span class="jenRooms">
              <button class="less active">-</button>
              <span class="number" id="rooms">1</span>
              <button class="more active">+</button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>-->
  
      <div class="">
	  <template v-if="calendartype == 'block'">
    <form :id="uid + '_jenDatePicker_form'" method="post">
      <div :id="uid + '_jenDatePicker'" class="jenDatePicker Calendarblock">
    
        <div class="jenDates">
          <input  id="startDate" class="startDate_class" type="text" name="fromDate" value="" readonly/>
          <input  id="startDate_string" class="startDate_string" type="text" name="fromDate_string" style="opacity:0;position:absolute;top:0;z-index:-1;" value="" readonly/>
          <div class="hiddenstartDate" style="display: none;"></div>
          <input id="endDate" class="endDate_class" type="text" name="toDate" value="" readonly/>
          <input id="endDate_string" class="endDate_string" type="text" name="toDate_string" style="opacity:0;position:absolute;top:0;z-index:-1;" value="" readonly/>
		  <div class="hiddenendDate" style="display: none;"></div>
          <span class="jenDays" id="jenDays">{{getDictionaryPhrase('Book1Night')}}</span>
        </div>
        <div id="jenCalender" class="jenCalender">
        </div>
        <div id="jenGuests">
          <div id="jenGuestDetails" class="jenGuestDetails">{{getDictionaryPhrase('Book1Room1Adult0Children')}}</div>
          <div class="jenGuests">
            <input type="hidden" v-model="reservedRoom" name="rooms[]" value="1" />
            <input type="hidden" v-model="reservedAdult" name="room[]['adult']" value="1" />
            <input type="hidden" v-model="reservedChildren" name="rooms[]['children']" value="0" />
          </div>
          <div id="jenGuestSelect" class="jenGuestSelect">
            <div class="jenGuestWrapper">
              <div class="jenGuestRow">
                <div class="jenGuestInfo">{{getDictionaryPhrase('BookMax3GuestsPerRoomPrefix')}}3 {{getDictionaryPhrase('BookMax3GuestsPerRoomSuffix')}}</div>
              </div>
              <div class="jenGuestHead jenGuestRow">
                <div class="jenGuestCol1"></div>
                <div class="jenGuestCol2">{{getDictionaryPhrase('BookAdults')}}</div>
                <div class="jenGuestCol3">{{getDictionaryPhrase('BookChildren12')}}</div>
              </div>
              <div id="rooms" class="rooms">
                <div class="jenGuestRow room" room="1">
                  <div class="jenGuestCol1">{{getDictionaryPhrase('BookRoom')}} 1</div>
                  <div class="jenGuestCol2 adult">
                    <span class="less disabled">-</span>
                    <span class="counter" data="adult">1</span>
                    <span class="more">+</span>
                  </div>
                  <div class="jenGuestCol3 child">
                    <span class="less disabled">-</span>
                    <span class="counter" data="child">0</span>
                    <span class="more">+</span>
                  </div>
                  <div class="jenGuestCol4">X</div>
                </div>
              </div>
              <div class="jenGuestRow">
                <div class="addRoom">
                  <span>+ {{getDictionaryPhrase('BookAddRoom')}}</span>
                </div>
              </div>
              <div class="jenGuestRow childPolicy">
                <div>{{getDictionaryPhrase('BookChildrenMeal')}}
                </div>
              </div>
              <div class="jenGuestRow groupPolicy">
                <div>{{getDictionaryPhrase('BookExtraChildren')}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="jenSubmit">
          <input type="submit" :value="getDictionaryPhrase('BookCheckAvailability')" class="jenSubmit" />
        </div>
      </div>
    </form>
	</template>
	<template v-else-if="calendartype == 'fields'">
    <form :id="uid + '_jenTimePicker_form'" method="post" >
      <div :id="uid + '_jenTimePicker'" class="jenTimePicker Calendarfields">
        <div :class="'jenDates '+ [isbooking == '1'?'':'hidden']">
          <!-- <div :class="'jenDates '"> -->
          <!--<input id="selectDate" class="selectDate" type="text" name="selectDate" value="" readonly/>-->
          <input class="selectDate" type="text" name="selectDate" value="" readonly/>
         
          <input id="selectTime" class="selectTime" type="text" name="selectTime" value="09:00" placeholder="Select Time" readonly/>
		   <input class="selectDate_string" type="text" name="selectDate_string" value="" style="opacity:0;position:absolute;top:0;z-index:-1;" readonly/>
		   <input class="selectDate_eat2eat" type="text" name="selectDate_eat2eat" value="" style="opacity:0;position:absolute;top:0;z-index:-1;" readonly/>
          <div id="jenTimeWrapper">
            <div class="jenSelect">

			  <div class="jenSelectItem active" data="1">09:00</div>
              <div class="jenSelectItem" data="1">10:00</div>
              <div class="jenSelectItem" data="1">11:00</div>
              <div class="jenSelectItem" data="1">12:00</div>
              <div class="jenSelectItem" data="1">13:00</div>
              <div class="jenSelectItem" data="1">14:00</div>
              <div class="jenSelectItem" data="1">15:00</div>
              <div class="jenSelectItem" data="1">16:00</div>
              <div class="jenSelectItem" data="1">17:00</div>
              <div class="jenSelectItem" data="1">18:00</div>
              <div class="jenSelectItem" data="1">19:00</div>
              <div class="jenSelectItem" data="1">20:00</div>
              <div class="jenSelectItem" data="1">21:00</div>
              <div class="jenSelectItem" data="1">22:00</div>

            </div>
          </div>
        </div>
        <div id="jenRCalender" class="jenCalender">
        </div>
        <!--<div id="jenRGuests">
          <div id="jenRGuestDetails" class="jenGuestDetails">1 Adult, 0 Children</div>
          <div class="jenRGuests">
            <input type="hidden" name="radults" value="1" />
            <input type="hidden" name="rchildren" value="0" />
          </div>
          <div id="jenRGuestSelect" class="jenGuestSelect">
            <div class="jenGuestWrapper">
              <div class="jenGuestRow adult">
                <div class="jenGuestCol1">Adult</div>
                <div class="jenGuestCol2">
                  <span class="less disabled">-</span>
                  <span class="counter" data="adult">1</span>
                  <span class="more">+</span>
                </div>
              </div>
              <div class="jenGuestRow child">
                <div class="jenGuestCol1">Child</div>
                <div class="jenGuestCol2">
                  <span class="less disabled">-</span>
                  <span class="counter" data="child">0</span>
                  <span class="more">+</span>
                </div>
              </div>
            </div>
          </div>
        </div>-->
        <div id="jenRSubmit" class="jenRSubmit">
          <!--<input type="submit" value="Reserve" class="jenSubmit" />-->
		    <a :class="'btn btn-primary '+ [isbooking == '1'?'':'hidden']">{{getDictionaryPhrase('Reserve')}}</a>
        </div>
      </div>
    </form>
	</template>
  </div>
  
  </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import { Image } from '@sitecore-jss/sitecore-jss-vue';
import axios from 'axios';
import { dataFetcher } from '../dataFetcher.js';
import { layoutFetcher, layoutFetchAction } from '../layoutFetcher.js';
import {
getDictionaryPhrase,
  getObject,
  graphqlRemoveReserved,
  graphqlToObject,
  getObjectById,
  graphqlFetcher,
} from '../graphqlFetcher.js';
//import $ from 'jquery';
import ApolloClient from 'apollo-boost';
import gql from 'graphql-tag';
import { uuid } from 'vue-uuid';
import {
  enableExperimentalFragmentVariables,
  disableExperimentalFragmentVariables,
} from 'graphql-tag';
import Vue from 'vue';
import VueMeta from 'vue-meta';
import { trackingApi } from '@sitecore-jss/sitecore-jss-tracking';
import config from '../temp/config';
import {bookhotel} from '../hjutil.js';

export default {
  name: 'HJCalendar',
  components: {
    ScText: Text,
  },
  props: {
    fields: {
      type: Object,
      default: function() {},
    },
    currentcontext: {
      type: Object,
      default: null,
    },
    uid: {
      type: String,
      default: function() {
        return uuid.v1();
      },
    },
    calendartype: {
      type: String,
      default: function() {
        return 'block';
      },
    },
	bookingurl: {
      type: String,
      default: function() {
        return '';
      },
    },
	isbooking: {
      type: String,
      default: function() {
        return 0;
      },
    },
	iseat2eat: {
      type: String,
      default: function() {
        return 0;
      },
    },
  },
  data() {
    return {
      loadingQueriesCount: 0,
      error: null,
      DPstartDate: new Date().toISOString().slice(0, 10),
      DPendDate: new Date().toISOString().slice(0, 10),
      reservedDate: new Date().toISOString().slice(0, 10),
      reservedTime: '11:00 AM',
      reservedRoom: '1',
      reservedAdult: '1',
      reservedChildren: '0',
    };
  },
  computed: {
    datasource: function() {
      return this.fields && this.fields.data && this.fields.data.datasource;
    },
    contextItem: function() {
      return this.fields && this.fields.data && this.fields.data.contextItem;
    },
  },
  mounted: function() {
    // ...

    let vm = this;
    //'#'+vm.uid+'_jenDatePicker


	if ($('.HJContainer').attr('data-propertymaxoccupy') != undefined)
	{
		if ( vm.$jss.routeData().itemLanguage == 'zh-CN' || vm.$jss.routeData().itemLanguage == 'zh'){
			$('#' + vm.uid + ' .jenGuestInfo').html(vm.getDictionaryPhrase("BookMax3GuestsPerRoomPrefix") + $('.HJContainer').attr('data-propertymaxoccupy') + vm.getDictionaryPhrase("BookMax3GuestsPerRoomSuffix"));
		}
		else
		{
			$('#' + vm.uid + ' .jenGuestInfo').html(vm.getDictionaryPhrase("BookMax3GuestsPerRoomPrefix") + ' ' + $('.HJContainer').attr('data-propertymaxoccupy') + ' ' + vm.getDictionaryPhrase("BookMax3GuestsPerRoomSuffix"));
		}
	}
	else
	{
		$('#' + vm.uid + ' .jenGuestInfo').html(vm.getDictionaryPhrase("BookMax3GuestsPerRoom"));
	}

    var minRAdult = 1;
    var maxRAdult = 12;
    var minRChild = 0;
    var maxRChild = 4;

    var breakPoint = 640;

    if (!window['console']) {
      window.console = {};
      window.console.log = function() {};
    }

    var todayDate = new Date();
    var fromDate =
      todayDate.getDate() +
      ' ' +
      $.dateRangePickerLanguages['default']['month-name'][todayDate.getMonth()] +
      ' (' +
      $.dateRangePickerLanguages['default']['week-day'][todayDate.getDay()] +
      ')';
    var tomorrowDate = new Date(todayDate);
    tomorrowDate.setDate(tomorrowDate.getDate() + 1);
    var toDate =
      tomorrowDate.getDate() +
      ' ' +
      $.dateRangePickerLanguages['default']['month-name'][tomorrowDate.getMonth()] +
      ' (' +
      $.dateRangePickerLanguages['default']['week-day'][tomorrowDate.getDay()] +
      ')';

    //$('#' + vm.uid + '_jenDatePicker #startDate').val(fromDate);
    vm.DPstartDate = fromDate;
   // $('#' + vm.uid + '_jenDatePicker #endDate').val(toDate);
    //console.log(toDate);
    vm.DPstartDate = toDate;
    
    if (vm.calendartype == 'block') {
	  var bookingString = "";
      $('#' + vm.uid + '_jenDatePicker_form').submit(function(e) {
		var roomlength = $(this)
		  .parent()
		  .parent()
		  .find('.jenGuestSelect .room').length;
		var totaladult = 0;
		var totalchild = 0;
		$(this)
		  .parent()
		  .parent()
		  .find('.jenGuestSelect .room')
		  .each(function() {
			var parent = $(this);
			var sibling = parent.siblings('.counter');
			var current = sibling.attr('data');
			var adult = parent.find('.adult');
			var child = parent.find('.child');
			totaladult = adult.find('.counter').html() * 1;
			totalchild = child.find('.counter').html() * 1;
			if (bookingString != '')
				bookingString += ',';
			bookingString += totaladult.toString() + totalchild.toString();
			if (current == 'adult') totaladult = totaladult + ctr;
			else if (current == 'child') totalchild = totalchild + ctr;

			var dStart = new Date($('#' + vm.uid + '_jenBDate #hiddenstartBDate').html());
			var dEnd = new Date($('#' + vm.uid + '_jenBDate #hiddenendBDate').html()); 

			if (totaladult + totalchild > 2) {
			  //console.log('family');
			  return;
			} else {
			  if (
				dStart.getDay() > 0 &&
				dStart.getDay() < 6 &&
				(dEnd.getDay() > 0 || dEnd.getDay() < 6)
			  ) {
				if (totaladult + totalchild == 1) {
				  //console.log('business');
				  return;
				}
				if (totaladult + totalchild > 1) {
				  //console.log('leisure');
				  return;
				}
			  }
			}
		  });			

	  var dStart = new Date($(this).parent().parent().find('.jenDates .startDate_string').val());
	  var dEnd = new Date($(this).parent().parent().find('.jenDates .endDate_string').val());
		var propertycode = $(this).parents('.HJContentBlock:first').attr('data-propertycode');
		var propertycityalias = $(this).parents('.HJContentBlock:first').attr('data-propertycityalias');
		var propertyhotelalias = $(this).parents('.HJContentBlock:first').attr('data-propertyhotelalias');
		
		var promo_type = "";
		bookhotel(vm.$jss.hjconfig().encryption_key, vm.$jss.hjconfig().encryption_iv, vm.$jss.hjconfig().book_url, propertycode, propertycityalias, propertyhotelalias, dStart, dEnd, roomlength, totaladult.toString() + totalchild.toString(), promo_type, '', '', '');
	  
	  /*
        console.log(
          'DatePicker form submit, StartDate: ' +
            //vm.DPstartDate +
            $(this).parent().parent().find('.jenDates .startDate_string').val() +
            ',EndDate: ' +
            //vm.DPendDate +
            $(this).parent().parent().find('.jenDates .endDate_string').val()  +
            ',Rooms: ' +
            vm.reservedRoom +
            ',Adults: ' +
            vm.reservedAdult +
            ',Children: ' +
            vm.reservedChildren
        );
		*/
        //console.log($('#' + vm.uid + '_jenDatePicker .jenDates').datepicker('getDate'));
        e.preventDefault();
      });
    } else if (vm.calendartype == 'fields') {

     /* $('#' + vm.uid + '_jenTimePicker_form').submit(function(e) {
        //console.log('TimePicker form submit');
        //vm.reservedTime = $('#' + vm.uid + '_jenTimePicker #selectTime').val();
        //console.log(
          'TimePicker form submit, ReservedDate: ' +
            //vm.reservedDate +
            $(this).parent().parent().find('.jenDates .selectDate_string').val() +
            ',Reserved Time: ' +
            vm.reservedTime 
        );
        e.preventDefault();
      });*/
	  
	    $('#' + vm.uid + '_jenTimePicker_form .jenRSubmit a').unbind('click').bind('click', function(e){
		//console.log(vm.bookingurl + ';' + vm.isbooking + ';'+vm.iseat2eat);
		if (vm.isbooking == '1'){
			if (vm.iseat2eat == '1'){
			//Eat2Eat
			var selectedDate = $(this).parent().parent().find('.jenDates .selectDate_eat2eat').val();
			var selectedTime = $(this).parent().parent().find('.jenDates .selectTime').val().replace(':','');
			/*console.log(
			  'TimePicker form submit, ReservedDate: ' +
				//vm.reservedDate +
				$(this).parent().parent().find('.jenDates .selectDate_eat2eat').val() +
				',Reserved Time: ' +
				$(this).parent().parent().find('.jenDates .selectTime').val().replace(':','')
				//vm.reservedTime 
			);*/
			//window.open('http://www.eat2eat.com/JenHotel/Pages/Reservation.aspx?relId=MwA0ADQAMgA=&lang=0&pro=0&br=0&ho=8888320&res=0&ci=0&ms=1035&src=IPS'+'&dt='+selectedDate+'&tm='+selectedTime);
				if (vm.bookingurl && vm.bookingurl != ''){
					window.open(vm.bookingurl+'&dt='+selectedDate+'&tm='+selectedTime);
				}
			}else{
			var selectedDate = $(this).parent().parent().find('.jenDates .selectDate_string').val();
			var selectedTime = $(this).parent().parent().find('.jenDates .selectTime').val();
			/*console.log(
			  'TimePicker form submit, ReservedDate: ' +            
				$(this).parent().parent().find('.jenDates .selectDate_string').val() +
				',Reserved Time: ' +
				$(this).parent().parent().find('.jenDates .selectTime').val() 
			);*/
			//window.open('https://www.restaurants.sg/modules/booking/book_form.php?bktracking=WEBSITE&bkrestaurant=SG_SG_R_HjnTanglinJ65'+'&bkdate='+selectedDate+'&bktime='+selectedTime);
				if (vm.bookingurl && vm.bookingurl != ''){
					window.open(vm.bookingurl+'&bkdate='+selectedDate+'&bktime='+selectedTime);
				}
			}
		}else{
			if (vm.bookingurl && vm.bookingurl != ''){
				window.open(vm.bookingurl+'&bkdate='+selectedDate+'&bktime='+selectedTime);
			}		
		}
		
		
		
		//Booking link for https://www.restaurants.sg/modules/booking/book_form.php?bktracking=WEBSITE&bkrestaurant=SG_SG_R_HjnTanglinJ65
		// Booking Link for eat2eat : http://www.eat2eat.com/JenHotel/Pages/Reservation.aspx?relId=MwA0ADQAMgA=&lang=0&pro=0&br=0&ho=8888320&res=0&ci=0&ms=1035&src=IPS


        e.preventDefault();
	  });
    }

    /** Click Event **/


    $('#' + vm.uid + '_jenTimePicker #selectTime').click(function() {
      $('#' + vm.uid + '_jenTimePicker #jenTimeWrapper').toggle();
    });

    $('#' + vm.uid + '_jenTimePicker .jenSelectedItem').click(function() {
      $('#' + vm.uid + '_jenTimePicker .jenSelect').toggle();
    });

    $('#' + vm.uid + '_jenTimePicker .jenSelectItem').click(function() {
      $('#' + vm.uid + '_jenTimePicker .jenSelectItem').each(function() {
        $(this).removeClass('active');
      });
      $(this).addClass('active');
      if (
        $('#' + vm.uid + '_jenTimePicker .jenSelect')
          .find('.active')
          .attr('data') == '1'
      ) {
        $('#' + vm.uid + '_jenTimePicker #selectTime').val($(this).html());
      } else {
        $('#' + vm.uid + '_jenTimePicker #selectTime').val('');
      }
      $('#' + vm.uid + '_jenTimePicker  #jenTimeWrapper').hide();
    });

    $('#' + vm.uid + '_jenDatePicker #jenGuestDetails').click(function() {
      $('#' + vm.uid + '_jenDatePicker #jenGuestSelect').toggle();
    });

    $('#' + vm.uid + '_jenTimePicker #jenRGuestDetails').click(function() {
      $('#' + vm.uid + '_jenTimePicker #jenRGuestSelect').toggle();
    });

    $('#' + vm.uid + '_jenDatePicker #rooms').on('click', '.less', function() {
      var less = updateGuests($(this), -1);
      if (less) {
        $(this)
          .siblings('.counter')
          .html(
            $(this)
              .siblings('.counter')
              .html() *
              1 -
              1
          );
      }
      updatePolicy($('#' + vm.uid + '_jenDatePicker #rooms'), vm.getDictionaryPhrase("BookRoom"), vm.getDictionaryPhrase("CalendarAdult"), vm.getDictionaryPhrase("CalendarChildren"));
    });

    $('#' + vm.uid + '_jenDatePicker #rooms').on('click', '.more', function() {
      var more = updateGuests($(this), 1);
      if (more) {
        $(this)
          .siblings('.counter')
          .html(
            $(this)
              .siblings('.counter')
              .html() *
              1 +
              1
          );
      }
      updatePolicy($('#' + vm.uid + '_jenDatePicker #rooms'), vm.getDictionaryPhrase("BookRoom"), vm.getDictionaryPhrase("CalendarAdult"), vm.getDictionaryPhrase("CalendarChildren"));
    });

    $('#' + vm.uid + '_jenDatePicker #jenGuests .addRoom').click(function() {
      addRoom($(this), vm.getDictionaryPhrase("BookRoom"), vm.getDictionaryPhrase("CalendarAdult"), vm.getDictionaryPhrase("CalendarChildren") );
      var roomno = $('#' + vm.uid + '_jenDatePicker #rooms').children('.room').length;
      if (roomno >= 5) {
        $(this)
          .parent()
          .css('display', 'none');
      }
    });

    $('#' + vm.uid + '_jenDatePicker #jenGuests .rooms').on('click', '.jenGuestCol4', function() {
      var roomno = $('#' + vm.uid + '_jenDatePicker #rooms').children('.room').length;
      if (roomno <= 5) {
        $(this)
          .parent()
          .parent()
          .parent()
          .find('.addRoom')
          .parent()
          .css('display', 'flex');
      }
      removeRoom($(this), vm.getDictionaryPhrase("BookRoom"), vm.getDictionaryPhrase("CalendarAdult"), vm.getDictionaryPhrase("CalendarChildren"));
    });

    $('#' + vm.uid + '_jenTimePicker #jenRGuestSelect').on('click', '.more', function() {
      var ctr =
        $(this)
          .siblings('.counter')
          .html() *
          1 +
        1;
      var guest = $(this)
        .siblings('.counter')
        .attr('data');
      updateRGuests(guest, $(this), ctr);
    });

    $('#' + vm.uid + '_jenTimePicker #jenRGuestSelect').on('click', '.less', function() {
      var ctr =
        $(this)
          .siblings('.counter')
          .html() *
          1 -
        1;
      var guest = $(this)
        .siblings('.counter')
        .attr('data');
      updateRGuests(guest, $(this), ctr);
    });

    function updateRGuests(guest, obj, ctr) {
      if (guest == 'adult') {
        if (ctr == minRAdult) {
          obj
            .parent()
            .find('.less')
            .addClass('disabled');
          obj.siblings('.counter').html(ctr);
        }
        if (ctr > minRAdult && ctr <= maxRAdult) {
          obj
            .parent()
            .find('.more')
            .removeClass('disabled');
          obj
            .parent()
            .find('.less')
            .removeClass('disabled');
          obj.siblings('.counter').html(ctr);
        }
        if (ctr == maxRAdult) {
          obj
            .parent()
            .find('.more')
            .addClass('disabled');
        }
      } else if (guest == 'child') {
        if (ctr == minRChild) {
          obj
            .parent()
            .find('.less')
            .addClass('disabled');
          obj.siblings('.counter').html(ctr);
        }
        if (ctr > minRChild && ctr <= maxRChild) {
          obj
            .parent()
            .find('.more')
            .removeClass('disabled');
          obj
            .parent()
            .find('.less')
            .removeClass('disabled');
          obj.siblings('.counter').html(ctr);
        }
        if (ctr == maxRChild) {
          obj
            .parent()
            .find('.more')
            .addClass('disabled');
        }
      }
      var adultR =
        $('#' + vm.uid + '_jenTimePicker #jenRGuestSelect')
          .find('.adult')
          .find('.counter')
          .html() * 1;
      var childR =
        $('#' + vm.uid + '_jenTimePicker #jenRGuestSelect')
          .find('.child')
          .find('.counter')
          .html() * 1;
      var statusR = adultR + ' Adult';
      vm.reservedAdult = adultR;
      if (adultR > 1) {
        //statusR += 's';
      }
      statusR += ', ' + childR + ' Child';
      vm.reservedChildren = childR;
      if (childR == 0 || childR > 1) {
        statusR += 'ren';
      }
      $('#' + vm.uid + '_jenTimePicker #jenRGuestDetails').html(statusR);
    }

    function updateGuests(guest, ctr) {
      var process = false;
      var parent = guest.closest('.room');
      var sibling = guest.siblings('.counter');
      var current = sibling.attr('data');
      var adult = parent.find('.adult');
      var child = parent.find('.child');
      var totaladult = adult.find('.counter').html() * 1;
      var totalchild = child.find('.counter').html() * 1;
      if (current == 'adult') totaladult = totaladult + ctr;
      else if (current == 'child') totalchild = totalchild + ctr;

	var propertymaxoccupy = $('.HJContainer').attr('data-propertymaxoccupy');
	
	if (propertymaxoccupy == undefined)
		propertymaxoccupy = 3;

      if (totaladult + totalchild <= (propertymaxoccupy-0) && totaladult + totalchild >= 1) {
        if (totaladult >= 1 && totaladult <= (propertymaxoccupy-0) && (totalchild >= 0 && totalchild < ((propertymaxoccupy-0)-1))) {
          if (totaladult == 1) {
            adult.find('.more').removeClass('disabled');
            adult.find('.less').addClass('disabled');
            process = true;
          } else if (totaladult == (propertymaxoccupy-0)) {
            adult.find('.more').addClass('disabled');
            adult.find('.less').removeClass('disabled');
            process = true;
          } else {
            adult.find('.more').removeClass('disabled');
            adult.find('.less').removeClass('disabled');
            process = true;
          }
        }
        if (totalchild >= 0 && totalchild <= ((propertymaxoccupy-0)-1) && (totaladult > 0 && totaladult < (propertymaxoccupy-0))) {
          if (totalchild == 0) {
            child.find('.more').removeClass('disabled');
            child.find('.less').addClass('disabled');
            process = true;
          } else if (totalchild == 2) {
            child.find('.more').addClass('disabled');
            child.find('.less').removeClass('disabled');
            process = true;
          } else {
            child.find('.more').removeClass('disabled');
            child.find('.less').removeClass('disabled');
            process = true;
          }
        }
      }
      if (totaladult + totalchild >= (propertymaxoccupy-0)) {
        adult.find('.more').addClass('disabled');
        child.find('.more').addClass('disabled');
      }
      vm.reservedAdult = totaladult;
      vm.reservedChildren = totalchild;
      return process;
    }

    function addRoom(obj, bookroomtext, bookadultstext, bookchildrentext) {
      obj = obj
        .parent()
        .parent()
        .find('.rooms');
      var roomno = obj.children('.room').length;
      if (roomno < 5) {
        var room = '                <div class="jenGuestRow room" room="' + (roomno + 1) + '">';
        room += '                 	<div class="jenGuestCol1">' + bookroomtext + ' ' + (roomno + 1) + '</div>';
        room += '                  <div class="jenGuestCol2 adult">';
        room += '                    <span class="less disabled">-</span>';
        room += '                    <span class="counter" data="adult">1</span>';
        room += '                    <span class="more">+</span>';
        room += '                  </div>';
        room += '                  <div class="jenGuestCol3 child">';
        room += '                    <span class="less disabled">-</span>';
        room += '                    <span class="counter" data="child">0</span>';
        room += '                    <span class="more">+</span>';
        room += '                  </div>';
        room += '                 	<div class="jenGuestCol4">X</div>';
        room += '                </div>';
        obj.append(room);
      }
      updateRoom(obj, bookroomtext, bookadultstext, bookchildrentext);
    }

    function removeRoom(room, bookroomtext, bookadultstext, bookchildrentext) {
      var roomParent = room.closest('.room').parent();
      room.closest('.room').remove();
      updateRoom(roomParent, bookroomtext, bookadultstext, bookchildrentext);
    }

    function updateRoom(obj, bookroomtext, bookadultstext, bookchildrentext) {
      var rooms = obj.children('.room');
      var roomno = obj.children('.room').length;
      if (roomno > 1) {
        $('#' + vm.uid + '_jenDatePicker .jenGuestCol4').show();
      } else {
        $('#' + vm.uid + '_jenDatePicker .jenGuestCol4').hide();
      }
      var ctr = 1;
      rooms.each(function() {
        $(this).attr('room', ctr);
        $(this)
          .find('.jenGuestCol1')
          .html(bookroomtext + ' ' + ctr);
        ctr++;
      });
      vm.reservedRoom = ctr;
      updatePolicy(obj, bookroomtext, bookadultstext, bookchildrentext);
    }

    function updatePolicy(obj, bookroomtext, bookadultstext, bookchildrentext) {
      var total_adult = 0;
      var total_child = 0;
      var rooms = obj.children('.room');
      rooms.each(function() {
        total_adult +=
          $(this)
            .children('.adult')
            .children('.counter')
            .html() * 1;
        total_child +=
          $(this)
            .children('.child')
            .children('.counter')
            .html() * 1;
      });
      if (total_child >= 1) {
        obj
          .parent()
          .find('.childPolicy')
          .css('display', 'block');
        if (total_adult >= 2) {
          obj
            .parent()
            .find('.groupPolicy')
            .css('display', 'block');
        } else {
          obj
            .parent()
            .find('.groupPolicy')
            .hide();
        }
      } else {
        obj
          .parent()
          .find('.childPolicy')
          .hide();
        obj
          .parent()
          .find('.groupPolicy')
          .hide();
      }
      var roomno = obj.children('.room').length;
      var status = roomno + ' ' + bookroomtext;
      if (roomno > 1) 
	  {
		if (bookroomtext.indexOf('room') != -1 || bookroomtext.indexOf('room') != -1)
			status += 's';
	  }
      status += ', ' + total_adult + ' ' + bookadultstext;
      //if (total_adult > 1) status += 's';
	  if (total_adult > 1) 
	  {
		//if ((bookadultstext.indexOf('Adult') != -1 || bookadultstext.indexOf('adult') != -1) &&
		//bookadultstext.indexOf('(s)') == -1)
		//	status += 's';
	  }
      status += ', ' + total_child + ' ' + bookchildrentext;
      if (total_child > 1)
	  {
		if (bookchildrentext.indexOf('child') != -1 || bookchildrentext.indexOf('child') != -1)
			status += 'ren';
	  }
      obj
        .parent()
        .parent()
        .parent()
        .find('.jenGuestDetails')
        .html(status);
    }
  },
  updated() {
    let vm = this;
  },
  methods: {
	getDictionaryPhrase: function(key)
	{
		return getDictionaryPhrase(key, this);
	},
    isproperty: function(name) {
      if (this.fields != null && this.fields[name] != undefined) {
        return this.fields[name];
      } else {
        if (this.fields.data.datasource && this.fields.data.datasource.fields) {
          for (var i = 0; i < this.fields.data.datasource.fields.length; i++) {
            if (this.fields.data.datasource.fields[i].name == name) {
              if (
                this.fields.data.datasource.fields[i].targetItems &&
                this.fields.data.datasource.fields[i].targetItems.length > 0
              ) {
                if (this.fields.data.datasource.fields[i].targetItems[0].textField)
                  return this.fields.data.datasource.fields[i].targetItems[0].textField.value;
                else if (this.fields.data.datasource.fields[i].targetItems[0].title)
                  return this.fields.data.datasource.fields[i].targetItems[0].textField.title;
                else return '';
              } else {
                return this.fields.data.datasource.fields[i].value;
              }
            }
          }
        }
      }
      return null;
    },
  },
};
</script>
