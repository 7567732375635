<template>
  <div>
      
  <!-- New Footer -->
	<div class="container-fluid footer-container-property hidden">
            <div class="row no-gutters justify-content-center">
                <div class="col-12 text-center pt-4 mt-3">
                    <ul>
                        <li class="f-pn">
                          <span class="footer-propertyname"></span>
                        </li>
						<li v-if="email">
                            <a :href="'mailto:' +email">
                              <img :src="emailIcon"/>
                              <span class="footer-propertyemail">{{email}}</span>
                            </a>
                        </li>
						<li>
                            <a href="">
                              <img :src="phoneIcon"/>
                              <span class="footer-propertynumber"></span>
                            </a>
                        </li>
                        <li>
                          <img :src="locationIcon"/>
                          <span>
                            <a href="#" 
                            :data-address="address" 
                            :data-lng="longitude" 
                            :data-lat="latitude" 
                            target="_blank" 
                            class="footer-property-location">{{address}}</a>
							              <!--<a href="http://api.map.baidu.com/marker?location=39.91182,116.4569263&output=html" target="_blank">1 Jianguomen Outer St, Jian Wai Da Jie, Chaoyang, China, 100004</a>-->
                          </span>
                        </li>
                        <!--<li><img src="Footer/images/metro.png"/><span>mtr exit b2</span></li>
                        <li><img src="Footer/images/bus.png"/><span>2a, 27, 690</span></li>-->
                    </ul>
                </div>
            </div>
        </div>
		
		  <div class="container-fluid footer-container mobile-hidden">
            <div class="row no-gutters justify-content-center">
                <div class="col-12 col-md-11 col-lg-12 mt-4 mt-md-2 pt-md-3 mb-3 mb-md-3 pb-5 pb-md-0 maxWidth-b">
                    <div class="container-fluid pl-0 pr-0 pl-md-auto pr-md-auto">
                        <div class="row no-gutters justify-content-center">
                            <!--<div class="col-8 mobile-hidden">-->
                            <div class="col-6 col-md-9 mobile-hidden">
                                <div class="container-fluid">
                                    <div class="row justify-content-center">
									<template v-for="(group, index) in llinks">
									<div :class="'col-3 text-left ' + [index == 0?'custom-padding ':''] + [index == 3?'custom-padding ':''] + [index == 1?'pl-0 ':'']">
										<template v-for="(link, subindex) in group">
											
													<template v-if="Array.isArray(link)" >
														<template v-if="link.length > 0" >
														<ul>
															<template v-for="(subgroup, subsubindex) in link">
																<li>
																	<a :href="subgroup['cta'].value" class="footer-anchors">
																	<template v-if="subgroup['image'] != null && subgroup['image'].src != ''" >
																		<img :class="subgroup['imagetype'].value" :src="subgroup['image']['src']" />
																	</template>
																	<template v-else>
																		{{subgroup['title'].value}}
																	</template>
																	</a>
																</li>
															</template>
														</ul>
														</template>
													</template>
													<template v-else>
														<h4 v-html="link['title'].value"></h4>
													</template>
											
											</template>		</div>	
									</template>
									
                                    </div>
                                </div>
                            </div>
                            
                            <!-- added mt-4 mt-md-0 march-->
                            <div class="col-12 col-md-3 mt-4 mt-md-0 right-panel">
                                <div class="container-fluid pl-0 pr-0 pl-md-auto pr-md-auto">
								<template v-for="(group, index) in rlinks">
										<template v-for="(link, subindex) in group">
													<template v-if="Array.isArray(link)" >
														<template v-if="link.length > 0" >
														
															<div class="row justify-content-center mt-2" style="max-width: 260px">
															<div class="col-12">
															<template v-for="(subgroup, subsubindex) in link">
																	<template v-if="subgroup['image'] != null && subgroup['image'].src != ''" >
																		<template v-if="subgroup['imagetype'].value == 'footer-qrcode'">
																			<div class="footer-qrcode-container">
																				<img :src="subgroup['image']['src']" class="footer-qrcode img-fluid">
																			</div>
																		</template>
																		<template v-else-if="subgroup['imagetype'].value == 'footer-googleplay'">
																		    <a  :href="subgroup['cta'].value"  class="footer-googleplay"><img :src="subgroup['image']['src']"class="img-fluid"></a></template>
																		<template v-else-if="subgroup['imagetype'].value == 'footer-appstore'">																	
																			<a :href="subgroup['cta'].value"  class="footer-appstore"><img :src="subgroup['image']['src']" class="img-fluid"></a>
																		</template>
																		<template v-else>
																			<a  :href="subgroup['cta'].value"  target="_blank"><img :src="subgroup['image']['src']" :class="subgroup['cssclass'].value"></a>
																		</template>
																	</template>
															</template>
															</div>
															</div>
														</template>
													</template>
													<template v-else>
													
													<div class="row justify-content-center">
													<div class="col-12">
														<div class="" style="padding-right:0;">
															<h4 class="mb-1">{{link['title'].value}}</h4>
															<p class="mb-0">{{link['description'].value}}</p>
														</div>
													</div>	
													</div> 																										
													</template>
													<!-- closing col-12 -->

											</template>
									</template>		
								
								
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid footer-container2">
            <div class="row no-gutters justify-content-center">
                <div class="col-12">
                    <div class="container-fluid mobile-hidden pr-0 pl-0">
                        <div class="row no-gutters justify-content-center">
                            <div class="col-12">
                                <div class="container-fluid mobile-hidden pr-0 pl-0">
                                    <div class="row no-gutters justify-content-center bg-grey pt-0 pt-md-2 pb-0 pb-2">
                                        <div class="col-12">
											<template v-for="(group, index) in bllinks">
												<template v-if="group.length > 1">
												<template v-for="(link, subindex) in group">
														<template v-if="Array.isArray(link)" >
															<template v-if="link.length > 0" >
																<template v-for="(subgroup, subsubindex) in link">                                   
																		<template v-if="subgroup['image'] != null && subgroup['image'].src != ''" >
																			<img :src="link['image']['src']"/>
																		</template>
																</template>
															</template>
														</template>
														<template v-else>
															<template v-if="link['image'] != null && link['image'].src != ''" >															
																<img :src="link['image']['src']"/>
															</template>
														</template>
												</template>	
												</template>												
											</template>  									
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row no-gutters justify-content-center mt-3 mb-4">
                            <div class="col-12">
                                <p class="footer-copy">
									<template v-for="(group, index) in blinks">	<!--Desktop Bottom Links -->
											<template v-if="group.length > 1">
											<template v-for="(link, subindex) in group">
												<template v-if="!Array.isArray(link)" >
												<a :href="link['cta'].value" :class="'footer-bottom-anchors ' + link['cssclass'].value">{{link['title'].value}}</a> 												
												<template v-if="subindex < (group.length - 2)"> <span :class="link['cssclass'].value">|</span> </template>
												</template>
											</template>
											</template>
									</template>	  
									 <br/>
									
									<template v-for="(group, index) in disclaimer">	 <!--Desktop Bottom Disclaimer -->
											<template v-if="group.length > 1">
											<template v-for="(link, subindex) in group">
												<template v-if="!Array.isArray(link)" >{{link['title'].value}}</template>
											</template>
											</template>
									</template>
                                    <!--&copy; 2020 Shangri-La International Hotel Management Ltd. All Rights Reserved. ICP License: 17055189-->
                                    
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="container-fluid desktop-hidden pl-0 pr-0">
                        <div class="row no-gutters justify-content-center pt-3" style="border-top: 1px solid #a2aaad;">
                            <div class="col-12">
							<template v-for="(group, index) in bllinks">
							<template v-if="group.length > 1">
												<template v-for="(link, subindex) in group">
														<template v-if="Array.isArray(link)" >
															<template v-if="link.length > 0" >
																<template v-for="(subgroup, subsubindex) in link">                                   
																		<template v-if="subgroup['image'] != null && subgroup['image'].src != ''" >
																			<img :src="link['image']['src']" class="img-fluid"/>
																		</template>
																</template>
															</template>
														</template>
														<template v-else>
															<template v-if="link['image'] != null && link['image'].src != ''" >															
																<img :src="link['image']['src']" class="img-fluid"/>
															</template>
														</template>
												</template>	
								</template>
							</template>  
                            </div>
                        </div>
                        <div class="row no-gutters justify-content-center mt-3 pt-4" style="background-color: #eee;">
                            <div class="col-11 pl-0 pr-0">
                                <ul class="mb-1">
								
								<template v-for="(group, index) in blinks">	<!--Desktop Bottom Links -->
											<template v-if="group.length > 1">
											<template v-for="(link, subindex) in group">
												<template v-if="!Array.isArray(link)" >
												 <li :class="'mb-3 ' + link['cssclass'].value"><a :href="link['cta'].value" >{{link['title'].value}}</a></li>
												</template>
											</template>
											</template>
									</template>	
                                </ul>
                            </div>
                            <div class="row justify-content-center ml-3 mr-3 pt-3 pb-3 footer-copy2"> 
								<template v-for="(group, index) in disclaimer">	 <!--Desktop Bottom Disclaimer -->
											<template v-if="group.length > 1">
											<template v-for="(link, subindex) in group">
												<template v-if="!Array.isArray(link)" >{{link['title'].value}}</template>
											</template>
											</template>
								</template>
							</div>
                        </div>
                    </div>
				</div>
            </div>
        </div>

 <!-- Cookie Consent Box -->
        <!-- <div class="cookie_policy js-cookie-policy sl-section">
            <div class="content" style=""> 
                <div class="desc" v-html="getDictionaryPhrase('CookiePolicy')"></div>
				<div class="btn-group"><a class="btn btn-primary cookie_consent_cta" href="javascript:;" style="">{{getDictionaryPhrase('Accept')}}</a>
                </div>
            </div>
        </div> -->
  
  </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import { Image } from '@sitecore-jss/sitecore-jss-vue';
import axios from 'axios';
import { dataFetcher } from '../dataFetcher.js';
import { layoutFetcher, layoutFetchAction } from '../layoutFetcher.js';
import {
getDictionaryPhrase,
	getKeys,
  graphqlRemoveReserved,
  getLinks,
  getContentByType,
  getObject,
  getObjectById,
  graphqlFetcherTestSSR,
  graphqlFetcher,
} from '../graphqlFetcher.js';
//import $ from 'jquery';
import ApolloClient from 'apollo-boost';
import gql from 'graphql-tag';
import { uuid } from 'vue-uuid';
import {
  enableExperimentalFragmentVariables,
  disableExperimentalFragmentVariables,
} from 'graphql-tag';
import Vue from 'vue';
import VueMeta from 'vue-meta';
import { trackingApi } from '@sitecore-jss/sitecore-jss-tracking';
import config from '../temp/config';
import plusicon from '../assets/img/footer/plus.png';
import locationicon from '../assets/img/location.png';
import phoneicon from '../assets/img/phone.png';
import emailIcon from '../assets/img/email.png';

export default {
  name: 'HJFooter',
  components: {
    ScText: Text,
  },
  props: {
    fields: {
      type: Object,
      default: function() {},
    },
    currentcontext: {
      type: Object,
      default: null,
    },
    uid: {
      type: String,
      default: function() {
        return uuid.v1();
      },
    },
  },
  data() {
    return {
      loadingQueriesCount: 0,
      error: null,
      llinks: [],
      rlinks: [],
	  bllinks: [],
	  disclaimer: [],
	  blinks: [],
	  plusIcon: plusicon,
	  locationIcon: locationicon,
	  phoneIcon: phoneicon,
	  emailIcon: emailIcon,
	  address: '',
	  email: '',
	  longitude: '',
	  latitude: '',
    };
  },
  computed: {
    datasource: function() {
      return this.fields.data && this.fields.data.datasource;
    },
    contextItem: function() {
      return this.fields.data && this.fields.data.contextItem;
    },
  },
  mounted: function() {
    // ...
    /*console.log("cookie");
		//console.log(document.cookie);
		//console.log(document.cookies);
		var nameEQ = "SC_ANALYTICS_GLOBAL_COOKIE" + "=";
	  var ca = document.cookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ') c = c.substring(1, c.length);
			if (c.indexOf(nameEQ) == 0) console.log(c.substring(nameEQ.length, c.length));
		}
		*/

    let vm = this;
    let current_item = vm.$jss.sitecoreContext();

    var _routedata = vm.$jss.routeData();

    setTimeout(function() {
      var from = document.referrer;
    }, 500);
/*
    const trackingApiOptions = {
      host: config.sitecoreApiHost,
      querystringParams: {
        sc_apikey: config.sitecoreApiKey,
      },
      fetcher: dataFetcher,
    };
*/
    //trackingApi
    //  .trackEvent([{ pageId: '{36715B3C-7A6C-5576-9343-F6B504173D74}', url: '/hotels/beijing'}], trackingApiOptions)
    //  .then(() => console.log('Page event pushed'))
    //  .catch((error) => console.error(error));

    //console.log(vm.datasource);
    vm.llinks = [];
    vm.rlinks = [];
	vm.bllinks = [];
	vm.blinks = [];
	vm.disclaimer = [];
    vm.llinks = getLinks(vm.datasource, vm, true, 'left');

    vm.rlinks = getLinks(vm.datasource, vm, true, 'right');

    vm.bllinks = getLinks(vm.datasource, vm, true, 'bottomlogos');

    vm.disclaimer = getLinks(vm.datasource, vm, true, 'disclaimer');
    vm.blinks = getLinks(vm.datasource, vm, true, 'bottom');
	
	$(document).ready(function(){
    	/*Cookie Consent */

            //console.log(getCookie('cookie_consent'));
            if (getCookie('cookie_consent') || localStorage.getItem('cookie_consent')){
                
            }else{
                $('.cookie_policy').show();
            }
            
            $('.cookie_consent_cta').on('click', function(){
                setCookie('cookie_consent', 1, 1);
                //localStorage.setItem('cookie_consent', 1);

                $('.cookie_policy').hide();
                //console.log('cookie_consent click');
            });
            
            function setCookie(cname, cvalue, exdays) {
              //console.log('set cookie');
              var d = new Date();
              d.setTime(d.getTime() + (exdays*24*60*60*1000));
              var expires = "expires="+ d.toUTCString();
              document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
            }

            function getCookie(cname) {
              var name = cname + "=";
              var ca = document.cookie.split(';');
              for(var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == ' ') {
                  c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                  return c.substring(name.length, c.length);
                }
              }
              return "";
            }
;            
          // Add attribute so All links in this container opens in a new tab
          $('.footer-container a, .footer-container2 a').attr('target', '_blank');


        });


  },
  updated() {
    let vm = this;
	//console.log(vm.$jss);
	//console.log(vm.$t);
	
	var propertyid = vm.$jss.property();
	var hotelDetails = {longitude:{value: 0}, latitude:{value:0}, Title:{value:'HotelJen Hong Kong'}};
	if (propertyid && propertyid != null){
		$('.footer-container-property').removeClass('hidden');

		propertyid = propertyid.replace(/\-/g,"").toUpperCase();
		hotelDetails = getObject(getObjectById(vm.$jss.hotels(),propertyid));
		if (hotelDetails)
		{
			if (hotelDetails && hotelDetails.address)
			{
				vm.address= hotelDetails.address.value;
			}
			if (hotelDetails && hotelDetails.email)
			{
				vm.email= hotelDetails.email.value;
			}
			if (hotelDetails && hotelDetails.longitude)
				vm.longitude= hotelDetails.longitude.value;
			if (hotelDetails && hotelDetails.latitude)
				vm.latitude= hotelDetails.latitude.value;
			
			if (hotelDetails && hotelDetails.fullname)
				$('.footer-container-property .footer-propertyname').html(hotelDetails.fullname.value);
			// if (hotelDetails && hotelDetails.phone)
      // 	$('.footer-container-property .footer-propertynumber').html(hotelDetails.phone.value);

			if (hotelDetails && hotelDetails.phone) {
				$('.footer-container-property .footer-propertynumber').html(hotelDetails.phone.value);
				const phoneNumber = hotelDetails.phone.value.replace(/[+ - )(]/g, '');
				$('.footer-container-property .footer-propertynumber')
				  .closest('a')
				  .attr('href', `tel:+${phoneNumber}`);
			}

			if (hotelDetails && hotelDetails.longitude && hotelDetails.latitude)
			{
				$('.footer-container-property .footer-propertylocation').attr('href', 'https://www.google.com/maps/search/?api=1&query='+hotelDetails.longitude.value + ',' + hotelDetails.latitude.value);
			}
			else
			{
				if (hotelDetails && hotelDetails.address)
					$('.footer-container-property .footer-propertylocation').html(hotelDetails.address.value);
				if (hotelDetails && hotelDetails.address)
					$('.footer-container-property .footer-propertylocation').attr('href', 'http://maps.google.com/?q='+hotelDetails.address.value);
			}
			$('.footer-container-property .footer-propertylocation').unbind('click').bind('click',function(){
				//console.log($(this).attr('href'));
				window.open($(this).attr('href'));
			});
		}
	}else{
		//$('.footer-container-property').hide();
	}
	
	//console.log(hotelDetails);
	
	$('.footer-property-location').unbind('click').bind('click', function(e){
		e.preventDefault();
		var propertyAddress = $(this).attr('data-address');
		var propertyLng = $(this).attr('data-lng');
		var propertyLat = $(this).attr('data-lat');
		
		if (propertyAddress && propertyAddress != ''){
			//window.open('http://maps.google.com/?q='+propertyAddress);
			if ($('.HJContainer').attr('data-geolocation') != undefined && $('.HJContainer').attr('data-geolocation') != '')
			{ 
				if ($('.HJContainer').attr('data-geolocation').toLowerCase() == "cn")
				{
					window.open('https://api.map.baidu.com/marker?location=' + propertyLat + ',' + propertyLng + '&output=html');
				}
				else
				{
					window.open('https://www.google.com/maps/search/?api=1&query=' + propertyLng + ',' + propertyLat);				
				}
			}
			else
				window.open('https://www.google.com/maps/search/?api=1&query=' + propertyLng + ',' + propertyLat);
		}
	});
	
  },
  methods: {
	getDictionaryPhrase: function(key)
	{
		return getDictionaryPhrase(key, this);
	},
    isproperty: function(name) {
      if (this.fields != null && this.fields[name] != undefined) {
        return this.fields[name];
      } else {
        if (this.fields.data.datasource && this.fields.data.datasource.fields) {
          for (var i = 0; i < this.fields.data.datasource.fields.length; i++) {
            if (this.fields.data.datasource.fields[i].name == name) {
              if (
                this.fields.data.datasource.fields[i].targetItems &&
                this.fields.data.datasource.fields[i].targetItems.length > 0
              ) {
                if (this.fields.data.datasource.fields[i].targetItems[0].textField)
                  return this.fields.data.datasource.fields[i].targetItems[0].textField.value;
                else if (this.fields.data.datasource.fields[i].targetItems[0].title)
                  return this.fields.data.datasource.fields[i].targetItems[0].textField.title;
                else return '';
              } else {
                return this.fields.data.datasource.fields[i].value;
              }
            }
          }
        }
      }
      return null;
    },
  },
};
</script>
