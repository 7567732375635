import axios from 'axios';
import ApolloClient from 'apollo-boost';
import gql from 'graphql-tag';
import { enableExperimentalFragmentVariables } from 'graphql-tag';
import {getHotelCurrencyPrice, getCurrencyPrice, getminprice, getprice} from './personalise.js';
import {sitecoredatetodatetag, appendAuthHeaders} from './hjutil.js';
//import 'babel-polyfill';
import config from './temp/config';


/**
 * Implements a data fetcher using Axios - replace with your favorite
 * SSR-capable HTTP or fetch library if you like. See HttpJsonFetcher<T> type
 * in sitecore-jss library for implementation details/notes.
 * @param {string} url The URL to request; may include query string
 * @param {any} data Optional data to POST with the request.
 */

/* comment out Sitecore.Services.GraphQL.config, change 15 to 100
*	<complexityConfiguration type="GraphQL.Validation.Complexity.ComplexityConfiguration, GraphQL">
*		<!--<maxDepth>15</maxDepth>-->
*		<!--<maxComplexity>2000</maxComplexity>-->
*		<!--<fieldImpact>2</fieldImpact>-->
*		<maxDepth>100</maxDepth>
*	</complexityConfiguration>
 * 
 *
 */


function getData(
    data,
    vm,
    graphqlresults,
    client,
    type,
    action,
	issearch,
    templatefilter,
    skipparent,
	property,
	isfeatureimageonly,
	travellertype,
	event,
	context,
	isloadmore
	,isgraphql
	,searchresults
	, targetobject
	,targetobjectid
	,keeprawdata
  ) {
	  
	  
	
//console.log(data);
  if (!Object.keys) {
    Object.keys = (function() {
      'use strict';
      var hasOwnProperty = Object.prototype.hasOwnProperty,
        hasDontEnumBug = !{ toString: null }.propertyIsEnumerable('toString'),
        dontEnums = [
          'toString',
          'toLocaleString',
          'valueOf',
          'hasOwnProperty',
          'isPrototypeOf',
          'propertyIsEnumerable',
          'constructor',
        ],
        dontEnumsLength = dontEnums.length;

      return function(obj) {
        if (typeof obj !== 'function' && (typeof obj !== 'object' || obj === null)) {
          throw new TypeError('Object.keys called on non-object');
        }

        var result = [],
          prop,
          i;

        for (prop in obj) {
          if (hasOwnProperty.call(obj, prop)) {
            result.push(prop);
          }
        }

        if (hasDontEnumBug) {
          for (i = 0; i < dontEnumsLength; i++) {
            if (hasOwnProperty.call(obj, dontEnums[i])) {
              result.push(dontEnums[i]);
            }
          }
        }
        return result;
      };
    })();
  }

    if (keeprawdata == undefined || keeprawdata == null)
		keeprawdata = false;
    if (isloadmore == undefined || isloadmore == null)
		isloadmore = false;
	//	check if need to convert
    if (isgraphql == undefined || isgraphql == null)
		isgraphql = true;
    let room_fragment = `
...on HJRoomTemplate {
	  features
	  {
			targetItems {
...on ConstantsHJRoomFeatureTypeTemplate {
			title {
			  value
			}
			description
			{
			  value
			}
}
	  }
    }
}
`;


    var findnode = function(o, ar) {
      for (var i = 0; i < o['children'].length; i++) {
        if (o['children'][i]['templatename'] && o['children'][i]['templatename'] == 'hjform') {
          ar = graphqlToObjectFormObject(o['children'][i]);
          break;
        }
        if (o['children'][i]['children'] != null) {
          findnode(o['children'][i]);
        }
      }
    };
    var updateHotel = function(data, vm, graphqlresults) {
      var _ar = graphqlresults;
      for (
        var i = 0;
        i < ar['children'].length;
        i++ //	hotels
      ) {
        if (ar['children'][i]['children'] != null) {
          //	has images or room folder
          for (
            var j = 0;
            j < ar['children'][i]['children'].length;
            j++ //	has images or room folder
          ) {
            if (
              ar['children'][i]['children'][j]['templatename'] != null &&
              ar['children'][i]['children'][j]['templatename'].toLowerCase() == 'folder'
            ) {
              for (
                var k = 0;
                k < ar['children'][i]['children'][j]['children'].length;
                k++ //	has rooms
              ) {
                if (ar['children'][i]['children'][j]['children'][k]['features']) {
                  var __ar = [];
                  //var o = {};
                  //o["description"] = ""
                  //o["children"] = _
                }
              }
            }
          }
        }
      }
    };

	var ar = [];
	if (type != null && type != '')
	{
		if (isgraphql)
		{
			if (data)
			{
				if(data.data)
				{
					if (data.data.item)
					{
						ar = graphqlToObject(type, data.data.item, vm, templatefilter, skipparent, false, null, false, keeprawdata);
					}
					else if (data.search && data.search.results)
							ar = graphqlToObject(type, data.search.results, vm, templatefilter, skipparent, false, null, false, keeprawdata);
					else if (data.data && data.data.search && data.data.search.results)
							ar = graphqlToObject(type, data.data.search.results, vm, templatefilter, skipparent, false, null, false, keeprawdata);
					else if (data.data.data && data.data.data.item && data.data.data.item)
							ar = graphqlToObject(type, data.data.data.item, vm, templatefilter, skipparent, false, null, false, keeprawdata);
					else if (data.data.data && data.data.data.search && data.data.data.search.results)
							ar = graphqlToObject(type, data.data.data.search.results, vm, templatefilter, skipparent, false, null, false, keeprawdata);
					else
						ar = graphqlToObject(type, data.data, vm, templatefilter, skipparent, false, null, false, keeprawdata);
				}
				else if (data['placeholders'])
				{
					var _keys = Object.keys(data['placeholders']);
					for (var l = 0; l < _keys.length; l++) {
						if (_keys[l].toLowerCase() == "jss-main")
						{
							for (var m=0; m < data['placeholders'][_keys[l]].length; m++)
							{
								if (data['placeholders'][_keys[l]][m]['componentName'].toLowerCase() == type.toLowerCase())
								{
									if (data['placeholders'][_keys[l]][m]['fields'] && data['placeholders'][_keys[l]][m]['fields']['data'])
										ar = graphqlToObject('', data['placeholders'][_keys[l]][m]['fields']['data'], vm, templatefilter, skipparent, false, null, false, keeprawdata);
								}
							}
						}
					}
				}
				else
				{
					if (data instanceof Array && data.length > 0 && ((data[0].data && data[0].data.search && data[0].data.search.results) || (data[0].data && data[0].data.item) || data[0] instanceof String))
					{
						var objArray = [];
						for (var i=1; i < data.length; i++)
						{
							if (data[i] && data[i].data && data[i].data.search && data[i].data.search.results)
								objArray.push(graphqlToObject(type, data[i].data.search.results, vm, templatefilter, skipparent, false, null, false, keeprawdata));
							else if (data[i] && data[i].data && data[i].data.item)
							{
								objArray.push(graphqlToObject(type, data[i].data.item, vm, templatefilter, skipparent, false, null, false, keeprawdata));
							}
							else if (data[i] && data[i] instanceof String)
							{
								if (getCookie('HJ_GEO_GLOBAL_COOKIE') == null) {
									setCookie('HJ_GEO_GLOBAL_COOKIE', data[i], 365);
									vm.$jss.store.setHJGeoCookie(data[i]);
								} 
								if (!localStorage.getItem('curcurrency'))
								{
									setCurrencyByGeo(data[i], vm);
								}
							}
						}
						if (type.toLowerCase() == "hjdealstay")
						{
							objArray.push(vm.$jss.rooms());
							objArray.push(vm.$jss.hotels());
						}
						else if ( 
							type.toLowerCase() == "hjdealeat"
						)
						{
							objArray.push(vm.$jss.hotels());
						}
						else if ( 
							type.toLowerCase() == "hjdealspace"
						)
						{
							objArray.push(vm.$jss.hotels())
						}
						else if ( 
							type.toLowerCase() == "hjdealwedding"
						)
						{
							objArray.push(vm.$jss.hotels())
						}
						else if ( 
							type.toLowerCase() == "hjdeal"
						)
						{
							objArray.push(vm.$jss.hotels());
						}
						else if ( 
							type.toLowerCase() == "hjroom-template"
						)
						{
							objArray.push(vm.$jss.rooms());
							//objArray.push(vm.$jss.features());
							objArray.push(vm.$jss.hotels());
						}
						else if ( 
							type.toLowerCase() == "hotel" ||
							type.toLowerCase() == "stay"
						)
						{
							objArray.push(vm.$jss.hotels());
						}
						else if ( 
							type.toLowerCase() == "hjspace" ||
							type.toLowerCase() == "hjdealspace"
						)
						{
							objArray.push(vm.$jss.hotels());
						}
						else if ( 
							type.toLowerCase() == "hjworkout"
						)
						{
							//objArray.push(vm.$jss.features());
							objArray.push(vm.$jss.hotels());
							//objArray.push(vm.$jss.openingtimes());
						}
						else if ( 
							type.toLowerCase() == "hjrestaurant"
						) 
						{
							objArray.push(vm.$jss.hotels());
							//objArray.push(vm.$jss.openingtimes());
						}
						else if ( 
							type.toLowerCase() == "hjevent"
						)
						{
							objArray.push(vm.$jss.hotels());
						}
						else if ( 
							type.toLowerCase() == "hjhostcelebrate" ||
							type.toLowerCase() == "hjhostmeet"
						)
						{
							objArray.push(vm.$jss.hotels());
							//objArray.push(vm.$jss.features());
							//objArray.push(vm.$jss.openingtimes());
						}
						else if ( 
							type.toLowerCase() == "hjnearby"
						)
						{
							objArray.push(vm.$jss.hotels());
						}
						else if ( 
							type.toLowerCase() == "hjservice"
						)
						{
							objArray.push(vm.$jss.hotels());
						
						}
						if (data[0].data && data[0].data.search && data[0].data.search.results)
							ar = graphqlToObject(type, data[0].data.search.results, vm, templatefilter, skipparent, true, objArray, false, keeprawdata);
						else if (data[0].data && data[0].data.item)
						{
							ar = graphqlToObject(type, data[0].data.item, vm, templatefilter, skipparent, true, objArray, false, keeprawdata);
						}
						//resolverelationships(ar, objArray);
						//console.log(ar);
						//console.log(objArray);
					}
					else
					{
					if (data.item)
						ar = graphqlToObject(type, data.item, vm, templatefilter, skipparent, false, null, false, keeprawdata);
					else if (data.search && data.search.results)
							ar = graphqlToObject(type, data.search.results, vm, templatefilter, skipparent, false, null, false, keeprawdata);
					else if (data.data && data.data.data && data.data.data.search && data.data.data.search.results)
							ar = graphqlToObject(type, data.data.data.search.results, vm, templatefilter, skipparent, false, null, false, keeprawdata);
					else
					{
						ar = graphqlToObject(type, data, vm, templatefilter, skipparent, false, null, false, keeprawdata);
					}
				}
			}
		}
		}
		else
		{
			ar = data;
		}
	}
	else
	{
		if (data)
		{
			if (data.data)
			{
				if (data.data.item)
					ar = graphqlToObject(type, data.data.item, vm, templatefilter, skipparent);
				else if (data.search && data.search.results)
						ar = graphqlToObject(type, data.search.results, vm, templatefilter, skipparent, false, null, false, keeprawdata);
				else if (data.data && data.data.search && data.data.search.results)
						ar = graphqlToObject(type, data.data.search.results, vm, templatefilter, skipparent, false, null, false, keeprawdata);
				else if (data.data.data && data.data.data.item && data.data.data.item)
						ar = graphqlToObject(type, data.data.data.item, vm, templatefilter, skipparent, false, null, false, keeprawdata);
				else
					ar = graphqlToObject(type, data.data, vm, templatefilter, skipparent, false, null, false, keeprawdata);
			}
			else if (data['placeholders'])
			{
				var _keys = Object.keys(data['placeholders']);
				for (var l = 0; l < _keys.length; l++) {
					if (_keys[l].toLowerCase() == "jss-main")
					{
						ar = graphqlToObject('', data['placeholders'][_keys[l]], vm, templatefilter, skipparent, false, null, false, keeprawdata);
					}
				}
			}
			else
			{
				if (data.item)
					ar = graphqlToObject(type, data.item, vm, templatefilter, skipparent, false, null, false, keeprawdata);
				else if (data.search && data.search.results)
					ar = graphqlToObject(type, data.search.results, vm, templatefilter, skipparent, false, null, false, keeprawdata);
				else if (data.data && data.data.search && data.data.search.results)
					ar = graphqlToObject(type, data.data.search.results, vm, templatefilter, skipparent, false, null, false, keeprawdata);
				else
				{
					ar = graphqlToObject(type, data, vm, templatefilter, skipparent, false, null, false, keeprawdata);
				}
			}
		}
	} 

	if (type.toLowerCase() == "allsociety")
	{
		vm.$jss.store.setAllsociety(data[0].data.item);
		//console.log(ar);
		//var new_ar = graphqlToObject_allsociety(type, data[0].data.item, vm, templatefilter, skipparent);
		//console.log(new_ar);
	}

    if (type.toLowerCase() == 'hotel') {
      for (
        var i = 0;
        i < ar['children'].length;
        i++ //	hotels
      ) {
        if (ar['children'][i]['children'] != null) {
          //	has images or room folder
          //console.log('inside hotels has children');
          for (
            var j = 0;
            j < ar['children'][i]['children'].length;
            j++ //	has images or room folder
          ) {
            if (
              ar['children'][i]['children'][j]['templatename'] != null &&
              ar['children'][i]['children'][j]['templatename'].toLowerCase() == 'folder'
            ) {
              for (
                var k = 0;
                k < ar['children'][i]['children'][j]['children'].length;
                k++ //	has rooms
              ) {
                if (ar['children'][i]['children'][j]['children'][k]['features']) {
                  var path = ar['children'][i]['children'][j]['children'][k]['id']
                    .toString()
                    .replace('{', '')
                    .replace('}', '');
                  //var query = gql`	//	 used by apollo client
					var query = gql`
						query {item(path: "${path}")
						{
							...on HJRoomTemplate {
								features
								{
									targetItems {
										...on ConstantsHJRoomFeatureTypeTemplate {
											title {
												value
												}
												description
												{
													value
												}
												image
												{
													jss
												}
											}
										}
									}
								}
							}
						}`;

		/*axios.post('/api/apollo', {data:query}).then(result => { 
			console.log(result);
		})
		.catch( err => { console.log(err) } )
*/


                  client
                    .query({
                      query: gql(query),
                    })
                    .then((data) => {
                      //console.log(data);
                    })
                    .catch((error) => console.error(error));
				
                }
              }
            }
          }
        }
      }
    }
    if (type.toLowerCase() == 'form') {
		if (ar['children'])
		{
      for (var i = 0; i < ar['children'].length; i++) {
        if (ar['children'][i]['templatename'] && ar['children'][i]['templatename'] == 'hjform') {
          ar = graphqlToObjectFormObject(ar['children'][i]);
          break;
        }
        if (ar['children'][i]['children'] != null) {
          findnode(ar['children'][i], ar);
        }
      }
		}
		//console.log(ar);
    }
	if (!(action == undefined || action == null))
	{
		action(data, vm, ar, type,property, event, context, isloadmore, graphqlresults, searchresults, targetobject, targetobjectid);
	}
	else
	{
		return ar;
	}
  }

function resolverelationships(o, objArray, templatename, fieldname)
{
  if (!Object.keys) {
    Object.keys = (function() {
      'use strict';
      var hasOwnProperty = Object.prototype.hasOwnProperty,
        hasDontEnumBug = !{ toString: null }.propertyIsEnumerable('toString'),
        dontEnums = [
          'toString',
          'toLocaleString',
          'valueOf',
          'hasOwnProperty',
          'isPrototypeOf',
          'propertyIsEnumerable',
          'constructor',
        ],
        dontEnumsLength = dontEnums.length;

      return function(obj) {
        if (typeof obj !== 'function' && (typeof obj !== 'object' || obj === null)) {
          throw new TypeError('Object.keys called on non-object');
        }

        var result = [],
          prop,
          i;

        for (prop in obj) {
          if (hasOwnProperty.call(obj, prop)) {
            result.push(prop);
          }
        }

        if (hasDontEnumBug) {
          for (i = 0; i < dontEnumsLength; i++) {
            if (hasOwnProperty.call(obj, dontEnums[i])) {
              result.push(dontEnums[i]);
            }
          }
        }
        return result;
      };
    })();
  }

	if (objArray != null && objArray.length > 0)
	{
		for(var j=0; j < objArray.length; j++)
		{
			if (objArray[j] && objArray[j]['children'] && objArray[j]['children'] && objArray[j]['children'].length > 0 && objArray[j]['children'][0]['templatename'] && objArray[j]['children'][0]['templatename'].toLowerCase() === templatename.toLowerCase())
			{
				if (o[fieldname] && o[fieldname].value && o[fieldname].value != '')
				{
					var propertyArray = [];
					if (o[fieldname].value.indexOf("|") != -1)
					{
						propertyArray = o[fieldname].value.split("|");
					}
					else
					{
						propertyArray.push(o[fieldname].value);
					}
					for (var k=0; k < propertyArray.length; k++)
					{
						propertyArray[k] = propertyArray[k].replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim();
					}
					for (var k=0; k < propertyArray.length; k++)
					{
						if (!o[fieldname]['children'])
						{
							o[fieldname]['children'] = [];
						}
						if (!o[fieldname]['targetItems'])
						{
							o[fieldname]['targetItems'] = [];
						}
						var _o = objArray[j]['children'].find(x => x.id.replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim() === propertyArray[k].replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim());
						if (_o != null && _o != undefined)
						{
							var jss = {};
							var hasvalue = false;
							var _keys = Object.keys(_o);
							for (var m = 0; m < _keys.length; m++) {
								if (_keys[m].toLowerCase().substring(0,2) != "__")
								{
									if (_keys[m].toLowerCase() === 'value' || _keys[m].toLowerCase() === 'id')
									{
										jss[_keys[m]] = _o["id"];
									}
									//else if (_keys[m].toLowerCase() === 'image')
									//{
									//	console.log(_o["image"]);
									//	jss["image"] = //_o["image"].jss.value.src;
									//}
									else
									{
										if (typeof _o[_keys[m]] === 'object')
										{
											jss[_keys[m]] = Object.assign({}, _o[_keys[m]]);
										}
										else
										{
											jss[_keys[m]] = _o[_keys[m]];
										}
									}
								}
							}
							
							var emptyIndex = -1;
							if (fieldname.toLowerCase() == "property")
							{
								for (var m=0; m < o[fieldname]['children'].length; m++)
								{
									if(!o[fieldname]['children'][m]['id'])
									{
										emptyIndex = m;
										break;
									}
								}
							}
							if (emptyIndex != -1)
								o[fieldname]['children'][emptyIndex] = jss;
							else
								o[fieldname]['children'].push(jss);
							
							emptyIndex = -1;
							if (fieldname.toLowerCase() == "property")
							{
								for (var m=0; m < o[fieldname]['targetItems'].length; m++)
								{
									if(!o[fieldname]['targetItems'][m]['id'])
									{
										emptyIndex = m;
										break;
									}
								}
							}
							if (emptyIndex != -1)
								o[fieldname]['targetItems'][emptyIndex] = jss;
							else
								o[fieldname]['targetItems'].push(jss);
						}
					}
				}
			}
		}
	}
}

export function getObject(
	data, vm, property, isfeatureimageonly, travellertype, type, isgraphql 
)
{

	if (property == undefined || property == null)
		property = null;
  if (isfeatureimageonly == undefined || isfeatureimageonly == null)
	  isfeatureimageonly = false;
  if (travellertype == undefined || travellertype == null)
	  travellertype = "other";
  if (type == undefined || type == null)
	  type = "";
  if (isgraphql == undefined || isgraphql == null)
	  isgraphql = false;
	return getData(data, vm, null, null, type, null, false, '', null, property, isfeatureimageonly, travellertype, null, null, false, isgraphql);
}

export function getMoreContentByType(
	type, vm, action, pagesize, lastitemid, property, isfeatureimageonly, travellertype, event 
)
{
	if (property == undefined || property == null)
	{
		property = null;
	}
	if (event == undefined || event == null)
	{
		event = undefined;
	}
	if (pagesize == undefined || pagesize == null)
	{
		pagesize = 3;
	}
	var path = "";
	var graphchildren = "";
	if (lastitemid != undefined && lastitemid != null && lastitemid != '')
	{
		graphchildren = '(first: ' + pagesize.toString() + ' after: "' + lastitemid + '")';
	}
	else
	{
		graphchildren = '(first: ' + pagesize.toString() + ')';
	}
	
	var childrenlevel1 = "";
	var childrenlevel2 = "";
	var childrenlevel3 = "";
	var templateId = "";
	
	if (type.toLowerCase() == "hjdealstay" || 
		type.toLowerCase() == "hjdealeat" || 
		type.toLowerCase() == "hjdealspace" || 
		type.toLowerCase() == "hjdealwedding" || 
		type.toLowerCase() == "hjdeal") 
	{		
		path = "/sitecore/content/hoteljen/Content/deals";
		templateId  = getTemplateId(vm.templates, vm, type, true);
		if (templateId != '')
		{	
			if (graphchildren != '')
				graphchildren = '(includeTemplateIDs:"' + templateId.trim() + '" ' + graphchildren.substr(1, graphchildren.trim().length-1);
			else
				graphchildren = '(includeTemplateIDs:"' + templateId.trim() + '")';
		}
		childrenlevel1 = graphchildren;
		childrenlevel2 = "";
		childrenlevel3 = "";
	}
	else if (type.toLowerCase() == "hotel" || type.toLowerCase() == "stay")
	{
		path = "/sitecore/content/hoteljen/Content/hotels";
		templateId  = getTemplateId(vm.templates, vm, type, true);
		if (templateId != '')
		{	
			if (graphchildren != '')
				graphchildren = '(includeTemplateIDs:"' + templateId.trim() + '" ' + graphchildren.substr(1, graphchildren.trim().length-1);
			else
				graphchildren = '(includeTemplateIDs:"' + templateId + '")';
		}
		childrenlevel1 = graphchildren;
		childrenlevel2 = "";
		childrenlevel3 = "";
	}
	else if (type.toLowerCase() == "hjrestaurant" || type.toLowerCase() == "hjworkout" ||
	type.toLowerCase() == "hjspace" || type.toLowerCase() == "hjevent"
	)
	{
		path = "/sitecore/content/hoteljen/Content/data";
		templateId  = getTemplateId(vm.templates, vm, type, true);
		if (templateId != '')
		{	
			if (graphchildren != '')
				graphchildren = '(includeTemplateIDs:"' + templateId.trim() + '" ' + graphchildren.substr(1, graphchildren.trim().length-1);
			else
				graphchildren = '(includeTemplateIDs:"' + templateId + '")';
		}
		childrenlevel1 = "";
		childrenlevel2 = graphchildren;
		childrenlevel3 = "";
	}
	else if (type.toLowerCase() == "hjhost" || 
	type.toLowerCase() == "hjhostmeet" || 
	type.toLowerCase() == "hjhostcelebrate")
	{
		templateId  = getTemplateId(vm.templates, vm, type, true);
		if (templateId != '')
		{	
			if (graphchildren != '')
				graphchildren = '(includeTemplateIDs:"' + templateId.trim() + '" ' + graphchildren.substr(1, graphchildren.trim().length-1);
			else
				graphchildren = '(includeTemplateIDs:"' + templateId + '")';
		}
		path = "/sitecore/content/hoteljen/Content/hosts";
		childrenlevel1 = graphchildren;
		childrenlevel2 = "";
		childrenlevel3 = "";
		
	}
	else if (type.toLowerCase() == "hjnearby")
	{
		path = "/sitecore/content/hoteljen/Content/nearby";
		templateId  = getTemplateId(vm.templates, vm, type, true);
		if (templateId != '')
		{	
			if (graphchildren != '')
				graphchildren = '(includeTemplateIDs:"' + templateId.trim() + '" ' + graphchildren.substr(1, graphchildren.trim().length-1);
			else
				graphchildren = '(includeTemplateIDs:"' + templateId + '")';
		}
		childrenlevel1 = graphchildren;
		childrenlevel2 = "";
		childrenlevel3 = "";
	}
	else
	{
		path = "/sitecore/content/hoteljen/Content";
		childrenlevel1 = "";
		childrenlevel2 = "";
		childrenlevel3 = "";
	}
	
	//var query = gql`query {item(path:"${path}") {
	var query = `query {item(path:"${path}") {
	   id
		template
		{
			name
		}
		fields
		{
		  __typename
		  name
		  value
		  editable
		}
		children${childrenlevel1}
		{
			id
			template
			{
				name
			}
			fields
			{
			  __typename
			  name
			  value
			  editable						  
			}
			children${childrenlevel2}
			{
				id
				template
				{
					name
				}
				fields
				{
				  __typename
				  name
				  value
				  editable						  
				}
				children${childrenlevel3}
				{
					id
					template
					{
						name
					}
					fields
					{
					  __typename
					  name
					  value
					  editable						  
					}
					children
					{
						id
						template
						{
							name
						}
						fields
						{
						  __typename
						  name
						  value
						  editable						  
						}
						children
						{
							id
							template
							{
								name
							}
							fields
							{
							  __typename
							  name
							  value
							  editable						  
							}
						}
					}
				}
			}
		}
	}  
}`

  var _getMoreContentByType = function(query, graphchildren, type, vm, action, pagesize, lastitemid, property, isfeatureimageonly, travellertype, event) {
	  	  
	  const client = new ApolloClient({
		uri: vm.$jss.hjconfig().sitecoreApiHost + '/sitecore/api/' + vm.$jss.hjconfig().jssAppName + '?sc_lang='+vm.$jss.routeData().itemLanguage + '&sc_apikey=' + vm.$jss.hjconfig().sitecoreSCApiKey,
		defaultOptions: {
		 fetchPolicy: 'no-cache',
		 //fetchPolicy: 'cache-first',
		},
	  });

	  graphqlFetcher(
		'',
		vm,
		vm.fields.graphqlresults,
		'',
		query,
		client,
		action,
		false,
		null,
		null,
		graphchildren,
		pagesize,
		property,
		isfeatureimageonly,
		travellertype
	  );
  }
	/*
	if (vm.$jss.hjconfig() == null)
	{
		$.getJSON( "/api/gethjconfig", function( data ) {
			vm.$jss.store.setHjconfig(data);
			vm.hjconfig = vm.$jss.hjconfig(); 
			_getMoreContentByType(query, graphchildren, type, vm, action, pagesize, lastitemid, property, isfeatureimageonly, travellertype, event);
		});			
	}
	else
	{*/
		return _getMoreContentByType(query, graphchildren, type, vm, action, pagesize, lastitemid, property, isfeatureimageonly, travellertype, event);
	/*}*/
}

export function getContentByType(
	data, type, vm, isgraphql, isfeatureimageonly, travellertype, isservices, maxcount, property, eventtype, checkindate, likes, maximages, ignoreallfilter, sorted
)
{
		//var countries = getContentByType(vm.$jss.constants(), "hjcountry", this, false, false, 'other', false);

	if (ignoreallfilter == undefined || ignoreallfilter == null)
	{
		ignoreallfilter = false;
	}
	if (sorted == undefined || sorted == null)
	{
		sorted = false;
	}
	if (isfeatureimageonly == undefined || isfeatureimageonly == null)
	{
		isfeatureimageonly = false;
	}
	if (isservices == undefined || isservices == null)
	{
		isservices = false;
	}	
	else
	{
	}
	if (property == undefined || property == null  || property == ''  || property == '*' || property == 'all')
	{
		property = null;
	}
	if (eventtype == undefined || eventtype == null  || eventtype == ''  || eventtype == '*' || eventtype == 'all')
	{
		eventtype = null;
	}
	if (checkindate == undefined || checkindate == null  || checkindate == '')
	{
		checkindate = null;
	}
	if (likes == undefined || likes == null)
	{
		likes = [];
	}
	if (maxcount == undefined || maxcount == null)
	{
		maxcount = 30;
	}	
	if (maximages == undefined || maximages == null)
	{
		maximages = 2;
	}	
	var results = [];

	var currentcount = 0;
	if (isgraphql == undefined || isgraphql == true)
	{
		isgraphql = true;
	}
	
	if (isgraphql)
	{
		data = getObject(data, vm, property, isfeatureimageonly, travellertype);
	}
	//var _getContentByType = function(data, type, vm, isgraphql, isfeatureimageonly, travellertype, isservices, maxcount, property, eventtype, checkindate, likes, maximages){
		/*
		const client = new ApolloClient({
		uri: vm.$jss.hjconfig().sitecoreApiHost + '/sitecore/api/' + vm.$jss.hjconfig().jssAppName + '?sc_apikey=' + vm.$jss.hjconfig().sitecoreSCApiKey,
		defaultOptions: {
		  //fetchPolicy: 'no-cache',
		  fetchPolicy: 'cache-first',
		},
		});
		*/
	//}

	
	var sortContentByType = function(item, type, isfeatureimageonly, travellertype)
	{
		if (!(isfeatureimageonly == undefined || isfeatureimageonly == false))
		{
			var count = 0;
			var featuredIndex = -1;
			var firstImageIndex = -1;
			var imagecount = 0;
			if (item["children"] != null)
			{
				for(var i=item["children"].length-1; i >= 0; i--)
				{
					if (item['children'][i].templatename && item["children"][i].templatename == "hjimage")
					{
						if (!(item["children"][i]["isfeatured"] == null || item["children"][i]["isfeatured"] == false))
						{
							featuredIndex = i;
						}
						firstImageIndex = i;
					}
				}
				for(var i=item["children"].length-1; i >= 0; i--)
				{
					if (featuredIndex > -1)
					{
						if (i != featuredIndex)
						{
							item["children"].splice(i, 1);
						}
					}
					else
					{
						if (firstImageIndex > -1)
						{
							if (i != firstImageIndex)
							{
								item["children"].splice(i, 1);
							}
						}
						else
						{
							if (item["children"][i].templatename == "hjimage")
							{
								if (maximages != -1)
								{
									if (imagecount > maximages)
									{
										item["children"].splice(i, 1);
									}
								}
								imagecount++;
							}
						}
					}
				}
			}	
			if (imagecount == 0)
			{
			}
		}
	}
	
	var checkifadd = function(item, eventtype, checkindate, likes, type, vm, ignoreallfilter)
	{
		
		var isadd = true;
		if (!ignoreallfilter)
		{
			if (item['hidden'] && 
				item['hidden'].value && 
				item['hidden'].value == '1')
			{
				isadd = false;
				return isadd;
			}
			if (checkindate != null && 
				checkindate != '' &&
				(item['enddate'] && 
				item['enddate'].value && 
				item['enddate'].value != ''))
			{
				var datestring = item["enddate"].value;
				var enddate = new Date(datestring.substring(0,4)+"-"+datestring.substring(4,6)+"-"+datestring.substring(6,8));
				if (new Date(checkindate) > new Date(enddate))
				{	
					isadd = false;
					return isadd;
				}
			}
			if ((type.toLowerCase() == 'hjevent' || type.toLowerCase() == 'hjworkout' || type.toLowerCase() == 'hjspace') && 
			(item['issociety'] && item['issociety'].value  && item['issociety'].value == '1'))
			{
				//console.log(item);
				//console.log(vm.datasource);
				if (vm != null && vm.datasource && vm.datasource["showlikeonly"] && vm.datasource["showlikeonly"].value &&
				vm.datasource["showlikeonly"].value === '1')
				{
					isadd = true;
					return;
				}
				else
				{
					if (!((vm != null && vm.datasource && vm.datasource["showsociety"] && vm.datasource["showsociety"].value &&
					vm.datasource["showsociety"].value === '1') || 
					(vm != null && vm.datasource && vm.datasource["showsocietyonly"].value && vm.datasource["showsocietyonly"].value === '1'))) 
					{
						if (item['issociety'].value === '1')
						{
							isadd = false;
							return isadd;
						}
					}
				}
			}
		}
		return isadd;
	};
	
	var _checkifadd = function(item, eventtype, checkindate, likes, type, vm, ignoreallfilter)
	{
		var isadd = true;
		//if ((eventtype != null && item["eventtype"] && item["eventtype"] != undefined && item["eventtype"].value)
		if ((checkindate != null && item["enddate"] && item["enddate"].value)
			)
		{
		}
		return isadd;
	};		
	var getChildren = function(item, type, results, isfeatureimageonly, travellertype, isservices, currentcount, maxcount, property, eventtype, checkindate, likes, vm, ignoreallfilter){
		var count = 0;
		var isadd = false;
		
		var _item = item['children'] && item['children'].length > 0 ? item['children'] : item;
		for(var i=0; i < _item.length; i++)
		{
			//if (false)
			if (isservices)
			{
				//	there is no limit for services, assume not that many 
				if (_item[i]['contentcategory']  && 
					_item[i]['contentcategory']['children'] &&
					_item[i]['contentcategory']['children'].length > 0 &&
					_item[i]['contentcategory']['children'][0].value.toLowerCase() === type.toLowerCase())
				{
					if (property != null && _item[i]["property"])
					{
						var propertyvalue = '';
						if (!(_item[i]["property"].value && _item[i]["property"].value != ''))
						{
							if (_item[i]["property"] && 
								_item[i]["property"]["children"] &&
								_item[i]["property"]["children"].length > 0 &&
								_item[i]["property"]["children"][0]["id"]
								)
							{
								if (_item[i]["property"].value)
								{
									if (_item[i]["property"].value == '')
										_item[i]["property"].value = _item[i]["property"]["children"][0]["id"];
								}
								else
									_item[i]["property"].value = _item[i]["property"]["children"][0]["id"];
							}
						}
						if (property.toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim() === propertyvalue.toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim())
						{
							var __item = _item[i];
							results.push(__item);									
						}
					}
					else
					{
						var __item = _item[i];
						results.push(__item);									
					}
				}
				else
				{
					if (_item[i]["children"] != null && _item[i]["children"].length > 0)
					{
						getChildren(_item[i], type, results, isfeatureimageonly, travellertype, isservices, currentcount, maxcount, property, eventtype, checkindate, likes, vm, ignoreallfilter);
					}					
				}
			}
			else
			{
				if (_item[i].templatename && _item[i].templatename.toLowerCase() == type.toLowerCase())
				{
					if ((isfeatureimageonly != undefined && isfeatureimageonly == true)
						|| (travellertype != undefined && travellertype != null))
					{
						if (property != null && property != '' && _item[i]["property"])
						{
							var propertyvalue = '';
							if (_item[i]["property"].value)
								propertyvalue = _item[i]["property"].value
							if (!(_item[i]["property"].value && _item[i]["property"].value != ''))
							{
								if (_item[i]["property"] && 
									_item[i]["property"]["children"] &&
									_item[i]["property"]["children"].length > 0 &&
									_item[i]["property"]["children"][0]["id"]
									)
								{
									if (_item[i]["property"].value)
									{
										if (_item[i]["property"].value == '')
											_item[i]["property"].value = _item[i]["property"]["children"][0]["id"];
									}
									else
										_item[i]["property"].value = _item[i]["property"]["children"][0]["id"];
								}
							}
							if (property.toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim() === propertyvalue.toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim())
							{
								var __item = _item[i];
								sortContentByType(__item, type, isfeatureimageonly, travellertype);					
								if (checkifadd(_item[i], eventtype, checkindate, likes, type, vm, ignoreallfilter))
								{
										results.push(__item);									
								}
								currentcount++;
								if (currentcount > maxcount && maxcount > 0)
									break;
							}
						}
						else
						{
							var __item = _item[i];
							sortContentByType(__item, type, isfeatureimageonly, travellertype);					
							if (checkifadd(__item, eventtype, checkindate, likes, type, vm, ignoreallfilter))
							{
									results.push(__item);									
							}
							currentcount++;
							if (currentcount > maxcount && maxcount > 0)
								break;
						}
					}
					else
					{
						if (property != null && property != '' && _item[i]["property"])
						{
							var propertyvalue = '';
							if (_item[i]["property"].value)
								propertyvalue = _item[i]["property"].value
							if (!(_item[i]["property"].value && _item[i]["property"].value != ''))
							{
								if (_item[i]["property"] && 
									_item[i]["property"]["children"] &&
									_item[i]["property"]["children"].length > 0 &&
									_item[i]["property"]["children"][0]["id"]
									)
								{
									if (_item[i]["property"].value)
									{
										if (_item[i]["property"].value == '')
											_item[i]["property"].value = _item[i]["property"]["children"][0]["id"];
									}
									else
										_item[i]["property"].value = _item[i]["property"]["children"][0]["id"];
								}
							}
							if (property.toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim() === propertyvalue.toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim())
							{
								if (checkifadd(_item[i], eventtype, checkindate, likes, type, vm, ignoreallfilter))
								{
										results.push(_item[i]);									
								}
							}
						}
						else
						{
							if (checkifadd(_item[i], eventtype, checkindate, likes, type, vm, ignoreallfilter))
							{
									results.push(_item[i]);									
							}
						}
						currentcount++;
						if (currentcount > maxcount && maxcount > 0)
							break;
					}
				}
				else
				{
					if (_item[i]["children"] != null && _item[i]["children"].length > 0)
					{
						getChildren(_item[i], type, results, isfeatureimageonly, travellertype, isservices, currentcount, maxcount, property, eventtype, checkindate, likes, vm, ignoreallfilter);
					}					
				}
			}
		}
	}
	
	var getdata = function(
		results, data, type, vm, isfeatureimageonly, travellertype, isservices, maxcount, property, eventtype, checkindate, likes, ignoreallfilter
		){
		if (data && data.templatename && data.templatename == type)
		{
			if (data['contentcategory']  && 
				data['contentcategory']['children'] &&
				data['contentcategory']['children'].length > 0 &&
				data['contentcategory']['children'][0].value.toLowerCase() == type.toLowerCase())
			{
				if (property != null && property != '' && item["children"][i]["property"] && item["children"][i]["property"].value)
				{
					if (property.toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim() == item["children"][i]["property"]["value"].toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim())
					{
								if (checkifadd(data, eventtype, checkindate, likes, type, vm, ignoreallfilter))
								{
										results.push(data);									
								}
					}
				}
				else
				{
					if (checkifadd(data, eventtype, checkindate, likes, type, vm, ignoreallfilter))
					{
							results.push(data);									
					}
				}
				currentcount++;
			}
		}
		else
		{
			if (data.length > 0 || (data["children"] != null && data["children"].length > 0))
			{
				getChildren(data, type, results, isfeatureimageonly, travellertype, isservices, currentcount, maxcount, property, eventtype, checkindate, likes, vm, ignoreallfilter);
			}
		}
	}
	if (likes != null && likes.length > 0)
	{
		getdata(results, data, type, vm, isfeatureimageonly, travellertype, isservices, maxcount, property, eventtype, checkindate, likes, ignoreallfilter);
	}
	else
	{
		getdata(results, data, type, vm, isfeatureimageonly, travellertype, isservices, maxcount, property, eventtype, checkindate, likes, ignoreallfilter);
	}
	// results = results.sort(dynamicsort("orderindex","asc"));
	// return results;
	return iterateResults(results)
}

function dynamicsort(property,order) {
    var sort_order = 1;
    if(order === "desc"){
        sort_order = -1;
    }
    return function (a, b){
        // a should come before b in the sorted order
		if (a[property])
		{
			if (a[property].value)
			{
				if(a[property].value < b[property].value){
						return -1 * sort_order;
				// a should come after b in the sorted order
				}else if(a[property].value > b[property].value){
						return 1 * sort_order;
				// a and b are the same
				}else{
						return 0 * sort_order;
				}
			}
			else
			{
				if(a[property] < b[property]){
						return -1 * sort_order;
				// a should come after b in the sorted order
				}else if(a[property] > b[property]){
						return 1 * sort_order;
				// a and b are the same
				}else{
						return 0 * sort_order;
				}
			}
		}
		return 0;
    }
}


export function graphqlFetcher(
  type,
  vm,
  graphqlresults,
  settingspath,
  overridegql,
  client,
  action,
  issearch,
  templatefilter,
  skipparent,
  graphqlchildren,
  pagesize,
  property,
  isfeatureimageonly, 
  travellertype,
  isloadmore
) {
	
   
  if (typeof property == 'undefined') {
    property = null;
  }
  if (typeof pagesize == 'undefined') {
    pagesize = 3;
  }
  if (typeof issearch == 'undefined') {
    issearch = false;
  }

  if (typeof templatefilter == 'undefined') {
    templatefilter = [];
  }
  if (typeof skipparent == 'undefined') {
    skipparent = false;
  }
  if (typeof graphqlchildren == 'undefined') {
    graphqlchildren = '';
  }
  if (isfeatureimageonly == undefined || isfeatureimageonly == null)
	  isfeatureimageonly = false;
  if (travellertype == undefined || travellertype == null)
	  travellertype = "other";
  if (settingspath == null || settingspath == '')
	settingspath = '/sitecore/content/' + config.jssAppName + '/Content/Config';
  if (isloadmore == undefined || isloadmore == null)
	  isloadmore = false;

  var fetchquery = function(
    data,
    vm,
    graphqlresults,
    client,
    type,
    getData,
    action,
	issearch,
    templatefilter,
    skipparent,
    graphqlchildren,
	pagesize,
	property,
	eventtype, 
	isfeatureimageonly,
	travellertype,
	isloadmore
  ) {
    //let graphchildren = "(first: 3)";

    var o = {};
    var ar = [];

    for (var i = 0; i < data.data.item.children.length; i++) {
      o = {};
      for (var j = 0; j < data.data.item.children[i].fields.length; j++) {
        o[data.data.item.children[i].fields[j].name] = data.data.item.children[i].fields[j].value;
      }
      ar.push(o);
    }

    let query = '';
    let path = '';
    let fragment = '';
    switch (type) {
      case 'hotel':
        path = ar[0]['hjhotelpath'];
        break;
      case 'event':
        path = ar[0]['hjeventpath'];
        break;
      case 'nearby':
        path = ar[0]['hjnearbypath'];
        break;
      case 'restaurant':
        path = ar[0]['hjrestaurantpath'];
        break;
      case 'space':
        path = ar[0]['hjspacepath'];
        break;
      case 'workout':
        path = ar[0]['hjworkoutpath'];
        break;
    }

    let image_fragment = `
						  ... on ImageField
						  {
							  jss
						  }
	   `;
    let linkfield_fragment = `
					  ... on LinkField
					  {
						  url
					  }
	   `;
    let hotel_fragment = `
...on HJHotelTemplate {
	  amenities
	  {
			targetItems {
...on ConstantsHJAmenityTypeTemplate {
			title {
			  value
			}
			description
			{
			  value
			}
			image 
			{
			  jss
			}
}
	  }
    }
}
`;
    //let room_fragment = `
    //
    //	   `;

    let room_fragment = `
			...on HJRoomTemplate {
				features
				{
					targetItems {
						...on ConstantsHJRoomFeatureTypeTemplate {
							title {
								value
							}
							description
							{
								value
							}
						}
					}
				}
			}
`;

    switch (type) {
      case 'hotel':
		//query = gql`
        query = `	
					query {item(path: "${path}")
					{
					   id
						template
						{
							name
						}
						fields(ownFields:true)
						{
						  __typename
						  name
						  value
						  editable
						}
						children${graphqlchildren}
						{
							id
							template
							{
								name
							}
							fields(ownFields:true)
							{
							  __typename
							  name
							  value
							  editable						  
							}
							${hotel_fragment}
							children
							{
								id
								template
								{
									name
								}
								fields(ownFields:true)
								{
									__typename
									name
									value
									editable
								}
								children
								{
									id
									template
									{
										name
									}
									fields(ownFields:true)
									{
										__typename
										name
										value
										editable
									}
									children
									{
										id
										template
										{
											name
										}
										fields(ownFields:true)
										{
											__typename
											name
											value
											editable
										}
									}
								}
							}
						}
					}
					}`;
        break;
      case 'room':
        //query = gql`	
        query = `	
					query {item(path: "${path}")
					{
						children${graphqlchildren}
						{
							children
							{
								id
								template
								{
									name
								}
								fields(ownFields:true)
								{
									__typename
									name
									value
									editable
								}
								${room_fragment}
								children
								{
									id
									template
									{
										name
									}
									fields(ownFields:true)
									{
										__typename
										name
										value
										editable
									}
								}
							}
						}
					}
					}`;
        break;
      case 'event':
      case 'nearby':
      case 'restaurant':
      case 'space':
      case 'workout':
        //query = gql`	
        query = `
					query {item(path: "${path}")
					{
					   id
						template
						{
							name
						}
						fields(ownFields:true)
						{
						  __typename
						  name
						  value
						  editable
						}
						children${graphqlchildren}
						{
							id
							template
							{
								name
							}
							fields(ownFields:true)
							{
							  __typename
							  name
							  value
							  editable
							}
							children
							{
								id
								template
								{
									name
								}
								fields(ownFields:true)
								{
									__typename
									name
									value
									editable
								}
								children
								{
									id
									template
									{
										name
									}
									fields(ownFields:true)
									{
										__typename
										name
										value
										editable
									}
								}
							}
						}
					}
					}`;
        break;
    }



    client
      .query({
        query: gql(query),
      })
      .then((data) => {
        getData(data, vm, graphqlresults, client, type, action, issearch, templatefilter, skipparent, property, null, isfeatureimageonly, travellertype, null, null, isloadmore);
      })
      .catch((error) => console.error(error));
  };	//	END: fetchquery

  var _graphqlFetcher = function(){
	  client = new ApolloClient({
		uri: vm.$jss.hjconfig().sitecoreApiHost + '/api/' + vm.$jss.hjconfig().jssAppName + '?sc_lang='+vm.$jss.routeData().itemLanguage + '&sc_apikey=' + vm.$jss.hjconfig().sitecoreSCApiKey,
		defaultOptions: {
		  //fetchPolicy: 'no-cache',
		  fetchPolicy: 'cache-first',
		},
	  });


	  if (type != null && type != '' && 
		!(type == 'form' || type == 'detail')) {
		// type is defined but type is not form or detail
		//let query = gql`	
		let query = `
				query {item(path: "${settingspath}") 
				{
					id
					children
					{
						__typename
						fields(ownFields:true)
						{
							name
							value
						}
					}
				}
				}
				`;

		client
		  .query({
			query: gql(query),
		  })
		  .then((data) => {
			console.log('fetchquery');
			fetchquery(
			  data,
			  vm,
			  graphqlresults,
			  client,
			  type,
			  getData,
			  action,
			  issearch,
			  templatefilter,
			  skipparent,
			  graphqlchildren,
			  pagesize,
			  property,
			  isfeatureimageonly,
			  travellertype,
			  isloadmore
			);
		  })
		  .catch((error) => console.error(error));
	  } else {
		if (client != null && !(overridegql == null || overridegql == ''))
		{
			if (issearch == true)
			{
				searchMoreContentByType(graphqlresults, type, vm, action, pagesize, null, null, property, isfeatureimageonly, travellertype, null); 
			}
			else
			{
				var auth = {
						headers: {} 
					}
				appendAuthHeaders(auth.headers, vm.$jss.hjconfig().hmac_appsecret)
				axios.post('/api/apollo', 
				{data:overridegql, lang:vm.$jss.routeData().itemLanguage}, auth).then(result => { 
					getData(result, vm, graphqlresults, client, type, action, issearch,templatefilter, skipparent, property, isfeatureimageonly, travellertype, null, null, isloadmore);
				})
				.catch( err => { console.log(err) } )
				
				/*
				client
				  .query({
					query: overridegql,
				  })
				  .then((data) => {
					getData(data, vm, graphqlresults, client, type, action, issearch,templatefilter, skipparent, property, isfeatureimageonly, travellertype, null, null, isloadmore);
				  })
				  //.then(data => {console.log(data);})
				  .catch((error) => console.error(error));
				 */
			}
		}
		else
		{
			if (type == null || type == '')
			{
				getData(vm.datasource, vm, graphqlresults, client, type, action, issearch, templatefilter, null, property, isfeatureimageonly, travellertype, null, null, isloadmore);
			}
			else if (type == 'hotel')
			{
				/*if (vm != null && vm.$jss.hotels() != null)
				{
					getData(vm.$jss.hotels(), vm, graphqlresults, client, type, action, issearch, templatefilter, null, property, isfeatureimageonly, travellertype, null, null, isloadmore, false);
				}
				else
				{*/
					//vm.$jss.store.setHotels(vm.getObject(vm.$jss.hotels(), vm));
					getData(vm.$jss.hotels(), vm, graphqlresults, client, type, action, issearch, templatefilter, null, property, isfeatureimageonly, travellertype, null, null, isloadmore, false);
				/*}*/
			}
			else if (type == 'deal')
			{
				if (vm != null && vm.$jss.deals() != null)
				{
					getData(vm.$jss.deals(), vm, graphqlresults, client, type, action, issearch, templatefilter, null, property, isfeatureimageonly, travellertype, null, null, isloadmore, false);
				}
				else
				{
					vm.$jss.store.setDeals(vm.getObject(vm.deals, vm));
					getData(vm.$jss.deals(), vm, graphqlresults, client, type, action, issearch, templatefilter, null, property, isfeatureimageonly, travellertype, null, null, isloadmore, false);
				}
			}
			else if (type == 'host' || type == 'hostmeet' || type == 'hostcelebrate'  )
			{
				if (vm != null && vm.$jss.hosts() != null)
				{
					getData(vm.$jss.hosts(), vm, graphqlresults, client, type, action, issearch, templatefilter, null, property, isfeatureimageonly, travellertype, null, null, isloadmore, false);
				}
				else
				{
					vm.$jss.store.setHosts(vm.getObject(vm.hosts, vm));
					getData(vm.$jss.hosts(), vm, graphqlresults, client, type, action, issearch, templatefilter, null, property, isfeatureimageonly, travellertype, null, null, isloadmore, false);
				}
			}
			else if (type == 'nearby')
			{
				if (vm != null && vm.$jss.nearby() != null)
				{
					getData(vm.$jss.nearby(), vm, graphqlresults, client, type, action, issearch, templatefilter, null, property, isfeatureimageonly, travellertype, null, null, isloadmore, false);
				}
				else
				{
					vm.$jss.store.setNearby(vm.getObject(vm.nearby, vm));
					getData(vm.nearby, vm, graphqlresults, client, type, action, issearch, templatefilter, null, property, isfeatureimageonly, travellertype, null, null, isloadmore, false);
				}
			}
			else if (type == 'society')
			{
				/*
				console.log(vm.$jss.society());
				if (vm != null && vm.$jss.society() != null)
				{
					getData(vm.$jss.society(), vm, graphqlresults, client, type, action, issearch, templatefilter, null, property, isfeatureimageonly, travellertype, null, null, isloadmore, false);
				}
				else
				{*/
					vm.$jss.store.setSociety(vm.getObject(vm.society, vm));
					getData(vm.$jss.society(), vm, graphqlresults, client, type, action, issearch, templatefilter, null, property, isfeatureimageonly, travellertype, null, null, isloadmore, false);
				/*}*/
			}
			else
			{
				getData(vm.datasource, vm, graphqlresults, client, type, action, issearch, templatefilter, null, property, isfeatureimageonly, travellertype, null, null, isloadmore);
			}
		}
	  }
	}
	
	/*if (vm.$jss.hjconfig() == null)
	{
		$.getJSON( "/api/gethjconfig", function( data ) {
			vm.$jss.store.setHjconfig(data);
			_graphqlFetcher(type, vm, graphqlresults, settingspath, overridegql, client, action, issearch, templatefilter, skipparent, graphqlchildren, pagesize, property, isfeatureimageonly, travellertype, isloadmore);
		});			
	}
	else
	{*/
		_graphqlFetcher(type, vm, graphqlresults, settingspath, overridegql, client, action, issearch, templatefilter, skipparent, graphqlchildren, pagesize, property, isfeatureimageonly, travellertype, isloadmore);
	/*}*/
}

export function graphqlToObject(type, item, vm, templatefilter, skipparent, mergeconstants, objArray, buildobjectonly, keeprawdata) {
	
	//console.log(type);

  if (keeprawdata)
	  return item;
  
  if (!Object.keys) {
    Object.keys = (function() {
      'use strict';
      var hasOwnProperty = Object.prototype.hasOwnProperty,
        hasDontEnumBug = !{ toString: null }.propertyIsEnumerable('toString'),
        dontEnums = [
          'toString',
          'toLocaleString',
          'valueOf',
          'hasOwnProperty',
          'isPrototypeOf',
          'propertyIsEnumerable',
          'constructor',
        ],
        dontEnumsLength = dontEnums.length;

      return function(obj) {
        if (typeof obj !== 'function' && (typeof obj !== 'object' || obj === null)) {
          throw new TypeError('Object.keys called on non-object');
        }

        var result = [],
          prop,
          i;

        for (prop in obj) {
          if (hasOwnProperty.call(obj, prop)) {
            result.push(prop);
          }
        }

        if (hasDontEnumBug) {
          for (i = 0; i < dontEnumsLength; i++) {
            if (hasOwnProperty.call(obj, dontEnums[i])) {
              result.push(dontEnums[i]);
            }
          }
        }
        return result;
      };
    })();
  }

	//var ssrdata = getObject(vm.$jss.routeData(), vm, null, null, null, "hjheader");

  var constants = null;
  var hotels = null;
  var amenities = null;
  var travellertypes = null;
  var contentcategories = null;
  var features = null;
  var eventtypes = null;
  
  
  if (objArray == null || objArray == undefined)
  {
	  objArray = [];
  }
  if (mergeconstants == undefined)
  {
	mergeconstants = true;
  }
  if (buildobjectonly == undefined)
  {
	buildobjectonly = false;
  }

  mergeconstants = false;
  if(mergeconstants)
  {
		constants = vm.$jss.constants();
		if (vm.$jss.travellertypes() != null)
			travellertypes = vm.$jss.travellertypes();
		else
		{
			vm.$jss.store.setTravellertypes(getContentByType(vm.$jss.constants(), "hjtravellertype", vm, false, false, 'other', false));
			travellertypes = vm.$jss.travellertypes();
		}
		if (vm.$jss.features() != null)
			features = vm.$jss.features();
		else
		{
			vm.$jss.store.setFeatures(getContentByType(vm.$jss.constants(), "constants-hjroomfeature-type-template", vm, false, false, 'other', false));
			features = vm.$jss.features();
		}
		if (vm.$jss.contentcategories() != null)
			contentcategories = vm.$jss.contentcategories();
		else
		{
			vm.$jss.store.setFeatures(getContentByType(vm.$jss.constants(), "hjcontentcategory", vm, false, false, 'other', false));
			contentcategories = vm.$jss.contentcategories();
		}
  }

  var populateConstants = function(o, key, constants)
  {
	var _arr = [];
	var jss = {};
	if (o[key])
	{
		if (!(o[key]['children'] && o[key]['children'] != null && o[key]['children'].length > 0))
		{
			if (constants != undefined && constants != null)
			{
			  ar_ = [];
			  if (o[key] && o[key].value)
			  {
				  _arr = [];
				  if (o[key].value.indexOf('|') != -1)
					_arr = o[key].value.split('|');
				  else
					_arr.push(o[key].value.trim());
				  for (var _j = 0; _j < _arr.length; _j++) {
					jss = {};
					jss['value'] = "";
					for (var k=0; k < constants.length; k++)
					{
						if (constants[k]["id"].toString().toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "") === _arr[_j].replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").toLowerCase())
						{
							var hasvalue = false;
							var _keys = Object.keys(constants[k]);
							for (var l = 0; l < _keys.length; l++) {
								if (_keys[l].toLowerCase() == "value")
									hasvalue = true;
								if (constants[k][_keys[l]].value)
									jss[_keys[l]] = constants[k][_keys[l]].value;
								else
								{
									if (_keys[l].toLowerCase() === 'value')
									{
										jss[_keys[l]] = constants[k]["id"].value.replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim();
										hasvalue = true;
									}
									else
										jss[_keys[l]] = constants[k][_keys[l]].value;
								}
							}
							if (!hasvalue)
								jss["value"] = constants[k]['id'].value;
							break;
						}
					}
					ar_.push(jss);
				  }
			  }
				o[key]['children'] = ar_;
			}
		}
	}
  }
  var getgraphqlobject = function(item, type, vm, templatefilter, changelowercase, constants, hotels, amenities, travellertypes, contentcategories, features, objArray, buildobjectonly) 
  {
		var _ar = [];
		var o = {};
		var jss = {};
		var _arr = [];
		var data = {};
		if (changelowercase == undefined)
			changelowercase = true;
		var o = {};
		if (item.template != null && typeof item.template != 'undefined') {
			o['templatename'] = item.template.name.toLowerCase();
		}
        o['id'] = item.id;
		if (item.fields && typeof item.fields != 'undefined') {
			for (var j = 0; j < item.fields.length; j++) {
			  if (item.fields[j].__typename.toLowerCase() == 'imagefield') {
				jss = {};
				jss['__typename'] = item.fields[j].__typename.toLowerCase();
				jss['value'] = item.fields[j].value;
				if (
				  item.fields[j].editable != null &&
				  item.fields[j].editable != ''
				) {
				  var regexp = /(src=\")[\""A-Za-z0-9.;!#$%&'*+-\/=?^_`{|}~\p{L}\p{Nd}]{1,}(\")/g;
				  var match = item.fields[j].editable.match(regexp);
				  if (match != null && match.length > 0)
				  {
					  jss['src'] = match[0].substring(5, match[0].length - 1);
					  regexp = /(alt=\")[\""A-Za-z0-9.;!#$%&'*+-\/=?^_`{|}~\p{L}\p{Nd}]{0,}(\")/g;
					  match = item.fields[j].editable.match(regexp);
					  if (match != null && match.length > 0)
						jss['alt'] = match[0].substring(5, match[0].length - 1);
					  regexp = /(width=\")[\""A-Za-z0-9.;!#$%&'*+-\/=?^_`{|}~\p{L}\p{Nd}]{0,}(\")/g;
					  match = item.fields[j].editable.match(regexp);
					  if (match != null && match.length > 0)
						jss['width'] = match[0].substring(7, match[0].length - 1);
					  regexp = /(height=\")[\""A-Za-z0-9.;!#$%&'*+-\/=?^_`{|}~\p{L}\p{Nd}]{0,}(\")/g;
					  match = item.fields[j].editable.match(regexp);
					  if (match != null && match.length > 0)
						jss['height'] = match[0].substring(8, match[0].length - 1);
					  jss['editable'] = item.fields[j].editable;
				  }
				}
				else
				{
				  jss['src'] = '';
				  jss['width'] = '';
				  jss['height'] = '';
				  jss['editable'] = '';
				}
				if (changelowercase)
					o[item.fields[j].name.toLowerCase()] = jss;
				else
					o[item.fields[j].name] = jss;
			  } else if (item.fields[j].__typename.toLowerCase() == 'generallink' ||
				item.fields[j].__typename.toLowerCase() == 'linkfield'
			  ) {
				jss = {};
				jss['__typename'] = item.fields[j].__typename.toLowerCase();
				if (
				  item.fields[j].editable != null &&
				  item.fields[j].editable != ''
				) {
				  if (item.fields[j].value.indexOf("internal") != -1 || item.fields[j].value.indexOf("external") != -1)
				  {
				  var regexp = /(href=\")[\""A-Za-z0-9.;:!#$%&'*+-\/=?^_`{|}~\p{L}\p{Nd}]{1,}(\")/g;
				  var match = item.fields[j].editable.match(regexp);
				  if (match != null && match.length > 0)
				  {
					jss['value'] = match[0].substring(6, match[0].length - 1);
				  }
				  else
				  {
					  jss['value'] = '';
				  }					  
				  if (changelowercase)
					o[item.fields[j].name.toLowerCase()] = jss;
				  else
					o[item.fields[j].name] = jss;
				  }
				  else
				  {
				  var regexp = /(url=\")[\""A-Za-z0-9.;:!#$%&'*+-\/=?^_`{|}~\p{L}\p{Nd}]{1,}(\")/g;
				  var match = item.fields[j].editable.match(regexp);
				  if (match != null && match.length > 0)
				  {
					jss['value'] = match[0].substring(5, match[0].length - 1);
				  }
				  else
				  {
					  jss['value'] = '';
				  }
				  if (changelowercase)
					o[item.fields[j].name.toLowerCase()] = jss;
				  else
					o[item.fields[j].name] = jss;
				  }
				}
				else
				{
					jss['value'] = item.fields[j].value;
				  if (changelowercase)
					o[item.fields[j].name.toLowerCase()] = jss;
				  else
					o[item.fields[j].name] = jss;
				}
			  } else {
				jss = {};
				jss['__typename'] = item.fields[j].__typename.toLowerCase();
				jss['value'] = item.fields[j].value;
			  if (changelowercase)
				o[item.fields[j].name.toLowerCase()] = jss;
			  else
				o[item.fields[j].name] = jss;
			  }
			}
		}
		//if (!buildobjectonly)
		//{
			if (item.AdobeHotelID || item.AdobeSection || item.AdobePlacementPosition || item.AdobePlacementFormat ||
				item.AdobePlacementCategory || item.AdobeCampaignName || item.AdobeLanguageAudienceName)
			{
				o['adobetrackingcode'] = "";
				var adobetrackingurl = "";
				if (item.AdobeHotelID && item.AdobeHotelID.value && item.AdobeHotelID.value != null)
				{
					adobetrackingurl += item.AdobeHotelID.value;
				}
				if (item.AdobeSection && item.AdobeSection.value && item.AdobeSection.value != null)
				{
					adobetrackingurl += '_' + item.AdobeSection.value;
				}				
				else
				{
					adobetrackingurl += '_';
				}
				if (item.AdobePlacementPosition && item.AdobePlacementPosition.value && item.AdobePlacementPosition.value != null)
				{
					adobetrackingurl += '_' + item.AdobePlacementPosition.value;
				}
				else
				{
					adobetrackingurl += '_';
				}
				if (item.AdobePlacementFormat && item.AdobePlacementFormat.value && item.AdobePlacementFormat.value != null)
				{
					adobetrackingurl += '_' + item.AdobePlacementFormat.value;
				}
				else
				{
					adobetrackingurl += '_';
				}
				if (item.AdobePlacementCategory && item.AdobePlacementCategory.value && item.AdobePlacementCategory.value != null )
				{
					adobetrackingurl += '_' + item.AdobePlacementCategory.value; 
				}
				else
				{
					adobetrackingurl += '_';
				}
				if (item.AdobeCampaignName && item.AdobeCampaignName.value && item.AdobeCampaignName.value != null)
				{
					adobetrackingurl += '_' + item.AdobeCampaignName.value;
				}
				else
				{
					adobetrackingurl += '_';
				}
				if (item.AdobeLanguageAudienceName && item.AdobeLanguageAudienceName.value && item.AdobeLanguageAudienceName.value != null)
				{
					adobetrackingurl += '_' + item.AdobeLanguageAudienceName.value;
				}
				else
				{
					adobetrackingurl += '_';
				}
				o['adobetrackingcode'] = '?cid=' +  encodeURI(adobetrackingurl);
			}
		//}		
        var keys = Object.keys(item);
        for (var j = 0; j < keys.length; j++) {
          if ((keys[j] != 'fields' && keys[j].substring(0,2) != '__') ||
		  keys[j] == '__typename') {
            o[keys[j]] = item[keys[j]];
			if (item[keys[j]] && item[keys[j]].targetItems && item[keys[j]].targetItems.length > 0) {
				ar_ = [];
				for (var k = 0; k < item[keys[j]].targetItems.length; k++) {
					jss = {};
					var _keys = Object.keys(item[keys[j]].targetItems[k]);
					for (var l = 0; l < _keys.length; l++) {
						if (item[keys[j]].targetItems[k][_keys[l]] && item[keys[j]].targetItems[k][_keys[l]].value)
							jss[_keys[l]] = item[keys[j]].targetItems[k][_keys[l]].value;
						else
						{
							if (_keys[l].toLowerCase() === 'id')
								jss[_keys[l]] = item[keys[j]].targetItems[k][_keys[l]].replace("&#8208;", "").replace("-", "").replace("{", "").replace("}", "").trim();
							else if (_keys[l].toLowerCase() === 'image')
							{
								if (item[keys[j]].targetItems[k][_keys[l]])
								{
									if (item[keys[j]].targetItems[k][_keys[l]].jss && item[keys[j]].targetItems[k][_keys[l]].jss.value && item[keys[j]].targetItems[k][_keys[l]].jss.value.src) {
									  jss['image'] = item[keys[j]].targetItems[k][_keys[l]].jss.value.src;
									}
								}
							}
							else
								jss[_keys[l]] = item[keys[j]].targetItems[k][_keys[l]];
						}
					}
					ar_.push(jss);
				}
				o[keys[j]]['children'] = ar_;
			}
          }
        }
		//populateConstants(o, 'property', hotels);
		////populateConstants(o, 'amenities', amenities);
		////populateConstants(o, 'contentcategory', contentcategories);
		//populateConstants(o, 'eventtype', eventtypes);
		if (objArray != null && objArray != undefined && objArray.length > 0)
		{
			resolverelationships(o, objArray, "HJContentCategory", "hjcontentcategory");
			resolverelationships(o, objArray, "HJRestaurant", "restaurant");
			resolverelationships(o, objArray, "HJHotel-Template", "property");
			resolverelationships(o, objArray, "HJRoom-Template", "room");
			resolverelationships(o, objArray, "HJRoom-Template", "property");
			//resolverelationships(o, objArray, "constants-hjroomfeature-type-template", "features");
			//resolverelationships(o, objArray, "HJOpenCloseTime", "openingtime");
		}
	 
	  //if (!buildobjectonly)
	  //{
		  if (item.contentCategory != null) {
			//console.log(item);
			ar_ = [];
			if (item.contentCategory.targetItems && item.contentCategory.targetItems.length > 0) {
			  //jss = {};
			  for (var j = 0; j < item.contentCategory.targetItems.length; j++) {
				  jss = {};
				if (item.contentCategory.targetItems[j].title)
					jss['title'] = item.contentCategory.targetItems[j].title.value;
				else
					jss['title'] = "";
				if (item.contentCategory.targetItems[j].value)
					jss['value'] = item.contentCategory.targetItems[j].value.value;
				else
					jss['value'] = "";
				if (item.contentCategory.targetItems[j].description)
					jss['description'] = item.contentCategory.targetItems[j].description.value;
				else
					jss['description'] = "";
				ar_.push(jss);
			  }
			}
			if (o['contentcategory'] != null) {
			  o['contentcategory']['children'] = ar_;
			}
		  }
		  if (item.layoutType != null) {
			ar_ = [];
			jss = {};
			jss['value'] = "carousel";
			if (item.layoutType.targetItems && item.layoutType.targetItems.length > 0) {
				if (item.layoutType.targetItems[0].textField)
					jss['value'] = item.layoutType.targetItems[0].textField.value;
			}
			o['layouttype'] = jss;
		  }
		  if (item.room != null) {
			ar_ = [];
			if (item.room.targetItems && item.room.targetItems.length > 0) {
			  for (var j = 0; j < item.room.targetItems.length; j++) {
				jss = {};
				jss['title'] = "";
				jss['description'] = "";
				jss['roomtypecode'] = "";
				jss['rateplancode'] = "";
				jss['roomcode'] = "";
				if (item.room.targetItems[j].title)
					jss['title'] = item.room.targetItems[j].title.value;
				if (item.room.targetItems[j].description)
					jss['description'] = item.room.targetItems[j].description.value;
				if (item.room.targetItems[j].roomtypecode)
					jss['roomtypecode'] = item.room.targetItems[j].roomtypecode.value;
				if (item.room.targetItems[j].roomcode)
					jss['roomcode'] = item.room.targetItems[j].roomcode.value;
				if (item.room.targetItems[j].rateplancode)
					jss['rateplancode'] = item.room.targetItems[j].rateplancode.value;
				ar_.push(jss);
			  }
			}
			if (o['room'] != null) {
			  o['room']['children'] = ar_;
			}
		  }
		  
		  if (item.openingTime != null) {
			ar_ = [];
			if (item.openingTime.targetItems && item.openingTime.targetItems.length > 0) {
			  for (var j = 0; j < item.openingTime.targetItems.length; j++) {
				jss = {};
				jss['day'] = "";
				jss['open'] = "";
				jss['close'] = "";
				jss['description'] = "";
				jss['moredescription'] = "";
				if (item.openingTime.targetItems[j].day)
					jss['day'] = item.openingTime.targetItems[j].day.value;
				if (item.openingTime.targetItems[j].open)
					jss['open'] = item.openingTime.targetItems[j].open.value;
				if (item.openingTime.targetItems[j].close)
					jss['close'] = item.openingTime.targetItems[j].close.value;
				//if (item.openingTime.targetItems[j].Description)
				//	jss['description'] = item.openingTime.targetItems[j].Description.value;
				if (item.openingTime.targetItems[j].description)
					jss['description'] = item.openingTime.targetItems[j].description.value;
				if (item.openingTime.targetItems[j].moredescription)
					jss['moredescription'] = item.openingTime.targetItems[j].moredescription.value;
				else
				{
					if (item.openingTime.targetItems[j].description)
						jss['moredescription'] = item.openingTime.targetItems[j].description.value;
				}
				ar_.push(jss);
			  }
			}
			if (o['openingtime'] != null) {
			  o['openingtime']['children'] = ar_;
			}
		  }
		if (vm != null && vm.hotel_prices && vm.hotel_prices != undefined && vm.hotel_prices != null)
		{
		  if (o['templatename'] && o['templatename'].toLowerCase() === "hjroom-template")
		  {
			//console.log(o["property"]);
			if (o["property"] && 
				o["property"]["children"] &&
				o["property"]["children"].length > 0 &&
				o["property"]["children"][0]["hotelcode"] &&
				o["roomtypecode"] &&
				o["roomtypecode"].value)
			{
				if (o["property"] && o["property"]["children"] && o["property"]["children"].length > 0)
				{
					if (type.toLowerCase() === "hjroom-template" || type.toLowerCase() === "hjimage")
					{
						//o["price"] = getprice(o["property"]["children"][0]["hotelcode"], o["roomtypecode"].value, vm.hotel_prices, vm.$jss.currency(), vm.$jss.travellertype());
					}
				}
			}
		  }
		  else if (o['templatename'] && 
		  (o['templatename'].toLowerCase() == "hjdeal" ||
		  o['templatename'].toLowerCase() == "hjdealstay" || 
		  o['templatename'].toLowerCase() == "hjdealeat" || 
		  o['templatename'].toLowerCase() == "hjdealspace" || 
		  o['templatename'].toLowerCase() == "hjdealwedding" || 
		  o['templatename'].toLowerCase() == "hjevent"))
		  {
			if (o["price"] && o["price"] != null && o["price"].value > 0)
			{
				if (o["currency"])
				{
					if (!(o["currency"].value && o["currency"].value != null && o["currency"].value != ''))
						o["currency"].value = '';
					if (o["currency"].value != '')
					{
						o["price"] = getCurrencyPrice(o["price"].value, "", vm.hotel_prices, o["currency"].value, vm.$jss.currency(), vm.$jss.travellertype());
					}
					else
					{
						if (o["property"] && 
							o["property"]["children"] &&
							o["property"]["children"].length > 0 &&
							o["property"]["children"][0]["hotelcode"])
						{
							o["price"] = getCurrencyPrice(o["price"].value, o["property"]["children"][0]["hotelcode"], vm.hotel_prices, o["currency"].value, vm.$jss.currency(), vm.$jss.travellertype());
						}
					}
				}
				else
				{
					if (o["property"] && 
						o["property"]["children"] &&
						o["property"]["children"].length > 0 &&
						o["property"]["children"][0]["hotelcode"])
					{
						if (type.toLowerCase() === "hjroom-template" || type.toLowerCase() === "hjimage")
							o["price"] = getCurrencyPrice(o["price"].value, o["property"]["children"][0]["hotelcode"], vm.hotel_prices, o["currency"].value, vm.$jss.currency(), vm.$jss.travellertype());
					}
				}
			}
		  }
		  else if (o['templatename'] && 
			o['templatename'].toLowerCase() == "hjimage")
		  {
			//console.log(o["property"]);
			if (o["property"] && o["property"]["children"] && o["property"]["children"].length > 0) {
				if (o["property"]["children"][0]["hotelcode"] != '')
				{
					//if (type.toLowerCase() === "hjroom-template" || type.toLowerCase() === "hjimage")
						//o["price"] = getminprice(o["property"]["children"][0]["hotelcode"], vm.hotel_prices, vm.$jss.currency(), vm.$jss.travellertype());
				}
			} else if (o["property"] && o["property"].value && o["property"].value != '')
			{	
				//if (type.toLowerCase() === "hjroom-template" || type.toLowerCase() === "hjimage")
				//	o["price"] = gethotelminprice(vm.$jss.hotels(), vm, o["property"].value, vm.hotel_prices, vm.$jss.currency(), vm.$jss.travellertype());
			}
		  }
		}
		/*if (o['templatename'].toLowerCase() == "hjrestaurant")
			console.log(o);
		*/
		if (o['templatename'] && (o['templatename'].toLowerCase() == "hjroom-template" ||
			o['templatename'].toLowerCase() == "hjevent" ||
			o['templatename'].toLowerCase() == "hjworkout" ||
			o['templatename'].toLowerCase() == "hjrestaurant" ||
			o['templatename'].toLowerCase() == "hjspace" ||
			o['templatename'].toLowerCase() == "hjhostmeet" ||
			o['templatename'].toLowerCase() == "hjhostcelebrate"
			)
		)
		{
			
			if (o['section'] || o['urlalias'])
			{
				/*if (o['templatename'].toLowerCase() == "hjrestaurant")
				{
					console.log(o['section']);
					console.log(o['urlalias']);
				}*/
				//console.log(o['contentCategory']);
				var anchoralias = "";
				var sharealias = "";
				if (o['templatename'].toLowerCase() == "hjroom-template"){
					anchoralias += 'stay';
					sharealias += 'stay';
				}else if (o['templatename'].toLowerCase() == "hjrestaurant"){
					anchoralias += 'eat';
					sharealias += 'eat';
				}else if (o['templatename'].toLowerCase() == "hjspace"){
					anchoralias += 'co-working';
					sharealias += 'co-working';
				}else if (o['templatename'].toLowerCase() == "hjevent"){
					anchoralias += 'events';
					sharealias += 'events';
				}else if (o['templatename'].toLowerCase() == "hjworkout"){
					anchoralias += 'active';
					sharealias += 'active';
				}
				if (o['section'].value != null && o['section'].value != '')
				{
					if (anchoralias != ''){
						if (o['templatename'].toLowerCase() == "hjevent" || o['templatename'].toLowerCase() == "hjworkout" ){
							anchoralias += '-';
							sharealias += '/';
						}else{
							anchoralias += '-';
							sharealias += '-';
						}
					}
					anchoralias += o['section'].value;
					sharealias += o['section'].value;
				}
				if (o['urlalias'].value != null && o['urlalias'].value != '')
				{
					if (o['templatename'].toLowerCase() == "hjevent" || o['templatename'].toLowerCase() == "hjworkout" ){
						anchoralias += '-';
						sharealias += '/';
					}else{
						anchoralias += '-';
						sharealias += '-';
					}
					anchoralias += o['urlalias'].value;
					sharealias += o['urlalias'].value;
				}
				o['anchoralias'] = anchoralias;
				o['sharealias'] = sharealias;
				//var anchoralias = o['section'].value + '-' + o['urlalias'].value;
				//if (o['contentCategory'])
				//{
				//	o['contentCategory']['children'][0] + '-' + anchoralias;
				//}
				if (o['templatename'].toLowerCase() == "hjrestaurant")
				{
					//console.log(anchoralias);
				}
				if (o['templatename'].toLowerCase() == "hjroom-template")
				{
					if (o["property"] && 
						o["property"]["children"] &&
						o["property"]["children"].length > 0 &&
						o["property"]["children"][0]["hotelcode"]
						//&& o["roomtypecode"] &&
						//o["roomtypecode"].value
						)
					{
						if (o["property"] && o["property"]["children"] && o["property"]["children"].length > 0)
						{
							var bookingurl = "";
							if (o['property']['children'][0]['cityalias'] && o['property']['children'][0]['cityalias'].value)
							{
								bookingurl += o['property']['children'][0]['cityalias'].value + '|';
							}
							else if (o['property']['children'][0]['cityalias'] && !(o['property']['children'][0]['cityalias'].value))
							{
								bookingurl += o['property']['children'][0]['cityalias'] + '|';
							}
							if (o['property']['children'][0]['hotelalias'] && o['property']['children'][0]['hotelalias'].value)
							{
								bookingurl += o['property']['children'][0]['hotelalias'].value + '|';
							}
							else if (o['property']['children'][0]['hotelalias'] && !(o['property']['children'][0]['hotelalias'].value))
							{
								bookingurl += o['property']['children'][0]['hotelalias'] + '|';
							}
							if (o['property']['children'][0]['hotelcode'] && o['property']['children'][0]['hotelcode'].value)
							{
								bookingurl += o['property']['children'][0]['hotelcode'].value + '|';
							}
							else if (o['property']['children'][0]['hotelcode'] && !(o['property']['children'][0]['hotelcode'].value))
							{
								bookingurl += o['property']['children'][0]['hotelcode'] + '|';
							}
							o['bookingurl'] = bookingurl;
						}
					}
				}
				
			}
			
		}
		if (o['templatename'] && (o['templatename'].toLowerCase() == "hjdealeat" ||
			o['templatename'].toLowerCase() == "hjdealstay" ||
			o['templatename'].toLowerCase() == "hjdealwedding")
			)
		{
			if (o["property"] && 
				o["property"]["children"] &&
				o["property"]["children"].length > 0 &&
				o["property"]["children"][0]["id"]
				)
			{
				if (o["property"].value)
				{
					if (o["property"].value == '')
						o["property"].value = o["property"]["children"][0]["id"];
				}
				else
					o["property"].value = o["property"]["children"][0]["id"];
			}
			if (o['templatename'].toLowerCase() == "hjdealstay")
			{
				if (o["property"] && 
					o["property"]["children"] &&
					o["property"]["children"].length > 0 &&
					o["property"]["children"][0]["hotelcode"] 
					//&& o["room"] && 
					//o["room"]["children"] &&
					//o["room"]["children"].length > 0 &&
					//o["room"]["children"][0]["roomtypecode"]
					)
				{
					if (o["property"] && o["property"]["children"] && o["property"]["children"].length > 0)
					{
						var bookingurl = "";
						if (o['property']['children'][0]['cityalias'] && o['property']['children'][0]['cityalias'].value)
						{
							bookingurl += o['property']['children'][0]['cityalias'].value + '|';
						}
						else if (o['property']['children'][0]['cityalias'] && !(o['property']['children'][0]['cityalias'].value))
						{
							bookingurl += o['property']['children'][0]['cityalias'] + '|';
						}
						if (o['property']['children'][0]['hotelalias'] && o['property']['children'][0]['hotelalias'].value)
						{
							bookingurl += o['property']['children'][0]['hotelalias'].value + '|';
						}
						else if (o['property']['children'][0]['hotelalias'] && !(o['property']['children'][0]['hotelalias'].value))
						{
							bookingurl += o['property']['children'][0]['hotelalias'] + '|';
						}
						if (o['property']['children'][0]['hotelcode'] && o['property']['children'][0]['hotelcode'].value)
						{
							bookingurl += o['property']['children'][0]['hotelcode'].value + '|';
						}
						else if (o['property']['children'][0]['hotelcode'] && !(o['property']['children'][0]['hotelcode'].value))
						{
							bookingurl += o['property']['children'][0]['hotelcode'] + '|';
						}
						o['bookingurl'] = bookingurl;
					}
				}
			}
		}
		if (o['templatename'] &&
			o['templatename'].toLowerCase() == "hjroom-template" &&
			o["features"] &&
			o["features"]["children"] &&
			o["features"]["children"].length >0)
		{
			var curr_image = "";
			for (var i=0; i < o["features"]["children"].length;i++)
			{
				if (o["features"]["children"][i]["image"] && 
					o["features"]["children"][i]["image"] != '')
				{
					if (o["features"]["children"][i]["image"] === curr_image)
					{
						o["features"]["children"][i]["image"] = "";
					}
					else
					{
						curr_image = o["features"]["children"][i]["image"];
					}
				}
			}
		}
		
		if (o["cta"] && 
			o["cta"].value &&
			o["cta"].value != '' )
		{
			//var regexp = /(url=\")[\""A-Za-z0-9.;:!#$%&'*+-\/=?^_`{|}~\p{L}\p{Nd}]{1,}(\")/g;
			var regexp = /(url=\")[A-Za-z0-9.;:!#$%&'*+-\/=?^_`{|}~\p{L}\p{Nd}]{1,}(\")/g;
			var match = o['cta'].value.replace(/[\n\r\s\t]+/g, '').match(regexp);
			if (match != null && match.length > 0)
			  {
				 
				o["cta"].value = match[0].substring(5, match[0].length - 1);
				if (o["cta"].value.indexOf("linktype") != -1)
				{
					console.log(o["cta"].value);
				}
			}else{
				regexp = /<link linktype=\"internal\" \/>/g;
				match = o['cta'].value.match(regexp);
				if (match != null && match.length > 0)
				{
					o["cta"].value = "";
				}
				else
				{
					if (vm != null)
					{
						if (vm.$jss.routeData().itemLanguage.toLowerCase() == "zh")
						{
							//console.log(o["cta"].value);
							if (o["cta"].value.substring(0,3).toLowerCase() != "/cn" && o["cta"].value.indexOf("http") == -1)
								o["cta"].value = "/cn" + o["cta"].value;
							else if (o["cta"].value.indexOf("/cn/hotels/jen") == -1)
							{
								o["cta"].value = o["cta"].value.replace(/\/hotels\/jen/g, '/cn/hotels/jen');
							}
							//else if (o["cta"].value.indexOf("https://www.shangri-la.com:443/hotels/jen") != -1)
							//{
							//	o["cta"].value.replace(/https:\/\/www.shangri-la.com:443\/hotels\/jen/g, 'https://www.shangri-la.com:443/cn/hotels/jen');
							//}
						}
						else
						{
							if (o["cta"].value.substring(0,3).toLowerCase() != "/" + vm.$jss.routeData().itemLanguage.substring(vm.$jss.routeData().itemLanguage.length-2).toLowerCase() &&
								o["cta"].value.indexOf("http") == -1)
								o["cta"].value = "/" + vm.$jss.routeData().itemLanguage.substring(vm.$jss.routeData().itemLanguage.length-2).toLowerCase() + o["cta"].value;
						}
					}
				}
			}
			o['cta'].value = o['cta'].value.replace(/\/zh\//g, '/');
		}
		if (o["detailpageurl"] && 
			o["detailpageurl"].value &&
			o["detailpageurl"].value != '' )
		{
			var regexp = /(url=\")[A-Za-z0-9.;:!#$%&'*+-\/=?^_`{|}~\p{L}\p{Nd}]{1,}(\")/g;
			var match = o['detailpageurl'].value.replace(/[\n\r\s\t]+/g, '').match(regexp);
			if (match != null && match.length > 0)
			  {
				 
				o["detailpageurl"].value = match[0].substring(5, match[0].length - 1);
			}else{
				regexp = /<link linktype=\"internal\" \/>/g;
				match = o['detailpageurl'].value.match(regexp);
				if (match != null && match.length > 0)
				{
					o["detailpageurl"].value = "";
				}
				else
				{
					if (vm != null)
					{
						if (vm.$jss.routeData().itemLanguage.toLowerCase() == "zh")
						{
							if (o["detailpageurl"].value.substring(0,3).toLowerCase() != "/cn" && o["detailpageurl"].value.indexOf("http") == -1)
								o["detailpageurl"].value = "/cn" + o["detailpageurl"].value;
							else if (o["detailpageurl"].value.indexOf("/cn/hotels/jen") == -1)
							{
								o["detailpageurl"].value = o["detailpageurl"].value.replace(/\/hotels\/jen/g, '/cn/hotels/jen');
							}
							//else if (o["detailpageurl"].value.indexOf("https://www.shangri-la.com:443/hotels/jen") != -1)
							//{
							//	o["detailpageurl"].value.replace(/https:\/\/www.shangri-la.com:443\/hotels\/jen/g, 'https://www.shangri-la.com:443/cn/hotels/jen');
							//}
						}
						else
						{
							if (o["detailpageurl"].value.substring(0,3).toLowerCase() != "/" + vm.$jss.routeData().itemLanguage.substring(vm.$jss.routeData().itemLanguage.length-2).toLowerCase() &&
								o["detailpageurl"].value.indexOf("http") == -1)
								o["detailpageurl"].value = "/" + vm.$jss.routeData().itemLanguage.substring(vm.$jss.routeData().itemLanguage.length-2).toLowerCase() + o["detailpageurl"].value;
						}
					}
				}
			}
			o['detailpageurl'].value = o['detailpageurl'].value.replace(/\/zh\//g, '/');
		}
		if (o["buttoncta"] && 
			o["buttoncta"].value &&
			o["buttoncta"].value != '' )
		{
			var regexp = /(url=\")[\""A-Za-z0-9.;:!#$%&'*+-\/=?^_`{|}~\p{L}\p{Nd}]{1,}(\")/g;
			var match = o['buttoncta'].value.match(regexp);
			if (match != null && match.length > 0)
			  {
				o["buttoncta"].value = match[0].substring(5, match[0].length - 1);
			}else{
				regexp = /<link linktype=\"internal\" \/>/g;
				match = o['buttoncta'].value.match(regexp);
				if (match != null && match.length > 0)
				{
					o["buttoncta"].value = "";
				}
				else
				{
					if (vm != null)
					{
						if (vm.$jss.routeData().itemLanguage.toLowerCase() == "zh")
						{
							if (o["buttoncta"].value.substring(0,3).toLowerCase() != "/cn" && o["buttoncta"].value.indexOf("http") == -1)
								o["buttoncta"].value = "/cn" + o["buttoncta"].value;
							else if (o["buttoncta"].value.indexOf("/cn/hotels/jen") == -1)
							{
								o["buttoncta"].value = o["buttoncta"].value.replace(/\/hotels\/jen/g, '/cn/hotels/jen');
							}
							//else if (o["buttoncta"].value.indexOf("https://www.shangri-la.com:443/hotels/jen") != -1)
							//{
							//	o["buttoncta"].value.replace(/https:\/\/www.shangri-la.com:443\/hotels\/jen/g, 'https://www.shangri-la.com:443/cn/hotels/jen');
							//}
						}
						else
						{
							if (o["buttoncta"].value.substring(0,3).toLowerCase() != "/" + vm.$jss.routeData().itemLanguage.substring(vm.$jss.routeData().itemLanguage.length-2).toLowerCase() && 
							o["buttoncta"].value.indexOf("http") == -1)
								o["buttoncta"].value = "/" + vm.$jss.routeData().itemLanguage.substring(vm.$jss.routeData().itemLanguage.length-2).toLowerCase() + o["buttoncta"].value;
						}
					}
				}
			}
		}
		if (o["captionbuttoncta"] && 
			o["captionbuttoncta"].value &&
			o["captionbuttoncta"].value != '' )
		{
			var regexp = /(url=\")[\""A-Za-z0-9.;:!#$%&'*+-\/=?^_`{|}~\p{L}\p{Nd}]{1,}(\")/g;
			var match = o['captionbuttoncta'].value.match(regexp);
			if (match != null && match.length > 0)
			  {
				o["captionbuttoncta"].value = match[0].substring(5, match[0].length - 1);
			}else{
				regexp = /<link linktype=\"internal\" \/>/g;
				match = o['captionbuttoncta'].value.match(regexp);
				if (match != null && match.length > 0)
				{
					o["captionbuttoncta"].value = "";
				}
				else
				{
					if (vm != null)
					{
						if (vm.$jss.routeData().itemLanguage.toLowerCase() == "zh")
						{
							if (o["captionbuttoncta"].value.substring(0,3).toLowerCase() != "/cn" && o["captionbuttoncta"].value.indexOf("http") == -1)
								o["captionbuttoncta"].value = "/cn" + o["captionbuttoncta"].value;
							else if (o["captionbuttoncta"].value.indexOf("/cn/hotels/jen") == -1)
							{
								o["captionbuttoncta"].value = o["captionbuttoncta"].value.replace(/\/hotels\/jen/g, '/cn/hotels/jen');
							}
							//else if (o["captionbuttoncta"].value.indexOf("https://www.shangri-la.com:443/hotels/jen") != -1)
							//{
							//	o["captionbuttoncta"].value.replace(/https:\/\/www.shangri-la.com:443\/hotels\/jen/g, 'https://www.shangri-la.com:443/cn/hotels/jen');
							//}
						}
						else
						{
							if (o["captionbuttoncta"].value.substring(0,3).toLowerCase() != "/" + vm.$jss.routeData().itemLanguage.substring(vm.$jss.routeData().itemLanguage.length-2).toLowerCase() &&
							o["captionbuttoncta"].value.indexOf("http") == -1)
								o["captionbuttoncta"].value = "/" + vm.$jss.routeData().itemLanguage.substring(vm.$jss.routeData().itemLanguage.length-2).toLowerCase() + o["captionbuttoncta"].value;
						}
					}
				}
			}
		}
		if (o["buttoncta"] && 
			o["buttoncta"].value &&
			o["buttoncta"].value != '' )
		{
			var regexp = /(url=\")[\""A-Za-z0-9.;:!#$%&'*+-\/=?^_`{|}~\p{L}\p{Nd}]{1,}(\")/g;
			var match = o['buttoncta'].value.match(regexp);
			if (match != null && match.length > 0)
			  {
				o["buttoncta"].value = match[0].substring(5, match[0].length - 1);
			}else{
				regexp = /<link linktype=\"internal\" \/>/g;
				match = o['buttoncta'].value.match(regexp);
				if (match != null && match.length > 0)
				{
					o["buttoncta"].value = "";
				}
				else
				{
					if (vm != null)
					{
						if (vm.$jss.routeData().itemLanguage.toLowerCase() == "zh")
						{
							if (o["buttoncta"].value.substring(0,3).toLowerCase() != "/cn" && o["buttoncta"].value.indexOf("http") == -1)
								o["buttoncta"].value = "/cn" + o["buttoncta"].value;
							else if (o["buttoncta"].value.indexOf("/cn/hotels/jen") == -1)
							{
								o["buttoncta"].value = o["buttoncta"].value.replace(/\/hotels\/jen/g, '/cn/hotels/jen');
							}
							//else if (o["buttoncta"].value.indexOf("https://www.shangri-la.com:443/hotels/jen") != -1)
							//{
							//	o["buttoncta"].value.replace(/https:\/\/www.shangri-la.com:443\/hotels\/jen/g, 'https://www.shangri-la.com:443/cn/hotels/jen');
							//}
						}
						else
						{
							if (o["buttoncta"].value.substring(0, 3).toLowerCase() != "/" + vm.$jss.routeData().itemLanguage.substring(vm.$jss.routeData().itemLanguage.length-2).toLowerCase() && o["buttoncta"].value.indexOf("http") == -1)
								o["buttoncta"].value = "/" + vm.$jss.routeData().itemLanguage.substring(vm.$jss.routeData().itemLanguage.length-2).toLowerCase() + o["buttoncta"].value;
						}
					}
				}
			}
		}
		if (o['datetag'] && o['datetag'].value && o['startdate'] && o['startdate'].value && o['startdate'].value != '')
		{
			o['datetag'].value = sitecoredatetodatetag(o['startdate'].value, vm.$jss.routeData().itemLanguage.toLowerCase());
		}
	  //}
	//console.log(o);
	return o;
  }
  

  var getChildren = function(type, item, vm, templatefilter, constants, hotels, amenities, travellertypes, contentcategories, features, objArray) {
    var _ar = [];
    var o = {};
    var jss = {};
	var _arr = [];
	if (objArray == null || objArray == undefined)
	{
		objArray = [];
	}
    if (item.children) {
      for (var i = 0; i < item.children.length; i++) {
		o = getgraphqlobject(item.children[i], type, vm, templatefilter, true, constants, hotels, amenities, travellertypes, contentcategories, features, objArray)
        if (item.children[i].children != null && item.children[i].children.length > 0) {
          o['children'] = getChildren(type, item.children[i], vm, templatefilter, constants, hotels, amenities, travellertypes, contentcategories, features, objArray);
        }
        _ar.push(o);
      }
    }

    return _ar;
  };

  var o = {};
  var ar = [];
  var ar_ = [];
  var jss = {};
  var _o = {};

  if (item.items)
  {
	  if (type.toLowerCase() == "allsociety")
	  {
		  //console.log(item);
	  }
    o['children'] = [];
	if (item.items.length > 0) {
		for (var i=0; i < item.items.length; i++)
		{
			_o = {};
			if (item.items[i].item)
			{
				_o = getgraphqlobject(item.items[i].item, type, vm, templatefilter, true, constants, hotels, amenities, travellertypes, contentcategories, features, objArray);
				if (item.items[i].item.children != null && item.items[i].item.children.length > 0)
				{
					_o['children'] = getChildren(type, item.items[i].item, vm, templatefilter, constants, hotels, amenities, travellertypes, contentcategories, features, objArray);
				}
			}
			o['children'].push(_o);
		}
	}
  }
  else
  {
	  //o = getgraphqlobject(item, type, vm, templatefilter, false, constants, hotels, amenities, travellertypes, contentcategories, features);
	  o = getgraphqlobject(item, type, vm, templatefilter, true, constants, hotels, amenities, travellertypes, contentcategories, features, objArray);
	  
	  if (item.children != null && item.children.length > 0) {
		o['children'] = getChildren(type, item, vm, templatefilter, constants, hotels, amenities, travellertypes, contentcategories, features, objArray);
	  }
  }
  return o;
}

export function getObjectById(o, id) {	
	var getObjectChildrenById = function(o, id) 
	{
		if (o["id"] != id)
		{
			if (o["children"] != null && o["children"].length > 0)
			{
				for (var i=0; i < o["children"].length; i++)
				{
					if (o["children"][i]["id"] == id)
					{
						return o["children"][i];
					}
					else
					{
							if (o["children"][i]["children"] != null && o["children"][i]["children"].length > 0 )
							{	
								if (getObjectChildrenById(o["children"][i], id)){
									return getObjectChildrenById(o["children"][i], id);
								}
							}
					}
				}
			}
			return false;
		}else{
			return o;
		}
	};
	
	if (!(o == undefined || o == null))
	{
		if (o["id"] == id)
		{
			return o;
		}
		else
		{
			if (o["children"] != null)
			{
				return getObjectChildrenById(o, id);
			}
			else
			{
				var _o = {};
				return _o;
			}
		}
	}
	return o;
}

export function _getObjectById(o, id) {	
	var getObjectChildrenById = function(o, id) 
	{
		if (o["id"].toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "") != id.toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, ""))
		{
			if (o["children"] != null && o["children"].length > 0)
			{
				for (var i=0; i < o["children"].length; i++)
				{
					if (o["children"][i]["id"].toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "") === id.toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, ""))
					{
						return o["children"][i];
					}
					else
					{
							if (o["children"][i]["children"] != null && o["children"][i]["children"].length > 0 )
							{	
								if (getObjectChildrenById(o["children"][i], id)){
									return getObjectChildrenById(o["children"][i], id);
								}
							}
					}
				}
			}
			return false;
		}else{
			return o;
		}
	};
	
	if (!(o == undefined || o == null))
	{
		if (o["id"].toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "") === id.toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, ""))
		{
			return o;
		}
		else
		{
			if (o["children"] != null)
			{
				return getObjectChildrenById(o, id);
			}
			else
			{
				var _o = {};
				return _o;
			}
		}
	}
	return o;
}
export function graphqlToObjectFormObject(o) {
  if (!Object.keys) {
    Object.keys = (function() {
      'use strict';
      var hasOwnProperty = Object.prototype.hasOwnProperty,
        hasDontEnumBug = !{ toString: null }.propertyIsEnumerable('toString'),
        dontEnums = [
          'toString',
          'toLocaleString',
          'valueOf',
          'hasOwnProperty',
          'isPrototypeOf',
          'propertyIsEnumerable',
          'constructor',
        ],
        dontEnumsLength = dontEnums.length;

      return function(obj) {
        if (typeof obj !== 'function' && (typeof obj !== 'object' || obj === null)) {
          throw new TypeError('Object.keys called on non-object');
        }

        var result = [],
          prop,
          i;

        for (prop in obj) {
          if (hasOwnProperty.call(obj, prop)) {
            result.push(prop);
          }
        }

        if (hasDontEnumBug) {
          for (i = 0; i < dontEnumsLength; i++) {
            if (hasOwnProperty.call(obj, dontEnums[i])) {
              result.push(dontEnums[i]);
            }
          }
        }
        return result;
      };
    })();
  }

  var getChildren = function(item) {
    var _ar = [];
    var group = [];
    var o = {};
    var jss = {};
    var groupO = {};
    if (item['children'] != null) {
      var groupname = '';
      var fieldtype = '';
      var inGroup = false;
      group = [];
      for (var i = 0; i < item['children'].length; i++) {
        if (item['children'][i]['groupname'].value != '') {
          inGroup = true;
        } else {
          inGroup = false;
        }
        if (item['children'][i]['groupname'].value.toLowerCase() != groupname.toLowerCase()) {
          if (group.length > 0) {
            groupO = {};
            groupO['groupname'] = groupname;
            groupO['fieldtype'] = fieldtype;
            groupO['children'] = group;
            _ar.push(groupO);
            group = [];
          }
          groupname = item['children'][i]['groupname'].value;
          fieldtype = item['children'][i]['fieldtypestring'].value;
        }
        o = {};
        var keys = Object.keys(item['children'][i]);
        for (var j = 0; j < keys.length; j++) {
          if ((keys[j] != 'children' && keys[j].substring(0,2) != '__') ||
		  keys[j] == '__typename') {
            o[keys[j]] = item['children'][i][keys[j]];
          }
        }
        if (item['children'][i]['children'] != null) {
          o['children'] = getChildren(item['children'][i]);
        }
        if (inGroup) {
          group.push(o);
        } else {
          _ar.push(o);
        }
      }
      if (groupname.toLowerCase() != '') {
        if (group.length > 0) {
          groupO = {};
          groupO['groupname'] = groupname;
          groupO['children'] = group;
          groupO['fieldtype'] = fieldtype;
          _ar.push(groupO);
          group = [];
        }
      }
    }
    return _ar;
  };

  var fo = {};
  var ar = {};

  var groupname = '';

  fo = {};
  var keys = Object.keys(o);

  for (var j = 0; j < keys.length; j++) {
    if (keys[j] != 'children') {
      fo[keys[j]] = o[keys[j]];
    }
  }
  if (o['children'] != null) {
    fo['children'] = getChildren(o);
  }

  return fo;
}

export function getLinks(
	data, vm, isgraphql, position, route, property, templateproperty
)
{
	//var ssrdata = getObject(vm.$jss.routeData(), vm, null, null, null, "hjheader");
	var groupname = "";
	var subgroupname = "";
	var o = {};
	var group = [];	//	group
	var links = [];	//	list of groups
	var subgroups = [];	//	subgroups within group
	var subgroup = [];
		//console.log(data);
		
	if (property === undefined)
	{
		property = null;
	}
	if (templateproperty === undefined)
	{
		templateproperty = null;
	}
	if (route === undefined)
	{
		if (vm != null)
		{
			route = vm.$jss.routeData();
		}
		else
			route = null;
	}
	if (data != null && data != undefined)
	{
		if (isgraphql == undefined || isgraphql == true)
		{
			isgraphql = true;
		}
		if (isgraphql)
		{
			data = getObject(data, vm);
		}
		if (data["children"] && data["children"] != null && data["children"].length > 0)
		{
			groupname = data["children"][0]["groupname"].value;
			subgroupname = data["children"][0]["subgroupname"].value;
		}
		if (data["children"] && data["children"] != null && data["children"].length > 0)
		{
			for (var i=0; i < data["children"].length; i++)
			{
				o = {};
				var keys = Object.keys(data['children'][i]);
				for (var j = 0; j < keys.length; j++) {
					if (keys[j].substring(0,2) != '__' || keys[j] == '__typename')
					{
						o[keys[j]] = data['children'][i][keys[j]];
					}
				}
				
				if (!(data["children"][i]["urlexcluderegex"] && data["children"][i]["urlexcluderegex"].value))
				{
					o["urlexcluderegex"] = {value:""};
				}
				if (data["children"][i]["istitle"].value == "1")
				{
					o["liclass"] = "title";
				}
				else
				{
					o["liclass"] = "";
				}
				if (data["children"][i]["description"].value == "1")
				{
					o["hasdesclass"] = "hasdesc";
				}
				else
				{
					o["hasdesclass"] = "";
				}
				if (data["children"][i]['cta'].value != ''){
					var regexp = /(url=\")[\""A-Za-z0-9.;:!#$%&'*+-\/=?^_`{|}~\p{L}\p{Nd}]{1,}(\")/g;
					var match = data["children"][i]['cta'].value.match(regexp);
					if (match != null && match.length > 0)
					  {
						o["cta"].value = match[0].substring(5, match[0].length - 1);
					}else{
						regexp = /<link linktype=\"internal\" \/>/g;
						match = data["children"][i]['cta'].value.match(regexp);
						if (match != null && match.length > 0)
						{
							o["cta"].value = "";
						}
						else
						{
							o["cta"].value = data["children"][i]['cta'].value;
							if (property != null)
							{
								var propertyid = property.replace(/\-/g,"").toUpperCase();
								var hotelDetails = getObject(getObjectById(vm.$jss.hotels(),propertyid), vm);
								var templatecityalias = "beijing";
								var templatepropertyalias = "centraldistrict";
								if (templateproperty != null)
								{
									var templatepropertyid = templateproperty.replace(/\-/g,"").toUpperCase();
									var templatehotelDetails = getObject(getObjectById(vm.$jss.hotels(),templatepropertyid), vm);
									templatepropertyalias = templatehotelDetails['hotelalias'].value;
									templatecityalias = templatehotelDetails['cityalias'].value;
								}
								o["cta"].value = o["cta"].value.replace(new RegExp(eval('/' + templatepropertyalias + '/g')), hotelDetails['hotelalias'].value);
								o["cta"].value = o["cta"].value.replace(new RegExp( eval('/' + templatecityalias + '/g')), hotelDetails['cityalias'].value);
								//console.log(o["cta"].value);
								if (route.itemLanguage.toLowerCase() === "zh")
								{
									if (o["cta"].value.substring(0,3).toLowerCase() != "/cn" && o["cta"].value.indexOf("http") == -1)
										o["cta"].value = "/cn" + o["cta"].value;
									else if (o["cta"].value.indexOf("/cn/hotels/jen") == -1)
									{
										o["cta"].value = o["cta"].value.replace(/\/hotels\/jen/g, '/cn/hotels/jen');
									}
									//else if (o["cta"].value.indexOf("https://www.shangri-la.com:443/hotels/jen") != -1)
									//{
									//	o["cta"].value.replace(/https:\/\/www.shangri-la.com:443\/hotels\/jen/g, 'https://www.shangri-la.com:443/cn/hotels/jen');
									//}
								}
								else
								{								
									if (o["cta"].value.substring(0,3).toLowerCase() != "/" + route.itemLanguage.substring(route.itemLanguage.length-2).toLowerCase() && 
									o["cta"].value.indexOf("http") == -1)
										o["cta"].value = "/" + route.itemLanguage.substring(route.itemLanguage.length-2).toLowerCase() + o["cta"].value;
								}
							}
							else
							{
								//console.log(o["cta"].value);
								if (route.itemLanguage.toLowerCase() === "zh")
								{
									if (o["cta"].value.substring(0,3).toLowerCase() != "/cn" && o["cta"].value.indexOf("http") == -1)
										o["cta"].value = "/cn" + o["cta"].value;
									else if (o["cta"].value.indexOf("/cn/hotels/jen") == -1)
									{
										o["cta"].value = o["cta"].value.replace(/\/hotels\/jen/g, '/cn/hotels/jen');
									}
									//else if (o["cta"].value.indexOf("https://www.shangri-la.com:443/hotels/jen") != -1)
									//{
									//	o["cta"].value.replace(/https:\/\/www.shangri-la.com:443\/hotels\/jen/g, 'https://www.shangri-la.com:443/cn/hotels/jen');
									//}
								}
								else
								{
									if (o["cta"].value.substring(0,3).toLowerCase() != "/" + route.itemLanguage.substring(route.itemLanguage.length-2).toLowerCase() && o["cta"].value.indexOf("http") == -1)
										o["cta"].value = "/" + route.itemLanguage.substring(route.itemLanguage.length-2).toLowerCase() + o["cta"].value;
								}
							}
						}
					}
				}
				else{
					o["cta"].value = "";
				}
				if (position == undefined || position == '' || o["position"].value == position)
				{
					if (groupname != data["children"][i]["groupname"].value)
					{
						group.push(subgroup);
						links.push(group);
						group = [];
						subgroup = [];
						groupname = data["children"][i]["groupname"].value;
						subgroupname = data["children"][i]["subgroupname"].value;
					}
					if (data["children"][i]["issubgroup"].value == '1')
					{
						if (subgroupname != data["children"][i]["subgroupname"].value)
						{
							group.push(subgroup);
							subgroup = [];
							subgroupname = data["children"][i]["subgroupname"].value;
						}
						subgroup.push(o);
					}
					else
					{
						group.push(o);
					}
				}
			}
		}
		if (subgroup.length > 0  || subgroups.length > 0 || group.length > 0 )
		{
			group.push(subgroup);
			links.push(group);
		}
	}
	
	return links;
}

export function graphqlRemoveReserved(item) {
  var getChildren = function(item) {
    if (item.children) {
      for (var i = item.children.length-1; i >= 0; i--) {
        for (var j = item.children[i].fields.length-1; j >= 0; j--) {
			if (item.children[i].fields[j].name.substring(0, 2) == '__' &&
				item.children[i].fields[j].name.toLowerCase() != '__typename') {
				item.children[i].fields.splice(j, 1);
			}
        }
        if (item.children[i].children != null && item.children[i].children.length > 0) {
          getChildren(item.children[i]);
        }
      }
    }
  };

  if (item.fields && typeof item.fields != 'undefined') {
    try {
      for (var j = item.fields.length-1; j >= 0; j--) {
        if (item.fields[j].name.substring(0, 2) == '__' &&
			item.fields[j].name.toLowerCase() != '__typename') {
		  item.fields.splice(j, 1);
        }
      }
    } catch (e) {}
  }
  if (item.children != null && item.children.length > 0) {
    getChildren(item);
  }
}

export function gethotelminprice(
	data, vm, id, prices, currency, travellertype
)
{
	
	//if (!(vm != null && vm.$jss.hotels() != null && vm.$jss.hotels().children && vm.$jss.hotels().children.length > 0))	{
	//	vm.$jss.store.setHotels(getContentByType(graphqlToObject('', vm.$jss.hotels(), vm, '', null, false), "hjhotel-template", vm, false, false, 'other', false, 2000));
	//}
	
	var hotels = vm.$jss.hotels();
	
	for (var i=0; i < hotels['children'].length; i++)
	{
		if (hotels['children'][i]['id'].replace(/-/g, "").replace(/{/g, "").replace(/}/g, "") === id.replace(/-/g, "").replace(/{/g, "").replace(/}/g, ""))
		{
			if (hotels['children'][i]['hotelcode'] != null && 
				hotels['children'][i]['hotelcode'].value &&
				hotels['children'][i]['hotelcode'].value  != '')
			{
				return getminprice(hotels['children'][i]['hotelcode'].value, vm.hotel_prices, currency, travellertype);
			}
			break;
		}
	}
	return 0;
}
export function gethotelprice(
	data, vm, id, prices, currency, travellertype
)
{
	//var hotels = getObject(data, vm);
	var hotels = null;
	//if (vm != null && vm.$jss.hotels() != null)
	//{
		//hotels = getObject(data, vm);
	//}
	//else
	//{
	//	vm.$jss.store.setHotels(getContentByType(graphqlToObject('', vm.$jss.hotels(), vm, '', null, false), "hjhotel-template", vm, false, false, 'other', false, 2000));
	//	hotels = vm.$jss.hotels();
	//}

	for (var i=0; i < hotels['children'].length; i++)
	{
		if (hotels['children'][i]['id'].replace(/-/g, "").replace(/{/g, "").replace(/}/g, "") === id.replace(/-/g, "").replace(/{/g, "").replace(/}/g, ""))
		{
			if (hotels['children'][i]['hotelcode'] != null && 
				hotels['children'][i]['hotelcode'].value &&
				hotels['children'][i]['hotelcode'].value  != '')
			{
				return getprice(hotels['children'][i]['hotelcode'].value, vm.hotel_prices, currency, travellertype);
			}
			break;
		}
	}
	return 0;
}

export function getTemplateId(
	data, vm, templatename, isgraphql
)
{
	if (data.children != null)
	{
		for(var i=0; i < data.children.length; i++)
		{
			if (data.children[i]["name"].toLowerCase() == templatename.toLowerCase())
			{
				return data.children[i].id;
			}
		}
	}
	return null;
}

function getSearchQuery(type, vm, property, path )
{
	var propertycode = "";
	var propertyfolder = "";
	var fragment = "";
	if (!(property == undefined || property == null || property == ""))
	{
		var propertyid = property.replace(/\-/g,"").toUpperCase();
		//var hotelDetails = getObject(getObjectById(vm.hotels,propertyid), vm);
		var hotelDetails = vm.$jss.hotels().children.find(x=>x.id.replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim().toLowerCase() === propertyid.replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim().toLowerCase());
		if (hotelDetails != null && hotelDetails != undefined)
		{
			if (hotelDetails && hotelDetails.hotelcode)
			{
				propertycode = hotelDetails.hotelcode.value;
				if (vm.$jss.hjconfig().hotelcodes && vm.$jss.hjconfig().hotelcodes.length > 0)
				{
					for (var i=0; i < vm.$jss.hjconfig().hotelcodes.length; i++)
					{
						if (vm.$jss.hjconfig().hotelcodes[i][0].toString().toLowerCase() === propertycode.toLowerCase())
						{
							propertyfolder = "/" + vm.$jss.hjconfig().hotelcodes[i][2];
							break;
						}
					}
				}
			}
		}
	}
	if (path == undefined || path == null)
	{
		path = "";
	}

	var _type = "";
	if (type.toLowerCase() == "hjroom-template") 
	{		
		_type = "HJRoom-Template";
		path = "/sitecore/content/hoteljen/Content/hotels";
		fragment = `...on HJRoomTemplate {
							id 
				 title: field(name:"Title")

				  {
					value
				  }
				 description: field(name:"Description")

				  {
					value
				  }
				 roomtypecode: field(name:"RoomTypeCode")
				  {
					value
				  }
			}`
	}
	else if (type.toLowerCase() == "hjrestaurant")
	{
		_type = "HJRestaurant";
		path = "/sitecore/content/hoteljen/Content/data/restaurant/" + propertyfolder;
		fragment = `...on HJRestaurant {
								id 
					 title: field(name:"Title")

					  {
						value
					  }
					 bookingurl: field(name:"BookingUrl")

					  {
						value
					  }
					 isbooking: field(name:"IsBooking")

					  {
						value
					  }
					 iseat2eat: field(name:"IsEat2Eat")

					  {
						value
					  }
				}`
	}
	else if (type.toLowerCase() == "hjhotel-template")
	{
		_type = "HJHotel-Template";
		path = "/sitecore/content/hoteljen/Content/hotels";
		fragment = `...on HJHotelTemplate {
							id 
				 title: field(name:"Title")

				  {
					value
				  }
				 description: field(name:"Description")

				  {
					value
				  }
				 hotelcode: field(name:"HotelCode")

				  {
					value
				  }
				  cityalias: field(name:"CityAlias")
				  {
					value
				  }
				 hotelalias: field(name:"HotelAlias")

				  {
					value
				  }
				  tzid: field(name:"Tzid")
				  {
					  value
				  }
			}`;
	}
	else if (type.toLowerCase() == "hjeventtype")
	{
		_type = "HJEventType";
		path = "/sitecore/content/hoteljen/Content/Constants/Others/HJEventType";
		fragment = `
					...on HJEventType {
									id
									title: field(name:"Title")
						  {
							value
						  }
						 description: field(name:"Description")
						  {
							value
						  } 
					}`
	}
	else if (type.toLowerCase() == "hjopenclosetime")
	{
		_type = "HJOpenCloseTime";
		path = "/sitecore/content/hoteljen/Content/Constants/Others/OpeningTime";
		fragment = `...on HJOpenCloseTime {
					id 
				  day: field(name:"Day")
				  {
					value
				  }
				  open: field(name:"Open")
				  {
					value
				  }
				  close: field(name:"Close")
				  {
					value
				  }
				description: field(name:"Description")
				{
				  value
				}
			}`
	}
	else if (type.toLowerCase() == "constants-hjroomfeature-type-template")
	{
		_type = "Constants-HJRoomFeature-Type-Template";
		path = "/sitecore/content/hoteljen/Content/Constants/Others/RoomFeature";
		fragment = `...on ConstantsHJRoomFeatureTypeTemplate {
					title: field(name:"Title")
					{
					  value
					}
					description: field(name:"Description")
					{
					  value
					}
					moredescription: field(name:"MoreDescription")
					{
					  value
					}
					image : field(name:"Image")
					{
					  jss
					}
				}`
	}
	else if (type.toLowerCase() === "hjcontentcategory")
	{
		_type = "HJContentCategory";
		path = "/sitecore/content/hoteljen/Content/Constants/System/HJContentCategory";
		fragment = `							...on HJContentCategory {
								title: field(name:"Title")

								{
									value
								}
								description: field(name:"Description")

								{
									value
								}
								value
								{
									value
								}
							}
`
	}
	
	var propertyvalue = "*";
	if (!(property == null || property === '' || property === '*' || property.toLowerCase() === 'all'))
		propertyvalue = property.toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim();
	
	var query = `query
{
  search(fieldsEqual:[
    {name:"_templatename", value:"${_type}" }
	,{name:"property", value:"${propertyvalue}" } 
   ]
    rootItem: "${path}"
  ) {
    results {
      items {
        id
        templateName
        name
        language       
				item {
		   id
		   path
			template
			{
				name
			}
			fields
			{
			  __typename
			  name
			  value
			  editable
			}
			${fragment}
        }
      }
    }
  }
}
`
	if (type.toLowerCase() == "hjhotel-template" ||
	type.toLowerCase() == "hjeventtype" ||
	type.toLowerCase() == "hjopenclosetime" ||
	type.toLowerCase() == "constants-hjroomfeature-type-template"
	)
	{
		query = `query
		{
		  search(fieldsEqual:[
			{
			name:"_templatename", value:"${_type}" }
		   ]
			rootItem: "${path}"
		  ) {
			results {
			  items {
				id
				templateName
				name
				language       
						item {
				   id
				   path
					template
					{
						name
					}
					fields
					{
					  __typename
					  name
					  value
					  editable
					}
					${fragment}
				}
			  }
			}
		  }
		}
		`
	}

	return query;
}

export function searchMoreContentByType_v2(
	graphqlresults, type, vm, action, pagesize, lastpage, property, isfeatureimageonly, travellertype, language, condition, event, context, searchresults, targetobject, path 
)
{  

	//if (vm.$jss.hjconfig().use_version1 != undefined && vm.$jss.hjconfig().use_version1 != null && vm.$jss.hjconfig().use_version1 == true)
	//{
	//	return searchMoreContentByType_v1(graphqlresults, type, vm, action, pagesize, lastpage, property, isfeatureimageonly, travellertype, language, condition, event, context, searchresults, targetobject, path);
	//}
	var propertycode = "";
	var propertyfolder = "";
	if (!(property == undefined || property == null || property == ""))
	{
		var propertyid = property.replace(/\-/g,"").toUpperCase();
		var hotelDetails = getObject(getObjectById(vm.$jss.hotels(),propertyid), vm);
		if (hotelDetails)
		{
			if (hotelDetails && hotelDetails.hotelcode)
			{
				propertycode = hotelDetails.hotelcode.value;
				if (vm.$jss.hjconfig().hotelcodes && vm.$jss.hjconfig().hotelcodes.length > 0)
				{
					for (var i=0; i < vm.$jss.hjconfig().hotelcodes.length; i++)
					{
						if (vm.$jss.hjconfig().hotelcodes[i][0].toString().toLowerCase() === propertycode.toLowerCase())
						{
							propertyfolder = "/" + vm.$jss.hjconfig().hotelcodes[i][2];
							break;
						}
					}
				}
			}
		}
	}
	if (condition == undefined || condition == null || condition == "")
		condition = "";
	else
	{
		condition = ","+condition; 
	}
	if (language == undefined || language == null)
		language = "en";
	if (isfeatureimageonly == undefined || isfeatureimageonly == null)
		isfeatureimageonly = false;
	if (travellertype == undefined || travellertype == null)
		travellertype = "other";
	if (event == undefined || event == null)
	{
		event = undefined;
	}
	if (context == undefined || context == null)
	{
		context = undefined;
	}
	if (searchresults == undefined || searchresults == null)
	{
		searchresults = null;
	}
	if (targetobject == undefined || targetobject == null)
	{
		targetobject = null;
	}
	if (pagesize == undefined || pagesize == null)
	{
		pagesize = 30;
	}
	if (path == undefined || path == null)
	{
		path = "";
	}
	
	
	var _type = "";
	var fragment = '';
	
	if (type.toLowerCase() == "hjdealstay" || 
		type.toLowerCase() == "hjdealeat" || 
		type.toLowerCase() == "hjdealspace" || 
		type.toLowerCase() == "hjdealwedding" || 
		type.toLowerCase() == "hjdeal") 
	{		
		path = "/sitecore/content/hoteljen/Content/deals" + propertyfolder;
		if (type.toLowerCase() == "hjdealstay") 
		{
			_type = "HJDealStay";
		}
		if (type.toLowerCase() == "hjdealeat") 
		{
			_type = "HJDealEat";
		}
		if (type.toLowerCase() == "hjdealspace") 
		{
			_type = "HJDealSpace";
		}
		if (type.toLowerCase() == "hjdealwedding") 
		{
			_type = "HJDealWedding";
		}
		if (type.toLowerCase() == "hjdeal") 
		{
			_type = "HJDeal";						
		}
	}
	else if (type.toLowerCase() == "hotel" || type.toLowerCase() == "stay" || type.toLowerCase() == "hjhotel-template" ||
		 type.toLowerCase() == "hjroom-template" || type.toLowerCase() == "hjroom-template_minprices")
	{
		path = "/sitecore/content/hoteljen/Content/hotels";
		if (type.toLowerCase() == "hjroom-template" || type.toLowerCase() == "hjroom-template_minprices")
		{
			_type = "HJRoom-Template";
		}
		else
		{
			_type = "HJHotel-Template";
		}
	}
	else if (type.toLowerCase() == "hjrestaurant" || type.toLowerCase() == "hjworkout" ||
	type.toLowerCase() == "hjspace" || type.toLowerCase() == "hjevent"
	)
	{
		path = "/sitecore/content/hoteljen/content/data" + propertyfolder;
		if (type.toLowerCase() == "hjspace")
		{			
			path = "/sitecore/content/hoteljen/content/data/Spaces" + propertyfolder;
			_type = "HJSpace";
		}
		else if (type.toLowerCase() == "hjworkout")
		{
			path = "/sitecore/content/hoteljen/content/data/Workout" + propertyfolder;
			_type = "HJWorkout";
		}
		else if (type.toLowerCase() == "hjrestaurant")
		{
			path = "/sitecore/content/hoteljen/content/data/Restaurants" + propertyfolder;
			_type = "HJRestaurant";
		}
		else if (type.toLowerCase() == "hjevent") 
		{
			path = "/sitecore/content/hoteljen/content/data/Events" + propertyfolder;
			_type = "HJEvent";
		}
	}
	else if (type.toLowerCase() == "hjhost" || 
	type.toLowerCase() == "hjhostmeet" || 
	type.toLowerCase() == "hjhostcelebrate")
	{
		path = "/sitecore/content/hoteljen/Content/hosts" + propertyfolder;
		if (type.toLowerCase() == "hjhostmeet") 
		{
			_type = "HJHostMeet";
		}
		if (type.toLowerCase() == "hjhostcelebrate") 
		{
			_type = "HJHostCelebrate";						
		}
	}
	else if (type.toLowerCase() == "hjnearby")
	{
		path = "/sitecore/content/hoteljen/Content/nearby" + propertyfolder;
		_type = "HJNearby";			
	}
	else if (type.toLowerCase() == "hjservice")
	{
		path = "/sitecore/content/hoteljen/Content/services" + propertyfolder;
		_type = "HJService";			
	}
	else if (type.toLowerCase() === "hjpropertymenu")
	{
		_type = "HJHeader";		
		pagesize = -1;
		
	}
	else if (type.toLowerCase() === "hjbrandmenu")
	{
		_type = "HJHeader";			
		pagesize = -1;
	}
	else if (type.toLowerCase() === "adhoc")
	{
		_type = "";			
		pagesize = -1;
	}
	else
	{
		path = "/sitecore/content/hoteljen/Content";
		_type = "*";			
	}
	
	
	var paging = "";
	if (pagesize != -1)
	{
		if (lastpage >= 1)
		{
			lastpage += 1;
			paging = 'first: ' + pagesize.toString() + ' after: "' + lastpage.toString() + '"';  
		}
		else
			paging = 'first: ' + pagesize.toString(); 
	}
	
	var propertyvalue = "*";
	if (!(property == null || property === '' || property === '*' || property.toLowerCase() === 'all'))
		propertyvalue = property.toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim();
	
	//orderindex = ',sort:{field:"orderindex",dir:ASC},';
	//var query = gql`query 
	var query = `query
{
  search(fieldsEqual:[
    {
    #name:"_fullpath", value:"/sitecore/content/hoteljen/Content/deals*" },
    name:"_templatename", value:"${_type}" }
	,{name:"property", value:"${propertyvalue}" } 
	##name:"_templatename", value:"hjroom-template" }
    #,{name:"contentcategory", value:"03c0cc9f48cc453ebd55952c91732428" }
    #,{name:"_language", value:"${language}" } 
    #,{name:"disabled", value:"false" },
    #,{name:"__smallcreateddate_tdt", value:"11/6/2019 2:08:59 AM"}		
	${condition}
   ]
    rootItem: "${path}"
   ${paging} 
  ) {
    results {
      items {
        id
        templateName
        name
        language       
				item {
		   id
		   path
			template
			{
				name
			}
			fields(ownFields:true)
			{
			  __typename
			  name
			  value
			  editable
			}
			${fragment}
			children
			{
				id
				template
				{
					name
				}
				fields(ownFields:true)
				{
				  __typename
				  name
				  value
				  editable						  
				}
			}
        }
      }
    }
  }
}
`

	if (type.toLowerCase() == "hjdealeat" ||
		type.toLowerCase() == "hjdealspace" || 
		type.toLowerCase() == "hjdealwedding" || 
		type.toLowerCase() == "hjdeal" ||
		type.toLowerCase() == "hjhostcelebrate" ||
		type.toLowerCase() == "hjhostmeet" ||
		type.toLowerCase() == "hjevent" ||
		type.toLowerCase() == "hjservice" ||
		type.toLowerCase() == "hjnearby" ||
		type.toLowerCase() == "hjhost" ||
		type.toLowerCase() == "hjrestaurant" ||
		type.toLowerCase() == "hjspace" ||
		type.toLowerCase() == "hjworkout"){
		query = `query
		{
		  search(fieldsEqual:[
			{name:"_templatename", value:"${_type}" }
			,{name:"property", value:"${propertyvalue}" } 
			${condition}
		   ]
			rootItem: "${path}"
		   ${paging} 
		  ) {
			results {
			  items {
				id
				templateName
				name
				language       
						item {
				   id
				   path
					template
					{
						name
					}
					fields(ownFields:true)
					{
					  __typename
					  name
					  value
					  editable
					}
					${fragment}
					children
					{
						id
						template
						{
							name
						}
						fields(ownFields:true)
						{
						  __typename
						  name
						  value
						  editable						  
						}
					}
				}
			  }
			}
		  }
		}
		`
	}
	if (type.toLowerCase() === "hjpropertymenu" || type.toLowerCase() === "hjbrandmenu" || type.toLowerCase() === "adhoc")
	{
		query = `query
	{
	  search(fieldsEqual:[
		{
	   ]
		rootItem: "${path}"
	  ) {
		results {
		  items {
			id
			templateName
			name
			language       
					item {
			   id
			   path
				template
				{
					name
				}
				fields(ownFields:true)
				{
				  __typename
				  name
				  value
				  editable
				}
				${fragment}
				children
				{
					id
					template
					{
						name
					}
					fields(ownFields:true)
					{
					  __typename
					  name
					  value
					  editable						  
					}
					children
					{
						id
						template
						{
							name
						}
						fields(ownFields:true)
						{
						  __typename
						  name
						  value
						  editable						  
						}
					}
				}
			}
		  }
		}
	  }
	}
	`
	}
  var _searchMoreContentByType = function(query, graphqlresults, type, vm, action, pagesize, lastpage, property, isfeatureimageonly, travellertype, language, condition, event, context, searchresults, targetobject) {
	  const client = new ApolloClient({
		  uri: vm.$jss.hjconfig().sitecoreApiHost + '/api/' + vm.$jss.hjconfig().jssAppName + '?sc_lang='+ vm.$jss.routeData().itemLanguage +'&sc_apikey=' + vm.$jss.hjconfig().sitecoreSCApiKey,
		  defaultOptions: {
		  //fetchPolicy: 'no-cache',
		  fetchPolicy: 'cache-first',
		},
	  });
	  
		var auth = {
				headers: {} 
			}
		appendAuthHeaders(auth.headers, vm.$jss.hjconfig().hmac_appsecret)
		
		/*
		const _search = (_query, _vm, _language, id) => {
		  return new Promise((resolve, reject) => {
			axios.post('/api/apollo', 
			{data:_query, lang:_language, id:id}, auth)
				.then(result => result)
				.then(result => {
					resolve(result);
				}).catch(e => {
					reject(e);
				});
		  })
		}
		*/

		const _search = (_query, _vm, _language, id) => {
		  return new Promise((resolve, reject) => {
			axios.post('/api/apollo', 
			{data:_query, lang:_language, id:id}, auth)
				.then(result => result)
				.then(result => {
					resolve(result);
				}).catch(e => {
					reject(e);
				});
		  })
		}
		
		var cacheid = (propertyvalue == "*" ? "all" : propertyvalue.toLowerCase()) + language.toLowerCase();

		if (
			type.toLowerCase() == "hjdealeat" ||
			type.toLowerCase() == "hjdealspace" || 
			type.toLowerCase() == "hjdealwedding" || 
			type.toLowerCase() == "hjdeal" ||
			type.toLowerCase() == "hjhostcelebrate" ||
			type.toLowerCase() == "hjhostmeet" ||
			type.toLowerCase() == "hjevent" ||
			type.toLowerCase() == "hjservice" ||
			type.toLowerCase() == "hjnearby" ||
			type.toLowerCase() == "hjhost" ||
			type.toLowerCase() == "hjrestaurant" ||
			type.toLowerCase() == "hjspace" ||
			type.toLowerCase() == "hjworkout" ||
			type.toLowerCase() == "hjroom-template" ||
			type.toLowerCase() == "hjroom-template_minprices" ||
			type.toLowerCase() == "hjhotel-template" ||
			type.toLowerCase() == "hjdealstay"
		)
		{
			const promises = []

			if (type.toLowerCase() == "hjdealstay")
			{
				promises.push(_search(query, vm, vm.$jss.routeData().itemLanguage, type.toLowerCase() + cacheid, null));
				//promises.push(_search(getSearchQuery('HJRoom-Template', vm, property, '' ), vm, vm.$jss.routeData().itemLanguage, "hjroomtemplate" + cacheid, vm.$jss.rooms()));
				//promises.push(_search(getSearchQuery('HJHotel-Template', vm, property, '' ), vm, vm.$jss.routeData().itemLanguage, "hjhoteltemplateall" + language.toLowerCase(), vm.$jss.hotels()));
			}
			else if ( 
				type.toLowerCase() == "hjdealeat"
			)
			{
				promises.push(_search(query, vm, vm.$jss.routeData().itemLanguage, type.toLowerCase()+cacheid, null));
				promises.push(_search(getSearchQuery('HJRestaurant', vm, property, '' ), vm, vm.$jss.routeData().itemLanguage, "hjrestaurant" + cacheid, null));
				//promises.push(_search(getSearchQuery('HJHotel-Template', vm, property, '' ), vm, vm.$jss.routeData().itemLanguage, "hjroomtemplate" + language.toLowerCase(), vm.$jss.hotels()));
			}
			else if ( 
				type.toLowerCase() == "hjdealspace"
			)
			{
				promises.push(_search(query, vm, vm.$jss.routeData().itemLanguage, type.toLowerCase()+cacheid, null));
				//promises.push(_search(getSearchQuery('HJHotel-Template', vm, property, '' ), vm, vm.$jss.routeData().itemLanguage, "hjhoteltemplateall" + language.toLowerCase(), , vm.$jss.hotels()));
			}
			else if ( 
				type.toLowerCase() == "hjdealwedding"
			)
			{
				promises.push(_search(query, vm, vm.$jss.routeData().itemLanguage, type.toLowerCase()+cacheid, null));
				//promises.push(_search(getSearchQuery('HJHotel-Template', vm, property, '' ), vm, vm.$jss.routeData().itemLanguage, "hjhoteltemplateall" + language.toLowerCase(), , vm.$jss.hotels()));
			}
			else if ( 
				type.toLowerCase() == "hjdeal"
			)
			{
				promises.push(_search(query, vm, vm.$jss.routeData().itemLanguage, type.toLowerCase()+cacheid, null));
				//promises.push(_search(getSearchQuery('HJHotel-Template', vm, property, '' ), vm, vm.$jss.routeData().itemLanguage, "hjhoteltemplateall" + language.toLowerCase(), vm.$jss.hotels()));
			}
			else if ( 
				type.toLowerCase() == "hjroom-template" || type.toLowerCase() == "hjroom-template_minprices"
			)
			{
				promises.push(_search(query, vm, vm.$jss.routeData().itemLanguage, type.toLowerCase()+cacheid, null));
				//promises.push(_search(getSearchQuery('HJRoom-Template', vm, property, '' ), vm, vm.$jss.routeData().itemLanguage, "hjroomtemplateall" + cacheid, vm.$jss.rooms()));
				//promises.push(_search(getSearchQuery('constants-hjroomfeature-type-template', vm, property, '' ), vm, vm.$jss.routeData().itemLanguage, "constantshjroomfeaturetypetemplateall" + language.toLowerCase(), vm.$jss.features()));
				//promises.push(_search(getSearchQuery('HJHotel-Template', vm, property, '' ), vm, vm.$jss.routeData().itemLanguage, "hjhoteltemplateall" + language.toLowerCase(), vm.$jss.hotels()));
			}
			else if ( 
				type.toLowerCase() == "hotel" ||
				type.toLowerCase() == "stay"
			)
			{
				promises.push(_search(query, vm, vm.$jss.routeData().itemLanguage, type.toLowerCase()+cacheid, null));
				//promises.push(_search(getSearchQuery('HJHotel-Template', vm, property, '' ), vm, vm.$jss.routeData().itemLanguage, "hjhoteltemplateall" + language.toLowerCase(), vm.$jss.hotels()));
			}
			else if ( 
				type.toLowerCase() == "hjspace" ||
				type.toLowerCase() == "hjdealspace"
			)
			{
				promises.push(_search(query, vm, vm.$jss.routeData().itemLanguage, type.toLowerCase()+cacheid, null));
				//promises.push(_search(getSearchQuery('hjeventtype', vm, property, '' ), vm, vm.$jss.routeData().itemLanguage, "hjeventtypeall" + language.toLowerCase(), null));
				//promises.push(_search(getSearchQuery('HJHotel-Template', vm, property, '' ), vm, vm.$jss.routeData().itemLanguage, "hjhoteltemplateall" + language.toLowerCase(), vm.$jss.hotels()));
			}
			else if ( 
				type.toLowerCase() == "hjworkout"
			)
			{
				promises.push(_search(query, vm, vm.$jss.routeData().itemLanguage, type.toLowerCase()+cacheid, null));
				//promises.push(_search(getSearchQuery('HJOpenCloseTime', vm, property, '' ), vm, vm.$jss.routeData().itemLanguage, "hjopenclosetimeall" + language.toLowerCase()));
				//promises.push(_search(getSearchQuery('constants-hjroomfeature-type-template', vm, property, '' ), vm, vm.$jss.routeData().itemLanguage, "constantshjroomfeaturetypetemplateall" + language.toLowerCase(), vm.$jss.features()));
				//promises.push(_search(getSearchQuery('HJHotel-Template', vm, property, '' ), vm, vm.$jss.routeData().itemLanguage, "hjhoteltemplateall" + language.toLowerCase(), vm.$jss.hotels()));
			}
			else if ( 
				type.toLowerCase() == "hjrestaurant"
			)
			{
				promises.push(_search(query, vm, vm.$jss.routeData().itemLanguage, type.toLowerCase()+cacheid, null));
				//promises.push(_search(getSearchQuery('HJOpenCloseTime', vm, property, '' ), vm, vm.$jss.routeData().itemLanguage, "hjopenclosetimeall" + language.toLowerCase(), null));
				//promises.push(_search(getSearchQuery('HJHotel-Template', vm, property, '' ), vm, vm.$jss.routeData().itemLanguage, "hjhoteltemplateall" + language.toLowerCase(), vm.$jss.hotels()));
			}
			else if ( 
				type.toLowerCase() == "hjevent"
			)
			{
				promises.push(_search(query, vm, vm.$jss.routeData().itemLanguage, type.toLowerCase()+cacheid, null));
				//promises.push(_search(getSearchQuery('HJEventType', vm, property, '' ), vm, vm.$jss.routeData().itemLanguage, "hjeventtypeall" + language.toLowerCase(), null));
				//promises.push(_search(getSearchQuery('HJHotel-Template', vm, property, '' ), vm, vm.$jss.routeData().itemLanguage, "hjhoteltemplateall" + language.toLowerCase(), vm.$jss.hotels()));
			}
			else if ( 
				type.toLowerCase() == "hjhostcelebrate" ||
				type.toLowerCase() == "hjhostmeet"
			)
			{
				
				promises.push(_search(query, vm, vm.$jss.routeData().itemLanguage, type.toLowerCase()+cacheid, null));
				//promises.push(_search(getSearchQuery('HJOpenCloseTime', vm, property, '' ), vm, vm.$jss.routeData().itemLanguage, "hjopenclosetimeall" + language.toLowerCase(), vm.$jss.hotels()));
				//promises.push(_search(getSearchQuery('constants-hjroomfeature-type-template', vm, property, '' ), vm, vm.$jss.routeData().itemLanguage, "constantshjroomfeaturetypetemplateall" + language.toLowerCase(), vm.$jss.features()));
				//promises.push(_search(getSearchQuery('HJHotel-Template', vm, property, '' ), vm, vm.$jss.routeData().itemLanguage, "hjhoteltemplateall" + language.toLowerCase(), vm.$jss.hotels()));
			}
			else if ( 
				type.toLowerCase() == "hjnearby"
			)
			{
				promises.push(_search(query, vm, vm.$jss.routeData().itemLanguage, type.toLowerCase()+cacheid, null));
				//promises.push(_search(getSearchQuery('HJHotel-Template', vm, property, '' ), vm, vm.$jss.routeData().itemLanguage, "hjhoteltemplateall" + language.toLowerCase(), vm.$jss.hotels()));
			}
			else if ( 
				type.toLowerCase() == "hjservice"
			)
			{
				promises.push(_search(query, vm, vm.$jss.routeData().itemLanguage, type.toLowerCase()+cacheid, null));
				//promises.push(_search(getSearchQuery('HJHotel-Template', vm, property, '' ), vm, vm.$jss.routeData().itemLanguage, "hjhoteltemplateall" + language.toLowerCase(), vm.$jss.hotels()));
			}
			return Promise.all(promises)
			.then(results => {
				return results.map(result => {
					return result.data;
				});
			})
			.then(results => {
				getData(results, vm, graphqlresults, null, type, action, true, '', null, property, isfeatureimageonly, travellertype, event, context, true, true, searchresults, targetobject);
			  });
		}
		else
		{
			axios.post('/api/apollo', 
			{data:query, lang:vm.$jss.routeData().itemLanguage}, auth).then(result => { 
				getData(result, vm, graphqlresults, null, type, action, true, '', null, property, isfeatureimageonly, travellertype, event, context, true, true, searchresults, targetobject);
			})
			.catch( err => { console.log(err) } )
		}
	
		/*
		client.query({
			query: gql(query),
		})
		.then((data) => {
			console.log(data);
			getData(data, vm, graphqlresults, null, type, action, true, '', null, property, isfeatureimageonly, travellertype, event, context, true, true, searchresults, targetobject);
		})
		.catch((error) => console.error(error));
		*/
	
	}
	/*
	if (vm.$jss.hjconfig() == null)
	{
		$.getJSON( "/api/gethjconfig", function( data ) {
			vm.$jss.store.setHjconfig(data);
			_searchMoreContentByType(query, graphqlresults, type, vm, action, pagesize, lastpage, property, isfeatureimageonly, travellertype, language, condition, event, context, searchresults, targetobject);
		});			
	}
	else
	{*/
		_searchMoreContentByType(query, graphqlresults, type, vm, action, pagesize, lastpage, property, isfeatureimageonly, travellertype, language, condition, event, context, searchresults, targetobject);
	/*}*/	
}

export function searchMoreContentByType(
	graphqlresults, type, vm, action, pagesize, lastpage, property, isfeatureimageonly, travellertype, language, condition, event, context, searchresults, targetobject, path, targetobjectid, keeprawdata
) {

	//	targetobjectid used for jquery, ref or mounting components
	if (vm.$jss.hjconfig().use_version1 != undefined && vm.$jss.hjconfig().use_version1 != null && vm.$jss.hjconfig().use_version1 == true) {
		return searchMoreContentByType_v2(graphqlresults, type, vm, action, pagesize, lastpage, property, isfeatureimageonly, travellertype, language, condition, event, context, searchresults, targetobject, path);
	}
	var propertyvalue = "*";
	var propertycode = "";
	var propertyfolder = "";
	if (!(property == undefined || property == null || property == "")) {
		var propertyid = property.replace(/\-/g, "").toUpperCase();
		propertyvalue = propertyid;
		var hotelDetails = vm.$jss.hotels().children.find(x=>x.id.replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim().toLowerCase() === propertyid.replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim().toLowerCase());
		//var hotelDetails = getObject(getObjectById(vm.$jss.hotels(), propertyid), vm);
		if (hotelDetails != null && hotelDetails != undefined) {
			hotelDetails = getObject(hotelDetails, vm);
			if (hotelDetails && hotelDetails.hotelcode) {
				propertycode = hotelDetails.hotelcode.value;
				if (vm.$jss.hjconfig().hotelcodes && vm.$jss.hjconfig().hotelcodes.length > 0) {
					for (var i = 0; i < vm.$jss.hjconfig().hotelcodes.length; i++) {
						if (vm.$jss.hjconfig().hotelcodes[i][0].toString().toLowerCase() === propertycode.toLowerCase()) {
							propertyfolder = "/" + vm.$jss.hjconfig().hotelcodes[i][2];
							break;
						}
					}
				}
			}
		}
	}
	if (condition == undefined || condition == null || condition == "")
		condition = "";
	else {
		condition = "," + condition;
	}
	if (language == undefined || language == null)
		language = "en";
	if (isfeatureimageonly == undefined || isfeatureimageonly == null)
		isfeatureimageonly = false;
	if (travellertype == undefined || travellertype == null)
		travellertype = "other";
	if (event == undefined || event == null) {
		event = undefined;
	}
	if (context == undefined || context == null) {
		context = undefined;
	}
	if (searchresults == undefined || searchresults == null) {
		searchresults = null;
	}
	if (targetobject == undefined || targetobject == null) {
		targetobject = null;
	}
	if (pagesize == undefined || pagesize == null) {
		pagesize = 30;
	}
	if (path == undefined || path == null) {
		path = "";
	}
	if (targetobjectid == undefined || targetobjectid == null) {
		targetobjectid = "";
	}
	if (keeprawdata == undefined || keeprawdata == null) {
		keeprawdata = false;
	}


	var _type = "";
	var fragment = '';

	if (type.toLowerCase() == "hjdealstay" ||
		type.toLowerCase() == "hjdealeat" ||
		type.toLowerCase() == "hjdealspace" ||
		type.toLowerCase() == "hjdealwedding" ||
		type.toLowerCase() == "hjdeal") {
	}
	else if (type.toLowerCase() == "hotel" || type.toLowerCase() == "stay" || type.toLowerCase() == "hjhotel-template" ||
		type.toLowerCase() == "hjroom-template" ||
		type.toLowerCase() == "hjroom-template_minprices") {
	}
	else if (type.toLowerCase() == "hjrestaurant" || type.toLowerCase() == "hjworkout" ||
		type.toLowerCase() == "hjspace" || type.toLowerCase() == "hjevent"
	) {
	}
	else if (type.toLowerCase() == "hjhost" ||
		type.toLowerCase() == "hjhostmeet" ||
		type.toLowerCase() == "hjhostcelebrate") {
	}
	else if (type.toLowerCase() == "hjnearby") {
	}
	else if (type.toLowerCase() == "hjservice") {
	}
	else if (type.toLowerCase() === "hjpropertymenu") {
		pagesize = -1;

	}
	else if (type.toLowerCase() === "hjbrandmenu") {
		pagesize = -1;
	}
	else if (type.toLowerCase() === "adhoc") {
		pagesize = -1;
	}
	else {
		_type = "*";
	}

	var query = `query
{
  search(fieldsEqual:[
    {
    name:"_templatename", value:"${_type}" }
   ]
    rootItem: "${path}"
   ${paging} 
  ) {
    results {
      items {
        id
        templateName
        name
        language       
				item {
		   id
		   path
			template
			{
				name
			}
			fields(ownFields:true)
			{
			  __typename
			  name
			  value
			  editable
			}
			${fragment}
			children
			{
				id
				template
				{
					name
				}
				fields(ownFields:true)
				{
				  __typename
				  name
				  value
				  editable						  
				}
			}
        }
      }
    }
  }
}
`

	var paging = "";
	if (pagesize != -1) {
		if (lastpage >= 1) {
			lastpage += 1;
			paging = 'first: ' + pagesize.toString() + ' after: "' + lastpage.toString() + '"';
		}
		else
			paging = 'first: ' + pagesize.toString();
	}

	
	//var query = ``;


	var _searchMoreContentByType = function (query, graphqlresults, type, vm, action, pagesize, lastpage, property, propertycode, isfeatureimageonly, travellertype, language, condition, event, context, searchresults, targetobject, targetobjectid, keeprawdata) {
		const client = new ApolloClient({
			uri: vm.$jss.hjconfig().sitecoreApiHost + '/api/' + vm.$jss.hjconfig().jssAppName + '?sc_lang=' + vm.$jss.routeData().itemLanguage + '&sc_apikey=' + vm.$jss.hjconfig().sitecoreSCApiKey,
			defaultOptions: {
				//fetchPolicy: 'no-cache',
				fetchPolicy: 'cache-first',
			},
		});

		var auth = {
			headers: {}
		}
		appendAuthHeaders(auth.headers, vm.$jss.hjconfig().hmac_appsecret)

		const getgeolocation = () => {
			return new Promise((resolve, reject) => {
				axios.get('/api/getvisitorgeo')
					.then(
					result => result.text())
					.then(result => {
						resolve(result);
					}).catch(e => {
						reject(e);
					});
			})
		}

		const _search = (type, property, propertycode, _language, path, paging) => {
			if (path == undefined || path == null) {
				path = "";
			}
			if (paging) {
				paging = '';
			}

			return new Promise((resolve, reject) => {
				axios.post('/api/apolloapi',
					{ type: type, property: property, propertycode: propertycode, language:_language, path: path, paging:paging }, auth)
					.then(result => result)
					.then(result => {
						resolve(result);
					}).catch(e => {
						reject(e);
					});
			})
		}

		var cacheid = (propertyvalue == "*" ? "all" : propertyvalue.toLowerCase()) + language.toLowerCase();

		if (
			type.toLowerCase() == "hjdealeat" ||
			type.toLowerCase() == "hjdealspace" ||
			type.toLowerCase() == "hjdealwedding" ||
			type.toLowerCase() == "hjdeal" ||
			type.toLowerCase() == "hjhostcelebrate" ||
			type.toLowerCase() == "hjhostmeet" ||
			type.toLowerCase() == "hjevent" ||
			type.toLowerCase() == "hjservice" ||
			type.toLowerCase() == "hjnearby" ||
			type.toLowerCase() == "hjhost" ||
			type.toLowerCase() == "hjrestaurant" ||
			type.toLowerCase() == "hjspace" ||
			type.toLowerCase() == "hjworkout" ||
			type.toLowerCase() == "hjroom-template" ||
			type.toLowerCase() == "hjroom-template_minprices" ||
			type.toLowerCase() == "hjhotel-template" ||
			type.toLowerCase() == "hjdealstay"
		) {
			const promises = []

			promises.push(_search(type, property, propertycode, vm.$jss.routeData().itemLanguage, null, paging));
			if (
				type.toLowerCase() == "hjdealeat"
			) {
				promises.push(_search('HJRestaurant', property, propertycode, vm.$jss.routeData().itemLanguage, null, null));
			}
			else if (
				type.toLowerCase() == "hjspace" ||
				type.toLowerCase() == "hjdealspace"
			) {
				//promises.push(_search('hjeventtype', null, null, vm.$jss.routeData().itemLanguage, null, null));
			}
			else if (
				type.toLowerCase() == "hjdealwedding"
			) {
				//promises.push(_search('hjeventtype', null, null, vm.$jss.routeData().itemLanguage, null, null));
			}
			else if (
				type.toLowerCase() == "hjworkout"
			) {
				//promises.push(_search('HJOpenCloseTime', null, null, vm.$jss.routeData().itemLanguage, null, null));
			}
			else if (
				type.toLowerCase() == "hjrestaurant"
			) {
				//promises.push(_search('HJOpenCloseTime', null, null, vm.$jss.routeData().itemLanguage, null, null));
			}
			else if (
				type.toLowerCase() == "hjevent"
			) {
				//promises.push(_search('hjeventtype', null, null, vm.$jss.routeData().itemLanguage, null, null));
			}
			else if (
				type.toLowerCase() == "hjhostcelebrate" ||
				type.toLowerCase() == "hjhostmeet"
			) {

				//promises.push(_search('HJOpenCloseTime', null, null, vm.$jss.routeData().itemLanguage, null, null));
			}
			//console.log(vm.$jss.hjgeocookie());
			if (!(vm.$jss.hjgeocookie() != null && vm.$jss.hjgeocookie() != ''))
			{
				
				promises.push(_search("geolocation", property, propertycode, vm.$jss.routeData().itemLanguage, null, paging));
				//promises.push(getgeolocation);
			}
	
			return Promise.all(promises)
				.then(results => {
					return results.map(result => {
						return result.data;
					});
				})
				.then(results => {
					getData(results, vm, graphqlresults, null, type, action, true, '', null, property, isfeatureimageonly, travellertype, event, context, true, true, searchresults, targetobject, targetobjectid, keeprawdata);
				});
		}
		else {
			//if (type.toLowerCase() == "hjcustom")
			//{
			//	axios.post('/api/apollo', 
			//	{data:query, lang:vm.$jss.routeData().itemLanguage}, auth).then(result => { 
			//		getData(result, vm, graphqlresults, null, type, action, true, '', null, property, isfeatureimageonly, travellertype, event, context, true, true, searchresults, targetobject);
			//	})
			//	.catch( err => { console.log(err) } )
			//}
			//else
			//{
				const promises = []
				promises.push(_search(type, property, propertycode, vm.$jss.routeData().itemLanguage, path, paging));
				if (!(vm.$jss.hjgeocookie() != null && vm.$jss.hjgeocookie() != ''))
				{
					promises.push(_search("geolocation", property, propertycode, vm.$jss.routeData().itemLanguage, path, paging));
					//promises.push(getgeolocation);
				}
				return Promise.all(promises)
					.then(results => {
						return results.map(result => {
							return result.data;
						});
					})
					.then(results => {
						getData(results, vm, graphqlresults, null, type, action, true, '', null, property, isfeatureimageonly, travellertype, event, context, true, true, searchresults, targetobject, targetobjectid, keeprawdata);
					});
				
				/*
				axios.post('/api/apolloapi',
					{ type: type, property: property, propertycode: propertycode, language: vm.$jss.routeData().itemLanguage, path: path, paging:paging }, auth).then(result => {
						getData(result, vm, graphqlresults, null, type, action, true, '', null, property, isfeatureimageonly, travellertype, event, context, true, true, searchresults, targetobject, targetobjectid);
					})
					.catch(err => { console.log(err) })
				*/
			//}
		}

		/*
		client.query({
			query: gql(query),
		})
		.then((data) => {
			console.log(data);
			getData(data, vm, graphqlresults, null, type, action, true, '', null, property, isfeatureimageonly, travellertype, event, context, true, true, searchresults, targetobject);
		})
		.catch((error) => console.error(error));
		*/

	}
	/*
	if (vm.$jss.hjconfig() == null)
	{
		$.getJSON( "/api/gethjconfig", function( data ) {
			vm.$jss.store.setHjconfig(data);
			_searchMoreContentByType(query, graphqlresults, type, vm, action, pagesize, lastpage, property, isfeatureimageonly, travellertype, language, condition, event, context, searchresults, targetobject);
		});			
	}
	else
	{*/
	_searchMoreContentByType(query, graphqlresults, type, vm, action, pagesize, lastpage, property, propertycode, isfeatureimageonly, travellertype, language, condition, event, context, searchresults, targetobject, targetobjectid, keeprawdata);
	/*}*/
}


export function getminpricerooms(vm, prices, currency, travellertype, likes, maxcount, graphqlrooms) {
  if (travellertype == undefined || travellertype.toLowerCase() == 'other' || travellertype == '') {
    travellertype = 'Other';
  }

	if (maxcount == undefined || maxcount == null)
	{
		maxcount = -1;
	}	

	var roomsobject = null;
	var rooms = [];
	
	var valueobj = {};
	valueobj['value'] = 0;
  var hotelobject = null;
  //if (vm != null && vm.$jss.hotels() != null)
  //{
		hotelobject = vm.$jss.hotels();
  //}
  //else
  //{
		//vm.$jss.store.setHotels(getContentByType(graphqlToObject('', vm.$jss.hotels(), vm, '', null, false), "hjhotel-template", vm, false, false, 'other', false, 2000));
	//	hotelobject = vm.$jss.hotels();
  //}
  if (graphqlrooms != undefined && graphqlrooms != null)
  {
	roomsobject = getObject(graphqlrooms, vm);
  }
  var newPrices = vm.$jss.prices()
  
  var lfound = false;
  for (var i=0; i < hotelobject.children.length; i++)
  {
	if (vm != null && vm.$jss.minprices() != null && vm.$jss.minprices().length > 0)
	{
		lfound = false;
		for (var k=0; k < vm.$jss.minprices().length; k++)
		{
			if (hotelobject.children[i]['hotelcode'].value.toLowerCase() === vm.$jss.minprices()[k].hotelcode.toLowerCase())
			{
			  //var hotelrooms = roomsobject.children.find(x=>x.id == hotelobject.children[i]['id'].toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim());
				var hotelrooms = [];
				for (var j=0; j < roomsobject.children.length; j++)
				{
					if (roomsobject.children[j]['property'].value.toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim() === hotelobject.children[i]['id'].toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim())
					{
						hotelrooms.push(roomsobject.children[j]);
					}
				}
			  if (hotelrooms != undefined && hotelrooms != null)
			  {
				  for (var j=0; j < hotelrooms.length; j++)
				  {
					  if (hotelrooms[j]['roomtypecode'].value.toLowerCase().substring(0,2) === vm.$jss.minprices()[k].roomcode.toLowerCase().substring(0,2))
					  {
						  var roomobject = hotelrooms[j];
						  roomobject['price'] = vm.$jss.minprices()[k].price;
						  if (!(likes != undefined && likes != null && likes.length > 0))
						  {
							rooms.push(roomobject);
							lfound = true;
						  }
						  else
						  {
							var ispushed = false;
							for (var l=0; l < likes.length; l++)
							{
								if (likes[l]["id"] && likes[l]["id"].toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim() === hotelrooms[j]['id'].toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim())
								{
									rooms.unshift(roomobject);
									ispushed = true;
									lfound = true;
									break;
								}
							}
							if (!ispushed)
							{
								//hotelrooms[j].price = valueobj;
								rooms.push(roomobject);
								lfound = true;
							}
						  }
						  if (!(likes != undefined && likes != null && likes.length > 0))
							break;
					  }
					  else
					  {
						  if (likes != undefined && likes != null && likes.length > 0)
						  {
							for (var l=0; l < likes.length; l++)
							{
								if (likes[l]["id"] && likes[l]["id"].toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim() === hotelrooms[j]['id'].toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim())
								{
									var roomobject = hotelrooms[j];
									roomobject['price'] = vm.$jss.minprices()[k].price;
									rooms.unshift(roomobject);
									lfound = true;
									break;
								}
							}
						  }
					  }
				  }
			  }
			  break;
			}
		}
		if (!lfound)
		{
			/*
			var hotelrooms = roomsobject.children.find(x=>x['property'].value.toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim() === hotelobject.children[i]['id'].toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim());
			if (hotelrooms != undefined && hotelrooms != null && hotelrooms.length > 0) {
				var roomobject = hotelrooms[0];
				roomobject['price'] = 0;
				rooms.push(roomobject);
			}
			*/
			var hotelrooms = [];
			if (roomsobject != null)
			{
				for (var j=0; j < roomsobject.children.length; j++)
				{
					if (roomsobject.children[j]['property'].value.toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim() === hotelobject.children[i]['id'].toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim())
					{
						hotelrooms.push(roomsobject.children[j]);
						break;
					}
				}
				if (hotelrooms != undefined && hotelrooms.length > 0) {
					var roomobject = hotelrooms[0];
					roomobject['price'] = 0;
					rooms.push(roomobject);
				}
			}
		}
	}
	else
	{
		var hotelrooms = null;
		if (roomsobject != null)
		{
			hotelrooms = roomsobject.children.find(x=>x['property'].value.toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim() === hotelobject.children[i]['id'].toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim());
		}
		else
		{
			hotelrooms = vm.$jss.rooms().children.find(x=>x['property'].value.toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim() === hotelobject.children[i]['id'].toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim());
		}
	  hotelrooms = roomsobject.children;
	  if (hotelrooms != undefined && hotelrooms.length > 0)
	  {
		  if (!(likes != undefined && likes != null && likes.length > 0))
		  {
			hotelrooms[0].price = valueobj;
			rooms.push(hotelrooms[0]);
		  }
		  else
		  {
			  var randomindex = Math.floor(Math.random() * hotelrooms.length); 
			  for (var j=0; j < hotelrooms.length; j++)
			  {
				  if (j == randomindex)	// always push the first one
				  {
					  if (!(likes != undefined && likes != null && likes.length > 0))
					  {
						hotelrooms[j].price = valueobj;
						rooms.push(hotelrooms[j]);
					  }
					  else
					  {
						var ispushed = false;
						for (var l=0; l < likes.length; l++)
						{
							if (likes[l]["id"] && likes[l]["id"].toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim() === hotelrooms[j]['id'].toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim())
							{
								hotelrooms[j].price = valueobj;
								rooms.unshift(hotelrooms[j]);
								ispushed = true;
								break;
							}
						}
						if (!ispushed){
							hotelrooms[j].price = valueobj;
							rooms.push(hotelrooms[j]);
					  }
					  }
					  if (!(likes != undefined && likes != null && likes.length > 0))
						break;
				  }
				  else
				  {
					  if (likes != undefined && likes != null && likes.length > 0)
					  {
						for (var l=0; l < likes.length; l++)
						{
							if (likes[l]["id"] && likes[l]["id"].toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim() === hotelrooms[j]['id'].toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim())
							{
								hotelrooms[j].price = valueobj;
								rooms.unshift(hotelrooms[j]);
								break;
							}
						}
					  }
				  }
			  }
		  }
	  }
	}
  }
  
  if (rooms.length > 0 && maxcount != -1)
  {
	  if (rooms.length > 0)
		rooms = rooms.slice(0, maxcount);
  }
  return rooms;
}


export function _getminpricerooms(vm, prices, currency, travellertype, likes, maxcount, graphqlrooms) {
  if (travellertype == undefined || travellertype.toLowerCase() == 'other' || travellertype == '') {
    travellertype = 'Other';
  }

	if (maxcount == undefined || maxcount == null)
	{
		maxcount = -1;
	}	

	var rooms = [];
	
	var valueobj = {};
	valueobj['value'] = 0;
  var hotelobject = null;
  //if (vm != null && vm.$jss.hotels() != null)
  //{
		hotelobject = vm.$jss.hotels();
  //}
  //else
  //{
		//vm.$jss.store.setHotels(getContentByType(graphqlToObject('', vm.$jss.hotels(), vm, '', null, false), "hjhotel-template", vm, false, false, 'other', false, 2000));
	//	hotelobject = vm.$jss.hotels();
  //}
  
  var roomsobject = null;
  if (graphqlrooms != undefined && graphqlrooms != null)
  {
	roomsobject = getObject(graphqlrooms, vm);
  }

  var lfound = false;
  
  for (var i=0; i < hotelobject.children.length; i++)
  {
	if (vm != null && vm.$jss.minprices() != null && vm.$jss.minprices().length > 0)
	{
		lfound = false;
		for (var k=0; k < vm.$jss.minprices().length; k++)
		{
			if (hotelobject.children[i]['hotelcode'].value.toLowerCase() === vm.$jss.minprices()[k].hotelcode.toLowerCase())
			{
			  //var hotelrooms = getContentByType(hotelobject.children[i], "hjroom-template", vm, false, false, travellertype, false, 2000);
			  var hotelrooms = roomsobject.children.find(x=>x.id == hotelobject.children[i]['id'].toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim());
			  for (var j=0; j < hotelrooms.length; j++)
			  {
				  if (hotelrooms[j]['roomtypecode'].value.toLowerCase() === vm.$jss.minprices()[k].roomcode.toLowerCase())
				  {
					  var roomobject = hotelrooms[j];
					  roomobject['price'] = vm.$jss.minprices()[k].price;
					  if (!(likes != undefined && likes != null && likes.length > 0))
					  {
						//hotelrooms[j].price = valueobj;
						rooms.push(roomobject);
						lfound = true;
					  }
					  else
					  {
						var ispushed = false;
						for (var l=0; l < likes.length; l++)
						{
							if (likes[l]["id"] && likes[l]["id"].toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim() === hotelrooms[j]['id'].toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim())
							{
								//hotelrooms[j].price = valueobj;
								rooms.unshift(roomobject);
								ispushed = true;
								lfound = true;
								break;
							}
						}
						if (!ispushed)
						{
							//hotelrooms[j].price = valueobj;
							rooms.push(roomobject);
							lfound = true;
						}
					  }
					  if (!(likes != undefined && likes != null && likes.length > 0))
						break;
				  }
				  else
				  {
					  if (likes != undefined && likes != null && likes.length > 0)
					  {
						for (var l=0; l < likes.length; l++)
						{
							if (likes[l]["id"] && likes[l]["id"].toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim() === hotelrooms[j]['id'].toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim())
							{
								var roomobject = hotelrooms[j];
								roomobject['price'] = vm.$jss.minprices()[k].price;
								rooms.unshift(roomobject);
								lfound = true;
								break;
							}
						}
					  }
				  }
			  }
			  break;
			}
		}
		if (!lfound)
		{
			//var hotelrooms = roomsobject.children.find(x=>x.id == hotelobject.children[i]['id'].toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim());
			var hotelrooms = roomsobject.children.find(x=>x['property'].value.toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim() === hotelobject.children[i]['id'].toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim());
			if (hotelrooms.length > 0) {
				var roomobject = hotelrooms[0];
				roomobject['price'] = 0;
				rooms.push(roomobject);
			}
		}
	}
	else
	{
		var hotelrooms = roomsobject.find(x=>x.id == hotelobject.children[i]['id'].toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim());
	  if (hotelrooms.length > 0)
	  {
		  if (!(likes != undefined && likes != null && likes.length > 0))
		  {
			hotelrooms[0].price = valueobj;
			rooms.push(hotelrooms[0]);
		  }
		  else
		  {
			  var randomindex = Math.floor(Math.random() * hotelrooms.length); 
			  for (var j=0; j < hotelrooms.length; j++)
			  {
				  if (j == randomindex)	// always push the first one
				  {
					  if (!(likes != undefined && likes != null && likes.length > 0))
					  {
						hotelrooms[j].price = valueobj;
						rooms.push(hotelrooms[j]);
					  }
					  else
					  {
						var ispushed = false;
						for (var l=0; l < likes.length; l++)
						{
							if (likes[l]["id"] && likes[l]["id"].toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim() === hotelrooms[j]['id'].toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim())
							{
								hotelrooms[j].price = valueobj;
								rooms.unshift(hotelrooms[j]);
								ispushed = true;
								break;
							}
						}
						if (!ispushed){
							hotelrooms[j].price = valueobj;
							rooms.push(hotelrooms[j]);
					  }
					  }
					  if (!(likes != undefined && likes != null && likes.length > 0))
						break;
				  }
				  else
				  {
					  if (likes != undefined && likes != null && likes.length > 0)
					  {
						for (var l=0; l < likes.length; l++)
						{
							if (likes[l]["id"] && likes[l]["id"].toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim() === hotelrooms[j]['id'].toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim())
							{
								hotelrooms[j].price = valueobj;
								rooms.unshift(hotelrooms[j]);
								break;
							}
						}
					  }
				  }
			  }
		  }
	  }
	}
  }
  if (rooms.length > 0 && maxcount != -1)
  {
	  if (rooms.length > 0)
		rooms = rooms.slice(0, maxcount);
  }
  return rooms;
}

export function __getminpricerooms(vm, prices, currency, travellertype, likes, maxcount, graphqlrooms) {
  if (travellertype == undefined || travellertype.toLowerCase() == 'other' || travellertype == '') {
    travellertype = 'Other';
  }

	if (maxcount == undefined || maxcount == null)
	{
		maxcount = -1;
	}	

	var roomsobject = null;
	var rooms = [];
	
	var valueobj = {};
	valueobj['value'] = 0;
  var hotelobject = null;
  //if (vm != null && vm.$jss.hotels() != null)
  //{
		hotelobject = vm.$jss.hotels();
  //}
  //else
  //{
		//vm.$jss.store.setHotels(getContentByType(graphqlToObject('', vm.$jss.hotels(), vm, '', null, false), "hjhotel-template", vm, false, false, 'other', false, 2000));
	//	hotelobject = vm.$jss.hotels();
  //}
  if (graphqlrooms != undefined && graphqlrooms != null)
  {
	roomsobject = getObject(graphqlrooms, vm);
  }
  var newPrices = vm.$jss.prices()
  
  var lfound = false;
  for (var i=0; i < hotelobject.children.length; i++)
  {
	if (vm != null && vm.$jss.minprices() != null && vm.$jss.minprices().length > 0)
	{
		var minprice = vm.$jss.minprices().find(x=>x.hotelcode.toLowerCase() === hotelobject.children[i]['hotelcode'].value.toLowerCase());
		if (minprice != undefined)
		{
			var hotelroom = null;
			if (roomsobject != null)
			{
				hotelroom = roomsobject.children.find(x=>x['property'].value.toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim() == hotelobject.children[i]['id'].toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim() && x['roomtypecode'].value.substring(0,2) === minprice['roomcode'].substring(0,2) );
				/*
				for (var j=0; j < roomsobject.children.length; j++)
				{
					if (roomsobject.children[j]["property"].value.toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim() === hotelobject.children[i]['id'].toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim() && roomsobject.children[j]['roomtypecode'].value.toLowerCase().substring(0,2) === minprice['roomcode'].toLowerCase().substring(0,2))
					{
						hotelroom = roomsobject.children[j];
						break;
					}
				}
				*/
			}
			else
			{
				hotelroom = vm.$jss.rooms().children.find(x=>x['property'].value.toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim() == hotelobject.children[i]['id'].toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim() && x['roomtypecode'].value.substring(0,2) === minprice['roomcode'].substring(0,2) );
			}
			if (hotelroom == null)
			{
				var hotelrooms = [];
				if (roomsobject != null)
				{
					//hotelrooms = roomsobject.children.find(x=>x['property'].value.toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim() === hotelobject.children[i]['id'].toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim());
					for (var j=0; j < roomsobject.children.length; j++)
					{
						if (roomsobject.children[j]['property'].value.toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim() === hotelobject.children[i]['id'].toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim())
						{
							hotelrooms.push(roomsobject.children[j]);
							break;
						}
					}
				}
				else
				{
					hotelrooms = vm.$jss.rooms().children.find(x=>x['property'].value.toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim() === hotelobject.children[i]['id'].toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim());
				}
				hotelroom = hotelrooms[0];
			}
			if (hotelroom != undefined && hotelroom != null)
			{
				hotelroom['price'] = {};
				hotelroom['price'] =  getHotelCurrencyPrice(minprice.price, hotelobject.children[i]['hotelcode'].value.toLowerCase(), prices, currency, travellertype);
				  if (!(likes != undefined && likes != null && likes.length > 0))
				  {
					rooms.push(hotelroom);
				  }
				  else
				  {
					var ispushed = false;
					for (var l=0; l < likes.length; l++)
					{
						if (likes[l]["id"] && likes[l]["id"].toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim() === hotelrooms[j]['id'].toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim())
						{
							rooms.unshift(hotelroom);
							ispushed = true;
							break;
						}
					}
					if (!ispushed)
					{
						rooms.push(hotelroom);
					}
				  }
			}
		}
		else
		{
			var hotelrooms = [];
			if (roomsobject != null)
			{
				//hotelrooms = roomsobject.children.find(x=>x['property'].value.toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim() === hotelobject.children[i]['id'].toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim());
				for (var j=0; j < roomsobject.children.length; j++)
				{
					if (roomsobject.children[j]['property'].value.toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim() === hotelobject.children[i]['id'].toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim())
					{
						hotelrooms.push(roomsobject.children[j]);
						break;
					}
				}
			}
			else
			{
				hotelrooms = vm.$jss.rooms().children.find(x=>x['property'].value.toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim() === hotelobject.children[i]['id'].toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim());
			}
			if (hotelrooms != undefined && hotelrooms.length > 0) {
				var roomobject = hotelrooms[0];
				roomobject['price'] = 0;
				rooms.push(roomobject);
			}
		}
	}
	else
	{
		var hotelrooms = null;
		if (roomsobject != null)
		{
			hotelrooms = roomsobject.children.find(x=>x['property'].value.toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim() === hotelobject.children[i]['id'].toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim());
		}
		else
		{
			hotelrooms = vm.$jss.rooms().children.find(x=>x['property'].value.toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim() === hotelobject.children[i]['id'].toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim());
		}
	  hotelrooms = roomsobject.children;
	  if (hotelrooms != undefined && hotelrooms.length > 0)
	  {
		  if (!(likes != undefined && likes != null && likes.length > 0))
		  {
			hotelrooms[0].price = valueobj;
			rooms.push(hotelrooms[0]);
		  }
		  else
		  {
			  var randomindex = Math.floor(Math.random() * hotelrooms.length); 
			  for (var j=0; j < hotelrooms.length; j++)
			  {
				  if (j == randomindex)	// always push the first one
				  {
					  if (!(likes != undefined && likes != null && likes.length > 0))
					  {
						hotelrooms[j].price = valueobj;
						rooms.push(hotelrooms[j]);
					  }
					  else
					  {
						var ispushed = false;
						for (var l=0; l < likes.length; l++)
						{
							if (likes[l]["id"] && likes[l]["id"].toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim() === hotelrooms[j]['id'].toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim())
							{
								hotelrooms[j].price = valueobj;
								rooms.unshift(hotelrooms[j]);
								ispushed = true;
								break;
							}
						}
						if (!ispushed){
							hotelrooms[j].price = valueobj;
							rooms.push(hotelrooms[j]);
					  }
					  }
					  if (!(likes != undefined && likes != null && likes.length > 0))
						break;
				  }
				  else
				  {
					  if (likes != undefined && likes != null && likes.length > 0)
					  {
						for (var l=0; l < likes.length; l++)
						{
							if (likes[l]["id"] && likes[l]["id"].toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim() === hotelrooms[j]['id'].toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim())
							{
								hotelrooms[j].price = valueobj;
								rooms.unshift(hotelrooms[j]);
								break;
							}
						}
					  }
				  }
			  }
		  }
	  }
	}
  }
  
  if (rooms.length > 0 && maxcount != -1)
  {
	  if (rooms.length > 0)
		rooms = rooms.slice(0, maxcount);
  }
  return rooms;
}


export function getContentAPI()
{
	return "my content";
}

export function getKeys(item) {
	
  if (!Object.keys) {
    Object.keys = (function() {
      'use strict';
      var hasOwnProperty = Object.prototype.hasOwnProperty,
        hasDontEnumBug = !{ toString: null }.propertyIsEnumerable('toString'),
        dontEnums = [
          'toString',
          'toLocaleString',
          'valueOf',
          'hasOwnProperty',
          'isPrototypeOf',
          'propertyIsEnumerable',
          'constructor',
        ],
        dontEnumsLength = dontEnums.length;

      return function(obj) {
        if (typeof obj !== 'function' && (typeof obj !== 'object' || obj === null)) {
          throw new TypeError('Object.keys called on non-object');
        }

        var result = [],
          prop,
          i;

        for (prop in obj) {
          if (hasOwnProperty.call(obj, prop)) {
            result.push(prop);
          }
        }

        if (hasDontEnumBug) {
          for (i = 0; i < dontEnumsLength; i++) {
            if (hasOwnProperty.call(obj, dontEnums[i])) {
              result.push(dontEnums[i]);
            }
          }
        }
        return result;
      };
    })();
  }

  var keys = Object.keys(item);
  return keys;
}

export function getDictionaryPhrase(key, vm)
{
	var getObject = function(o)
	{
		if (o['template'] && 
			o['template'].name && 
			o['template'].name.toLowerCase() === "hjdictionary")
		{
			if (o['key'].value.toLowerCase() === key.toLowerCase())
			{
				return o['phrase'].value;
			}
		}	
		return null;
	};
	
	if (vm != null && vm.$jss.dictionary() != null)
	{
		if (vm.$jss.dictionary().children && vm.$jss.dictionary().children.length > 0)
		{
			for (var i=0; i < vm.$jss.dictionary().children.length; i++)
			{	
				if (vm.$jss.dictionary().children[i]['children'] && vm.$jss.dictionary().children[i]['children'].length > 0)
				{
					for (var j=0; j < vm.$jss.dictionary().children[i]['children'].length; j++)
					{
						var o = getObject(vm.$jss.dictionary().children[i]['children'][j]);
						if (o != null)
							return o;
					}
				}
				else
				{
					var o = getObject(vm.$jss.dictionary().children[i]);
					if (o != null)
						return o;
				}				
			}
		}
	}
	return key;
}

export function searchMoreContentByType_v1(
	graphqlresults, type, vm, action, pagesize, lastpage, property, isfeatureimageonly, travellertype, language, condition, event, context, searchresults, targetobject, path 
)
{  

	console.log("using old version");	
	var propertycode = "";
	var propertyfolder = "";
	if (!(property == undefined || property == null || property == ""))
	{
		var propertyid = property.replace(/\-/g,"").toUpperCase();
		var hotelDetails = getObject(getObjectById(vm.$jss.hotels(),propertyid), vm);
		if (hotelDetails)
		{
			if (hotelDetails && hotelDetails.hotelcode)
			{
				propertycode = hotelDetails.hotelcode.value;
				if (vm.$jss.hjconfig().hotelcodes && vm.$jss.hjconfig().hotelcodes.length > 0)
				{
					for (var i=0; i < vm.$jss.hjconfig().hotelcodes.length; i++)
					{
						if (vm.$jss.hjconfig().hotelcodes[i][0].toString().toLowerCase() === propertycode.toLowerCase())
						{
							propertyfolder = "/" + vm.$jss.hjconfig().hotelcodes[i][2];
							break;
						}
					}
				}
			}
		}
	}
	if (condition == undefined || condition == null || condition == "")
		condition = "";
	else
	{
		condition = ","+condition; 
	}
	if (language == undefined || language == null)
		language = "en";
	if (isfeatureimageonly == undefined || isfeatureimageonly == null)
		isfeatureimageonly = false;
	if (travellertype == undefined || travellertype == null)
		travellertype = "other";
	if (event == undefined || event == null)
	{
		event = undefined;
	}
	if (context == undefined || context == null)
	{
		context = undefined;
	}
	if (searchresults == undefined || searchresults == null)
	{
		searchresults = null;
	}
	if (targetobject == undefined || targetobject == null)
	{
		targetobject = null;
	}
	if (pagesize == undefined || pagesize == null)
	{
		pagesize = 30;
	}
	if (path == undefined || path == null)
	{
		path = "";
	}
	
	
	var _type = "";
	var fragment = '';
	
	if (type.toLowerCase() == "hjdealstay" || 
		type.toLowerCase() == "hjdealeat" || 
		type.toLowerCase() == "hjdealspace" || 
		type.toLowerCase() == "hjdealwedding" || 
		type.toLowerCase() == "hjdeal") 
	{		
		path = "/sitecore/content/hoteljen/Content/deals" + propertyfolder;
		if (type.toLowerCase() == "hjdealstay") 
		{
			_type = "HJDealStay";
				fragment = `...on HJDealStay {
					  room
					  {
							targetItems {
								...on HJRoomTemplate {
									id 
						 title: field(name:"Title")

						  {
							value
						  }
						 description: field(name:"Description")

						  {
							value
						  }
						 roomtypecode: field(name:"RoomTypeCode")

						  {
							value
						  }
						 roomcode: field(name:"RoomCode")

						  {
							value
						  }
								}
							}
					}
					  property
					  {
							targetItems {
								...on HJHotelTemplate {
									id 
						 title: field(name:"Title")

						  {
							value
						  }
						 description: field(name:"Description")

						  {
							value
						  }
						 hotelcode: field(name:"HotelCode")

						  {
							value
						  }
						  cityalias: field(name:"CityAlias")
						  {
							value
						  }
						 hotelalias: field(name:"HotelAlias")

						  {
							value
						  }
						  tzid: field(name:"Tzid")
						  {
							  value
						  }
								}
							}
					}
					contentCategory
					{
						targetItems {
							...on HJContentCategory {
								title: field(name:"Title")

								{
									value
								}
								description: field(name:"Description")

								{
									value
								}
								value
								{
									value
								}
							}
						}
					}
					  property
					  {
							targetItems {
								...on HJHotelTemplate {
									id 
						 title: field(name:"Title")

						  {
							value
						  }
						 description: field(name:"Description")

						  {
							value
						  }
						 hotelcode: field(name:"HotelCode")

						  {
							value
						  }
						  cityalias: field(name:"CityAlias")
						  {
							value
						  }
						 hotelalias: field(name:"HotelAlias")

						  {
							value
						  }
						  tzid: field(name:"Tzid")
						  {
							  value
						  }
								}
							}
					}
				}`
				fragment = `...on HJDealStay {
					  room
					  {
							targetItems {
								...on HJRoomTemplate {
									id 
						 title: field(name:"Title")

						  {
							value
						  }
						 description: field(name:"Description")

						  {
							value
						  }
						 roomtypecode: field(name:"RoomTypeCode")

						  {
							value
						  }
						 roomcode: field(name:"RoomCode")

						  {
							value
						  }
								}
							}
					}
					contentCategory
					{
						targetItems {
							...on HJContentCategory {
								title: field(name:"Title")

								{
									value
								}
								description: field(name:"Description")

								{
									value
								}
								value: field(name:"Value")
								{
									value
								}
							}
						}
					}
					  property
					  {
							targetItems {
								...on HJHotelTemplate {
									id 
						 title: field(name:"Title")

						  {
							value
						  }
						 description: field(name:"Description")

						  {
							value
						  }
						 hotelcode: field(name:"HotelCode")

						  {
							value
						  }
						  cityalias: field(name:"CityAlias")
						  {
							value
						  }
						 hotelalias: field(name:"HotelAlias")

						  {
							value
						  }
						  tzid: field(name:"Tzid")
						  {
							  value
						  }
								}
							}
					}
				}`;
		}
		if (type.toLowerCase() == "hjdealeat") 
		{
			_type = "HJDealEat";
			fragment = `...on HJDealEat {
				  restaurant
				  {
						targetItems {
							...on HJRestaurant {
								id 
					 title: field(name:"Title")

					  {
						value
					  }
					 bookingurl: field(name:"BookingUrl")

					  {
						value
					  }
					 isbooking: field(name:"IsBooking")

					  {
						value
					  }
					 iseat2eat: field(name:"IsEat2Eat")

					  {
						value
					  }
							}
						}
				}
				  property
				  {
						targetItems {
							...on HJHotelTemplate {
								id 
					 title: field(name:"Title")

					  {
						value
					  }
					 description: field(name:"Description")

					  {
						value
					  }
					 hotelcode: field(name:"HotelCode")

					  {
						value
					  }
					 cityalias: field(name:"CityAlias")

					  {
						value
					  }
					 hotelalias: field(name:"HotelAlias")

					  {
						value
					  }
					  tzid: field(name:"Tzid")
					  {
						  value
					  }
							}
						}
				}
				contentCategory
				{
					targetItems {
						...on HJContentCategory {
							title: field(name:"Title")

							{
								value
							}
							description: field(name:"Description")

							{
								value
							}
							value: field(name:"Value")
							{
								value
							}
						}
					}
				}
			}`
		}
		if (type.toLowerCase() == "hjdealspace") 
		{
			_type = "HJDealSpace";
			fragment = `...on HJDealSpace {
				  space
				  {
						targetItems {
							...on HJSpace {
								id 
					 title: field(name:"Title")

					  {
						value
					  }
							}
						}
				}
				  property
				  {
						targetItems {
							...on HJHotelTemplate {
								id 
					 title: field(name:"Title")

					  {
						value
					  }
					 description: field(name:"Description")

					  {
						value
					  }
					 hotelcode: field(name:"HotelCode")

					  {
						value
					  }
					 cityalias: field(name:"CityAlias")

					  {
						value
					  }
					 hotelalias: field(name:"HotelAlias")

					  {
						value
					  }
					  tzid: field(name:"Tzid")
					  {
						  value
					  }
							}
						}
				}
				contentCategory
				{
					targetItems {
						...on HJContentCategory {
							title: field(name:"Title")

							{
								value
							}
							description: field(name:"Description")

							{
								value
							}
							value: field(name:"Value")
							{
								value
							}
						}
					}
				}
			}`
		}
		if (type.toLowerCase() == "hjdealspace") 
		{
			_type = "HJDealWedding";
			fragment = `...on HJDealWedding {
				  property
				  {
						targetItems {
							...on HJHotelTemplate {
								id 
					 title: field(name:"Title")

					  {
						value
					  }
					 description: field(name:"Description")

					  {
						value
					  }
					 hotelcode: field(name:"HotelCode")

					  {
						value
					  }
					 cityalias: field(name:"CityAlias")

					  {
						value
					  }
					 hotelalias: field(name:"HotelAlias")

					  {
						value
					  }
					  tzid: field(name:"Tzid")
					  {
						  value
					  }
							}
						}
				}
				contentCategory
				{
					targetItems {
						...on HJContentCategory {
							title: field(name:"Title")

							{
								value
							}
							description: field(name:"Description")

							{
								value
							}
							value: field(name:"Value")
							{
								value
							}
						}
					}
				}
			}`
		}
		if (type.toLowerCase() == "hjdeal") 
		{
			_type = "HJDeal";			
			fragment = `...on HJDeal {
			  property
				  {
						targetItems {
							...on HJHotelTemplate {
								id 
					 title: field(name:"Title")

					  {
						value
					  }
					 description: field(name:"Description")

					  {
						value
					  }
					 hotelcode: field(name:"HotelCode")

					  {
						value
					  }
					 cityalias: field(name:"CityAlias")

					  {
						value
					  }
					 hotelalias: field(name:"HotelAlias")

					  {
						value
					  }
					  tzid: field(name:"Tzid")
					  {
						  value
					  }
							}
						}
				}
			}`
			
		}
	}
	else if (type.toLowerCase() == "hotel" || type.toLowerCase() == "stay" || type.toLowerCase() == "hjhotel-template" ||
		 type.toLowerCase() == "hjroom-template" || type.toLowerCase() == "hjroom-template_minprices")
	{
		path = "/sitecore/content/hoteljen/Content/hotels";
		if (type.toLowerCase() == "hjroom-template" || type.toLowerCase() == "hjroom-template_minprices")
		{
			_type = "HJRoom-Template";
			fragment = `...on HJRoomTemplate {
				features
				{
					targetItems {
						...on ConstantsHJRoomFeatureTypeTemplate {
							title: field(name:"Title")
			 
							{
							  value
							}
							description: field(name:"Description")
							{
							  value
							}
							moredescription: field(name:"MoreDescription")
							{
							  value
							}
								image 
								{
								  jss
								}
						}
					}
				}
				property
				{
						targetItems {
							...on HJHotelTemplate {
								id 
					 title: field(name:"Title")

					  {
						value
					  }
					 description: field(name:"Description")

					  {
						value
					  }
					 hotelcode: field(name:"HotelCode")

					  {
						value
					  }
					 cityalias: field(name:"CityAlias")

					  {
						value
					  }
					 hotelalias: field(name:"HotelAlias")

					  {
						value
					  }
					  tzid: field(name:"Tzid")
					  {
						  value
					  }
							}
						}
				}
			}`
		}
		else
		{
			_type = "HJHotel-Template";
			fragment = `...on HJHotelTemplate {
				  amenities
				  {
						targetItems {
							...on ConstantsHJAmenityTypeTemplate {
								title: field(name:"Title")
			 
								{
								  value
								}
								description: field(name:"Description")
								{
								  value
								}
								image : field(name:"Image")
								{
								  jss
								}
							}
						}
				}
			}`
		}
	}
	else if (type.toLowerCase() == "hjrestaurant" || type.toLowerCase() == "hjworkout" ||
	type.toLowerCase() == "hjspace" || type.toLowerCase() == "hjevent"
	)
	{
		path = "/sitecore/content/hoteljen/content/data" + propertyfolder;
		if (type.toLowerCase() == "hjspace")
		{			
			path = "/sitecore/content/hoteljen/content/data/Spaces" + propertyfolder;
			_type = "HJSpace";
			fragment = `...on HJSpace {
				  property
				  {
						targetItems {
							...on HJHotelTemplate {
								id 
					 title: field(name:"Title")

					  {
						value
					  }
					 description: field(name:"Description")

					  {
						value
					  }
					 hotelcode: field(name:"HotelCode")

					  {
						value
					  }
					 cityalias: field(name:"CityAlias")

					  {
						value
					  }
					 hotelalias: field(name:"HotelAlias")

					  {
						value
					  }
					  tzid: field(name:"Tzid")
					  {
						  value
					  }
							}
						}
				}
			}`
			//fragment = ``;
		}
		else if (type.toLowerCase() == "hjworkout")
		{
			path = "/sitecore/content/hoteljen/content/data/Workout" + propertyfolder;
			_type = "HJWorkout";
			fragment = `...on HJWorkout {
				  openingTime
				  {
						targetItems {
							...on HJOpenCloseTime {
								id 
					  day: field(name:"Day")
					  {
						value
					  }
					  open: field(name:"Open")
					  {
						value
					  }
					  close: field(name:"Close")
					  {
						value
					  }
						description: field(name:"Description")
						{
						  value
						}
							}
						}
				}
				features
				{
					targetItems {
						...on ConstantsHJRoomFeatureTypeTemplate {
							title: field(name:"Title")
			 
							{
							  value
							}
							description: field(name:"Description")
							{
							  value
							}
							moredescription: field(name:"MoreDescription")
							{
							  value
							}
								image : field(name:"Image")
								{
								  jss
								}
						}
					}
				}
				  property
				  {
						targetItems {
							...on HJHotelTemplate {
								id 
					 title: field(name:"Title")

					  {
						value
					  }
					 description: field(name:"Description")

					  {
						value
					  }
					 hotelcode: field(name:"HotelCode")

					  {
						value
					  }
					 cityalias: field(name:"CityAlias")

					  {
						value
					  }
					 hotelalias: field(name:"HotelAlias")

					  {
						value
					  }
					  tzid: field(name:"Tzid")
					  {
						  value
					  }
							}
						}
				}
			}`
		}
		else if (type.toLowerCase() == "hjrestaurant")
		{
			path = "/sitecore/content/hoteljen/content/data/Restaurants" + propertyfolder;
			_type = "HJRestaurant";
			fragment = `...on HJRestaurant {
				  openingTime
				  {
						targetItems {
							...on HJOpenCloseTime {
								id 
					  day: field(name:"Day")
					  {
						value
					  }
					  open: field(name:"Open")
					  {
						value
					  }
					  close: field(name:"Close")
					  {
						value
					  }
						description: field(name:"Description")
						{
						  value
						}
							}
						}
				}
				  property
				  {
						targetItems {
							...on HJHotelTemplate {
								id 
					 title: field(name:"Title")

					  {
						value
					  }
					 description: field(name:"Description")

					  {
						value
					  }
					 hotelcode: field(name:"HotelCode")

					  {
						value
					  }
					 cityalias: field(name:"CityAlias")

					  {
						value
					  }
					 hotelalias: field(name:"HotelAlias")

					  {
						value
					  }
					  tzid: field(name:"Tzid")
					  {
						  value
					  }
							}
						}
				}
			}`
			fragment = `...on HJRestaurant {
				  property
				  {
						targetItems {
							...on HJHotelTemplate {
								id 
					 title: field(name:"Title")

					  {
						value
					  }
					 description: field(name:"Description")

					  {
						value
					  }
					 hotelcode: field(name:"HotelCode")

					  {
						value
					  }
					 cityalias: field(name:"CityAlias")

					  {
						value
					  }
					 hotelalias: field(name:"HotelAlias")

					  {
						value
					  }
					  tzid: field(name:"Tzid")
					  {
						  value
					  }
							}
						}
				}
			}`
		}
		else if (type.toLowerCase() == "hjevent") 
		{
			path = "/sitecore/content/hoteljen/content/data/Events" + propertyfolder;
			_type = "HJEvent";
			fragment = `
				...on HJEvent {
					  property
					  {
							targetItems {
								...on HJHotelTemplate {
									id 
						 title: field(name:"Title")
						  {
							value
						  }
						 description: field(name:"Description")
						  {
							value
						  }
						 hotelcode: field(name:"HotelCode")
						  {
							value
						  }
						 cityalias: field(name:"CityAlias")

						  {
							value
						  }
						 hotelalias: field(name:"HotelAlias")

						  {
							value
						  }
						  tzid: field(name:"Tzid")
						  {
							  value
						  }
								}
							}
					}
				}
			`;
		}
	}
	else if (type.toLowerCase() == "hjhost" || 
	type.toLowerCase() == "hjhostmeet" || 
	type.toLowerCase() == "hjhostcelebrate")
	{
		path = "/sitecore/content/hoteljen/Content/hosts" + propertyfolder;
		if (type.toLowerCase() == "hjhostmeet") 
		{
			_type = "HJHostMeet";
			fragment = `...on HJHostMeet {
				  openingTime
				  {
						targetItems {
							...on HJOpenCloseTime {
								id 
					  day: field(name:"Day")
					  {
						value
					  }
					  open: field(name:"Open")
					  {
						value
					  }
					  close: field(name:"Close")
					  {
						value
					  }
						description: field(name:"Description")
						{
						  value
						}
							}
						}
				}
				features
				{
					targetItems {
						...on ConstantsHJRoomFeatureTypeTemplate {
							title: field(name:"Title")
			 
							{
							  value
							}
							description: field(name:"Description")
							{
							  value
							}
							moredescription: field(name:"MoreDescription")
							{
							  value
							}
								image : field(name:"Image")
								{
								  jss
								}
						}
					}
				}
				  property
				  {
						targetItems {
							...on HJHotelTemplate {
								id 
					 title: field(name:"Title")

					  {
						value
					  }
					 description: field(name:"Description")

					  {
						value
					  }
					 hotelcode: field(name:"HotelCode")

					  {
						value
					  }
					 cityalias: field(name:"CityAlias")

					  {
						value
					  }
					 hotelalias: field(name:"HotelAlias")

					  {
						value
					  }
					  tzid: field(name:"Tzid")
					  {
						  value
					  }
							}
						}
				}
			}`
			fragment = `...on HJHostMeet {
				features
				{
					targetItems {
						...on ConstantsHJRoomFeatureTypeTemplate {
							title: field(name:"Title")
			 
							{
							  value
							}
							description: field(name:"Description")
							{
							  value
							}
							moredescription: field(name:"MoreDescription")
							{
							  value
							}
								image : field(name:"Image")
								{
								  jss
								}
						}
					}
				}
				  property
				  {
						targetItems {
							...on HJHotelTemplate {
								id 
					 title: field(name:"Title")

					  {
						value
					  }
					 description: field(name:"Description")

					  {
						value
					  }
					 hotelcode: field(name:"HotelCode")

					  {
						value
					  }
					 cityalias: field(name:"CityAlias")

					  {
						value
					  }
					 hotelalias: field(name:"HotelAlias")

					  {
						value
					  }
					  tzid: field(name:"Tzid")
					  {
						  value
					  }
							}
						}
				}
			}`
		}
		if (type.toLowerCase() == "hjhostcelebrate") 
		{
			_type = "HJHostCelebrate";			
			fragment = `...on HJHostCelebrate {
				  openingTime
				  {
						targetItems {
							...on HJOpenCloseTime {
								id 
					  day: field(name:"Day")
					  {
						value
					  }
					  open: field(name:"Open")
					  {
						value
					  }
					  close: field(name:"Close")
					  {
						value
					  }
						description: field(name:"Description")
						{
						  value
						}
							}
						}
				}
				features
				{
					targetItems {
						...on ConstantsHJRoomFeatureTypeTemplate {
							title: field(name:"Title")
			 
							{
							  value
							}
							description: field(name:"Description")
							{
							  value
							}
							moredescription: field(name:"MoreDescription")
							{
							  value
							}
								image : field(name:"Image")
								{
								  jss
								}
						}
					}
				}
				  property
				  {
						targetItems {
							...on HJHotelTemplate {
								id 
					 title: field(name:"Title")

					  {
						value
					  }
					 description: field(name:"Description")

					  {
						value
					  }
					 hotelcode: field(name:"HotelCode")

					  {
						value
					  }
					 cityalias: field(name:"CityAlias")

					  {
						value
					  }
					 hotelalias: field(name:"HotelAlias")

					  {
						value
					  }
					  tzid: field(name:"Tzid")
					  {
						  value
					  }
							}
						}
				}
			}`
			fragment = `...on HJHostCelebrate {
				features
				{
					targetItems {
						...on ConstantsHJRoomFeatureTypeTemplate {
							title: field(name:"Title")
			 
							{
							  value
							}
							description: field(name:"Description")
							{
							  value
							}
							moredescription: field(name:"MoreDescription")
							{
							  value
							}
								image : field(name:"Image")
								{
								  jss
								}
						}
					}
				}
				  property
				  {
						targetItems {
							...on HJHotelTemplate {
								id 
					 title: field(name:"Title")

					  {
						value
					  }
					 description: field(name:"Description")

					  {
						value
					  }
					 hotelcode: field(name:"HotelCode")

					  {
						value
					  }
					 cityalias: field(name:"CityAlias")

					  {
						value
					  }
					 hotelalias: field(name:"HotelAlias")

					  {
						value
					  }
					  tzid: field(name:"Tzid")
					  {
						  value
					  }
							}
						}
				}
			}`
			
		}
	}
	else if (type.toLowerCase() == "hjnearby")
	{
		path = "/sitecore/content/hoteljen/Content/nearby" + propertyfolder;
		_type = "HJNearby";			
		fragment = `...on HJNearby {
			  property
			  {
					targetItems {
						...on HJHotelTemplate {
							id 
				 title: field(name:"Title")

				  {
					value
				  }
				 description: field(name:"Description")

				  {
					value
				  }
				 hotelcode: field(name:"HotelCode")

				  {
					value
				  }
				 cityalias: field(name:"CityAlias")

				  {
					value
				  }
				 hotelalias: field(name:"HotelAlias")

				  {
					value
				  }
				  tzid: field(name:"Tzid")
				  {
					  value
				  }
						}
					}
			}
		}`
	}
	else if (type.toLowerCase() == "hjservice")
	{
		path = "/sitecore/content/hoteljen/Content/services" + propertyfolder;
		_type = "HJService";			
		fragment = `...on HJService {
			  property
			  {
					targetItems {
						...on HJHotelTemplate {
							id 
				 title: field(name:"Title")

				  {
					value
				  }
				 description: field(name:"Description")

				  {
					value
				  }
				 hotelcode: field(name:"HotelCode")

				  {
					value
				  }
				 cityalias: field(name:"CityAlias")

				  {
					value
				  }
				 hotelalias: field(name:"HotelAlias")

				  {
					value
				  }
				  tzid: field(name:"Tzid")
				  {
					  value
				  }
						}
					}
			}
			contentCategory
			{
				targetItems {
					...on HJContentCategory {
						title: field(name:"Title")

						{
							value
						}
						description: field(name:"Description")

						{
							value
						}
						value: field(name:"Value")
						{
							value
						}
					}
				}
			}
		}`
	}
	else if (type.toLowerCase() === "hjpropertymenu")
	{
		_type = "HJHeader";		
		pagesize = -1;
		
	}
	else if (type.toLowerCase() === "hjbrandmenu")
	{
		_type = "HJHeader";			
		pagesize = -1;
	}
	else if (type.toLowerCase() === "adhoc")
	{
		_type = "";			
		pagesize = -1;
	}
	else
	{
		path = "/sitecore/content/hoteljen/Content";
		_type = "*";			
	}
	
	
	var paging = "";
	if (pagesize != -1)
	{
		if (lastpage >= 1)
		{
			lastpage += 1;
			paging = 'first: ' + pagesize.toString() + ' after: "' + lastpage.toString() + '"';  
		}
		else
			paging = 'first: ' + pagesize.toString(); 
	}
	
	var propertyvalue = "*";
	if (!(property == null || property === '' || property === '*' || property.toLowerCase() === 'all'))
		propertyvalue = property.toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim();
	
	//orderindex = ',sort:{field:"orderindex",dir:ASC},';
	//var query = gql`query 
	var query = `query
{
  search(fieldsEqual:[
    {
    #name:"_fullpath", value:"/sitecore/content/hoteljen/Content/deals*" },
    name:"_templatename", value:"${_type}" }
	,{name:"property", value:"${propertyvalue}" } 
	##name:"_templatename", value:"hjroom-template" }
    #,{name:"contentcategory", value:"03c0cc9f48cc453ebd55952c91732428" }
    #,{name:"_language", value:"${language}" } 
    #,{name:"disabled", value:"false" },
    #,{name:"__smallcreateddate_tdt", value:"11/6/2019 2:08:59 AM"}		
	${condition}
   ]
    rootItem: "${path}"
   ${paging} 
  ) {
    results {
      items {
        id
        templateName
        name
        language       
				item {
		   id
		   path
			template
			{
				name
			}
			fields(ownFields:true)
			{
			  __typename
			  name
			  value
			  editable
			}
			${fragment}
			children
			{
				id
				template
				{
					name
				}
				fields(ownFields:true)
				{
				  __typename
				  name
				  value
				  editable						  
				}
				children
				{
					id
					template
					{
						name
					}
					fields(ownFields:true)
					{
					  __typename
					  name
					  value
					  editable						  
					}
					children
					{
						id
						template
						{
							name
						}
						fields
						{
						  __typename
						  name
						  value
						  editable						  
						}
						children
						{
							id
							template
							{
								name
							}
							fields
							{
							  __typename
							  name
							  value
							  editable						  
							}
							children
							{
								id
								template
								{
									name
								}
								fields
								{
								  __typename
								  name
								  value
								  editable						  
								}
							}
						}
					}
				}
			}
        }
      }
    }
  }
}
`

	if (
		type.toLowerCase() == "hjdealeat" ||
		type.toLowerCase() == "hjdealspace" || 
		type.toLowerCase() == "hjdealwedding" || 
		type.toLowerCase() == "hjdeal" ||
		type.toLowerCase() == "hjhostcelebrate" ||
		type.toLowerCase() == "hjhostmeet" ||
		type.toLowerCase() == "hjevent" ||
		type.toLowerCase() == "hjservice" ||
		type.toLowerCase() == "hjnearby" ||
		type.toLowerCase() == "hjhost" ||
		type.toLowerCase() == "hjrestaurant" ||
		type.toLowerCase() == "hjspace" ||
		type.toLowerCase() == "hjworkout"
	)
	{
		var query = `query
		{
		  search(fieldsEqual:[
			{
			#name:"_fullpath", value:"/sitecore/content/hoteljen/Content/deals*" },
			name:"_templatename", value:"${_type}" }
			,{name:"property", value:"${propertyvalue}" } 
			##name:"_templatename", value:"hjroom-template" }
			#,{name:"contentcategory", value:"03c0cc9f48cc453ebd55952c91732428" }
			#,{name:"_language", value:"${language}" } 
			#,{name:"disabled", value:"false" },
			#,{name:"__smallcreateddate_tdt", value:"11/6/2019 2:08:59 AM"}		
			${condition}
		   ]
			rootItem: "${path}"
		   ${paging} 
		  ) {
			results {
			  items {
				id
				templateName
				name
				language       
						item {
				   id
				   path
					template
					{
						name
					}
					fields
					{
					  __typename
					  name
					  value
					  editable
					}
					${fragment}
					children
					{
						id
						template
						{
							name
						}
						fields
						{
						  __typename
						  name
						  value
						  editable						  
						}
						children
						{
							id
							template
							{
								name
							}
							fields
							{
							  __typename
							  name
							  value
							  editable						  
							}
						}
					}
				}
			  }
			}
		  }
		}
		`
	}
	if (type.toLowerCase() === "hjpropertymenu" || type.toLowerCase() === "hjbrandmenu" || type.toLowerCase() === "adhoc")
	{
		//var query = gql`query 
		var query = `query
	{
	  search(fieldsEqual:[
		{
	   ]
		rootItem: "${path}"
	  ) {
		results {
		  items {
			id
			templateName
			name
			language       
					item {
			   id
			   path
				template
				{
					name
				}
				fields
				{
				  __typename
				  name
				  value
				  editable
				}
				${fragment}
				children
				{
					id
					template
					{
						name
					}
					fields
					{
					  __typename
					  name
					  value
					  editable						  
					}
					children
					{
						id
						template
						{
							name
						}
						fields
						{
						  __typename
						  name
						  value
						  editable						  
						}
					}
				}
			}
		  }
		}
	  }
	}
	`
	}
  var _searchMoreContentByType = function(query, graphqlresults, type, vm, action, pagesize, lastpage, property, propertycode, isfeatureimageonly, travellertype, language, condition, event, context, searchresults, targetobject) {
	  const client = new ApolloClient({
		  uri: vm.$jss.hjconfig().sitecoreApiHost + '/api/' + vm.$jss.hjconfig().jssAppName + '?sc_lang='+ vm.$jss.routeData().itemLanguage +'&sc_apikey=' + vm.$jss.hjconfig().sitecoreSCApiKey,
		  defaultOptions: {
		  //fetchPolicy: 'no-cache',
		  fetchPolicy: 'cache-first',
		},
	  });
	  
		var auth = {
				headers: {} 
			}
		appendAuthHeaders(auth.headers, vm.$jss.hjconfig().hmac_appsecret)
	  //console.log(client);
		axios.post('/api/apollo', 
		{data:query, lang:vm.$jss.routeData().itemLanguage}, auth).then(result => { 
			getData(result, vm, graphqlresults, null, type, action, true, '', null, property, isfeatureimageonly, travellertype, event, context, true, true, searchresults, targetobject);
		})
		.catch( err => { console.log(err) } )
	
		/*
		client.query({
			query: gql(query),
		})
		.then((data) => {
			console.log(data);
			getData(data, vm, graphqlresults, null, type, action, true, '', null, property, isfeatureimageonly, travellertype, event, context, true, true, searchresults, targetobject);
		})
		.catch((error) => console.error(error));
		*/
	
	}
	/*
	if (vm.$jss.hjconfig() == null)
	{
		$.getJSON( "/api/gethjconfig", function( data ) {
			vm.$jss.store.setHjconfig(data);
			_searchMoreContentByType(query, graphqlresults, type, vm, action, pagesize, lastpage, property, isfeatureimageonly, travellertype, language, condition, event, context, searchresults, targetobject);
		});			
	}
	else
	{*/
		_searchMoreContentByType(query, graphqlresults, type, vm, action, pagesize, lastpage, property, propertycode, isfeatureimageonly, travellertype, language, condition, event, context, searchresults, targetobject);
	/*}*/	
}

export function updatePriceString(price,currency){
	
	var currencies = {
		"USD": {
			"symbol": "$",
			"name": "US Dollar",
			"symbol_native": "$","code": "USD",
			"name_plural": "US dollars"
		},
		"CAD": {
			"symbol": "CA$",
			"name": "Canadian Dollar",
			"symbol_native": "$","code": "CAD",
			"name_plural": "Canadian dollars"
		},
		"EUR": {
			"symbol": "€",
			"name": "Euro",
			"symbol_native": "€","code": "EUR",
			"name_plural": "euros"
		},
		"AED": {
			"symbol": "AED",
			"name": "United Arab Emirates Dirham",
			"symbol_native": "د.إ.‏","code": "AED",
			"name_plural": "UAE dirhams"
		},
		"AFN": {
			"symbol": "Af",
			"name": "Afghan Afghani",
			"symbol_native": "؋",
			"code": "AFN",
			"name_plural": "Afghan Afghanis"
		},
		"ALL": {
			"symbol": "ALL",
			"name": "Albanian Lek",
			"symbol_native": "Lek",			
			"code": "ALL",
			"name_plural": "Albanian lekë"
		},
		"AMD": {
			"symbol": "AMD",
			"name": "Armenian Dram",
			"symbol_native": "դր.",
			"code": "AMD",
			"name_plural": "Armenian drams"
		},
		"ARS": {
			"symbol": "AR$",
			"name": "Argentine Peso",
			"symbol_native": "$",
			"code": "ARS",
			"name_plural": "Argentine pesos"
		},
		"AUD": {
			"symbol": "AU$",
			"name": "Australian Dollar",
			"symbol_native": "$",
			"code": "AUD",
			"name_plural": "Australian dollars"
		},
		"AZN": {
			"symbol": "man.",
			"name": "Azerbaijani Manat",
			"symbol_native": "ман.",
			"code": "AZN",
			"name_plural": "Azerbaijani manats"
		},
		"BAM": {
			"symbol": "KM",
			"name": "Bosnia-Herzegovina Convertible Mark",
			"symbol_native": "KM",
			"code": "BAM",
			"name_plural": "Bosnia-Herzegovina convertible marks"
		},
		"BDT": {
			"symbol": "Tk",
			"name": "Bangladeshi Taka",
			"symbol_native": "৳",
			"code": "BDT",
			"name_plural": "Bangladeshi takas"
		},
		"BGN": {
			"symbol": "BGN",
			"name": "Bulgarian Lev",
			"symbol_native": "лв.",
			"code": "BGN",
			"name_plural": "Bulgarian leva"
		},
		"BHD": {
			"symbol": "BD",
			"name": "Bahraini Dinar",
			"symbol_native": "د.ب.‏",
			"code": "BHD",
			"name_plural": "Bahraini dinars"
		},
		"BIF": {
			"symbol": "FBu",
			"name": "Burundian Franc",
			"symbol_native": "FBu",
			"code": "BIF",
			"name_plural": "Burundian francs"
		},
		"BND": {
			"symbol": "BN$",
			"name": "Brunei Dollar",
			"symbol_native": "$",
			"code": "BND",
			"name_plural": "Brunei dollars"
		},
		"BOB": {
			"symbol": "Bs",
			"name": "Bolivian Boliviano",
			"symbol_native": "Bs",
			"code": "BOB",
			"name_plural": "Bolivian bolivianos"
		},
		"BRL": {
			"symbol": "R$",
			"name": "Brazilian Real",
			"symbol_native": "R$",
			"code": "BRL",
			"name_plural": "Brazilian reals"
		},
		"BWP": {
			"symbol": "BWP",
			"name": "Botswanan Pula",
			"symbol_native": "P",
			"code": "BWP",
			"name_plural": "Botswanan pulas"
		},
		"BYN": {
			"symbol": "Br",
			"name": "Belarusian Ruble",
			"symbol_native": "руб.",
			"code": "BYN",
			"name_plural": "Belarusian rubles"
		},
		"BZD": {
			"symbol": "BZ$",
			"name": "Belize Dollar",
			"symbol_native": "$",
			"code": "BZD",
			"name_plural": "Belize dollars"
		},
		"CDF": {
			"symbol": "CDF",
			"name": "Congolese Franc",
			"symbol_native": "FrCD",
			"code": "CDF",
			"name_plural": "Congolese francs"
		},
		"CHF": {
			"symbol": "CHF",
			"name": "Swiss Franc",
			"symbol_native": "CHF",
			"code": "CHF",
			"name_plural": "Swiss francs"
		},
		"CLP": {
			"symbol": "CL$",
			"name": "Chilean Peso",
			"symbol_native": "$",
			"code": "CLP",
			"name_plural": "Chilean pesos"
		},
		"CNY": {
			"symbol": "CN¥",
			"name": "Chinese Yuan",
			"symbol_native": "¥",
			"code": "CNY",
			"name_plural": "Chinese yuan"
		},
		"COP": {
			"symbol": "CO$",
			"name": "Colombian Peso",
			"symbol_native": "$",
			"code": "COP",
			"name_plural": "Colombian pesos"
		},
		"CRC": {
			"symbol": "₡",
			"name": "Costa Rican Colón",
			"symbol_native": "₡",
			"code": "CRC",
			"name_plural": "Costa Rican colóns"
		},
		"CVE": {
			"symbol": "CV$",
			"name": "Cape Verdean Escudo",
			"symbol_native": "$",
			"code": "CVE",
			"name_plural": "Cape Verdean escudos"
		},
		"CZK": {
			"symbol": "Kč",
			"name": "Czech Republic Koruna",
			"symbol_native": "Kč",
			"code": "CZK",
			"name_plural": "Czech Republic korunas"
		},
		"DJF": {
			"symbol": "Fdj",
			"name": "Djiboutian Franc",
			"symbol_native": "Fdj",
			"code": "DJF",
			"name_plural": "Djiboutian francs"
		},
		"DKK": {
			"symbol": "Dkr",
			"name": "Danish Krone",
			"symbol_native": "kr",
			"code": "DKK",
			"name_plural": "Danish kroner"
		},
		"DOP": {
			"symbol": "RD$",
			"name": "Dominican Peso",
			"symbol_native": "$",
			"code": "DOP",
			"name_plural": "Dominican pesos"
		},
		"DZD": {
			"symbol": "DA",
			"name": "Algerian Dinar",
			"symbol_native": "د.ج.‏",
			
			
			"code": "DZD",
			"name_plural": "Algerian dinars"
		},
		"EEK": {
			"symbol": "Ekr",
			"name": "Estonian Kroon",
			"symbol_native": "kr",
			
			
			"code": "EEK",
			"name_plural": "Estonian kroons"
		},
		"EGP": {
			"symbol": "EGP",
			"name": "Egyptian Pound",
			"symbol_native": "ج.م.‏",
			
			
			"code": "EGP",
			"name_plural": "Egyptian pounds"
		},
		"ERN": {
			"symbol": "Nfk",
			"name": "Eritrean Nakfa",
			"symbol_native": "Nfk",
			
			
			"code": "ERN",
			"name_plural": "Eritrean nakfas"
		},
		"ETB": {
			"symbol": "Br",
			"name": "Ethiopian Birr",
			"symbol_native": "Br",
			
			
			"code": "ETB",
			"name_plural": "Ethiopian birrs"
		},
		"FJD": {
			"symbol": "£",
			"name": "Fijian dollar",
			"symbol_native": "$",
			"code": "FJD",
			"name_plural": "Fijian dollar"
		},
		"GBP": {
			"symbol": "£",
			"name": "British Pound Sterling",
			"symbol_native": "£",
			"code": "GBP",
			"name_plural": "British pounds sterling"
		},
		"GEL": {
			"symbol": "GEL",
			"name": "Georgian Lari",
			"symbol_native": "GEL",
			
			
			"code": "GEL",
			"name_plural": "Georgian laris"
		},
		"GHS": {
			"symbol": "GH₵",
			"name": "Ghanaian Cedi",
			"symbol_native": "GH₵",
			
			
			"code": "GHS",
			"name_plural": "Ghanaian cedis"
		},
		"GNF": {
			"symbol": "FG",
			"name": "Guinean Franc",
			"symbol_native": "FG",
			
			
			"code": "GNF",
			"name_plural": "Guinean francs"
		},
		"GTQ": {
			"symbol": "GTQ",
			"name": "Guatemalan Quetzal",
			"symbol_native": "Q",
			
			
			"code": "GTQ",
			"name_plural": "Guatemalan quetzals"
		},
		"HKD": {
			"symbol": "HK$",
			"name": "Hong Kong Dollar",
			"symbol_native": "$",
			
			
			"code": "HKD",
			"name_plural": "Hong Kong dollars"
		},
		"HNL": {
			"symbol": "HNL",
			"name": "Honduran Lempira",
			"symbol_native": "L",
			
			
			"code": "HNL",
			"name_plural": "Honduran lempiras"
		},
		"HRK": {
			"symbol": "kn",
			"name": "Croatian Kuna",
			"symbol_native": "kn",
			
			
			"code": "HRK",
			"name_plural": "Croatian kunas"
		},
		"HUF": {
			"symbol": "Ft",
			"name": "Hungarian Forint",
			"symbol_native": "Ft",
			
			
			"code": "HUF",
			"name_plural": "Hungarian forints"
		},
		"IDR": {
			"symbol": "Rp",
			"name": "Indonesian Rupiah",
			"symbol_native": "Rp",
			
			
			"code": "IDR",
			"name_plural": "Indonesian rupiahs"
		},
		"ILS": {
			"symbol": "₪",
			"name": "Israeli New Sheqel",
			"symbol_native": "₪",
			
			
			"code": "ILS",
			"name_plural": "Israeli new sheqels"
		},
		"INR": {
			"symbol": "Rs",
			"name": "Indian Rupee",
			"symbol_native": "টকা",
			
			
			"code": "INR",
			"name_plural": "Indian rupees"
		},
		"IQD": {
			"symbol": "IQD",
			"name": "Iraqi Dinar",
			"symbol_native": "د.ع.‏",
			
			
			"code": "IQD",
			"name_plural": "Iraqi dinars"
		},
		"IRR": {
			"symbol": "IRR",
			"name": "Iranian Rial",
			"symbol_native": "﷼",
			
			
			"code": "IRR",
			"name_plural": "Iranian rials"
		},
		"ISK": {
			"symbol": "Ikr",
			"name": "Icelandic Króna",
			"symbol_native": "kr",
			
			
			"code": "ISK",
			"name_plural": "Icelandic krónur"
		},
		"JMD": {
			"symbol": "J$",
			"name": "Jamaican Dollar",
			"symbol_native": "$",
			
			
			"code": "JMD",
			"name_plural": "Jamaican dollars"
		},
		"JOD": {
			"symbol": "JD",
			"name": "Jordanian Dinar",
			"symbol_native": "د.أ.‏",
			
			
			"code": "JOD",
			"name_plural": "Jordanian dinars"
		},
		"JPY": {
			"symbol": "¥",
			"name": "Japanese Yen",
			"symbol_native": "￥",
			
			
			"code": "JPY",
			"name_plural": "Japanese yen"
		},
		"KES": {
			"symbol": "Ksh",
			"name": "Kenyan Shilling",
			"symbol_native": "Ksh",
			
			
			"code": "KES",
			"name_plural": "Kenyan shillings"
		},
		"KHR": {
			"symbol": "KHR",
			"name": "Cambodian Riel",
			"symbol_native": "៛",
			
			
			"code": "KHR",
			"name_plural": "Cambodian riels"
		},
		"KMF": {
			"symbol": "CF",
			"name": "Comorian Franc",
			"symbol_native": "FC",
			
			
			"code": "KMF",
			"name_plural": "Comorian francs"
		},
		"KRW": {
			"symbol": "₩",
			"name": "South Korean Won",
			"symbol_native": "₩",
			
			
			"code": "KRW",
			"name_plural": "South Korean won"
		},
		"KWD": {
			"symbol": "KD",
			"name": "Kuwaiti Dinar",
			"symbol_native": "د.ك.‏",
			
			
			"code": "KWD",
			"name_plural": "Kuwaiti dinars"
		},
		"KZT": {
			"symbol": "KZT",
			"name": "Kazakhstani Tenge",
			"symbol_native": "тңг.",
			
			
			"code": "KZT",
			"name_plural": "Kazakhstani tenges"
		},
		"LBP": {
			"symbol": "LB£",
			"name": "Lebanese Pound",
			"symbol_native": "ل.ل.‏",
			
			
			"code": "LBP",
			"name_plural": "Lebanese pounds"
		},
		"LKR": {
			"symbol": "SLRs",
			"name": "Sri Lankan Rupee",
			"symbol_native": "Rs",
			"code": "LKR",
			"name_plural": "Sri Lankan rupees"
		},
		"LTL": {
			"symbol": "Lt",
			"name": "Lithuanian Litas",
			"symbol_native": "Lt",
			
			
			"code": "LTL",
			"name_plural": "Lithuanian litai"
		},
		"LVL": {
			"symbol": "Ls",
			"name": "Latvian Lats",
			"symbol_native": "Ls",
			
			
			"code": "LVL",
			"name_plural": "Latvian lati"
		},
		"LYD": {
			"symbol": "LD",
			"name": "Libyan Dinar",
			"symbol_native": "د.ل.‏",
			
			
			"code": "LYD",
			"name_plural": "Libyan dinars"
		},
		"MAD": {
			"symbol": "MAD",
			"name": "Moroccan Dirham",
			"symbol_native": "د.م.‏",
			
			
			"code": "MAD",
			"name_plural": "Moroccan dirhams"
		},
		"MDL": {
			"symbol": "MDL",
			"name": "Moldovan Leu",
			"symbol_native": "MDL",
			
			
			"code": "MDL",
			"name_plural": "Moldovan lei"
		},
		"MGA": {
			"symbol": "MGA",
			"name": "Malagasy Ariary",
			"symbol_native": "MGA",
			
			
			"code": "MGA",
			"name_plural": "Malagasy Ariaries"
		},
		"MKD": {
			"symbol": "MKD",
			"name": "Macedonian Denar",
			"symbol_native": "MKD",
			
			
			"code": "MKD",
			"name_plural": "Macedonian denari"
		},
		"MMK": {
			"symbol": "MMK",
			"name": "Myanma Kyat",
			"symbol_native": "K",
			"code": "MMK",
			"name_plural": "Myanma kyats"
		},
		"MNT": {
			"symbol": "MNT",
			"name": "Mongolian Tunic",
			"symbol_native": "‎₮",
			"code": "MNT",
			"name_plural": "Mongolian Tunic"
		},
		"MOP": {
			"symbol": "MOP$",
			"name": "Macanese Pataca",
			"symbol_native": "$",
			"code": "MOP",
			"name_plural": "Macanese patacas"
		},
		"MUR": {
			"symbol": "MURs",
			"name": "Mauritian Rupee",
			"symbol_native": "MURs",
			"code": "MUR",
			"name_plural": "Mauritian rupees"
		},
		"MXN": {
			"symbol": "MX$",
			"name": "Mexican Peso",
			"symbol_native": "$",
			"code": "MXN",
			"name_plural": "Mexican pesos"
		},
		"MYR": {
			"symbol": "RM",
			"name": "Malaysian Ringgit",
			"symbol_native": "RM",
			
			
			"code": "MYR",
			"name_plural": "Malaysian ringgits"
		},
		"MZN": {
			"symbol": "MTn",
			"name": "Mozambican Metical",
			"symbol_native": "MTn",
			
			
			"code": "MZN",
			"name_plural": "Mozambican meticals"
		},
		"NAD": {
			"symbol": "N$",
			"name": "Namibian Dollar",
			"symbol_native": "N$",
			"code": "NAD",
			"name_plural": "Namibian dollars"
		},
		"NGN": {
			"symbol": "₦",
			"name": "Nigerian Naira",
			"symbol_native": "₦",
			
			
			"code": "NGN",
			"name_plural": "Nigerian nairas"
		},
		"NIO": {
			"symbol": "C$",
			"name": "Nicaraguan Córdoba",
			"symbol_native": "$",
			
			"code": "NIO",
			"name_plural": "Nicaraguan córdobas"
		},
		"NOK": {
			"symbol": "Nkr",
			"name": "Norwegian Krone",
			"symbol_native": "kr",
			
			
			"code": "NOK",
			"name_plural": "Norwegian kroner"
		},
		"NPR": {
			"symbol": "NPRs",
			"name": "Nepalese Rupee",
			"symbol_native": "नेरू",
			
			
			"code": "NPR",
			"name_plural": "Nepalese rupees"
		},
		"NZD": {
			"symbol": "NZ$",
			"name": "New Zealand Dollar",
			"symbol_native": "$",
			"code": "NZD",
			"name_plural": "New Zealand dollars"
		},
		"NTD": {
			"symbol": "NT$",
			"name": "New Taiwan Dollar",
			"symbol_native": "$",
			"code": "NZD",
			"name_plural": "New Taiwan dollars"
		},
		"OMR": {
			"symbol": "OMR",
			"name": "Omani Rial",
			"symbol_native": "ر.ع.‏",
			"code": "OMR",
			"name_plural": "Omani rials"
		},
		"PAB": {
			"symbol": "B/.",
			"name": "Panamanian Balboa",
			"symbol_native": "B/.",
			
			
			"code": "PAB",
			"name_plural": "Panamanian balboas"
		},
		"PEN": {
			"symbol": "S/.",
			"name": "Peruvian Nuevo Sol",
			"symbol_native": "S/.",
			
			
			"code": "PEN",
			"name_plural": "Peruvian nuevos soles"
		},
		"PHP": {
			"symbol": "₱",
			"name": "Philippine Peso",
			"symbol_native": "₱",
			
			
			"code": "PHP",
			"name_plural": "Philippine pesos"
		},
		"PKR": {
			"symbol": "PKRs",
			"name": "Pakistani Rupee",
			"symbol_native": "₨",
			
			
			"code": "PKR",
			"name_plural": "Pakistani rupees"
		},
		"PLN": {
			"symbol": "zł",
			"name": "Polish Zloty",
			"symbol_native": "zł",
			
			
			"code": "PLN",
			"name_plural": "Polish zlotys"
		},
		"PYG": {
			"symbol": "₲",
			"name": "Paraguayan Guarani",
			"symbol_native": "₲",
			
			
			"code": "PYG",
			"name_plural": "Paraguayan guaranis"
		},
		"QAR": {
			"symbol": "QR",
			"name": "Qatari Rial",
			"symbol_native": "ر.ق.‏",
			
			
			"code": "QAR",
			"name_plural": "Qatari rials"
		},
		"RMB": {
			"symbol": "CN¥",
			"name": "Chinese Yuan",
			"symbol_native": "¥",
			"code": "CNY",
			"name_plural": "Chinese yuan"
		},
		"RON": {
			"symbol": "RON",
			"name": "Romanian Leu",
			"symbol_native": "RON",
			
			
			"code": "RON",
			"name_plural": "Romanian lei"
		},
		"RSD": {
			"symbol": "din.",
			"name": "Serbian Dinar",
			"symbol_native": "дин.",
			
			
			"code": "RSD",
			"name_plural": "Serbian dinars"
		},
		"RUB": {
			"symbol": "RUB",
			"name": "Russian Ruble",
			"symbol_native": "₽.",
			
			
			"code": "RUB",
			"name_plural": "Russian rubles"
		},
		"RWF": {
			"symbol": "RWF",
			"name": "Rwandan Franc",
			"symbol_native": "FR",
			
			
			"code": "RWF",
			"name_plural": "Rwandan francs"
		},
		"SAR": {
			"symbol": "SR",
			"name": "Saudi Riyal",
			"symbol_native": "ر.س.‏",
			"code": "SAR",
			"name_plural": "Saudi riyals"
		},
		"SCR": {
			"symbol": "SR",
			"name": "Seychellois rupee",
			"symbol_native": "SRe",
			"code": "SCR",
			"name_plural": "Seychellois rupee"
		},
		"SDG": {
			"symbol": "SDG",
			"name": "Sudanese Pound",
			"symbol_native": "SDG",
			"code": "SDG",
			"name_plural": "Sudanese pounds"
		},
		"SEK": {
			"symbol": "Skr",
			"name": "Swedish Krona",
			"symbol_native": "kr",
			"code": "SEK",
			"name_plural": "Swedish kronor"
		},
		"SGD": {
			"symbol": "S$",
			"name": "Singapore Dollar",
			"symbol_native": "$",
			"code": "SGD",
			"name_plural": "Singapore dollars"
		},
		"SOS": {
			"symbol": "Ssh",
			"name": "Somali Shilling",
			"symbol_native": "Ssh",
			
			
			"code": "SOS",
			"name_plural": "Somali shillings"
		},
		"SYP": {
			"symbol": "SY£",
			"name": "Syrian Pound",
			"symbol_native": "ل.س.‏",
			
			
			"code": "SYP",
			"name_plural": "Syrian pounds"
		},
		"THB": {
			"symbol": "฿",
			"name": "Thai Baht",
			"symbol_native": "฿",
			
			
			"code": "THB",
			"name_plural": "Thai baht"
		},
		"TND": {
			"symbol": "DT",
			"name": "Tunisian Dinar",
			"symbol_native": "د.ت.‏",
			
			
			"code": "TND",
			"name_plural": "Tunisian dinars"
		},
		"TOP": {
			"symbol": "T$",
			"name": "Tongan Paʻanga",
			"symbol_native": "$",
			"code": "TOP",
			"name_plural": "Tongan paʻanga"
		},
		"TRY": {
			"symbol": "TL",
			"name": "Turkish Lira",
			"symbol_native": "TL",
			
			
			"code": "TRY",
			"name_plural": "Turkish Lira"
		},
		"TTD": {
			"symbol": "TT$",
			"name": "Trinidad and Tobago Dollar",
			"symbol_native": "$",
			"code": "TTD",
			"name_plural": "Trinidad and Tobago dollars"
		},
		"TWD": {
			"symbol": "NT$",
			"name": "New Taiwan Dollar",
			"symbol_native": "$",
			
			
			"code": "TWD",
			"name_plural": "New Taiwan dollars"
		},
		"TZS": {
			"symbol": "TSh",
			"name": "Tanzanian Shilling",
			"symbol_native": "TSh",
			
			
			"code": "TZS",
			"name_plural": "Tanzanian shillings"
		},
		"UAH": {
			"symbol": "₴",
			"name": "Ukrainian Hryvnia",
			"symbol_native": "₴",
			"code": "UAH",
			"name_plural": "Ukrainian hryvnias"
		},
		"UGX": {
			"symbol": "USh",
			"name": "Ugandan Shilling",
			"symbol_native": "USh",
			"code": "UGX",
			"name_plural": "Ugandan shillings"
		},
		"UYU": {
			"symbol": "$U",
			"name": "Uruguayan Peso",
			"symbol_native": "$",
			"code": "UYU",
			"name_plural": "Uruguayan pesos"
		},
		"UZS": {
			"symbol": "UZS",
			"name": "Uzbekistan Som",
			"symbol_native": "UZS",
			"code": "UZS",
			"name_plural": "Uzbekistan som"
		},
		"VEF": {
			"symbol": "Bs.F.",
			"name": "Venezuelan Bolívar",
			"symbol_native": "Bs.F.",
			"code": "VEF",
			"name_plural": "Venezuelan bolívars"
		},
		"VND": {
			"symbol": "₫",
			"name": "Vietnamese Dong",
			"symbol_native": "₫",
			"code": "VND",
			"name_plural": "Vietnamese dong"
		},
		"XAF": {
			"symbol": "FCFA",
			"name": "CFA Franc BEAC",
			"symbol_native": "FCFA",
			"code": "XAF",
			"name_plural": "CFA francs BEAC"
		},
		"XOF": {
			"symbol": "CFA",
			"name": "CFA Franc BCEAO",
			"symbol_native": "CFA",
			"code": "XOF",
			"name_plural": "CFA francs BCEAO"
		},
		"YER": {
			"symbol": "YR",
			"name": "Yemeni Rial",
			"symbol_native": "ر.ي.‏",
			"code": "YER",
			"name_plural": "Yemeni rials"
		},
		"ZAR": {
			"symbol": "R",
			"name": "South African Rand",
			"symbol_native": "R",
			"code": "ZAR",
			"name_plural": "South African rand"
		},
		"ZMK": {
			"symbol": "ZK",
			"name": "Zambian Kwacha",
			"symbol_native": "ZK",
			"code": "ZMK",
			"name_plural": "Zambian kwachas"
		},
		"ZWL": {
			"symbol": "ZWL$",
			"name": "Zimbabwean Dollar",
			"symbol_native": "$",
			"code": "ZWL",
			"name_plural": "Zimbabwean Dollar"
		}
	}
	var cur_currency = localStorage.getItem('curcurrency')
	var symbol = null;

	if(cur_currency) cur_currency
	else cur_currency = 'USD'

	if(currencies[cur_currency].symbol_native === cur_currency) symbol = ""
	else symbol = currencies[cur_currency].symbol_native

	return symbol + price.toLocaleString();
}

export function setCurrencyByGeo(geolocation, vm)
{
	if (vm.$jss.hjgeocookie() != null && vm.$jss.hjgeocookie() != '')
	{
		//console.log(vm.$jss.constants());
		//var countries = getContentByType(vm.$jss.constants(), "hjcountry", this, false, false, 'other', false);
		//var currencies = getContentByType(vm.$jss.constants(), "hjcurrency", this, false, false, 'other', false);
		var countries = getContentByType(vm.$jss.constants(), "hjcountry", vm, false, false, 'other', false, -1);
		var currencies = getContentByType(vm.$jss.constants(), "hjcurrency", vm, false, false, 'other', false, -1);
		
		if (countries.length > 0)
		{
			for (var i=0; i < countries.length; i++)
			{
				if (countries[i].countrycode.value.toLowerCase() === vm.$jss.hjgeocookie().toLowerCase())
				{
					if (currencies.length > 0)
					{
						for (var j=0; j < currencies.length; j++)
						{
							if (currencies[j].id.toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim() === countries[i].currency.value.toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim())
							{
								console.log(localStorage.getItem('curcurrency'))
								if(localStorage.getItem('curcurrency') && localStorage.getItem('curcurrency') != null && localStorage.getItem('curcurrency') != ''){
									vm.$jss.store.setCurrency(localStorage.getItem('curcurrency'))
									//currentCurrency = cur_currency
								} 
								else {
									//currentCurrency = currencies[j].code.value;
									localStorage.setItem('curcurrency',currencies[j].code.value);
									//cur_currency = currencies[j].code.value;
									vm.$jss.store.setCurrency(currencies[j].code.value);
								}

								break;
							}
						}
					}
				}
			}
		}
	}
}


export function graphqlToObject_allsociety(type, item, vm, templatefilter, skipparent, mergeconstants, objArray, buildobjectonly) {
	
  if (!Object.keys) {
    Object.keys = (function() {
      'use strict';
      var hasOwnProperty = Object.prototype.hasOwnProperty,
        hasDontEnumBug = !{ toString: null }.propertyIsEnumerable('toString'),
        dontEnums = [
          'toString',
          'toLocaleString',
          'valueOf',
          'hasOwnProperty',
          'isPrototypeOf',
          'propertyIsEnumerable',
          'constructor',
        ],
        dontEnumsLength = dontEnums.length;

      return function(obj) {
        if (typeof obj !== 'function' && (typeof obj !== 'object' || obj === null)) {
          throw new TypeError('Object.keys called on non-object');
        }

        var result = [],
          prop,
          i;

        for (prop in obj) {
          if (hasOwnProperty.call(obj, prop)) {
            result.push(prop);
          }
        }

        if (hasDontEnumBug) {
          for (i = 0; i < dontEnumsLength; i++) {
            if (hasOwnProperty.call(obj, dontEnums[i])) {
              result.push(dontEnums[i]);
            }
          }
        }
        return result;
      };
    })();
  }

	//var ssrdata = getObject(vm.$jss.routeData(), vm, null, null, null, "hjheader");

  var constants = null;
  var hotels = null;
  var amenities = null;
  var travellertypes = null;
  var contentcategories = null;
  var features = null;
  var eventtypes = null;
  
  
  if (objArray == null || objArray == undefined)
  {
	  objArray = [];
  }

  var getgraphqlobject = function(item, type, vm, templatefilter, changelowercase, constants, hotels, amenities, travellertypes, contentcategories, features, objArray, buildobjectonly) 
  {
		var _ar = [];
		var o = {};
		var jss = {};
		var _arr = [];
		var data = {};
		if (changelowercase == undefined)
			changelowercase = true;
		var o = {};
		if (item.template != null && typeof item.template != 'undefined') {
			o['templatename'] = item.template.name.toLowerCase();
		}
        o['id'] = item.id;
		if (item.fields && typeof item.fields != 'undefined') {
			for (var j = 0; j < item.fields.length; j++) {
			  if (item.fields[j].__typename.toLowerCase() == 'imagefield') {
				jss = {};
				jss['__typename'] = item.fields[j].__typename.toLowerCase();
				jss['value'] = item.fields[j].value;
				if (
				  item.fields[j].editable != null &&
				  item.fields[j].editable != ''
				) {
				  var regexp = /(src=\")[\""A-Za-z0-9.;!#$%&'*+-\/=?^_`{|}~\p{L}\p{Nd}]{1,}(\")/g;
				  var match = item.fields[j].editable.match(regexp);
				  if (match != null && match.length > 0)
				  {
					  jss['src'] = match[0].substring(5, match[0].length - 1);
					  regexp = /(alt=\")[\""A-Za-z0-9.;!#$%&'*+-\/=?^_`{|}~\p{L}\p{Nd}]{0,}(\")/g;
					  match = item.fields[j].editable.match(regexp);
					  if (match != null && match.length > 0)
						jss['alt'] = match[0].substring(5, match[0].length - 1);
					  regexp = /(width=\")[\""A-Za-z0-9.;!#$%&'*+-\/=?^_`{|}~\p{L}\p{Nd}]{0,}(\")/g;
					  match = item.fields[j].editable.match(regexp);
					  if (match != null && match.length > 0)
						jss['width'] = match[0].substring(7, match[0].length - 1);
					  regexp = /(height=\")[\""A-Za-z0-9.;!#$%&'*+-\/=?^_`{|}~\p{L}\p{Nd}]{0,}(\")/g;
					  match = item.fields[j].editable.match(regexp);
					  if (match != null && match.length > 0)
						jss['height'] = match[0].substring(8, match[0].length - 1);
					  jss['editable'] = item.fields[j].editable;
				  }
				}
				else
				{
				  jss['src'] = '';
				  jss['width'] = '';
				  jss['height'] = '';
				  jss['editable'] = '';
				}
				if (changelowercase)
					o[item.fields[j].name.toLowerCase()] = jss;
				else
					o[item.fields[j].name] = jss;
			  } else if (item.fields[j].__typename.toLowerCase() == 'generallink' ||
				item.fields[j].__typename.toLowerCase() == 'linkfield'
			  ) {
				jss = {};
				jss['__typename'] = item.fields[j].__typename.toLowerCase();
				if (
				  item.fields[j].editable != null &&
				  item.fields[j].editable != ''
				) {
				  if (item.fields[j].value.indexOf("internal") != -1 || item.fields[j].value.indexOf("external") != -1)
				  {
				  var regexp = /(href=\")[\""A-Za-z0-9.;:!#$%&'*+-\/=?^_`{|}~\p{L}\p{Nd}]{1,}(\")/g;
				  var match = item.fields[j].editable.match(regexp);
				  if (match != null && match.length > 0)
				  {
					jss['value'] = match[0].substring(6, match[0].length - 1);
				  }
				  else
				  {
					  jss['value'] = '';
				  }					  
				  if (changelowercase)
					o[item.fields[j].name.toLowerCase()] = jss;
				  else
					o[item.fields[j].name] = jss;
				  }
				  else
				  {
				  var regexp = /(url=\")[\""A-Za-z0-9.;:!#$%&'*+-\/=?^_`{|}~\p{L}\p{Nd}]{1,}(\")/g;
				  var match = item.fields[j].editable.match(regexp);
				  if (match != null && match.length > 0)
				  {
					jss['value'] = match[0].substring(5, match[0].length - 1);
				  }
				  else
				  {
					  jss['value'] = '';
				  }
				  if (changelowercase)
					o[item.fields[j].name.toLowerCase()] = jss;
				  else
					o[item.fields[j].name] = jss;
				  }
				}
				else
				{
					jss['value'] = item.fields[j].value;
				  if (changelowercase)
					o[item.fields[j].name.toLowerCase()] = jss;
				  else
					o[item.fields[j].name] = jss;
				}
			  } else {
				jss = {};
				jss['__typename'] = item.fields[j].__typename.toLowerCase();
				jss['value'] = item.fields[j].value;
			  if (changelowercase)
				o[item.fields[j].name.toLowerCase()] = jss;
			  else
				o[item.fields[j].name] = jss;
			  }
			}
		}
        var keys = Object.keys(item);
        for (var j = 0; j < keys.length; j++) {
          if ((keys[j] != 'fields' && keys[j].substring(0,2) != '__') ||
		  keys[j] == '__typename') {
            o[keys[j]] = item[keys[j]];
			if (item[keys[j]] && item[keys[j]].targetItems && item[keys[j]].targetItems.length > 0) {
				ar_ = [];
				for (var k = 0; k < item[keys[j]].targetItems.length; k++) {
					jss = {};
					var _keys = Object.keys(item[keys[j]].targetItems[k]);
					for (var l = 0; l < _keys.length; l++) {
						if (item[keys[j]].targetItems[k][_keys[l]] && item[keys[j]].targetItems[k][_keys[l]].value)
							jss[_keys[l]] = item[keys[j]].targetItems[k][_keys[l]].value;
						else
						{
							if (_keys[l].toLowerCase() === 'id')
								jss[_keys[l]] = item[keys[j]].targetItems[k][_keys[l]].replace("&#8208;", "").replace("-", "").replace("{", "").replace("}", "").trim();
							else if (_keys[l].toLowerCase() === 'image')
							{
								if (item[keys[j]].targetItems[k][_keys[l]])
								{
									if (item[keys[j]].targetItems[k][_keys[l]].jss && item[keys[j]].targetItems[k][_keys[l]].jss.value && item[keys[j]].targetItems[k][_keys[l]].jss.value.src) {
									  jss['image'] = item[keys[j]].targetItems[k][_keys[l]].jss.value.src;
									}
								}
							}
							else
								jss[_keys[l]] = item[keys[j]].targetItems[k][_keys[l]];
						}
					}
					ar_.push(jss);
				}
				o[keys[j]]['children'] = ar_;
			}
          }
        }
	return o;
  }
  

  
  var getChildren = function(type, item, vm, templatefilter, constants, hotels, amenities, travellertypes, contentcategories, features, objArray) {
	if (type.toLowerCase() == "allsociety")
	{
		//console.log(item);
	}
    var _ar = [];
    var o = {};
    var jss = {};
	var _arr = [];
	if (objArray == null || objArray == undefined)
	{
		objArray = [];
	}
	for (var i = 0; i < item.length; i++) {
		o = {};
		o = getgraphqlobject(item[i], type, vm, templatefilter, true, constants, hotels, amenities, travellertypes, contentcategories, features, objArray)
		if (item[i].children && item[i].children != null && item[i].children.length > 0) {
			o['children'] = getChildren(type, item[i].children, vm, templatefilter, constants, hotels, amenities, travellertypes, contentcategories, features, objArray);
		}
		_ar.push(o);
	}

	return _ar;
  };

  var o = {};
  var ar = [];
  var ar_ = [];
  var jss = {};
  var _o = {};


	

	o = getgraphqlobject(item, type, vm, templatefilter, true, constants, hotels, amenities, travellertypes, contentcategories, features, objArray); 
	if (item.children && item.children.length > 0)
	{
		o['children'] = getChildren(type, item.children, vm, templatefilter, constants, hotels, amenities, travellertypes, contentcategories, features, objArray)
	}

  return o;  
}

export function getContentByType_new(
	data, type, vm, isgraphql, isfeatureimageonly, travellertype, isservices, maxcount, property, eventtype, checkindate, likes, maximages, ignoreallfilter
)
{
	var results = [];
	var currentcount = 0;

	if (isgraphql == undefined || isgraphql == true)
	{
		isgraphql = true;
	}
	
	if (isgraphql)
	{
		data = getObject(data, vm, property, isfeatureimageonly, travellertype);
	}

	
	var sortContentByType = function(item, type, isfeatureimageonly, travellertype)
	{
		if (!(isfeatureimageonly == undefined || isfeatureimageonly == false))
		{
			var count = 0;
			var featuredIndex = -1;
			var firstImageIndex = -1;
			var imagecount = 0;
			if (item["children"] != null)
			{
				for(var i=item["children"].length-1; i >= 0; i--)
				{
					if (item['children'][i].templatename && item["children"][i].templatename == "hjimage")
					{
						if (!(item["children"][i]["isfeatured"] == null || item["children"][i]["isfeatured"] == false))
						{
							featuredIndex = i;
						}
						firstImageIndex = i;
					}
				}
				for(var i=item["children"].length-1; i >= 0; i--)
				{
					if (featuredIndex > -1)
					{
						if (i != featuredIndex)
						{
							item["children"].splice(i, 1);
						}
					}
					else
					{
						if (firstImageIndex > -1)
						{
							if (i != firstImageIndex)
							{
								item["children"].splice(i, 1);
							}
						}
						else
						{
							if (item["children"][i].templatename == "hjimage")
							{
								if (maximages != -1)
								{
									if (imagecount > maximages)
									{
										item["children"].splice(i, 1);
									}
								}
								imagecount++;
							}
						}
					}
				}
			}	
			if (imagecount == 0)
			{
			}
		}
	}
	
	var getChildren = function(item, type, results, isfeatureimageonly, travellertype, isservices, currentcount, maxcount, property, eventtype, checkindate, likes, vm, ignoreallfilter){
		var count = 0;
		var isadd = false;
		
		var _item = item['children'] && item['children'].length > 0 ? item['children'] : item;
		for(var i=0; i < _item.length; i++)
		{
			if (_item[i].templatename && _item[i].templatename.toLowerCase() == type.toLowerCase())
			{
				if ((isfeatureimageonly != undefined && isfeatureimageonly == true)
					|| (travellertype != undefined && travellertype != null))
				{
					var __item = _item[i];
					sortContentByType(__item, type, isfeatureimageonly, travellertype);					
					results.push(__item);									
				}
				else
				{
					results.push(_item[i]);									
				}
			}
			else
			{
				if (_item[i]["children"] != null && _item[i]["children"].length > 0)
				{
					getChildren(_item[i], type, results, isfeatureimageonly, travellertype, isservices, currentcount, maxcount, property, eventtype, checkindate, likes, vm, ignoreallfilter);
				}					
			}
		}
	}
	
	var getdata = function(
		results, data, type, vm, isfeatureimageonly, travellertype, isservices, maxcount, property, eventtype, checkindate, likes, ignoreallfilter
		){
		if (data && data.templatename && data.templatename == type)
		{
			results.push(data);									
		}
		else
		{
			if (data.length > 0 || (data["children"] != null && data["children"].length > 0))
			{
				getChildren(data, type, results, isfeatureimageonly, travellertype, isservices, currentcount, maxcount, property, eventtype, checkindate, likes, vm, ignoreallfilter);
			}
		}
	}
	getdata(results, data, type, vm, isfeatureimageonly, travellertype, isservices, maxcount, property, eventtype, checkindate, likes, ignoreallfilter);
	
	// return results;
	return iterateResults(results);
}



function iterateResults(results){
	var newArray = []
	var noindexarrays = []
	if(Object.keys(results).length > 0 ){

		if('orderindex' in results[0]){

			for(var i=0;i<results.length;i++){
				newArray.push(results[i]);
				noindexarrays.push({'propId':results[i].id,'order':results[i].orderindex.value})
			}
			//console.log(typeof newArray)
			//console.log(newArray.sort(function(a, b){return a.orderindex.value - b.orderindex.value}))
			//console.log(noindexarrays.sort(function(a, b){return b.order - a.order}))
			return newArray.sort(function(a, b){return a.orderindex.value - b.orderindex.value})

		}else{
			return results
		}
	}

}


