<template>
  <div
    :id="this.uid"
    class="container-fluid maps-container pl-0 pr-0 HJMap components"
  >
    <div class="row no-gutters justify-content-center">
      <div class="col-12">
        <h3>{{ getDictionaryPhrase('MapTitle') }}</h3>
      </div>
    </div>
    <div class="jenMap">
      <div id="map"></div>
      <div id="nearbyPlaces" style="visibility:hidden">
        <div class="owl-carousel owl-theme"></div>
      </div>
    </div>

    <!--</div>-->
    <!--<div id="jenHotel">
		<div class="jenHotelPhone"><img :src="phoneLogo" alt="" /><span></span></div>
		<div class="jenHotelAddress"><img :src="locationLogo" alt="" /><span></span></div>
		<div class="jenHotelMetro"><img :src="metroLogo" alt="" /><span></span></div>
		<div class="jenHotelBus"><img :src="busLogo" alt="" /><span></span></div>
	</div>-->
    <div id="jenFilters">
      <!--<div class="jenFilterZoom">
			<div class="jenOptionSelected">2000m</div>
			<div class="jenOptions">
				<div class="jenOption">500m</div>
				<div class="jenOption">1000m</div>
				<div class="jenOption active">2000m</div>
			</div>
		</div>-->
      <div class="jenFilterCategories"></div>
    </div>
  </div>
</template>


<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import { Image } from '@sitecore-jss/sitecore-jss-vue';
import axios from 'axios';
import { dataFetcher } from '../dataFetcher.js';
import { layoutFetcher, layoutFetchAction } from '../layoutFetcher.js';
import {
getDictionaryPhrase,
	getContentByType, 
	getKeys,
  getObject,
  graphqlRemoveReserved,
  graphqlToObject,
  getObjectById,
  graphqlFetcher,
} from '../graphqlFetcher.js';
//import $ from 'jquery';
import ApolloClient from 'apollo-boost';
import gql from 'graphql-tag';
import { uuid } from 'vue-uuid';
import {
  enableExperimentalFragmentVariables,
  disableExperimentalFragmentVariables,
} from 'graphql-tag';
import Vue from 'vue';
import Meta from 'vue-meta';
import { trackingApi } from '@sitecore-jss/sitecore-jss-tracking';
import config from '../temp/config';
import buslogo from '../assets/img/bus.png';
import locationlogo from '../assets/img/location.png';
import metrologo from '../assets/img/metro.png';
import phonelogo from '../assets/img/phone.png';
import hotellogo from '../assets/img/icons/hotel-icon.png';
import articon from '../assets/img/icons/art-icon.png';
import cultureicon from '../assets/img/icons/culture-icon.png';
import drinkicon from '../assets/img/icons/drink-icon.png';
import foodicon from '../assets/img/icons/food-icon.png';
import musicicon from '../assets/img/icons/music-icon.png';
import transportationicon from '../assets/img/icons/transportation-icon.png';
import shoppingicon from '../assets/img/icons/shopping-icon.png';
import previcon from '../assets/img/icons/arrow_left.png';
import nexticon from '../assets/img/icons/arrow_right.png';
import locationicon from '../assets/img/icons/location-icon.png';
import phoneicon from '../assets/img/icons/phone-icon.png';
import closeicon from '../assets/img/close_btn.png';
/*import artitemicon from '../assets/img/icons/art-item-icon.png';
import cultureitemicon from '../assets/img/icons/culture-item-icon.png';
import drinkitemicon from '../assets/img/icons/drink-item-icon.png';
import fooditemicon from '../assets/img/icons/food-item-icon.png';
import musicitemicon from '../assets/img/icons/music-item-icon.png';
import transportationitemicon from '../assets/img/icons/transportation-item-icon.png';
import shoppingitemicon from '../assets/img/icons/shopping-item-icon.png';*/

Vue.use(Meta);
export default {
  name: 'HJMap',
/*  metaInfo() {
    return {
      script: [
      { 
		src: 'http://api.map.baidu.com/api?v=3.0&ak=qazw7qyvrfYgCa1XnYbIxr6oHe4vhmnX&callback=initBMap', 
		async: false, 
		defer: false,
        type: 'text/javascript',
        body: true,

		
	  },
      { 
		src: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyC7FoHpDUJpNNNvE9hKGZ13ezJYQsMrJX4&libraries=places&callback=initMap&libraries=geometry', 
		async: true, 
		defer: true,
        type: 'text/javascript',
        body: true,
	  }
      ]	
    };
  },*/
  components: {
    ScText: Text,
  },
  props: {
    fields: {
      type: Object,
      default: function() {},
    },
    currentcontext: {
      type: Object,
      default: null,
    },
    uid: {
      type: String,
      default: function() {
        return uuid.v1();
      },
    },
	nearbylocations: {
		type: String,
		default: function() {
			return null;
		},
	}
  },
  data() {
    return {
      loadingQueriesCount: 0,
      error: null,
	  allcontentArr: [],
	  busLogo: buslogo,
	  locationLogo: locationlogo,
	  metroLogo: metrologo,
	  phoneLogo: phonelogo,
	  hotelLogo: hotellogo,
	  artIcon: articon,
	  cultureIcon: cultureicon,
	  drinkIcon: drinkicon,
	  foodIcon: foodicon,
	  musicIcon: musicicon,
	  transportationIcon: transportationicon,
	  shoppingIcon: shoppingicon,
	  prevIcon: previcon,
	  nextIcon: nexticon,
	  locationIcon: locationicon,
	  phoneIcon: phoneicon,
	  /*artitemIcon: artitemicon,
	  cultureitemIcon: cultureitemicon,
	  drinkitemIcon: drinkitemicon,
	  fooditemIcon: fooditemicon,
	  musicitemIcon: musicitemicon,
	  transportationitemIcon: transportationitemicon,
	  shoppingitemIcon: shoppingitemicon*/
	  closeIcon: closeicon,
    };
  },
  computed: {
    datasource: function() {
	  if (this.currentcontext != null)
		return this.currentcontext;
	  else
		return this.fields && this.fields.data && this.fields.data.datasource;
    },
    contextItem: function() {
      return this.fields.data && this.fields.data.contextItem;
    },
    hotels: function() {
      if (this.$jss.hotels()) return this.$jss.hotels();
      else return {};
    },
    constants: function() {
      if (this.$jss.constants()) 
		return this.$jss.constants();
      else {
        return {};
      }
    },
  },
  mounted: function() {
    // ...
    let vm = this;
	var propertyid = vm.$jss.property();
	var hotelDetails = {longitude:{value: 0}, latitude:{value:0}, Title:{value:'HotelJen Hong Kong'}};
	
	if (propertyid && propertyid != null){
		propertyid = propertyid.replace(/\-/g,"").toUpperCase();
		hotelDetails = getObject(getObjectById(vm.$jss.hotels(),propertyid), vm);
	}else{
		console.log('no property id found');
	}
	
	var touchstartOrClick = 'click';
	var screenSize = $(window).width();
	console.log('window width check'+$(window).width());
	if (screenSize >= 640){
		touchstartOrClick = 'click';
	}else{
		touchstartOrClick = 'touchend';
	}
	
	var counter;
	counter = 0;
	var checkmap = window.setInterval(function(vm){ 
		if (vm.nearbylocations != null && vm.nearbylocations.children && vm.nearbylocations.children.length > 0)
		{
			var features = [];
			
			/*
			if (vm.nearbylocations.children && vm.nearbylocations.children.length > 0){
				for (var i = 0; i < vm.nearbylocations.children.length; i++){
					var featureitems = {};
					if (vm.nearbylocations.children[i].property && vm.nearbylocations.children[i].property.targetItems.length > 0 && vm.nearbylocations.children[i].property.targetItems[0].id === propertyid){
						var locationArr = vm.nearbylocations.children[i].location.value.split(", ");
						if (locationArr.length != 2){
							locationArr = vm.nearbylocations.children[i].location.value.split(",");
						}
						featureitems.lng = locationArr[0];
						featureitems.lat = locationArr[1];
						if (vm.nearbylocations.children[i].nearbytype.value !=''){
							featureitems.type = vm.nearbylocations.children[i].nearbytype.value.toLowerCase();
						}else{
							featureitems.type = 'shopping';
						}
						featureitems.title = vm.nearbylocations.children[i].title.value;
						featureitems.address = vm.nearbylocations.children[i].address.value;
						featureitems.phone = vm.nearbylocations.children[i].phone.value;
						featureitems.description = '';		
						featureitems.distance = 100;
						featureitems.url = vm.nearbylocations.children[i].url.value;
						featureitems.operating_hours = vm.nearbylocations.children[i].description.value;
						
						features.push(featureitems);
					}
		
				}
			}
			*/
			if (vm.nearbylocations.children && vm.nearbylocations.children.length > 0){
				for (var i = 0; i < vm.nearbylocations.children.length; i++){
					var featureitems = {};
					//if (vm.nearbylocations.children[i].property && vm.nearbylocations.children[i].property.targetItems.length > 0 && vm.nearbylocations.children[i].property.targetItems[0].id === propertyid){
						var locationArr = vm.nearbylocations.children[i].location.value.split(", ");
						if (locationArr.length != 2){
							locationArr = vm.nearbylocations.children[i].location.value.split(",");
						}
						featureitems.lng = locationArr[0];
						featureitems.lat = locationArr[1];
						if (vm.nearbylocations.children[i].nearbytype.value !=''){
							featureitems.type = vm.nearbylocations.children[i].nearbytype.value.toLowerCase();
						}else{
							featureitems.type = 'shopping';
						}
						featureitems.title = vm.nearbylocations.children[i].title.value;
						featureitems.address = vm.nearbylocations.children[i].address.value;
						featureitems.phone = vm.nearbylocations.children[i].phone.value;
						featureitems.description = '';		
						featureitems.distance = 100;
						featureitems.url = vm.nearbylocations.children[i].url.value;
						featureitems.operating_hours = vm.nearbylocations.children[i].description.value;
						
						features.push(featureitems);
					//}
		
				}
			}
			//console.log(features);
			
			//Init Map 
			var map;
			var mainMAP = "baidu";
			// if (vm.$jss.hjgeocookie() != null && vm.$jss.hjgeocookie() == "CN")
			// {			
			// 	mainMAP = "baidu";
			// }
			//mainMAP = "baidu";
			var orgWidth = 0;

			var markers = [];
			var showCategories = [];
			var place = [];

			/*var imageBase = 'images/';
			var iconBase = imageBase + 'icons/';*/

			var icons = {
				art: {
					icon: vm.artIcon,
					color: '#CC3300'
				},
				culture: {
					icon: vm.cultureIcon,
					color: '#CCCC33'
				},
				drink: {
					icon: vm.drinkIcon,
					color: '#FF6699'
				},
				food: {
					icon: vm.foodIcon,
					color: '#FFB500'
				},
				music: {
					icon: vm.musicIcon,
					color: '#9999FF'
				},
				shopping: {
					icon: vm.shoppingIcon,
					color: '#343944'
				},
				transportation: {
					icon: vm.transportationIcon,
					color: '#D3BC8D'
				}
			};

			/*var hotel_icon = iconBase + 'hotel-icon.png';*/
			if (hotelDetails && hotelDetails.longitude && hotelDetails.longitude.value == ''){
				hotelDetails.longitude.value = 0;
			}

			if (hotelDetails && hotelDetails.latitude && hotelDetails.latitude.value == ''){
				hotelDetails.latitude.value = 0;
			}

			var hotel = [{
				icon: vm.hotelLogo,
				name: (hotelDetails && hotelDetails.title && hotelDetails.title.value ? hotelDetails.title.value : ""),
				//lng: 22.2830218,
				//lat: 114.1434218,
				lng: (hotelDetails && hotelDetails.longitude && hotelDetails.longitude.value ? parseFloat(hotelDetails.longitude.value) : 0),
				lat: (hotelDetails && hotelDetails.latitude && hotelDetails.latitude.value ? parseFloat(hotelDetails.latitude.value) : 0),
				phone: '+852 2974 1234',
				address: '508 QUEEN\'S ROAD WEST, HONG KONG',
				metro: 'MR EXIT B2',
				bus: '2A, 27, 690'
			}];

			/*var featureIcons = {
				art: vm.artitemIcon,
				culture: vm.cultureitemIcon,
				drink: vm.drinkitemIcon,
				food: vm.fooditemIcon,
				music: vm.musicitemIcon,
				transportation: vm.transportationitemIcon,
				shopping: vm.shoppingitemIcon
			};*/

			/*if (mainMAP == 'google' && features.length > 1){
				setTimeout(function(){initMap(hotel, features, icons, vm.prevIcon, vm.nextIcon, vm.locationIcon, vm.phoneIcon, vm.closeIcon);}, 300);
			}else if (mainMAP == 'baidu' && features.length > 1){
				//initBMap(hotel, features, icons, vm.prevIcon, vm.nextIcon, vm.locationIcon, vm.phoneIcon, featureIcons);
				setTimeout(function(){initBMap(hotel, features, icons, vm.prevIcon, vm.nextIcon, vm.locationIcon, vm.phoneIcon, vm.closeIcon);}, 300);
			}*/
			//orgWidth = $('.owl-stage').width();
			//console.log(orgWidth);
			console.log(typeof initMap)
			if (mainMAP == 'google') { 
			//&& features.length > 1){
				if(initMap && typeof initMap === 'function') var map = initMap(hotel, features, icons, vm.prevIcon, vm.nextIcon, vm.locationIcon, vm.phoneIcon, vm.closeIcon);					
			}else if (mainMAP == 'baidu') {
			//&& features.length > 1){
				//initBMap(hotel, features, icons, vm.prevIcon, vm.nextIcon, vm.locationIcon, vm.phoneIcon, featureIcons);
				if(initBMap && typeof initBMap === 'function')
				var map =  initBMap(hotel, features, icons, vm.prevIcon, vm.nextIcon, vm.locationIcon, vm.phoneIcon, vm.closeIcon)
			}
			
			$(window).resize(function(){
				setTimeout(function(){orgWidth = $('.owl-stage').width();},300);
			});
			
			$(document).ready(function() {
				
				// orgWidth = $('.owl-stage').width();
				// $('.owl-carousel').on('refreshed.owl.carousel', function(event) {
				// 	orgWidth = $('.owl-stage').width();
				// });

				$('.jenHotelPhone > span').html(hotel[0].phone);
				$('.jenHotelAddress > span').html(hotel[0].address);
				$('.jenHotelMetro > span').html(hotel[0].metro);
				$('.jenHotelBus > span').html(hotel[0].bus);

				var categories = [];
				$(features).each(function (index, value) {
					categories.push(value.type);
				});
				 
				categories = array_unique(categories);
				categories.sort();
				 
				/*var filterCat = "";
				$(categories).each(function (index, value) {
					filterCat += '<span class="jenFilterCategory"><input type="checkbox" class="jenFilterCB" filter="' + value + '" checked />' + sentenceCase(value) + '</span>';
				});*/
				var filterCat = "";
				 $(categories).each(function (index, value) {
					 filterCat += '<label class="checkbox-container">' + sentenceCase(value)
					 filterCat +=   '<input type="checkbox" checked="checked" class="jenFilterCB" filter="' + value + '" >'
					 filterCat +=   '<span class="checkmark"></span>'
					 filterCat += '</label>'
					//  <span class="jenFilterCategory"><input type="checkbox" class="jenFilterCB" filter="' + value + '" checked />' + sentenceCase(value) + '</span>';
				 });
				 
				$('.jenFilterCategories').html(filterCat);
				
				if ($('.jenFilterCategories input').length > 0)
				{
					console.log('jenFilterCategories clicked');
					// $('.jenFilterCategories input:first').trigger('click');
					// $('.jenFilterCategories input:first').trigger('click');
					$('#jenFilters input:first').trigger('click');
					setTimeout(function(){$('#jenFilters input:first').trigger('click')},300);
					$('#nearbyPlaces').css('visibility', 'visible');
				}

				/*$(document).on('click', '.jenClose', function() {
					$(this).parent('.jenPlaceDescription').hide(300);
					setTimeout(function(){$('.owl-item').removeClass('selected');}, 200);
				});*/

				/*$(document).on('click', '.owl-item', function() {
					//console.log('owl-item clicked');
					var owl_stage = $('.owl-stage');
					var owl_items = owl_stage.find('.owl-item');
				
					if (owl_stage.width() === orgWidth) {
						$('.owl-stage').width(orgWidth + 280);
					}
					if ($(this).find('.jenPlaceDescription').css('display') == "none") {
						owl_items.each(function() {
							$(this).find('.jenPlaceDescription').hide();
						});
						$(this).find('.jenPlaceDescription').show(300);
					}
					$('.owl-item').each( function () {
							$(this).removeClass('selected');
					});
					  $(this).addClass('selected');
					 
				});*/
				
					$('#nearbyPlaces .owl-carousel').on('touchmove', function(e){
						$('#nearbyPlaces .owl-carousel').addClass('drag');

					});

					$('#nearbyPlaces .owl-carousel').on('touchend', function(e){
						//console.log('dragend: '+ e.originalEvent.touches[0].pageX);		
						setTimeout(function(){
								$('#nearbyPlaces .owl-carousel ').removeClass('drag');
						}, 200);

					});
				

				 $(document).on(touchstartOrClick, '.jenClose', function(e) {
					e.preventDefault()
					e.stopPropagation()
					$(this).closest('.owl-item').removeClass('selected')
					$(this).parent('.jenPlaceDescription').hide(300);
					$(this).parent().parent().parent().parent().parent().removeClass('selected')
				});
				
					$(document).on(touchstartOrClick, '.jenPlaceAddress a', function(e) {
					 e.preventDefault();
					e.stopPropagation();
					if ($(this).attr('href') != '' || $(this).attr('href') != undefined){
						window.open($(this).attr('href'));
					}
				});
				
				$(document).on(touchstartOrClick, '.jenPlaceTitle a', function(e) {
				
					if ($(this).parent().parent().parent().parent().hasClass('selected') && ($(this).attr('href') != '' || $(this).attr('href') != undefined)){
						window.open($(this).attr('href'));
					}

				});

			//    $(document).on('click', '#nearbyPlaces .owl-item', function(e) {
				// $('#nearbyPlaces .owl-item').unbind(touchstartOrClick).bind(touchstartOrClick, function(e) {
				//    var carousel = $('#nearbyPlaces .owl-carousel').data('owl.carousel');
				// 	//console.log(carousel.relative($(this).prev().index()));
				// 	var node = Array.prototype.slice.call($('#nearbyPlaces .owl-stage').children(".owl-item:not('.cloned')"));
				// 	e.preventDefault()
				// 	//e.stopPropagation()
				// 	//var markerID = $(this).children('.item').attr('data-slideto');
				// 	var markerID = carousel.relative($(this).index());
				// 	var preservedID = $(this).children('.item').attr('data-slideto');
				// 	//console.log(carousel.relative($(this).index()));
								
				// 	var owl_stage = $('.owl-stage');
				// 	var owl_items = owl_stage.find('.owl-item');
				// 	if (owl_stage.width() === orgWidth) {
				// 	   /*$('.owl-stage').width(orgWidth + 180);*/
				// 		$('.owl-stage').width(orgWidth + 480);
				// 	}
					
				// 	// Expand Content for item
				// 	if (!$(this).parent().parent().parent().hasClass('drag')){
				// 	if ($(this).find('.jenPlaceDescription').css('display') == "none") {
				// 		owl_items.each(function() {
				// 			$(this).find('.jenPlaceDescription').hide();
				// 		});
				// 		$(this).find('.jenPlaceDescription').show(300);
				// 	}
				// 	$('.owl-item').each( function () {
				// 			$(this).removeClass('selected');
				// 	});
				// 	  $(this).addClass('selected');
					  
					  
					
				// 	 var screenWidth = $(window).width();  

				// 	if ($(this).hasClass('cloned')){
				// 		setTimeout(function(){
				// 		console.log('cloned slide');
				// 		$('.item-' + preservedID).parent().addClass('selected');
				// 		//if ($('.item-' + markerID).parent().find('.jenPlaceDescription').css('display') == "none") {
				// 			//owl_items.each(function() {
				// 			//	$(this).parent().find('.jenPlaceDescription').hide();
				// 			//});
				// 		$('.item-' + preservedID).parent().find('.jenPlaceDescription').show(300);
				// 	//}
				// 		}, 100);
				// 	}
					  
					 
				// 	  //console.log(screenWidth);
				// 	  //console.log(markerID);
				// 	  if (screenWidth >= 1024){
				// 		 // if (markerID != 0 ){
				// 		  //$('.owl-carousel').trigger('to.owl.carousel', markerID - 1);
				// 		  //}else if (markerID == (features.length - 1)){
				// 			   //$('.owl-carousel').trigger('to.owl.carousel', markerID - 3);
				// 			  // $('.owl-carousel').trigger('to.owl.carousel', 15);
				// 		 // }else{
				// 			  // $('.owl-carousel').trigger('to.owl.carousel', markerID);
				// 		  //}
				// 		  /*if (markerID == 0 || markerID == (node.length-1)) {
				// 			$('.owl-carousel').trigger('to.owl.carousel', markerID);
				// 		  }else{
				// 			$('.owl-carousel').trigger('to.owl.carousel', markerID - 1);
				// 		  }*/
				// 		  if (markerID == 0){
				// 			$('.owl-carousel').trigger('to.owl.carousel', node.length - 1);
				// 			$('.item-' + preservedID).parent().addClass('selected');
				// 			$('.item-' + preservedID).parent().find('.jenPlaceDescription').show(300);
				// 		  }else{
				// 			$('.owl-carousel').trigger('to.owl.carousel', markerID - 1);
				// 		  }
				// 	  }else{
				// 		  $('.owl-carousel').trigger('to.owl.carousel', markerID);
				// 	  }
					  
					  
				// 	 var latVal = $(this).children('.item').attr('data-lat');
				// 	 var lngVal =  $(this).children('.item').attr('data-lng');
					 
				// 	 if (mainMAP == 'baidu'){
				// 		var latlng = new BMap.Point(latVal, lngVal);
				// 		if (map){
				// 			map.panTo(latlng);
				// 		}
				// 	 }else{
				// 		 var latling = new google.maps.LatLng(lngVal, latVal);
				// 		 if (map){
				// 			map.panTo(latling);
				// 		 }
				// 	 }
				// 	}

				// });

				

				$('.jenOptionSelected').unbind('click').bind('click', function(){
					console.log('jenOptionSelected Clicked');
					$('.jenOptions').toggle();
				});
				
				// NOT BEING USED
				// $(document).on('click', '.jenOption', function() {
				// 	$('.jenOption').each( function (){
				// 		$(this).removeClass('active');
				// 	});
				// 	$(this).addClass('active');
				// 	$('.jenOptionSelected').html($(this).html());
				// 	$('.jenOptions').hide();
					
				// 	var showCategories = [];
				// 	 $('.jenFilterCB').each(function () {
				// 		if($(this).prop('checked')) {
				// 			showCategories.push($(this).attr('filter'));
				// 	}
				// 	});
				// 	var showRange = parseInt($(this).html());
				// 	updatePlaces(features, mainMAP, showCategories, vm.prevIcon, vm.nextIcon, showRange);
				// });

				// $(document).on('click', '.jenFilterCB', function() {
				$('#jenFilters .jenFilterCB').unbind('click').bind('click', function() {
					var showCategories = [];
					var showRange = parseInt($('.jenOptionSelected').html());
					$('.jenFilterCB').each(function () {
						if($(this).prop('checked')) {
							showCategories.push($(this).attr('filter'));
						}
					});
					//updatePlaces(features, mainMAP, showCategories, vm.prevIcon, vm.nextIcon);
					updatePlaces(features, mainMAP, showCategories, vm.prevIcon, vm.nextIcon, showRange);
				});

			});
			if (mainMAP === 'google')
			{
				if (typeof google === 'object' && typeof google.maps === 'object')
				{
					clearInterval(checkmap);				
				}
			}
			else
			{
				if (typeof BMap === 'object')
				{
					clearInterval(checkmap);				
				}
			}
			counter++;
			if (counter > 5)
			{
				clearInterval(checkmap);
			}
		}
	}, 3000, vm);
	

	function array_unique(array) {
		var unique = [];
		for ( var i = 0 ; i < array.length ; ++i ) {
			if ( unique.indexOf(array[i]) == -1 )
				unique.push(array[i]);
		}
		return unique;
	}

	function sentenceCase (str) {
		 if ((str===null) || (str===''))
			 return false;
		 else
		 str = str.toString();

		 return str.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
	}

	function recalcCarouselWidth(carousel) {
		var $stage = carousel.find('.owl-stage'),
			 stageW = $stage.width(),
		 $el = $('.owl-item'),
		 elW = 0;
		$el.each(function() {
			elW += $(this)[0].getBoundingClientRect().width;
		});
		if ( elW > stageW ) {
		 console.log('elW maggiore di stageW: ' +  elW + ' > ' + stageW);
		 $stage.width( Math.ceil( elW ) );
		}
	}
  },
  updated() {
    let vm = this;
  },
  methods: {
	getDictionaryPhrase: function(key)
	{
		return getDictionaryPhrase(key, this);
	},
    isproperty: function(name) {
      if (this.fields != null && this.fields[name] != undefined) {
        return this.fields[name];
      } else {
        if (this.fields.data.datasource && this.fields.data.datasource.fields) {
          for (var i = 0; i < this.fields.data.datasource.fields.length; i++) {
            if (this.fields.data.datasource.fields[i].name == name) {
              if (
                this.fields.data.datasource.fields[i].targetItems &&
                this.fields.data.datasource.fields[i].targetItems.length > 0
              ) {
                if (this.fields.data.datasource.fields[i].targetItems[0].TextField)
                  return this.fields.data.datasource.fields[i].targetItems[0].textField.value;
                else if (this.fields.data.datasource.fields[i].targetItems[0].title)
                  return this.fields.data.datasource.fields[i].targetItems[0].textField.Title;
                else return '';
              } else {
                return this.fields.data.datasource.fields[i].value;
              }
            }
          }
        }
		else if (this.currentcontext && this.currentcontext.fields)
		{
			var keys = getKeys(this.currentcontext);
			for (var i=0; i < keys.length; i++)
			{
				if (keys[i].toLowerCase() === name.toLowerCase())
				{
					if (this.currentcontext[keys[i]].targetItems && 
						this.currentcontext[keys[i]].targetItems.length > 0) {
						if (this.currentcontext[keys[i]].targetItems[0].TextField)
							return this.currentcontext[keys[i]].targetItems[0].textField.value;
						else if (this.currentcontext[keys[i]].targetItems[0].title)
							return this.currentcontext[keys[i]].targetItems[0].title.value;
						else
							return "";
					}
					else
					{
						if (this.currentcontext[keys[i]].value != undefined)
							return this.currentcontext[keys[i]].value;
						else
							return this.currentcontext[keys[i]];
					}
				}
			}
			for (var i=0; i < this.currentcontext.fields.length; i++)
			{
				if (this.currentcontext.fields[i].name.toLowerCase() == name.toLowerCase())
				{
					if (this.currentcontext.fields[i].targetItems && 
						this.currentcontext.fields[i].targetItems.length > 0) {
						if (this.currentcontext.fields[i].targetItems[0].textField)
							return this.currentcontext.fields[i].targetItems[0].textField.value;
						else if (this.currentcontext.fields[i].targetItems[0].title)
							return this.currentcontext.fields[i].targetItems[0].title.value;
						else
							return "";
					}
					else
					{
						if (this.currentcontext.fields[i].value)
							return this.currentcontext.fields[i].value;
						else
							return this.currentcontext.fields[i];
					}
				}
			}
		}
      }
      return null;
    },
  },
};
</script>
