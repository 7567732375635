<template>
	<div>
	</div>
</template>

<script>
export default {
  name: 'HJContentModule',
  components: {
  },
  props: {
    fields: {
      type: Object,
      default: function() {},
    },
  }
}
</script>
