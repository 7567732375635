<template>
	<div :id="this.uid" :class="'HJContentBlockList ' + isproperty('CssClass') +' '+ [data_datasource['isdemo'] && data_datasource['isdemo'].value == '1'? 'DemoBlock': '']" :data-datatype="isproperty('contentCategory')">		
		<template v-if="isHomePage">
		<template v-if="data_datasource && data_datasource.anchorid && data_datasource.anchorid.value">
			<div style="visibility:hidden;height:1px;" :id="data_datasource.anchorid.value"></div>
		</template>
		
		<template v-if="this.isproperty('iscarousel') != '1'">
			<template v-if="data_datasource['title'] && data_datasource['title'].value">
			<div class="list-title">{{data_datasource['title'].value}}</div>
			</template>			
			<template v-if="data_datasource['description'] && data_datasource['description'].value">
			<div class="list-desc" v-html="data_datasource['description'].value"></div>
			</template>
		</template>	
		
		
		
		<template v-if="this.isproperty('iscarousel') == '1'">
		

		
	    <div class="row no-gutters justify-content-center">
           <div class="col-12 col-lg-10 col-xl-8 text-center">
            <div class="container-fluid pr-0 pl-0">
			<template v-if="data_datasource['title'] && data_datasource['title'].value">
				 <div class="row no-gutters mb-4 pb-2">
						<div class="col-12">
							<h3>{{data_datasource['title'].value}}</h3>
						</div>
				 </div>
			</template>
			
			<div class="row no-gutters">
               <div class="col-12 recommend-carousel brand-society-carousel events">
                                <!-- Featured Swiper -->
								<template v-if="graphqlresult && graphqlresult.length > 0">
                                <div :id="'swiper_'+uid" :class="'recommend-swiper-container swiper-container-full gallery-top contentblock-society-top events swiper-container-initialized swiper-container-horizontal'" style="perspective: 1px">
                                    <div class="swiper-wrapper">
									<template v-for="(item, index) in graphqlresult">
										<template v-if="item['templatename'] != 'hjevent' && item['templatename'] != 'hjworkout' && item['templatename'] != 'hjspace'">
											<div class="swiper-slide align-items-start" :data-itemid="item['id']">
												<div class="container-fluid pl-0 pr-0">
													<template v-if="item['children'] && item['children'].length > 0">
														<template v-for="(subitem, subindex) in item['children']">
														<div :class="'row no-gutters ' + [subindex > 0?'hidden':'']">
															<div class="col-12 text-left">
															<template v-if="item['price']">
																<template v-if="item['price'] && item['price'].value && item['price'].value > 0">
																	<div class="img-overlay">
																			<h3><small> {{getDictionaryPhrase('From')}}</small> {{updatePriceString(item["price"].value)}} <span>{{$jss.currency()}}</span></h3>
																	</div>											
																</template>
																<template v-else-if="item['price'] && item['price'] > 0">
																	<div class="img-overlay">
																			<h3><small> {{getDictionaryPhrase('From')}}</small> {{updatePriceString(item["price"])}} <span>{{$jss.currency()}}</span></h3>
																	</div>
																</template>
															</template> <!-- End: Price Tag -->
																<template v-if="subitem['templatename'] == 'hjimage'">
																	<template v-if="subitem['image'].src != ''">
																	 <div class="img-block1" :style="'background-image: url('+subitem['image']['src']+');'"></div>
																	</template>
																	<template v-else>
																		<div class="img-block1" style="background-image: url(data:image/png;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==);"></div>
																	</template>
																</template> <!-- End: item Image background -->                                                      
																<div class="icon-tags" style="top:0.5rem;">
																	<div class="fav-btn icon" :data-linkedid="item['id']"></div>
																</div>															
															</div>
														</div> <!-- End:  Hero Image-->
														</template>
													</template> <!-- End of Hero Section -->

													<div class="row no-gutters">
														<div class="col-6 text-left">
														<template v-if="item['property'] && item['property']['children'] && item['property']['children'].length > 0 && item['property']['children'][0]['title']">
															<p>{{ item['property']['children'][0]['title'] }}</p>
															</template>
															<h4 style="margin-top:0;">{{ item['title'].value }}</h4>
															
															<template v-if="item['templatename'] == 'hjroom-template'">
																<button type="button" class="book btn btn-primary mt-3" :data-urlalias="item['urlalias'].value" :data-roomtypecode="item['roomtypecode'].value" :data-rateplancode="item['rateplancode'].value" :data-bookingurl="item['bookingurl']" :data-roomcode="item['roomcode'].value" :data-offerurl="[item['offerurl'] && item['offerurl'].value ? item['offerurl'].value : '']">{{getDictionaryPhrase('Book')}}</button>
															<template v-else>
																<button type="button" class="book btn btn-primary mt-3">{{getDictionaryPhrase('Book')}}</button>
															</template>
														</div>
														
														<template v-if="item['price']">
															<template v-if="item['price'].value && item['price'].value > 0">
																	<div class="col-6 text-right">
																		<p class="mt-3">{{getDictionaryPhrase('From')}}</p>
																		<h4 style="margin-top:0;">{{updatePriceString(item["price"].value)}} <span>{{$jss.currency()}} <br></span></h4>
																		<p>{{getDictionaryPhrase('PerNight')}}</p>
																	</div>
															</template>
															<template v-else-if="item['price'] > 0">
																	<div class="col-6 text-right">
																		<p class="mt-3">{{getDictionaryPhrase('From')}}</p>
																		<h4 style="margin-top:0;">{{updatePriceString(item["price"])}} <span>{{$jss.currency()}} <br></span></h4>
																		<p>{{getDictionaryPhrase('PerNight')}}</p>
																	</div>
															</template>
														</template>
													</div> <!-- End: Description -->

												</div>
												<div class="itemid" style="display:none">{{item['id']}} </div>
											</div>
										</template> <!-- End: hjroom-template -->
										<template v-else>
											<div class="swiper-slide" :data-itemid="item['id']">
											<template v-if="item['children'] && item['children'].length > 0">
														<template v-for="(subitem, subindex) in item['children']">
														<template v-if="subitem['templatename'] == 'hjimage'">
																	<template v-if="subitem['image'].src != ''">
																		<div class="gallery-container lazyload" :data-bg="subitem['image']['src']"></div>
																	</template>
																	<template v-else>
																		<div  class="gallery-container" style="background-image: url(data:image/png;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==);"></div>
																	</template>
																</template> <!-- End: item Image background -->         
															
														
														</template>
											</template>
												
												<div class="societypage-details-block aa">
													<template v-if="item['datetag'] && item['datetag'].value && item['datetag'].value != '' && item['property'].targetItems && item['property'].targetItems.length > 0" >
														<div class="event-calendar text-center date-tag" :data-tzid="item['property'].targetItems[0].tzid.value" :data-eventlocation="item['property'].targetItems[0].title.value" :data-eventstartdate="item['startdate'].value" :data-eventenddate="item['enddate'].value" v-html="item['datetag'].value"></div>
													</template>
													<template v-else>
														<div style="visibility: hidden; opacity: 0"></div>
													</template>
													<template v-if="item['templatename'] !='hjspace'">
														<template v-if="$jss.property() != null">
															<div class="icon-tags">
																<div class="fav-btn icon" :data-linkedid="item['id']" :data-containerid="uid"></div>
															</div>
														</template>
														<div class="icon-tags">
															<template v-if="item['sharealias']">
																<div style="display:none;">{{item['sharealias']}}</div>
															</template>
															<div class="share-btn icon" :data-title="item['title'] ? item['title'].value : ''" :data-linkedid="item['id']" :data-containerid="uid" :data-fbk="[item['detailpageurl'] ? item['detailpageurl'].value : item['sharealias'] ? item['sharealias'] : '']"  :data-copylink="[item['detailpageurl'] ? item['detailpageurl'].value : item['sharealias'] ? item['sharealias'] : '']"></div>
														</div>
														<div class="icon-tags">
															<template v-if="item['templatename']=='hjevent'">	
															<div class="calendar-btn icon" :data-linkedid="item['id']" :data-containerid="uid"></div>
															</template>
														</div>			
													</template>
												</div>
												<div class="container-fluid pl-0 pr-0 pb-0">
													<div class="row no-gutters justify-content-center pb-5">
														<div class="col-11">
														<template v-if="!$jss.property()">
														<template v-if="item['property'] && item['property']['children'] && item['property']['children'].length > 0 && item['property']['children'][0]['title']">
															<p class="event-location">{{ item['property']['children'][0]['title'] ? item['property']['children'][0]['title'].value : '' }}</p>
															</template>
														</template>
														<template v-else>
														<template v-if="item['address'] && item['address'].value != ''">
														<p class="event-location">{{ item['address'].value }}</p>
														</template>
														</template>
															<p class="event-title">{{ item['title'].value }}</p>
															<p class="event-description" v-html="item['description'].value"></p>
														</div>
													</div>
												</div>
												<div class="row no-gutters justify-content-center price-container">
													<div class="col-11 text-center">
														<div class="container-fluid pr-0 pl-0">
															<div class="row no-gutters justify-content-center">
															<template v-if="item['templatename']=='hjevent'">	
																<div class="col-6 text-center">
																	<!--<template v-if="item['price'].value == ''" >-->
																		<!-- <h5>{{getDictionaryPhrase('Member')}}: <br/><span>{{getDictionaryPhrase('By-quote')}}</span></h5> -->
																		<!--<h5>{{getDictionaryPhrase('Member')}}: <br/><span>&nbsp;</span></h5>
																	</template>	
																	<template v-else-if="item['price'].value == 0" >
																		<h5>{{getDictionaryPhrase('Member')}}: <br/><span>{{getDictionaryPhrase('Free')}}</span></h5>
																	</template>
																	<template v-else-if="item['price'].value < 0" >
																		<h5>{{getDictionaryPhrase('Member')}}: <br/><span>-</span></h5>
																	</template>-->
																	<template v-if="item['price'].value > 0">
																		<h5>{{getDictionaryPhrase('Member')}}: <br/><span>{{updatePriceString(item['price'].value)}} <small>{{curcurrency}}</small></span></h5>
																	</template>
																</div>
																<div class="col-6 text-center">
																	<!--<template v-if="item['memberprice'].value == ''" >-->
																		<!-- <h5>{{getDictionaryPhrase('NonMember')}}: <br/><span>{{getDictionaryPhrase('ByQuote')}}</span></h5> -->
																		<!--<h5>{{getDictionaryPhrase('NonMember')}}: <br/><span>&nbsp;</span></h5>
																	</template>	
																	<template v-else-if="item['memberprice'].value == 0" >
																		<h5>{{getDictionaryPhrase('NonMember')}}:<br/><span>{{getDictionaryPhrase('Free')}}</span></h5>
																	</template>
																	<template v-else-if="item['memberprice'].value < 0" >
																		<h5>{{getDictionaryPhrase('NonMember')}}:<br/><span>-</span></h5>
																	</template>-->
																	<template v-if="item['memberprice'].value > 0">
																		<h5>{{getDictionaryPhrase('NonMember')}}: <br/><span>{{updatePriceString(item['memberprice'].value)}} <small>{{curcurrency}}</small> </span></h5>																				
																		<!-- <h5>{{getDictionaryPhrase('NonMember')}}: <br/><span>{{updatePriceString(item['memberprice'].value)}} <small>{{localStorage.getItem('curcurrency') ? localStorage.getItem('curcurrency') : 'USD'}}</small> </span></h5>	 -->
																	</template>
																						
																</div>
															</template>

															<template v-else-if="item['templatename']=='hjworkout'">
																	<div class="col-6 text-center">
																	<!--<template v-if="item['price'].value == ''" >
																		<h5><span>{{getDictionaryPhrase('ByQuote')}}</span></h5>
																	</template>	
																	<template v-else-if="item['price'].value == 0">
																		<h5><span>{{getDictionaryPhrase('Free')}}</span></h5>
																	</template>	
																	<template v-else-if="item['price'].value < 0">
																		<h5><span>-</span></h5>
																	</template>-->
																	<template v-if="item['price'].value > 0">
																		<h5><span>{{updatePriceString(item['price'].value)}}<small>{{curcurrency}}</small></span></h5>
																	</template>
																	</div>
															</template>
																
																
															</div>
														</div>
														
													</div>
												</div>
												<template v-if="item['button'] && item['button'].value != '' && item['buttoncta'] && item['buttoncta'].value != ''">
													<a :href="item['buttoncta'].value" class="btn btn-primary">{{item['button'].value}}</a>
												</template>
												<div class="itemid" style="display:none">{{item['id']}} </div>
											</div>
										</template>
										</template>						
                                    </div>
                                    <!-- Add Pagination -->
                                    <div :class="'swiper-pagination_' + uid+' swiper-pagination'"></div>
                                </div>
                                <!-- Add Arrows -->
                                <div :class="'swiper-button-next '"></div>
                                <div :class="'swiper-button-prev '"></div>
								</template>
                    </div>
                </div>
			</div>
			</div>
		</div>			
		<div id="shareWindow" :class="uid+'_shareWindow shareWindows'">
			<div class="overlay"></div>
			<div class="share-window">
				<div class="addthis_inline_share_toolbox_k327"></div>
				<span data-toggle="tooltip" data-trigger="manual" title="copied" style="position:absolute;left:50%;bottom:5rem"></span>
				<input type="text" :id="uid+'_copy-link-text'" style="opacity:0; position: absolute; top: -1000px; left: -1000px;" readonly>
				<button type="button" class="btn btn-primary copy-link mt-3" :data-linkid="uid+'_copy-link-text'">copy link</button>
			</div>
		</div>
            
		</template>
		
		<template v-else>
			<div class="content">
				<template v-for="(item, index) in graphqlresult">
					<div class="contenttile" :data-itemid="item['id']">
						<div class="hero">
							<template v-if="item['children'] && item['children'].length > 0">
								<template v-for="(subitem, subindex) in item['children']">
									<template v-if="subitem['templatename'] == 'hjimage'">
										<template v-if="subitem['image'].src = ''">
											<img  class="lazyload" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" :data-src="subitem['image']['src']" alt="" />
										</template>
									</template>
								</template>
							</template>
						</div>
						<!--<div class="description">
						<div class="item-sections">
						{{ item['title'].value }}
						</div>
						<div class="actionbutton item-sections">
						<input class="book" type="button" :value="getDictionaryPhrase('Book')" />
						</div>
						</div>-->
						<div class="description">
					<div class="item-sections item-section-left">
						<div class="item-section-intro">
						<template v-if="item['property'] && item['property']['children'] && item['property']['children'].length > 0 && item['property']['children'][0]['title']">
							{{ item['property']['children'][0]['title'] }}
						</template>
						</div>
						<div class="item-section-title">{{ item['title'].value }}</div>
						<div class="item-section-desc">
							<template v-if="item['description'] && item['description'].value">
								{{ item['description'].value }}
							<!--This offer is available from 15 Jun 2019 through 30 Sep 2019. Breakfast included with food and drink credit of $25 per stay.-->
							</template>
						</div>
					</div>
					<div class="actionbutton item-sections item-section-right">
						<template v-if="item['price']">
							<template v-if="item['price'].value && item['price'].value > 0">
								<div class="pricing-tag">{{getDictionaryPhrase('From')}}<span class="price">{{updatePriceString(item["price"].value)}} </span> <span style="color:#343944;"> {{$jss.currency()}}</span> {{getDictionaryPhrase('PerNight')}} </div>
							</template>
							<template v-else-if="item['price'] > 0">
								<div class="pricing-tag">{{getDictionaryPhrase('From')}} <span class="price">{{updatePriceString(item["price"])}} </span> <span style="color:#343944;"> {{$jss.currency()}}</span> {{getDictionaryPhrase('PerNight')}} </div>
							</template>
						</template>
						<template v-if="item['cta'].value != ''">
							<input type="button" :value="getDictionaryPhrase('Reserve')" />
						</template>
					</div>
					</div>
						
						<div class="itemid" style="display:none">{{item['id']}} </div>
					</div>
				</template>
			</div> <!-- end:content-->
		</template> <!-- END: iscarousel -->
		</template>
	</div> <!-- end:HJContentBlockList -->
</template>

<script>
import { axios } from 'axios';
import ApolloClient from 'apollo-boost';
import gql from 'graphql-tag';
import { uuid } from 'vue-uuid';
import { 
getDictionaryPhrase,
updatePriceString,
getSSR, getKeys, graphqlToObject, getminpricerooms, _getminpricerooms, searchMoreContentByType, graphqlRemoveReserved, getContentByType, getObject, graphqlFetcherTestSSR, graphqlFetcher } from '../graphqlFetcher.js';
import { getminprice } from '../personalise.js';
import HJForm from './HJForm';
import HJContentBlockList from './HJContentBlockList';
import Vue from 'vue';
import config from '../temp/config';
import { serverBus } from '../event-bus.js';
import { getContent, getContentByProperty, loadmore } from '../HJContentBlockList.js';
import facebookicon from '../assets/img/svg/facebook.svg';
import instagramicon from '../assets/img/svg/instagram.svg';
import wechaticon from '../assets/img/svg/wechat.svg';
import weiboicon from '../assets/img/svg/weibo.svg';
import 'lazysizes';
// import a plugin
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import {bookhotel} from '../hjutil.js';


/*
const client = new ApolloClient({
  uri: config.sitecoreApiHost + '/api/' + config.jssAppName + '?sc_apikey=' + config.sitecoreSCApiKey,
  defaultOptions: {
    fetchPolicy: 'no-cache',
  },
});
*/

export default {
  name: 'HJContentBlockList',
  components: {
    HJForm,
    HJContentBlockList,
  },
  props: {
    fields: {
      type: Object,
      default: function(){},
    },
    rendering: {
      type: Object,
    },
    uid: {
      type: String,
      default: function(){return uuid.v1();},
    },
    currentcontext: {
      type: String,
      default: null,
    },	
    lastitemid: {
      type: String,
      default: null,
    },
  },
  data: function() {
    return {
      loadingQueriesCount: 0,
      error: null,
	  graphqlresult: [],
  	  hotelnames:[],
	  data_datasource: {},
	  haslink: true,
	  isrefresh: null,
	  contentCategory:"",
	  hideformobile: false,
	  facebookIcon: facebookicon,
	  instagramIcon: instagramicon,
	  wechatIcon: wechaticon,
	  weiboIcon: weiboicon,
	  hjsettings: null,
	  curcurrency: null,
	  isHomePage: window.location.pathname.length < 16 && window.outerWidth < 1026  ? false : true,
	 
    };
  },
  computed: {
	currency: function(){
		return this.$jss.currency();
	},
    datasource: function() {
	  if (this.currentcontext != null)
		return this.currentcontext;
	  else
		return this.fields && this.fields.data && this.fields.data.datasource;
    },
    contextItem: function() {
      return this.fields.data && this.fields.data.contextItem;
    },
	
	hotel_prices: function () {
		if (this.$jss.prices() != undefined && this.$jss.prices() != null )
			return this.$jss.prices(); 
		else
			return null;
	},
    templates: function() {
		if (this.$root.templates)
			return this.$root.templates; 
		else
		{
			return {};
		}
	},
    propertydata: function() {
	  if (this.datacontext != null)
	  {
		return this.datacontext;
	  } else {
			return {};
	  }
	},
  },
  watch: {
	currency : function(){
		this.getdata();
	}
  },
  methods: {
	getDictionaryPhrase: function(key)
	{
		return getDictionaryPhrase(key, this);
	},
	updatePriceString: function(price){
		return updatePriceString(price);
	},
    isproperty: function(name) {
		if (this.fields != null && this.fields[name] != undefined)
		{
			if (this.fields[name])
			{
				if (this.fields[name] && 
					this.fields[name].targetItems  && 
					this.fields[name].targetItems.length > 0)
					{
						if (this.fields[name].targetItems[0].textField)
							return this.fields[name].targetItems[0].textField.value;
						else if (this.fields[name].targetItems[0].title)
							return this.fields[name].targetItems[0].title.value;
						else
							return "";
					}
				else
				{
					if (this.fields[name].value)
						return this.fields[name].value;
					else
						return this.fields[name];
				}
			}
			else
				return "";
		}
		else if (this.datasource != null && this.datasource[name.toLowerCase()] != undefined)
		{
			if (this.datasource[name.toLowerCase()])
			{
				if (this.datasource[name.toLowerCase()] && 
					this.datasource[name.toLowerCase()].children  && 
					this.datasource[name.toLowerCase()].children.length > 0)
					{
						if (this.datasource[name.toLowerCase()].children[0].textField)
							return this.datasource[name.toLowerCase()].children[0].textField.value;
						else if (this.datasource[name.toLowerCase()].children[0].title)
							return this.datasource[name.toLowerCase()].children[0].title.value;
						else
							return "";
					}
				else
				{
					return this.datasource[name.toLowerCase()].value;
				}
			}
			else
				return "";
		}
		else
		{
			if (this.fields && this.fields.data && this.fields.data.datasource && this.fields.data.datasource.fields)
			{
				var datasource = graphqlToObject('', this.fields.data.datasource, this, '', null, false);
				if (datasource[name.toLowerCase()])
				{
					return datasource[name.toLowerCase()].value;
				}
				else
				{
					for (var i=0; i < this.fields.data.datasource.fields.length; i++)
					{
						if (this.fields.data.datasource.fields[i].name == name)
						{
							if (this.fields.data.datasource.fields[i].targetItems && 
								this.fields.data.datasource.fields[i].targetItems.length > 0) {
								if (this.fields.data.datasource.fields[i].targetItems[0].textField)
									return this.fields.data.datasource.fields[i].targetItems[0].textField.value;
								else if (this.fields.data.datasource.fields[i].targetItems[0].title)
									return this.fields.data.datasource.fields[i].targetItems[0].title.value;
								else
									return "";
							}
							else
							{
								return this.fields.data.datasource.fields[i].value;
							}
						}
					}
				}
			}
			else if (this.currentcontext)
			{
				var keys = getKeys(this.currentcontext);
				for (var i=0; i < keys.length; i++)
				{
					if (keys[i].toLowerCase() === name.toLowerCase())
					{
						if (this.currentcontext[keys[i].toLowerCase()].children && 
							this.currentcontext[keys[i].toLowerCase()].children.length > 0) {
							if (this.currentcontext[keys[i].toLowerCase()].children[0].textField)
								return this.currentcontext[keys[i].toLowerCase()].children[0].textField.value;
							else if (this.currentcontext[keys[i].toLowerCase()].children[0].title)
								return this.currentcontext[keys[i].toLowerCase()].children[0].title.value;
							else
								return "";
						}
						else
						{
							if (this.currentcontext[keys[i].toLowerCase()].value != undefined)
								return this.currentcontext[keys[i].toLowerCase()].value;
							else
								return this.currentcontext[keys[i].toLowerCase()];
						}
					}
				}

				if (this.currentcontext.fields)
				{
					for (var i=0; i < this.currentcontext.fields.length; i++)
					{
						if (this.currentcontext.fields[i].name.toLowerCase() === name.toLowerCase())
						{
							if (this.currentcontext.fields[i].targetItems && 
								this.currentcontext.fields[i].targetItems.length > 0) {
								if (this.currentcontext.fields[i].targetItems[0].textField)
									return this.currentcontext.fields[i].targetItems[0].textField.value;
								else if (this.currentcontext.fields[i].targetItems[0].title)
									return this.currentcontext.fields[i].targetItems[0].title.value;
								else
									return "";
							}
							else
							{
								if (this.currentcontext.fields[i].value != undefined)
									return this.currentcontext.fields[i].value;
								else
									return this.currentcontext.fields[i];
							}
						}
					}
				}
			}
		}
		var o = {};
		o.value = '';
		return o;
	},
	getdata: function(){
		let vm = this;	
		//if (vm.data && vm.data.datasource != null)
		//{
		//	graphqlRemoveReserved(vm.data.datasource);
		//}

		//Set currency
		var cur_currency = localStorage.getItem('curcurrency')
		var currentCurrency = ''

		if(cur_currency){
		vm.$jss.store.setCurrency(cur_currency);
		}else {
		vm.$jss.store.setCurrency('USD')
		}
		//Set currency END


		vm.hotelnames = vm.$jss.hotelnames();
		
		if (vm.datasource && vm.datasource["contentCategory"] && vm.datasource["contentCategory"].targetItems.length > 0  && vm.datasource["contentCategory"].targetItems[0].value && vm.datasource["contentCategory"].targetItems[0].value.value)
		{
			vm.contentCategory = vm.datasource["contentCategory"].targetItems[0].value.value.toLowerCase();
		}

		
		//var updateResults = function(data, _vm, ar, type, property) {
		vm.data_datasource = vm.currentcontext;
			//Set currency
			var cur_currency = localStorage.getItem('curcurrency')
			var currentCurrency = ''

			if(cur_currency){
			vm.$jss.store.setCurrency(cur_currency);
			}else {
			vm.$jss.store.setCurrency('USD')
			}
			//Set currency END

			if (vm.datasource["contentCategory"] != null && vm.datasource["contentCategory"].targetItems.length > 0)
			{
				vm.graphqlresult = [];
				for (var i =0; i < vm.datasource["contentCategory"].targetItems.length; i++)
				{
					if (vm.datasource["contentCategory"].targetItems[i].value.value.toLowerCase() == "hjroom-template")
					{
						searchMoreContentByType(null, "hjroom-template_minprices", vm, 
						function(data, _vm, ar, type, property, event, context, isloadmore, graphqlresults, searchresults, targetobject){
							// console.log(ar);
							var likes = {likes:[]};
							if (getCookie('HJ_GLOBAL_COOKIE') == null) {
								likes = {likes:[]};
							} else {
								var likes_json = getCookie('HJ_GLOBAL_COOKIE');			
								likes = JSON.parse(likes_json);
							}
							
							_vm.$jss.store.setMinprices(getminprice(null, _vm.hotel_prices, _vm.$jss.currency(), _vm.$jss.travellertype()));
							// get min price rooms
							var results = {};
							if (ar[0] && ar[0] != undefined && ar[0] != null)
							{
								results = getminpricerooms(_vm, _vm.hotel_prices, _vm.$jss.currency(), _vm.$jss.travellertype(), likes.likes, 15, ar[0]);
								//results = _getminpricerooms(_vm, _vm.hotel_prices, _vm.$jss.currency(), _vm.$jss.travellertype(), likes.likes, 15, ar[0]);
							}
							else
							{
								results = getminpricerooms(_vm, _vm.hotel_prices, _vm.$jss.currency(), _vm.$jss.travellertype(), likes.likes, 15, ar);
								//results = _getminpricerooms(_vm, _vm.hotel_prices, _vm.$jss.currency(), _vm.$jss.travellertype(), likes.likes, 15, ar);
							}
							if (_vm.graphqlresult === null)
								_vm.graphqlresult = results;
							else
								_vm.graphqlresult = _vm.graphqlresult.concat(results);
							//var s = getContent(_vm.graphqlresult, _vm, type, false, carouselobject, true);
							// console.log(results);
							//console.log(s);
							// END: get min price rooms						
						}, null, null, null, false, vm.$jss.travellertype(), null, "", null, null, null, vm.graphqlresult);
					}
					else
					{
						var pagesize = 6;
						if (vm.datasource["contentCategory"].targetItems[i].value.value.toLowerCase() == "hjevent")
						{
							pagesize = -1;
						}
						searchMoreContentByType(null, vm.datasource["contentCategory"].targetItems[i].value.value.toLowerCase(), vm, 
						function(data, _vm, ar, type, property, event, context, isloadmore, graphqlresults, searchresults, targetobject){
								//console.log(ar);
								if (_vm.datasource["showlikeonly"] && _vm.datasource["showlikeonly"] && _vm.datasource["showlikeonly"].value && _vm.datasource["showlikeonly"].value === '1' && (type.toLowerCase() == 'hjevent' || type.toLowerCase() == 'hjdealeat' || type.toLowerCase() == 'hjdealstay' || type.toLowerCase() == 'hjdealwedding'))
								{
									var results = getContentByType(ar, type.toLowerCase(), _vm, false, true, _vm.$jss.travellertype(), false, 6, _vm.$jss.property());
									if (_vm.graphqlresult === null)
										targetobject = [];
									addLikedOrClicked(results, _vm.graphqlresult);
								}
								else
								{
									var results = getContentByType(ar, type.toLowerCase(), _vm, false, true, _vm.$jss.travellertype(), false, 6, _vm.$jss.property());
									
									if (_vm.graphqlresult === null)
									{
										_vm.graphqlresult = results;
									}
									else
									{
										_vm.graphqlresult = _vm.graphqlresult.concat(results);
									}
										
								}
								//var s = getContent(_vm.graphqlresult, vm, type, false, carouselobject, true);
								//console.log(s);
							}, pagesize, null, vm.$jss.property(), false, vm.$jss.travellertype(), null, "", null, null, null, vm.graphqlresult);
					}
				}
			}
		//};

		//graphqlFetcher('', vm, '', null, '', null, updateResults, false, null, false, '', 6, vm.$jss.property(), null, false, vm.$jss.travellertype());		
	},
  },
  mounted: function() {
	//console.log(vm);
	if (this.$jss.hotels() != null)
	{
	if (true)	//	this code will be used for later lazy loading
	{
	let vm = this;	



	//Set currency
    var cur_currency = localStorage.getItem('curcurrency')
    var currentCurrency = ''

    if(cur_currency){
	  vm.$jss.store.setCurrency(cur_currency);
	  vm.curcurrency = cur_currency
    }else {
	  vm.$jss.store.setCurrency('USD')
	  vm.curcurrency = 'USD'
	}
	//Set currency END


	vm.hotelnames = vm.$jss.hotelnames();
	
    window.setTimeout(function() {
		vm.getdata();		
		if (vm.isproperty('iscarousel') == '1')
		{
			/*$('#' + vm.uid + '_owl-carousel').owlCarousel({ 
				loop:true, 
				margin:10, 
				nav:true,
				responsiveClass:true,
				responsive:{
					0:{
						items:1,
						nav:true
					},
					600:{
						items:3,
						nav:true
					},
					1000:{
						items:3,
						loop:true
					}
				}
			});*/
				if ($('#swiper_'+vm.uid)){
					if ($('#swiper_'+vm.uid).hasClass('swiper-container-initialized')){
						var prevswiper = document.querySelector('#swiper_'+vm.uid).swiper;
						if (prevswiper != undefined && prevswiper != null)
						{
							prevswiper.destroy();
						}
					}
					if($('#swiper_'+vm.uid).closest('.recommend-carousel').find('.swiper-slide').length > 0){
						var swiper = new Swiper('#swiper_'+vm.uid, {
							centerInsufficientSlides: true,
							slidesPerView: 1,
							spaceBetween: 25,
							loop: $('#swiper_'+vm.uid).closest('.recommend-carousel').find('.swiper-slide').length > 2 ? true : false,
							loopAdditionalSlides: 1,
							pagination: {
								el: '.swiper-pagination_'+vm.uid,
								clickable: true,
							},
							navigation: {
								nextEl: $('#swiper_'+vm.uid).closest('.recommend-carousel').find('.swiper-button-next'),
								prevEl: $('#swiper_'+vm.uid).closest('.recommend-carousel').find('.swiper-button-prev'),
							},
							breakpoints: {
								1025: {
									slidesPerView: 3,
									spaceBetween: 30,
									slidesPerGroup: 1,
								}
							},
						});	
					}
				}						

			 	
		}
	}, 1);
    //}, 300);
	}	
	}
	let addthisscript = document.createElement('script')
	addthisscript.setAttribute('src', '//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5da03251ade4f0e7')
	addthisscript.defer = true
	document.body.appendChild(addthisscript)
  },
  created: function() {
  },
  updated: function() {
  
  	let vm = this;
	//add simple support for background images:
	document.addEventListener('lazybeforeunveil', function(e){
		var bg = e.target.getAttribute('data-bg');
		if(bg){
			e.target.style.backgroundImage = 'url(' + bg + ')';
		}
	});

    var cur_currency = localStorage.getItem('curcurrency')
    var currentCurrency = ''

    if(cur_currency){
	  vm.$jss.store.setCurrency(cur_currency);
	  vm.curcurrency = cur_currency
    }else {
	  vm.$jss.store.setCurrency('USD')
	  vm.curcurrency = 'USD'
	}

    $(document).ready(function() {
		var likes = {likes:[]};
		if (getCookie('HJ_GLOBAL_COOKIE') == null) {
			likes = {likes:[]};
		} else {
			var likes_json = getCookie('HJ_GLOBAL_COOKIE');			
			likes = JSON.parse(likes_json);
			//console.log('get likes');
		}
		//console.log($('#' + vm.uid + ' .contenttile'));
		//$('#' + vm.uid + ' .contenttile').each(function(){ 
		$('#' + vm.uid + ' .swiper-slide').each(function(){ 
			var itemid = $(this).attr('data-itemid');
			if (likes.likes.length > 0)
			{
				for (var i = likes.likes.length-1; i >= 0; i--)
				{
					if (likes.likes[i]["id"] == itemid)
					{
						//console.log(likes.likes[i]["id"]);
						$(this).find('.fav-btn.icon').addClass('active');
					}
				}
			}
		});
		
		$('#' + vm.uid + ' .book').off('click').on('click', function(e) {
			var language = 'en'; 
			if ( vm.$jss.routeData().itemLanguage == 'zh-CN' || vm.$jss.routeData().itemLanguage == 'zh'){
				language = 'cn'; 
			}else{
				language = vm.$jss.routeData().itemLanguage.substring(vm.$jss.routeData().itemLanguage.length-2).toLowerCase()			
			} 
			var key = vm.$jss.hjconfig().encryption_key;
			var iv = vm.$jss.hjconfig().encryption_iv;
			var propertycode = "";
			var propertycityalias = "";
			var propertyhotelalias = "";
			var bookurl = vm.$jss.hjconfig().bookurl;
			if ($(this).attr('data-bookingurl').length > 0)
			{
				propertycode = $(this).attr('data-bookingurl').split('|')[2];
				propertycityalias = $(this).attr('data-bookingurl').split('|')[0];
				propertyhotelalias = $(this).attr('data-bookingurl').split('|')[1];
			}
			var bookurl = vm.$jss.hjconfig().book_url;
			
			bookhotel(key, iv, bookurl, propertycode, propertycityalias, propertyhotelalias, null, null, null, null, 'None', '', '', '', language);
		});
    });

	if (vm.isproperty('iscarousel') == '1')
	{

		/*$('#' + vm.uid + '_owl-carousel').owlCarousel('destroy');
		$('#' + vm.uid + '_owl-carousel').owlCarousel({ 
			loop:true, 
			margin:10, 
			nav:true,
			responsiveClass:true,
			responsive:{
				0:{
					items:1,
					nav:true
				},
				600:{
					items:3,
					nav:true
				},
				1000:{
					items:3,
					loop:true
				}
			}
		});*/

					if ($('#swiper_'+vm.uid)){
					if ($('#swiper_'+vm.uid).hasClass('swiper-container-initialized')){
						var prevswiper = document.querySelector('#swiper_'+vm.uid).swiper;
						if (prevswiper != undefined && prevswiper != null)
						{
							prevswiper.destroy();
						}
					}
					if($('#swiper_'+vm.uid).closest('.recommend-carousel').find('.swiper-slide').length > 0){
						var swiper = new Swiper('#swiper_'+vm.uid, {
							centerInsufficientSlides: true,							
							slidesPerView: 1,
							spaceBetween: 25,
							loop: $('#swiper_'+vm.uid).closest('.recommend-carousel').find('.swiper-slide').length > 2 ? true : false,
							loopAdditionalSlides: 1,
							pagination: {
								el: '.swiper-pagination_'+vm.uid,
								clickable: true,
							},
							navigation: {
								nextEl: $('#swiper_'+vm.uid).closest('.recommend-carousel').find('.swiper-button-next'),
								prevEl: $('#swiper_'+vm.uid).closest('.recommend-carousel').find('.swiper-button-prev'),
							},
							breakpoints: {
								1025: {
									slidesPerView: 3,
									spaceBetween: 25,
									slidesPerGroup: 1
								}
							},
						});		
					}
				}
				

	}
	
	$('.fav-btn').unbind('click').bind('click', function(){
		//var itemID = $(this).data('linkedid');
		//console.log(itemID);
		setLike(this);
	});
	
	$('.calendar-btn').off('click').on('click', function(){
					setClicked(this);
					//addEventToCalendar("subject", "description", "location", "12/1/2020", "12/6/2020");
					addEventToCalendar(this);
	});
	
	//Share Overlay
	if ( $('.HJContainer').attr('data-hjhref') &&  $('.HJContainer').attr('data-hjhref') != ''){
		var basepath = $('.HJContainer').attr('data-hjhref') + '/';
		}else{
		var basepath = './';
		}
	
	$('.share-btn').unbind('click').bind('click', function(){
		var containerID = $(this).attr('data-containerid');
		var shareUrl = $(this).attr('data-fbk');
		var newShareUrl = shareUrl;
		if(shareUrl.match(/active|workout|co-working-space|events/g)){
			newShareUrl = shareUrl.replace('active','workout').split("/")
			newShareUrl = basepath + newShareUrl[0];
		}
		var copylink = newShareUrl;
		var facebook = newShareUrl;
		addthis.update('share', 'url', newShareUrl);
		addthis.url = newShareUrl;                
		addthis.toolbox(".addthis_inline_share_toolbox_k327");
		var linktitle = $(this).attr('data-title')
		// var facebook = basepath + $(this).attr('data-fbk');
		// var instagram = basepath+ $(this).attr('data-ig');
		// var wechat = basepath + $(this).attr('data-wechat');
		// var weibo = basepath + $(this).attr('data-weibo');
		// var copylink = basepath + $(this).attr('data-copylink');
		
		$('.shareWindows').removeClass('active');
		$('.shareWindows .copy-link').prev().val(copylink);
		$('.'+containerID+'_shareWindow').addClass('active');
		$('.shareWindows .addthis_inline_share_toolbox_k327').attr('data-url', facebook);
		$('.shareWindows .addthis_inline_share_toolbox_k327').attr('data-title', linktitle);
		// $('.shareWindows .facebook-link').attr('data-sharelink', facebook);
		// $('.shareWindows .instagram-link').attr('data-sharelink', instagram);
		// $('.shareWindows .wechat-link').attr('data-sharelink', wechat);
		// $('.shareWindows .weibo-link').attr('data-sharelink', weibo);
		// $('.shareWindows #'+vm.uid+'_copy-link-text').val(copylink);
		
		
	});
	
	$('.shareWindows .icon-container').unbind('click').bind('click', function(){
		//console.log($(this).attr('data-sharelink'));
	}); 
	
	$('.shareWindows .overlay').unbind('click').bind('click', function(){
		$('.shareWindows').removeClass('active');
	});
	
	$('.shareWindows .facebook-link').unbind('click').bind('click',function(){
		var share_link = $(this).attr('data-sharelink');
		//console.log(vm.$jss.hjconfig())
		window.open(vm.$jss.hjconfig().facebook_share + share_link);
	});
	
	// $('.shareWindows .instagram-link').unbind('click').bind('click',function(){
	// 	var share_link = $(this).attr('data-sharelink');
	// 	window.open(vm.$jss.hjconfig().instagram_share + share_link);
	// });	
	
	$('.shareWindows .weibo-link').unbind('click').bind('click',function(){
		var share_link = $(this).attr('data-sharelink');
		window.open(vm.$jss.hjconfig().weibo_share + share_link);
	});
	
	$('.shareWindows .wechat-link').unbind('click').bind('click',function(){
		var share_link = $(this).attr('data-sharelink');
		window.open(vm.$jss.hjconfig().wechat_share.value + share_link);
	});
	
	//Copy link
	$('.copy-link').unbind('click').bind('click', function(){
			var copyid = $(this).attr('data-linkid');
			var copyText = document.getElementById(copyid);
			var tipAttr = $(this).prev().prev();
		  	copyText.select();
		 	// copyText[0].setSelectionRange(0, 99999); /*For mobile devices  */
			document.execCommand("copy");

			tipAttr.tooltip('show')
			setTimeout(function(){tipAttr.tooltip('hide')},2000)

		  //test for copy
		  //console.log('Copied text: ' + copyText.val());
	});

	$('.copy-link').focusout(function(){ 
		$('[data-toggle="tooltip"]').tooltip('hide');
	})
	
	
	if (this.isproperty('iscarousel') == '1'){
		$(window).resize(function(){
			 var vmID = vm.uid; 
			 var windowWidth = $(window).width();
             var windowHeight = $(window).height();
			 //console.log(vmID + ' Window Size: '+ windowWidth + ', ' +windowHeight);
		});
	
	}
  },
};
</script>

