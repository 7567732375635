const store = {
  // default state
  state: {
    sitecoreContext: {
      pageEditing: false,
    },
    routeData: null,
	_settings : null,
    currency: null,
    travellertype: 'other',
    hjcookie: null,
    hjgeocookie: null,
    prices: null,
    property: null,
	// for caching
	hotels: null,
	allcontent: null,
	services: null,
	constants: null,
	templates: null,
	settings: null,
	hjconfig: null,
	amenities: null,
	eventtypes: null,
	travellertypes: null,
	features: null,
	contentcategories: null,
	rooms: null,
	minprices: null,
	minpricesobject: null,
	hotelnames: null,
	locations: null,
	deals: null,
	hosts: null,
	nearby: null,
	society: null,
	ismobile: false,
	openingtimes: null,
	dictionary: null,
	allsociety: null
	
  },
  setSitecoreData(sitecoreData) {
    const route = sitecoreData.sitecore && sitecoreData.sitecore.route;
    const context = (sitecoreData.sitecore && sitecoreData.sitecore.context) || {};

    // Do not replace the original state object - the store and any components that use the store
    // need to share a reference to the same object in order for mutations to be observed.
    this.state.routeData = route;
    this.state.sitecoreContext = {
      ...context,
      routeName: route && route.name,
      itemId: route && route.itemId,
    };
	this.state._settings = sitecoreData.config;
    this.state.currency = null;
    this.state.hotel_prices = null;
    this.state.travellertype = 'other';
    this.state.hjcookie = '';
    this.state.hjgeocookie = '';
	this.state.hotels = null;
	this.state.allcontent = null;
	this.state.services = null;
	this.state.settings = null;
	//this.state.hjconfig = null;
	this.state.hjconfig = sitecoreData.config;
	this.state.constants = null;
	this.state.templates = null;
	this.state.eventtype = null;
	this.state.amenities = null;
	this.state.eventtypes = null;
	this.state.travellertypes = null;
	this.state.features = null;
	this.state.contentcategories = null;
	this.state.rooms = null;
	this.state.minprices = null;
	this.state.minpricesobject = null;
	this.state.hotelnames = null;
	this.state.locations = null;
	this.state.deals = null;
	this.state.hosts = null;
	this.state.nearby = null;
	this.state.society = null;
	this.state.ismobile = null;
	this.state.openingtimes = null;
	this.state.dictionary = null;
	this.state.allsociety = null;
  },
  setCurrency(currency) {
    this.state.currency = currency;
  },
  setTravellertype(travellertype) {
    this.state.travellertype = travellertype;
  },
  setHJCookie(hjcookie) {
    this.state.hjcookie = hjcookie;
  },
  setHJGeoCookie(hjgeocookie) {
    this.state.hjgeocookie = hjgeocookie;
  },
  setPrices(prices) {
    this.state.prices = prices;
  },
  setHotels(hotels) {
    this.state.hotels = hotels;
  },
  setAllContent(allcontent) {
    this.state.allcontent = allcontent;
  },
  setServices(services) {
    this.state.services = services;
  },
  setConstants(constants) {
    this.state.constants = constants;
  },
  setSettings(settings) {
    this.state.settings = settings;
  },
  set_Settings(settings) {
    this.state._settings = settings;
  },
  setHjconfig(hjconfig) {
    this.state.hjconfig = hjconfig;
  },
  setTemplates(templates) {
    this.state.templates = templates;
  },
  setEventtype(eventtype) {
    this.state.eventtype = eventtype;
  },
  setAmenities(amenities) {
    this.state.amenities = amenities;
  },
  setEventtypes(eventtypes) {
    this.state.eventtypes = eventtypes;
  },
  setTravellertypes(travellertypes) {
    this.state.travellertypes = travellertypes;
  },
  setFeatures(features) {
    this.state.features = features;
  },
  setContentCategories(contentcategories) {
    this.state.contentcategories = contentcategories;
  },
  setRooms(rooms) {
    this.state.rooms = rooms;
  },
  setMinprices(minprices) {
    this.state.minprices = minprices;
  },
  setMinpricesobject(minpricesobject) {
    this.state.minpricesobject = minpricesobject;
  },
  setHotelnames(hotelnames) {
    this.state.hotelnames = hotelnames;
  },
  setLocations(locations) {
    this.state.locations = locations;
  },
  setHosts(hosts) {
    this.state.hosts = hosts;
  },
  setDeals(deals) {
    this.state.deals = deals;
  },
  setNearby(nearby) {
    this.state.nearby = nearby;
  },
  setSociety(society) {
    this.state.society = society;
  },
  setIsmobile(ismobile) {
    this.state.ismobile = ismobile;
  },
  setOpeningTimes(openingtimes) {
    this.state.openingtimes = openingtimes;
  },
  setDictionary(dictionary) {
    this.state.dictionary = dictionary;
  },
  setAllsociety(allsociety) {
    this.state.allsociety = allsociety;
  },
};

// Vue plugins must export a function named 'install'
function install(Vue) {
  // "standard" convention for Vue plugins to ensure they are only installed once.
  if (install.installed) {
    return;
  }
  install.installed = true;

  Vue.prototype.$jss = {
    // there may be other JSS plugins installed, merge existing properties
    ...Vue.prototype.$jss,
    store,
    sitecoreContext() {
      // this is intended only as a convenience function for easier access to the current context.
      return store.state.sitecoreContext;
    },
    routeData() {
      // this is intended only as a convenience function for easier access to the current routeData.
      return store.state.routeData;
    },
    currency() {
      return store.state.currency;
    },
    travellertype() {
      return store.state.travellertype;
    },
    hjcookie() {
      return store.state.hjcookie;
    },
    hjgeocookie() {
      return store.state.hjgeocookie;
    },
    hotels() {
      return store.state.hotels;
    },
    rooms() {
      return store.state.rooms;
    },
    minprices() {
      return store.state.minprices;
    },
    minpricesobject() {
      return store.state.minpricesobject;
    },
    allcontent() {
      return store.state.allcontent;
    },
    services() {
      return store.state.services;
    },
    constants() {
		if (store.state.constants != null)
		{
			if (store.state.constants && store.state.constants.id && store.state.constants.id === undefined )
			{
				console.log(store.state.constants);
				store.state.constants = null;
			}
		}
		return store.state.constants;
    },
    settings(){
      return store.state.settings;
    },
    _settings(){
      return store.state._settings;
    },
    hjconfig(){
      return store.state.hjconfig;
    },
    templates() {
      return store.state.templates;
    },
    eventtype() {
      return store.state.eventtype;
    },
  amenities() {
	return store.state.amenities;
  },
  eventtypes() {
	return store.state.eventtypes;
  },
  travellertypes() {
	return store.state.travellertypes;
  },
  features() {
	return store.state.features;
  },
  contentcategories() {
	return store.state.contentcategories;
  },
    prices() {
      return store.state.prices;
    },
    locations() {
      return store.state.locations;
    },
    hotelnames() {
      return store.state.hotelnames;
    },
	deals() {
		return store.state.deals;
	},
	hosts() {
		return store.state.hosts;
	},
	nearby() {
		return store.state.nearby;
	},
	society() {
		return store.state.society;
	},
	ismobile() {
		return store.state.ismobile;
	},
	openingtimes() {
		return store.state.openingtimes;
	},
    property() {
      if (
        store.state.routeData &&
        store.state.routeData.fields &&
        store.state.routeData.fields.Property &&
        store.state.routeData.fields.Property.length > 0
      ) {
        if (store.state.routeData.fields.Property[0] && store.state.routeData.fields.Property[0].id)
          return store.state.routeData.fields.Property[0].id;
        else return null;
      } else return null;
    },
    dictionary() {
      return store.state.dictionary;
    },
    allsociety() {
      return store.state.allsociety;
    },
  };
}

export default { install };
