<template>
  <div>
    <p>HJTab Component</p>
    <sc-text :field="fields.heading" />
  </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import config from '../temp/config';
import { axios } from 'axios';
//import $ from 'jquery';
import ApolloClient from 'apollo-boost';
import gql from 'graphql-tag';
import { uuid } from 'vue-uuid';
import { graphqlRemoveReserved, graphqlFetcherTestSSR, graphqlFetcher } from '../graphqlFetcher.js';
import Vue from 'vue';
import { serverBus } from '../event-bus.js';

export default {
  name: 'HJTab',
  components: {
    ScText: Text,
  },
  props: {
    fields: {
      type: Object,
      default: function() {},
    },
    uid: {
      type: String,
      default: function() {
        return uuid.v1();
      },
    },
    currentcontext: {
      type: String,
      default: function() {},
    },
  },
  data: function() {
    return {
      loadingQueriesCount: 0,
      error: null,
    };
  },
  computed: {
    datasource: function() {
      return this.fields.data && this.fields.data.datasource;
    },
    contextItem: function() {
      return this.fields.data && this.fields.data.contextItem;
    },
  },
  methods: {},
  mounted: function() {
    if (this.fields.data && this.fields.data.datasource) {
      //graphqlRemoveReserved(this.fields.data.datasource);
    }
  },
  created: function() {},
  updated: function() {},
};
</script>
