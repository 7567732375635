<template>
  <div :id="this.uid" class="HJDataHotels">
  </div>
</template>

<script>
import Vue from 'vue';
import VueMeta from 'vue-meta';
import config from '../temp/config';
import { uuid } from 'vue-uuid';
import { graphqlRemoveReserved, graphqlFetcher } from '../graphqlFetcher.js';

export default {
  name: 'HJDataHotels',
  components: {},
  props: {
    fields: {
      type: Object,
      default: function() {},
    },
    uid: {
      type: String,
      default: function() {
        return uuid.v1();
      },
    },
  },
  data() {
    return {
      loadingQueriesCount: 0,
      error: null,
    };
  },
  computed: {
    hotels: function() {
      return this.fields.data && this.fields.data.datasource;
    },
  },
  mounted: function() {
    if (this.fields.data && this.fields.data.datasource) {
      graphqlRemoveReserved(this.fields.data.datasource);
    }
    this.$root.hotels = this.fields.data.datasource;
  },
  updated() {},
  methods: {},
};
</script>
