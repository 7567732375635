<template>
	<div :id="this.uid" :class="[data_datasource.layouttype.value == 'carousel'? 'mobile-hidden ' : ' ']+[data_datasource.layouttype.value == 'carousel2'? 'mobile-hidden ' : ' ']+[data_datasource.layouttype.value == 'parallaxBox'? 'section' : ''] + ' '+ [data_datasource.layouttype.value == 'banner3'? 'mt-0 mb-0' : ''] +' ' + [data_datasource.layouttype.value == 'banner4'? 'mt-0 mb-0' : ''] +' '+ [data_datasource.animationtype && data_datasource.animationtype.value ? data_datasource.animationtype.value : ''] +' HJContentBlock ' + [data_datasource['isdemo'] && data_datasource['isdemo'].value == '1'? 'DemoBlock': '']" 
	:data-propertyid="hoteldetail && hoteldetail['id'] ? hoteldetail['id'] :''" 
	:data-propertycode="hoteldetail && hoteldetail['hotelcode'] && hoteldetail['hotelcode'].value ? hoteldetail['hotelcode'].value : ''" 
	:data-propertycityalias="hoteldetail && hoteldetail['cityalias'] && hoteldetail['cityalias'].value ? hoteldetail['cityalias'].value : ''"  
	:data-propertyhotelalias="hoteldetail && hoteldetail['hotelalias'] && hoteldetail['hotelalias'].value ? hoteldetail['hotelalias'].value : ''"
	:data-ishomepage="isHomePage">

	<template v-if="isHomePage">
		
		<div :id="this.uid+'-top-border-right'" class="row no-gutters mt-3 pb-md-4 mb-4 hidden"> <!-- MRM removed padding -->
                <div class="col-7 line"></div>
                <div class="col-1">&nbsp;</div>
                <div class="col-4 line"></div>
        </div>
		
		<div :id="this.uid+'-top-border-left'" class="row no-gutters mt-3 pb-md-4 mb-4 hidden"> <!-- MRM removed padding -->
                <div class="col-4 line"></div>
                <div class="col-1">&nbsp;</div>
                <div class="col-7 line"></div>
        </div>
		
		<template v-if="data_datasource && data_datasource.anchorid && data_datasource.anchorid.value">
			<div style="visibility:hidden;height:1px;" :id="data_datasource.anchorid.value"></div>
		</template>
		<template v-if="data_datasource['isdemo'] && data_datasource['isdemo'].value == '1'">
			<div class="module_demo_text">
			<div class="module_demo_text">
				<div class="title">
				MODULE:
				</div>
				<div class="text">
				{{data_datasource['modulename'].value}}
				</div>
				<div class="title">
				APPEARS ON:
				</div>
				<div class="text">
				{{data_datasource['moduleappearson'].value}}
				</div>
				<div class="title">
				EFFECTS:
				</div>
				<div class="text">
				{{data_datasource['moduleeffects'].value}}
				</div>
				<template v-if="data_datasource['moduledescription'] && data_datasource['moduledescription'].value != ''">
					<div class="title">
					OTHER COMMENTS:
					</div>
					<div class="text">
					{{data_datasource['moduledescription'].value}}
					</div>
				</template>
			</div>
		</template>
	<!-- deal information -->
	<template v-if="data_datasource.layouttype.value == 'deal-information'">
        <div :id="uid + '_dealinformation'" :class="'components dealinformation '+ data_datasource.cssclass.value">
		<!--<h1>{{data_datasource.title.value}}</h1>
        <div :id="uid + '_dealinformation'" :class="'components dealinformation '+ data_datasource.cssclass.value">
		<template v-if="data_datasource.dealinformation.value" >
			<div v-html="data_datasource.dealinformation.value"></div>
		</template>
		</div>-->
		
		 <div class="container-fluid propertyslider-container slide-stay">
            
            <div class="row no-gutters justify-content-center">
                <div class="col-12 col-md-12 col-lg-9 col-xl-8">
                    <div class="swiper-container-full gallery-top slider-booking-top">
                        <!--<div class="swiper-wrapper">
                            <div class="swiper-slide">
                                <div class="gallery-container" style="background-image:url(OfferImages/offer1.jpg)"></div>
                                <div class="img-overlay2"></div>
                            </div>
                        </div>
                        <div class="swiper-pagination"></div>
                        <div class="swiper-button-next"></div>
                        <div class="swiper-button-prev"></div>
                    </div>-->
					
					 <div :class="'s_'+uid + '_swiper-container swiper-container-full gallery-top slider-booking-top'">
                        <div :class="'swiper-wrapper '+ [data_datasource.children.length == 1?'disabled':'']">
						<div :class="'swiper-slide '" v-for="(slide, index) in data_datasource.children" v-bind:key="index" v-if="slide">
							<template v-if="slide.templatename == 'hjimage' && slide.image && slide.image.src">
								<!--<div class="owl-imgbg lazyload" :data-bg="slide.image.src"></div>-->
									 <div class="gallery-container lazyload" :data-bg="slide.image.src"></div>
									<div class="img-overlay2"></div>														
							</template>
						</div>
                        </div>
                        <!-- Add Pagination -->
                        <div :class="'swiper-pagination ' + [data_datasource.children.length == 1?'disabled':'']"></div>
                        <div :class="'swiper-button-next ' + [data_datasource.children.length == 1?'disabled':'']"></div>
                        <div :class="'swiper-button-prev ' + [data_datasource.children.length == 1?'disabled':'']"></div>
                    </div>
                </div>
            </div>
            <div class="container-fluid pr-0 pl-0 slider-booking-overview">
                <div class="row no-gutters justify-content-center">
                    <div class="col-11 col-md-10 col-lg-9 col-xl-8">
                        <div class="container-fluid pl-0 pr-0 ">
                            <div class="row no-gutters">
                                <div class="col-11 col-md-8">
                                    <h4 class="mt-md-4" v-html="data_datasource.title.value"></h4>
                                    <p v-html="data_datasource.dealinformation.value"> </p>
                                </div>
                                <div class="col-12 col-md-4 text-center">
                                   <template v-if="data_datasource.buttontext.value">
									<a :href="[data_datasource.buttoncta == ''? '#':data_datasource.buttoncta.value]" class="btn btn-primary offer-book" style="margin-left:0;" :data-moduleid="uid"  :data-track-location-id="data_datasource.adobelocationid.value" :data-track-button-name="data_datasource.adobebuttonname.value">{{data_datasource.buttontext.value}}</a>			
									</template> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
	</template> <!-- END: deal information -->
	
	<!-- carousel -->
	<template v-if="data_datasource.layouttype.value == 'carousel'">      
		
		<div :class="'components carousel container-fluid swiper-main-container '+ data_datasource.cssclass.value" style="padding: 0;">
            <div class="row no-gutters">
			<template v-if="data_datasource.animationtype && data_datasource.animationtype.value == 'left'">
                <div class="col-7 stay-carousel">
                    <!-- Stays Swiper -->
                    <div :class="'s_'+uid + '_swiper-container stay-swiper-container '">
                        <div :class="'swiper-wrapper '+ [data_datasource.children.length == 1?'disabled':'']">
					<div :class="'swiper-slide '" v-for="(slide, index) in data_datasource.children" v-bind:key="index" v-if="slide">
						<template v-if="slide.templatename == 'hjimage' && slide.image && slide.image.src">
							<!--<div class="owl-imgbg lazyload" :data-bg="slide.image.src"></div>-->
								<div class="img-overlay">
										<div class="img-text-border">
											<h3 class="mb-0" v-html="slide.title.value"></h3>
											<div class="img-quote" v-html="slide.description.value"></div>
										</div>
									</div>
									<div class="img-block1 lazyload" :data-bg="slide.image.src"> </div>
							 <div class="img-details" v-if="slide && slide.subtitle" v-html="slide.subtitle.value"></div>
						</template>
					</div>
                        </div>
                        <!-- Add Pagination -->
                        <div :class="'swiper-pagination ' + [data_datasource.children.length == 1?'disabled':'']"></div>
                        <div :class="'swiper-button-next ' + [data_datasource.children.length == 1?'disabled':'']"></div>
                        <div :class="'swiper-button-prev ' + [data_datasource.children.length == 1?'disabled':'']"></div>
                    </div>
                </div>
                <div class="col-4 col-lg-3 stay-block">
                    <h3 class="mt-4 mb-4 pt-1">{{data_datasource.title.value}}</h3>
                    <p class="mb-5 pb-3" v-html="data_datasource.description.value"></p>
					<template v-if="data_datasource.buttontext.value">
					<a :href="[data_datasource.buttoncta == ''? '#':data_datasource.buttoncta.value]" class="btn btn-outline-light carousel-explore" :data-moduleid="uid"  :data-track-location-id="data_datasource.adobelocationid.value" :data-track-button-name="data_datasource.adobebuttonname.value">{{data_datasource.buttontext.value}}</a>
					<!--<button type="button" class="btn btn-outline-light" data-id="stay" id="explore">explore</button>-->
					</template>     
                </div>
				                <div class="col-1 col-lg-2">&nbsp;</div>
				</template>
				
				
				
				<template v-else>
				                        <div class="col-1 col-lg-2">&nbsp;</div>        
                <div class="col-4 col-lg-3 stay-block pl-0">
                    <h3 class="mt-4 mb-4 pt-1">{{data_datasource.title.value}}</h3>
                    <p class="mb-5 pb-3" v-html="data_datasource.description.value"></p>
					<template v-if="data_datasource.buttontext.value">
					<a :href="[data_datasource.buttoncta == ''? '#':data_datasource.buttoncta.value]" class="btn btn-outline-light carousel-explore" :data-moduleid="uid"  :data-track-location-id="data_datasource.adobelocationid.value" :data-track-button-name="data_datasource.adobebuttonname.value">{{data_datasource.buttontext.value}}</a>
					<!--<button type="button" class="btn btn-outline-light" data-id="stay" id="explore">explore</button>-->
					</template>     
                </div>
				
				<div class="col-7 stay-carousel">
                    <!-- Stays Swiper -->
                    <div :class="'s_'+uid + '_swiper-container stay-swiper-container '">
                        <div :class="'swiper-wrapper '+ [data_datasource.children.length == 1?'disabled':'']">
					<div :class="'swiper-slide '" v-for="(slide, index) in data_datasource.children" v-bind:key="index" v-if="slide">
						<template v-if="slide.templatename == 'hjimage' && slide.image && slide.image.src">
							<!--<div class="owl-imgbg lazyload" :data-bg="slide.image.src"></div>-->
								<div class="img-overlay">
										<div class="img-text-border">
											<h3 class="mb-0" v-html="slide.title.value"></h3>
											<div class="img-quote" v-html="slide.description.value"></div>
										</div>
									</div>
									<div class="img-block1 lazyload" :data-bg="slide.image.src"> </div>
							 <div class="img-details" v-if="slide && slide.subtitle" v-html="slide.subtitle.value"></div>
						</template>
					</div>
                        </div>
                        <!-- Add Pagination -->
                        <div :class="'swiper-pagination ' + [data_datasource.children.length == 1?'disabled':'']"></div>
                        <div :class="'swiper-button-next ' + [data_datasource.children.length == 1?'disabled':'']"></div>
                        <div :class="'swiper-button-prev ' + [data_datasource.children.length == 1?'disabled':'']"></div>
                    </div>
                </div>
				
				</template>
				

            </div>
        </div>
	</template> <!-- END: carousel -->
	
	<!-- carousel 2 -->
	<template v-if="data_datasource.layouttype.value == 'carousel2'">
		<div :class="'container-fluid swiper-main-container2 '+ data_datasource.cssclass.value" style="padding: 0;">
		    <div class="row mt-5 mb-5 pb-0 no-gutters justify-content-center">
                <div class="col-10 col-lg-10 col-xl-10">
                    <div class="container-fluid pl-0 pr-0">
						<div class="row no-gutters">
						<template v-if="data_datasource.animationtype && data_datasource.animationtype.value == 'left'">
							<div class="col-8 eat-carousel">
								<!-- Stays Swiper -->
								<div :class="'s_'+uid + '_swiper-container eat-swiper-container '">
									<div :class="'swiper-wrapper '+ [data_datasource.children.length == 1?'disabled':'']">
								<div :class="'swiper-slide '" v-for="(slide, index) in data_datasource.children" v-bind:key="index" v-if="slide">
									<template v-if="slide.templatename == 'hjimage' && slide.image && slide.image.src">
										<!--<div class="owl-imgbg lazyload" :data-bg="slide.image.src"></div>-->
											<div class="img-overlay">
													<div class="img-text-border">
														<h3 class="mb-0" v-html="slide.title.value"></h3>
														<div class="img-quote" v-html="slide.description.value"></div>
													</div>
												</div>
												<div class="img-block1 lazyload" :data-bg="slide.image.src"> </div>
										 <div class="img-details" v-if="slide && slide.subtitle" v-html="slide.subtitle.value"></div>
									</template>
								</div>
									</div>
									<!-- Add Pagination -->
									<div class="swiper-pagination"></div>
									<div :class="'swiper-button-next ' + [data_datasource.children.length == 1?'disabled':'']"></div>
									<div :class="'swiper-button-prev ' + [data_datasource.children.length == 1?'disabled':'']"></div>
								</div>
							</div>
							<div class="col-4 eat-block">
								<h3 class="mt-4 mb-4 pt-1">{{data_datasource.title.value}}</h3>
								<div v-html="data_datasource.description.value"></div>
								<template v-if="data_datasource.buttontext.value">
								<a :href="[data_datasource.buttoncta == ''? '#':data_datasource.buttoncta.value]" class="btn btn-outline-light carousel-explore" :data-moduleid="uid"  :data-track-location-id="data_datasource.adobelocationid.value" :data-track-button-name="data_datasource.adobebuttonname.value">{{data_datasource.buttontext.value}}</a>
								<!--<button type="button" class="btn btn-outline-light" data-id="stay" id="explore">explore</button>-->
								</template>     
							</div>
											
							</template>
							
							
							
							<template v-else>							    
								<div class="col-4 eat-block">
									<h3 class="mt-4 mb-4 pt-1">{{data_datasource.title.value}}</h3>
									<p class="mb-5 pb-3" v-html="data_datasource.description.value"></p>
									<template v-if="data_datasource.buttontext.value">
									<a :href="[data_datasource.buttoncta == ''? '#':data_datasource.buttoncta.value]" class="btn btn-outline-light carousel-explore" :data-moduleid="uid"  :data-track-location-id="data_datasource.adobelocationid.value" :data-track-button-name="data_datasource.adobebuttonname.value">{{data_datasource.buttontext.value}}</a>
									<!--<button type="button" class="btn btn-outline-light" data-id="stay" id="explore">explore</button>-->
									</template>     
								</div>
							
								<div class="col-8 eat-carousel">
									<!-- Stays Swiper -->
									<div :class="'s_'+uid + '_swiper-container eat-swiper-container '">
										<div :class="'swiper-wrapper '+ [data_datasource.children.length == 1?'disabled':'']">
									<div :class="'swiper-slide '" v-for="(slide, index) in data_datasource.children" v-bind:key="index" v-if="slide">
										<template v-if="slide.templatename == 'hjimage' && slide.image && slide.image.src">
											<!--<div class="owl-imgbg lazyload" :data-bg="slide.image.src"></div>-->
												<div class="img-overlay">
														<div class="img-text-border">
															<h3 class="mb-0" v-html="slide.title.value"></h3>
															<div class="img-quote" v-html="slide.description.value"></div>
														</div>
													</div>
													<div class="img-block1 lazyload" :data-bg="slide.image.src"> </div>
											 <div class="img-details" v-if="slide && slide.subtitle" v-html="slide.subtitle.value"></div>
										</template>
									</div>
										</div>
										<!-- Add Pagination -->
										<div class="swiper-pagination hidden"></div>
										<div :class="'swiper-button-next ' + [data_datasource.children.length == 1?'disabled':'']"></div>
										<div :class="'swiper-button-prev ' + [data_datasource.children.length == 1?'disabled':'']"></div>
									</div>
								</div>
							</template>
							

						</div>
					</div>
				</div>
			</div>
		
		</div>
	</template> <!-- END: carousel2 -->
	
	
	
	
	<!-- carousel only -->
	<template v-if="data_datasource.layouttype.value == 'carousel-only'">
        
		
		<div :class="'container-fluid ourstory-container jen_carousel pr-0 pl-0 '+ data_datasource.cssclass.value">
            <div class="row no-gutters justify-content-center">
                <div class="col-12 col-lg-10 col-xl-8">
                    <div class="container-fluid pr-0 pl-0">
					 <div class="container-fluid pr-0 pl-0">
                        <div class="row no-gutters justify-content-center">
                            <div class="col-12">
                                <!-- Swiper -->
                                <div class="ourstory-swiper-container">
                                    <div :class="'swiper-wrapper '+ [data_datasource.children.length == 1 ? 'disabled':'']">
									<template v-if="data_datasource.children && data_datasource.children.length > 0">
										<template v-for="(slide, index) in data_datasource.children">
											<template v-if="slide.templatename == 'hjimage' && slide.image && slide.image.src">
												<div class="swiper-slide">
													<div :class="'ourstory-img-block lazyload '"  :data-bg="slide.image.src"></div>
												</div>
											</template>
										</template>
									</template>
                                    </div>
                                    <!-- Add Pagination -->
                                    <div :class="'swiper-button-next '+ [data_datasource.children.length == 1 ? 'disabled':'']"></div>
                                    <div :class="'swiper-button-prev '+ [data_datasource.children.length == 1 ? 'disabled':'']"></div>
                                    <div :class="'swiper-pagination '+ [data_datasource.children.length == 1 ? 'disabled':'']"></div>
                                </div>
                            </div>
                        </div>
                    </div>
					<template v-if="data_datasource.title && data_datasource.title.value != ''">
					<div class="container-fluid pr-0 pl-0">
						<div class="row no-gutters justify-content-center">
							<div class="col-12 col-sm-8">
								<h3 class="story_text" :style="'background-image: url(' + textBorder + ')'" v-html="data_datasource.title.value">	</h3>
							</div>
						</div>
					</div>
					</template>
					<template v-if="data_datasource.subtitle && data_datasource.subtitle.value != ''">
                        <div class="row no-gutters justify-content-center mt-md-5 mb-5 mb-md-3">
                            <div class="col-11 col-xl-10">
                                <h3 class="ml-0 mt-4 mt-md-0 text-center-desktop" v-html="data_datasource.subtitle.value"></h3>
                                <p class="text-center-desktop pl-0" v-html="data_datasource.description.value"></p>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
	</template> <!-- carousel-only -->
	
	<!-- banner right -->
	<template v-else-if="data_datasource.layouttype.value == 'banner'">
		<div  :class="'row no-gutters justify-content-center propertycontentblock-row brand-society '+data_datasource.cssclass.value"">
				<div class="col-12 col-xl-6">
				<template v-if="data_datasource.children && data_datasource.children.length > 0">
				<template v-for="(slide, index) in data_datasource.children">
					<template v-if="slide.templatename == 'hjimage' && slide.image && slide.image.src">
						<div :class="'hero-container lazyload ' + [index > 0?'hidden':'']" :data-bg="slide.image.src"></div>
					</template>
				</template>
				</template>
                </div>
                <div class="col-12 col-xl-6 align-self-center">
                    <div class="block-container float-left">
                        <h3 v-html="data_datasource.title.value"></h3>
                        <p v-html="data_datasource.description.value"></p>
						<template v-if="data_datasource.buttontext.value">
							<a :href="[data_datasource.buttoncta == ''? '#':data_datasource.buttoncta.value]" class="btn btn-primary explore-more mb-5 carousel-explore" style="margin-left:0;" :data-moduleid="uid"  :data-track-location-id="data_datasource.adobelocationid.value" :data-track-button-name="data_datasource.adobebuttonname.value">{{data_datasource.buttontext.value}}</a>
						</template> 
                    </div>
                </div>
                
         </div>
	</template>
	<!-- End:banner Right -->
	
	<!-- banner left -->
	<template v-else-if="data_datasource.layouttype.value == 'banner2'">
	<div  :class="'row no-gutters justify-content-center propertycontentblock-row brand-society flex-column-reverse flex-xl-row '+data_datasource.cssclass.value"">
                <div class="col-12 col-xl-6 align-self-center">
                    <div class="block-container float-right">
                        <h3 v-html="data_datasource.title.value"></h3>
                        <p v-html="data_datasource.description.value"></p>
						<template v-if="data_datasource.buttontext.value">
							<a :href="[data_datasource.buttoncta == ''? '#':data_datasource.buttoncta.value]" class="btn btn-primary explore-more mb-5 carousel-explore"  style="margin-left:0;" :data-moduleid="uid"  :data-track-location-id="data_datasource.adobelocationid.value" :data-track-button-name="data_datasource.adobebuttonname.value">{{data_datasource.buttontext.value}}</a>			
						</template> 
                    </div>
                </div>
                <div class="col-12 col-xl-6">
				<template v-if="data_datasource.children && data_datasource.children.length > 0">
				<template v-for="(slide, index) in data_datasource.children">
					<template v-if="slide.templatename == 'hjimage' && slide.image && slide.image.src">
						<div :class="'hero-container lazyload ' + [index > 0?'hidden':'']" :data-bg="slide.image.src"></div>
					</template>
				</template>
				</template>
                </div>
         </div>
	
	</template>
	<!-- End:banner left -->
	
	<template v-else-if="data_datasource.layouttype.value == 'banner3'">
	<div :class="'container-fluid ourstory-container pr-0 pl-0 '+ data_datasource.cssclass.value">
            <div class="row no-gutters justify-content-center">
                <div class="col-12 col-lg-10 col-xl-8">
                    <div class="container-fluid pr-0 pl-0 mb-md-4 pb-md-2">
                        <div class="row no-gutters justify-content-center flex-column-reverse flex-xl-row">
                            <div class="col-11 col-xl-5 align-self-center">
								<h3 v-html="data_datasource.title.value"></h3>
                                <p v-html="data_datasource.description.value"></p>
                                <!-- <a href="#" class="btn btn-primary" style="display: block; opacity: 1;">BOOK</a> -->
                            </div>
                            <div class="col-1">&nbsp;</div>
                            <div class="col-12 col-xl-6">
							<template v-if="data_datasource.children && data_datasource.children.length > 0">
								<template v-for="(slide, index) in data_datasource.children">
									<template v-if="slide.templatename == 'hjimage' && slide.image && slide.image.src">
									<div :class="'jenstay_img lazyload ' + [index > 0?'hidden':'']" :data-bg="slide.image.src"></div>
									</template>
								</template>
							</template>                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	</template>
	
	
	<template v-else-if="data_datasource.layouttype.value == 'banner4'">
	<div :class="'container-fluid ourstory-container pr-0 pl-0 '+ data_datasource.cssclass.value">
            <div class="row no-gutters justify-content-center">
                <div class="col-12 col-lg-10 col-xl-8">
                    
                    <div class="container-fluid pr-0 pl-0">
                        <div class="row no-gutters justify-content-center">
                            <div class="col-12 col-xl-6">
							<template v-if="data_datasource.children && data_datasource.children.length > 0">
								<template v-for="(slide, index) in data_datasource.children">
									<template v-if="slide.templatename == 'hjimage' && slide.image && slide.image.src">
									<div :class="'jenstay_img2 lazyload ' + [index > 0?'hidden':'']" :data-bg="slide.image.src"></div>
									</template>
								</template>
							</template>                               
                            </div>
                            <div class="col-11 col-xl-6 align-self-center">
                                <h3 class="ml-0 ml-xl-5 mt-4 mt-xl-0" v-html="data_datasource.title.value"></h3>
                                <p class="ml-0 ml-xl-5" v-html="data_datasource.description.value"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div></template>
	
	<!-- Start of new Parallax Block -->
	<template v-if="data_datasource.layouttype.value == 'parallaxBox'">
	        <div class="container-fluid societybanner-container pr-0 pl-0">
            <div class="row no-gutters mt-5">
                <div class="col-12">
					<template v-if="data_datasource.children && data_datasource.children.length > 0">
						<template v-if= "data_datasource['children'][0].children && data_datasource['children'][0].children.length > 1"> 
							<div class="banner-block3 mobile-hidden column-banner-blocks" id="banner-block3" :style="'background-image: url('+data_datasource['children'][0]['children'][0].image.src+')'"></div>
							<div class="banner-block4 mobile-hidden column-banner-blocks" id="banner-block4" :style="'background-image: url('+data_datasource['children'][0]['children'][1].image.src+')'"></div>
						</template>
					</template>
					

					 <div class="container-fluid pr-0 pl-0">
					 
					 <template v-if="data_datasource.children && data_datasource.children.length > 0">
						<template v-for="(item, index) in data_datasource.children">
						<template v-if = "index > 0">
							
							
							<div class="row no-gutters justify-content-center text-center">
                            <!-- <div class="col-12 col-md-7 col-lg-6"> -->
							<div class="col-12 col-xl-6">
							<template v-if="item['children'] && item['children'].length > 1">
								<template v-if="item['children'][0].image && item['children'][0].image.src != ''">
                                <div class="banner-block1" :style="'background-image: url('+ item['children'][0].image.src +');position:relative;overflow:hidden;'" >
								<template v-if="item['children'][0].title && item['children'][0].title.value != ''">
									<div class="text-highlight title-highlight" style="position: absolute; bottom: 50%; left:50%;transform:translate(-50%,85%);z-index: 10;width:100%">
											<h3 class="society-property-name" style="color: white;font-family: TungstenMedium,TungstenMediumttf,TungstenMediumwoff,TungstenMediumeot, Arial, Helvetica, sans-serif;font-size: 5.5rem;text-transform:uppercase;z-index:10;font-size:4vw">{{ item['children'][0].title.value}}</h3>                          
									</div>
									<div class="black-overlay" style="background-color: black; opacity: 0.4; width: 1000px; height: 1000px; position: absolute; top:0;"></div>
								</template>
								</div>	
								</template>
									<img :src="jenSocietyLogo" class="banner-jensocietylogo" />
								<template v-if="item['children'][1].image && item['children'][1].image.src != ''">
									<div class="banner-block2" :style="'background-image: url('+ item['children'][1].image.src +');'" ></div>
								</template>
							</template>	
							
                                
								
								<div class="banner-block-text" data-aos="fade-in"  :data-aos-id="'block'+index">
									<h3 v-if="item['title'].value && item['title'].value != ''" v-html="item['title'].value" >​</h3>
                                    <p v-if="item['description'].value" class="society-text" v-html="item['description'].value"></p>
                                </div>
								<template v-if="item['image'] && item['image'].src != ''">
                                <div class="banner-block2" :style="'background-image: url('+ item['image'].src+')'">
                                    <!-- <div class="text-highlight">
                                        <h3>dj night</h3>
                                        <p>Rooftop Bar</p>
                                    </div> -->
                                </div>
								</template>
                            </div>
                        </div>
						
						</template>
						
						</template>
						
					</template>
					 
					 
                    </div>
               

				  </div>
            </div>
        </div>
	</template>
	<!-- End of new parallax block -->
	
	
	<!-- golden circle -->
	<template v-if="data_datasource.layouttype.value == 'goldenCircle'">
	        <div :class="'container-fluid goldencircle-container ' + data_datasource.cssclass.value" style="padding:0;">
            <div class="row no-gutters mt-5 pt-5 pb-5 mb-5">
                <div class="col-4 line"></div>
                <div class="col-1">&nbsp;</div>
                <div class="col-7 line"></div>
            </div>
            <div class="row no-gutters justify-content-center">
                <div class="col-12 col-lg-10 col-xl-8 text-center">
                    <div class="container-fluid pr-0 pl-0">
                        <div class="row no-gutters mb-4 pb-2">
                            <div class="col-6">
                                <div class="container-fluid pl-0 pr-0">
                                    <div class="row no-gutters">
                                        <div class="col-12 text-left">
                                            <h2 class="mb-3 pb-3"><img :src="GCIcon" class="logo mr-2" />{{data_datasource.title.value}}</h2>
                                          
											<div v-html="data_datasource.description.value"></div>
                                        </div>
                                    </div>
                                    <div class="row no-gutters">
                                        <div class="col-12 text-left">
											<template v-if="(data_datasource.children[1] && data_datasource.children[1] != '') && (data_datasource.children[2] && data_datasource.children[2] != '') && (data_datasource.children[3] && data_datasource.children[3] != '')">
                                            <p class="mb-0">
												<img :src="data_datasource.children[1].image.src" class="gc-card mr-1" /> {{data_datasource.children[1].title.value}} <span class="ml-3 mr-3">|</span>
                                                <img :src="data_datasource.children[2].image.src" class="gc-card mr-1" /> {{data_datasource.children[2].title.value}} <span class="ml-3 mr-3">|</span>
                                                <img :src="data_datasource.children[3].image.src" class="gc-card mr-1" /> {{data_datasource.children[3].title.value}}
											
                                            </p>
											</template>
											<template v-if="data_datasource.buttontext.value && data_datasource.buttontext.value !=''">
												<a :href="data_datasource.buttoncta" target="_blank" class="btn btn-primary mt-5 join-now-gc">{{data_datasource.buttontext.value}}</a>
											</template>
										</div>
                                    </div>
                                </div>
                            </div>                          
                            <div class="col-6 img-block lazyload" :data-bg="data_datasource.children[0].image.src">
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row no-gutters mt-5 pt-5 pb-5">
                <div class="col-7 line"></div>
                <div class="col-1">&nbsp;</div>
                <div class="col-4 line"></div>
            </div>
        </div>

	</template> <!-- END: golden circle -->


	<!-- Brand Text Overview -->
	<template v-if="data_datasource.layouttype.value == 'brandtext-overview'">
		<div class="container-fluid brandoverview-container mobile-hidden">
            <div class="row mt-4 mb-4 pt-3 pb-3 no-gutters justify-content-center" style="/* margin-left: 7rem; */">
                <div class="col-md-10 col-lg-10 col-xl-8">
                    <div class="container-fluid pl-0 pr-0">
                        <div class="row no-gutters justify-content-center">
                            
                            <div class="col-8 col-md-10 col-lg-8 col-xl-8 brandoverview">
                                <h3 style="max-width: 100%;padding-left: 4.7rem;font-size: 56pt;padding-top: 3rem;padding-bottom: 2rem;line-height: 4.5rem;letter-spacing: 0.09rem;" ><span v-html="data_datasource.title.value"></span><img :src="fullStopImg" class="full-stop"></h3>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
	</template>
	<!-- Brand Text Overview END-->


	<!-- grid1 and grid2 -->
	<template v-if="data_datasource.layouttype.value == 'grid1' || data_datasource.layouttype.value == 'grid2'">

		<div :class="'container-fluid destination-container ' + data_datasource.cssclass.value">
            <div class="row no-gutters justify-content-center">
                <div class="col-12 col-lg-8 col-xl-8 mt-1 pt-1 mb-1 pb-1">
                    <div class="container-fluid pl-0 pr-0">
                        <div class="row no-gutters mb-5 pb-4">
                            <div class="col-12 text-center">
                                <h3>{{data_datasource.title.value}}</h3>
                            </div>
                        </div>
                        <div class="row no-gutters">
                            <div class="col-12 destination-carousel">
                                <!-- Destination Swiper -->
                                <div class="destination-swiper-container">
								<div class="swiper-wrapper">
								<template v-for="(slidegroup, index) in slidegroups">	
									<template v-if="slidegroup.gridtype == 1">
                                            <div class="swiper-slide">
                                                <div class="container-fluid pr-0 pl-0">
                                                    <div class="row no-gutters content-block">
                                                        <div class="col-6">
                                                            <div class="container-fluid pr-0 pl-0">
                                                                <div class="row no-gutters two-row">
                                                                    <div class="col-12">
																	<template v-if="slidegroup.slidegroup[0] && slidegroup.slidegroup[0] != ''">
                                                                        <div :class="'img-container mr-auto s4' + [slidegroup.slidegroup[0].image.src == ''?'offer-tag':'']">
																			<template v-if="(slidegroup.slidegroup[0].title.value != '' || slidegroup.slidegroup[0].description.value !='<p></p>') && slidegroup.slidegroup[0].image != ''">
																			<a :href="[slidegroup.slidegroup[0].cta.value == ''? '':slidegroup.slidegroup[0].cta.value]">
                                                                            <div class="img-overlay">
																				<div class="text-container">
																					<h3 class="mb-0 text-center" v-html="slidegroup.slidegroup[0].title.value"></h3>
																					<div class="img-pricing mt-3 text-center" v-html="slidegroup.slidegroup[0].description.value"></div>
																				</div>
																			</div>
																			</a>
																			</template>
																			<template v-if="slidegroup.slidegroup[0].image && slidegroup.slidegroup[0].image !=''">
																			<div class="s1-block1 lazyload" :data-bg="slidegroup.slidegroup[0].image.src"></div>
																			</template>
																			<template v-if="slidegroup.slidegroup[0].image.src == ''">
																				<h3 class="mb-0 text-center" v-html="slidegroup.slidegroup[0].title.value" ></h3>
																				<button type="button" class="btn btn-outline-light">{{slidegroup.slidegroup[0].buttontext}}</button>
																			</template>
                                                                        </div>
																	</template>
                                                                    </div>
                                                                </div>
                                                                <div class="row no-gutters two-row">
                                                                    <div class="col-6">
																	<template v-if="slidegroup.slidegroup[1] && slidegroup.slidegroup[1] != ''">
                                                                        <div :class="'img-container2 mr-auto s6' + [slidegroup.slidegroup[1].image.src == ''?'offer-tag':'']">
																			<template v-if="(slidegroup.slidegroup[1].title.value != '' || slidegroup.slidegroup[1].description.value !='<p></p>') && slidegroup.slidegroup[1].image.src != ''">
																			<a :href="[slidegroup.slidegroup[1].cta.value == ''? '':slidegroup.slidegroup[1].cta.value]">
                                                                            <div class="img-overlay" style="left:0;">
																				<div class="text-container">
																					<h3 class="mb-0 text-center" v-html="slidegroup.slidegroup[1].title.value"></h3>
																					<div class="img-pricing mt-3 text-center" v-html="slidegroup.slidegroup[1].description.value">
																					</div>
																				</div>
                                                                            </div>
																			</a>
																			</template>
																			<template v-if="slidegroup.slidegroup[1].image.src && slidegroup.slidegroup[1].image.src !=''">
																			<div class="s1-block2 z-effect lazyload" :data-bg="slidegroup.slidegroup[1].image.src"></div>
																			</template>
																			<template v-if="slidegroup.slidegroup[1].image.src == ''">
																				<h3 class="mb-0 text-center" v-html="slidegroup.slidegroup[1].title.value" ></h3>
																				<button type="button" class="btn btn-outline-light">{{slidegroup.slidegroup[1].buttontext}}</button>
																			</template>
                                                                        </div>
																	</template>
                                                                    </div>
                                                                    <div class="col-6">
																	<template v-if="slidegroup.slidegroup[2] && slidegroup.slidegroup[2] != ''">
                                                                        <div :class="'img-container2 mr-auto ml-2 s7' + [slidegroup.slidegroup[2].image.src == ''?'offer-tag':'']">
																			<template v-if="(slidegroup.slidegroup[2].title.value != '' || slidegroup.slidegroup[2].description.value !='<p></p>') && slidegroup.slidegroup[2].image.src != ''">
																			<a :href="[slidegroup.slidegroup[2].cta.value == ''? '':slidegroup.slidegroup[2].cta.value]">
																				<div class="img-overlay">
																					<div class="text-container">
																						<h3 class="mb-0 text-center" v-html="slidegroup.slidegroup[2].title.value"></h3>
																						<div class="img-pricing mt-3 text-center" v-html="slidegroup.slidegroup[2].description.value"></div> 
																					</div>
																				</div>
																			</a>
																			</template>
																			<template v-if="slidegroup.slidegroup[2].image.src && slidegroup.slidegroup[2].image.src !=''">
																			<div class="s1-block3 lazyload" :data-bg="slidegroup.slidegroup[2].image.src"></div>
																			</template>
																			<template v-if="slidegroup.slidegroup[2].image.src == ''">
																				<h3 class="mb-0 text-center" v-html="slidegroup.slidegroup[2].title.value" ></h3>
																				<button type="button" class="btn btn-outline-light">{{slidegroup.slidegroup[2].buttontext}}</button>
																			</template>
                                                                        </div>
																	</template>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
														<template v-if="slidegroup.slidegroup[3] && slidegroup.slidegroup[3] != ''">
                                                            <div :class="'img-container ml-auto s5' + [slidegroup.slidegroup[3].image.src == ''?'offer-tag':'']" data-link="">
																<template v-if="(slidegroup.slidegroup[3].title.value != '' || slidegroup.slidegroup[3].description.value !='<p></p>') && slidegroup.slidegroup[3].image.src != ''">
																			<a :href="[slidegroup.slidegroup[3].cta.value == ''? '':slidegroup.slidegroup[3].cta.value]">
																				<div class="img-overlay">
																					<div class="text-container">
																					<h3 class="mb-0 text-center" v-html="slidegroup.slidegroup[3].title.value"></h3>
																					<div class="img-pricing mt-3 text-center" v-html="slidegroup.slidegroup[3].description.value">   </div>
																					</div>
																				</div>
																			</a>
																</template>
																<template v-if="slidegroup.slidegroup[3].image.src && slidegroup.slidegroup[3].image.src !=''">
																	<div class="s1-block4 z-effect lazyload" :data-bg="slidegroup.slidegroup[3].image.src"></div>
																</template>
																<template v-if="slidegroup.slidegroup[3].image.src == ''">
																	<h3 class="mb-0 text-center" v-html="slidegroup.slidegroup[3].title.value" style="margin-top: 50%;"></h3>
                                                                    <button type="button" class="btn btn-outline-light">{{slidegroup.slidegroup[3].buttontext}}</button>
																</template>
                                                            </div>
														</template>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div><!-- Swiper Slide 1 -->
											</template>
										<template v-if="slidegroup.gridtype == expired">
                                        <div class="swiper-slide">
                                            <div class="container-fluid pr-0 pl-0">
                                                <div class="row no-gutters content-block">
                                                    <div class="col-12">
                                                        <div class="container-fluid pr-0 pl-0">
                                                            <div class="row no-gutters two-row">
                                                                <div class="col-9">
																<template v-if="slidegroup.slidegroup[0] && slidegroup.slidegroup[0] != ''">
                                                                        <div :class="'img-container s2 mr-auto ' + [slidegroup.slidegroup[0].image.src == ''?'offer-tag':'']">
																			<template v-if="(slidegroup.slidegroup[0].title.value != '' || slidegroup.slidegroup[0].description.value !='<p></p>') && slidegroup.slidegroup[0].image.src != ''">
																			<a :href="[slidegroup.slidegroup[0].cta.value == ''? '':slidegroup.slidegroup[0].cta.value]">
                                                                            <div class="img-overlay">
                                                                                <h3 class="mb-0 text-center" v-html="slidegroup.slidegroup[0].title.value"></h3>
                                                                                <div class="img-pricing mt-3 text-center" v-html="slidegroup.slidegroup[0].description.value">
                                                                                </div>
                                                                            </div>
																			</a>
																			</template>
																			<template v-if="slidegroup.slidegroup[0].image.src && slidegroup.slidegroup[0].image.src !=''">
                                                                            <div class="s2-block1 lazyload" :data-bg="slidegroup.slidegroup[0].image.src"></div>
																			</template>
																			<template v-if="slidegroup.slidegroup[0].image.src == ''">
																				<h3 class="mb-0 text-center" v-html="slidegroup.slidegroup[0].title.value" ></h3>
																				<button type="button" class="btn btn-outline-light">{{slidegroup.slidegroup[0].buttontext}}</button>
																			</template>
                                                                        </div>
																	</template>
                                                                </div>
                                                                <div class="col-3">
																<template v-if="slidegroup.slidegroup[1] && slidegroup.slidegroup[1] != ''">
                                                                        <div :class="'img-container ml-auto ' + [slidegroup.slidegroup[1].image.src == ''?'offer-tag':'']">
																			<template v-if="(slidegroup.slidegroup[1].title.value != '' || slidegroup.slidegroup[1].description.value !='<p></p>') && slidegroup.slidegroup[1].image.src != ''">
																			<a :href="[slidegroup.slidegroup[1].cta.value == ''? '':slidegroup.slidegroup[1].cta.value]">
                                                                            <div class="img-overlay">
                                                                                <h3 class="mb-0 text-center" v-html="slidegroup.slidegroup[1].title.value"></h3>
                                                                                <div class="img-pricing mt-3 text-center" v-html="slidegroup.slidegroup[1].description.value">
                                                                                </div>
                                                                            </div>
																			</a>
																			</template>
																			<template v-if="slidegroup.slidegroup[1].image.src && slidegroup.slidegroup[1].image.src !=''">
                                                                            <div class="s2-block2 lazyload" :data-bg="slidegroup.slidegroup[1].image.src"></div>
																			</template>
																			<template v-if="slidegroup.slidegroup[1].image.src == ''">
																				<h3 class="mb-0 text-center" v-html="slidegroup.slidegroup[1].title.value" ></h3>
																				<button type="button" class="btn btn-outline-light">{{slidegroup.slidegroup[1].buttontext}}</button>
																			</template>
                                                                        </div>
																	</template>
                                                                </div>
                                                            </div>
                                                            <div class="row no-gutters two-row">
                                                                <div class="col-3">
																<template v-if="slidegroup.slidegroup[2] && slidegroup.slidegroup[2] != ''">
                                                                        <div :class="'img-container2 mr-auto ' + [slidegroup.slidegroup[2].image.src == ''?'offer-tag':'']">
																			<template v-if="(slidegroup.slidegroup[2].title.value != '' || slidegroup.slidegroup[2].description.value !='<p></p>') && slidegroup.slidegroup[2].image.src != ''">
																			<a :href="[slidegroup.slidegroup[2].cta.value == ''? '':slidegroup.slidegroup[2].cta.value]">
                                                                            <div class="img-overlay" style="margin-left: 0;">
                                                                                <h3 class="mb-0 text-center" v-html="slidegroup.slidegroup[2].title.value"></h3>
                                                                                <div class="img-pricing mt-3 text-center" v-html="slidegroup.slidegroup[2].description.value">
                                                                                </div>
                                                                            </div>
																			</a>
																			</template>
																			<template v-if="slidegroup.slidegroup[2].image.src && slidegroup.slidegroup[2].image.src !=''">
                                                                            <div class="s2-block2 z-effect lazyload" :data-bg="slidegroup.slidegroup[2].image.src"></div>
																			</template>
																			<template v-if="slidegroup.slidegroup[2].image.src == ''">
																				<h3 class="mb-0 text-center" v-html="slidegroup.slidegroup[2].title.value" ></h3>
																				<button type="button" class="btn btn-outline-light">{{slidegroup.slidegroup[2].buttontext}}</button>
																			</template>
                                                                        </div>
																	</template>
                                                                </div>
                                                                <div class="col-9">
																	<template v-if="slidegroup.slidegroup[3] && slidegroup.slidegroup[3] != ''">
                                                                        <div :class="'img-container2 s2 mr-auto ml-2 ' + [slidegroup.slidegroup[3].image.src == ''?'offer-tag':'']">
																			<template v-if="(slidegroup.slidegroup[3].title.value != '' || slidegroup.slidegroup[3].description.value !='<p></p>') && slidegroup.slidegroup[3].image.src != ''">
																			<a :href="[slidegroup.slidegroup[3].cta.value == ''? '':slidegroup.slidegroup[3].cta.value]">
                                                                            <div class="img-overlay" >
                                                                                <h3 class="mb-0 text-center" v-html="slidegroup.slidegroup[3].title.value"></h3>
                                                                                <div class="img-pricing mt-3 text-center" v-html="slidegroup.slidegroup[3].description.value">
                                                                                </div>
                                                                            </div>
																			</a>
																			</template>
																			<template v-if="slidegroup.slidegroup[3].image.src && slidegroup.slidegroup[3].image.src !=''">
                                                                            <div class="s2-block3 lazyload" :data-bg="slidegroup.slidegroup[3].image.src"></div>
																			</template>
																			<template v-if="slidegroup.slidegroup[3].image.src == ''">
																				<h3 class="mb-0 text-center" v-html="slidegroup.slidegroup[3].title.value" ></h3>
																				<button type="button" class="btn btn-outline-light">{{slidegroup.slidegroup[3].buttontext}}</button>
																			</template>
                                                                        </div>
																	</template>                                                                 
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> <!-- Swiper Slide 2-->
										</template> 
										<template v-if="slidegroup.gridtype == 2">
                                        <div class="swiper-slide">
                                            <div class="container-fluid pr-0 pl-0">
                                                <div class="row no-gutters content-block">
                                                    <div class="col-3">
														<template v-if="slidegroup.slidegroup[0] && slidegroup.slidegroup[0] != ''">
                                                               <div :class="'img-container s3 mr-auto ' + [slidegroup.slidegroup[0].image.src == ''?'offer-tag':'']">
																	<template v-if="(slidegroup.slidegroup[0].title.value != '' || slidegroup.slidegroup[0].description.value !='<p></p>') && slidegroup.slidegroup[0].image.src != ''">
																		<a :href="[slidegroup.slidegroup[0].cta.value == ''? '':slidegroup.slidegroup[0].cta.value]">
                                                                         <div class="img-overlay" style="margin-left: 0;">
																		 <div class="text-container">
                                                                          <h3 class="mb-0 text-center" v-html="slidegroup.slidegroup[0].title.value"></h3>
                                                                          <div class="img-pricing mt-3 text-center" v-html="slidegroup.slidegroup[0].description.value">
                                                                         </div>
																		 </div>
                                                                         </div>
																		 </a>
																	</template>
																	<template v-if="slidegroup.slidegroup[0].image.src && slidegroup.slidegroup[0].image.src !=''">
                                                                         <div class="s3-block1 lazyload" :data-bg="slidegroup.slidegroup[0].image.src"></div>
																	</template>
																	<template v-if="slidegroup.slidegroup[0].image.src == ''">
																		<h3 class="mb-0 text-center" v-html="slidegroup.slidegroup[0].title.value" ></h3>
																		<button type="button" class="btn btn-outline-light">{{slidegroup.slidegroup[0].buttontext}}</button>
																	</template>
                                                                 </div>
														</template>
                                                    </div>
                                                    <div class="col-9">
                                                        <div class="container-fluid pr-0 pl-0">
                                                            <div class="row no-gutters two-row">
                                                                <div class="col-8">
																	<template v-if="slidegroup.slidegroup[1] && slidegroup.slidegroup[1] != ''">
																		<div :class="'img-container2 s3 ml-auto mr-auto ml-2 ' + [slidegroup.slidegroup[1].image.src == ''?'offer-tag':'']">
																		<template v-if="(slidegroup.slidegroup[1].title.value != '' || slidegroup.slidegroup[1].description.value !='<p></p>') && slidegroup.slidegroup[1].image.src != ''">
																			<a :href="[slidegroup.slidegroup[1].cta.value == ''? '':slidegroup.slidegroup[1].cta.value]">
																			 <div class="img-overlay" style="margin-left: 0;">
																			 <div class="text-container">
																				<h3 class="mb-0 text-center" v-html="slidegroup.slidegroup[1].title.value"></h3>
																				<div class="img-pricing mt-3 text-center" v-html="slidegroup.slidegroup[1].description.value"></div>
																			 </div>
																			 </div>
																			 </a>
																		</template>
																		<template v-if="slidegroup.slidegroup[1].image.src && slidegroup.slidegroup[1].image.src !=''">
																			 <div class="s3-block2 z-effect lazyload" :data-bg="slidegroup.slidegroup[1].image.src"></div>
																		</template>
																		<template v-if="slidegroup.slidegroup[1].image.src == ''">
																			<h3 class="mb-0 text-center" v-html="slidegroup.slidegroup[1].title.value" ></h3>
																			<button type="button" class="btn btn-outline-light">{{slidegroup.slidegroup[1].buttontext}}</button>
																		</template>
																	 </div>
																	</template>
                                                                </div>
                                                                <div class="col-4">
																	<template v-if="slidegroup.slidegroup[2] && slidegroup.slidegroup[2] != ''">
																		<div :class="'img-container2 s3 ml-auto ' + [slidegroup.slidegroup[2].image.src == ''?'offer-tag':'']">
																		<template v-if="(slidegroup.slidegroup[2].title.value != '' || slidegroup.slidegroup[2].description.value !='<p></p>') && slidegroup.slidegroup[2].image.src != ''">
																			<a :href="[slidegroup.slidegroup[2].cta.value == ''? '':slidegroup.slidegroup[2].cta.value]">
																			 <div class="img-overlay" style="margin-left: 0;">
																			 <div class="text-container">
																			  <h3 class="mb-0 text-center" v-html="slidegroup.slidegroup[2].title.value"></h3>
																			  <div class="img-pricing mt-3 text-center" v-html="slidegroup.slidegroup[2].description.value">
																			 </div>
																			 </div>
																			 </div>
																			 </a>
																		</template>
																		<template v-if="slidegroup.slidegroup[2].image.src && slidegroup.slidegroup[2].image.src !=''">
																			 <div class="s3-block3 z-effect lazyload" :data-bg="slidegroup.slidegroup[2].image.src"></div>
																		</template>
																		<template v-if="slidegroup.slidegroup[2].image.src == ''">
																			<h3 class="mb-0 text-center" v-html="slidegroup.slidegroup[2].title.value" ></h3>
																			<button type="button" class="btn btn-outline-light">{{slidegroup.slidegroup[2].buttontext}}</button>
																		</template>
																	 </div>
																	</template>
                                                                </div>
                                                            </div>
                                                            <div class="row no-gutters two-row">
                                                                <div class="col-4">
																	<template v-if="slidegroup.slidegroup[3] && slidegroup.slidegroup[3] != ''">
																		<div :class="'img-container2 s3 ml-auto ' + [slidegroup.slidegroup[3].image.src == ''?'offer-tag':'']">
																		<template v-if="(slidegroup.slidegroup[3].title.value != '' || slidegroup.slidegroup[3].description.value !='<p></p>') && slidegroup.slidegroup[3].image.src != ''">
																			<a :href="[slidegroup.slidegroup[3].cta.value == ''? '':slidegroup.slidegroup[3].cta.value]">
																			 <div class="img-overlay" style="margin-left: 0;">
																			 <div class="text-container">
																			  <h3 class="mb-0 text-center" v-html="slidegroup.slidegroup[3].title.value"></h3>
																			  <div class="img-pricing mt-3 text-center" v-html="slidegroup.slidegroup[3].description.value">
																			 </div>
																			 </div>
																			 </div>
																			 </a>
																		</template>
																		<template v-if="slidegroup.slidegroup[3].image.src && slidegroup.slidegroup[3].image.src !=''">																			 <div class="s3-block4 z-effect lazyload" :data-bg="slidegroup.slidegroup[3].image.src"></div>
																		</template>
																		<template v-if="slidegroup.slidegroup[3].image.src == ''">
																			<h3 class="mb-0 text-center" v-html="slidegroup.slidegroup[3].title.value" ></h3>
																			<button type="button" class="btn btn-outline-light">{{slidegroup.slidegroup[3].buttontext}}</button>
																		</template>
																	 </div>
																	</template>
                                                                </div>
                                                                <div class="col-8">
																	<template v-if="slidegroup.slidegroup[4] && slidegroup.slidegroup[4] != ''">
																		<div :class="'img-container2 s3 ml-auto ml-2 ' + [slidegroup.slidegroup[4].image.src == ''?'offer-tag':'']">
																		<template v-if="(slidegroup.slidegroup[4].title.value != '' || slidegroup.slidegroup[4].description.value !='<p></p>') && slidegroup.slidegroup[4].image.src != ''">
																			<a :href="[slidegroup.slidegroup[4].cta.value == ''? '':slidegroup.slidegroup[4].cta.value]">
																			 <div class="img-overlay" style="margin-left: 0;">
																			 <div class="text-container">
																			  <h3 class="mb-0 text-center" v-html="slidegroup.slidegroup[4].title.value"></h3>
																			  <div class="img-pricing mt-3 text-center" v-html="slidegroup.slidegroup[4].description.value">
																			 </div>
																			 </div>
																			 </div>
																			 </a>
																		</template>
																		<template v-if="slidegroup.slidegroup[4].image.src && slidegroup.slidegroup[4].image.src !=''">																			 <div class="s3-block5 lazyload" :data-bg="slidegroup.slidegroup[4].image.src"></div>
																		</template>
																		<template v-if="slidegroup.slidegroup[4].image.src == ''">
																			<h3 class="mb-0 text-center" v-html="slidegroup.slidegroup[4].title.value" ></h3>
																			<button type="button" class="btn btn-outline-light">{{slidegroup.slidegroup[4].buttontext}}</button>
																		</template>
																	 </div>
																	</template>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
										</template>
									</template>
                                    </div> <!-- Swiper slide 3 -->

                                    <!-- Add Pagination -->
                                    <div class="swiper-pagination"></div>
                                </div>
                                <!-- Add Arrows -->
                                <div class="swiper-button-next"></div>
                                <div class="swiper-button-prev"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

	</template>
	<!-- END: grid1 and grid2 -->

	
	<!-- Accordion -->       
	<template v-else-if="data_datasource.layouttype.value == 'accordion'">

		<div :class="'components container-fluid society-container pr-0 pl-0 '+ data_datasource.cssclass.value" >
            <div class="row no-gutters pt-4 mb-4 pb-4 justify-content-center">
                <div class="col-12 col-lg-10 col-xl-8">
                    <div class="container-fluid pl-0 pr-0">
                        <div class="row no-gutters">
                            <div class="col-12 text-center">
                                <h1><img :src="jenSocietyLogo" class="jen-logo" /></h1>
                                <p class="mt-4"  v-html="data_datasource.description.value"></p>
								<template v-if="data_datasource.buttontext.value != ''">
									<p><br/><a :href="[data_datasource.buttoncta.value == ''? '#':data_datasource.buttoncta.value]" class="learnmore underlineCTA" :data-track-location-id="data_datasource.adobelocationid.value" :data-track-button-name="data_datasource.adobebuttonname.value">{{data_datasource.buttontext.value}}</a></p>
								</template>                                  
                              
                            </div>
                        </div>
						 <div class="row no-gutters mt-4 mb-4 society-content-box">
							<template v-for="(slide, index) in data_datasource.children">
							<template v-if="slide.image && slide.image.src">
								<!-- <div :class="'block'+(index+1)+' details-block ' + [index == 0?'event-details-block':'coworking-details-block']" :style="'background-image:url(' + slide.image.src + ');'"> -->
								<div :class="'block'+(index+1)+' details-block ' + [index == 0?'event-details-block':'coworking-details-block'] + ' lazyload'" :data-bg="slide.image.src">
									<div class="block-overlay"></div>
									<div :class="[index == 0?'event-details-box':'coworking-details-box']">
										<h3>{{slide.title.value}}</h3>
										
										<p class="" v-html="slide.description.value"></p>

										<template v-if="slide.button.value && slide.button.value != ''">
										<button type="button" :data-link="slide.buttoncta.value" class="btn btn-outline-light mt-3 accordion-link">{{slide.button.value}}</button>
										</template>
									</div>
									<div class="overlay"></div>
									<div class="placeholder">{{slide.title.value}}</div>			
								</div>
							</template>
							</template>
						</div>
			
                                     
						<div class="row no-gutters mt-5">
                            <div class="col-12 text-center">						               
								<template v-if="data_datasource.captionbutton && data_datasource.captionbutton.value != ''" >
								<a id="becomeamember" :href="[data_datasource.captionbuttoncta.value == ''? '#':data_datasource.captionbuttoncta.value]" class="accordion-cta btn btn-primary" :data-moduleid="uid"  :data-track-location-id="data_datasource.adobelocationid.value" :data-track-button-name="data_datasource.adobebuttonname.value">{{data_datasource.captionbutton.value}}</a>								
								</template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	</template>
	<!-- END accordion -->
	
	
	<!-- masthead -->       
	<template v-else-if="data_datasource.layouttype.value == 'masthead'">
		<template v-if="data_datasource.children && data_datasource.children.length > 0 && data_datasource.children[0].image && data_datasource.children[0].image.src">
			<div class="container-fluid pr-0 pl-0 ">
            <div class="row no-gutters justify-content-center">
                <div :class="data_datasource.cssclass.value">
                    <div class="banner-image banner-container lazyload"  :data-bg="data_datasource.children[0].image.src"></div>

                </div>
            </div>
        </div>
		
		</template>
	</template>
	<!-- END:masthead -->
	<!-- links -->       
	<template v-else-if="data_datasource.layouttype.value == 'links'">		
		 <div :class="'society-container society-level row no-gutters justify-content-center '+  data_datasource.cssclass.value ">
                <div class="col-12 col-lg-10 col-xl-9">
                    <div class="container-fluid pl-0 pr-0"  data-aos-id="societyblock">
                        <template v-if="data_datasource.title && data_datasource.title.value != ''">
						<div class="row no-gutters">
                            <div class="col-12 text-center">
                                <h3 class="mt-4 mb-2 mb-md-0">{{data_datasource.title.value}}</h3>
                                <!-- <p class="mt-4 mb-2 mb-md-0"></p> -->
                            </div>
                        </div>
						</template>
                        <div class="row no-gutters">
                            <div class="col-11 text-center" style="margin:0 auto;">
                                <div class="container-fluid pr-0 pl-0">
                                    <div class="row no-gutters societypage justify-content-center">
										<template v-for="(item, index) in data_datasource.children">
											<template v-if="item['templatename'] == 'hjlink'">
												 <!--<div :class="'col-6 mb-5 mb-md-0 ' + [data_datasource.children.length > 4?'col-md-2':'col-md-3']">-->
												 <div :class="'col-6 mb-5 mb-md-0 ' + [data_datasource.children.length > 5?'col-md-2':'col-md-3']">
												 <template v-if="item['image'] && item['image']['src']">
													<div :class="'icon-container lazyload ' + item['cssclass'].value " :data-bg="item['image']['src']" alt=""></div>
													</template>
													<h4 v-html="item['title'].value"></h4>
													<p v-html="item['description'].value"></p>
												</div>
											</template>
										</template>
									</div>							
                                    <div class="row no-gutters mt-0 mt-md-5">
                                        <div class="col-12 text-center">
										<template v-if="data_datasource.buttontext.value != ''">
											<div class="actionbutton">
												<!--<a :href="data_datasource.buttoncta" :data-track-location-id="data_datasource.adobelocationid.value" :data-track-button-name="data_datasource.adobebuttonname.value">
													{{data_datasource.buttoncta}}
												</a>-->
												<a id="becomeamember" :href="[data_datasource.buttoncta.value == ''? '#':data_datasource.buttoncta.value]" class="accordion-cta btn btn-primary" :data-moduleid="uid"  :data-track-location-id="data_datasource.adobelocationid.value" :data-track-button-name="data_datasource.adobebuttonname.value">{{data_datasource.buttontext.value}}</a>
												<!--<button type="button" class="btn btn-primary accordion-cta" id="becomeamember" :data-track-location-id="data_datasource.adobelocationid.value" :data-track-button-name="data_datasource.adobebuttonname.value">{{data_datasource.buttontext.value}}</button>-->
											</div>
										</template>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>	
            </div>
          
	</template>
	<!-- END: links -->
	<!-- text -->       
	<template v-else-if="data_datasource.layouttype.value == 'text'">
		<div class="container-fluid content-container pr-0 pl-0">
		<div :class="'row no-gutters justify-content-center '+ data_datasource.cssclass.value">
                <div class="col-11 col-md-10 col-lg-8 col-xl-6">
                    <h3 v-html="data_datasource.title.value"></h3>
                    <p  v-html="data_datasource.description.value"></p>
					<template v-if="data_datasource.buttontext.value">
						<div class="CTA-container">
						<a :href="[data_datasource.buttoncta.value == ''?'#':data_datasource.buttoncta.value]" :class="'textCTA btn btn-primary ' + [data_datasource.buttoncta.value == ''?'rfp-link':'']" :data-track-location-id="data_datasource.adobelocationid" :data-track-button-name="data_datasource.adobebuttonname.value" :data-moduleid="uid">{{data_datasource.buttontext.value}}</a>
						</div>
					</template>
                   <!-- <button type="button" class="btn btn-primary" onclick="handleRFPClick(this)">request a proposal</button> -->
                </div>
         </div>
		 </div>
	</template>
	<!-- END: text -->
	<!-- menupagelinks -->       
	<template v-else-if="data_datasource.layouttype.value == 'menupagelinks'">
		<div :id="uid + '_links'" :class="'components menupagelinks content_block_black '+ data_datasource.cssclass.value">
		<template v-for="(group, index) in links">
			<div class="links">
				<ul>
				<template v-for="(link, subindex) in group">
					<li :class="link['liclass']">
						<template v-if="Array.isArray(link)" >
							<template v-if="link.length > 0" >
								<template v-for="(subgroup, subsubindex) in link">
									<div class="subgroup">
										<a :href="subgroup['cta'].value" class="p-2 text-dark">
										{{subgroup['title'].value}}
										</a>
									</div>
								</template>
							</template>
						</template>
						<template v-else>
							<a :href="link['cta'].value">
							{{link['title'].value}}
							</a>
						</template>
					</li>
				</template>
				</ul>
			</div>
		</template>	
        </div>
	</template>
	<!-- end: menupagelinks -->
	<!-- Tabs with Carousels -->
    <template v-if="Tabs.length > 0 && data_datasource.layouttype.value == 'tabs' && (data_datasource.layouttype.value != 'parallaxBox' || data_datasource.layouttype.value != 'goldenCircle' || data_datasource.layouttype.value != 'carousel' || data_datasource.layouttype.value != 'carousel-only')">
        <!-- 1 tab only -->
        <template v-if="Tabs.length == 1">
            <div :id="uid + '_tabs'" :class="'components propertyslider-container slide-stay tabs '+ data_datasource.cssclass.value">
				
				<div class="row no-gutters justify-content-center propertyslider-row">
                <div class="col-11 col-md-10 col-lg-8 col-xl-6 text-center">
                    <h3 v-html="data_datasource.title.value"></h3>
                    <p style="max-width:80%;" class="mx-auto" v-html="data_datasource.description.value"> </p>
                </div>
            </div>
               
                <template v-if="Tabs[0] && Tabs[0].content && Tabs[0].content.length < 1">
                    <ul class="contentblock-tabs">
                        <li><a :href="'#' + uid + '_tabs-' + index" :class="'tab-' + index + ' main-tabs '+ [index == 0? 'active' : '']" v-for="(tab, index) in Tabs[0].content" :data-moduleid="uid">{{tab.title.value}}</a></li>
                    </ul>
                </template>
                <template v-else>
					 <div :class="'tabs_'+ uid + '_booking-thumbs row no-gutters justify-content-center booking-thumb-stay'">
						<div class="col-12 col-md-12 col-lg-7 col-xl-5">
							<!-- Swiper -->
							<div class="swiper-container-thumb gallery-thumbs slider-booking-thumbs">
								<div class="swiper-wrapper">
									<template v-for="(tab, index) in Tabs[0].content">
										<div  :class="[index == 0? 'active' : ''] + ' swiper-slide main-tabs tab-' + index " :data-tabcontent="'#' + uid + '_tabs-' + index"  :data-moduleid="uid" :data-slickindex="index">
											<div class="block-selection">
												<template v-if="tab.anchoralias">
													<input :id="tab.anchoralias" :name="tab.anchoralias" style="visibility:hidden" />
												</template>
												<input type="hidden" class="room-info" :value="tab.title.value" />
												<p>&nbsp;</p>
												<hr/>
												<h4>{{tab.title.value}}</h4>
											</div>                         
										</div>
									</template>
								</div>
								<div class="swiper-button-next"></div>
								<div class="swiper-button-prev"></div>
							</div>
						</div>
					</div> <!-- End: New Tabs (Swiper) -->
                </template>
                <template v-for="(tab, index) in Tabs[0].content">
                    <div :id="uid + '_tabs-' + index" :class="[index > 0 ? 'hidden' : '', 'tab-contents']">
						
						<!-- New Tabs Carousel (Swiper) -->
						 <div class="row no-gutters justify-content-center">
							<div class="col-12 col-md-12 col-lg-9 col-xl-8">
								<div :class="'tabs_' + uid +'_Tabslider_'+ index +' swiper-container-full gallery-top slider-booking-top'">
									<div class="swiper-wrapper">
									 <template v-for="(imageChild, index) in tab.children">
									 	<div class="swiper-slide">
											<template v-if="imageChild.image && imageChild.image.src">
												<div class="gallery-container lazyload" :data-bg="imageChild.image.src"></div>
											</template>
											<div class="img-heart jen40-like" data-roomtype="jen40"></div>
											<template v-if="imageChild.title && imageChild.title !=''">
											<div class="img-overlay showOverlay">
												<div class="img-text-border text-center" >
													<h3 class="mb-0" v-html="imageChild.title.value"></h3>
													<div class="img-quote" v-html="imageChild.description.value"></div>
												</div>
											</div>
											<div class="img-overlay2"></div>
											</template>
										</div>
									 </template>
				
									</div>
									<div class="swiper-pagination"></div>
									<div class="swiper-button-next"></div>
									<div class="swiper-button-prev"></div>
								</div>
							</div>
						</div>
						<!-- End: New Tabs Carousel (Swiper) -->
						<!-- New Overview (Need Dynamic Content)-->
						<div class="container-fluid pr-0 pl-0 slider-booking-overview">
								<div class="row no-gutters justify-content-center">
									<div class="col-11 col-md-10 col-lg-9 col-xl-8">
										<div class="container-fluid pl-0 pr-0 mobile-hidden">
											<div class="row no-gutters">
												<div class="col-6 align-self-end mt-4">
													<!-- march -->
													<template v-if ="tab.templatename == 'hjrestaurant'">
													<p class="mt-0">{{getDictionaryPhrase('RestaurantSubtitle')}}</p> <!-- Only have this section for Eat and fit -->
													</template>
													<h4>{{tab.title.value}}</h4>
													<template v-if="data_datasource.hasdetail.value == '1'">
													<a :id="uid+'-view-details-'+index.toString()" :data-uid="uid" :data-tabindex="index.toString()" class="view-details viewdetail">{{getDictionaryPhrase('ViewDetails')}} <img :src="arrowDownIcon" class="vd-arrow-down" /> </a>
													</template>
												</div>
												<template v-if="tab.templatename == 'hjroom-template'">
													<div class="col-6 text-right">
														<template v-if="tab.price != null && tab.price > 0">
															<h5>{{getDictionaryPhrase('From')}} <span>{{updatePriceString(tab.price)}} <small>{{$jss.currency()}}</small> <br/> </span>{{getDictionaryPhrase('PerNight')}}</h5>
														</template>
														<a class="book btn btn-primary mobile-hidden" :data-urlalias="tab['urlalias'].value" :data-roomtypecode="tab['roomtypecode'].value"  :data-rateplancode="tab['rateplancode'].value" :data-roomcode="tab['roomcode'].value" :data-bookingurl="tab['bookingurl']">{{getDictionaryPhrase('Book')}}</a>
													</div>
												</template>
												<template v-if="tab.templatename == 'hjrestaurant'">
													<div class="col-6 text-right">
														<template v-if="tab.bookingurl.value != ''">
															<a :href="[tab.bookingurl.value != ''?tab.bookingurl.value:'#']" class="btn btn-primary mobile-hidden detail-hidden reserve-button" target="_blank">{{getDictionaryPhrase('Reserve')}}</a>
														</template>
													</div>
												</template>
												
											</div>
										</div>
										<div class="container-fluid pl-0 pr-0 desktop-hidden">
											<div class="row no-gutters">
												<div class="col-12">
													<template v-if="tab.templatename == 'hjroom-template'">
													<p v-html="tab.subtitle.value">40 sqm in size</p>
													</template>
													<div class="container-fluid pr-0 pl-0">
														<div class="row no-gutters">
														
														<template v-if="tab.templatename == 'hjroom-template'">
															<div class="col-6">																
																<h4>{{tab.title.value}}</h4>
																<!-- march -->
																<template v-if="data_datasource.hasdetail.value == '1'">
																	<a :id="uid+'-view-details-'+index.toString()" :data-uid="uid" :data-tabindex="index.toString()" class="view-details viewdetail">{{getDictionaryPhrase('ViewDetails')}} <img :src="arrowDownIcon" class="vd-arrow-down" /> </a>
																</template>
															</div>
															
															<div class="col-6 text-right">
															<template v-if="tab.price != null && tab.price > 0">
																<h5>{{getDictionaryPhrase('From')}} <span>{{updatePriceString(tab.price)}} <small>{{$jss.currency()}}</small> <br/> </span>{{getDictionaryPhrase('PerNight')}}</h5>
															</template>		
															</div>
														</template>
														<template v-else>
															<div class="col-12">
																<template v-if ="tab.templatename == 'hjrestaurant'">
																	<p>{{getDictionaryPhrase('RestaurantSubtitle')}}</p><!-- Only have this section for Eat and fit -->
																</template>
																<h4>{{tab.title.value}}</h4>
																<!-- march -->
																<template v-if="data_datasource.hasdetail.value == '1'">
																	<a :id="uid+'-view-details-'+index.toString()" :data-uid="uid" :data-tabindex="index.toString()" class="view-details viewdetail">{{getDictionaryPhrase('ViewDetails')}} <img :src="arrowDownIcon" class="vd-arrow-down" /> </a>
																</template>
															</div>
														</template>

														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						<!-- End: New Overview (need dynamic content)-->


                        <div :id="uid + '_tabs-' + index.toString() + '_detail'" class="detail-content hidden">
                            <h-j-detail :currentcontext="tab" :hoteldetail="hoteldetail" />
                        </div>
						
                        
                    </div>
                </template>
        </template>
        <!-- END: one tab only -->
        <!-- Has Subtabs (more than 1 tab) -->
        <template v-else>
			<div :class="'container-fluid hostslider-container '+ data_datasource.cssclass.value">
            <div class="row no-gutters">
                <div class="col-12">
				<div :id="uid + '_tabs'" :class="'components tabs hasSubTabs propertyslider-container slide-stay'">
					<div class="tab-header">
						<h1 class="tab-header-title">{{data_datasource.title.value}}</h1>
						<p class="tab-header-desc">{{data_datasource.description.value}}</p>
					</div>
					<template v-if="Tabs.length > 0">
						<div class="row no-gutters justify-content-center">
								<div class="col-12">
									<ul class="nav justify-content-center hostTabs" style="width: 100%;">
									<template v-for="(tab, index) in Tabs">
										<li class="nav-item mr-3">
											<a :id="tab.title + '-tab'" :class="'tab-' + index + ' nav-link slider-menu-host main-tabs '+ [index == 0? 'active' : '']" href="#" :data-tabcontent="'#' + uid + '_tabs-' + index" :data-moduleid="uid" :data-maintab="index" >{{tab.title}}</a>
										</li>
									</template> 
									</ul>
								</div>
							</div>
					</template>
					<template v-else>
						<div :class="'tabs_'+ uid + '_booking-thumbs row no-gutters justify-content-center booking-thumb-stay'">
							<div class="col-12 col-md-12 col-lg-7 col-xl-5">
								<!-- Swiper -->
								<div class="swiper-container-thumb gallery-thumbs slider-booking-thumbs">
									<div class="swiper-wrapper">
										<template v-for="(tab, index) in Tabs[0].content">
											<div  :class="[index == 0? 'active' : ''] + ' swiper-slide main-tabs tab-' + index " :data-tabcontent="'#' + uid + '_tabs-' + index"  :data-moduleid="uid" :data-slickindex="index">
												<div class="block-selection">
													<template v-if="tab.anchoralias">
														<input :id="tab.anchoralias" :name="tab.anchoralias" style="visibility:hidden" />
													</template>
													<input type="hidden" class="room-info" :value="tab.title.value" />
													<p>&nbsp;</p>
													<hr/>
													<h4>{{tab.title.value}}</h4>
												</div>                         
											</div>
										</template>
									</div>
								<div class="swiper-button-next"></div>
								<div class="swiper-button-prev"></div>
							</div>
						</div>
						</div>

					</template>
					<template v-for="(tab, index) in Tabs">
						<div :id="uid + '_tabs-' + index" :class="[index > 0 ? 'hidden' : '', 'tab-contents']">
							
							 <div class="row no-gutters justify-content-center propertyslider-row">
								<div class="col-11 col-md-10 col-lg-8 col-xl-6 text-center">
									<h3 id="hostTitle">{{tab.title}}</h3>
									<p id="hostDesc" v-html="tab.description"></p>
								</div>
							</div>
							
							<template v-if="tab.content && tab.content.length > 0">
								<!--<template v-if="tab.content.length < 3">
								   <div class="carousel-tabs">
									   <ul class="contentblock-tabs">
											<li><a :href="'#' + uid + '_tabs-' + index + '_subtabs-' + subindex" :class="uid + '_tabs-' + index + '_subtab-' + subindex + ' main-subtabs '+[subindex == 0 ? 'active' : '']" v-for="(subtab, subindex) in tab.content" :data-moduleid="uid" :data-maintab="index" :data-mainsubtab="subindex">{{subtab.title.value}}</a></li>
										</ul>
									</div>
								</template>
								<template v-else>
									<div :class="uid + '_slick_subtab' + index + ' owl-carousel owl-theme carousel-tabs'">
										<div class="item" v-for="(subtab, subindex) in tab.content" :data-tabcontent="'#' + uid + '_subtabs-' + index">
											<div class="owl-content"><a :href="'#' + uid + '_tabs-' + index + '_subtabs-' + subindex" :class="uid + '_tabs-' + index + '_subtab-' + subindex + ' main-subtabs '+[subindex == 0 ? 'active' : '']" :data-moduleid="uid" :data-maintab="index" :data-mainsubtab="subindex">{{subtab.title.value}}</a></div>
										</div>
									</div>
								</template>-->
								<!-- New SubTabs Carousel -->
								<div :class="'subtabs_'+ uid + '_booking-thumb_'+ index +' row no-gutters justify-content-center booking-thumb-stay'">
									<div class="col-12 col-md-12 col-lg-7 col-xl-5">
										<!-- Swiper -->
										<div class="swiper-container-thumb gallery-thumbs slider-booking-thumbs">
											<div class="swiper-wrapper">
												<template v-for="(subtab, subindex) in tab.content">
													<div  :class="uid + '_tabs-' + index + '_subtab-' + subindex + ' swiper-slide main-subtabs '+[subindex == 0 ? 'active' : '']" :data-subtabcontent="'#' + uid + '_tabs-' + index + '_subtabs-' + subindex"  :data-moduleid="uid" :data-maintab="index" :data-mainsubtab="subindex">
														<div class="block-selection">
															<template v-if="subtab.anchoralias">
																<input :id="subtab.anchoralias" :name="subtab.anchoralias" style="visibility:hidden" />
															</template>
															<input type="hidden" class="room-info" :value="tab.title.value" />
															<p>&nbsp;</p>
															<hr/>
															<h4>{{subtab.title.value}}</h4>
														</div>                         
													</div>
												</template>
											</div>
										<div class="swiper-button-next"></div>
										<div class="swiper-button-prev"></div>
									</div>
								</div>
								</div>
								<!-- End: New SubTabs Carousel -->
								
								<template v-for="(subtab, subindex) in tab.content">
									<div :id="uid + '_tabs-' + index + '_subtabs-' + subindex" :class="[subindex > 0 ? 'hidden' : '', 'subtab-contents  row no-gutters justify-content-center']">
									   <div class="col-12 col-md-12 col-lg-9 col-xl-8">
										<div :class="'subtabs-' + uid +'_tab_'+index+'_Tabslider_' + subindex +' swiper-container-full gallery-top slider-booking-top'">
											<div class="swiper-wrapper">
												<template v-for="(imageChild, index) in subtab.children">
													<div  v-if="imageChild.templatename == 'hjimage'" class="swiper-slide">
														<template v-if="imageChild.image && imageChild.image.src">
															<div class="gallery-container lazyload" :data-bg="imageChild.image.src"></div>
														</template>
														<div class="img-overlay2"></div>
													</div>
												</template>
											</div>
											<div class="swiper-pagination"></div>
											<div class="swiper-button-next"></div>
											<div class="swiper-button-prev"></div>
										</div>
										
										<!-- New SubTabs Content Carousel -->
										
										
										<!-- End: New SubTabs Content Carousel -->
										
										<!-- End: SubTabs Content Carousel -->
										
										<!-- New Details Container -->
										<div class="container-fluid pr-0 pl-0 pb-4 slider-booking-overview">
											<div class="row no-gutters justify-content-center">
												<div class="col-11 col-md-12">
													<div class="container-fluid pl-0 pr-0 mobile-hidden">
														<div class="row no-gutters">
															<div class="col-6">
																<p id="roomspec">{{subtab.subtitle.value}}</p> <!-- new added field subtitle for HostCelebrate and HostMeet -->
																<h4 id="roomtitle">{{subtab.title.value}} </h4>
																<template v-if="data_datasource.hasdetail.value == '1'">
																	<a class="view-details viewsubdetail">{{getDictionaryPhrase('ViewDetail')}} <img :src="arrowDownIcon" class="vd-arrow-down" /> </a>
																</template>
															</div>
															<div class="col-6">
																<div class="contianer-fluid pr-0 pl-0">
																	<div class="row no-gutters justify-content-end room-counter">
																		<div class="col-2">
																			<h5>{{getDictionaryPhrase('Reception')}}</h5>
																			<p id="recep-num">
																			<template v-if="subtab['reception'] != null && subtab['reception'].value  > 0">
																				{{subtab['reception'].value}}
																			</template>
																			<template v-else>-</template>
																			</p>
																		</div>
																		<div class="col-2">
																			<h5>{{getDictionaryPhrase('Theater')}}</h5>
																			<p id="thea-num">
																			<template v-if="subtab['theatre'] != null && subtab['theatre'].value > 0">
																				{{subtab['theatre'].value}}
																			</template>
																			<template v-else>-</template>
																			</p>
																		</div>
																		<div class="col-2">
																			<h5>{{getDictionaryPhrase('Banquet')}}</h5>
																			<p id="banq-num">
																			<template v-if="subtab['banquet'] != null && subtab['banquet'].value  > 0">
																				{{subtab['banquet'].value}}
																			</template>
																			<template v-else>-</template></li>																	
																			</p>
																		</div>
																		<div class="col-2">
																			<h5>{{getDictionaryPhrase('Classroom')}}</h5>
																			<p id="class-num">
																			<template v-if="subtab['classroom'] != null && subtab['classroom'].value  > 0">
																				{{subtab['classroom'].value}}
																			</template>
																			<template v-else>-</template>
																			</p>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div class="container-fluid pl-0 pr-0 desktop-hidden">
														<div class="row no-gutters">
															<div class="col-12">
																<div class="container-fluid pr-0 pl-0">
																	<div class="row no-gutters">
																		<div class="col-12">
																			<h4 id="roomtitle-m">{{subtab.title.value}} </h4>																			
																		</div>
																		<div class="col-12">
																			<!--<p id="roomspec-m">L3, 27 X 14 sqm, 4.5m ceiling</p>-->
																			<p id="roomspec-m">{{subtab.subtitle.value}}</p> <!-- new added field subtitle for HostCelebrate and HostMeet -->
																		</div>
																		<div class="col-12">
																			<div class="contianer-fluid pr-0 pl-0">
																				<div class="row no-gutters justify-content-center room-counter">
																					<div class="col-3">
																						<h5>{{getDictionaryPhrase('Reception')}}</h5>
																						<p id="recep-num-m">
																						<template v-if="subtab['reception'] != null && subtab['reception'].value  > 0">
																							{{subtab['reception'].value}}
																						</template>
																						<template v-else>-</template>
																						</p>
																					</div>
																					<div class="col-3">
																						<h5>{{getDictionaryPhrase('Theater')}}</h5>
																						<p id="thea-num-m">
																						<template v-if="subtab['theatre'] != null && subtab['theatre'].value > 0">
																							{{subtab['theatre'].value}}
																						</template>
																						<template v-else>-</template>
																						</p>
																					</div>
																					<div class="col-3">
																						<h5>{{getDictionaryPhrase('Banquet')}}</h5>
																						<p id="banq-num-m">
																						<template v-if="subtab['banquet'] != null && subtab['banquet'].value  > 0">
																							{{subtab['banquet'].value}}
																						</template>
																						<template v-else>-</template></li>																				
																						</p>
																					</div>
																					<div class="col-3">
																						<h5>{{getDictionaryPhrase('Classroom')}}</h5>
																						<p id="class-num-m">
																						<template v-if="subtab['classroom'] != null && subtab['classroom'].value  > 0">
																							{{subtab['classroom'].value}}
																						</template>
																						<template v-else>-</template>
																						</p>
																					</div>
																				</div>
																			</div>
																		</div>
																		<template v-if="data_datasource.hasdetail.value == '1'">
																			<a class="view-details viewsubdetail">{{getDictionaryPhrase('ViewDetail')}} <img :src="arrowDownIcon" class="vd-arrow-down" /> </a>
																			</template>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									<!-- End: New Details container -->

										
										<div :id="uid + '_tabs-' + index + '_subtabs-' + subindex.toString() + '_detail'" class="detail-content pb-4 hidden">
											<h-j-detail :currentcontext="subtab" :moduleid="uid" :hoteldetail="hoteldetail" />
										</div>
										
										</div>
									</div> <!-- End: SubTabs Content -->
								</template>
								 
								 <!-- Service Section -->
								 <template v-if="tab.services && tab.services.length > 0">
									<!-- New Service Container -->
									
									    <div :id="uid + '_tabs-' + index.toString() + '_services'" :class="'services-container-' + uid +'-tab-'+ index.toString() + ' container-fluid propertyslider-container slide-eat services-container'">
											<div class="row no-gutters justify-content-center propertyslider-row">
												<div class="col-11 col-md-10 col-lg-8 col-xl-6 text-center">
													<h3>{{getDictionaryPhrase('Service')}}</h3>
												</div>
											</div>
											<div class="row no-gutters justify-content-center">
												<div class="col-12 col-md-12 col-lg-9 col-xl-8 services-swiper-control">
													<div class="swiper-container-full gallery-top slider-eat-top services-swiper-container services-slider" style="overflow: hidden;">
														<div :class="'swiper-wrapper '">
														<template v-for="(item, index) in tab.services">
															<div class="swiper-slide" :data-itemid="item['id']">
																<template v-if="item['children'] && item['children'].length > 0">
																	<template v-for="(subitem, subindex) in item['children']">
																		<template v-if="subitem['templatename'] == 'hjimage'">
																			<template v-if="subitem['image'] && subitem['image'].src">
																				<div :class= "[subindex == 0?'':'hidden'] +' gallery-container lazyload'" :data-bg="subitem['image']['src']"></div>
																				<div class="overlay"> </div>
																			</template>
																		</template>
																	</template>
																</template>
																<h4>{{ item['title'].value }}</h4>
																<p class="text-expand" v-html="item['description'].value"></p>
																<!--<a class="view-details view-details-services">view more <img :src="arrowDownIcon" class="vd-arrow-down" /> </a>
																<a class="view-less view-details-services" style="display: none;">view less <img :src="arrowUpIcon" class="vd-arrow-up" /> </a>-->
															</div>
														</template>
														</div>
													</div>
													<div class="swiper-pagination"></div>
													<div class="swiper-button-next"></div>
													<div class="swiper-button-prev"></div>
												</div>
											</div>
											<div class="row no-gutters justify-content-center mt-2 pt-5">
												<div class="col-12 text-center">
													<!--<a href="#" class="btn request-proposal rfp-form-popup" :data-moduleid="uid">{{getDictionaryPhrase('RequestAProposal')}}</a>-->
													<!-- <a href="#" class="btn request-proposal rfp-trigger-cta" :data-moduleid="uid">{{getDictionaryPhrase('RequestAProposal')}}</a> -->
													<a href="#" class="btn request-proposal rfp-link" :data-moduleid="uid">{{getDictionaryPhrase('RequestAProposal')}}</a>
												</div>
											</div>
										</div>
									<!-- End: New Service Conainer -->
								</template> <!-- End: Service Section -->
							</template>
						</div>
					</template>
				</div>
				</div>
			</div>
			</div> <!-- End: Subtabs Wrapper -->
        </template>
        <!-- has subtabs (more than 1 tab) -->
    </template> <!-- End of Tabs -->
	<!-- gallery -->
	<template v-else-if="data_datasource.layouttype.value == 'gallery'">
		<div :id="uid + '_tabs'" :class="'components tabs '+ data_datasource.cssclass.value">
				<div class="tab-header">
						<h1>{{data_datasource.title.value}}</h1>
						<p class="tab-desc" v-html="data_datasource.description.value"></p>
				</div>
		
		<div class="hidden">
				<template v-if="Tabs.length == 1">
					<div :class="uid + '_slick owl-carousel owl-theme carousel-tabs gallery-tabs-container'">
						<div class="item" v-for="(tab, index) in Tabs[0].content" :data-tabcontent="'#' + uid + '_tabs-' + index">
						<div class="owl-content"><a :href="uid + '_gallery_' + index" :class="uid +'_tab-' + index + ' gallery-tabs '+ [index == 0? 'active' : '']" :data-moduleid="uid" :data-slickindex="index">{{tab.title.value}}</a></div>	
						</div>
					</div>
					
					<div :id="uid + '_gallery'" class="slick_gallery">
						<div :class="uid + '_slick_gallery owl-carousel owl-theme'">	
						<template v-for="(tab, index) in Tabs[0].content">
								<template v-if="tab.children.length > 0">
								<template v-for="(imageChild, childindex) in tab.children">
								<div v-if="imageChild.templatename == 'hjimage'" :class="[childindex == 0? uid +'_gallery_'+index + ' tab-slide' : ''] + ' ' + [childindex == (tab.children.length - 1)? 'lasttab-slide': ''] + ' items ' + [imageChild.title.value != ''? 'hovereffect' : '']" :data-moduleid="uid" :data-maintab="index">
								<template v-if="imageChild.image && imageChild.image.src" >
								<div class="owl-imgbg-tab lazyload" :data-bg="imageChild.image.src"></div>
								 </template>
								 <div v-if="imageChild.title.value" class="slick-content">
									<div class="slick-content-title title-frame">{{imageChild.title.value}}</div>
									<div class="slick-content-desc" v-html="imageChild.description.value"></div>				
								</div>
								<div v-if="imageChild.subtitle && imageChild.subtitle.value" class="slick-footer">{{imageChild.subtitle.value}}</div>
								</div>
								</template>
								</template>
						</template>
						</div>
					</div>
				</template>
				
				<template v-else>
					<div :class="uid + '_slick owl-carousel owl-theme carousel-tabs gallery-tabs-container'">
						<div class="item" v-for="(tab, index) in Tabs" :data-tabcontent="'#' + uid + '_tabs-' + index">
						<div class="owl-content"><a :href="uid + '_gallery_' + index" :class="uid +'_tab-' + index + ' gallery-tabs '+ [index == 0? 'active' : '']" :data-moduleid="uid" :data-slickindex="index">{{tab.title}}</a></div>	
						</div>
					</div>
					
					<div :id="uid + '_gallery'" class="slick_gallery slick_gallery_multitabs">
						<div :class="uid + '_slick_gallery owl-carousel owl-theme'">	
						<template v-for="(tab, index) in Tabs">
								<template v-if="tab.content && tab.content.length > 0">
										<template v-for="(subtab, subindex) in tab.content">
											<template v-if="subtab.children && subtab.children.length > 0">
											<template v-for="(imageChild, childindex) in limited(subtab.children,3)">
											<!--<div v-if="imageChild.templatename == 'hjimage'" :class="[childindex == 0? uid +'_gallery_'+index : ''] + ' items ' + [imageChild.title.value != ''? 'hovereffect' : '']">-->
											<div v-if="imageChild.templatename == 'hjimage'" :class="[childindex == 0? uid +'_gallery_'+index + ' tab-slide' : ''] + ' ' + [childindex == (tab.content.length - 1)? 'lasttab-slide': ''] + ' items ' + [imageChild.title.value != ''? 'hovereffect' : '']" :data-moduleid="uid" :data-maintab="index">
											<template v-if="imageChild.image && imageChild.image.src" >
											<div class="owl-imgbg-tab lazyload" :data-bg="imageChild.image.src"></div>
											 </template>
											 <div v-if="imageChild.title.value" class="slick-content">
												<div class="slick-content-title title-frame">{{imageChild.title.value}}</div>
												<div class="slick-content-desc" v-html="imageChild.description.value"></div>				
											</div>
											<div v-if="imageChild.subtitle && imageChild.subtitle.value" class="slick-footer">{{imageChild.subtitle.value}}</div>
											</div>
										</template>
										</template>
									</template>
								</template>
						</template>
						</div>
					</div>				
				</template>
				
		</div>
		</div>
	</template>
	<!-- END: gallery -->
	<!-- parallax box -->
	<!--<template v-if = "data_datasource.layouttype.value == 'parallaxBox' ">
		<div class="parallax-columns"  data-0="transform:translate(0,0%);opacity:1"  data-110p-end="transform:translate(0,0%);opacity:1" data-100p-end="transform:translate(0,-100%);opacity:0" >
	<img data-180p="opacity:1" data-200p="opacity:0" src='http://placekitten.com/800/600?image=1' alt='placeholder-image1' style="width: 300px; height: 100%; position:absolute; left:0;">
	<img data-180p="opacity:1" data-200p="opacity:0" src='http://placekitten.com/800/600?image=3' alt='placeholder-image2' style="width: 300px; height: 100%; position:absolute; right:0;">
	<img data-220p="opacity:0" data-240p="opacity:1" src='http://placekitten.com/800/600?image=3' alt='placeholder-image3' style="width: 300px; height: 100%; position:absolute; left:0;opacity:0;">
	<img data-220p="opacity:0" data-240p="opacity:1" src='http://placekitten.com/800/600?image=1' alt='placeholder-image4' style="width: 300px; height: 100%; position:absolute; right:0;opacity:0;">
	</div>
	
	<div data-0="transform:translate(0,0%);" data-100p="transform:translate(0,-100%);"></div>
	
	<div data-0="transform:translate(0,100%);" data-100p="transform:translate(0,0%); top:0;" data-_box-100p="" data-_box-350p="transform:translate(0,-100%)">
		<div style="position:absolute; width: 100%; height: 50%; text-align:center;top: 25%">
		<div class="box" style="display:inline-block;" data-100p="width:50%;height:100%;" data-_box-100p="width:100%;" data-_box-120p="width:100%;"  data-_box-150p="width:50%;"></div>
		</div>
	</div>

	<div data-_box-150p="transform:translate(0,100%);" data-_box-200p="transform:translate(0,0%);" data-_box-300p="transform:translate(0,-100%);"></div>
	</template>-->	
	<!-- END: parallax box -->
	
	<template v-if="data_datasource.layouttype.value == 'carousel' || data_datasource.layouttype.value == 'carousel2'">
		<div :class="uid + '_popup-container popup-container'" >
			<!-- Explore modal--> 
		<div id="explore-modal" style="opacity:1; z-index:13;">
            <div class="explore-modal mobile-hidden">
                <div class="container-fluid pr-5 pl-5 pt-5 pb-5">
                    <div class="row no-gutters"><div class="col-12"><h1>{{getDictionaryPhrase('ChooseHotels')}}</h1></div></div>
					<div class="row no-gutters">
					<template v-if="locations && locations.length > 0">
						 <template v-for="(location, index) in locations">
							<template v-if="location.children && location.children.length > 0"> 
								<template v-for="(hotelname, subindex) in location['children']">
									<template v-if="!(hotelname['isdisabled'] && hotelname['isdisabled'] == '1')">
										 <div class="col-3">
											 <template v-if="data_datasource && data_datasource.anchorid && data_datasource.anchorid.value">
												<a class="popup-cta" :href="[hotelname['cta'] == ''? '#' : hotelname['cta'] + '/' + data_datasource.anchorid.value]">
												<h3 class="mt-2 property-select" :data-id="hotelname['id']" data-section="events-block" v-html="hotelname['titlehtml']"></h3>
												<p>{{location['title'].value}}</p>
												</a>
											</template>
											<template v-else>
												<a class="popup-cta" :href="[hotelname['cta'] == ''? '#' : hotelname['cta']]">
												<h3 class="mt-2 property-select" :data-id="hotelname['id']" data-section="events-block" v-html="hotelname['titlehtml']"></h3>
												<p>{{location['title'].value}}</p>
												</a>
											</template>	
										</div>
									</template>
								</template>												
							</template> 
						</template>					
					</template>
					</div>
                    
                </div>
                <img :src="closeBlackIcon" class="close-btn" :data-moduleid="uid"/>
            </div>
			<div class="explore-modal-m active desktop-hidden" style="position: absolute; width: 100%; height: 100%;background-color: white;padding: 2rem;">
			        <img :src="closeBlackIcon" class="close-btn-e-m" :data-moduleid="uid"/>
                        <ul class="plist properties">	
							<template v-if="locations && locations.length > 0">
								<template v-for="(location, index) in locations">
									<template v-if="location.children && location.children.length > 0"> 
										<template v-for="(hotelname, subindex) in location['children']">
										<template v-if="!(hotelname['isdisabled'] && hotelname['isdisabled'] == '1')">
											<template v-if="data_datasource && data_datasource.anchorid && data_datasource.anchorid.value">
													<li class="property-select" data-hotel="beijing" :data-id="hotelname['id']" data-section="events-block"><a class="popup-cta" :href="[hotelname['cta'] == ''? '#' : hotelname['cta']+ '/' + data_datasource.anchorid.value]"><div v-html="hotelname['titlehtml']"></div><span>{{location['title'].value}}</span></a></li>
											</template>
											<template v-else>
													<li class="property-select" data-hotel="beijing" :data-id="hotelname['id']" data-section="events-block"><a class="popup-cta" :href="[hotelname['cta'] == ''? '#' : hotelname['cta']]"><div v-html="hotelname['titlehtml']"></div><span>{{location['title'].value}}</span></a></li>
											</template>
										</template>
										</template>												
									</template> 
								</template>					
							</template>
                        </ul>
            </div>
		</div>
		</div> 
	</template>
	
	<template v-if="data_datasource.layouttype.value == 'banner' || data_datasource.layouttype.value == 'banner2' || data_datasource.layouttype.value == 'banner3' || data_datasource.layouttype.value == 'banner4'">
		<div :class="uid + '_popup-container popup-container'" >
			<!-- Explore modal--> 
			<template v-if="!$jss.property()">
			<div id="explore-modal" style="opacity:1; z-index:13;">
				<div class="explore-modal mobile-hidden">
					<div class="container-fluid pr-5 pl-5 pt-5 pb-5">
						<div class="row no-gutters"><div class="col-12"><h1>{{getDictionaryPhrase('ChooseHotels')}}</h1></div></div>
						<div class="row no-gutters">
						<template v-if="locations && locations.length > 0">
							 <template v-for="(location, index) in locations">
								<template v-if="location.children && location.children.length > 0"> 
									<template v-for="(hotelname, subindex) in location['children']">
										<template v-if="!(hotelname['isdisabled'] &&  hotelname['isdisabled'] == '1')">
										 <div class="col-3">
											 <template v-if="data_datasource && data_datasource.anchorid && data_datasource.anchorid.value">
												<a class="popup-cta" :href="[hotelname['cta'] == ''? '#' : hotelname['cta'] + '/society/' + data_datasource.anchorid.value]">
												<h3 class="mt-2 property-select" :data-id="hotelname['id']" data-section="events-block" v-html="hotelname['titlehtml']"></h3>
												<p>{{location['title'].value}}</p>
												</a>
											</template>
											<template v-else>
												<a class="popup-cta" :href="[hotelname['cta'] == ''? '#' : hotelname['cta']]">
												<h3 class="mt-2 property-select" :data-id="hotelname['id']" data-section="events-block" v-html="hotelname['titlehtml']"></h3>
												<p>{{location['title'].value}}</p>
												</a>
											</template>	
										</div>
										</template>
									</template>												
								</template> 
							</template>					
						</template>
						</div>
						
					</div>
					<img :src="closeBlackIcon" class="close-btn" :data-moduleid="uid"/>
				</div>
				<div class="explore-modal-m active desktop-hidden" style="position: absolute; width: 100%; height: 100%;background-color: white;padding: 2rem;">
						<img :src="closeBlackIcon" class="close-btn-e-m" :data-moduleid="uid"/>
							<ul class="plist properties">	
								<template v-if="locations && locations.length > 0">
									<template v-for="(location, index) in locations">
										<template v-if="location.children && location.children.length > 0"> 
											<template v-for="(hotelname, subindex) in location['children']">
											<template v-if="!(hotelname['isdisabled'] && hotelname['isdisabled'] == '1')">
												<template v-if="data_datasource && data_datasource.anchorid && data_datasource.anchorid.value">
														<li class="property-select" data-hotel="beijing" :data-id="hotelname['id']" data-section="events-block"><a class="popup-cta" :href="[hotelname['cta'] == ''? '#' : hotelname['cta']+ '/society/' + data_datasource.anchorid.value]"><div v-html="hotelname['titlehtml']"></div><span>{{location['title'].value}}</span></a></li>
												</template>
												<template v-else>
														<li class="property-select" data-hotel="beijing" :data-id="hotelname['id']" data-section="events-block"><a class="popup-cta" :href="[hotelname['cta'] == ''? '#' : hotelname['cta']]"><div v-html="hotelname['titlehtml']"></div><span>{{location['title'].value}}</span></a></li>
												</template>
											</template>
											</template>												
										</template> 
									</template>					
								</template>
							</ul>
				</div>
			</div>
			</template>
		</div> 
	</template>
	
	<template v-if="data_datasource.layouttype.value == 'accordion' || data_datasource.layouttype.value == 'links'">
		<div :class="uid + '_popup-signup-container popup-container popup-signup-container'" >
			<div>
				<template v-if="data_datasource != ''">
					<template v-if="data_datasource.children.length > 0">
						<template v-for="(item, index) in data_datasource.children">
							<template v-if="item['templatename'] == 'hjform'">
								<template v-if="item['formtype'] && item['formtype'].value != ''">
									<h-j-form :parentid="data_datasource.id" :formType="item['formtype'].value" :formid = "item['id']" :LinkedForm = "item['linkedform'].value" :currentcontext = "data_datasource" :moduleid = "uid"/>
								</template>
							</template>
						</template>
					</template>
				</template>
			</div>

		</div> 
	</template>
	
	<template v-if="data_datasource.layouttype.value == 'text'">
		<div :class="uid + '_popup-signup-container popup-container popup-signup-container'" >
			<div >
				<template v-if="data_datasource != ''">
					<template v-if="data_datasource.children.length > 0">
						<template v-for="(item, index) in data_datasource.children">
							<template v-if="item['templatename'] == 'hjform'">
								<template v-if="item['formtype'] && item['formtype'].value != ''">
									<h-j-form :parentid="data_datasource.id" :formType="item['formtype'].value" :formid = "item['id']" :LinkedForm="item['linkedform'].value" :currentcontext = "data_datasource" :moduleid = "uid"/>
								</template>

							</template>
						</template>
					</template>
				</template>
			</div>
		</div> 
	</template>

	
		<div :id="this.uid+'-bottom-border-right'" class="row no-gutters mt-2 mb-2 hidden"> <!-- MRM removed padding -->
                <div class="col-7 line"></div>
                <div class="col-1">&nbsp;</div>
                <div class="col-4 line"></div>
        </div>
		
		<div :id="this.uid+'-bottom-border-left'" class="row no-gutters mt-2 mb-2 hidden"> <!-- MRM removed padding -->
                <div class="col-4 line"></div>
                <div class="col-1">&nbsp;</div>
                <div class="col-7 line"></div>
        </div>

	</template>
	</div>
</template>

<style>
.box { 
  height: 60px; 
  width: 60px; 
  background: red; 
}
</style>

<script>
import { axios } from 'axios';
//import $ from 'jquery';
//import { TimelineLite, Back, TweenMax } from 'gsap';
//import { VueperSlides, VueperSlide } from 'vueperslides';
import '../assets/Accordion_css.css';
import '../assets/vueperslides.css';
import ApolloClient from 'apollo-boost';
import gql from 'graphql-tag';
import { uuid } from 'vue-uuid';
import { getObjectById,
getDictionaryPhrase,
updatePriceString,
getSSR, getKeys, searchMoreContentByType, graphqlToObject, gethotelminprice, getLinks, graphqlRemoveReserved, getContentByType, getObject, graphqlFetcherTestSSR, graphqlFetcher } from '../graphqlFetcher.js';
import { getpricebyid, getprice, getminprice } from '../personalise.js';
import HJDetail from './HJDetail';
import HJForm from './HJForm';
import HJContentBlockList from './HJContentBlockList';
import Vue from 'vue';
import config from '../temp/config';
import fullstopimg from '../assets/img/fullstop.png'
import { serverBus } from '../event-bus.js';
import closeblackicon from '../assets/img/close_btn.png';
import closewhiteicon from '../assets/img/close-white.png';
import gcicon from '../assets/img/golden-circle-logo.png';
import jensocietylogo from '../assets/img/jen_society_logo.png';
import arrowdownicon from '../assets/img/arrow_down.png';
import arrowupicon from '../assets/img/arrow_up.png';
import textborder from '../assets/img/textborder_large.png';
import { bookhotel, getSortedContent, addAdhocImages } from '../hjutil.js';
import 'lazysizes';
// import a plugin
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import { getDestinationFooter } from '../HJContentBlockList.js';

	export default {
  name: 'HJContentBlock',
  components: {
    //VueperSlides,
    //VueperSlide,
    HJForm,
    HJContentBlockList,
	HJDetail
  },
  props: {
    fields: {
      type: Object,
      default: function(){},
    },
    rendering: {
      type: Object,
    },
    uid: {
      type: String,
      default: function(){return uuid.v1();},
    },
    currentcontext: {	// current context is itself
      type: String,
      default: null,
    },
    datacontext: {	// current context is itself
      type: Object,
      default: null,
    },
  },
  data: function() {
    return {
      loadingQueriesCount: 0,
	  error: null,
	  isHomePage: window.location.pathname.length < 16 && window.outerWidth < 1026  ? false : true,
	  fullStopImg: fullstopimg,
	  slidegroups: [],
		slides: [
		  {
			title: '',
			content: '',
			image: '',
			data: {},
			//custom fields for footerText and button
			footer:'',
			buttontext: '',
			buttoncta: '',
			id: '',
			formid: '',
			adobelocationid: '',
			adobebuttonname: '',
			adobepreviousbuttonclick: '',
			adobetrackingcode: '',
		  },
		],
		Tabs: [
		  {
			title: '',
			description: '',
			//tabType:'',
			content: {},
            services: {},
		  },
		],
		show: true,
		position: 'left',
		type: 'accordion',
		//text: ['1','2','3'],
		data_datasource: {
			layouttype: {value:''},
			Animationtype: {value:''}
		},
		links: [],
		hotelnames:[],
		locations: [],
		closeWhiteIcon: closewhiteicon,
		closeBlackIcon: closeblackicon,
		GCIcon: gcicon,
		jenSocietyLogo: jensocietylogo,
		arrowDownIcon: arrowdownicon,
		arrowUpIcon: arrowupicon,
		textBorder: textborder,
		hideformobile:false,
		hjsettings: null,
		hoteldetail: null
    };
  },
  computed: {
	currency: function(){
		return this.$jss.currency();
	},
	ismobile: function(){
		return this.$jss.ismobile();
	},
    datasource: function() {
	  if (this.currentcontext != null)
	  {
		return this.currentcontext;
	  } else
		return this.fields && this.fields.data && this.fields.data.datasource;
    },
    contextItem: function() {
      return this.fields.data && this.fields.data.contextItem;
    },
	
	hotel_prices: function () {
		if (this.$jss.prices() != undefined && this.$jss.prices() != null )
			return this.$jss.prices(); 
		else
			return null;
	},
    propertydata: function() {
	  if (this.datacontext != null)
	  {
		return this.datacontext;
	  } else {
		//if (this.$root.propertydata)
		//	return this.$root.propertydata; 
		//else
		//{
			return null;
		//}
	  }
	},
  },
  watch: {
	currency : function(){
		this.getdata();
		//location.reload();
		//this.data_datasource.title = this.$jss.currency();
		//this.$forceUpdate();
	}
  },
  methods: {
	getDictionaryPhrase: function(key)
	{
		return getDictionaryPhrase(key, this);
	},
	updatePriceString: function(price){
		return updatePriceString(price);
	},
	book_click: function()
	{
		let vm = this;
		var language = 'en'; 
		if ( vm.$jss.routeData().itemLanguage == 'zh-CN' || vm.$jss.routeData().itemLanguage == 'zh'){
			language = 'cn'; 
		}else{
			language = vm.$jss.routeData().itemLanguage.substring(vm.$jss.routeData().itemLanguage.length-2).toLowerCase()			
		} 
		var key = vm.$jss.hjconfig().encryption_key;
		var iv = vm.$jss.hjconfig().encryption_iv;
		var propertycode = "";
		var propertycityalias = "";
		var propertyhotelalias = "";
		if ($(this).attr('data-bookingurl'))
		{
			propertycode = $(this).attr('data-bookingurl').split('|')[2];
			propertycityalias = $(this).attr('data-bookingurl').split('|')[0];
			propertyhotelalias = $(this).attr('data-bookingurl').split('|')[1];
		}
		book_url = vm.$jss.hjconfig().book_url;
		bookhotel(key, iv, book_url, propertycode, propertycityalias, propertyhotelalias, null, null, null, null, 'None', '', $(this).attr('data-roomcode'), '', language);
	},
	limited: function(object, limit)
	{
		if (limit < object.length)
			return object.slice(0, limit-1);
		else
			return object;
	},
    getComponentId: function() {
      var lfound = false;
      let vm = this;
      let current_item = vm.$jss.sitecoreContext();
	  vm.currency = vm.$jss.currency();
      var routedata = vm.$jss.routeData();

      for (var i = 0; i < routedata.placeholders['jss-main'].length; i++) {
        if (routedata.placeholders['jss-main'][i].uid == this.uid) {
          lfound = true;
          return routedata.placeholders['jss-main'][i].dataSource.replace('{', '').replace('}', '');
        }
      }
      return null;
    },
    isproperty: function(name) {
		if (this.fields != null && this.fields[name] != undefined)
		{
			if (this.fields[name])
			{
				if (this.fields[name] && 
					this.fields[name].targetItems  && 
					this.fields[name].targetItems.length > 0)
					{
						if (this.fields[name].targetItems[0].textField)
							return this.fields[name].targetItems[0].textField.value;
						else if (this.fields[name].targetItems[0].title)
							return this.fields[name].targetItems[0].title.value;
						else
							return "";
					}
				else
				{
					if (this.fields[name].value)
						return this.fields[name].value;
					else
						return this.fields[name];
				}
			}
			else
				return "";
		}
		else if (this.datasource != null && this.datasource[name.toLowerCase()] != undefined)
		{
			if (this.datasource[name.toLowerCase()])
			{
				if (this.datasource[name.toLowerCase()] && 
					this.datasource[name.toLowerCase()].children  && 
					this.datasource[name.toLowerCase()].children.length > 0)
					{
						if (this.datasource[name.toLowerCase()].children[0].textField)
							return this.datasource[name.toLowerCase()].children[0].textField.value;
						else if (this.datasource[name.toLowerCase()].children[0].title)
							return this.datasource[name.toLowerCase()].children[0].title.value;
						else
							return "";
					}
				else
				{
					return this.datasource[name.toLowerCase()].value;
				}
			}
			else
				return "";
		}
		else
		{
			if (this.fields && this.fields.data && this.fields.data.datasource && this.fields.data.datasource.fields)
			{
				var datasource = graphqlToObject('', this.fields.data.datasource, this, '', null, false);
				if (datasource[name.toLowerCase()])
				{
					return datasource[name.toLowerCase()].value;
				}
				else
				{
					for (var i=0; i < this.fields.data.datasource.fields.length; i++)
					{
						if (this.fields.data.datasource.fields[i].name == name)
						{
							if (this.fields.data.datasource.fields[i].targetItems && 
								this.fields.data.datasource.fields[i].targetItems.length > 0) {
								if (this.fields.data.datasource.fields[i].targetItems[0].textField)
									return this.fields.data.datasource.fields[i].targetItems[0].textField.value;
								else if (this.fields.data.datasource.fields[i].targetItems[0].title)
									return this.fields.data.datasource.fields[i].targetItems[0].title.value;
								else
									return "";
							}
							else
							{
								return this.fields.data.datasource.fields[i].value;
							}
						}
					}
				}
			}
			else if (this.currentcontext)
			{
				var keys = getKeys(this.currentcontext);
				for (var i=0; i < keys.length; i++)
				{
					if (keys[i].toLowerCase() === name.toLowerCase())
					{
						if (this.currentcontext[keys[i].toLowerCase()].children && 
							this.currentcontext[keys[i].toLowerCase()].children.length > 0) {
							if (this.currentcontext[keys[i].toLowerCase()].children[0].textField)
								return this.currentcontext[keys[i].toLowerCase()].children[0].textField.value;
							else if (this.currentcontext[keys[i].toLowerCase()].children[0].title)
								return this.currentcontext[keys[i].toLowerCase()].children[0].title.value;
							else
								return "";
						}
						else
						{
							if (this.currentcontext[keys[i].toLowerCase()].value != undefined)
								return this.currentcontext[keys[i].toLowerCase()].value;
							else
								return this.currentcontext[keys[i].toLowerCase()];
						}
					}
				}

				if (this.currentcontext.fields)
				{
					for (var i=0; i < this.currentcontext.fields.length; i++)
					{
						if (this.currentcontext.fields[i].name.toLowerCase() === name.toLowerCase())
						{
							if (this.currentcontext.fields[i].targetItems && 
								this.currentcontext.fields[i].targetItems.length > 0) {
								if (this.currentcontext.fields[i].targetItems[0].textField)
									return this.currentcontext.fields[i].targetItems[0].textField.value;
								else if (this.currentcontext.fields[i].targetItems[0].title)
									return this.currentcontext.fields[i].targetItems[0].title.value;
								else
									return "";
							}
							else
							{
								if (this.currentcontext.fields[i].value != undefined)
									return this.currentcontext.fields[i].value;
								else
									return this.currentcontext.fields[i];
							}
						}
					}
				}
			}
		}
		var o = {};
		o.value = '';
		return o;
	},
	getdata: function(){
		//if (this.isVisible)
		if (true)
		{
			let vm = this;

			//Set currency
			var cur_currency = localStorage.getItem('curcurrency')
			var currentCurrency = ''

			if(cur_currency){
			vm.$jss.store.setCurrency(cur_currency);
			}else {
			vm.$jss.store.setCurrency('USD')
			}
			//Set currency END
			let currency = vm.$jss.currency();
			let prices = null ; 
			if (vm.$jss.prices() == null)
			{
				$.getJSON('/api/gethotelprices', function(data) {
				  vm.$jss.store.setPrices(data);
				});
			}
			prices = vm.$jss.prices();
			var routedata = vm.$jss.routeData();
			/*if (vm.$jss.hjconfig() == null)
			{
				$.getJSON('/api/gethjconfig', function(data) {
				  vm.$jss.store.setHjconfig(data);
				});
			}*/
			
			if (this.fields && this.fields.data && this.fields.data.datasource)
			{
				graphqlRemoveReserved(this.fields.data.datasource);
			}

			vm.hotelnames = vm.$jss.hotelnames();
			var hotels = null;
			if (vm.$jss.hotels() != null && vm.$jss.hotels()['children'] && vm.$jss.hotels()['children'].length > 0)
			{
				vm.$jss.store.setMinpricesobject(getminprice(null, prices, currency, vm.$jss.travellertype(), vm.$jss.hotels()['children']));
				vm.minpricesobject = vm.$jss.minpricesobject();
			}
			if (vm.$jss.locations() != null && vm.$jss.locations().length > 0)
			{
				vm.locations = vm.$jss.locations();
			}
			else
			{
				//Get Locations 
				if (vm.$jss.locations() == null)
				{
					var _locations = getContentByType(
						vm.$jss.constants(),
						'hjlocation',
						vm,
						false,
						false,
						'other',
						false
						);
					if (vm.hotelnames && vm.hotelnames['children'] && _locations) {
						for (var i = 0; i < _locations.length; i++) {
							var hotel_count = 0;
							_locations[i]['children'] = [];
							for (var j = 0; j < vm.hotelnames['children'].length; j++) {
								if (
									vm.hotelnames['children'][j]['location'] &&
									vm.hotelnames['children'][j]['location'].value
								) {
									if (
										vm.hotelnames['children'][j]['location'].value
										.toLowerCase()
										.replace(/-/g, '')
										.replace(/{/g, '')
										.replace(/}/g, '') ===
									  _locations[i].id
										.toLowerCase()
										.replace(/-/g, '')
										.replace(/{/g, '')
										.replace(/}/g, '')
									) {
										var _price = vm.$jss.minpricesobject().find(x=>x.hotelcode.toLowerCase().trim() === vm.hotelnames['children'][j]['hotelcode'].value.toLowerCase().trim());
										_locations[i]['children'].push({
											title: vm.hotelnames['children'][j]['title'].value,
											titlehtml: vm.hotelnames['children'][j]['titlehtml'].value,
											id: vm.hotelnames['children'][j]['id'],
											cta: vm.hotelnames['children'][j]['cta'].value,
											isdisabled: vm.hotelnames['children'][j]['isdisabled'].value,
											adobebuttonname: vm.hotelnames['children'][j]['adobebuttonname'],
											adobelocationid: vm.hotelnames['children'][j]['adobelocationid'],
											adobepreviousbuttonclick: vm.hotelnames['children'][j]['adobepreviousbuttonclick'],
											hotelalias: vm.hotelnames['children'][j]['hotelalias'].value,
											hotelcode: vm.hotelnames['children'][j]['hotelcode'].value,
											cityalias: vm.hotelnames['children'][j]['cityalias'].value,
											price: _price != undefined && _price != null ? _price.price : 0
										});
									}
								}
							}
						}
						vm.$jss.store.setLocations(_locations);
					}
					vm.locations = vm.$jss.locations();
				} 
			} // END: vm.$jss.locations() != null && vm.$jss.locations().length > 0
			
			//console.log(vm.$jss.locations());
			var obj = 'bbb';
			//serverBus.$on('headerMounted', function(hotels, root) {
			//});
			
			var _routedata = vm.$jss.routeData();
			var componentIDArr = [];

			var componentID = _routedata.placeholders['jss-main'][1].dataSource
			  .replace('{', '')
			  .replace('}', '');

			//Test Output Data
			var testData = function(data, vm) {
				//console.log(data);
			};

			//Get Component Content by graphqlfetcher function
			//var getcomponentContent_ar = function(data, _vm, ar, type, property, event, context, isloadmore) //{
				vm.data_datasource = vm.currentcontext;
				if (vm.isproperty("layouttype") == "menupagelinks")
				{
					vm.links = [];
					vm.links = getLinks(vm.data_datasource, _vm, false);
				}
				var tempTabs = [];
				var temp2 = [];
				var fieldtitle = '';
				var fielddesc = '';
				var fieldimage = '';
				var fieldfooter = '';
				var fieldbuttontext = '';
				var fieldbuttoncta = '';
				var fieldid = '';
				var formid = '';
				var hasAnchor = true;
				var hasDisclaimer = true;
				
				if (vm.data_datasource.children) {
					var tabcount = 0;
					for (var i = 0; i < vm.data_datasource.children.length; i++) {
						if (vm.data_datasource.children[i].templatename == 'hjimage') {
							fieldtitle = vm.data_datasource.children[i].title.value;
							if (vm.data_datasource.children[i]["property"] && vm.data_datasource.children[i]["property"].value)
							{
								var _minprices = vm.$jss.minpricesobject();
								for(var j=0; j < _minprices.length; j++)
								{
									if (_minprices[j] && _minprices[j]['id'] != null && _minprices[j]['id'].toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim() === vm.data_datasource.children[i]["property"]["value"].toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim())
									{
										if (_minprices[j].price > 0)
										{
											fielddesc += '<p>';
											fielddesc += vm.getDictionaryPhrase('From') + ' <span class="price"> ' + updatePriceString(_minprices[j].price.toLocaleString()) + ' </span> <span>' +  vm.$jss.currency() + '</span><br>' +  vm.getDictionaryPhrase('PerNight')  
											fielddesc += '</p>';
										}
										break;
									}
								}
							}
							else
							{
								//fielddesc += '<p>';
								fielddesc += vm.data_datasource.children[i].description.value;
								//fielddesc += '</p>';
							}
							vm.data_datasource.children[i].description.value = fielddesc;
							if (vm.data_datasource.children[i].button.value != '' ) {
								fieldbuttontext = vm.data_datasource.children[i].button.value;
							}else{
								fieldbuttontext = '';
							}
							if (vm.data_datasource.children[i].buttoncta.value != '' ) {
								var regexp = /(url=\")[\""A-Za-z0-9.;:!#$%&'*+-\/=?^_`{|}~\p{L}\p{Nd}]{1,}(\")/g;
								var match = vm.data_datasource.children[i].buttoncta.value.match(regexp);
								if (match != null && match.length > 0)
								{
									var buttonctaURL = match[0].substring(5, match[0].length - 1);
									fieldbuttoncta = buttonctaURL;
									if (vm.data_datasource.children[i]['adobetrackingcode'])
										fieldbuttoncta += vm.data_datasource.adobetrackingcode;
								}
								else
								{
									fieldbuttoncta = '';
								}
							}else{
								fieldbuttoncta = '';
							}
							
							
							//Check and Get Disclaimer content
							/*if (vm.data_datasource.children[i].subtitle.value != '') {
								fieldfooter = vm.data_datasource.children[i].subtitle.value;
							}else{
								fieldfooter = '';
							}*/

							//Check and Get Image Source
							if (vm.data_datasource.children[i].image && vm.data_datasource.children[i].image.src != '') {
								fieldimage = vm.data_datasource.children[i].image.src;
							} else {
								fieldimage = '';
							}
							//Check and Get Form component
							if (vm.data_datasource.children[i].children && vm.data_datasource.children[i].children.length > 0 && vm.data_datasource.children[i].children[0].templatename == 'hjform') {
								//Get Form's Parent ID
								if (vm.data_datasource.children[i].id != null && vm.data_datasource.children[i].id != '' && vm.data_datasource.children[i].id != 'undefined') {
								  fieldid = vm.data_datasource.children[i].id;
								}
								//Get Form's ID
								if (vm.data_datasource.children[i].children[0].id != null && vm.data_datasource.children[i].children[0].id != '' && vm.data_datasource.children[i].children[0].id != 'undefined') {
								  formid = vm.data_datasource.children[i].children[0].id;
								}
							}
							
							if (vm.$jss.travellertype().toLowerCase() == 'other')
							{
								temp2.push(vm.data_datasource.children[i]);
							}
							else
							{
								if ((vm.data_datasource.children[i]["travellerType"] && vm.data_datasource.children[i]["travellerType"].targetItems && vm.data_datasource.children[i]["travellerType"].targetItems.length > 0) ||
								(vm.data_datasource.children[i]["travellerType"] && vm.data_datasource.children[i]["travellerType"].targetItems && vm.data_datasource.children[i]["travellerType"].targetItems.length == 0)
								)
								{
									if (vm.data_datasource.children[i]["travellerType"].targetItems.length == 0 ||
										vm.$jss.travellertype().toLowerCase() == vm.data_datasource.children[i]["travellerType"].targetItems[0].title.toLowerCase())
									{
										temp2.push(vm.data_datasource.children[i]);
									}
								}
							}
							
							fieldtitle = '';
							fielddesc = '';
							fieldimage = '';
							fieldfooter = '';
							fieldbuttontext = '',
							fieldbuttoncta = '',
							fieldid = '';
							formid = '';
							adobebuttonname: '';
							adobelocationid: '';
							adobepreviousbuttonclick: '';
						}
						if (vm.data_datasource.children[i].templatename == 'hjtab')
						{
							
							var type = ""; 
							if (vm.data_datasource.children[i].contentcategory != null && vm.data_datasource.children[i].contentcategory["children"] != null)
							{
								if (vm.data_datasource.children[i].contentcategory["children"][0].value != "")
								{
									type = vm.data_datasource.children[i].contentcategory["children"][0].value;
								}
							}
							if (type != "")
							{
								if (type.toLowerCase() == "hjdeal" || 
									type.toLowerCase() == "hjdealstay" || 
									type.toLowerCase() == "hjdealeat" ||
									type.toLowerCase() == "hjdealspace" ||
									type.toLowerCase() == "hjdealwedding" 
								)
								{
									searchMoreContentByType(null, type, vm, 
										function(data, _vm, ar, type, property, event, context, isloadmore, graphqlresults, searchresults, targetobject){
											var items = getContentByType(ar, type, _vm, false, (_vm.isproperty("layouttype") == "gallery" ? true : false), _vm.$jss.travellertype(), false, 20, property);
											//items = getSortedContent(items, "orderindex", false, false);
											addAdhocImages(items, context);
													targetobject.push({
														title: context.heading.value, 
														description: context.description.value,
														content: searchresults, //services: ar.children
														services: []
														});
										}, 20, null, vm.$jss.property(), false, vm.$jss.travellertype(), null, "", null, vm.data_datasource.children[i], null, tempTabs);
										
								}
								else if (type.toLowerCase() == "hjrestaurant" ||
									type.toLowerCase() == "hjspace" ||
									type.toLowerCase() == "hjworkout" ||
									type.toLowerCase() == "hjevent")
								{
								
									if (vm.propertydata && vm.propertydata != null && vm.propertydata.children && vm.propertydata.children.length > 0)
									{
										var _propdata = null;
										if (vm.propertydata.children[0].template.name.toLowerCase() === "folder")
											_propdata = vm.propertydata.children.find(x=>x.name.toLowerCase() == type.substring(2).toLowerCase());
										else
											_propdata = vm.propertydata;
										
										var propdata = getObject(_propdata, vm);
										
										
										/*
										if (propdata.children[0].children.length == 1)
										{
											if (vm.$jss.allsociety() != null && vm.$jss.allsociety().children && vm.$jss.allsociety().children.length > 0)
											{
												var tempData = vm.$jss.allsociety().children.find(x=>x.name.toLowerCase() == type.substring(2).toLowerCase());
												if (tempData.children[0].children.length > 1)
												{
													propdata = getObject(tempData, vm);
												}
											}
										}
										*/
										var items = getContentByType(propdata, type.toLowerCase(), vm, false, (vm.isproperty("layouttype") == "gallery" ? true : false), vm.$jss.travellertype(), false, 20, vm.$jss.property());
										//items = getSortedContent(items, "orderindex", false, false);
										addAdhocImages(items, vm.data_datasource.children[i]);
										tempTabs.push({
											title: vm.data_datasource.children[i].heading.value, 
											description: vm.data_datasource.children[i].description.value,
											content: items,
											services: getContentByType(vm.services, type, vm, true, true, vm.$jss.travellertype(), true, 20, vm.$jss.property()
											)
											});
									}
									else
									{
										searchMoreContentByType(null, type, vm, 
											function(data, _vm, ar, type, property, event, context){
												var items = getContentByType(ar, type, _vm, false, (vm.isproperty("layouttype") == "gallery" ? true : false), _vm.$jss.travellertype(), false, 20, _vm.$jss.property());
												//items = getSortedContent(items, "orderindex", false, false);
												addAdhocImages(items, context);
												tempTabs.push({
													title: context.heading.value, 
													description: context.description.value,
													content: items,
													services: getContentByType(_vm.services, type, _vm, true, true, _vm.$jss.travellertype(), true, 20, _vm.$jss.property()
													)
													});
											}, 20, null, vm.$jss.property(), false, vm.$jss.travellertype(), null, "", null, vm.data_datasource.children[i]);
									}
								}
								else if (type.toLowerCase() == "hjhost" ||
										 type.toLowerCase() == "hjhostcelebrate" ||
										 type.toLowerCase() == "hjhostmeet"
								)
								{
									if (vm.propertydata && vm.propertydata != null && vm.propertydata.children && vm.propertydata.children.length > 0)
									{
										var _propdata = null;
										if (vm.propertydata.children[0].template.name.toLowerCase() === "folder")
											_propdata = vm.propertydata.children.find(x=>x.name.toLowerCase() == "host");
										else
											_propdata = vm.propertydata;
										
										var propdata = getObject(_propdata, vm);

										var items = getContentByType(propdata, type, vm, false, (vm.isproperty("layouttype") == "gallery" ? true : false), vm.$jss.travellertype(), false, 20, vm.$jss.property());
										//items = getSortedContent(items, "orderindex", false, false);
										addAdhocImages(items, vm.data_datasource.children[i]);
										var originaltype = "";
										if (items.length > 0)
										{
											if (items[0].templatename.toLowerCase() === "hjhostmeet")
												originaltype = items[0].templatename.toLowerCase();
											else if (items[0].templatename.toLowerCase() === "hjhostcelebrate")
												originaltype = items[0].templatename.toLowerCase();
										}
										var categories = vm.$jss.constants().children.find(x=>x.name == "ContentCategory");
										var category = categories.children.find(x=>x.value.value.toLowerCase() == originaltype);
										var services = vm.propertydata.children.find(x=>x.name.toLowerCase() === 'service');
										console.log(services);
										var _services = {};
										_services['children'] = [];
										for (var k=0; k < services.children.length; k++)
										{
											if (services.children[k].fields.find(y=>y.name.toLowerCase() === 'contentcategory').value.replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim().toLowerCase() == category.id.replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim().toLowerCase())
											{
												_services['children'].push(services.children[k]);
											}
										}
										_services = getObject(_services, vm)
										console.log(_services);
										tempTabs.push({
											title: vm.data_datasource.children[i].heading.value, 
											description: vm.data_datasource.children[i].description.value,
											content: items, //services: ar.children
											services: _services.children
											});
									}
									else
									{
										searchMoreContentByType(null, type, vm, 
											function(data, _vm, ar, type, property, event, context, isloadmore, graphqlresults, searchresults, targetobject){
												var items = getContentByType(ar, type, _vm, false, (_vm.isproperty("layouttype") == "gallery" ? true : false), _vm.$jss.travellertype(), false, 20, property);
												//items = getSortedContent(items, "orderindex", false, false);
												addAdhocImages(items, context);
												var originaltype = "";
												if (items.length > 0)
												{
													if (items[0].templatename.toLowerCase() === "hjhostmeet")
														originaltype = items[0].templatename.toLowerCase();
													else if (items[0].templatename.toLowerCase() === "hjhostcelebrate")
														originaltype = items[0].templatename.toLowerCase();
												}
												targetobject.push({
													title: context.heading.value, 
													description: context.description.value,
													content: items, //services: ar.children
													services: getContentByType(_vm.$jss.services(), originaltype, _vm, false, true, _vm.$jss.travellertype(), true, -1, _vm.$jss.property()
													)
													});
											}, 20, null, vm.$jss.property(), false, vm.$jss.travellertype(), null, "", null, vm.data_datasource.children[i], null, tempTabs);
									}
								}
								else if (type.toLowerCase() == "hjroom-template")
								{
									if (false && vm.propertydata != null && vm.propertydata.children && vm.propertydata.children.length > 0)
									{
										var rooms = getContentByType(vm.$jss.rooms(), 'hjroom-template', vm, false, (vm.isproperty("layouttype") == "gallery" ? true : false), vm.$jss.travellertype(), false, 20, vm.$jss.property());
										if (vm.isproperty("layouttype") != "gallery")
										{
											rooms = [];
											for (var s=0; s < vm.$jss.rooms().children.length; s++)
											{
												if(vm.$jss.rooms().children[s].property.value.toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim() === vm.$jss.property().toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim())
												{
													rooms.push(vm.$jss.rooms().children[s]);
												}									
											}
										}
										if (vm.$jss.prices() != null && vm.$jss.prices().hotels && vm.$jss.prices().hotels.length > 0)
										{
											for (var r=0; r < rooms.length; r++)
											{
												rooms[r]['price'] = getpricebyid(vm.$jss.property(), vm.$jss.hotels(), rooms[r]['roomtypecode'].value, vm.$jss.prices(), vm.$jss.currency(), vm.$jss.travellertype()) ;											
											}
										}
										if (vm.data_datasource.children[i].children && vm.data_datasource.children[i].children.length > 0)
										{
											addAdhocImages(rooms, vm.data_datasource.children[i]);
										}
										if (rooms.length > 0)
										{
											tempTabs.push({ title: vm.data_datasource.children[i].heading.value, content:rooms });			 
										}
										else
										{
											tempTabs.push({ title: vm.data_datasource.children[i].heading.value, content:rooms});			 
										}
									}
									else
									{
										searchMoreContentByType(null, type, vm, 
											function(data, _vm, ar, type, property, event, context){
												var rooms = {};
												rooms = getContentByType(ar, type, _vm, true, false, _vm.$jss.travellertype(), false, 20, _vm.$jss.property() );
												if (vm.$jss.prices() != null && vm.$jss.prices().hotels && vm.$jss.prices().hotels.length > 0)
												{
													for (var r=0; r < rooms.length; r++)
													{
														rooms[r]['price'] = getpricebyid(vm.$jss.property(), vm.$jss.hotels(), rooms[r]['roomtypecode'].value, vm.$jss.prices(), vm.$jss.currency(), vm.$jss.travellertype()) ;											
													}
												}
												//rooms = getSortedContent(rooms, "orderindex", false, false);
												if (context.children && context.children.length > 0)
												{
													addAdhocImages(rooms, context);
												}
												if (rooms.length > 0)
												{
													tempTabs.push({ title: context.heading.value, content:rooms });			 
												}
												else
												{
													tempTabs.push({ title: context.heading.value, content:rooms});			 
												}
											}			 								
											, 20, null, vm.$jss.property(), false, vm.$jss.travellertype(), null, "", null, vm.data_datasource.children[i]);
										$('#' + vm.uid + ' .book').off('click').on('click', function(e) {
										});
									}
								}
								else if (type.toLowerCase() == "hjhotel-template")
								{
									//console.log(type);
									var items = getContentByType(vm.$jss.hotels(), type, vm, true, false, vm.$jss.travellertype());
									//items = getSortedContent(items, "orderindex", false, false);
									if (vm.data_datasource.children[i].children && vm.data_datasource.children[i].children.length > 0)
									{
										addAdhocImages(items, vm.data_datasource.children[i]);
									}
									tempTabs.push({ title: vm.data_datasource.children[i].heading.value, content:items});	
								}
								else
								{
									//console.log(type);
									searchMoreContentByType(null, type, vm, 
										function(data, _vm, ar, type, property, event, context){
											tempTabs.push({
												title: context.heading.value, 
												description: context.description.value,
												content: getContentByType(ar, type.toLowerCase(), _vm, false, false, _vm.$jss.travellertype(), false, 20, _vm.$jss.property()),
												services: []
											});			 								
										}, 20, null, vm.$jss.property(), false, vm.$jss.travellertype(), null, "", null, vm.data_datasource.children[i]);
								}
							}
							tabcount++;
						}
					}	//	END: children
					vm.Tabs = tempTabs;
					//console.log(vm.Tabs);
					if (temp2 != '') {
						vm.slides = temp2;
						//console.log(temp2);
					
						/*if (vm.isproperty("layouttype") == "grid1" ||
							vm.isproperty("layouttype") == "grid2")
						{
							
							var maxslide = 4;
							if (vm.isproperty("layouttype") == "grid2")
							{
								maxslide = 5;
							}
							vm.slidegroups = [];
							var maxslides = temp2.length;
							var slidegroup = [];
							if (vm.isproperty("layouttype") == "grid1")
							{
								if (temp2.length > 4 && temp2.length % 4 != 0)
								{
									maxslides = temp2.length - (temp2.length % 4);
								}
							}
							else
							{
								if (temp2.length > 5 && temp2.length % 5 != 0)
								{
									maxslides = temp2.length - (temp2.length % 5);
								}
							}
							for(var i=0; i < maxslides; i++)
							{
								slidegroup.push(temp2[i]);
								if (i != 0 && i % maxslide == 0)
								{
									vm.slidegroups.push(slidegroup);
									slidegroup = [];
								}
							}	
							if (slidegroup.length > 0)
								vm.slidegroups.push(slidegroup);
						}*/
						
						/** with 3 grid type **/
						/*if (vm.isproperty("layouttype") == "grid1"){
							var gridtype = 1;
							var maxslide = 4;
							
							vm.slidegroups = [];
							var maxslides = temp2.length;
							var slideobj = {gridtype:1, slidegroup:[]};
							var slidegroup = [];
							
							for(var i=0; i < maxslides; i++)
							{
								slidegroup.push(temp2[i]);
									if (i != 0 && (i+1) % maxslide == 0)
									{	
										slideobj.gridtype = gridtype;							
										if (gridtype != 3){
											maxslide = 4;
											gridtype ++;
											if (gridtype == 3){
											maxslide = 13;
											}
										}else{
											maxslide = 4;
											gridtype = 1;									
										}
										slideobj.slidegroup = slidegroup;
										//vm.slidegroups.push(slidegroup);
										vm.slidegroups.push(slideobj);
										slideobj = {gridtype:1, slidegroup:[]};
										slidegroup = [];
									}
							}	
							if (slidegroup.length > 0){
								slideobj.gridtype = gridtype;
								slideobj.slidegroup = slidegroup;
								vm.slidegroups.push(slideobj);
							}
							
						}	*/ //	3 Grid Types
						
						/** With 2 Grid Type **/
						if (vm.isproperty("layouttype") == "grid1"){
							var gridtype = 1;
							var maxslide = 4;
							var loopedSlides = 0;
							vm.slidegroups = [];
							var maxslides = temp2.length;
							var slideobj = {gridtype:1, slidegroup:[]};
							var slidegroup = [];
							
							for(var i=0; i < maxslides; i++)
							{
								slidegroup.push(temp2[i]);
									if (i != 0 && ((i+1) - loopedSlides) % maxslide == 0)
									{	
										slideobj.gridtype = gridtype;							
										if (gridtype == 1){
											maxslide = 4;
											gridtype ++;
											loopedSlides = loopedSlides + 4;
											if (gridtype == 2){
												maxslide = 5;
											}
										}else{
											maxslide = 4;
											loopedSlides = loopedSlides + 5
											gridtype = 1;									
										}
										slideobj.slidegroup = slidegroup;
										//vm.slidegroups.push(slidegroup);
										vm.slidegroups.push(slideobj);
										slideobj = {gridtype:1, slidegroup:[]};
										slidegroup = [];
									}
							}	
							if (slidegroup.length > 0){
								slideobj.gridtype = gridtype;
								slideobj.slidegroup = slidegroup;
								vm.slidegroups.push(slideobj);
								
							}
							
							
						}
						
						
					}	// END; if temp2
				}	// END: if (vm.data_datasource.children)		  
			//}; // END; getcomponentContent_ar

		let hotel_fragment = `
	...on HJHotelTemplate {
		  amenities
		  {
				targetItems {
	...on ConstantsHJAmenityTypeTemplate {
				title {
				  value
				}
				description
				{
				  value
				}
				image 
				{
				  jss
				}
	}
		  }
		}
	}
	`;
			var query__ = `	
				query {item(path: "/sitecore/content/hoteljen/Content/Hotels")
				{
				   id
					template
					{
						name
					}
					fields(ownFields:true)
					{
					  __typename
					  name
					  value
					  editable
					}
					children
					{
						id
						template
						{
							name
						}
						fields(ownFields:true)
						{
						  __typename
						  name
						  value
						  editable						  
						}
						${hotel_fragment}
						children
						{
							id
							template
							{
								name
							}
							fields(ownFields:true)
							{
								__typename
								name
								value
								editable
							}
							children
							{
								id
								template
								{
									name
								}
								fields(ownFields:true)
								{
									__typename
									name
									value
									editable
								}
								children
								{
									id
									template
									{
										name
									}
									fields(ownFields:true)
									{
										__typename
										name
										value
										editable
									}
								}
							}
						}
					}
				}
				}`;
			//graphqlFetcher('', vm, '', null, '', null, //getcomponentContent_ar, null, '', null, '', 6, //vm.$jss.property(), false, vm.$jss.travellertype());	
			//graphqlFetcher('', vm, '', null, '', null, //getcomponentContent_ar, true, '', null, '', 6, //vm.$jss.property(), false, //vm.$jss.travellertype(), true);	
			/*
			graphqlFetcher('', vm, '', null, query__, null, function(data, _vm, ar, type, property, event, context, isloadmore){
				//console.log(ar);
			}, false, '', null, '', 6, vm.$jss.property(), false, vm.$jss.travellertype(), true);	
			*/
		}
	}
  },
  //async mounted() {
  mounted : function() {

	let vm = this;
	if (typeof window !== 'undefined') {
		//window.lazySizesConfig.loadHidden = false;
		// it's safe to use window now
		//console.log(window);
		//if (window.__JSS_STATE__)
		//{
		//	//
		//	const ssrRawJson = document.getElementById('__JSS_STATE__');
		//	if (ssrRawJson) {
		//		var jss_state = JSON.parse(ssrRawJson.innerHTML);
		//	}
		//	//
		//}
	}	
  	var minprices = null;

	var cur_currency = localStorage.getItem('curcurrency')
	var currentCurrency = ''

	if(cur_currency){
		vm.$jss.store.setCurrency(cur_currency);
		vm.currency = vm.$jss.store.setCurrency(cur_currency);
	}else {
		vm.$jss.store.setCurrency('USD')
		vm.currency = vm.$jss.store.setCurrency('USD');
	}

	if (vm != null && vm.$jss.minprices() != null && vm.$jss.minprices().length > 0)
	{
			minprices = vm.$jss.minprices();
	}
	else
	{
			vm.$jss.store.setMinprices(getminprice(null, vm.hotel_prices, vm.$jss.currency(), vm.$jss.travellertype()));
			minprices = vm.$jss.minprices();
	}

	if (!(vm.isproperty('hideformobile') === '1'))
	{
		vm.hideformobile = false
	}
	else
	{
		vm.hideformobile = true;
	}

	if (!(vm.ismobile && vm.hideformobile) && true) //	this code will be used for later lazy loading
	{
		let currency = vm.$jss.currency();
		let prices = null ; 
		if (vm.$jss.prices() == null)
		{
			$.getJSON('/api/gethotelprices', function(data) {
			  vm.$jss.store.setPrices(data);
			});
		}
		prices = vm.$jss.prices();
		var routedata = vm.$jss.routeData();
		if (vm.hotelprices != null)
		{
		$.getJSON( "/api/gethotelprices", function( data ) {
			vm.hotel_prices = data;
		});			
		}
		
		if (vm.$jss.hotelnames() != null && vm.$jss.hotelnames().children && vm.$jss.hotelnames().children.length > 0)
		{
			vm.hotelnames = vm.$jss.hotelnames();
		}
		else
		{
			//vm.$jss.store.setHotelnames(getObject(vm.$jss.hotels(), vm));
			vm.hotelnames = vm.$jss.hotelnames();
		}

		if (vm.$jss.property() && vm.$jss.property() != ''){
			var propertyid = vm.$jss.property();
			var hotelDetails = {};
			if (propertyid && propertyid != null){		
			propertyid = propertyid.replace(/\-/g,"").toUpperCase();
			hotelDetails = getObject(getObjectById(vm.$jss.hotels(),propertyid), vm);
			}else{
			vm.hjcity = '';
			}
			vm.hoteldetail = hotelDetails;
		}

		
		if (this.fields && this.fields.data && this.fields.data.datasource)
		{
			graphqlRemoveReserved(this.fields.data.datasource);
		}
		var obj = 'bbb';
		//serverBus.$on('headerMounted', function(hotels, root) {
		//});
		
		var _routedata = vm.$jss.routeData();
		var componentIDArr = [];

		var componentID = _routedata.placeholders['jss-main'][1].dataSource
		  .replace('{', '')
		  .replace('}', '');

		window.setTimeout(function() {
			//Test Output Data	
			var testData = function(data, vm) {
				//console.log(data);
			};
			vm.getdata();
		  
			var isloopCarousel = false;
			var isloopLinks = false;
			if (vm.slides.length > 1){
				isloopCarousel = true;
			}else{
				isloopCarousel = false;
			}
			
			/*if (vm._vm.data_datasource.children.length > 1){
				isloopLinks = true;
			}else{
				isloopLinks = false;
			}*/
			
			if (vm.isproperty("layouttype") == "grid1" || vm.isproperty("layouttype") == "grid2")
			{			
				//$('.destination-container').html(getDestinationFooter(null, vm, null, vm.slidegroups));
				
				var swiper = new Swiper('.destination-swiper-container', {
centerInsufficientSlides: true,							
								loop: true,
								pagination: {
									el: '.swiper-pagination',
									clickable: true
								},
								navigation: {
									nextEl: '.swiper-button-next',
									prevEl: '.swiper-button-prev',
								},
							});
							
			}
			
					
				
			
			
			if(navigator.userAgent.match(/Trident\/7\./)) { // if IE
				$('html').css('overflow','hidden').css('height', '100%;');
				$('body').css('overflow', 'auto').css('height', '100%');

			}
			
			if (vm.isproperty("layouttype") == "carousel-only"){
				
				$('.' + vm.uid + '_owl-carousel').owlCarousel('destroy');
				$('.' + vm.uid + '_owl-carousel').owlCarousel({ 
					loop: isloopCarousel, 
					margin:10, 
					nav:true,
					dots: true,
					autoplay:false,
					//autoplayTimeout:1000,
					//autoplayHoverPause:true,
					responsiveClass:true,
					responsive:{
						0:{
							items:1,
							nav:true,
							autoplay:false
						},
						600:{
							items:2,
							nav:true,
							autoplay:false
						},
						1000:{
							items:1,
							autoplay:false,
							loop: isloopCarousel 
						}
					},
					onDragged: function(){console.log('Carousel type on')},
					dots: true
				});
			}
			
			//Carousel Swiper init 
			if (vm.isproperty("layouttype") == "carousel" || vm.isproperty("layouttype") == "carousel2" ){
				setTimeout(function(){
				if ($('.s_'+ vm.uid + '_swiper-container')){
					if ($('.s_'+ vm.uid + '_swiper-container').hasClass('swiper-container-initialized')){
						var prevswiper = document.querySelector('.s_'+ vm.uid + '_swiper-container').swiper;
						prevswiper.destroy();
					}
					var Carouselswiper = new Swiper('.s_'+ vm.uid + '_swiper-container', {
centerInsufficientSlides: true,						
					loop: true,
					loopAdditionalSlides: 1,
					pagination: {
						el: '.swiper-pagination',
						clickable: true
					},
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					},
					watchOverflow: true
					});
				}
				},10);
				//},300);
			}
			
			
			$( "#" + vm.uid + "_tabs" ).tabs();
			if (vm.Tabs.length > 0)
			{
				for (var i=0; i < vm.Tabs.length; i++ )
				{
					$( "#" + vm.uid + "_tabs-" + i.toString() ).tabs();
				}
			}  
		},10);
			
		//}, 200);

		console.log(vm.isproperty("hasdetail"));
		if (vm.isproperty("hasdetail") == "1"){
			//$('#' + vm.uid).on('click', '.viewdetail', function (e) {
			$('.viewdetail').unbind('click').bind('click', function(e){

				var tab = $(e.target).parents('.tab-contents')[0];
				var id = $(tab).attr('id');
				var uID = $(this).attr('data-uid');
				var index = $(this).attr('data-tabindex');
				//console.log(id);
				

				if ($('#' + id + '_detail').hasClass('hidden'))
				{	
						$(this).addClass('hidden');
						//$('#'+uID+'-view-less-'+index).removeClass('hidden');
						$('#' + id + '_detail .view-less').attr('data-targetid', id );
						$('#' + id + '_detail .view-less').attr('data-targetdetails', id + '_detail');
						// $('#' + id + ' .detail-hidden').addClass('hidden');
					//Calender initiatize
					if (!$('#' + id + '_detail').hasClass('CalInit')){
						
						if ( $('#' + id + '_detail .jenDatePicker') && $('#' + id + '_detail .jenDatePicker').hasClass('Calendarblock')){
								
								//console.log('todate: '+fromDate + ' - ' +toDate );
								// For Display Date 
								$('#' + id + '_detail .startDate_class').val(fromDate);
								$('#' + id + '_detail .endDate_class').val(toDate);
								
								// For following date format (yyyy-mm-dd) for DateRangePicker to init and pick date
								$('#' + id + '_detail .startDate_string').val(fromDate_string);
								$('#' + id + '_detail .endDate_string').val(toDate_string);

								
								$('#' + id + '_detail .jenDates').dateRangePicker({
								//format: 'DD MMM (ddd)',
								format: 'YYYY-MM-DD',
								language: language,
								container: '#' + id + '_detail  #jenCalender',
								separator: '-',
								getValue: function() {
								  if (
									$('#' + id + '_detail .startDate_string').val() &&
									$('#' + id + '_detail .endDate_string').val()
								  ){
									return (
									  $('#' + id + '_detail .startDate_string').val() +
									  ' - ' +
									  $('#' + id + '_detail .endDate_string').val()
									);
								  }else {return '';}
								},
								setValue: function(s, s1, s2) {
									$(this).val(s);
									$('#' + id + '_detail .hiddenstartDate').html(s1);
									$('#' + id + '_detail .hiddenendDate').html(s2);
									$('#' + id + '_detail .startDate_string').val(s1);
									$('#' + id + '_detail .endDate_string').val(s2);
									var startDate = new Date(s1);
									var endDate = new Date(s2);
									
									if (language == 'default'){
										startDate = startDate.getDate() +
										' ' +
										$.dateRangePickerLanguages[language]['month-name'][startDate.getMonth()] +
										' (' +
										$.dateRangePickerLanguages[language]['week-day'][startDate.getDay()] +
										')';

										endDate = endDate.getDate() +
										' ' +
										$.dateRangePickerLanguages[language]['month-name'][endDate.getMonth()] +
										' (' +
										$.dateRangePickerLanguages[language]['week-day'][endDate.getDay()] +
										')';
									}else{
										startDate = 
										$.dateRangePickerLanguages[language]['month-name'][startDate.getMonth()] + ('0' + startDate.getDate()).slice(-2) +
										$.dateRangePickerLanguages[language]['day-name'] +
										' (' +
										$.dateRangePickerLanguages[language]['week-day'][startDate.getDay()] +
										')';

										endDate = 
										$.dateRangePickerLanguages[language]['month-name'][endDate.getMonth()] + ('0' + endDate.getDate()).slice(-2) +
										$.dateRangePickerLanguages[language]['day-name'] +
										' (' +
										$.dateRangePickerLanguages[language]['week-day'][endDate.getDay()] +
										')';
									/*
										startDate = startDate.getFullYear() + $.dateRangePickerLanguages[language]['year-name'] +
										$.dateRangePickerLanguages[language]['month-name'][startDate.getMonth()] + ('0' + startDate.getDate()).slice(-2) +
										$.dateRangePickerLanguages[language]['day-name'];

										endDate = endDate.getFullYear() + $.dateRangePickerLanguages[language]['year-name'] +
										$.dateRangePickerLanguages[language]['month-name'][endDate.getMonth()] + ('0' + endDate.getDate()).slice(-2) +
										$.dateRangePickerLanguages[language]['day-name'];
									*/	
										
									}
									
									$('#' + id + '_detail .startDate_class').val(startDate);
									$('#' + id + '_detail .endDate_class').val(endDate);
								
								  //$('#' + id + '_detail .startDate_class').val(s1);
									vm.DPstartDate = s1;
								  //$('#' + id + '_detail .endDate_class').val(s2);
									vm.DPendDate = s2;
								},
								inline: true,
								startDate: new Date(),
								selectForward: true,
								updatePicker: true,
								firstDate: $('#' + id + '_detail .startDate_class'),
								lastDate: $('#' + id + '_detail .endDate_class'),
								hoverDate: $('#' + id + '_detail .jenDays'),
								minDays: 2,
								showTopbar: false,
								singleMonth: '1',
								alwaysOpen: true,
								maxDays: 181,
								startOfWeek: 'monday',
								callback: function(startDate, endDate, period) {
								  //console.log(startDate.format('YYYY'));

								},
							  });

								 $('#' + id + '_detail').addClass('CalInit');
						  }
						  
						  if($('#' + id + '_detail .jenTimePicker') && $('#' + id + '_detail .jenTimePicker').hasClass('Calendarfields')){
								 $('#' + id + '_detail .selectDate').val(fromDate);									
								 $('#' + id + '_detail .selectDate_string').val(fromDate_string_2);
								 $('#' + id + '_detail .selectDate_eat2eat').val(fromDate_string);
								 //console.log($('#' + id + '_detail .selectDate_string').val());
								
								if (window.outerWidth > 1024){
								$('#' + id + '_detail .selectDate').dateRangePicker({
									format: 'YYYY/MM/DD',
									language: language,
									container: '#' + id + '_detail #jenRCalender',
									separator: '/',
									getValue: function() {
									if ($('#' + id + '_detail .selectDate_string').val() ){
									  return $('#' + id + '_detail .selectDate_string').val();
									  }else{
									  return '';
									  }
									},
									setValue: function(s) {
									$('#' + id + '_detail .selectDate_string').val(s);
									
									var selectedDate = new Date(s);
									var selectedDate2 = new Date(s).yyyymmdd();
									
									if (language == 'default'){
									selectedDate = selectedDate.getDate() +
									' ' +
									$.dateRangePickerLanguages[language]['month-name'][selectedDate.getMonth()] +
									' (' +
									$.dateRangePickerLanguages[language]['week-day'][selectedDate.getDay()] +
									')';
									}else{
									/*
									selectedDate = selectedDate.getFullYear() + $.dateRangePickerLanguages[language]['year-name'] +
											$.dateRangePickerLanguages[language]['month-name'][selectedDate.getMonth()] + ('0' + selectedDate.getDate()).slice(-2) +
											$.dateRangePickerLanguages[language]['day-name'];
									*/
									selectedDate = $.dateRangePickerLanguages[language]['month-name'][selectedDate.getMonth()] + ('0' + selectedDate.getDate()).slice(-2) +
											$.dateRangePickerLanguages[language]['day-name'] +
											' (' +
											$.dateRangePickerLanguages[language]['week-day'][selectedDate.getDay()] +
											')';
									}

									  $('#' + id + '_detail .selectDate').val(selectedDate);
									  $('#' + id + '_detail .selectDate_eat2eat').val(selectedDate2);
									  vm.reservedDate = s;
									},
									inline: false,
									autoClose: true,
									alwaysOpen: false,
									singleDate: true,
									startDate: new Date(),
									selectForward: true,
									updatePicker: false,
									showTopbar: false,
									singleMonth: '1',
									startOfWeek: 'monday',
							  });
							  }else{

								var now = new Date();
								var yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
								var tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));
								var nextMonths = new Date(now.getFullYear(), now.getMonth() + 12, now.getDate());
								var day = 24 * 60 * 60 * 1000;

								$('#' + id + '_detail .selectDate').mobiscroll().range({
									calendarScroll: 'vertical',
									lang: language_m,         // More info about calendarScroll: https://docs.mobiscroll.com/4-10-3/calendar#opt-calendarScroll
									//dateFormat: 'dd M (D) ',
									dateFormat: 'yy-mm-dd',
									theme: 'ios',
									themeVariant: 'light' , 
									display: 'bottom',
									min: now,
									max:  nextMonths,
									counter: true,
									selectedText: "{count}",
									selectedPluralText: "{count}",
								onInit: function(event, inst){
									
									console.log(inst);
									inst.setVal([now,now]);
									inst.startVal = inst.startVal.replace(/-/g, "/");
									// inst.endVal = inst.endVal.replace(/-/g, "/");
									inst._tempValue = inst.startVal + ' - ' + inst.startVal

									$('#' + id + '_detail .selectDate_string').val(inst._tempValue);
								
									var selectedDate = new Date();
									// var selectedDate2 = new Date(inst._tempValue).yyyymmdd();
									var selectedDate2 = new Date();
									
									if (language == 'default'){
										selectedDate = selectedDate.getDate() +
											' ' +
											$.dateRangePickerLanguages[language]['month-name'][selectedDate.getMonth()] +
											' (' +
											$.dateRangePickerLanguages[language]['week-day'][selectedDate.getDay()] +
											')';
									}else{
										$.dateRangePickerLanguages[language]['month-name'][selectedDate.getMonth()] + 
										('0' + selectedDate.getDate()).slice(-2) +
										$.dateRangePickerLanguages[language]['day-name'] +
										' (' +
										$.dateRangePickerLanguages[language]['week-day'][selectedDate.getDay()] +
										')';
									/*
										selectedDate = selectedDate.getFullYear() + $.dateRangePickerLanguages[language]['year-name'] +
											$.dateRangePickerLanguages[language]['month-name'][selectedDate.getMonth()] + selectedDate.getDate() +
											$.dateRangePickerLanguages[language]['day-name'];
									*/
									}

									  $('#' + id + '_detail .selectDate').val(selectedDate);
									  $('#' + id + '_detail .selectDate_eat2eat').val(selectedDate2);
									  $('#' + id + '_detail .selectDate').val();
									  $('#' + id + '_detail .selectDate_eat2eat').val();
									
									
									
								},
								onDayChange: function(event, inst){
									console.log(event)
									console.log(inst)
								},
								onSet: function (event, inst) {
									//console.log(inst.getDate());
									$('#' + id + '_detail .selectDate_string').val(inst._tempValue);
								
									var selectedDate = new Date(inst.startVal);
									var selectedDate2 = new Date(inst.startVal).yyyymmdd();
									// var selectedDate = new Date(inst._tempValue);
									// var selectedDate2 = new Date(inst._tempValue).yyyymmdd();
									if (language == 'default'){
									selectedDate = selectedDate.getDate() +
									' ' +
									$.dateRangePickerLanguages[language]['month-name'][selectedDate.getMonth()] +
									' (' +
									$.dateRangePickerLanguages[language]['week-day'][selectedDate.getDay()] +
									')';
									}else{
										$.dateRangePickerLanguages[language]['month-name'][selectedDate.getMonth()] + 
										('0' + selectedDate.getDate()).slice(-2) +
										$.dateRangePickerLanguages[language]['day-name'] +
										' (' +
										$.dateRangePickerLanguages[language]['week-day'][selectedDate.getDay()] +
										')';
										// selectedDate = 
										// 	$.dateRangePickerLanguages[language]['month-name'][selectedDate.getMonth()] +
										// 	('0' + selectedDate.getDate()).slice(-2) + 
										// 	$.dateRangePickerLanguages[language]['day-name'];
										// 	' (' +
										// 	$.dateRangePickerLanguages[language]['week-day'][selectedDate.getDay()] +
										// 	')';

									/*selectedDate = selectedDate.getFullYear() + $.dateRangePickerLanguages[language]['year-name'] +
											$.dateRangePickerLanguages[language]['month-name'][selectedDate.getMonth()] + selectedDate.getDate() +
											$.dateRangePickerLanguages[language]['day-name'];
									*/
									}

									  $('#' + id + '_detail .selectDate').val(selectedDate);
									  $('#' + id + '_detail .selectDate_eat2eat').val(selectedDate2);
									//$('#slider2jenstartBDate').val(inst.startVal);
									//var mobiscroll_selectedDate = new Date(inst.startVal);
									//console.log(mobiscroll_selectedDate);

									
									
									
									//inst.settings.min = inst._startDate;
								},
						
							});
							
							}
							$('#' + id + '_detail').addClass('CalInit');
						  }
						  
						  
					}

					$('#' + id + '_detail').removeClass('hidden');
					//$(e.target).html('view less');
					$(this).parent().addClass('viewOpen');
					if ($(this).hasClass('withBanner')){
					//console.log('Banner Reveal');
					$('#' + id + ' .room-detail-banner').removeClass('hidden' );
					}				
				}
				else
				{	
					$(this).addClass('hidden');
					$('#'+uID+'-view-details-'+index).removeClass('hidden');
					$('#' + id + '_detail').addClass('hidden');
					$(this).parent().removeClass('viewOpen');
					//$(e.target).html('view detail');
					if ($(this).hasClass('withBanner')){
					$('#' + id + ' .room-detail-banner').addClass('hidden' );
					}		
				}
				

			});
			
			//Detail Blcok View Less
			$('.view-less').unbind('click').bind('click', function(e){
				e.preventDefault();
				var targetID = $(this).attr('data-targetid');
				var targetdetails = $(this).attr('data-targetdetails');
				if (targetdetails && targetdetails != ''){
					//console.log('details view less for ' + targetdetails);
					$('#' + targetdetails).addClass('hidden');
					$('#' + targetID +  ' .viewdetail').removeClass('hidden');
					$('#' + targetID +  ' .detail-hidden').removeClass('hidden');
					$('html, body').animate({
					scrollTop: $('#'+targetID).offset().top -250
					}, 200)
				}
			});
			
			$('.viewsubdetail').unbind('click').bind('click', function(e){
				var tab = $(e.target).parents('.subtab-contents')[0];
				var id = $(tab).attr('id');
				if ($('#' + id + '_detail').hasClass('hidden'))
				{
					$('#' + id + '_detail').removeClass('hidden');
					//$(e.target).html('view less');
					$(this).parent().addClass('viewOpen');
					$('#' + id + ' .sub-view-less').attr('data-targetid', id);
					$(this).addClass('hidden');
					$('#'+ id + ' .viewsubdetail').addClass('hidden');
				}
				else
				{
					$('#' + id + '_detail').addClass('hidden');
					$(this).parent().removeClass('viewOpen');
					//$(e.target).html('view detail');
					
				}
			});
			
			//Detail Blcok Sub View Less
			$('.sub-view-less').unbind('click').bind('click', function(e){
				e.preventDefault();
				var targetID = $(this).attr('data-targetid');				
				if (targetID && targetID != ''){
					//console.log('details view less for ' + targetID);
					$('#' + targetID + '_detail').addClass('hidden');
					$('#' + targetID +  ' .viewsubdetail').removeClass('hidden');
					//$('#' + targetID +  ' .detail-hidden').removeClass('hidden');
				}
			});
		}
	
	}	//END: check mobile
  },
  created: function() {
	//serverBus.$emit('headerMounted', this.fields.data.hotels, this.$root);
  },
  updated: function() {
  
  	let vm = this;

	//Set currency
    var cur_currency = localStorage.getItem('curcurrency')
    var currentCurrency = ''

    if(cur_currency){
      vm.$jss.store.setCurrency(cur_currency);
    }else {
      vm.$jss.store.setCurrency('USD')
	}
	//Set currency END

	if (this.$jss.hotels() != null)
	{

//add simple support for background images:
document.addEventListener('lazybeforeunveil', function(e){
    var bg = e.target.getAttribute('data-bg');
    if(bg){
        e.target.style.backgroundImage = 'url(' + bg + ')';
    }
});
	//console.log(vm.data_datasource);

	if (!(vm.isproperty('hideformobile') === '1'))
	{
		vm.hideformobile = false
		//console.log('not hide for mobile');
	}
	else
	{
		vm.hideformobile = true;
		//console.log('hide for mobile');
	}

	if (!(vm.ismobile && vm.hideformobile))
	{
		$(document).ready(function() {
			var likes = {likes:[]};
			if (getCookie('HJ_GLOBAL_COOKIE') == null) {
				likes = {likes:[]};
			} else {
				var likes_json = getCookie('HJ_GLOBAL_COOKIE');			
				likes = JSON.parse(likes_json);
			}
			//console.log($('#' + vm.uid + ' .contenttile'));
			$('#' + vm.uid + ' .contenttile').each(function(){ 
				var itemid = $(this).attr('data-itemid');
				if (likes.likes.length > 0)
				{
					for (var i = likes.likes.length-1; i >= 0; i--)
					{
						if (likes.likes[i]["id"] == itemid)
						{
							//console.log(likes.likes[i]["id"]);
							$(this).find('.fav-btn').addClass('active');
						}
					}
				}
			});
			if (vm.isproperty("layouttype") == "grid1" || vm.isproperty("layouttype") == "grid2")
			{			
				//$('.destination-container').html(getDestinationFooter(null, vm, null, vm.slidegroups));
				
				$('.destination-container .img-container a').on('click', function(e){
					e.preventDefault();
					if ($(this).attr('href') != undefined && $(this).attr('href') != '')
					{
						window.location = $(this).attr('href');
					}
				} );
				
				var swiper = new Swiper('.destination-swiper-container', {
centerInsufficientSlides: true,					
								loop: true,
								pagination: {
									el: '.swiper-pagination',
									clickable: true
								},
								navigation: {
									nextEl: '.swiper-button-next',
									prevEl: '.swiper-button-prev',
								},
							});
			}
			$('.book').off('click').on('click', function(e) {
				var language = 'en'; 
				if ( vm.$jss.routeData().itemLanguage == 'zh-CN' || vm.$jss.routeData().itemLanguage == 'zh'){
					language = 'cn'; 
				}else{
					language = vm.$jss.routeData().itemLanguage.substring(vm.$jss.routeData().itemLanguage.length-2).toLowerCase()			
				} 
				var key = vm.$jss.hjconfig().encryption_key;
				var iv = vm.$jss.hjconfig().encryption_iv;
				var propertycode = "";
				var propertycityalias = "";
				var propertyhotelalias = "";
				if ($(this).attr('data-bookingurl'))
				{
					propertycode = $(this).attr('data-bookingurl').split('|')[2];
					propertycityalias = $(this).attr('data-bookingurl').split('|')[0];
					propertyhotelalias = $(this).attr('data-bookingurl').split('|')[1];
				}
				var book_url = vm.$jss.hjconfig().book_url;
				if (propertycode != '' && propertycityalias != '' && propertyhotelalias != '')
				{
					bookhotel(key, iv, book_url, propertycode, propertycityalias, propertyhotelalias, null, null, null, null, 'None', '', $(this).attr('data-roomcode'), '', language);
				}
			});
		});

		var isloopCarousel = false;
		var isloopLinks = false;
		if (vm.slides.length > 1){
			isloopCarousel = true;
		}else{
			isloopCarousel = false;
		}
		
		
	 
		
		
		//Check for Border 
		if ($('#'+vm.uid).hasClass('top-border-right')){
			$('#'+vm.uid+'-top-border-right').removeClass('hidden');
		}

		if ($('#'+vm.uid).hasClass('top-border-left')){
			$('#'+vm.uid+'-top-border-left').removeClass('hidden');
		}		
		
		if ($('#'+vm.uid).hasClass('bottom-border-right')){
			$('#'+vm.uid+'-bottom-border-right').removeClass('hidden');
		}		
		
		if ($('#'+vm.uid).hasClass('bottom-border-left')){
			$('#'+vm.uid+'-bottom-border-left').removeClass('hidden');
		}
		
		
		

		
		//Nico Accordion JS
		$('.society-content-box .close-btn-w').click(function(e){
		e.preventDefault()
		e.stopPropagation();
		$(this).parent().removeClass('active')
		});
		
		/*$('div.details-block').click(function(e){
			e.preventDefault()
			e.stopPropagation();
			
			var detail_blocks = document.querySelectorAll('.details-block')
			for(var x = 0; x < detail_blocks.length; x++){
				detail_blocks[x].classList.remove('active')
			}

			$(this).addClass('active')
		});
		$('div.event-details-box').click(function(e){
			e.preventDefault()
			e.stopPropagation();

		});*/
		$('.details-block').mouseover(function(){
    
			var detail_blocks = document.querySelectorAll('.details-block')
			for(var x = 0; x < detail_blocks.length; x++){
				detail_blocks[x].classList.remove('active')
			}
			$(this).addClass('active')
		})

		$('.details-block').mouseout(function(){
			
			var detail_blocks = document.querySelectorAll('.details-block')
			for(var x = 0; x < detail_blocks.length; x++){
				detail_blocks[x].classList.remove('active')
			}

		})
		
		$('.accordion-link').unbind('click').bind('click', function(){
			var link = $(this).attr('data-link');
			window.location=link;
		
		});

		/*$('div.details-block').click(function(e){
			// e.preventDefault()
			// e.stopPropagation();
			
			var detail_blocks = document.querySelectorAll('.details-block')
			for(var x = 0; x < detail_blocks.length; x++){
				detail_blocks[x].classList.remove('active')
			}

			$(this).addClass('active')
		});*/
		
		//Open Popup
		$('#'+vm.uid +' .accordion-cta').unbind('click').bind('click', function(e){
			e.preventDefault();
			var moduleID = $(this).data('moduleid');
			AddDataTrackingBinding(this,e);
			if ($(this).attr('href') == '#'){
				$('.'+vm.uid+'_popup-signup-container #becomeamember-modal').addClass('active')
				$('.booking-nav').css("display","none")
        		$('#jenBbooking-nav').css('display','none')
				popup('signup', moduleID);
				if(window.innerWidth < 1025 && !e.isTrigger > 0){
					document.body.style.setProperty("top",`-${window.scrollY}px`)
					document.body.style.setProperty("position","fixed")
				}
			}else{
				window.open($(this).attr('href'));
			}
		}); 

		$('#'+vm.uid +' .rfp-link').unbind('click').bind('click', function(e){
			e.preventDefault();
			const hoteldetail = vm.hoteldetail || {};
			const hotelCode = hoteldetail.hotelcode && hoteldetail.hotelcode.value || '';
			const hotelName = hoteldetail.fullname && hoteldetail.fullname.value || '';
			
			var language = 'en'; 
			if ( vm.$jss.routeData().itemLanguage == 'zh-CN' || vm.$jss.routeData().itemLanguage == 'zh'){
				language = 'cn'; 
			}else{
				language = vm.$jss.routeData().itemLanguage.substring(vm.$jss.routeData().itemLanguage.length-2).toLowerCase()			
			} 
			if ($(this).attr('href') == '#'){
				window.open(vm.$jss.hjconfig().book_url + language + '/corporate/meetings-events/find-a-venue/?hotelCode=' + hotelCode + '&hotel=' + hotelName);
			}else{
				window.open($(this).attr('href'));
			}
		});


		// $('#'+vm.uid +' .rfp-form-popup').unbind('click').bind('click', function(e){
		// 	e.preventDefault();
		// 	var moduleID = $(this).data('moduleid');
		// 	AddDataTrackingBinding(this,e);
		// 	if ($(this).attr('href') == '#'){
		// 		$('.'+vm.uid+'_popup-signup-container .becomeamember-modal-rfp').addClass('active')
		// 		$('.booking-nav').css("display","none")
        // 		$('#jenBbooking-nav').css('display','none')
		// 		popup('signup', moduleID);
		
		// 		if(window.innerWidth < 1025 && !e.isTrigger > 0){
		// 			document.body.style.setProperty("top",`-${window.scrollY}px`)
		// 			document.body.style.setProperty("position","fixed")
		// 		}
		// 	}else{
		// 		window.open($(this).attr('href'));
		// 	}
		// });

		// $('#'+vm.uid +' .hostslider-container .subtab-contents .rfp-form-popup').unbind('click').bind('click', function(e){
		// 	e.preventDefault();
		// 	$('#jenBbooking-nav').css('display','none')
		// 	$('.textCTA.rfp-form-popup').trigger('click');
		// 	if(window.innerWidth < 1025){
		// 		document.body.style.setProperty("top",`-${window.scrollY}px`)
		// 		document.body.style.setProperty("position","fixed")
		// 	}
		// })

		//RFP Trigger CTA
		// $('.rfp-trigger-cta').unbind('click').bind('click', function(e){
		// 	e.preventDefault();
		// 	$('#jenBbooking-nav').css('display','none')
		// 	$('.textCTA.rfp-form-popup').trigger('click');
		// 	if(window.innerWidth < 1025){
		// 		document.body.style.setProperty("top",`-${window.scrollY}px`)
		// 		document.body.style.setProperty("position","fixed")
		// 	}
		// });
		
		$('#'+vm.uid+' .main-tabs').unbind('click').bind('click', function(e){
			e.preventDefault();
			//console.log('main-tabs');
			//var relatedContentId = $(this).attr('href');
			var relatedContentId = $(this).data('tabcontent');
			var moduleId = $(this).data('moduleid');
			var slickIndex = $(this).data('slickindex');
			var mainTab = 0;
			if ($(this).data('maintab') && $(this).data('maintab') != ''){
			 mainTab = $(this).data('maintab');
			}
			//console.log('relatedContent ID: ' +relatedContentId+'; moduleID: '+moduleId+'_tabs');
			$('#'+moduleId + '_tabs .main-tabs').removeClass('active');
			$('#'+moduleId + '_tabs .main-tabs').removeClass('swiper-slide-thumb-active');
			$(this).addClass('active');
			$(this).addClass('swiper-slide-thumb-active');
			$('#'+moduleId+'_tabs .tab-contents').addClass('hidden');
			$('#'+moduleId+'_tabs .services-container').addClass('hidden');
			
			$(relatedContentId).removeClass('hidden');
			$('#'+ moduleId + '_tabs-' + mainTab + ' .services-container').removeClass('hidden');
			
			
			$('.'+ moduleId + '_tabs-' + mainTab + '_subtab-0').trigger('click');
			
			// Initate Slick when Tabs is clicked
			if (vm.Tabs.length > 0){
			
				if ($('.tabs_'+ moduleId +'_Tabslider_'+ slickIndex).hasClass('swiper-container-initialized')){
				
					var prevswiper = document.querySelector('.tabs_'+ moduleId +'_Tabslider_'+ slickIndex).swiper;
					prevswiper.destroy();
					var TabContentSlider = new Swiper('.tabs_'+ moduleId +'_Tabslider_'+ slickIndex , {
						centerInsufficientSlides: true,							
						spaceBetween: 25,
						loop:$('.tabs_'+ moduleId +'_Tabslider_'+ slickIndex + ' .swiper-slide').length > 1 ? true : false,
						loopAdditionalSlides: 1,
						navigation: $('.tabs_'+ moduleId +'_Tabslider_'+ slickIndex + ' .swiper-slide').length > 1 ? { nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' } : {},
						// navigation: {
						// 	nextEl: '.swiper-button-next',
						// 	prevEl: '.swiper-button-prev',
						// },
						pagination: {
							el: '.swiper-pagination',
							clickable: true
						},
					});
					
				}else{

					var TabContentSlider = new Swiper('.tabs_'+ moduleId +'_Tabslider_'+ slickIndex , {
						centerInsufficientSlides: true,							
						spaceBetween: 25,
						loop:$('.tabs_'+ moduleId +'_Tabslider_'+ slickIndex + ' .swiper-slide').length > 1 ? true : false,
						loopAdditionalSlides: 1,
						navigation: $('.tabs_'+ moduleId +'_Tabslider_'+ slickIndex + ' .swiper-slide').length > 1 ? { nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' } : {},
						// navigation: {
						// 	nextEl: '.swiper-button-next',
						// 	prevEl: '.swiper-button-prev',
						// },
						pagination: {
							el: '.swiper-pagination',
							clickable: true
						},
					});
				
				}
				
											
				
				if ($('.subtabs_'+ moduleId + '_booking-thumb_'+ mainTab+' .gallery-thumbs').hasClass('swiper-container-initialized')){
						var prevswiper = document.querySelector('.subtabs_'+ moduleId + '_booking-thumb_'+ mainTab+' .gallery-thumbs').swiper;
						prevswiper.destroy();
						var subtabThumbSlider = new Swiper('.subtabs_'+ moduleId + '_booking-thumb_'+ mainTab+' .gallery-thumbs', {
							centerInsufficientSlides: true,							
							slidesPerView: 'auto',
							// loop: true,
							freeMode: true,
							navigation: $('.tabs_'+ moduleId +'_Tabslider_'+ slickIndex + ' .swiper-slide').length > 1 ? { nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' } : {},
							navigation: {
								nextEl: '.swiper-button-next',
								prevEl: '.swiper-button-prev',
							},
							watchSlidesVisibility: true,
							watchSlidesProgress: true,
							breakpoints: {
							1025: {
								slidesPerView: 3,
							}
							},
						});

				}else{
						var subtabThumbSlider = new Swiper('.subtabs_'+ moduleId + '_booking-thumb_'+ mainTab+' .gallery-thumbs', {
							centerInsufficientSlides: true,							
							slidesPerView: 'auto',
							// loop: true,
							freeMode: true,
							navigation: {
								nextEl: '.swiper-button-next',
								prevEl: '.swiper-button-prev',
							},
							watchSlidesVisibility: true,
							watchSlidesProgress: true,
							breakpoints: {
							1025: {
								slidesPerView: 3,
							}
						},								
				});
				
				}
				
				
								
				
				//New Service Slider (Swiper)
				if ($('.services-container-' + moduleId +'-tab-' + mainTab +' .services-slider').hasClass('swiper-container-initialized')){
					var prevswiper = document.querySelector('.services-container-' + moduleId +'-tab-' + mainTab +' .services-slider').swiper;
					prevswiper.destroy();
					var servicesSlider =  new Swiper('.services-container-' + moduleId +'-tab-' + mainTab +' .services-slider', {
							centerInsufficientSlides: true,					
							spaceBetween: 25,
							loop:false,
							slidesPerView: 1,
							navigation: {
								nextEl: '.services-container-' + moduleId +'-tab-' + mainTab +' .services-swiper-control .swiper-button-next',
								prevEl: '.services-container-' + moduleId +'-tab-' + mainTab +' .services-swiper-control .swiper-button-prev',
							},
							pagination: {
								el: '.services-container-' + moduleId +'-tab-' + mainTab +' .swiper-pagination',
								clickable: true
							},
							breakpoints: {
								1025: {
									slidesPerView: 2,
								}
							},							
						});
					$('.services-container-' + moduleId +'-tab-' + mainTab +' .services-slider').css('overflow','hidden');
				}else{

					var servicesSlider =  new Swiper('.services-container-' + moduleId +'-tab-' + mainTab +' .services-slider', {
							centerInsufficientSlides: true,					
							spaceBetween: 25,
							loop:false,
							slidesPerView: 1,
							navigation: {
								nextEl: '.services-container-' + moduleId +'-tab-' + mainTab +' .services-swiper-control .swiper-button-next',
								prevEl: '.services-container-' + moduleId +'-tab-' + mainTab +' .services-swiper-control .swiper-button-prev',
							},
							pagination: {
								el: '.services-container-' + moduleId +'-tab-' + mainTab +' .swiper-pagination',
								clickable: true
							},
							breakpoints: {
								1025: {
									slidesPerView: 2,
								}
							},									
						});
					$('.services-container-' + moduleId +'-tab-' + mainTab +' .services-slider').css('overflow','hidden');
				}
				
				
			}
		});	
		
		
		$('.main-subtabs').unbind('click').bind('click', function(e){
			e.preventDefault();
			//var relatedContentId = $(this).attr('href');
			var relatedContentId = $(this).data('subtabcontent');
			//console.log('relatedContentId: ' + relatedContentId);
			//var moduleId = $(this).parent().parent().parent().attr('id');
			var activeTab = $(this).data('maintab');
			var activeSubTab = $(this).data('mainsubtab');
			var contentblockID = $(this).data('moduleid');
			var moduleId = contentblockID + '_tabs-' + activeTab;
			$('#'+moduleId+' .main-subtabs').removeClass('active');
			$(this).addClass('active');
			//console.log('relatedContent ID: ' +relatedContentId+'; moduleID: '+moduleId);
			$('#'+moduleId+' .subtab-contents').addClass('hidden');
			$('#'+moduleId+ ' ' + relatedContentId).removeClass('hidden');
			
					// Initate Slick when Tabs is clicked
				// var loopDetails = {}
				// console.log($('.subtabs-' + vm.uid +'_tab_'+activeTab+'_Tabslider_'+activeSubTab + ' .swiper-slide').length)
				// if($('.subtabs-' + vm.uid +'_tab_'+activeTab+'_Tabslider_'+activeSubTab + ' .swiper-slide').length > 1){
				// 	loopDetails = {
				// 		loop:true,
				// 		loopAdditionalSlides: 1
				// 	}
				// }else{ loopDetails = { loop: false  } }
				
				
				if ($('.subtabs-' + contentblockID +'_tab_'+activeTab+'_Tabslider_'+activeSubTab).hasClass('swiper-container-initialized')){
					var prevswiper = document.querySelector('.subtabs-' + contentblockID +'_tab_'+activeTab+'_Tabslider_'+activeSubTab).swiper;
					prevswiper.destroy();
					var subtabContentSlider = new Swiper('.subtabs-' + contentblockID +'_tab_'+activeTab+'_Tabslider_'+activeSubTab, {
						centerInsufficientSlides: true,							
						spaceBetween: 25,
						// loop:false,
						loop: $('.subtabs-' + contentblockID +'_tab_'+activeTab+'_Tabslider_'+activeSubTab + ' .swiper-slide').length > 1 ? true : false,
						loopAdditionalSlides: 1,
						navigation: $('.subtabs-' + contentblockID +'_tab_'+activeTab+'_Tabslider_'+activeSubTab + ' .swiper-slide').length > 1 ? { nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev',} : {},
						// navigation: {
						// 	nextEl: '.swiper-button-next',
						// 	prevEl: '.swiper-button-prev',
						// },
						pagination: {
							el: '.swiper-pagination',
							clickable: true
						}
					});

				}else{
					var subtabContentSlider = new Swiper('.subtabs-' + contentblockID +'_tab_'+activeTab+'_Tabslider_'+activeSubTab, {
						centerInsufficientSlides: true,							
						spaceBetween: 25,
						loop: $('.subtabs-' + contentblockID +'_tab_'+activeTab+'_Tabslider_'+activeSubTab + ' .swiper-slide').length > 1 ? true : false,
						loopAdditionalSlides: 1,
						navigation: $('.subtabs-' + contentblockID +'_tab_'+activeTab+'_Tabslider_'+activeSubTab + ' .swiper-slide').length > 1 ? { nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev',} : {},
						// navigation: {
						// 	nextEl: '.swiper-button-next',
						// 	prevEl: '.swiper-button-prev',
						// },
						pagination: {
							el: '.swiper-pagination',
							clickable: true
						}
					});
				
				}
				
				if($('.subtabs-' + contentblockID +'_tab_'+activeTab+'_Tabslider_'+activeSubTab + ' .swiper-slide').length < 2){
					$('.subtabs-' + contentblockID +'_tab_'+activeTab+'_Tabslider_'+activeSubTab + ' .swiper-wrapper').addClass( "disabled" );
					$('.subtabs-' + contentblockID +'_tab_'+activeTab+'_Tabslider_'+activeSubTab + ' .swiper-button-next,.subtabs-' + contentblockID +'_tab_'+activeTab+'_Tabslider_'+activeSubTab + ' .swiper-button-prev').addClass( "disabled" );
				}
			
		});
		

		var columnimages = [];
		var tempimage = {};
		
		if (vm.isproperty("layouttype") == "parallaxBox"){
		if ( vm.data_datasource['children'] &&  vm.data_datasource['children'].length > 0){
			//columnimages = vm.data_datasource['children'][0]['children'];
			//console.log(columnimages);
			for ( var i = 0; i <  vm.data_datasource['children'][0]['children'].length; i++){
				tempimage['image']= vm.data_datasource['children'][0]['children'][i].image.src;
				columnimages.push(tempimage);
				tempimage = {};
			}
			//console.log(columnimages);
			
			//Parallax Init
			var image_counter = 0;
			var last_known_scroll_position = 0;
			var left_block = document.getElementById('banner-block3');
			var right_block = document.getElementById('banner-block4');


			document.addEventListener('aos:in:block1', function(){

				
				if(window.pageYOffset > last_known_scroll_position){
					hideImage()
					setTimeout(function(){
						left_block.style.setProperty('background-image','url('+ columnimages[2].image +')')
						right_block.style.setProperty('background-image','url('+columnimages[3].image+')')
						showImage()
					}, 1)
					//},400)
					
				}else{
					hideImage()
					setTimeout(function(){
						left_block.style.setProperty('background-image','url('+ columnimages[0].image +')')
						right_block.style.setProperty('background-image','url('+columnimages[1].image+')')
						showImage()
					}, 1);
					//},400)
				}


				last_known_scroll_position = window.pageYOffset;
			});

			document.addEventListener('aos:in:block2', function(){
					//console.log('in block 2');
				if(window.pageYOffset > last_known_scroll_position){
					hideImage()
					setTimeout(function(){
						left_block.style.setProperty('background-image','url('+ columnimages[4].image +')')
						right_block.style.setProperty('background-image','url('+columnimages[5].image+')')
						showImage()
					}, 1)
					//},400)
					
				}else{
					hideImage()
					setTimeout(function(){
						left_block.style.setProperty('background-image','url('+ columnimages[2].image +')')
						right_block.style.setProperty('background-image','url('+columnimages[3].image+')')
						showImage()
					}, 2)
					//},400)
				}


				last_known_scroll_position = window.pageYOffset
			});

			document.addEventListener('aos:in:block3', function(){
				//console.log('in block 3');
				if(window.pageYOffset > last_known_scroll_position){
					hideImage()
					setTimeout(function(){
						left_block.style.setProperty('background-image','url('+ columnimages[6].image +')')
						right_block.style.setProperty('background-image','url('+ columnimages[7].image +')')
						showImage()
					},400)
					
				}else{
					hideImage()
					setTimeout(function(){
						left_block.style.setProperty('background-image','url('+ columnimages[4].image +')')
						right_block.style.setProperty('background-image','url('+ columnimages[5].image +')')
						showImage()
					}, 2);
					//},400)
				}


				last_known_scroll_position = window.pageYOffset
			});

			document.addEventListener('aos:in:societyblock', function(){
				
				if(window.pageYOffset > last_known_scroll_position){
					hideImage()
					
				}else{
					//hideImage()
					setTimeout(function(){
						left_block.style.setProperty('background-image','url('+ columnimages[6].image +')');
						right_block.style.setProperty('background-image','url('+ columnimages[7].image +')');
						showImage();
					},2);	
					//},400);
				}

				last_known_scroll_position = window.pageYOffset;
			});

			function hideImage(){
				left_block.style.setProperty('opacity','0')
				right_block.style.setProperty('opacity','0')
			}

			function showImage(){
				left_block.style.setProperty('opacity','1')
				right_block.style.setProperty('opacity','1')
			}

			AOS.init();
			
			var counter_parallax = 0;
			var checkanchor_Parallax = window.setInterval(function(){
				scrollToSection();
				counter_parallax ++;
				if (counter_parallax > 2){
					clearInterval(checkanchor_Parallax);
				}
			},1);
			//},1000);
			
			function scrollToSection(){
				if ($('.HJContainer').attr('data-hjloadinganchor') != '')
				{					
						$(document).scrollTop($('#' + $ar.cache('.HJContainer').attr('data-hjloadinganchor')).offset().top - 100);	
						//console.log('scroll to section');						
				}			
			}
			
			/*if ($('.HJContainer').attr('data-hjloadinganchor') != '')
				{
					
						setTimeout(function(){
						$(document).scrollTop($('#' + $('.HJContainer').attr('data-hjloadinganchor')).offset().top);
						//console.log('scroll to section');
						}, 1);
						//},1000);
					
				}*/
			
			}
		}
		
		
		
		if (vm.isproperty("layouttype") == "carousel" || 
		vm.isproperty("layouttype") == "carousel2" || 
			vm.isproperty("layouttype") == "carousel-only" ||
			vm.isproperty("layouttype") == "links"){
			
			if (vm.isproperty("layouttype") == "links"){
				
				
			}else if (vm.isproperty("layouttype") == "carousel-only"){
				$('.' + vm.uid + '_owl-carousel').owlCarousel('destroy');
				$('.' + vm.uid + '_owl-carousel').owlCarousel({ 
					loop: isloopCarousel, 
					margin:10, 
					nav:true,
					dots: true,
					autoplay:false,
					/*autoplayTimeout:1000,
					autoplayHoverPause:true,*/
					responsiveClass:true,
					responsive:{
						0:{
							items:1,
							nav:true,
							autoplay:false
						},
						1025:{
							items:1,
							nav:true,
							autoplay:false
						},
						1000:{
							items:1,
							loop: isloopCarousel,
						}
					},
					onDragged: function(){console.log('Carousel type on')},
					dots: true
				});
			}else{
				setTimeout(function(){
				if ($('.s_'+ vm.uid + '_swiper-container')){
					if ($('.s_'+ vm.uid + '_swiper-container').hasClass('swiper-container-initialized')){
						var prevswiper = document.querySelector('.s_'+ vm.uid + '_swiper-container').swiper;
						prevswiper.destroy();
					}
					var Carouselswiper = new Swiper('.s_'+ vm.uid + '_swiper-container', {
					centerInsufficientSlides: true,						
					loop: true,
					loopAdditionalSlides: 1,
					pagination: {
						el: '.swiper-pagination',
						clickable: true
					},
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					},
					watchOverflow: true
					});
				}
				}, 1);
				//},300);
			}
			
			/** Check if only one slide, disable drag **/
			if (vm.slides.length == 1){		
				$(".disable-owl-swipe").on("touchstart mousedown", function(e) {
					// Prevent carousel swipe
					e.stopPropagation();
				});
			}
			
		}else{
			$('.' + vm.uid + '_owl-carousel').owlCarousel('destroy');
			$('.' + vm.uid + '_owl-carousel').owlCarousel({ 
				loop:true, 
				margin:10, 
				nav:true,
				dots: true,
				autoplay:false,
				/*autoplayTimeout:1000,
				autoplayHoverPause:true,*/
				responsiveClass:true,
				responsive:{
					0:{
						items:1,
						nav:true,
						autoplay:false
					},
					1025:{
						items:2,
						nav:true,
						autoplay:false
					},
					1000:{
						items:3,
						loop:true
					}
				},
				onDragged: callback
			});
			
		
			//if (vm.isproperty("cssclass") == "gallery"){
			if (vm.isproperty("layouttype") == "gallery"){
				
			// For Gallery Tabs
				if ($('.' + vm.uid + '_slick').hasClass('slick-initialized')){
					$('.' + vm.uid + '_slick').slick('unslick');
				}
				$('.' + vm.uid + '_slick').not('.slick-initialized').slick({
					dots: false,
					infinite: false,
					speed: 400,
					slidesToShow: 3,
					centerMode: false,
					arrows: true,
					//asNavFor: '.' + vm.uid + '_slick_gallery',
					//focusOnSelect: true,
					responsive: [
						{
						  breakpoint: 1025,
						  settings: {
							slidesToShow: 1,
							slidesToScroll: 1,
							infinite: false,
							dots: false,
							centerMode: false,
							focusOnSelect: true
						  }
						}]
				}).on('afterChange', function(event, slick, currentSlide){

				});	
					
			// For Gallery Slides	
				if ($('.' + vm.uid + '_slick_gallery').hasClass('slick-initialized')){
					$('.' + vm.uid + '_slick_gallery').slick('unslick');
				}
				$('.' + vm.uid + '_slick_gallery').not('.slick-initialized').slick({
					dots: true,
					infinite: false,
					speed: 600,
					//slidesToShow: 3,
					arrows: true,
					centerMode: true,
					centerPadding: '8%',
					slidesToShow: 1,
					//asNavFor: '.' + vm.uid + '_slick',
					responsive: [
						{
						  breakpoint: 1025,
						  settings: {
							slidesToShow: 1,
							slidesToScroll: 1,
							infinite: false,
							dots: true,
							arrows: false,
							centerMode: false
						  }
						}]
					  
				}).on('swipe', function(slick, direction){
					if ($('.' + vm.uid + '_slick_gallery .slick-current').hasClass('tab-slide') || $('.' + vm.uid + '_slick_gallery .slick-current').hasClass('lasttab-slide')){
							//console.log('Tab Slide On');
							var moduleid = $('.' + vm.uid + '_slick_gallery .slick-current').data('moduleid');
							var tabid = $('.' + vm.uid + '_slick_gallery .slick-current').data('maintab');
							
							if (!$('.'+moduleid+'_tab-'+tabid).hasClass('active')){
								var galleryTabs = $('.'+moduleid+'_tab-'+tabid).parent().parent().data('slick-index');
								$('.' + vm.uid + '_slick').not('.slick-initialized').slick('slickGoTo', galleryTabs);
								$('.'+moduleid+'_tab-'+tabid).trigger('click');
							}
							//if (window.innerWidth <= 768){
								
							//}
							

						}
				
				}).on('afterChange', function(event, slick, currentSlide ){
				//console.log(event);
					if ($('.' + vm.uid + '_slick_gallery .slick-current').hasClass('tab-slide') || $('.' + vm.uid + '_slick_gallery .slick-current').hasClass('lasttab-slide')){
							//console.log('Tab Slide On');
							var moduleid = $('.' + vm.uid + '_slick_gallery .slick-current').data('moduleid');
							var tabid = $('.' + vm.uid + '_slick_gallery .slick-current').data('maintab');
							
							
							
							if (window.innerWidth <= 1024){
								var galleryTabs = $('.'+moduleid+'_tab-'+tabid).parent().parent().data('slick-index');
								$('.' + vm.uid + '_slick').not('.slick-initialized').slick('slickGoTo', galleryTabs);
							}
							
							if (!$('.'+moduleid+'_tab-'+tabid).hasClass('active')){
								$('.'+moduleid+'_tab-'+tabid).trigger('click');
								$('.gallery-tabs').removeClass('active');
								$('.'+moduleid+'_tab-'+tabid).addClass('active');
							}
						}else if (!$('.' + vm.uid + '_slick_gallery .slick-current').hasClass('tab-slide') || !$('.' + vm.uid + '_slick_gallery .slick-current').hasClass('lasttab-slide')){
							var moduleid = $('.' + vm.uid + '_slick_gallery .slick-current').data('moduleid');
							var tabid = $('.' + vm.uid + '_slick_gallery .slick-current').data('maintab');
							
							
							
							if (window.innerWidth <= 1024){
								var galleryTabs = $('.'+moduleid+'_tab-'+tabid).parent().parent().data('slick-index');
								$('.' + vm.uid + '_slick').not('.slick-initialized').slick('slickGoTo', galleryTabs);
							}
							
							if (!$('.'+moduleid+'_tab-'+tabid).hasClass('active')){
								//$('.'+moduleid+'_tab-'+tabid).trigger('click');
								$('.gallery-tabs').removeClass('active');
								$('.'+moduleid+'_tab-'+tabid).addClass('active');
							}						
						}
				});
				
				$('.gallery-tabs').on('click', function(e){
					e.preventDefault();
					var relatedContentId = $(this).attr('href');
					var moduleId = $(this).data('moduleid');
					var slickIndex = $('.'+relatedContentId+':not("slick-cloned")').data('slick-index');
					
					$('#'+moduleId + '_tabs .gallery-tabs').removeClass('active');
					$(this).addClass('active');
					$('.' + vm.uid + '_slick_gallery').not('.slick-initialized').slick('slickGoTo', slickIndex);		
				});
				
			
			}else{
								
				
				// Initiate Tabs Thumb (Swiper )
				var tabsThumbSlider = new Swiper('.tabs_'+vm.uid+'_booking-thumbs .gallery-thumbs', {
					centerInsufficientSlides: true,
					slidesPerView: 'auto',
					// loop: true,
					freeMode: true,
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					},
					watchSlidesVisibility: true,
					watchSlidesProgress: true,
					breakpoints: {
						1025: {
							slidesPerView: 3,
						}
					},					
				});
																							
				
				/* Initiate First SubTabs Slider (Swiper)*/
				var subtabThumbSlider = new Swiper('.subtabs_'+ vm.uid + '_booking-thumb_0 .gallery-thumbs', {
					centerInsufficientSlides: true,					
					slidesPerView: 'auto',
					// loop: true,
					freeMode: true,
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					},
					watchSlidesVisibility: true,
					watchSlidesProgress: true,
					breakpoints: {
						1025: {
							slidesPerView: 3,
						}
					},									
				});

				//Initiate Carousel in the First tabs for Single Tab
					var tabFirstSlider = new Swiper('.tabs_'+ vm.uid +'_Tabslider_0' , {
						centerInsufficientSlides: true,						
						spaceBetween: 25,
						loop:$('.tabs_'+ vm.uid +'_Tabslider_0 .swiper-slide').length > 1 ? true : false,
						loopAdditionalSlides: 1,
						navigation: {
							nextEl: '.swiper-button-next',
							prevEl: '.swiper-button-prev',
						},
						pagination: {
							el: '.swiper-pagination',
							clickable: true
						},
						// loopFillGroupWithBlank: true,
					});

				var subtabFirstSlider = new Swiper('.subtabs-' + vm.uid +'_tab_0_Tabslider_0', {
						centerInsufficientSlides: true,					
							spaceBetween: 25,
							// loop:false,
						loop:$('.subtabs-' + vm.uid +'_tab_0_Tabslider_0 .swiper-slide').length > 1 ? true : false,
						loopAdditionalSlides: 1,
						navigation: $('.subtabs-' + vm.uid +'_tab_0_Tabslider_0 .swiper-slide').length > 1 ? { nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev',} : {},
							// navigation: {
							// 	nextEl: '.swiper-button-next',
							// 	prevEl: '.swiper-button-prev',
							// },
							pagination: {
								el: '.swiper-pagination',
								clickable: true
							}
					});

				var subtabFirstSlider2 = new Swiper('.subtabs-' + vm.uid +'_tab_1_Tabslider_0', {
						centerInsufficientSlides: true,					
							spaceBetween: 25,
							// loop:false,
						loop:$('.subtabs-' + vm.uid +'_tab_1_Tabslider_0 .swiper-slide').length > 1 ? true : false,
						loopAdditionalSlides: 1,
						navigation: $('.subtabs-' + vm.uid +'_tab_1_Tabslider_0 .swiper-slide').length > 1 ? { nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev',} : {},
							// navigation: {
							// 	nextEl: '.swiper-button-next',
							// 	prevEl: '.swiper-button-prev',
							// },
							pagination: {
								el: '.swiper-pagination',
								clickable: true
							}
					});

				if($('.subtabs-' + vm.uid +'_tab_0_Tabslider_0 .swiper-slide').length < 2){
					$('.subtabs-' + vm.uid +'_tab_0_Tabslider_0 .swiper-wrapper').addClass( "disabled" );
					$('.subtabs-' + vm.uid +'_tab_0_Tabslider_0 .swiper-button-next,.subtabs-' + vm.uid +'_tab_0_Tabslider_0 .swiper-button-prev').addClass( "disabled" );
				}	

				if($('.subtabs-' + vm.uid +'_tab_1_Tabslider_0 .swiper-slide').length < 2){
					$('.subtabs-' + vm.uid +'_tab_1_Tabslider_0 .swiper-wrapper').addClass( "disabled" );
					$('.subtabs-' + vm.uid +'_tab_1_Tabslider_0 .swiper-button-next,.subtabs-' + vm.uid +'_tab_1_Tabslider_0 .swiper-button-prev').addClass( "disabled" );
				}		
				
				var servicesFirstSlider =  new Swiper('.services-container-' + vm.uid +'-tab-0 .services-slider', {
					centerInsufficientSlides: true,					
					spaceBetween: 25,
					slidesPerView: 1,
					loop:false,
					navigation: {
						nextEl: '.services-container-' + vm.uid +'-tab-0 .services-swiper-control .swiper-button-next',
						prevEl: '.services-container-' + vm.uid +'-tab-0 .services-swiper-control .swiper-button-prev',
					},
					pagination: {
						el: '.services-container-' + vm.uid +'-tab-0 .swiper-pagination',
						clickable: true
					},
					breakpoints: {
						1025: {
							slidesPerView: 2,
						}
					},
				});
			
			}
		}

		var s = '';
			
		if (vm.isproperty("layouttype") == "grid1" || vm.isproperty("layouttype") == "grid2")
		{
			var swiper = new Swiper('.destination-swiper-container', {
				centerInsufficientSlides: true,					
				loop: true,
				pagination: {
					el: '.swiper-pagination',
					clickable: true
				},
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
			});
		}
		
		// Details Calendar
			//console.log(vm.$jss.routeData().itemLanguage);
			if ( vm.$jss.routeData().itemLanguage == 'zh-CN' || vm.$jss.routeData().itemLanguage == 'zh'){
				var language = 'zh-CN'; 
				var language_m = 'zh';
			}else{
				var language = 'default'; 
				var language_m = 'en';
			} 
			
		    var todayDate = new Date();
			var fromDate_string = todayDate.yyyymmdd();
			//var fromDate_string = todayDate.getFullYear() +'-'+(todayDate.getMonth()+1)+'-'+todayDate.getDate();
			//var fromDate_string_2 = todayDate.getFullYear() +'/'+(todayDate.getMonth()+1)+'/'+todayDate.getDate();
			var fromDate_string_2 = ('0' + todayDate.getDate()).slice(-2) + '/'
             + ('0' + (todayDate.getMonth()+1)).slice(-2) + '/'
             + todayDate.getFullYear();
			
			var tomorrowDate = new Date(todayDate);
			tomorrowDate.setDate(tomorrowDate.getDate() + 1);
			var toDate_string = tomorrowDate.yyyymmdd();
			//var toDate_string = tomorrowDate.getFullYear() +'-'+(tomorrowDate.getMonth()+1)+'-'+tomorrowDate.getDate();;
			if ( language == 'default'){ 
				var fromDate =
				  todayDate.getDate() +
				  ' ' +
				  $.dateRangePickerLanguages[language]['month-name'][todayDate.getMonth()] +
				  ' (' +
				  $.dateRangePickerLanguages[language]['week-day'][todayDate.getDay()] +
				  ')';
				var toDate =
				  tomorrowDate.getDate() +
				  ' ' +
				  $.dateRangePickerLanguages[language]['month-name'][tomorrowDate.getMonth()] +
				  ' (' +
				  $.dateRangePickerLanguages[language]['week-day'][tomorrowDate.getDay()] +
				  ')';
			 }else{
			 	var fromDate = 
							$.dateRangePickerLanguages[language]['month-name'][todayDate.getMonth()] + ('0' + todayDate.getDate()).slice(-2) +
							$.dateRangePickerLanguages[language]['day-name'];
				var toDate = $.dateRangePickerLanguages[language]['month-name'][tomorrowDate.getMonth()] + ('0' + tomorrowDate.getDate()).slice(-2) +
							$.dateRangePickerLanguages[language]['day-name'];
				/*
			 	var fromDate = todayDate.getFullYear() + $.dateRangePickerLanguages[language]['year-name'] +
							$.dateRangePickerLanguages[language]['month-name'][todayDate.getMonth()] + ('0' + todayDate.getDate()).slice(-2) +
							$.dateRangePickerLanguages[language]['day-name'];
				var toDate = tomorrowDate.getFullYear() + $.dateRangePickerLanguages[language]['year-name'] +
							$.dateRangePickerLanguages[language]['month-name'][tomorrowDate.getMonth()] + ('0' + tomorrowDate.getDate()).slice(-2) +
							$.dateRangePickerLanguages[language]['day-name'];
				*/
			 } 

			
			
		
		if (vm.isproperty("hasdetail") == "1"){
			$('.viewdetail').unbind('click').bind('click', function(e){

				var tab = $(e.target).parents('.tab-contents')[0];
				var id = $(tab).attr('id');
				var uID = $(this).attr('data-uid');
				var index = $(this).attr('data-tabindex');
				//console.log(id);
				

				if ($('#' + id + '_detail').hasClass('hidden'))
				{	
						$(this).addClass('hidden');
						//$('#'+uID+'-view-less-'+index).removeClass('hidden');
						$('#' + id + '_detail .view-less').attr('data-targetid', id );
						$('#' + id + '_detail .view-less').attr('data-targetdetails', id + '_detail');
						// $('#' + id + ' .detail-hidden').addClass('hidden');
					//Calender initiatize
					if (!$('#' + id + '_detail').hasClass('CalInit')){
						
						if ( $('#' + id + '_detail .jenDatePicker') && $('#' + id + '_detail .jenDatePicker').hasClass('Calendarblock')){
								
								//console.log('todate: '+fromDate + ' - ' +toDate );
								// For Display Date 
								$('#' + id + '_detail .startDate_class').val(fromDate);
								$('#' + id + '_detail .endDate_class').val(toDate);
								
								// For following date format (yyyy-mm-dd) for DateRangePicker to init and pick date
								$('#' + id + '_detail .startDate_string').val(fromDate_string);
								$('#' + id + '_detail .endDate_string').val(toDate_string);

								
								$('#' + id + '_detail .jenDates').dateRangePicker({
								//format: 'DD MMM (ddd)',
								format: 'YYYY-MM-DD',
								language: language,
								container: '#' + id + '_detail  #jenCalender',
								separator: '-',
								getValue: function() {
								  if (
									$('#' + id + '_detail .startDate_string').val() &&
									$('#' + id + '_detail .endDate_string').val()
								  ){
									return (
									  $('#' + id + '_detail .startDate_string').val() +
									  ' - ' +
									  $('#' + id + '_detail .endDate_string').val()
									);
								  }else {return '';}
								},
								setValue: function(s, s1, s2) {
									$(this).val(s);
									$('#' + id + '_detail .hiddenstartDate').html(s1);
									$('#' + id + '_detail .hiddenendDate').html(s2);
									$('#' + id + '_detail .startDate_string').val(s1);
									$('#' + id + '_detail .endDate_string').val(s2);
									var startDate = new Date(s1);
									var endDate = new Date(s2);
									
									if (language == 'default'){
										startDate = startDate.getDate() +
										' ' +
										$.dateRangePickerLanguages[language]['month-name'][startDate.getMonth()] +
										' (' +
										$.dateRangePickerLanguages[language]['week-day'][startDate.getDay()] +
										')';

										endDate = endDate.getDate() +
										' ' +
										$.dateRangePickerLanguages[language]['month-name'][endDate.getMonth()] +
										' (' +
										$.dateRangePickerLanguages[language]['week-day'][endDate.getDay()] +
										')';
									}else{
										startDate = 
										$.dateRangePickerLanguages[language]['month-name'][startDate.getMonth()] + ('0' + startDate.getDate()).slice(-2) +
										$.dateRangePickerLanguages[language]['day-name'] +
										' (' +
										$.dateRangePickerLanguages[language]['week-day'][startDate.getDay()] +
										')';

										endDate = 
										$.dateRangePickerLanguages[language]['month-name'][endDate.getMonth()] + ('0' + endDate.getDate()).slice(-2) +
										$.dateRangePickerLanguages[language]['day-name'] +
										' (' +
										$.dateRangePickerLanguages[language]['week-day'][endDate.getDay()] +
										')';
									/*
										startDate = startDate.getFullYear() + $.dateRangePickerLanguages[language]['year-name'] +
										$.dateRangePickerLanguages[language]['month-name'][startDate.getMonth()] + ('0' + startDate.getDate()).slice(-2) +
										$.dateRangePickerLanguages[language]['day-name'];

										endDate = endDate.getFullYear() + $.dateRangePickerLanguages[language]['year-name'] +
										$.dateRangePickerLanguages[language]['month-name'][endDate.getMonth()] + ('0' + endDate.getDate()).slice(-2) +
										$.dateRangePickerLanguages[language]['day-name'];
									*/	
										
									}
									
									$('#' + id + '_detail .startDate_class').val(startDate);
									$('#' + id + '_detail .endDate_class').val(endDate);
								
								  //$('#' + id + '_detail .startDate_class').val(s1);
									vm.DPstartDate = s1;
								  //$('#' + id + '_detail .endDate_class').val(s2);
									vm.DPendDate = s2;
								},
								inline: true,
								startDate: new Date(),
								selectForward: true,
								updatePicker: true,
								firstDate: $('#' + id + '_detail .startDate_class'),
								lastDate: $('#' + id + '_detail .endDate_class'),
								hoverDate: $('#' + id + '_detail .jenDays'),
								minDays: 2,
								showTopbar: false,
								singleMonth: '1',
								alwaysOpen: true,
								maxDays: 181,
								startOfWeek: 'monday',
								callback: function(startDate, endDate, period) {
								  //console.log(startDate.format('YYYY'));

								},
							  });

								 $('#' + id + '_detail').addClass('CalInit');
						  }
						  
						  if($('#' + id + '_detail .jenTimePicker') && $('#' + id + '_detail .jenTimePicker').hasClass('Calendarfields')){
								 $('#' + id + '_detail .selectDate').val(fromDate);									
								 $('#' + id + '_detail .selectDate_string').val(fromDate_string_2);
								 $('#' + id + '_detail .selectDate_eat2eat').val(fromDate_string);
								 //console.log($('#' + id + '_detail .selectDate_string').val());
								
								if (window.outerWidth > 1024){
								$('#' + id + '_detail .selectDate').dateRangePicker({
									format: 'YYYY/MM/DD',
									language: language,
									container: '#' + id + '_detail #jenRCalender',
									separator: '/',
									getValue: function() {
									if ($('#' + id + '_detail .selectDate_string').val() ){
									  return $('#' + id + '_detail .selectDate_string').val();
									  }else{
									  return '';
									  }
									},
									setValue: function(s) {
									$('#' + id + '_detail .selectDate_string').val(s);
									
									var selectedDate = new Date(s);
									var selectedDate2 = new Date(s).yyyymmdd();
									
									if (language == 'default'){
									selectedDate = selectedDate.getDate() +
									' ' +
									$.dateRangePickerLanguages[language]['month-name'][selectedDate.getMonth()] +
									' (' +
									$.dateRangePickerLanguages[language]['week-day'][selectedDate.getDay()] +
									')';
									}else{
									/*
									selectedDate = selectedDate.getFullYear() + $.dateRangePickerLanguages[language]['year-name'] +
											$.dateRangePickerLanguages[language]['month-name'][selectedDate.getMonth()] + ('0' + selectedDate.getDate()).slice(-2) +
											$.dateRangePickerLanguages[language]['day-name'];
									*/
									selectedDate = $.dateRangePickerLanguages[language]['month-name'][selectedDate.getMonth()] + ('0' + selectedDate.getDate()).slice(-2) +
											$.dateRangePickerLanguages[language]['day-name'] +
											' (' +
											$.dateRangePickerLanguages[language]['week-day'][selectedDate.getDay()] +
											')';
									}

									  $('#' + id + '_detail .selectDate').val(selectedDate);
									  $('#' + id + '_detail .selectDate_eat2eat').val(selectedDate2);
									  vm.reservedDate = s;
									},
									inline: false,
									autoClose: true,
									alwaysOpen: false,
									singleDate: true,
									startDate: new Date(),
									selectForward: true,
									updatePicker: false,
									showTopbar: false,
									singleMonth: '1',
									startOfWeek: 'monday',
							  });
							  }else{

								var now = new Date();
								var yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
								var tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));
								var nextMonths = new Date(now.getFullYear(), now.getMonth() + 12, now.getDate());
								var day = 24 * 60 * 60 * 1000;

								$('#' + id + '_detail .selectDate').mobiscroll().range({
									calendarScroll: 'vertical',
									lang: language_m,         // More info about calendarScroll: https://docs.mobiscroll.com/4-10-3/calendar#opt-calendarScroll
									//dateFormat: 'dd M (D) ',
									dateFormat: 'yy-mm-dd',
									theme: 'ios',
									themeVariant: 'light' , 
									display: 'bottom',
									min: now,
									max:  nextMonths,
									counter: true,
									selectedText: "{count}",
									selectedPluralText: "{count}",
								onInit: function(event, inst){
									
									console.log(inst);
									inst.setVal([now,now]);
									inst.startVal = inst.startVal.replace(/-/g, "/");
									// inst.endVal = inst.endVal.replace(/-/g, "/");
									inst._tempValue = inst.startVal + ' - ' + inst.startVal

									$('#' + id + '_detail .selectDate_string').val(inst._tempValue);
								
									var selectedDate = new Date();
									// var selectedDate2 = new Date(inst._tempValue).yyyymmdd();
									var selectedDate2 = new Date();
									
									if (language == 'default'){
										selectedDate = selectedDate.getDate() +
											' ' +
											$.dateRangePickerLanguages[language]['month-name'][selectedDate.getMonth()] +
											' (' +
											$.dateRangePickerLanguages[language]['week-day'][selectedDate.getDay()] +
											')';
									}else{
										$.dateRangePickerLanguages[language]['month-name'][selectedDate.getMonth()] + 
										('0' + selectedDate.getDate()).slice(-2) +
										$.dateRangePickerLanguages[language]['day-name'] +
										' (' +
										$.dateRangePickerLanguages[language]['week-day'][selectedDate.getDay()] +
										')';
									/*
										selectedDate = selectedDate.getFullYear() + $.dateRangePickerLanguages[language]['year-name'] +
											$.dateRangePickerLanguages[language]['month-name'][selectedDate.getMonth()] + selectedDate.getDate() +
											$.dateRangePickerLanguages[language]['day-name'];
									*/
									}

									  $('#' + id + '_detail .selectDate').val(selectedDate);
									  $('#' + id + '_detail .selectDate_eat2eat').val(selectedDate2);
									  $('#' + id + '_detail .selectDate').val();
									  $('#' + id + '_detail .selectDate_eat2eat').val();
									
									
									
								},
								onDayChange: function(event, inst){
									console.log(event)
									console.log(inst)
								},
								onSet: function (event, inst) {
									//console.log(inst.getDate());
									$('#' + id + '_detail .selectDate_string').val(inst._tempValue);
								
									var selectedDate = new Date(inst.startVal);
									var selectedDate2 = new Date(inst.startVal).yyyymmdd();
									// var selectedDate = new Date(inst._tempValue);
									// var selectedDate2 = new Date(inst._tempValue).yyyymmdd();
									if (language == 'default'){
									selectedDate = selectedDate.getDate() +
									' ' +
									$.dateRangePickerLanguages[language]['month-name'][selectedDate.getMonth()] +
									' (' +
									$.dateRangePickerLanguages[language]['week-day'][selectedDate.getDay()] +
									')';
									}else{
										$.dateRangePickerLanguages[language]['month-name'][selectedDate.getMonth()] + 
										('0' + selectedDate.getDate()).slice(-2) +
										$.dateRangePickerLanguages[language]['day-name'] +
										' (' +
										$.dateRangePickerLanguages[language]['week-day'][selectedDate.getDay()] +
										')';
										// selectedDate = 
										// 	$.dateRangePickerLanguages[language]['month-name'][selectedDate.getMonth()] +
										// 	('0' + selectedDate.getDate()).slice(-2) + 
										// 	$.dateRangePickerLanguages[language]['day-name'];
										// 	' (' +
										// 	$.dateRangePickerLanguages[language]['week-day'][selectedDate.getDay()] +
										// 	')';

									/*selectedDate = selectedDate.getFullYear() + $.dateRangePickerLanguages[language]['year-name'] +
											$.dateRangePickerLanguages[language]['month-name'][selectedDate.getMonth()] + selectedDate.getDate() +
											$.dateRangePickerLanguages[language]['day-name'];
									*/
									}

									  $('#' + id + '_detail .selectDate').val(selectedDate);
									  $('#' + id + '_detail .selectDate_eat2eat').val(selectedDate2);
									//$('#slider2jenstartBDate').val(inst.startVal);
									//var mobiscroll_selectedDate = new Date(inst.startVal);
									//console.log(mobiscroll_selectedDate);

									
									
									
									//inst.settings.min = inst._startDate;
								},
						
							});
							
							}
							$('#' + id + '_detail').addClass('CalInit');
						  }
						  
						  
					}

					$('#' + id + '_detail').removeClass('hidden');
					//$(e.target).html('view less');
					$(this).parent().addClass('viewOpen');
					if ($(this).hasClass('withBanner')){
					//console.log('Banner Reveal');
					$('#' + id + ' .room-detail-banner').removeClass('hidden' );
					}				
				}
				else
				{	
					$(this).addClass('hidden');
					$('#'+uID+'-view-details-'+index).removeClass('hidden');
					$('#' + id + '_detail').addClass('hidden');
					$(this).parent().removeClass('viewOpen');
					//$(e.target).html('view detail');
					if ($(this).hasClass('withBanner')){
					$('#' + id + ' .room-detail-banner').addClass('hidden' );
					}		
				}
				

			});
			
			//Detail Blcok View Less
			$('.view-less').unbind('click').bind('click', function(e){
				e.preventDefault();
				var targetID = $(this).attr('data-targetid');
				var targetdetails = $(this).attr('data-targetdetails');
				if (targetdetails && targetdetails != ''){
					//console.log('details view less for ' + targetdetails);
					$('#' + targetdetails).addClass('hidden');
					$('#' + targetID +  ' .viewdetail').removeClass('hidden');
					$('#' + targetID +  ' .detail-hidden').removeClass('hidden');
					$('html, body').animate({
					scrollTop: $('#'+targetID).offset().top -250
					}, 200)
				}
			});
			
			$('.viewsubdetail').unbind('click').bind('click', function(e){
				var tab = $(e.target).parents('.subtab-contents')[0];
				var id = $(tab).attr('id');
				if ($('#' + id + '_detail').hasClass('hidden'))
				{
					$('#' + id + '_detail').removeClass('hidden');
					//$(e.target).html('view less');
					$(this).parent().addClass('viewOpen');
					$('#' + id + ' .sub-view-less').attr('data-targetid', id);
					$(this).addClass('hidden');
					$('#'+ id + ' .viewsubdetail').addClass('hidden');
				}
				else
				{
					$('#' + id + '_detail').addClass('hidden');
					$(this).parent().removeClass('viewOpen');
					//$(e.target).html('view detail');
					
				}
			});
			
			//Detail Blcok Sub View Less
			$('.sub-view-less').unbind('click').bind('click', function(e){
				e.preventDefault();
				var targetID = $(this).attr('data-targetid');				
				if (targetID && targetID != ''){
					//console.log('details view less for ' + targetID);
					$('#' + targetID + '_detail').addClass('hidden');
					$('#' + targetID +  ' .viewsubdetail').removeClass('hidden');
					//$('#' + targetID +  ' .detail-hidden').removeClass('hidden');
				}
			});
		}
		
		//Popup Function
		
		
		$('.main-carousel-cta').unbind('click').bind('click', function(e){
			e.preventDefault();
			if ($(this).attr('href') == '#'){		
			var moduleid = $(this).data('moduleid');
			popup('property', moduleid);

			}else{
			 window.open($(this).attr('href'));
			}
			//popup('property');
		});

		if (vm.isproperty("HasPropertySearch") == '1' || vm.isproperty("haspropertysearch") == '1')
		{
			$('#' + vm.uid + ' .carousel-explore').unbind('click').bind('click', function(e){
				console.log('has property search');
				//if ($(this).attr('href') == '#' || $(this).attr('href') == '' || $(this).attr('href') == null || $(this).attr('href') == undefined){		
				if (!vm.$jss.property()){		
					e.preventDefault();
					var moduleid = $(this).data('moduleid');
					popup('property', moduleid);

				}else{
				 //window.open($(this).attr('href'));
					if(window.innerWidth < 1025){
						document.body.style.setProperty("top",`-${window.scrollY}px`)
						document.body.style.setProperty("position","fixed")
					}
				 	$('.accordion-cta').trigger('click');
				}
				//popup('property');
			});
		}
		else
		{
			//$('#' + vm.uid + ' .carousel-explore').unbind('click').bind('click', function(e){
			$('#' + vm.uid + ' .carousel-explore').unbind('click').bind('click', function(e){
				e.preventDefault();
				if ($(this).attr('href') === '')
				{
					if(window.innerWidth < 1025){
						document.body.style.setProperty("top",`-${window.scrollY}px`)
						document.body.style.setProperty("position","fixed")
					}
					$('.accordion-cta').trigger('click');
				}
				else
				{
					if ($(this).attr('href').toLowerCase().indexOf("http") != -1)
						window.open($(this).attr('href'));
					else
						window.location = $(this).attr('href');
				}
			});
		}
		
		$('.owl-content-cta').unbind('click').bind('click', function(e){
			//if ($(this).hasClass('popup')){
			e.preventDefault();
			var moduleid = $(this).data('moduleid');
			popup('property', moduleid);

			//}else{
			// window.open(this.href);
			//}
			//popup('property');
		});
		
		$('.close-btn').unbind('click').bind('click', function(){
			var moduleid = $(this).data('moduleid');
			$('.booking-nav').css("display","block")
			$('.'+ moduleid + '_popup-container').removeClass('popupOpen');
			$('.'+ moduleid + '_popup-signup-container').removeClass('popupOpen');
			$('.'+vm.uid+'_popup-signup-container #becomeamember-modal').removeClass('active')
		});
		
		$('.close-btn-e-m').unbind('click').bind('click', function(){
			var moduleid = $(this).data('moduleid');
			$('.booking-nav').css("display","block")
			$('.'+ moduleid + '_popup-container').removeClass('popupOpen');
			$('.'+ moduleid + '_popup-signup-container').removeClass('popupOpen');
			$('.'+vm.uid+'_popup-signup-container #becomeamember-modal').removeClass('active')
		});
		
		
		function popup(type, id){
			if (type == 'property'){
				$('.'+ id + '_popup-container').addClass('popupOpen');
			} else if (type == 'signup'){
				$('.'+ id + '_popup-signup-container').addClass('popupOpen');
			}
		}
		
		function callback(){
			//console.log('carousel dragged');
		}
		$('.fav-btn').unbind('click').bind('click', function(e){
			setLike(this);
			e.stopImmediatePropagation();
		});
	}	// end: mobile check for updated function()
	}	//	end: check no data

  },
};
</script>
