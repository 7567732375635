<template>
<div>
  <div :class="formType + ' ' + [formType == 'submit-form'?'container':'']">
	<div :id="this.uid" class="HJForm" :data-targetemail="this.targetemail" :data-targethotel="this.targethotel" :data-targetaddress="this.targetaddress">
	<template v-if="this.formresults && this.formresults['children'] != null">
		<div class="content">
		<template v-if="formType == 'submit-form'">
		<template v-if="this.formresults['title'] && this.formresults['title'] != ''">
			<div class="title">
				<div class="title-text">{{this.formresults['title'].value}}</div>
			</div>
		</template>
		<template v-else>
			<div class="title">
				<div class="title-text sample-text">Some Sexy Offer Tag</div>
			</div>	
		</template>
		</template>
		
		<template v-if="formType == 'submit-form' || formType == 'search-form'">
		<form id="form" onkeydown="return event.key != 'Enter';">
		<template v-for="(item, index) in this.formresults['children']">
			<template v-if="item['fieldtypestring'].value == 'input'" >
							<div class="input-field">
							<div>{{item['title'].value}}</div>
							<input type="text" :name="item['title'].value" :placeholder="item['placeholder'].value" />
							</div>
			</template>	
			<template v-if="item['fieldtypestring'].value == 'checkbox'" >
						<div>{{item['title'].value}}</div>
						<input type="checkbox" :id="item['title'].value" :name="item['groupname'].value"  />
			</template>
			<template v-if="item['fieldtypestring'].value == 'radiobutton'" >
						<label class="custom-radio mt-4 mr-3">{{item['title'].value}}
							<input type="radio" name="radio">
							<span class="checkmark"></span>
						</label>
						<!--div class="radio-options">
						<input type="radio" :id="item['title'].value" :name="item['groupname'].value"  />
						<div>{{item['title'].value}}</div>
						</div-->
			</template>
			<template v-if="item['fieldtypestring'].value == 'button'" >
						<input type="button" class="form-button" :id="item['title'].value" :name="item['groupname'].value" :value="item['title'].value" />
			</template>
			
			<template v-if="item['fieldtypestring'].value == 'propertylist'">
			<div class="form-filter-text" v-html="item['title'].value"></div>
				<select class="filter form-control">
					<option value="">{{item['placeholder'].value}}</option>
						<template v-if="hotelnames && hotelnames['children']">
						<template v-for="(hotelname, index) in hotelnames['children']">
							<option :value="hotelname['id']">{{hotelname["title"].value}}</option>
						</template>
						</template>
					</select>
			</template>	
			<template v-if="item['fieldtypestring'].value == 'eventtype'">
					<div class="form-filter-text" v-html="item['title'].value"></div>			
					<select id="searchType" class="filter eventtype form-control">
						<option value="all">{{item['placeholder'].value}}</option>
						<template v-if="eventtype && eventtype['children']">
							<template v-for="(eventtypeitem, index) in eventtype['children']">
								<template v-if="eventtypeitem['contentCategory'] && eventtypeitem['contentCategory']['children'] && eventtypeitem['contentCategory']['children'].length > 0 && eventtypeitem['contentCategory']['children'][0].value == item['description'].value">
									<option :value="eventtypeitem['id']">
									<template v-for="(eventtypefield, index) in eventtypeitem['fields']">
										<template v-if="eventtypefield.name == 'Title'">
										{{eventtypefield.value}}
										</template>
									</template>						
									</option>
								</template>
							</template>
						</template>
					</select>
			</template>
			
			
			
		</template>
			
		</form> 
		</template>
		
		<template v-else-if="formType == 'popup-form'">
			<!--This is a popupform		
			-->
			
			<!-- Nico Form -->
			<div id="becomeamember-modal">
			<div class="becomeamember-modal">
                    
			<template v-if="this.formresults['children'] && this.formresults['children'] != ''">
			<template v-for="(item, index) in this.formresults['children']">
				
						
							<div :class="'container-fluid pr-5 pl-5 pt-5 pb-5 member-page' + (index+1)">
							<div class="row no-gutters">
								<div class="col-12">
								<template v-if="(index+1) < 10">
									<p class="step">0{{index+1}}</p>
								</template>
								<template v-else>
									<p class="step">{{index+1}}</p>
								</template>
									<h1 v-html="item['title'].value"></h1>
								</div>
							</div>
							
							<template v-if="item['fieldtypestring'].value == 'title'">
								<div class="row no-gutters justify-content-start mt-3 title-selector">
									<template v-if="item['children']">	
										<template v-for="(option, subindex) in item['children']">
											<div :class="option['cssclass'].value">
												<p class="title-selection" data-questionfield="index" :data-value="option['title'].value">{{option['title'].value}}</p>
											</div>					
										</template>
									</template>									
								</div>
							</template> <!-- closing of v-if Question 1 -->
							
							<template v-else-if="item['fieldtypestring'].value == 'firstname'">
					        <div class="row no-gutters justify-content-start mt-3">
								<div class="col-12">
									<template v-if="item['children']">	
										<template v-for="(option, subindex) in item['children']">
												<input type="text" id="firstname"  :placeholder="option['placeholder'].value" :name="option['title'].value" class="member-name" autocomplete="off" />															
										</template>
									</template>	
									<div class="invalid-feedback">
										{{item['errormessage'].value}}
									</div>									
								</div>
							</div>					
							</template> <!-- closing of v-if Question 2 -->	

							<template v-else-if="item['fieldtypestring'].value == 'lastname'">
					        <div class="row no-gutters justify-content-start mt-3">
								<div class="col-12">
									<template v-if="item['children']">	
										<template v-for="(option, subindex) in item['children']">
												<input type="text" id="lastname" :placeholder="option['placeholder'].value" :name="option['title'].value" class="member-name" autocomplete="off" />			
										</template>
									</template>	
									<div class="invalid-feedback">
										{{item['errormessage'].value}}
									</div>										
								</div>
							</div>					
							</template> <!-- closing of v-if Question  3-->
							
							<template v-else-if="item['fieldtypestring'].value == 'datepicker'">
							   <div class="row no-gutters justify-content-start mt-3">
								<div class="col-12">
									<div class="form-group">
									<template v-if="item['children']">	
											<template v-for="(option, subindex) in item['children']">
												<template v-if="option['fieldtypestring'].value == 'dateselector'">
													<select v-html="option['description'].value" :class="'form-control date-selector '+ option['cssclass'].value" id="dob-d"></select>
												</template>
												<template v-else-if="option['fieldtypestring'].value == 'monthselector'">
													<select v-html="option['description'].value" :class="'form-control month-selector '+ option['cssclass'].value" id="dob-m"></select>
												</template>	
												<template v-else-if="option['fieldtypestring'].value == 'yearselector'">
													<select v-html="option['description'].value" :class="'form-control year-selector '+ option['cssclass'].value" id="dob-y"></select>
												</template>			
											</template>
									</template>	
									<div class="invalid-feedback dob-feedback"> {{item['errormessage'].value}} </div>
									</div>                           
								</div>
							</div>				
							</template> <!-- closing of v-if Question  4-->
							
							<template v-else-if="item['fieldtypestring'].value == 'countrypicker'">
							   <div class="row no-gutters justify-content-start mt-3">
								<div class="col-12">
									<template v-if="item['children']">	
											<template v-for="(option, subindex) in item['children']">
														<select v-html="option['description'].value" id="member-country" :name="option['title'].value" :class="'form-control '+ option['cssclass'].value"></select>
											</template>
									</template>  
									<div class="invalid-feedback country-feedback"> {{item['errormessage'].value}}  </div>									
								</div>
							</div>				
							</template> <!-- closing of v-if Question  5-->
							
							<template v-else-if="item['fieldtypestring'].value == 'email'">
							   <div class="row no-gutters justify-content-start mt-3">
								<div class="col-12">
									<template v-if="item['children']">	
											<template v-for="(option, subindex) in item['children']">		 
												<input type="email" :placeholder="option['placeholder'].value" name="option['title'].value" class="member-email" autocomplete="off"/>
												<div class="invalid-feedback email"> {{item['errormessage'].value}} </div>
											</template>
									</template>	                         
								</div>
							</div>				
							</template> <!-- closing of v-if Question  6-->
							
							<template v-else-if="item['fieldtypestring'].value == 'phonenumber'">
							   <div class="row no-gutters justify-content-start mt-3">
								<div class="col-12">
									<div class="form-row">
									<template v-if="item['children']">	
										<template v-for="(option, subindex) in item['children']">
											<template v-if="option['fieldtypestring'].value == 'countrycode'">
											<div class="form-group col-md-4">
												<select v-html="option['description'].value" id="iddCode" :class="'form-control '+ option['cssclass'].value"></select>
											</div>
											
											</template>
											
											<template v-else>
											<div class="form-group col-md-8">
												<input type="number" :class="'form-control ' + option['cssclass'].value" :name="option['title'].value" id="inputMob" max="20" oninput="this.value=this.value.slice(0,11)">
												<div class="invalid-feedback">{{option['errormessage'].value}}</div>
											</div>
											</template>
										
										</template>
									</template>	


									</div> 
									<div class="invalid-feedback countrycode">{{item['errormessage'].value}}</div>
								</div>
							</div>				
							</template> <!-- closing of v-if Question  7-->	
							

							<template v-else-if="item['fieldtypestring'].value == 'submitbox'">
							   <div class="row no-gutters justify-content-start mt-3">
								<div class="col-12">

									<template v-if="item['children']">	
										<template v-for="(option, subindex) in item['children']">
											<template v-if="option['fieldtypestring'].value == 'password'">
												<div class="form-group">
													<label v-html="option['title'].value" for="memberPassword"></label>
													<input type="password" :class="'form-control member-name '+ option['cssclass'].value" id="memberPassword" aria-describedby="memberPassword">
												</div>
											</template>
											
											<template v-else-if="option['fieldtypestring'].value == 're-password'">
												<div class="form-group">
													<label v-html="option['title'].value" for="memberReEnterPassword"></label>
													<input type="password" :class="'form-control member-name '+ option['cssclass'].value" id="memberReEnterPassword" aria-describedby="memberReEnterPassword">
												</div>
											</template>
											
											<template v-else-if="option['fieldtypestring'].value == 'checkbox'">
												<label class="container mt-4" id="checkmark"><p v-html="option['description'].value"></p>
												<input type="checkbox">
												<span class="checkmark"></span>
												<small v-html="option['placeholder'].value" class="form-text text-muted mt-4"></small>
												</label>
												<div class="invalid-feedback terms">{{option['errormessage'].value}}</div>

											</template>
											
											<template v-else-if="option['fieldtypestring'].value == 'button'">
												<button type="button" id="form-submit" :class="'btn btn-primary final-step mt-4 '+ option['cssclass'].value">{{option['title'].value}}</button>								
											</template>
										
									
										</template>
									</template>	                          
								</div>
							</div>				
							</template> <!-- closing of v-if Question  7-->


							<template v-else-if="item['fieldtypestring'].value == 'summary'">
								<div class="row no-gutters justify-content-start mt-3">
								<form action="#" method="post" name="popupform" id="popupSubmit" class="row no-gutters justify-content-start mt-3" style="width:100%" >
								<template v-if="item['children']">	
								<div class="row no-gutters justify-content-start mt-3" style="width: 100%;">
									<div class="col-12">
										<p>{{item['errormessage'].value}}</p>
									</div>
								</div>
								<div class="row no-gutters justify-content-start mt-3 summary-section" style="width: 100%;">
										<template v-for="(option, subindex) in item['children']">
											<template v-if="option['fieldtypestring'].value == 'column'">
												<template v-if="(subindex+1)%2 == 0">
													<div class="col-2"></div>
												</template>
											
												<div class="col-5">
												<h4>{{option['title'].value}}</h4>
												<template v-if = "option['children'] && option['children'].length > 0">
													<template v-for="(optionfield, subsubindex) in option['children']">
														<label :data-title="optionfield['title'].value">
															<!--<input :class="optionfield['cssclass'].value" type="text" name="name" value="" disabled/>-->
															<div :class="optionfield['cssclass'].value + ' summary-fields summary-name'"></div>
														</label>
													
													</template>
												
												</template>											
												</div>
											</template>

										</template>
								</div>
								
								<div class="row no-gutters justify-content-start mt-3 summary-submit" style="width: 100%;">
										<template v-for="(option, subindex) in item['children']">											
											
											<template v-if="option['fieldtypestring'].value == 'button'">
											
												<div class="row no-gutters justify-content-start society-container mt-3" style="width: 100%;">
													<div class="col-12 text-center">
														<button type="button" :class="'btn btn-primary final-step final-member-step mt-4 '+ option['cssclass'].value">{{option['title'].value}}</button>
													</div>
												</div>																			
											</template>
										</template>
								</div>
								
								
								</template>
								</form>
								</div>
															
							</template> <!-- closing of v-if Final Step-->
							
							
							
							
							<div :class="'row no-gutters justify-content-start mt-5 progress-section '+ [item['fieldtypestring'].value == 'submitbox'?'':'pt-5']">
								<div class="col-2">
									<div class="prev-btn mr-2"></div>
									<div :class="'next-btn '+ [index == (formresults['children'].length - 2)?'finalstep-mem-next':'']" :data-questionindex="index"></div>
								</div>
								<div class="col-3 pt-2">
									<div class="progress-container">
										<!-- Progress Bar --->
										<div class="progress">
												<div class="progress-bar" role="progressbar" :style="'width: '+ Math.round((index/formlength)*1000)/10 +'%;'" aria-valuemin="0" aria-valuemax="100"></div>
											</div>
										<p><span class="progress-text">{{Math.round((index/(formlength))*1000)/10}}</span>
										<!-- {{$t('% complete')}} -->
										{{getDictionaryPhrase('complete')}}
										</p>
									</div>
								</div>
							</div>
						</div> 
						
						<!--</template> <!-- Closing for item['children'] check -->


				</template>
				</template>
				
					<template v-if="this.formresults['children'] && this.formresults['children'] != ''">
						<form action="#" method="post" name="popupform" id="popupSubmit">
							<template v-for="(item, index) in this.formresults['children']">												
										<input type="hidden" :id="'ans'+index" :name="'ansfield'+index" value="">
							</template>
						</form>
					</template>
					
					
				  <img :src="closeIcon" class="close-btn2" :data-moduleid="moduleid"/>
				  <div id="thank-you-message" class="thank-you-message">
                        <h3>{{getDictionaryPhrase('JenSocietyThankYou')}}</h3>
                        <img :src="closeIcon" class="close-btn2":data-moduleid="moduleid"/>
                    </div>
                </div>
				
			<!-- No Longer in use for new layout -->
			<div class="becomeamember-modal-m desktop-hidden mobile-hidden">
                    <div :data-moduleid="moduleid" class="close-btn-m"></div>
                    
					
				<div class="container-fluid" id="mobform-step1" >
                        <div class="row no-gutters justify-content-center">
                            <div class="col-12">
                                <h3>Enrolment (1/3)</h3>
                            </div>
                        </div>
                        <div class="row no-gutters justify-content-center">
                            <div class="col-12">
                                <form class="needs-validation" novalidate>
								<template v-if="this.formresults['children'] && this.formresults['children'] != ''">
									<template v-for="(item, index) in this.formresults['children']">
										<template v-if="item['groupname'].value == 'mobile1'">
											<div class="form-row">
												<template v-if="item['fieldtypestring'].value == 'title'">
													<div class="col-12">
														<input type="text" class="form-control" name="title" :placeholder="item['placeholder'].value" id="socFormTitle" required>
														<div class="invalid-feedback">
															{{item['errormessage'].value}}
														</div>
													</div>
												</template> <!-- end of Mobile Q1 -->
												
												<template v-if="item['fieldtypestring'].value == 'firstname'">
													<div class="col-12">
														<input type="text" id="mobfirstname" class="form-control" name="lname" :placeholder="item['placeholder'].value" required>
														<div class="invalid-feedback">
															{{item['errormessage'].value}}
														</div>
													</div>
												</template> <!-- end of Mobile Q2 -->

												<template v-if="item['fieldtypestring'].value == 'lastname'">
													<div class="col-12">
														<input type="text" id="moblastname" class="form-control" name="lname" :placeholder="item['placeholder'].value" required>
														<div class="invalid-feedback">
															{{item['errormessage'].value}}
														</div>
													</div>
												</template> <!-- end of Mobile Q3 -->

												<template v-if="item['fieldtypestring'].value == 'datepicker'">
													<div class="col-12">
														<input type="text" class="form-control" name="fname" :placeholder="item['placeholder'].value" id="socFormBirth" required>
														<div class="invalid-feedback socFormBirth">
															{{item['errormessage'].value}}
														</div>
													</div>
												</template> <!-- end of Mobile Q4 -->	

												<template v-if="item['fieldtypestring'].value == 'countrypicker'">
													<div class="col-12">
														<input type="text" class="form-control" name="fname" :placeholder="item['placeholder'].value" id="socFormCountry" required>
														<div class="invalid-feedback socFormCountry">
															{{item['errormessage'].value}}
														</div>
													</div>
												</template> <!-- end of Mobile Q5 -->
												
												<template v-if="item['fieldtypestring'].value == 'email'">
													<div class="col-12">
														<input type="text" id="member-mobile-email" class="form-control" name="email" :placeholder="item['placeholder'].value" required>
														<div class="invalid-feedback email">
															{{item['errormessage'].value}}
														</div>
													</div>
												</template> <!-- end of Mobile Q6 -->

												<template v-if="item['fieldtypestring'].value == 'phonenumber'">
													
														<template v-if="item['children']">	
														<template v-for="(option, subindex) in item['children']">
															<template v-if="option['fieldtypestring'].value == 'countrycode'">
															<div class="form-group col-4">
																<select v-html="option['description'].value" id="iddCodeMobile" :class="'form-control '+ option['cssclass'].value"  name="country-code" required></select>
															</div>														
															</template>
															
															<template v-else>
															<div class="form-group col-8">
															<input type="number" :class="'form-control ' + option['cssclass'].value" :name="option['title'].value" id="inputMobMob" required >
															</div>
															</template>
														
														</template>
														<template v-for="(option, subindex) in item['children']">
															<template v-if="option['fieldtypestring'].value == 'countrycode'">															
															<div class="invalid-feedback countrycode"> {{option['errormessage'].value}} </div>															
															</template>
															
															<template v-else>															
															<div class="invalid-feedback mobile"> {{option['errormessage'].value}} </div>
															</template>													
														</template>
													</template>	

												</template> <!-- end of Mobile Q7 -->
											</div>
										</template>								
									</template>
								</template> <!-- End of Mobile popup for Page 1 -->
         
                                    <button class="btn btn-primary mt-4" type="submit">{{getDictionaryPhrase('next')}}</button>
                                </form>
                            </div>
                        </div>
                    </div> <!-- End of Member form Mobile Step 1 -->
					
					
                    <div class="container-fluid" id="mobform-step2" >
                        <div class="row no-gutters justify-content-center">
                            <div class="col-12">
                                <a href="" class="society-form-prev" data-id="1"></a>
                                <h3>almost there (2/3) </h3>
                            </div>
                        </div>
                        <div class="row no-gutters justify-content-center">
                            <div class="col-12">
                                <form class="needs-validation" novalidate>
								
								<template v-if="this.formresults['children'] && this.formresults['children'] != ''">
									<template v-for="(item, index) in this.formresults['children']">
									<template v-if="item['groupname'].value == 'mobile2'">
										<template v-if="item['fieldtypestring'].value == 'submitbox'">
										
										<template v-if="item['children']">	
												<template v-for="(option, subindex) in item['children']">
												<div class="form-row">
													<template v-if="option['fieldtypestring'].value == 'password'">
														<div class="col-12">
															<input type="text" :class="'form-control member-name '+ option['cssclass'].value" name="fname" :placeholder="option['placeholder'].value" required>
															<div class="invalid-feedback">
																{{option['errormessage'].value}}
															</div>
														</div>
													</template>
													
													<template v-else-if="option['fieldtypestring'].value == 're-password'">
														<div class="col-12">
															<input type="text" :class="'form-control member-name '+ option['cssclass'].value" name="fname" :placeholder="option['placeholder'].value" required>
															<div class="invalid-feedback">
																{{option['errormessage'].value}}
															</div>
														</div>					
													</template>
													
													<template v-else-if="option['fieldtypestring'].value == 'checkbox'">
														 <div class="col-12">
														<label class="container mt-4" id="checkmark-mob"><p v-html="option['description'].value"></p>
															<small v-html="option['placeholder'].value" class="form-text text-muted mt-4">
															</small>
															<input type="checkbox">
															<span class="checkmark"></span>
														</label>
														 <div class="invalid-feedback term text-center">{{option['errormessage'].value}}</div>
														</div>
													
														
													</template>
													
													<template v-else-if="option['fieldtypestring'].value == 'button'">
														<button :class="'btn btn-primary mt-4 '+ option['cssclass'].value" type="submit">{{option['title'].value}}</button>							
													</template>
												
												</div> <!-- End of form row Q9 -->
												
												</template>

									</template>	

										</template>
									</template>
									</template>
								</template><!-- End of Mobile popup for Page 2 -->

                                    <button class="btn btn-primary mt-4 mob-next-step" type="submit">{{getDictionaryPhrase('next')}}</button>
                                </form>
                            </div>
                        </div>
                    </div><!-- End of Member form Mobile Step 2-->
					
					<div class="container-fluid" id="mobform-step3" >
                        <div class="row no-gutters justify-content-center">
                            <div class="col-12">
                                <a href="" class="society-form-prev" data-id="2"></a>
                                <h3>final step (3/3)</h3>
                            </div>
                        </div>
                        <div class="row no-gutters justify-content-center mob-summary">
                            <div class="col-12">
							<template v-if="this.formresults['children'] && this.formresults['children'] != ''">
									<template v-for="(item, index) in this.formresults['children']">
									<template v-if="item['groupname'].value == 'mobile3'">
									<template v-if="item['fieldtypestring'].value == 'summary'">
										<template v-if="item['children']">	
										<template v-for="(option, subindex) in item['children']">
											<template v-if="option['fieldtypestring'].value == 'column'">														
												<h4>{{option['title'].value}}</h4>
												<template v-if = "option['children'] && option['children'].length > 0">
													<template v-for="(optionfield, subsubindex) in option['children']">
														<label :data-title="optionfield['title'].value">
															<input :class="optionfield['cssclass'].value" type="text" name="name" value="" disabled/>
														</label>
													
													</template>
												
												</template>											
											
											</template>
											
											<template v-else-if="option['fieldtypestring'].value == 'button'">
											
												<div class="row no-gutters justify-content-start mt-3">
													<div class="col-12 text-center">
														<button type="button" :class="'btn btn-primary final-step mt-4 '+ option['cssclass'].value">{{option['title'].value}}</button>
													</div>
												</div>																			
											</template>
										</template>
								</template>
								</template>
								</template>
								</template>	
								</template>
                            </div>
                        </div>
                    </div>
					
                </div> <!-- End of Member form Mobile -->
			<!-- End: mobile member form -->
            </div>

			
			<!-- End of Nico Form -->
		</template>
		
		
		<!-- RFP -->
		<template v-else-if="formType == 'rfp-form'">
			<!--This is a popupform		
			{{this.formresults['children']}}-->
			
			<!-- Nico Form -->
			<div id="becomeamember-modal" class="becomeamember-modal-rfp">
			<div class="becomeamember-modal rfp-form">
                    
			<template v-if="this.formresults['children'] && this.formresults['children'] != ''">
			<template v-for="(item, index) in this.formresults['children']">
				
						
							<div :class="'container-fluid pr-5 pl-5 pt-5 pb-5 member-page' + (index+1)">
							<div class="row no-gutters">
								<div class="col-12">
								<template v-if="(index+1) < 11">
									<p class="step">0{{index+1}}</p>
								</template>
								<template v-else>
									<p class="step">{{index+1}}</p>
								</template>
									<h1 v-html="item['title'].value"></h1>
								</div>
							</div>
							
							<template v-if="item['fieldtypestring'].value == 'title'">
								<div class="row no-gutters justify-content-start mt-3 title-selector">
									<template v-if="item['children']">	
										<template v-for="(option, subindex) in item['children']">
											<div :class="option['cssclass'].value">
												<p class="title-selection" data-questionfield="index" :data-value="option['title'].value">{{option['title'].value}}</p>
											</div>					
										</template>
									</template>									
								</div>
							</template> <!-- closing of v-if Question 1 -->
							

							<template v-else-if="item['fieldtypestring'].value == 'fullname'">
					        <div class="row no-gutters justify-content-start mt-3">
								<div class="col-12">
									<template v-if="item['children']">	
										<template v-for="(option, subindex) in item['children']">
												<input type="text" id="fullname" :placeholder="option['placeholder'].value" :name="option['title'].value" class="member-name" autocomplete="off" />			
										</template>
									</template>	
									<div class="invalid-feedback">
										{{item['errormessage'].value}}
									</div>										
								</div>
							</div>					
							</template> <!-- closing of v-if fullname-->
							
							<template v-else-if="item['fieldtypestring'].value == 'lastname'">
					        <div class="row no-gutters justify-content-start mt-3">
								<div class="col-12">
									<template v-if="item['children']">	
										<template v-for="(option, subindex) in item['children']">
												<input type="text" id="lastname" :placeholder="option['placeholder'].value" :name="option['title'].value" class="member-name" autocomplete="off" />			
										</template>
									</template>	
									<div class="invalid-feedback">
										{{item['errormessage'].value}}
									</div>										
								</div>
							</div>					
							</template> <!-- closing of v-if lastname-->
							<template v-else-if="item['fieldtypestring'].value == 'number'">
					        <div class="row no-gutters justify-content-start mt-3">
								<div class="col-12">
									<template v-if="item['children']">	
										<template v-for="(option, subindex) in item['children']">
												<input id="rfp-number" type="number" :placeholder="option['placeholder'].value" :name="option['title'].value" class="member-namerfp" autocomplete="off" maxlength="3" oninput="this.value=this.value.slice(0,4)" onkeydown="return event.keyCode !== 69"/>											
										</template>
									</template>	
									<div class="invalid-feedback">
										{{item['errormessage'].value}}
									</div>										
								</div>
							</div>					
							</template> <!-- closing of v-if number-->
							
							<template v-else-if="item['fieldtypestring'].value == 'companyname'">
					        <div class="row no-gutters justify-content-start mt-3">
								<div class="col-12">
									<template v-if="item['children']">	
										<template v-for="(option, subindex) in item['children']">
												<input type="text" id="companyname" :placeholder="option['placeholder'].value" :name="option['title'].value" class="member-name" autocomplete="off" />			
										</template>
									</template>	
									<div class="invalid-feedback">
										{{item['errormessage'].value}}
									</div>										
								</div>
							</div>					
							</template> <!-- closing of v-if companyname-->

							<template v-else-if="item['fieldtypestring'].value == 'eventname'">
					        <div class="row no-gutters justify-content-start mt-3">
								<div class="col-12">
									<template v-if="item['children']">	
										<template v-for="(option, subindex) in item['children']">
												<input type="text" id="eventname" :placeholder="option['placeholder'].value" :name="option['title'].value" class="member-name" autocomplete="off" />			
										</template>
									</template>	
									<div class="invalid-feedback">
										{{item['errormessage'].value}}
									</div>										
								</div>
							</div>					
							</template> <!-- closing of v-if companyname-->

							
							<template v-else-if="item['fieldtypestring'].value == 'countrypicker'">
							   <div class="row no-gutters justify-content-start mt-3">
								<div class="col-12">
									<template v-if="item['children']">	
											<template v-for="(option, subindex) in item['children']">
														<select v-html="option['description'].value" id="member-country" :name="option['title'].value" :class="'form-control '+ option['cssclass'].value"></select>
											</template>
									</template> 
									<div class="invalid-feedback country-feedback"> {{item['errormessage'].value}}  </div>										
								</div>
							</div>				
							</template> <!-- closing of v-if countrypicker-->						

							<template v-else-if="item['fieldtypestring'].value == 'propertypicker'">
							   <div class="row no-gutters justify-content-start mt-3">
									<select class="form-control" id="rfp-hotel">
										<option value="default">{{item['placeholder'].value}}</option>
										<template v-if="hotelnames && hotelnames['children']">
											<template v-for="(hotelname, index) in hotelnames['children']">
												<template v-if="hotelname['isdisabled'].value === '1'">
													<option :value="hotelname['title'].value" disabled>{{hotelname["title"].value}}</option>
												</template>
												<template v-else>
													<option :value="hotelname['title'].value">{{hotelname["title"].value}}</option>
												</template>
											</template>
										</template>
									</select> 
									<div class="invalid-feedback hotel-feedback">{{item['errormessage'].value}} </div>									
								</div>				
							</template> <!-- closing of v-if Question  5-->
							
							<template v-else-if="item['fieldtypestring'].value == 'email'">
							   <div class="row no-gutters justify-content-start mt-3">
								<div class="col-12">
									<template v-if="item['children']">	
											<template v-for="(option, subindex) in item['children']">		 
												<input type="email" :placeholder="option['placeholder'].value" name="option['title'].value" class="member-email" autocomplete="off" />
												<div class="invalid-feedback email"> {{item['errormessage'].value}}  </div>
											</template>
									</template>	                         
								</div>
							</div>				
							</template> <!-- closing of v-if Question  6-->
							
							<template v-else-if="item['fieldtypestring'].value == 'phonenumber'">
							   <div class="row no-gutters justify-content-start mt-3">
								<div class="col-12">
									<div class="form-row">
									<template v-if="item['children']">	
										<template v-for="(option, subindex) in item['children']">
											<template v-if="option['fieldtypestring'].value == 'countrycode'">
											<div class="form-group col-md-4">
												<select v-html="option['description'].value" id="iddCode" :class="'form-control '+ option['cssclass'].value"></select>
											</div>
											
											</template>
											
											<template v-else>
											<div class="form-group col-md-8">
												<input type="number" :class="'form-control ' + option['cssclass'].value" :name="option['title'].value" id="inputMob" max="20" oninput="this.value=this.value.slice(0,11)" onkeydown="return event.keyCode !== 69">
												<!-- <input type="tel" :class="'form-control ' + option['cssclass'].value" :name="option['title'].value" id="inputMob" max="20" oninput="this.value=this.value.slice(0,11)" onkeydown="return event.keyCode !== 69"> -->
												<div class="invalid-feedback mobile">{{option['errormessage'].value}}</div>
											</div>
											</template>
										
										</template>
									</template>	


									</div> 
									<div class="invalid-feedback countrycode">{{item['errormessage'].value}}</div>
								</div>
							</div>				
							</template> <!-- closing of v-if Question  7-->	
														


							<template v-else-if="item['fieldtypestring'].value == 'submitbox'">
							   <div class="row no-gutters justify-content-start mt-3">
								<div class="col-12">
									<div class="form-group">
										<div class="form-group">
											<label for="hjenBookingDetails">{{item['placeholder'].value}} </label>
											<div id="rfpjenBookingDetails">
												<span class="rfpjenBClose"><img src="Banner/images/closeTab.png" /></span>
												<div id="rfpjenBDate">
													<div class="rfpjenDates">
														<input id="rfpstartBDate" class="rfpstartBDate" type="text" name="fromDate" value="" readonly/>
          												<input id="rfpendBDate" class="rfpendBDate" type="text" name="toDate" value="" readonly/>
														<input id="rfphiddenstartBDate" class="rfphiddenendBDate" type="hidden" value="" readonly/>
														<input id="rfphiddenendBDate" class="rfphiddenendBDate" type="hidden" value="" readonly/>
													</div>
													<div id="rfpjenBCalender" class="rfpjenCalender">
													</div>
												</div>
											</div>
										</div>
									</div>
									<template v-if="item['children']">	
										<template v-for="(option, subindex) in item['children']">
																						
											<template v-if="option['fieldtypestring'].value == 'checkbox'">
												<label class="container mt-5 checkmark-container" id="checkmark"><p v-html="option['description'].value"></p>
												<small v-html="option['placeholder'].value" class="form-text text-muted mt-4">
												</small>
												<input type="checkbox">
												<span class="checkmark"></span>
												</label>
												<div class="invalid-feedback terms">{{option['errormessage'].value}}</div>
											</template>
											
											<template v-else-if="option['fieldtypestring'].value == 'button'">
												<button type="button" id="form-submit" :class="'btn btn-primary final-step mt-4 '+ option['cssclass'].value">{{option['title'].value}}</button>								
											</template>
										
									
										</template>
									</template>	                          
								</div>
							</div>				
							</template> <!-- closing of v-if Question  9-->
							
							<template v-else-if="item['fieldtypestring'].value == 'summary'">
								<div class="row no-gutters justify-content-start">
								<form action="#" method="post" name="popupform" id="popupSubmit" class="row no-gutters justify-content-start" style="width:100%" >
								<template v-if="item['children'] && item['children'].length >= 3">	
								<!-- Temp Summary for RFP -->
								<div class="row no-gutters justify-content-start mt-2 summary-text">
									<div class="col-12">
										<p>{{item['errormessage'].value}}</p>
									</div>
								</div>
								<div class="row no-gutters justify-content-start mt-3 summary-section" style="width:100%;">
									<div class="col-5">
										<h4>{{item['children'][0]['title'].value}}</h4>
										<template v-if="item['children'][0]['children'] && item['children'][0]['children'].length > 0">
											<template v-for="(option0, subindex) in item['children'][0]['children']">
											<label :data-title="option0['title'].value">
												<!--<input :class="option0['cssclass'].value" type="text" name="name" value="" disabled/>-->
												<div :class="option0['cssclass'].value + ' summary-fields summary-name'"></div>
											</label>
											</template>
										</template>
										<h4 class="mt-5">{{item['children'][1]['title'].value}}</h4>
										<template v-if="item['children'][1]['children'] && item['children'][1]['children'].length > 0">
											<template v-for="(option1, subindex) in item['children'][1]['children']">
											<label :data-title="option1['title'].value">
												<!--<input :class="option1['cssclass'].value" type="text" name="name" value="" disabled/>-->
												<div :class="option1['cssclass'].value + ' summary-fields summary-name'"></div>
											</label>
											</template>
										</template>
										
									</div>
									<div class="col-2"></div>
									<div class="col-5">
										<h4>{{item['children'][2]['title'].value}}</h4>
										<template v-if="item['children'][2]['children'] && item['children'][2]['children'].length > 0">
											<template v-for="(option2, subindex) in item['children'][2]['children']">
											<label :data-title="option2['title'].value">
												<!--<input :class="option2['cssclass'].value" type="text" name="name" value="" disabled/>-->
												<div :class="option2['cssclass'].value + ' summary-fields summary-name'"></div>
											</label>
											</template>
										</template>
									</div>
								</div>
								
								<div class="row no-gutters justify-content-start mt-3" style="width:100%;">
										<div class="col-12 text-center">
											<button type="button" :class="'btn btn-primary final-step final-rfp-step mt-4 '+ item['children'][3]['cssclass'].value">{{item['children'][3]['title'].value}}</button>
										</div>
								</div>	
								
								<!-- End of Temp RFP -->
	
								</template>
								</form>
								</div>
															
							</template> <!-- closing of v-if Final Step-->
							

							
							
							<div :class="'row no-gutters justify-content-start mt-5 progress-section '+ [item['fieldtypestring'].value == 'submitbox' || item['fieldtypestring'].value == 'summary'?'':'pt-5']">
								<div class="col-2">
									<div class="prev-btn mr-2"></div>
									<div :class="'next-btn '+ [index == (formresults['children'].length - 2)?'finalstep-rfp-next':'']" :data-questionindex="index"></div>
								</div>
								<div class="col-3 pt-2">
									<div class="progress-container">
										<!-- Progress Bar --->
										<div class="progress">
												<div class="progress-bar" role="progressbar" :style="'width: '+ Math.round((index/formlength)*1000)/10 +'%;'" aria-valuemin="0" aria-valuemax="100"></div>
											</div>
										<p><span class="progress-text">{{Math.round((index/(formlength))*1000)/10}}</span>
										<!-- {{$t('% complete')}} -->
										{{getDictionaryPhrase('complete')}}
										</p>
										
									
									</div>
								</div>
							</div>
						</div> 
						
						<!--</template> <!-- Closing for item['children'] check -->


				</template>
				</template>
				
				  <img :src="closeIcon" class="close-btn2" :data-moduleid="moduleid"/>
				  <div id="thank-you-message" class="thank-you-message">
                        <h3>{{getDictionaryPhrase('JenSocietyThankYou')}}</h3>
                        <img :src="closeIcon" class="close-btn2":data-moduleid="moduleid"/>
                    </div>
                </div>
				
			<!-- No Longer in use for the new layout -->
			<div class="becomeamember-modal-m desktop-hidden mobile-hidden"> 
                    <div :data-moduleid="moduleid" class="close-btn-m"></div>
                    
					
				<div class="container-fluid" id="mobform-step1" >
                        <div class="row no-gutters justify-content-center">
                            <div class="col-12">
                                <h3>Enrolment (1/2)</h3>
                            </div>
                        </div>
                        <div class="row no-gutters justify-content-center">
                            <div class="col-12">
                                <form class="needs-validation" novalidate>
								<template v-if="this.formresults['children'] && this.formresults['children'] != ''">
									<template v-for="(item, index) in this.formresults['children']">
										<template v-if="item['groupname'].value == 'mobile1'">
											
												
												<template v-if="item['fieldtypestring'].value == 'fullname'">
													<div class="form-row">	
													<div class="col-12">
														<input type="text" id="mobfullname" class="form-control" name="lname" :placeholder="item['placeholder'].value" required>
														<div class="invalid-feedback mobile">
															{{item['errormessage'].value}}
														</div>
													</div>
													</div>
												</template> <!-- end of Mobile fullname mobile -->

												<template v-if="item['fieldtypestring'].value == 'companyname'">
													<div class="form-row">	
													<div class="col-12">
														<input type="text" id="mobcompanyname" class="form-control" name="lname" :placeholder="item['placeholder'].value" required>
														<div class="invalid-feedback mobile">
															{{item['errormessage'].value}}
														</div>
													</div>
													</div>
												</template> <!-- end of Mobile companyname mobile -->											
												
												<template v-if="item['fieldtypestring'].value == 'eventname'">	
													<div class="form-row">	
													<div class="col-12">
														<input type="text" id="mobeventname" class="form-control" name="lname" :placeholder="item['placeholder'].value" required>
														<div class="invalid-feedback mobile">
															{{item['errormessage'].value}}
														</div>
													</div>
													</div>
												</template> <!-- end of Mobile eventname mobile -->												
												
												<template v-if="item['fieldtypestring'].value == 'number'">	
													<div class="form-row">	
													<div class="col-12">
														<input type="number" id="rfp-number-mobile" class="form-control member-namerfp" name="attendess" :placeholder="item['placeholder'].value" required>
														<div class="invalid-feedback mobile">
															{{item['errormessage'].value}}
														</div>
													</div>
													</div>
												</template> <!-- end of Mobile number mobile -->

												<template v-if="item['fieldtypestring'].value == 'propertypicker'">	
													<div class="form-row">	
													<div class="col-12">
													<select class="form-control" name="hotel" id="rfp-hotel-m" required>
														<option value="">{{item['placeholder'].value}}</option>
														<template v-if="hotelnames && hotelnames['children']">
														<template v-for="(hotelname, index) in hotelnames['children']">
															<option :value="hotelname['title'].value">{{hotelname["title"].value}}</option>
														</template>
														</template>
													</select>  
														<div class="invalid-feedback mobile">
															{{item['errormessage'].value}}
														</div>
													</div>
													</div>
												</template> <!-- end of Mobile number mobile -->

												<template v-if="item['fieldtypestring'].value == 'countrypicker'">
													<div class="form-row">	
													<div class="col-12">
														<template v-if="item['children']">	
																<template v-for="(option, subindex) in item['children']">
																			<select v-html="option['description'].value" id="member-country-mobile" :name="option['title'].value" :class="'form-control '+ option['cssclass'].value"></select>
																</template>
														</template>  
														 <div class="invalid-feedback mobile socFormCountry">
															{{item['errormessage'].value}}
														</div>
													</div>
													</div>
												</template> <!-- end of Mobile Q5 -->
												
												<template v-if="item['fieldtypestring'].value == 'email'">
													<div class="form-row">	
													<div class="col-12">
														<input type="text" id="member-mobile-email" class="form-control" name="email" :placeholder="item['placeholder'].value" required>
														<div class="invalid-feedback mobile email">
															{{item['errormessage'].value}}
														</div>
													</div>
													</div>
												</template> <!-- end of Mobile Q6 -->

												<template v-if="item['fieldtypestring'].value == 'phonenumber'">
													<div class="form-row">	
														<template v-if="item['children']">	
														<template v-for="(option, subindex) in item['children']">
															<template v-if="option['fieldtypestring'].value == 'countrycode'">
															<div class="form-group col-4">
																<select v-html="option['description'].value" id="iddCodeMobile" :class="'form-control '+ option['cssclass'].value"  name="country-code" required></select>
															</div>														
															</template>
															
															<template v-else>
															<div class="form-group col-8">
															<input type="number" :class="'form-control ' + option['cssclass'].value" :name="option['title'].value" id="inputMobMob" required >
															</div>
															</template>
														
														</template>
														<template v-for="(option, subindex) in item['children']">
															<template v-if="option['fieldtypestring'].value == 'countrycode'">															
															<div class="invalid-feedback mobile countrycode"> {{option['errormessage'].value}} </div>															
															</template>
															
															<template v-else>															
															<div class="invalid-feedback mobile"> {{option['errormessage'].value}} </div>
															</template>													
														</template>
													</template>	
													</div>
												</template> <!-- end of phonenumber mobile -->
												
												<template v-if="item['fieldtypestring'].value == 'submitbox'">
										<div class="form-row">
                                        <div class="col-12">
                                            <label for="rfpjenMobBookingDetails">{{item['placeholder'].value}}</label>
                                            <div id="rfpjenMobBookingDetails">
                                                <span class="rfpjenMobBClose"><img src="images/closeTab.png" /></span>
                                                <div id="rfpjenMobBDate">
                                                    <div class="rfpjenMobDates">
                                                        <input id="rfpMobstartBDate" type="text" name="fromDate" value="" readonly/>                                                      
                                                    </div>
                                                    <div id="rfpjenMobBCalender" class="rfpjenMobCalender">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="col-12 pl-0">
										<template v-if="item['children']">	
											<template v-for="(option, subindex) in item['children']">
																							
												<template v-if="option['fieldtypestring'].value == 'checkbox'">
													<label class="container mt-5" id="checkmark-mob"><p v-html="option['description'].value"></p>
													<small v-html="option['placeholder'].value" class="form-text text-muted mt-4">
													</small>
													<input type="checkbox">
													<span class="checkmark"></span>
													</label>
													<div class="invalid-feedback term text-center mb-4">{{option['errormessage'].value}}</div>
												</template>
												
												<template v-else-if="option['fieldtypestring'].value == 'button'">
													<button type="button" id="form-submit" :class="'btn btn-primary final-step mt-4 '+ option['cssclass'].value">{{option['title'].value}}</button>								
												</template>
											
										
											</template>
										</template>	 
                                        </div>
                                    </div>
												
												
												</template>
										</template>								
									</template>
								</template> <!-- End of Mobile popup for Page 1 -->
         
                                    <button class="btn btn-primary mt-4 mob-next-step" type="submit">{{getDictionaryPhrase('next')}}</button>
                                </form>
                            </div>
                        </div>
                    </div> <!-- End of Member form Mobile Step 1 -->
				
					
                    <div class="container-fluid" id="mobform-step2" >
                        <div class="row no-gutters justify-content-center">
                            <div class="col-12">
                                <a href="" class="society-form-prev" data-id="1"></a>
                                <h3>almost there (2/3) </h3>
                            </div>
                        </div>
                        <div class="row no-gutters justify-content-center mob-summary pb-4">
                            <div class="col-12">
							<template v-if="this.formresults['children'] && this.formresults['children'] != ''">
							<template v-for="(item, index) in this.formresults['children']">
							<template v-if="item['groupname'].value == 'mobile2'">
								<template v-if="item['children']">	
									<template v-for="(option, subindex) in item['children']">
										<template v-if="option['fieldtypestring'].value == 'column'">
												<h4 :class="[subindex > 1?'mt-5 ':'']">{{option['title'].value}}</h4>
												<template v-if = "option['children'] && option['children'].length > 0">
													<template v-for="(optionfield, subsubindex) in option['children']">
														<label :data-title="optionfield['title'].value">
															<input :class="optionfield['cssclass'].value" type="text" name="name" value="" disabled/>
														</label>
													
													</template>
												</template>		
										</template>
										<template v-if="option['fieldtypestring'].value == 'button'">
											<button class="btn btn-primary mt-4" type="submit">option['title'].value</button>
										</template>
									</template>
								</template>
								</template>
								</template>
								</template>
					
                            </div>
                        </div>
                    </div><!-- End of Member form Mobile Step 3-->
					
                </div> <!-- End of Member form Mobile -->
			<!-- End: mobile RFP form -->	
            </div>
        <!--</div>-->
			
			<!-- End of Nico Form -->
		</template>
		

		</div> <!-- content -->
	</template>
	</div>
  </div>
  </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import { Image } from '@sitecore-jss/sitecore-jss-vue';
import axios from 'axios';
import { dataFetcher } from '../dataFetcher.js';
import { layoutFetcher, layoutFetchAction } from '../layoutFetcher.js';
import { getDictionaryPhrase, searchMoreContentByType, getKeys, getObject, graphqlRemoveReserved, graphqlToObject, getObjectById, graphqlFetcher } from '../graphqlFetcher.js';
//import $ from 'jquery';
import ApolloClient from 'apollo-boost';
import gql from 'graphql-tag';
import { uuid } from 'vue-uuid';
import {
  enableExperimentalFragmentVariables,
  disableExperimentalFragmentVariables,
} from 'graphql-tag';
import Vue from 'vue';
import { loadmore } from '../HJContentBlockList.js';
import {submitrfp } from '../hjutil.js';
import config from '../temp/config';
//import { ConnectedDemoQuery } from './hoteljen.query.graphql';
import HJCalendar from './HJCalendar';
import closeicon from '../assets/img/close_btn.png';

/*
const client = new ApolloClient({
  uri: config.sitecoreApiHost + '/api/' + config.jssAppName + '?sc_apikey=' + config.sitecoreSCApiKey,
});
*/
export default {
  name: 'HJForm',
  components: {
    ScText: Text,
	HJCalendar
  },
  props: {
    fields: {
      type: Object,
      default: function(){},
    },
    rendering: {
      type: Object,
    },
    uid: {
      type: String,
      default: () => uuid.v1(),
    },
    formid: {
      type: String,
      default: null,
    },
    LinkedForm: {
      type: String,
      default: null,
    },
    parentid: {
      type: String,
      default: () => 'CBD5CCD6-95AF-5EB6-992C-45B9D5AD8784',
    },
    lastitemid: {
      type: String,
      default: null,
    },
    formresults: {
      type: Object,
      default: null,
    },
    currentcontext: {
      type: Object,
      default: null,
    },
    formType: {
      type: String,
      default: () => 'submit-form',
    },
    moduleid: {
      type: String,
      default: () => '',
    },
  },
  data: function() {
    return {
      loadingQueriesCount: 0,
      error: null,
		data_datasource: {},
		hotelnames:[],
		eventtypes: [],
		formlength: 1,
		targetemail:'',
		targethotel:'',
		targetaddress:'',
		closeIcon: closeicon
    };
  },
  computed: { 
    datasource: function() {
	  if (this.currentcontext != null)
		return this.currentcontext;
	  else
		return this.fields && this.fields.data && this.fields.data.datasource;
    },
    contextItem: function() {
      return this.fields.data && this.fields.data.contextItem;
    },
  },
  mounted: function() {
	let vm = this;
	//console.log(vm);
	//console.log(vm.datasource);
	let hasProperty = false;

	//line to get location details
	if (vm.$jss.property() && vm.$jss.property() != ''){
		hasProperty = true;
		var propertyid = vm.$jss.property();
		var	propertyid = propertyid.replace(/\-/g,"").toUpperCase();
		// var hotelDetails = getObject(getObjectById(vm.$jss.hotels(),propertyid), vm);
		var hotelDetails = getObject(getObjectById(vm.$jss.hotels(),propertyid), vm);
	}

	if (this.fields && this.fields.data && this.fields.data.datasource)
	{
		graphqlRemoveReserved(this.fields.data.datasource);
	}
	
	vm.hotelnames = vm.$jss.hotelnames();
	
	if (vm.$jss.hotels() != null && vm.$jss.property() != null) {
		if (vm.hotelnames['children'] && vm.hotelnames['children'].length > 0)
		{
			for (var i=0; i < vm.hotelnames['children'].length; i++)
			{
				if (vm.hotelnames['children'][i]['id'].toLowerCase()
										.replace(/-/g, '')
										.replace(/{/g, '')
										.replace(/}/g, '') === vm.$jss.property().toLowerCase()
										.replace(/-/g, '')
										.replace(/{/g, '')
										.replace(/}/g, ''))
				{
					vm.targethotel = vm.hotelnames['children'][i]["fullname"].value;
					vm.targetaddress = vm.hotelnames['children'][i]["address"].value;
					if (vm.hotelnames['children'][i]["jensocietyemail"] && vm.hotelnames['children'][i]["jensocietyemail"].value)
					{
						vm.targetemail = vm.hotelnames['children'][i]["jensocietyemail"].value;
					}
					break;
				}
			}
		}
	}

	vm.eventtypes = vm.$jss.eventtypes();
	//if (vm.currentcontext != null && vm.formid != '')
	
	if ((vm.linkedform != null && vm.linkedform != '') || (vm.LinkedForm != null && vm.LinkedForm != ''))
	{
		var lf = "";
		if (vm.LinkedForm != null && vm.LinkedForm != '')
			lf = vm.LinkedForm;
		else if (vm.linkedform != null && vm.linkedform != '')
			lf = vm.linkedform;
			
        var query = `	
					query {item(path: "${lf}")
					{
					   id
						template
						{
							name
						}
						fields(ownFields:true)
						{
						  __typename
						  name
						  value
						  editable
						}
						children
						{
							id
							template
							{
								name
							}
							fields(ownFields:true)
							{
							  __typename
							  name
							  value
							  editable						  
							}
							children
							{
								id
								template
								{
									name
								}
								fields(ownFields:true)
								{
									__typename
									name
									value
									editable
								}
								children
								{
									id
									template
									{
										name
									}
									fields(ownFields:true)
									{
										__typename
										name
										value
										editable
									}
									children
									{
										id
										template
										{
											name
										}
										fields(ownFields:true)
										{
											__typename
											name
											value
											editable
										}
									}
								}
							}
						}
					}
			}`;
			graphqlFetcher(
			  '',
			  vm,
			  vm.formresults,
			  null,
			  query,
			  null,
			  function(data, _vm, ar) {
				_vm.formresults = ar;
				_vm.formlength = _vm.formresults['children'].length - 1;
		  },
			  false,
			  ['hjform', 'hjformfield'],
			  false
			);
	}
	else
	{
		if (vm.formid != null && vm.formid != '')
		/*	
			if the parent context and formid is passed to the form, find form attributes 
			from parent context
		*/
		{
			vm.formresults = {};
			vm.formresults = getObjectById(vm.currentcontext, vm.formid);
			//Test for Popup Form
			if (vm.formid == '67BAF09090604720B4B1346ED5E88C68' || vm.formid == '46FC3A6AFDDB4C539E901A07B0A95D31'){
			//console.log(vm.formresults);
			
			}
			vm.formlength = vm.formresults['children'].length - 1;
		}
		else
		{
			graphqlFetcher(
			  'form',
			  vm,
			  vm.formresults,
			  null,
			  '',
			  null,
			  function(data, _vm, ar) {
				_vm.formresults = ar;
			  },
			  false,
			  ['hjform', 'hjformfield'],
			  false
			);
		}
	}

    $(document).ready(function() {
      setTimeout(function() {
        $('.formbutton.' + vm.uid).on('click', function(e) {
          //console.log('el:mounted');
          //console.log('el:' + e.target.getAttribute('data-uid'));
        });
		//popupformInit();
      });
    });
	
	$('.privacy_policy').unbind('click').unbind('click', function(e){
		e.preventDefault();
	});
	if(hasProperty){
		var propertyName = hotelDetails.title.value;
		setTimeout(function(){
			$(`#rfp-hotel`).val(propertyName).change();
			$('#rfp-hotel').closest('.member-page8').find('.next-btn').addClass('active');
		},5000)
	}
  },
  updated: function() {
	let vm = this;
	var page = 0;
	var dateSuccess = false
	var countrySuccess = false;
	var iddCode = '';
	var mobCode = '';
	var iddCodeVer = false;
	
	if ( vm.$jss.routeData().itemLanguage == 'zh-CN' || vm.$jss.routeData().itemLanguage == 'zh'){
		var language = 'zh-CN'; 
		var language_m = 'zh';
	}else{
		var language = 'default'; 
		var language_m = 'en';
	} 
	
	/*
	//console.log('Popupform');
		popupformInit();
	}*/
	//console.log(vm.formType);
	
	if (vm.formType == 'popup-form'){
		popupformInit();
		$('.finalstep-mem-next').click(function(){
			// UpdateMemberSummary('desktop');
			
		});
	
		var pageForm = 0;
	}

	if (vm.formType == 'rfp-form'){
		typeof rfpformInit === 'function' ? rfpformInit() : '';
		
		//Init calendar
		$(document).ready(function(){
			if (!window['console'])
		{
			window.console = {};
			window.console.log = function(){};
		}

		var todayDate = new Date();
		if(todayDate.getDate() > 9)var dd = todayDate.getDate()
		else var dd = '0'+todayDate.getDate() 

		var fromDate = dd + ' ' + $.dateRangePickerLanguages[language]['month-name'][todayDate.getMonth()] + ' (' + $.dateRangePickerLanguages[language]['week-day'][todayDate.getDay()] + ')';
		var tomorrowDate = new Date(todayDate);

		tomorrowDate.setDate(tomorrowDate.getDate() + 1);
		if(tomorrowDate.getDate() > 9)var dd_end = tomorrowDate.getDate()
		else var dd_end = '0'+tomorrowDate.getDate() 
		var toDate = dd_end + ' ' + $.dateRangePickerLanguages[language]['month-name'][tomorrowDate.getMonth()] + ' (' + $.dateRangePickerLanguages[language]['week-day'][tomorrowDate.getDay()] + ')';
		
		//init settings for hidden start and end dates
		var newMonth = todayDate.getMonth() + 1;
		var newDate = todayDate.getDate();
		var newTmrMonth = tomorrowDate.getMonth() + 1;
		var newTmrDate = tomorrowDate.getDate();

		if(window.outerWidth > 1024){
		
			$('#rfphiddenstartBDate').val(`${todayDate.getFullYear()}-${newMonth < 10 ? newMonth = '0' + newMonth : newMonth}-${newDate < 10 ? newDate = '0' + newDate : newDate}`);
			$('#rfphiddenendBDate').val(`${tomorrowDate.getFullYear()}-${newTmrMonth < 10 ? newTmrMonth = '0' + newTmrMonth : newTmrMonth}-${newTmrDate < 10 ? newTmrDate = '0' + newTmrDate : newTmrDate}`);

			$('#rfpstartBDate').val(fromDate);
			$('#rfpendBDate').val(toDate);
		
			$('#rfpjenBDate').dateRangePicker(
			{
				// format: 'DD MMM (ddd)',
				format: 'YYYY-MM-DD',
				language: language,
				container: '#rfpjenBCalender',
				separator : '-',
				getValue: function()
				{
					if ($('#rfphiddenstartBDate').val() && $('#rfphiddenendBDate').val() ){
						return $('#rfphiddenstartBDate').val() + ' - ' + $('#rfphiddenendBDate').val();
					}
					else{
						return '';
					}
				},
				setValue: function(s,s1,s2)
				{
					// $('#rfpstartBDate').val(s1);
					// $('#rfpendBDate').val(s2);
			
					//Store Dates to hidden fields
					$('#rfphiddenstartBDate').val(s1);
					$('#rfphiddenendBDate').val(s2);

					$('#rfpstartBDate').val(s1);
					$('#rfpendBDate').val(s2);
					var startDate = new Date(s1);
					var endDate = new Date(s2);
					
					if (language == 'default'){
						startDate = startDate.getDate() +
						' ' +
						$.dateRangePickerLanguages[language]['month-name'][startDate.getMonth()] +
						' (' +
						$.dateRangePickerLanguages[language]['week-day'][startDate.getDay()] +
						')';	

						endDate = endDate.getDate() +
						' ' +
						$.dateRangePickerLanguages[language]['month-name'][endDate.getMonth()] +
						' (' +
						$.dateRangePickerLanguages[language]['week-day'][endDate.getDay()] +
						')';
					}else{
						startDate = 
						$.dateRangePickerLanguages[language]['month-name'][startDate.getMonth()] + ('0' + startDate.getDate()).slice(-2) +
						$.dateRangePickerLanguages[language]['day-name'] +
						' (' +
						$.dateRangePickerLanguages[language]['week-day'][startDate.getDay()] +
						')';

						endDate = 
						$.dateRangePickerLanguages[language]['month-name'][endDate.getMonth()] + ('0' + endDate.getDate()).slice(-2) +
						$.dateRangePickerLanguages[language]['day-name'] +
						' (' +
						$.dateRangePickerLanguages[language]['week-day'][endDate.getDay()] +
						')';
					}
					$('#rfpstartBDate').val(startDate);
					$('#rfpendBDate').val(endDate);
				},
				inline:false,
				startDate: new Date(),		
				selectForward: true,
				updatePicker: true,
				firstDate: $('#rfpstartBDate'),
				lastDate: $('#rfpendBDate'),
				hoverDate: $('#rfpjenBDays'),
				minDays: 0,
				showTopbar: false,
				alwaysOpen: false,
				stickyMonths: true,
				singleMonth: false,
				singleDate: false,
				maxDays: 181,
				startOfWeek: 'monday',
			});
		}else{
			
			var now = new Date();
			var yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
			var tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));
			var nextMonths = new Date(now.getFullYear(), now.getMonth() + 12, now.getDate());
			var day = 24 * 60 * 60 * 1000;
			
			$('#rfpjenBDate .rfpjenDates').mobiscroll().range({ 
				/*theme: 'ios',*/
				calendarScroll: 'vertical',
				lang: language_m,         // More info about calendarScroll: https://docs.mobiscroll.com/4-10-3/calendar#opt-calendarScroll
				//dateFormat: 'dd M (D) ',
				dateFormat: 'yy-mm-dd ',
				theme: 'ios',			
				themeVariant: 'light' ,  
				display: 'bottom',
				min: now,
				max:  nextMonths,
				counter: true,
				selectedText: "{count}",
				selectedPluralText: "{count}",
				onInit: function(event, inst){
					inst.setVal([now, tomorrow]);

					$('#rfpjenBDate #rfpstartBDate').val(inst.startVal);
					$('#rfpjenBDate #rfpendBDate').val(inst.endVal);
					$('#rfpjenBDate #rfphiddenstartBDate').val(inst.startVal);
					$('#rfpjenBDate #rfphiddenendBDate').val(inst.endVal);
					
					// for Safari, date format not recognizable for yy-mm-dd
					inst.startVal = inst.startVal.replace(/-/g, "/");
					inst.endVal = inst.endVal.replace(/-/g, "/");
	
					var startDate = new Date(inst.startVal);
					var endDate = new Date(inst.endVal);
		
					if (language == 'default'){
					startDate = startDate.getDate() +
					' ' +
					$.dateRangePickerLanguages[language]['month-name'][startDate.getMonth()] +
					' (' +
					$.dateRangePickerLanguages[language]['week-day'][startDate.getDay()] +
					')';

					endDate = endDate.getDate() +
					' ' +
					$.dateRangePickerLanguages[language]['month-name'][endDate.getMonth()] +
					' (' +
					$.dateRangePickerLanguages[language]['week-day'][endDate.getDay()] +
					')';
					}else{
					startDate = 
					//startDate.getFullYear() + $.dateRangePickerLanguages[language]['year-name'] +
					$.dateRangePickerLanguages[language]['month-name'][startDate.getMonth()] + ('0' + startDate.getDate()).slice(-2) +
					$.dateRangePickerLanguages[language]['day-name'] +
					' (' +
					$.dateRangePickerLanguages[language]['week-day'][startDate.getDay()] +
					')';

					endDate = 
					//endDate.getFullYear() + $.dateRangePickerLanguages[language]['year-name'] +
					$.dateRangePickerLanguages[language]['month-name'][endDate.getMonth()] + ('0' + endDate.getDate()).slice(-2) +
					$.dateRangePickerLanguages[language]['day-name'] + 
					' (' +
					$.dateRangePickerLanguages[language]['week-day'][endDate.getDay()] +
					')';
					
					
					}

					$('#rfpjenBDate #rfpstartBDate').val(startDate);
					$('#rfpjenBDate #rfpendBDate').val(endDate);
					
					
				},
				onSet: function (event, inst) {

					$('#rfpjenBDate #rfpstartBDate').val(inst.startVal);
					$('#rfpjenBDate #rfpendBDate').val(inst.endVal);
					$('#rfpjenBDate #rfphiddenstartBDate').val(inst.startVal);
					$('#rfpjenBDate #rfphiddenendBDate').val(inst.endVal);
					inst.startVal = inst.startVal.replace(/-/g, "/");
					inst.endVal = inst.endVal.replace(/-/g, "/");
					var startDate = new Date(inst.startVal);
					var endDate = new Date(inst.endVal);

					if (language == 'default'){
					startDate = startDate.getDate() +
					' ' +
					$.dateRangePickerLanguages[language]['month-name'][startDate.getMonth()] +
					' (' +
					$.dateRangePickerLanguages[language]['week-day'][startDate.getDay()] +
					')';

					endDate = endDate.getDate() +
					' ' +
					$.dateRangePickerLanguages[language]['month-name'][endDate.getMonth()] +
					' (' +
					$.dateRangePickerLanguages[language]['week-day'][endDate.getDay()] +
					')';
					
					}else{
					startDate = 
					//startDate.getFullYear() + $.dateRangePickerLanguages[language]['year-name'] +
					$.dateRangePickerLanguages[language]['month-name'][startDate.getMonth()] + ('0' +startDate.getDate()).slice(-2) +
					$.dateRangePickerLanguages[language]['day-name'] +
					' (' +
					$.dateRangePickerLanguages[language]['week-day'][endDate.getDay()] +
					')';

					endDate = 
					//endDate.getFullYear() + $.dateRangePickerLanguages[language]['year-name'] +
					$.dateRangePickerLanguages[language]['month-name'][endDate.getMonth()] + ('0' + endDate.getDate()).slice(-2) +
					$.dateRangePickerLanguages[language]['day-name'] +
					' (' +
					$.dateRangePickerLanguages[language]['week-day'][endDate.getDay()] +
					')';
					
					
					}		

					$('#rfpjenBDate #rfpstartBDate').val(startDate);
					$('#rfpjenBDate #rfpendBDate').val(endDate);
					
					
					
					//inst.settings.min = inst._startDate;
				}
			});  
			
		}
		
			// $('.rfpeventdateVal').html(`${$('.rfpstartBDate').val()} - ${$('.rfpendBDate').val()}`);
			if ($('.rfpstartBDate').val() === $('.rfpendBDate').val()) {
				$('.rfpeventdateVal').html(`${$('.rfpstartBDate').val()}`);
			} else {
				$('.rfpeventdateVal').html(
					`${$('.rfpstartBDate').val()} - ${$('.rfpendBDate').val()}`
				);
			}			
		
		});
		
	}
	
	 $(document).ready(function() {
	 
		//Submit Form - Member Form
		$('.final-member-step').unbind('click').bind('click', function(){			
			//console.log($('.fullnameVal').html() +';' +$('.birthdayVal').html() + ";" + $('.countryVal').html() + ";"+$('.emailVal').html()+";"+$('.numberVal').html());
			

			/*				
			{name:'Guest1', company: '1 company', email:'1@1.com', phoneNumber:'+85291119111', country:'HK', eventName:'Event Name', hotelCode:'HJB', eventStartDate:'2020-04-01', eventEndDate:'2020-04-01', numberOfAttendees:2}
			*/
			
			var cards = document.querySelectorAll('.becomeamember-modal .container-fluid');
			
			cards[0].style.setProperty('transform','translateX(0)');
			cards[0].style.setProperty('display','block');
			for(var c=1;c<cards.length;c++){
				cards[c].style.setProperty('transform','translateX(100vw)');
				cards[c].style.setProperty('display','none');
			} 

			var submit_data = {
				formtype: 'jensociety',
				targethotel: $('.HJForm').attr('data-targethotel'),
				targetaddress: $('.HJForm').attr('data-targetaddress'),
				targetemail: $('.HJForm').attr('data-targetemail'),
				name: $('.fullnameVal').html().replace(/</g, "&lt;").replace(/>/g, "&gt;"),
				//dateOfBirth: $('.birthdayVal').html(),
				email: $('.emailVal').html().replace(/</g, "&lt;").replace(/>/g, "&gt;"),
				country: $('.countryVal').html().replace(/</g, "&lt;").replace(/>/g, "&gt;"),
				phoneNumber: $('.numberVal').html().replace(' ', '').replace(/</g, "&lt;").replace(/>/g, "&gt;")
			};
			var rfp_callback = function(data)
			{
				//console.log(data);
			}

			// console.log(submit_data)
			submitrfp('/api/submitRFP',  submit_data, rfp_callback);
				
			$('.thank-you-message').addClass('active');
			$('.fullnameVal').html('')
			$('.numberVal').html('')
			$('.emailVal').html('')
			$('.countryVal').html('')


			$('#firstname').val('');
			$('#lastname').val('');
			$('#companyname').val('');
			$('.member-email').val('');
			$('#inputMob').val('');
			$('#iddCode').prop("selectedIndex", 0);
			$(`#member-country option:first`).prop('selected', true);
			
			var prevBtn = document.querySelectorAll('.becomeamember-modal .container-fluid .prev-btn');
			var nextBtn = document.querySelectorAll('.becomeamember-modal .container-fluid .next-btn');
			
			for(var y=0;y<prevBtn.length;y++){
				prevBtn[y].classList.remove('active');
				nextBtn[y].classList.remove('active');
			}

		});
		
		$('.final-rfp-step').unbind('click').bind('click', function(){			
			//console.log($('.fullnameVal').html() +';' +$('.birthdayVal').html() + ";" + $('.countryVal').html() + ";"+$('.emailVal').html()+";"+$('.numberVal').html());
			
			var cards = document.querySelectorAll('.becomeamember-modal .container-fluid');
			
			cards[0].style.setProperty('transform','translateX(0)');
			cards[0].style.setProperty('display','block');
			for(var c=1;c<cards.length;c++){
				cards[c].style.setProperty('transform','translateX(100vw)');
				cards[c].style.setProperty('display','none');
			} 

			/*				
			{name:'Guest1', company: '1 company', email:'1@1.com', phoneNumber:'+85291119111', country:'HK', eventName:'Event Name', hotelCode:'HJB', eventStartDate:'2020-04-01', eventEndDate:'2020-04-01', numberOfAttendees:2}
			*/

			var submit_data = {
				formtype: 'rfp',
				targetemail: $('.HJForm').attr('data-targetemail'),
				//name: $('.rfpfullnameVal').html() + ' ' + $('.rfplastnameVal').html(),
				name: $('.rfpfullnameVal').html().replace(/</g, "&lt;").replace(/>/g, "&gt;"),
				company: $('.rfpcompanyVal').html().replace(/</g, "&lt;").replace(/>/g, "&gt;"),
				email: $('.rfpemailVal').html().replace(/</g, "&lt;").replace(/>/g, "&gt;"),
				country: $('.rfpcountryVal').html().replace(/</g, "&lt;").replace(/>/g, "&gt;"),
				phoneNumber: $('.rfpnumberVal').html().replace(' ', '').replace(/</g, "&lt;").replace(/>/g, "&gt;").replace('+',''),
				hotel: $('.rfphotelVal').html().replace(/</g, "&lt;").replace(/>/g, "&gt;"),
				eventName: $('.rfpeventVal').html().replace(/</g, "&lt;").replace(/>/g, "&gt;"),
				numberOfAttendees: $('.rfpattendeeVal').html().replace(/</g, "&lt;").replace(/>/g, "&gt;"),
				eventStartDate: $('#rfphiddenstartBDate').val().replace(/</g, "&lt;").replace(/>/g, "&gt;"),
				eventEndDate: $('#rfphiddenendBDate').val().replace(/</g, "&lt;").replace(/>/g, "&gt;"),
				// eventDate: $('.rfpeventdateVal').html(),
			};
			var rfp_callback = function(data)
			{
				//console.log(data);
			}

			//submit data to api
			submitrfp('/api/submitRFP',  submit_data, rfp_callback );
			// console.log(submit_data)
			//line to get location details
			let hasProperty = false;


			if (vm.$jss.property() && vm.$jss.property() != ''){
				hasProperty = true;
				var propertyid = vm.$jss.property();
				var	propertyid = propertyid.replace(/\-/g,"").toUpperCase();
				// var hotelDetails = getObject(getObjectById(vm.hotels,propertyid), vm);
				var hotelDetails = getObject(getObjectById(vm.$jss.hotels(),propertyid), vm);
			}

			var todayDate = new Date();
			if(todayDate.getDate() > 9)var dd = todayDate.getDate()
			else var dd = '0'+todayDate.getDate() 

			var fromDate = dd + ' ' + $.dateRangePickerLanguages[language]['month-name'][todayDate.getMonth()] + ' (' + $.dateRangePickerLanguages[language]['week-day'][todayDate.getDay()] + ')';
			var tomorrowDate = new Date(todayDate);

			tomorrowDate.setDate(tomorrowDate.getDate() + 1);
			if(tomorrowDate.getDate() > 9)var dd_end = tomorrowDate.getDate()
			else var dd_end = '0'+tomorrowDate.getDate() 
			var toDate = dd_end + ' ' + $.dateRangePickerLanguages[language]['month-name'][tomorrowDate.getMonth()] + ' (' + $.dateRangePickerLanguages[language]['week-day'][tomorrowDate.getDay()] + ')';
			
			//init settings for hidden start and end dates
			var newMonth = todayDate.getMonth() + 1;
			var newDate = todayDate.getDate();
			var newTmrMonth = tomorrowDate.getMonth() + 1;
			var newTmrDate = tomorrowDate.getDate();

			if(hasProperty){
				var propertyName = hotelDetails.title.value;
				$(`#rfp-hotel option[value="default"]`).prop('selected', true);
			}
		
			$('#rfphiddenstartBDate').val(`${todayDate.getFullYear()}-${newMonth < 10 ? newMonth = '0' + newMonth : newMonth}-${newDate < 10 ? newDate = '0' + newDate : newDate}`);
			$('#rfphiddenendBDate').val(`${tomorrowDate.getFullYear()}-${newTmrMonth < 10 ? newTmrMonth = '0' + newTmrMonth : newTmrMonth}-${newTmrDate < 10 ? newTmrDate = '0' + newTmrDate : newTmrDate}`);

			$('#rfpstartBDate').val(fromDate);
			$('#rfpendBDate').val(toDate);
				
			$('.thank-you-message').addClass('active');
			$('.rfpfullnameVal').html('')
			$('.rfpcompanyVal').html('')
			$('.rfpnumberVal').html('')
			$('.rfpemailVal').html('')
			$('.rfpcountryVal').html('')
			$('.rfpeventVal').html('')
			$('.rfpattendeeVal').html('')


			$('#fullname').val('');
			$('#lastname').val('');
			$('#companyname').val('');
			$('.member-email').val('');
			$('#inputMob').val('');
			$('#iddCode').prop("selectedIndex", 0);
			$(`#member-country option[value="default"]`).prop('selected', true);
			$('#eventname').val('');
			$('#rfp-number').val('');
			$('#companyname').val('');
			$('#companyname').val('');
			$('#companyname').val('');
			
			var prevBtn = document.querySelectorAll('.becomeamember-modal .container-fluid .prev-btn');
			var nextBtn = document.querySelectorAll('.becomeamember-modal .container-fluid .next-btn');
			
			for(var y=0;y<prevBtn.length;y++){
				prevBtn[y].classList.remove('active');
				nextBtn[y].classList.remove('active');
			}
			// rfpformInit();
			// document.querySelectorAll('.finalstep-rfp-next')[0]
		});
		
	 
		/*$('.next-btn').unbind('click').bind('click', function(){
			if ($(this).hasClass('active')){
				var QIndex = $(this).data('questionindex');
				var ansId = $('#ans'+QIndex);
				//console.log(QIndex);
				
				if (QIndex == 0){
					ansId.val($('#firstname').val());
				}else if (QIndex == 1){
					ansId.val($('#lastname').val());
				}else if (QIndex == 2){
					var day = $('#dob-d').val();
					var month = $('#dob-m').val();
					var year = $('#dob-y').val();
					//var mydate = new Date(year, month - 1, day); 
					var mydate = year+'-'+month+'-'+day;
					ansId.val(mydate);
					//console.log(year+'-'+month+'-'+day);
				}else if (QIndex == 3){
					ansId.val($('#member-country').val());
				}else if (QIndex == 4){
					ansId.val($('.member-email').val());
				}else if (QIndex == 5){
					var countrycode = $('#iddCode').val();
					var mobile = $('#inputMob').val();
					ansId.val(countrycode+' '+mobile);
				}
				
				
			}
		
		}); */
		
		
		

	});

    $('.close-btn2').on('click',function(e){
		var hasProperty = false;
        e.preventDefault();
        $('#becomeamember-modal').removeClass('active');
        $('.booking-nav').css("display","block")
        $('#jenBbooking-nav').css('display','block')
        var moduleid = $(this).data('moduleid');
        $('.'+ moduleid + '_popup-container').removeClass('popupOpen');
        $('.'+ moduleid + '_popup-signup-container').removeClass('popupOpen');		
		$('body').css('overflow', '');
		if (window.innerWidth < 1025) {
			document.body.style.setProperty("position","relative")
			const scrollY = document.body.style.top;
			document.body.style.position = '';
			document.body.style.top = '';
			window.scrollTo(0, parseInt(scrollY || '0') * -1);
		}
        
        if($('#thank-you-message').hasClass('active')){
            page=0;
			//get hotel name
            if (vm.$jss.property() && vm.$jss.property() != ''){
                hasProperty = true;
                var propertyid = vm.$jss.property();
                var	propertyid = propertyid.replace(/\-/g,"").toUpperCase();
				// var hotelDetails = getObject(getObjectById(vm.hotels,propertyid), vm);
				var hotelDetails = getObject(getObjectById(vm.$jss.hotels(),propertyid), vm);
            }
            if(hasProperty){
                var propertyName = hotelDetails.title.value;
                setTimeout(function(){
                    $(`#rfp-hotel option[value="${propertyName}"]`).prop('selected', true);
                    $('#rfp-hotel').closest('.member-page8').find('.next-btn').addClass('active');
				},2000)
				// rfpformInit();
            }
        }
        
        $('#thank-you-message').removeClass('active');
        
    });
  
  },
  methods: {
		getDictionaryPhrase: function(key) {
			return getDictionaryPhrase(key, this);
		},
    isproperty: function(name) {
		if (this.fields != null && this.fields[name] != undefined)
		{
			return this.fields[name];
		}
		else
		{
			if (this.fields && this.fields.data && this.fields.data.datasource && this.fields.data.datasource.fields)
			{
				for (var i=0; i < this.fields.data.datasource.fields.length; i++)
				{
					if (this.fields.data.datasource.fields[i].name == name)
					{
						if (this.fields.data.datasource.fields[i].targetItems && 
							this.fields.data.datasource.fields[i].targetItems.length > 0) {
							if (this.fields.data.datasource.fields[i].targetItems[0].textField)
								return this.fields.data.datasource.fields[i].targetItems[0].textField.value;
							else if (this.fields.data.datasource.fields[i].targetItems[0].title)
								return this.fields.data.datasource.fields[i].targetItems[0].textField.title;
							else
								return "";
						}
						else
						{
							return this.fields.data.datasource.fields[i].value;
						}
					}
				}
			}
			else if (this.currentcontext && this.currentcontext.fields)
			{
				var keys = getKeys(this.currentcontext);
				for (var i=0; i < keys.length; i++)
				{
					if (keys[i].toLowerCase() === name.toLowerCase())
					{
						if (this.currentcontext[keys[i]].targetItems && 
							this.currentcontext[keys[i]].targetItems.length > 0) {
							if (this.currentcontext[keys[i]].targetItems[0].textField)
								return this.currentcontext[keys[i]].targetItems[0].textField.value;
							else if (this.currentcontext[keys[i]].targetItems[0].title)
								return this.currentcontext[keys[i]].targetItems[0].title.value;
							else
								return "";
						}
						else
						{
							if (this.currentcontext[keys[i]].value != undefined)
								return this.currentcontext[keys[i]].value;
							else
								return this.currentcontext[keys[i]];
						}
					}
				}
				for (var i=0; i < this.currentcontext.fields.length; i++)
				{
					if (this.currentcontext.fields[i].name.toLowerCase() == name.toLowerCase())
					{
						if (this.currentcontext.fields[i].targetItems && 
							this.currentcontext.fields[i].targetItems.length > 0) {
							if (this.currentcontext.fields[i].targetItems[0].textField)
								return this.currentcontext.fields[i].targetItems[0].textField.value;
							else if (this.currentcontext.fields[i].targetItems[0].title)
								return this.currentcontext.fields[i].targetItems[0].title.value;
							else
								return "";
						}
						else
						{
							if (this.currentcontext.fields[i].value)
								return this.currentcontext.fields[i].value;
							else
								return this.currentcontext.fields[i];
						}
					}
				}
			}
		}
		return null;
		var o = {};
		o.value = '';
		return o;
	},
	
  },
};
</script>
