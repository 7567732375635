<template>
    <div>
</div>
</template>


<script>

export default {
  name: 'MyFirstComponent',
  components: {},
  props: {
    fields: {
      type: Object,
      default: function() {},
    },
  },
};
</script>

