// Redirect.vue

<template>
    <div></div>
</template>
<script>
export default {
  mounted: function() {
    this.$router.push('/hotels/Beijing');
  },
};
</script>
