import { render, staticRenderFns } from "./HJRedirect.vue?vue&type=template&id=54cb0a62&"
import script from "./HJRedirect.vue?vue&type=script&lang=js&"
export * from "./HJRedirect.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "HJRedirect.vue"
export default component.exports