import { render, staticRenderFns } from "./RouteLoading.vue?vue&type=template&id=8e907e38&scoped=true&"
import script from "./RouteLoading.vue?vue&type=script&lang=js&"
export * from "./RouteLoading.vue?vue&type=script&lang=js&"
import style0 from "./RouteLoading.vue?vue&type=style&index=0&id=8e907e38&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8e907e38",
  null
  
)

component.options.__file = "RouteLoading.vue"
export default component.exports