import Vue from 'vue';
import Meta from 'vue-meta';
import VueApollo from 'vue-apollo';
import { SitecoreJssPlaceholderPlugin } from '@sitecore-jss/sitecore-jss-vue';
import AppRoot from './AppRoot';
import { createRouter } from './router';
import SitecoreJssStorePlugin from './lib/SitecoreJssStorePlugin';
import GraphQLClientFactory from './lib/GraphQLClientFactory';
import config from './temp/config';
import componentFactory from './temp/componentFactory';

Vue.use(Meta);
Vue.use(SitecoreJssStorePlugin);
Vue.use(SitecoreJssPlaceholderPlugin, { componentFactory });
Vue.use(VueApollo);

// createApp is invoked by both the main and SSR entry points, so the two entry points can use the same app creation process.
export function createApp(initialState, i18n) {
	//console.log("i18n");
	//console.log(i18n);
  Vue.config.productionTip = false;

  const router = createRouter();
  const graphQLProvider = createGraphQLProvider(initialState);

  const vueOptions = {
    apolloProvider: graphQLProvider,
    router,

    render: (createElement) => createElement(AppRoot),
  };
  // conditionally add i18n to the Vue instance if it is defined
  if (i18n) {
    vueOptions.i18n = i18n;
  }

  const app = new Vue(vueOptions);

  // if there is an initial state defined, push it into the store, where it can be referenced by interested components.
  if (initialState) {
    app.$jss.store.setSitecoreData(initialState);
	app.$jss.store.setHJGeoCookie("");
    //app.$jss.store.setPrices(null);
	app.$jss.store.setHotels(initialState.viewBag.hotels);
	app.$jss.store.setHotelnames(initialState.viewBag.hotels);
	app.$jss.store.setRooms(initialState.viewBag.rooms);
    app.$jss.store.setPrices(initialState.viewBag.hotelPrices);
    app.$jss.store.setConstants(initialState.viewBag.constants);
	if (initialState.viewBag.constants && initialState.viewBag.constants.children && initialState.viewBag.constants.children.length > 0)
	{
		var resources = initialState.viewBag.constants.children.find(x=>x.name.toLowerCase() ==="resources");
		if (resources != null && resources != undefined)
		{
			app.$jss.store.setDictionary(resources);
		}
	}
	//console.log(initialState.viewBag.system);
	//app.$jss.store.setHJGeoCookie(initialState.viewBag.geolocation);
	//sapp.$jss.store.setHotels(initialState.viewBag.hotels);
	//app.$jss.store.setHotelnames(initialState.viewBag.hotels);
	//app.$jss.store.setFeatures(initialState.viewBag.features);
	//app.$jss.store.setContentCategories(initialState.viewBag.contentcategories);
	//app.$jss.store.setRooms(initialState.viewBag.rooms);
	//app.$jss.store.setOpeningTimes(initialState.viewBag.openingtimes);
	//app.$jss.store.setServices(initialState.viewBag.services);
	//app.$jss.store.setNearby(initialState.viewBag.nearby);

  }
  //console.log(app.$jss.rooms());

  return { app, router, graphQLProvider };
}

export function createGraphQLProvider(initialState) {
  const client =
    initialState && initialState.APOLLO_STATE
      ? GraphQLClientFactory(config.graphQLEndpoint, false, initialState.APOLLO_STATE)
      : GraphQLClientFactory(config.graphQLEndpoint, true);

  const provider = new VueApollo({
    defaultClient: client,
  });

  return provider;
}
