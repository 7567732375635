import axios from 'axios';
import ApolloClient from 'apollo-boost';
import gql from 'graphql-tag';
import { enableExperimentalFragmentVariables } from 'graphql-tag';
import {getCurrencyPrice, getminprice, getprice} from './personalise.js';
//import 'babel-polyfill';
import config from './temp/config';
import crypto from 'crypto';
import { uuid } from 'vue-uuid';


export function getSortedContent(data, sortfield, isdate, isdescending)
{
	if (isdate == undefined)
		isdate = false;
	if (isdescending == undefined)
		isdescending = false;
	var results = [];

	for (var i=0; i < data.length; i++)
	{
		if (data[i][sortfield] && data[i][sortfield].value && data[i][sortfield].value != '')
		{
			if (results.length > 0)
			{
				for (var j=results.length-1; j >= 0; j--)
				{
					if (isdate)
					{
						//console.log(results[j][sortfield]);
						if (results[j][sortfield] && results[j][sortfield].value && results[j][sortfield].value != '')
						{
							var datestring = data[i][sortfield].value;
							var srcdate = new Date(datestring.substring(0,4)+"-"+datestring.substring(4,6)+"-"+datestring.substring(6,8));
							datestring = results[j][sortfield].value;
							var targetdate = new Date(datestring.substring(0,4)+"-"+datestring.substring(4,6)+"-"+datestring.substring(6,8));
							//console.log(srcdate)
							//console.log(targetdate)
							if (srcdate > targetdate)
							{
								if (j == results.length-1)
									results.push(data[i]);
								else
								{
									results.splice(j+1, 0, data[i]);
								}
								break;
							}
						}
					}
					else
					{
						if (sortfield.toLowerCase() === "orderindex")
						{
							if ((data[i][sortfield].value-0) > (results[j][sortfield].value-0))
							{
								if (j == results.length-1)
									results.push(data[i]);
								else
								{
									results.splice(j+1, 0, data[i]);
								}
								break;
							}
						}
						else
						{
							if (data[i][sortfield].value > results[j][sortfield].value)
							{
								if (j == results.length-1)
									results.push(data[i]);
								else
								{
									results.splice(j+1, 0, data[i]);
								}
								break;
							}
						}
					}
				}
			}
			else
				results.push(data[i]);
				
		}
	}
	console.log(results);
	return results;
}

export function bookhotel(key, iv, domain, hotelcode, cityalias, hotelalias, dStart, dEnd, rooms, adultchildselection, specialcodetype, specialcode, selectedroomurl, selectofferurl, language)
{
	if (dStart === undefined || dStart == null)
	{
		dStart = new Date();
		dStart.setDate(new Date().getDate()+1);
	}
	if (dEnd === undefined || dEnd == null)
	{
		dEnd = new Date();
		dEnd.setDate(new Date().getDate()+2);
	}
	if (rooms === undefined || rooms == null)
	{
		rooms = 1;
	}
	if (adultchildselection === undefined || adultchildselection == null)
	{
		adultchildselection = '10';
	}
	if (specialcodetype === undefined || specialcodetype == null || specialcodetype == '')
	{
		specialcodetype = 'None';
	}
	if (specialcode === undefined || specialcode == null)
	{
		specialcode = '';
	}
	if (selectedroomurl === undefined || selectedroomurl == null)
	{
		selectedroomurl = '';
	}
	if (selectofferurl === undefined || selectofferurl == null)
	{
		selectofferurl = '';
	}
	if (language === undefined || language == null)
	{
		language = "en";
	}
	if (language.toLowerCase() === 'zh' || language.toLowerCase() == 'zh-cn')
	{
		language = "cn";
	}
	var bookingString = dStart.yyyymmdd()+ '|' + dEnd.yyyymmdd() + '|' + rooms.toString() + '|' + adultchildselection + '|' + specialcodetype + '|' + specialcode + '|' + selectedroomurl + '|' + selectofferurl +'|' ;
	key = '99GCgc789GcgCg34';
	iv = '99GCgc789GcgCg34';
	console.log(dStart.yyyymmdd())
	console.log(dEnd.yyyymmdd())
	var s = encrypt_me(bookingString, key, iv);
	
	if (!(hotelcode === undefined || cityalias == undefined || hotelalias == undefined))
	{	
		window.open(domain + '/' + language + '/' + cityalias + '/' + hotelalias + '/reservations?json='+s+'&hid='+hotelcode, '_blank');				
	}
}

export function addAdhocImages(items, context)
{
	if (context.children && context.children.length > 0)
	{	
		for (var j=0; j < context.children.length; j++)
		{
			if ((context.children[j].templatename && context.children[j].templatename.toLowerCase() == "hjimage") || (context.children[j].template && context.children[j].template.name && context.children[j].template.name.toLowerCase() == "hjimage"))
			{
				var item = {};
				item.templatename = "imageset";
				item.title = {value:""};
				item.description = {value:""};
				item.children = [];
				item.children.push(context.children[j]);
				items.push(item);
			}
		}
	}
	/*
	var item = {};
	item.templatename = "imageset";
	item.title = {value:""};
	item.description = {value:""};
	item.children = [];
	if (context.children && context.children.length > 0)
	{	
		for (var j=0; j < context.children.length; j++)
		{
			if ((context.children[j].templatename && context.children[j].templatename.toLowerCase() == "hjimage") || (context.children[j].template && context.children[j].template.name && context.children[j].template.name.toLowerCase() == "hjimage"))
			{
				item.children.push(context.children[j]);
			}
		}
	}
	if (item.children.length > 0)
	{
		items.push(item);
	}
	console.log(item);
	*/
}
export function _testfunction()
{
	var uri = config.sitecoreApiHost + '/api/' + config.jssAppName + '?sc_apikey=' + config.sitecoreSCApiKey;
	
  const client = new ApolloClient({
	uri: config.sitecoreApiHost + '/api/' + config.jssAppName + '?sc_apikey=' + config.sitecoreSCApiKey,
    defaultOptions: {
      //fetchPolicy: 'no-cache',
	  fetchPolicy: 'cache-first',
    },
  });

    let query = gql`	
			query {item(path: "/sitecore/content/hoteljen/Content/Config") 
			{
				id
			}
			}
			`;

	var getData = function(){
		return "aaaa";
	}
	
	
	
	client
	  .query({
		query: query,
	  })
	  .then((data) => {
		getData_(data);
	  })
	  //.then(data => {console.log(data);})
	  .catch((error) => console.error('error:' + error));

	//return uri;
}
export function submitrfp(url, data, action)
{
	const auth = {
		headers: {
			"x-openapi-appid":"#", 
			"x-openapi-timestamp": "#", 
			"x-openapi-nonce": "#", 
			"x-openapi-signature": "#",
			"content-type": "application/json"
		}
	}

	/*
	axios.get('https://beta-openapi.shangri-la.com/openapi/v2/utils/queryExchangeRates',auth).then(result => { 
	 console.log(result.data)
	})
	*/

	axios.post(url, data ,auth).then(result => { 
	 action(result.data);
	})
}

export function getWeatherAPI(country){
	$.getJSON( "/api/getweather?city="+country, function( data ) {
		//console.log(data);
		var items = [];
		$.each( data, function( key, val ) {
		items.push( "<li id='" + key + "'>" + val + "</li>" );
		});
		//console.log(items);
    });     
}

export function appendAuthHeaders(headers, secret)
{
	var query = uuid.v1();
	var signature = crypto.createHmac("sha256", secret).update(query).digest("hex");

	if (headers != undefined && headers != null)
	{
		headers['x-signature'] = signature;
		headers['x-app-id'] = query;
	}
}

export function sitecoredatetodate(datestring)
{
	var date = new Date(datestring.substring(0,4)+"-"+datestring.substring(4,6)+"-"+datestring.substring(6,8));
	return date;
}

export function sitecoredatetodatetag(datestring, language, haspadding)
{
	var date = new Date(datestring.substring(0,4)+"-"+datestring.substring(4,6)+"-"+datestring.substring(6,8));
	if (haspadding == undefined || haspadding == null)
		haspadding = true;
	if (language == undefined || language == null)
		language = 'en';
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
	if (haspadding)
		month = month.padStart(2, '0');
    let day = date.getDate().toString();
	if (haspadding)
		day = day.padStart(2, '0');
	
	console.log(language);
	var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Dec"];
	if (language.toLowerCase() === "zh" || language.toLowerCase() === "zh-cn")
	{
		months = ["01月","02月","03月","04月","05月","06月","07月","08月","09月","10月","12月"];
	}
		
    return '<h3>' + day + '</h3><h4>' + months[date.getMonth()-1] + '</h4>';
}

var VIDEO_TYPE_GENERAL = 'General',
    VIDEO_TYPE_BRIGHTCOVE = 'Brightcove',
    VIDEO_TYPE_THIRD_PARTY = 'ThirdPartyLink';
const typeMap = {
	mp4: 'video/mp4',
	webm: 'video/webm',
	ogg: 'video/ogg',
}
export function getVideoInfo(external, uploaded) {
	let videoSource = '';
	let videoType = VIDEO_TYPE_GENERAL;
	let sourceType = typeMap['mp4'];
	let isThird = false

	if (external && external.value) {
		videoSource = external.value;
		if (isThirdLinkVideo(videoSource)) {
			videoType = VIDEO_TYPE_THIRD_PARTY;
			isThird = true;
            videoSource = generateVideoData(videoSource).videoIframeSrc;
		}
	} else if (uploaded && uploaded.url) {
		videoType = VIDEO_TYPE_GENERAL;
        videoSource = handleUploadedVideoSrc(uploaded);
	}

	if(videoType === VIDEO_TYPE_GENERAL && videoSource){
		let formatedVideoSource = videoSource.toLowerCase();
		if (formatedVideoSource.endsWith('.mp4')) {
			sourceType = typeMap['mp4']
		} else if (formatedVideoSource.endsWith('.webm')) {
			sourceType = typeMap['webm']
		} else if (formatedVideoSource.endsWith('.ogg')) {
			sourceType = typeMap['ogg']
		}
	}

	return {
		type: videoType, 
		videoSource,
		sourceType,
		isThird
	}
}

function formartSearch(search) {
    let query = {};
    search = search && search.substr(1);
    search && search.split('&').forEach((item) => {
        let itemSplitArr = item.split('=');
        let key = itemSplitArr[0];
        let value = itemSplitArr[1];
        query[key] = decodeURIComponent(value);
    });
    return query;
}

/**
 * 替换url中的参数
 * @param {string} videoPath - 第三方视频链接
 */
 function generateVideoData(videoPath) {
    let linkMatch = isThirdLinkVideo(videoPath);
    if (!linkMatch) {
        return {
            error: Error.NOT_THIRD_LINK_VIDEO
        };
    }
    // eslint-disable-next-line one-var
    let thirdLinkDomain = linkMatch[0].toLowerCase(),
        videoIframeSrc = '',
        error = '';
    if (thirdLinkDomain === Domains.YOUTUBE) {
		let videoSearch = videoPath.indexOf('?') > -1 ? videoPath.slice(videoPath.indexOf('?')) : '?';
        let urlInfo = formartSearch(videoSearch),
            videoId = urlInfo.v;
        videoIframeSrc = `https://www.youtube.com/embed/${videoId}?&autoplay=1`; //&origin=shangri-la.com
    } else if (thirdLinkDomain === Domains.TENCENT) {
        //https://v.qq.com/x/page/n3071j3xwzk.html
        let videoId = getPathTail(videoPath);
        videoIframeSrc = `https://v.qq.com/txp/iframe/player.html?vid=${videoId}&autoplay=true`;
    } else if (thirdLinkDomain === Domains.YOUKU) {
        //https://v.youku.com/v_show/id_XNDUxNjYxMzc1Ng==.html?spm=a2hzp.8244740.0.0
        let pathTail = getPathTail(videoPath),
            videoId = pathTail.split('_')[1];
        videoIframeSrc = `https://player.youku.com/embed/${videoId}`;
    }
    if(!videoIframeSrc) {
        error = Error.UNKNOWN;
    }
    return {
        error,
        videoIframeSrc
    };
}

const Domains = {
    YOUTUBE: 'youtube.com',
    TENCENT: 'qq.com',
    YOUKU: 'youku.com'
};

let thirdLinkDomains = [Domains.YOUTUBE, Domains.TENCENT, Domains.YOUKU];
const specificDomainsReg = new RegExp('(' + thirdLinkDomains.join('|') + ')', 'i');

/**
 * 判断是否是第三方视频链接
 * @param {string} videoPath 
 */
 function isThirdLinkVideo(videoPath) {
    return videoPath.match(specificDomainsReg);
}

function handleImageSrc(src = '') {
	const reg = /^\/-\/(jssmedia|media)/;
	const sitecoreHost = config.sitecoreApiHost;
	if (reg.test(src)) {
		return `${sitecoreHost}${src}`;
	}
	return src;
}

function handleUploadedVideoSrc(uploadedVideo = {}) {
	const { url, extension } =  uploadedVideo;
	const reg = /.+\.([a-z0-9]+)$/;
	const result = reg.exec(url);
	const newSrc =  url.replace && url.replace((result && result[1]) || 'ashx', extension);

	return handleImageSrc(newSrc);
} 

const defaultSearchTag = '.html';
function getPathTail(path, searchTag = defaultSearchTag) {
    let searchTagIndex = path.indexOf(searchTag);
    path = path.slice(0, searchTagIndex);
    let splitPath = path.split('/');
    return splitPath.pop();
}
