import { render, staticRenderFns } from "./HJBook.vue?vue&type=template&id=f3305f08&"
import script from "./HJBook.vue?vue&type=script&lang=js&"
export * from "./HJBook.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "HJBook.vue"
export default component.exports