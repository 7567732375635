<template>
  <div :id="this.uid" class="HJTestHeader">
  </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import { Image } from '@sitecore-jss/sitecore-jss-vue';
import axios from 'axios';
import { dataFetcher } from '../dataFetcher.js';
import { layoutFetcher, layoutFetchAction } from '../layoutFetcher.js';
import { graphqlRemoveReserved, graphqlFetcher } from '../graphqlFetcher.js';
import $ from 'jquery';
import ApolloClient from 'apollo-boost';
import gql from 'graphql-tag';
import { uuid } from 'vue-uuid';
import {
  enableExperimentalFragmentVariables,
  disableExperimentalFragmentVariables,
} from 'graphql-tag';
import Vue from 'vue';
import VueMeta from 'vue-meta';
import { trackingApi } from '@sitecore-jss/sitecore-jss-tracking';
import config from '../temp/config';
import { serverBus } from '../event-bus.js';
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

// set default config
VueCookies.config('7d')

export default {
  name: 'HJTestHeader',
  components: {
    ScText: Text,
  },
  props: {
    fields: {
      type: Object,
      default: function(){},
    },
    uid: {
      type: String,
      default: function(){return uuid.v1();},
    },
  },
  data() {
    return {
      loadingQueriesCount: 0,
      error: null
    };
  },
  computed: {  
    datasource: function() {
      return this.fields.data && this.fields.data.datasource;
    },
    contextItem: function() {
      return this.fields.data && this.fields.data.contextItem;
    },
    hotels: function() {
	  //serverBus.$emit('headerMounted', this.fields.data.hotels);
      return this.fields.data && this.fields.data.hotels;
    },
    allcontent: function() {
	  //serverBus.$emit('headerMounted', this.fields.data.allcontent);
      return this.fields.data && this.fields.data.allcontent;
    },
    services: function() {
      return this.fields.data && this.fields.data.services;
    },
    constants: function() {
      return this.fields.data && this.fields.data.constants;
    },
    templates: function() {
      return this.fields.data && this.fields.data.templates;
    },
	hotel_prices: function () {
		if (this.$jss.prices() != undefined && this.$jss.prices() != null )
			return this.$jss.prices(); 
		else
			return null;
	},
	eventtype: function () {
      return this.fields.data && this.fields.data.eventtype;
	},
  },
  mounted: function() {
	 
      let vm = this;	 
		let currency = vm.$jss.currency();
		$.getJSON( "/api/gethotelprices", function( data ) {
			vm.hotel_prices = data;
		});			
	  console.log("HJHeader Cookie");
	  console.log(vm.$cookies.keys());
	  if (this.fields.data && this.fields.data.hotels)
	  {
		graphqlRemoveReserved(this.fields.data.hotels);
	  }
	  vm.$root.hotels = this.fields.data.hotels;
  	  if (this.fields.data && this.fields.data.allcontent)
	  {
		graphqlRemoveReserved(this.fields.data.allcontent);
	  }
	  vm.$root.allcontent = this.fields.data.allcontent;
	  if (this.fields.data && this.fields.data.datasource)
	  {
		graphqlRemoveReserved(this.fields.data.datasource);
	  }
	  vm.$root.hjheader = this.fields.data.datasource;
	  if (this.fields.data && this.fields.data.eventtype)
	  {
		graphqlRemoveReserved(this.fields.data.eventtype);
	  }
	  vm.$root.eventtype = this.fields.data.eventtype;
	  if (this.fields.data && this.fields.data.constants)
	  {
		graphqlRemoveReserved(this.fields.data.constants);
	  }
	  vm.$root.constants = this.fields.data.constants;
	  if (this.fields.data && this.fields.data.services)
	  {
		graphqlRemoveReserved(this.fields.data.services);
	  }
	  vm.$root.services = this.fields.data.services;
	  //serverBus.$emit('headerMounted', this.fields.data.hotels, this.$root);
	  vm.$root.templates = this.fields.data.templates;
	 
  /*
  axios
    .get(
      'http://hoteljencd.mrmworldwide.asia/sitecore/testip.aspx?ipaddress=35.184.241.42&sc_apikey=EC98B168-BA7B-4ECF-B4D3-80690C9441E8'
    )
    .then(function(response) {
      console.log(response);
    });*/
		
  },
  updated() {

  },
  methods: {
  },
}
</script>
