<template>
  <div :id="this.uid" class="HJCountryBlock">
  </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import { Image } from '@sitecore-jss/sitecore-jss-vue';
import axios from 'axios';
import { dataFetcher } from '../dataFetcher.js';
import { layoutFetcher, layoutFetchAction } from '../layoutFetcher.js';
import { graphqlRemoveReserved, graphqlFetcherTestSSR, graphqlFetcher } from '../graphqlFetcher.js';
import $ from 'jquery';
import ApolloClient from 'apollo-boost';
import gql from 'graphql-tag';
import { uuid } from 'vue-uuid';
import {
  enableExperimentalFragmentVariables,
  disableExperimentalFragmentVariables,
} from 'graphql-tag';
import Vue from 'vue';
import VueMeta from 'vue-meta';
import { trackingApi } from '@sitecore-jss/sitecore-jss-tracking';
import config from '../temp/config';

export default {
  name: 'HJCountryBlock',
  components: {
    ScText: Text,
  },
  rendering: {
    type: Object,
  },
  props: {
    fields: {
      type: Object,
      default: function() {},
    },
    uid: {
      type: String,
      default: function() {
        return uuid.v1();
      },
    },
  },
  data() {
    return {
      loadingQueriesCount: 0,
      error: null,
    };
  },
  computed: {
    datasource: function() {
      return this.fields.data && this.fields.data.datasource;
    },
    contextItem: function() {
      return this.fields.data && this.fields.data.contextItem;
    },
  },
  methods: {
    isproperty: function(name) {
      if (this.fields != null && this.fields[name] != undefined) {
        if (this.fields[name]) {
          if (
            this.fields[name] &&
            this.fields[name].targetItems &&
            this.fields[name].targetItems.length > 0
          ) {
            if (this.fields[name].targetItems[0].textField)
              return this.fields[name].targetItems[0].textField.value;
            else if (this.fields[name].targetItems[0].title)
              return this.fields[name].targetItems[0].title.value;
            else return '';
          } else return this.fields[name];
        } else return '';
      } else {
        if (
          this.fields &&
          this.fields.data &&
          this.fields.data.datasource &&
          this.fields.data.datasource.fields
        ) {
          var datasource = graphqlToObject('', this.fields.data.datasource, this, '', null, false);
          if (datasource[name.toLowerCase()]) {
            if (name.toLowerCase() == 'layouttype') {
              console.log(datasource[name.toLowerCase()].value);
            }
            return datasource[name.toLowerCase()].value;
          } else {
            for (var i = 0; i < this.fields.data.datasource.fields.length; i++) {
              if (this.fields.data.datasource.fields[i].name == name) {
                if (name.toLowerCase() == 'layouttype') {
                  console.log(this.fields.data.datasource);
                }
                if (
                  this.fields.data.datasource.fields[i].targetItems &&
                  this.fields.data.datasource.fields[i].targetItems.length > 0
                ) {
                  if (this.fields.data.datasource.fields[i].targetItems[0].textField)
                    return this.fields.data.datasource.fields[i].targetItems[0].textField.value;
                  else if (this.fields.data.datasource.fields[i].targetItems[0].title)
                    return this.fields.data.datasource.fields[i].targetItems[0].title.value;
                  else return '';
                } else {
                  return this.fields.data.datasource.fields[i].value;
                }
              }
            }
          }
        }
      }
      var o = {};
      o.value = '';
      return o;
    },
  },
  mounted: function() {
    // ...

    var cur_currency = localStorage.getItem('curcurrency')
    var currentCurrency = ''

    if(cur_currency){
      vm.$jss.store.setCurrency(cur_currency);
    }else {
      vm.$jss.store.setCurrency('USD')
    }

    var getCurrency = function(vm) {
      if (
        vm.fields &&
        vm.fields.data &&
        vm.fields.data.datasource &&
        vm.fields.data.datasource.fields
      ) {
        if (vm.fields.data.datasource['currency']) {
          if (
            vm.fields.data.datasource['currency'].targetItems &&
            vm.fields.data.datasource['currency'].targetItems.length > 0
          ) {
            if (vm.fields.data.datasource['currency'].targetItems[0].code)
              return vm.fields.data.datasource['currency'].targetItems[0].code.value;
          }
        }
      }
      return '';
    };

    let vm = this;
    var currency = getCurrency(vm);
    if (currency != '') vm.$jss.store.setCurrency(currency);

    let current_item = vm.$jss.sitecoreContext();

    var _routedata = vm.$jss.routeData();

    setTimeout(function() {
      var from = document.referrer;
    }, 500);

/*    const trackingApiOptions = {
      host: config.sitecoreApiHost,
      querystringParams: {
        sc_apikey: config.sitecoreApiKey,
      },
      fetcher: dataFetcher,
    };
*/
    //trackingApi
    //  .trackEvent([{ pageId: '{36715B3C-7A6C-5576-9343-F6B504173D74}', url: '/hotels/beijing'}], trackingApiOptions)
    //  .then(() => console.log('Page event pushed'))
    //  .catch((error) => console.error(error));
  },
  updated: function() {
    let vm = this;
  },
  methods: {},
};
</script>
