import { render, staticRenderFns } from "./HJData.vue?vue&type=template&id=aed6cc34&"
import script from "./HJData.vue?vue&type=script&lang=js&"
export * from "./HJData.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "HJData.vue"
export default component.exports