<template>
  <div :id="this.uid" class="HJBook components">
	<!--<div class="desktop">	
	<div class="input-field">
		<select class="filter">
			<option value="all">All</option>
			<template v-if="hotelnames && hotelnames['children']">
			<template v-for="(hotelname, index) in hotelnames['children']">
				<template v-if="!(hotelname['isdisabled'] && hotelname['isdisabled'].value && hotelname['isdisabled'].value == '1')">
					<option :value="hotelname['id']">{{hotelname["title"].value}}</option>
				</template>
			</template>
			</template>
		</select>
	</div>
	<div class="input-field">
		<input type="text" :name="from" />
	</div>
	<div class="input-field">
		<input type="text" :name="to" />
	</div>
	<div class="input-field">
		<input type="text" :name="room" />
	</div>
	<div class="input-field">
		<input type="text" :name="adult" />
	</div>
	<div class="input-field">
		<input type="text" :name="specialcode" :placeholder="this.getDictionaryPhrase('SpecialCode')" />
	</div>
	<div class="button">
		<input type="button" :name="book" :value="getDictionaryPhrase('Book')" />
	</div>
	</div>
	<div class="mobile">
	</div>
  </div>-->
    <!--<div class="topBack">-->
	<div>
    <form method="post" onsubmit="return false;">
    <div id="jenBookingDetails" class="jenBookingDetails">
      <span class="jenBClose"></span>
      <div id="jenBHotels">
        <input type="text" class="jenBHotel" name="jenbhotels" id="jenBHotel" :placeholder="getDictionaryPhrase('BookDestination')" readonly />
        <div class="jenSelect">
			<div class="jenSelectItem active" data="0">{{getDictionaryPhrase('SelectHotel')}}</div>
            <template v-if="hotelnames && hotelnames['children']">
                <template v-for="(hotelname, index) in hotelnames['children']">
					<template v-if="!(hotelname['isdisabled'] && hotelname['isdisabled'].value && hotelname['isdisabled'].value == '1')">
						<!--<template v-if="this.$jss.property() != null && hotelname['id'].toLowerCase().replace(/-/g, '').replace(/{/g, '').replace(/}/g, '') ===
										  this.$jss.property().toLowerCase().replace(/-/g, '').replace(/{/g, '').replace(/}/g, '')">
							<div class="jenSelectItem active" data='1' :data-propertyid="hotelname['id']" :data-propertycode="hotelname['hotelcode'].value" :data-propertycityalias="hotelname['cityalias'].value"  :data-propertyhotelalias="hotelname['hotelalias'].value"  :data-propertymaxoccupy="hotelname['maxoccupy'].value">{{hotelname["title"].value}}</div>
						</template>
						<template v-else>-->
							<div class="jenSelectItem" data='1' :data-propertyid="hotelname['id']" :data-propertycode="hotelname['hotelcode'].value" :data-propertycityalias="hotelname['cityalias'].value"  :data-propertyhotelalias="hotelname['hotelalias'].value" :data-propertymaxoccupy="hotelname['maxoccupy'].value">{{hotelname["title"].value}}</div>
					</template>
                </template>
            </template>
        </div>
      </div>
      
      <div :id="uid+'_jenBDate'">
        <div class="jenDates">
          <input id="startBDate" class="startBDate" type="text" name="fromDate" value="" readonly/>
          <input id="startBDate_string" class="startBDate_string" type="text" name="fromDate_string" style="opacity:0;position:absolute;top:0;z-index:-1;" value="" readonly/>
         
          <input id="endBDate" class="endBDate" type="text" name="toDate" value="" readonly/>
		  <input id="endBDate_string" class="endBDate_string" type="text" name="toDate_string" style="opacity:0;position:absolute;top:0;z-index:-1;" value="" readonly/>
		  <div id="hiddenstartBDate" style="display:none;"></div>
		  <div id="hiddenendBDate" style="display:none;"></div>
          <span class="jenDays" id="jenBDays">{{getDictionaryPhrase('Book1Night')}}</span>
        </div>
        <div id="jenBCalender" class="jenCalender">
        </div>
      </div>
      
      <div id="jenBGuests">
        <div id="jenBGuestDetails" class="jenGuestDetails">{{getDictionaryPhrase('Book1Room1Adult0Children')}}</div>
        <div class="jenBGuests">
          <input type="hidden" name="rooms[]" value="1">
          <input type="hidden" name="room[]['adult']" value="1">
          <input type="hidden" name="rooms[]['children']" value="0">
        </div>
        <div id="jenBGuestSelect" class="jenGuestSelect jenBGuestSelect">
          <div class="jenGuestWrapper">
            <div class="jenGuestRow">
              <div class="jenGuestInfo">{{getDictionaryPhrase('BookMax3GuestsPerRoomPrefix')}} 3{{getDictionaryPhrase('BookMax3GuestsPerRoomSuffix')}}</div>
            </div>
            <div class="jenGuestHead jenGuestRow">
              <div class="jenGuestCol1"></div>
              <div class="jenGuestCol2">{{getDictionaryPhrase('CalendarAdult')}}</div>
              <div class="jenGuestCol3">{{getDictionaryPhrase('BookChildren12')}}</div>
            </div>
            <div id="brooms" class="rooms">
              <div class="jenGuestRow room" room="1">
                <div class="jenGuestCol1">{{getDictionaryPhrase('BookRoom')}} 1</div>
                <div class="jenGuestCol2 adult">
                  <span class="less disabled">-</span>
                  <span class="counter" data="adult">1</span>
                  <span class="more">+</span>
                </div>
                <div class="jenGuestCol3 child">
                  <span class="less disabled">-</span>
                  <span class="counter" data="child">0</span>
                  <span class="more">+</span>
                </div>
                <div class="jenGuestCol4">X</div>
              </div>
            </div>
            <div class="jenGuestRow">
              <div class="addRoom">
                <span>+ {{getDictionaryPhrase('BookAddRoom')}}</span>
              </div>
            </div>
            <div class="jenGuestRow childPolicy">
              <div>
                {{getDictionaryPhrase('BookChildrenMeal')}}
              </div>
            </div>
            <div class="jenGuestRow groupPolicy">
              <div>{{getDictionaryPhrase('BookExtraChildren')}}
              </div>
            </div>
          </div>
        </div>
      </div>
        
      <div id="jenBPromo">
	  
        <input type="text" name="promo" value="" :placeholder="getDictionaryPhrase('SpecialCode')" class="jenBPromo mobile-hidden" readonly/>   
		<input type="text" name="promo" value="" :placeholder="getDictionaryPhrase('SpecialCode')" class="hjenBPromo desktop-hidden"/>
        <div class="jenBPromoWrapper">
          <div class="jenRow">
            <div class="jenBPromoHead">{{getDictionaryPhrase('BookCorporateSpecialRate')}}</div>
          </div>
          <div class="jenRow jenExpand">
            <div class="jenSelectedItem">{{getDictionaryPhrase('SelectCodeType')}}</div>
            <div class="jenSelect">
              <div class="jenSelectItem active" data="0" data-codetype="None" >{{getDictionaryPhrase('SelectCodeType')}}</div>
              <div class="jenSelectItem" data="1" data-codetype="Corporate">{{getDictionaryPhrase('BookCorporate')}}</div>
              <div class="jenSelectItem" data="1" data-codetype="Group">{{getDictionaryPhrase('BookGroup')}}</div>
              <div class="jenSelectItem" data="1" data-codetype="TravelAgency">{{getDictionaryPhrase('BookTravelAgency')}}</div>
              <div class="jenSelectItem" data="1" data-codetype="Promotion">{{getDictionaryPhrase('BookPromotion')}}</div>
            </div>
          </div>
          <div class="jenRow">
            <input type="text" class="jenpromocode" maxlength="100" :placeholder="getDictionaryPhrase('EnterCode')">
          </div>
        </div>
      </div>
	  
      <div class="radio-container desktop-hidden">
        <label class="custom-radio mt-4 mr-4 ml-2">Corporate
            <input type="radio" name="radio">
            <span class="checkmark"></span>
        </label>
        <label class="custom-radio mr-3">Group
            <input type="radio" name="radio">
            <span class="checkmark"></span>
        </label>
        <br/>
        <label class="custom-radio mr-4 ml-2">Promotion
            <input type="radio" name="radio">
            <span class="checkmark"></span>
        </label>
        <label class="custom-radio mr-3">Travel agency
            <input type="radio" name="radio">
            <span class="checkmark"></span>
        </label>
    </div>
      
      <div id="jenBSubmit">
        <input type="submit" :value="getDictionaryPhrase('Book')" class="jenSubmit">
      </div>
    </div>
    </form>
	<!-- sss -->
  </div>
  <!--</div>-->
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import { Image } from '@sitecore-jss/sitecore-jss-vue';
import axios from 'axios';
import { dataFetcher } from '../dataFetcher.js';
import { layoutFetcher, layoutFetchAction } from '../layoutFetcher.js';
import {
getDictionaryPhrase,
  getObject,
  graphqlRemoveReserved,
  graphqlToObject,
  getObjectById,
  graphqlFetcher,
} from '../graphqlFetcher.js';
//import $ from 'jquery';
import ApolloClient from 'apollo-boost';
import gql from 'graphql-tag';
import { uuid } from 'vue-uuid';
import {
  enableExperimentalFragmentVariables,
  disableExperimentalFragmentVariables,
} from 'graphql-tag';
import Vue from 'vue';
import VueMeta from 'vue-meta';
import { trackingApi } from '@sitecore-jss/sitecore-jss-tracking';
import config from '../temp/config';
import {bookhotel} from '../hjutil.js';

export default {
  name: 'HJBook',
  components: {
    ScText: Text,
  },
  props: {
    fields: {
      type: Object,
      default: function() {},
    },
    currentcontext: {
      type: Object,
      default: null,
    },
    uid: {
      type: String,
      default: function() {
        return uuid.v1();
      },
    },
  },
  data() {
    return {
      loadingQueriesCount: 0,
      error: null,
      hotelnames: [],
	  domstartDate: '2020-04-03',
	  domendDate: '2020-04-04'
    };
  },
  computed: {
    datasource: function() {
      return this.fields.data && this.fields.data.datasource;
    },
    contextItem: function() {
      return this.fields.data && this.fields.data.contextItem;
    },
    hotels: function() {
      if (this.$root.hotels) return this.$root.hotels;
      else return {};
    },
    constants: function() {
      if (this.$root.constants) return this.$root.constants;
      else {
        return {};
      }
    },
  },
  mounted: function() {
    // ...
	
    let vm = this;
	
	if ( vm.$jss.routeData().itemLanguage == 'zh-CN' || vm.$jss.routeData().itemLanguage == 'zh'){
		var language = 'zh-CN'; 
		var language_m = 'zh';
		var night_translate = '晚';
		var night_translate_pl = '晚';
	}else{
		var language = 'default'; 
		var language_m = 'en';
		var night_translate = ' night';
		var night_translate_pl = ' nights';
	} 
	//var language = 'zh-cn'; 
	
	if (vm.$jss.hotelnames() == null || !(vm.$jss.hotelnames() != null && vm.$jss.hotelnames().children && vm.$jss.hotelnames().children.length > 0))
		vm.$jss.store.setHotelnames(getObject(vm.$jss.hotels(), vm));
	vm.hotelnames = vm.$jss.hotelnames();

    setTimeout(function() {
      var from = document.referrer;
    }, 500);
	
	
	var hiddenToDate = new Date().toISOString().slice(0,10);
	var hiddenEndDate = new Date();
	hiddenEndDate.setDate(hiddenEndDate.getDate() + 1);
	hiddenEndDate = hiddenEndDate.toISOString().slice(0,10);
	if ($('#' + vm.uid + '_jenBDate #hiddenstartBDate').val() == '' && $('#' + vm.uid + '_jenBDate #hiddenendBDate').val() == ''){
		//console.log('set date');
		$('#' + vm.uid + '_jenBDate #hiddenstartBDate').html(hiddenToDate);
		$('#' + vm.uid + '_jenBDate #hiddenendBDate').html(hiddenEndDate);
	}
	
	if ($('#' + vm.uid + ' #jenBookingDetails').find('#jenBHotels .jenSelect').find('.active').attr('data-propertymaxoccupy') != undefined)
	{
		$('#' + vm.uid + ' .jenGuestInfo').html(vm.getDictionaryPhrase("BookMax3GuestsPerRoomPrefix") + $('#' + vm.uid + ' #jenBookingDetails').find('#jenBHotels .jenSelect').find('.active').attr('data-propertymaxoccupy') + vm.getDictionaryPhrase("BookMax3GuestsPerRoomSuffix"));
	}
	else
	{
		$('#' + vm.uid + ' .jenGuestInfo').html(vm.getDictionaryPhrase("BookMax3GuestsPerRoom"));
	}
	
	 var todayDate = new Date();
	 var fromDate_string = todayDate.yyyymmdd();
	 var tomorrowDate = new Date(todayDate);
    tomorrowDate.setDate(tomorrowDate.getDate() + 1);
    var toDate_string = tomorrowDate.yyyymmdd();
	
	if (language == 'default'){
	var fromDate =
      todayDate.getDate() +
      ' ' +
      $.dateRangePickerLanguages[language]['month-name'][todayDate.getMonth()] +
      ' (' +
      $.dateRangePickerLanguages[language]['week-day'][todayDate.getDay()] +
      ')';
    
    var toDate =
      tomorrowDate.getDate() +
      ' ' +
      $.dateRangePickerLanguages[language]['month-name'][tomorrowDate.getMonth()] +
      ' (' +
      $.dateRangePickerLanguages[language]['week-day'][tomorrowDate.getDay()] +
      ')';
	 }else{
		var fromDate = 
		//todayDate.getFullYear() + $.dateRangePickerLanguages[language]['year-name'] +
		$.dateRangePickerLanguages[language]['month-name'][todayDate.getMonth()] + ('0' + todayDate.getDate()).slice(-2) +
		$.dateRangePickerLanguages[language]['day-name'] +
		  ' (' +
		  $.dateRangePickerLanguages[language]['week-day'][todayDate.getDay()] +
		  ')';

		var toDate = 
		//tomorrowDate.getFullYear() + $.dateRangePickerLanguages[language]['year-name'] +
		$.dateRangePickerLanguages[language]['month-name'][tomorrowDate.getMonth()] + ('0' + tomorrowDate.getDate()).slice(-2) +
		$.dateRangePickerLanguages[language]['day-name'] +
		  ' (' +
		  $.dateRangePickerLanguages[language]['week-day'][tomorrowDate.getDay()] +
		  ')';
	 
	 
	 }
	  
	  

	//console.log('set dates on load');
    $('#' + vm.uid + ' #startBDate').val(fromDate);
    $('#' + vm.uid + ' #endBDate').val(toDate); 

	$('#' + vm.uid + ' #startBDate_string').val(fromDate_string);
    $('#' + vm.uid + ' #endBDate_string').val(toDate_string);
	
	if (window.outerWidth > 1024){
	 $('#' + vm.uid + '_jenBDate .jenDates').dateRangePicker({
      //format: 'DD MMM (ddd)',
      format: 'YYYY-MM-DD',
      container: '#' + vm.uid + '_jenBDate #jenBCalender',
      separator: '-',
	  language: language,
	  //altFormat: "yy-mm-dd",	
		//altField: '#rangeBDate',    
		getValue: function() {

        if (
          $('#' + vm.uid + '_jenBDate #startBDate_string').val() &&
          $('#' + vm.uid + '_jenBDate #endBDate_string').val()
        )
          return (
            $('#' + vm.uid + '_jenBDate #startBDate_string').val() +
            ' - ' +
            $('#' + vm.uid + '_jenBDate #endBDate_string').val()
          );
        else return '';
      },
      setValue: function(s, s1, s2) {
		// store start date and end date to dom
		//vm.domstartDate = s1;
		//vm.domendDate = s2;
		//console.log(vm.domstartDate + ' to ' + vm.domendDate);
		
		//Store Dates to hidden fields
		$('#' + vm.uid + '_jenBDate #hiddenstartBDate').html(s1);
        $('#' + vm.uid + '_jenBDate #hiddenendBDate').html(s2);
		$('#' + vm.uid + '_jenBDate #startBDate_string').val(s1);
        $('#' + vm.uid + '_jenBDate #endBDate_string').val(s2);
		var startDate = new Date(s1);
		var endDate = new Date(s2);
		
		if (language == 'default'){
		startDate = startDate.getDate() +
		' ' +
		$.dateRangePickerLanguages[language]['month-name'][startDate.getMonth()] +
		' (' +
		$.dateRangePickerLanguages[language]['week-day'][startDate.getDay()] +
		')';	

		endDate = endDate.getDate() +
		' ' +
		$.dateRangePickerLanguages[language]['month-name'][endDate.getMonth()] +
		' (' +
		$.dateRangePickerLanguages[language]['week-day'][endDate.getDay()] +
		')';
		}else{
		
		startDate = 
		//startDate.getFullYear() + $.dateRangePickerLanguages[language]['year-name'] +
		$.dateRangePickerLanguages[language]['month-name'][startDate.getMonth()] + ('0' + startDate.getDate()).slice(-2) +
		$.dateRangePickerLanguages[language]['day-name'] +
		' (' +
		$.dateRangePickerLanguages[language]['week-day'][startDate.getDay()] +
		')';

		endDate = 
		//endDate.getFullYear() + $.dateRangePickerLanguages[language]['year-name'] +
		$.dateRangePickerLanguages[language]['month-name'][endDate.getMonth()] + ('0' + endDate.getDate()).slice(-2) +
		$.dateRangePickerLanguages[language]['day-name'] +
		' (' +
		$.dateRangePickerLanguages[language]['week-day'][endDate.getDay()] +
		')';
		
		}
		//console.log(s1);
		//console.log(s2);

		//console.log(startDate);
		//console.log(endDate);
		
        $('#' + vm.uid + '_jenBDate #startBDate').val(startDate);
        $('#' + vm.uid + '_jenBDate #endBDate').val(endDate);
      },
      inline: false,
      startDate: new Date(),
      selectForward: true,
      updatePicker: true,
      firstDate: $('#' + vm.uid + '_jenBDate startBDate'),
      lastDate: $('#' + vm.uid + '_jenBDate #endBDate'),
      hoverDate: $('#' + vm.uid + '_jenBDate #jenBDays'),
      minDays: 2,
      showTopbar: false,
      alwaysOpen: false,
      stickyMonths: true,
      maxDays: 181,
      startOfWeek: 'monday',
    });

	}else{
	
			var now = new Date();
			var yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
			var tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));
			var nextMonths = new Date(now.getFullYear(), now.getMonth() + 12, now.getDate());
			var day = 24 * 60 * 60 * 1000;
      
      
			$('#' + vm.uid + '_jenBDate .jenDates:first').mobiscroll().range({ 
				/*theme: 'ios',*/
				calendarScroll: 'vertical',
				lang: language_m,         // More info about calendarScroll: https://docs.mobiscroll.com/4-10-3/calendar#opt-calendarScroll
				//dateFormat: 'dd M (D) ',
				dateFormat: 'yy-mm-dd ',
				theme: 'ios',			
				themeVariant: 'light' ,  
				display: 'bottom',
				min: now,
				max:  nextMonths,
				counter: true,
				selectedText: "{count}",
				selectedPluralText: "{count}",
				onInit: function(event, inst){
					inst.setVal([now, tomorrow]);

					$('#' + vm.uid + '_jenBDate #startBDate_string').val(inst.startVal);
					$('#' + vm.uid + '_jenBDate #endBDate_string').val(inst.endVal);
					$('#' + vm.uid + '_jenBDate #hiddenstartBDate').html(inst.startVal);
					$('#' + vm.uid + '_jenBDate #hiddenendBDate').html(inst.endVal);
					
					// for Safari, date format not recognizable for yy-mm-dd
					inst.startVal = inst.startVal.replace(/-/g, "/");
					inst.endVal = inst.endVal.replace(/-/g, "/");
	
					var startDate = new Date(inst.startVal);
					var endDate = new Date(inst.endVal);
		
					if (language == 'default'){
					startDate = startDate.getDate() +
					' ' +
					$.dateRangePickerLanguages[language]['month-name'][startDate.getMonth()] +
					' (' +
					$.dateRangePickerLanguages[language]['week-day'][startDate.getDay()] +
					')';

					endDate = endDate.getDate() +
					' ' +
					$.dateRangePickerLanguages[language]['month-name'][endDate.getMonth()] +
					' (' +
					$.dateRangePickerLanguages[language]['week-day'][endDate.getDay()] +
					')';
					}else{
					startDate = 
					//startDate.getFullYear() + $.dateRangePickerLanguages[language]['year-name'] +
					$.dateRangePickerLanguages[language]['month-name'][startDate.getMonth()] + ('0' + startDate.getDate()).slice(-2) +
					$.dateRangePickerLanguages[language]['day-name'] +
					' (' +
					$.dateRangePickerLanguages[language]['week-day'][startDate.getDay()] +
					')';

					endDate = 
					//endDate.getFullYear() + $.dateRangePickerLanguages[language]['year-name'] +
					$.dateRangePickerLanguages[language]['month-name'][endDate.getMonth()] + ('0' + endDate.getDate()).slice(-2) +
					$.dateRangePickerLanguages[language]['day-name'] + 
					' (' +
					$.dateRangePickerLanguages[language]['week-day'][endDate.getDay()] +
					')';
					
					
					}

					$('#' + vm.uid + '_jenBDate #startBDate').val(startDate);
					$('#' + vm.uid + '_jenBDate #endBDate').val(endDate);
					
					
				},
				onBeforeShow: function (event, inst){
					//console.log('Open Calendar');
					//inst.settings.min = tomorrow;
				},
				onSetDate: function (event, inst){

					//inst.refresh();
					//$('#hjenmstartBDate').val(inst.startVal);
					//$('#hjenmendBDate').val(inst.endVal);
					//
				},
				onDayChange: function (ev, inst) {
					/*console.log(ev.active);
					if (ev.active === 'start') { // start date will be selected
						var startDate = ev.date;

						// set the max date to the selected start date + 7 days
						inst.option({ min: new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()) });
					}*/
				},
				onSet: function (event, inst) {

					$('#' + vm.uid + '_jenBDate #startBDate_string').val(inst.startVal);
					$('#' + vm.uid + '_jenBDate #endBDate_string').val(inst.endVal);
					$('#' + vm.uid + '_jenBDate #hiddenstartBDate').html(inst.startVal);
					$('#' + vm.uid + '_jenBDate #hiddenendBDate').html(inst.endVal);
					inst.startVal = inst.startVal.replace(/-/g, "/");
					inst.endVal = inst.endVal.replace(/-/g, "/");
					var startDate = new Date(inst.startVal);
					var endDate = new Date(inst.endVal);

					if (language == 'default'){
					startDate = startDate.getDate() +
					' ' +
					$.dateRangePickerLanguages[language]['month-name'][startDate.getMonth()] +
					' (' +
					$.dateRangePickerLanguages[language]['week-day'][startDate.getDay()] +
					')';

					endDate = endDate.getDate() +
					' ' +
					$.dateRangePickerLanguages[language]['month-name'][endDate.getMonth()] +
					' (' +
					$.dateRangePickerLanguages[language]['week-day'][endDate.getDay()] +
					')';
					
					}else{
					startDate = 
					//startDate.getFullYear() + $.dateRangePickerLanguages[language]['year-name'] +
					$.dateRangePickerLanguages[language]['month-name'][startDate.getMonth()] + ('0' +startDate.getDate()).slice(-2) +
					$.dateRangePickerLanguages[language]['day-name'] +
					' (' +
					$.dateRangePickerLanguages[language]['week-day'][endDate.getDay()] +
					')';

					endDate = 
					//endDate.getFullYear() + $.dateRangePickerLanguages[language]['year-name'] +
					$.dateRangePickerLanguages[language]['month-name'][endDate.getMonth()] + ('0' + endDate.getDate()).slice(-2) +
					$.dateRangePickerLanguages[language]['day-name'] +
					' (' +
					$.dateRangePickerLanguages[language]['week-day'][endDate.getDay()] +
					')';
					
					
					}		

					$('#' + vm.uid + '_jenBDate #startBDate').val(startDate);
					$('#' + vm.uid + '_jenBDate #endBDate').val(endDate);
					var night = parseInt($('.mbsc-fr-hdr').html()) -1 ;
					if (night > 1){
						var nightText = night + night_translate_pl;
					}else{
						var nightText = night + night_translate;
					}
					$('#' + vm.uid + '_jenBDate .jenDays').html( nightText );
					
					
					//inst.settings.min = inst._startDate;
				}
		});   
	}
	// Get Booking Event 
	function getBooking(event) {

    console.log(event);
	
	  var bookingString = "";
		var adultchildselection = '';
	  var submitb = $(event.target);
      $(submitb)
        .parent()
        .parent()
        .find('.jenGuestSelect .room').length;
      if (
        $(event.target)
          .parent()
          .parent()
          .find('.jenGuestSelect .room').length > 0
      ) {
		var roomlength = $(event.target)
          .parent()
          .parent()
          .find('.jenGuestSelect .room').length;
		  
		var totaladult = 0;
		var totalchild = 0;
        $(event.target)
          .parent()
          .parent()
          .find('.jenGuestSelect .room')
          .each(function() {
            var parent = $(this);
            var sibling = parent.siblings('.counter');
            //var current = sibling.attr('data');
            var adult = parent.find('.adult');
            var child = parent.find('.child');
            totaladult += adult.find('.counter').html() * 1;
            totalchild += child.find('.counter').html() * 1;
			if (adultchildselection != '')
				adultchildselection += ',';
			adultchildselection += (adult.find('.counter').html() * 1).toString() + (child.find('.counter').html() * 1).toString();
            //if (current == 'adult') 
			//	totaladult = totaladult + ctr;
            //else if (current == 'child') 
			//	totalchild = totalchild + ctr;

            var dStart = new Date($('#' + vm.uid + '_jenBDate #hiddenstartBDate').html());
            var dEnd = new Date($('#' + vm.uid + '_jenBDate #hiddenendBDate').html()); 

            if (totaladult + totalchild > 2) {
              //console.log('family');
              return;
            } else {
              if (
                dStart.getDay() > 0 &&
                dStart.getDay() < 6 &&
                (dEnd.getDay() > 0 || dEnd.getDay() < 6)
              ) {
                if (totaladult + totalchild == 1) {
                  //console.log('business');
                  return;
                }
                if (totaladult + totalchild > 1) {
                  //console.log('leisure');
                  return;
                }
              }
            }
          });			
			//var dStart = new Date($('#' + vm.uid + '_jenBDate #hiddenstartBDate').html()); //modified to get from hidden fields
            //var dEnd = new Date($('#' + vm.uid + '_jenBDate #hiddenendBDate').html()); //modified to get from hidden fields

            console.log($(event.target).parent().parent().find('#hiddenstartBDate').html().replace(/-/ig,"/"))
            console.log($(event.target).parent().parent().find('#hiddenendBDate').html().replace(/-/ig,"/"))
			
			var dStart = new Date($(event.target).parent().parent().find('#hiddenstartBDate').html().replace(/-/ig,"/"));
            var dEnd = new Date($(event.target).parent().parent().find('#hiddenendBDate').html().replace(/-/ig,"/"));
			
			bookingString = dStart.yyyymmdd()+ '|' + dEnd.yyyymmdd() + '|' + roomlength + '|' + adultchildselection + '|None|' + $('#' + vm.uid + ' #jenBPromo .jenBPromo').val() + '|||' ;
			var key = '99GCgc789GcgCg34';
			var iv = '99GCgc789GcgCg34';
			  var s = encrypt_me(bookingString, key, iv);
			  

			  var propertycode = $(event.target).parent().parent().find(' #jenBHotels .jenSelect').find('.active').attr('data-propertycode');

			if (propertycode === undefined) {
			  const propertyInput = $(event.target).parent().parent().find('#jenBHotel');
			  propertyInput.addClass('is-invalid');
			  propertyInput.attr('placeholder', 'Enter location').val('').focus().blur();
			}

      

			var propertycityalias = $(event.target).parent().parent().find(' #jenBHotels .jenSelect').find('.active').attr('data-propertycityalias');
			var propertyhotelalias = $(event.target).parent().parent().find(' #jenBHotels .jenSelect').find('.active').attr('data-propertyhotelalias');
			
			var promo_type = "";
			if ($('#' + vm.uid + ' #jenBPromo .jenSelectItem.active').length > 0)
			{
				$('#' + vm.uid + ' #jenBPromo .jenSelectItem.active').each(function(){
					if ($(this).prev('.jenSelectedItem').html() != '')
					{
						promo_type = $(this).attr('data-codetype');
					}
				});
			}
			
			var language = 'en'; 
			if ( vm.$jss.routeData().itemLanguage == 'zh-CN' || vm.$jss.routeData().itemLanguage == 'zh'){
				language = 'cn'; 
			}else{
				language = vm.$jss.routeData().itemLanguage.substring(vm.$jss.routeData().itemLanguage.length-2).toLowerCase()	;		
			} 

			console.log(totaladult);
			console.log(totalchild);
			console.log(roomlength);
			//	no need for offerurl and roomtypecode
			bookhotel(key, iv, vm.$jss.hjconfig().book_url, propertycode, propertycityalias, propertyhotelalias, dStart, dEnd, roomlength, adultchildselection, promo_type, $('#' + vm.uid + ' #jenBPromo .jenpromocode').val(), '', '', language);
			/*
		var parent = guest.closest('.room');
		var sibling = guest.siblings('.counter');
		var current = sibling.attr('data');
		var adult = parent.find('.adult');
		var child = parent.find('.child');
		var totaladult += (adult.find('.counter').html()*1);
		var totalchild += (child.find('.counter').html()*1);
		if(current == "adult")
			totaladult = totaladult + ctr;
		else if(current == "child")
			totalchild = totalchild + ctr;

		*/
      }
    }
	
	//Submit booking 
	$(document).ready(function() {
		setTimeout(function(){
		//$('.jenSubmit').unbind().bind('click', function(){
		$('.HJBook .jenSubmit').unbind('click').bind('click', function(e){
					getBooking(e);
		});
		},300);
			// code to set active
		  $('#' + vm.uid + ' #jenBHotels .jenSelectItem').each(function() {
			$(this).removeClass('active');
		  });
		  $('#' + vm.uid + ' #jenBHotels .jenSelectItem').each(function() {
			  if ($(this).attr('data-propertyid') == $('.HJContainer').attr('data-propertyid'))
			  {
				  $(this).addClass('active');
				  if (
					$('#' + vm.uid + ' #jenBHotels .jenSelect')
					  .find('.active')
					  .attr('data') == '1'
				  ) {
					$('#' + vm.uid + ' #jenBHotel').val($(this).html());
				  } else {
					$('#' + vm.uid + ' #jenBHotel').val('');
				  }
				  $('#' + vm.uid + ' #jenBHotels .jenSelect').hide();
			  }
		  });
		$('#' + vm.uid + ' .jenGuestInfo').html(vm.getDictionaryPhrase("BookMax3GuestsPerRoomPrefix") + $('#' + vm.uid + ' #jenBookingDetails').find('#jenBHotels .jenSelect').find('.active').attr('data-propertymaxoccupy') + vm.getDictionaryPhrase("BookMax3GuestsPerRoomSuffix"));
			// END: code to set active
	});
	
  },
  updated() {
    let vm = this;
    var minRAdult = 1;
    var maxRAdult = 12;
    var minRChild = 0;
    var maxRChild = 4;

    var breakPoint = 1024;
	
	//Store Start Date and End Date for Booking to Dom on load 
	/*vm.domstartDate = new Date().toISOString().slice(0,10);
	vm.domendDate = new Date();
	vm.domendDate.setDate(vm.domendDate.getDate() + 1);
	vm.domendDate = vm.domendDate.toISOString().slice(0,10);*/
	
	//console.log(vm.domstartDate + ' to ' + vm.domendDate);
	
	//Assign default values to hidden start and end date input fields
	
	
	
   

	$(document).ready(function() {
		
	
		$('.jenSubmit').unbind().bind('click', function(){
			var target = $(event.target);
			if(!target.closest('#jenBGuests').length && $('#jenBGuestSelect').is(":visible")) {
			  $('#jenBGuestSelect').hide();
			}

			if(!target.closest('#jenBPromo').length && $('.jenBPromoWrapper').is(":visible")) {
			  $('.jenBPromoWrapper').hide();
			  $('jenBPromoWrapper .jenSelect').hide();
			}
			
			if(!target.closest('#jenBHotels').length && $('#jenBHotels .jenSelect').is(":visible")) {
			  $('#jenBHotels .jenSelect').hide();
			}
			
			  $('#' + vm.uid + ' #jenBHotels .jenSelectItem').each(function() {
				$(this).removeClass('active');
			  });
			  $('#' + vm.uid + ' #jenBHotels .jenSelectItem').each(function() {
				  console.log('outside jenBHotels');
				  if ($(this).attr('data-propertyid') == $('.HJContainer').attr('data-propertyid'))
				  {
					  console.log('inside jenBHotels');
					  $(this).addClass('active');
					  if (
						$('#' + vm.uid + ' #jenBHotels .jenSelect')
						  .find('.active')
						  .attr('data') == '1'
					  ) {
						$('#' + vm.uid + ' #jenBHotel').val($(this).html());
					  } else {
						$('#' + vm.uid + ' #jenBHotel').val('');
					  }
					  $('#' + vm.uid + ' #jenBHotels .jenSelect').hide();
				  }
			  });
		});
		
		$(document).mousedown(function(event) { 
		var target = $(event.target);
		if(!target.closest('#jenBGuests').length && $('.jenBGuestSelect').is(":visible")) {
		  $('.jenBGuestSelect').hide();
		 
		}
		if(!target.closest('#jenGuestSelect').length && $('#jenGuestSelect').is(":visible")) {
		  $('#jenGuestSelect').hide();
		 
		}
		if(!target.closest('#jenBPromo').length && $('.jenBPromoWrapper').is(":visible")) {
		  $('.jenBPromoWrapper').hide();
		  $('jenBPromoWrapper .jenSelect').hide();
		}
		if(!target.closest('.jenDates').length && $('#jenTimeWrapper').is(":visible")) {
		  $('#jenTimeWrapper').hide();
		}
		if(!target.closest('#jenRGuests').length && $('#jenRGuestSelect').is(":visible")) {
		  $('#jenRGuestSelect').hide();
		  //console.log('#jenRGuestSelect');
		}
		if(!target.closest('#jenBHotels').length && $('#jenBHotels .jenSelect').is(":visible")) {
		  $('#jenBHotels .jenSelect').hide();
		}
	});
		/*$(document).mousedown(function(event) { 
			var target = $(event.target);
			//console.log(target);
		});*/
		
	});
	
	 
	
    if (!window['console']) {
      window.console = {};
      window.console.log = function() {};
    }

   

	//Date Picker Init in updated
   /* $('#' + vm.uid + '_jenBDate .jenDates').dateRangePicker({
      //format: 'DD MMM (ddd)',
      format: 'YYYY-MM-DD',
      container: '#' + vm.uid + '_jenBDate #jenBCalender',
      separator: '-',
	  //altFormat: "yy-mm-dd",	
		//altField: '#rangeBDate',    
	getValue: function() {
		//console.log($(this));
		//console.log($(this).val());
        if (
          $('#' + vm.uid + '_jenBDate #startBDate').val() &&
          $('#' + vm.uid + '_jenBDate #endBDate').val()
        )
          return (
            $('#' + vm.uid + '_jenBDate #startBDate').val() +
            ' - ' +
            $('#' + vm.uid + '_jenBDate #endBDate').val()
          );
        else return '';
      },
      setValue: function(s, s1, s2) {
		// store start date and end date to dom
		//vm.domstartDate = s1;
		//vm.domendDate = s2;
		//console.log(vm.domstartDate + ' to ' + vm.domendDate);
		
		//Store Dates to hidden fields
		$('#' + vm.uid + '_jenBDate #hiddenstartBDate').val(s1);
        $('#' + vm.uid + '_jenBDate #hiddenendBDate').val(s2);
		var startDate = new Date(s1);
		var endDate = new Date(s2);
		
		
		startDate = startDate.getDate() +
		' ' +
		$.dateRangePickerLanguages[language]['month-name'][startDate.getMonth()] +
		' (' +
		$.dateRangePickerLanguages[language]['week-day'][startDate.getDay()] +
		')';

		endDate = endDate.getDate() +
		' ' +
		$.dateRangePickerLanguages[language]['month-name'][endDate.getMonth()] +
		' (' +
		$.dateRangePickerLanguages[language]['week-day'][endDate.getDay()] +
		')';
		//console.log(s1);
		//console.log(s2);

		//console.log(startDate);
		//console.log(endDate);
		
        $('#' + vm.uid + '_jenBDate #startBDate').val(startDate);
        $('#' + vm.uid + '_jenBDate #endBDate').val(endDate);
      },
      inline: false,
      startDate: new Date(),
      selectForward: true,
      updatePicker: true,
      firstDate: $('#' + vm.uid + '_jenBDate startBDate'),
      lastDate: $('#' + vm.uid + '_jenBDate #endBDate'),
      hoverDate: $('#' + vm.uid + '_jenBDate #jenBDays'),
      minDays: 2,
      showTopbar: false,
      alwaysOpen: false,
      stickyMonths: true,
      maxDays: 181,
      startOfWeek: 'monday',
    });*/



    //$('#' + vm.uid + '_jenBDate #startDate').val(fromDate);
    //$('#' + vm.uid + '_jenBDate #endDate').val(toDate);

    /*$(document).mousedown(function(event) { 
		var target = $(event.target);
		if(!target.closest('#jenBGuests').length && $('#jenBGuestSelect').is(":visible")) {
		  $('#jenBGuestSelect').hide();
		}

		if(!target.closest('#jenBPromo').length && $('.jenBPromoWrapper').is(":visible")) {
		  $('.jenBPromoWrapper').hide();
		  $('jenBPromoWrapper .jenSelect').hide();
		}
		
		if(!target.closest('#jenBHotels').length && $('#jenBHotels .jenSelect').is(":visible")) {
		  $('#jenBHotels .jenSelect').hide();
		}
		getTotalGuests(event);
	});*/

    // $('#' + vm.uid +' .jenBClose').click(function() {
    $('.jenBClose').click(function() {
      // $('#' + vm.uid +' #jenBookingDetails').css('display', 'none');
	    $('#' + vm.uid +' #jenBookingDetails').removeClass('jenSticky');
      
      // $('body').removeClass('lockScreen');
    });

    // $('#' + vm.uid + ' .jenBOpen').click(function() {
    $('#jenBbooking-nav').click(function() {
      if ($(document).width() <= breakPoint) {
        $('#' + vm.uid + ' #jenBookingDetails').addClass('jenSticky');
      }
      $('#' + vm.uid + ' .jenBClose').show();
      // $('#' + vm.uid + ' #jenBookingDetails').show();
      // $('#' + vm.uid + ' .jenBOpen').hide();
      // $('body').addClass('lockScreen');
    });

    $('#' + vm.uid + ' #jenBGuestDetails').unbind('click').bind('click', function() {
      $('#' + vm.uid + ' #jenBGuestSelect').toggle();
    });

    $('#' + vm.uid + ' .jenBPromo').unbind('click').bind('click', function(e) {
      $('.jenBPromoWrapper').toggle();
    });

    $('#jenBPromo .jenSelectedItem').click(function() {
      $('#' + vm.uid + ' #jenBPromo .jenSelect').toggle();
    });

    $('#' + vm.uid + ' #jenBPromo .jenSelectItem').click(function() {
      $('#' + vm.uid + ' #jenBPromo .jenSelectItem').each(function() {
        $(this).removeClass('active');
      });
      $('#' + vm.uid + ' #jenBPromo .jenSelectedItem').html($(this).html());
      $(this).addClass('active');
      $('#' + vm.uid + ' #jenBPromo .jenSelect').hide();
      if (
        $('#' + vm.uid + ' #jenBPromo .jenSelect')
          .find('.active')
          .attr('data') == '1' &&
        $('#' + vm.uid + ' #jenBPromo .jenBPromo').val() != ''
      )
        $('#' + vm.uid + ' #jenBPromo .jenBPromo').val($(this).html() + ', ' + $('#' + vm.uid + ' #jenBPromo .jenpromocode').val());
      else $('#' + vm.uid + ' #jenBPromo .jenBPromo').val('');
    });

    $('#' + vm.uid + ' #jenBPromo .jenpromocode').keyup(function() {
      if (
        $('#' + vm.uid + ' #jenBPromo .jenSelect')
          .find('.active')
          .attr('data') == '1' &&
        $(this).val() != ''
      )
        $('#' + vm.uid + ' #jenBPromo .jenBPromo').val(
          $('#' + vm.uid + ' #jenBPromo .jenSelect')
            .find('.active')
            .html() +
            ', ' +
            $(this).val()
        );
      else $('#' + vm.uid + ' #jenBPromo .jenBPromo').val('');
    });

    /*$('#selectTime').click(function () {
		$('#jenTimePicker #jenTimeWrapper').toggle();
	});*/

    $('#' + vm.uid + ' #jenBHotel').unbind('click').bind('click',function(){


      $('#' + vm.uid + ' #jenBHotels .jenSelect').toggle();
    });

    $('#' + vm.uid + ' #jenBHotels .jenSelectItem').click(function() {
      $('#' + vm.uid + ' #jenBHotels .jenSelectItem').each(function() {
        $(this).removeClass('active');
      });
      $(this).addClass('active');
      if (
        $('#' + vm.uid + ' #jenBHotels .jenSelect')
          .find('.active')
          .attr('data') == '1'
      ) {
        $('#' + vm.uid + ' #jenBHotel').val($(this).html());
      } else {
        $('#' + vm.uid + ' #jenBHotel').val('');
      }
      $('#' + vm.uid + ' #jenBHotels .jenSelect').hide();
		$('#' + vm.uid + ' .jenGuestInfo').html(vm.getDictionaryPhrase("BookMax3GuestsPerRoomPrefix") + $('#' + vm.uid + ' #jenBookingDetails').find('#jenBHotels .jenSelect').find('.active').attr('data-propertymaxoccupy') + vm.getDictionaryPhrase("BookMax3GuestsPerRoomSuffix"));
		
		console.log('resetGuests');
		resetGuests(vm.uid);
		updatePolicy($('#' + vm.uid + ' #brooms'), vm.getDictionaryPhrase("BookRoom"), vm.getDictionaryPhrase("CalendarAdult"), vm.getDictionaryPhrase("CalendarChildren"));

    });


    $('#' + vm.uid + ' #brooms').on('click', '.less', function() {
      var less = updateGuests($(this), -1);
      if (less) {
        $(this)
          .siblings('.counter')
          .html(
            $(this)
              .siblings('.counter')
              .html() *
              1 -
              1
          );
      }
      updatePolicy($('#' + vm.uid + ' #brooms'), vm.getDictionaryPhrase("BookRoom"), vm.getDictionaryPhrase("CalendarAdult"), vm.getDictionaryPhrase("CalendarChildren"));
    });

    $('#' + vm.uid + ' #brooms').on('click', '.more', function() {
      var more = updateGuests($(this), 1);
      if (more) {
        $(this)
          .siblings('.counter')
          .html(
            $(this)
              .siblings('.counter')
              .html() *
              1 +
              1
          );
      }
      updatePolicy($('#' + vm.uid + ' #brooms'), vm.getDictionaryPhrase("BookRoom"), vm.getDictionaryPhrase("CalendarAdult"), vm.getDictionaryPhrase("CalendarChildren"));
    });

    /*$('#rooms').on('click', '.less', function () {
		var less = updateGuests($(this), -1);
		if(less) {
			$(this).siblings('.counter').html($(this).siblings('.counter').html()*1 - 1);
		}
		updatePolicy($('#rooms'), vm.getDictionaryPhrase("BookRoom"), vm.getDictionaryPhrase("CalendarAdult"), vm.getDictionaryPhrase("CalendarChildren"));
	});

	$('#rooms').on('click', '.more', function () {
		var more = updateGuests($(this), 1);
		if(more) {
			$(this).siblings('.counter').html($(this).siblings('.counter').html()*1 + 1);
		}
		updatePolicy($('#rooms'), vm.getDictionaryPhrase("BookRoom"), vm.getDictionaryPhrase("CalendarAdult"), vm.getDictionaryPhrase("CalendarChildren"));
	});*/

    $('#' + vm.uid + ' #jenBGuests .addRoom').click(function() {
      addRoom($(this), vm.getDictionaryPhrase("BookRoom"), vm.getDictionaryPhrase("CalendarAdult"), vm.getDictionaryPhrase("CalendarChildren"));
      var roomno = $('#brooms').children('.room').length;
      if (roomno >= 5) {
        $(this)
          .parent()
          .css('display', 'none');
      }
    });

    /*$('#jenGuests .addRoom').click( function () {
		addRoom($(this), vm.getDictionaryPhrase("BookRoom"), vm.getDictionaryPhrase("CalendarAdult"), vm.getDictionaryPhrase("CalendarChildren"));
		roomno = $('#rooms').children('.room').length;
		if(roomno >= 5) {
			$(this).parent().css('display', 'none');
		}
	});*/

    $('#' + vm.uid + ' #jenBGuests .rooms').on('click', '.jenGuestCol4', function() {
      var roomno = $('#' + vm.uid + ' #brooms').children('.room').length;
      if (roomno <= 5) {
        $(this)
          .parent()
          .parent()
          .parent()
          .find('.addRoom')
          .parent()
          .css('display', 'flex');
      }
      removeBRoom($(this), vm.getDictionaryPhrase("BookRoom"), vm.getDictionaryPhrase("CalendarAdult"), vm.getDictionaryPhrase("CalendarChildren"));
    });

    /*$('#jenGuests .rooms').on('click', '.jenGuestCol4', function () {
		roomno = $('#rooms').children('.room').length;
		if(roomno <= 5) {
			$(this).parent().parent().parent().find('.addRoom').parent().css('display', 'flex');
		}
		removeRoom($(this), vm.getDictionaryPhrase("BookRoom"), vm.getDictionaryPhrase("CalendarAdult"), vm.getDictionaryPhrase("CalendarChildren"));
	});

	$('#jenRGuestSelect').on('click', '.more', function () {
		ctr = $(this).siblings('.counter').html()*1 + 1;
		guest = $(this).siblings('.counter').attr('data');
		updateRGuests(guest, $(this), ctr);		
	});

	$('#jenRGuestSelect').on('click', '.less', function () {
		ctr = $(this).siblings('.counter').html()*1 - 1;
		guest = $(this).siblings('.counter').attr('data');
		updateRGuests(guest, $(this), ctr);		
	});*/

	// Expired Sticky Booking 
	/*
    $(window).scroll(function() {
      var distanceFromTop = $(this).scrollTop();
      if (distanceFromTop >= $('.topBack').height() - 200) {
        $('#jenBookingDetails').addClass('jenSticky');
        $('.maxWidth').css('padding-top', '100px');
        //$('.jenBClose').css('display', 'block');
      } else {
        $('#jenBookingDetails').removeClass('jenSticky');
        $('.maxWidth').css('<p></p>adding-top', '0px');
        //$('.jenBClose').css('display', 'none');
      }
    });*/
  if ($(`#${vm.uid} #jenBookingDetails`).length) {
    	var BookingFromTop = $('#' + vm.uid + ' #jenBookingDetails').offset().top;
  }
  
	
	/*
	$(window).scroll(function(e) {
      var distanceFromTop = $(this).scrollTop();
	  if($(window).width() >= 768){
		
		  if (distanceFromTop >= $('#jenBookingDetails').height() + BookingFromTop) {
			$('#jenBookingDetails').addClass('jenSticky');
			$('.maxWidth').css('padding-top', '100px');
		  } else {
			$('#jenBookingDetails').removeClass('jenSticky');
			$('.maxWidth').css('padding-top', '0px');

		  }
		  
		  if (this.oldScroll2 > this.scrollY) {
			//console.log('scroll up');
			if ($('#jenBookingDetails').hasClass('jenSticky')){
				$('#jenBookingDetails').hide();
			}else{
				$('#jenBookingDetails').show();
			}
		  } else {
			if ($('#jenBookingDetails').hasClass('jenSticky')){
				$('#jenBookingDetails').show();
			}else{
				$('#jenBookingDetails').show();
			}
		  }

		  this.oldScroll2 = this.scrollY;
	  }
    });
	*/
	

    $(window).resize(function() {
	if($(window).width() >= 1024){
		//BooingFromTop = $('#jenBookingDetails').offset().top;
	}
      if ($(document).width() <= breakPoint) {
        $('#' + vm.uid + ' #jenBookingDetails').hide();
        $('#' + vm.uid + ' .jenBOpen').show();
      } else {
        $('#' + vm.uid + ' .jenBOpen').hide();
        $('#' + vm.uid + ' #jenBookingDetails').show();
      }
    });

    /*function updateRGuests(guest, obj, ctr) {
	if(guest == "adult"){
		if(ctr == minRAdult) {
			obj.parent().find('.less').addClass('disabled');
			obj.siblings('.counter').html(ctr);
		}
		if(ctr > minRAdult && ctr <= maxRAdult) {
			obj.parent().find('.more').removeClass('disabled');
			obj.parent().find('.less').removeClass('disabled');
			obj.siblings('.counter').html(ctr);
		}
		if (ctr == maxRAdult) {
			obj.parent().find('.more').addClass('disabled');
		} 
	} else if (guest == "child") {
		if(ctr == minRChild) {
			obj.parent().find('.less').addClass('disabled');
			obj.siblings('.counter').html(ctr);
		}
		if(ctr > minRChild && ctr <= maxRChild) {
			obj.parent().find('.more').removeClass('disabled');
			obj.parent().find('.less').removeClass('disabled');
			obj.siblings('.counter').html(ctr);
		}
		if (ctr == maxRChild) {
			obj.parent().find('.more').addClass('disabled');
		} 
	}
	adultR = $('#jenRGuestSelect').find('.adult').find('.counter').html()*1;
	childR = $('#jenRGuestSelect').find('.child').find('.counter').html()*1;
	statusR = adultR + ' Adult';
	//if(adultR > 1) {
	//	statusR += 's';
	//}
	statusR += ', ' + childR + ' Child';
	if(childR == 0 || childR > 1) {
		statusR += 'ren';
	}
	$('#jenRGuestDetails').html(statusR);
}*/
    function getTotalGuests(event) {
      $('.jenSubmit')
        .parent()
        .parent()
        .find('.jenGuestSelect .room').length;
      if (
        $(event.target)
          .parent()
          .parent()
          .find('.jenGuestSelect .room').length > 0
      ) {
        $(event.target)
          .parent()
          .parent()
          .find('.jenGuestSelect .room')
          .each(function() {
            var parent = $(this);
            var sibling = parent.siblings('.counter');
            var current = sibling.attr('data');
            var adult = parent.find('.adult');
            var child = parent.find('.child');
            var totaladult = adult.find('.counter').html() * 1;
            var totalchild = child.find('.counter').html() * 1;
            if (current == 'adult') totaladult = totaladult + ctr;
            else if (current == 'child') totalchild = totalchild + ctr;

            var dStart = new Date($('#' + vm.uid + '_jenBDate #startBDate').val());
            var dEnd = new Date($('#' + vm.uid + '_jenBDate #endBDate').val());
            //console.log(dStart.getDay());
            //console.log(dEnd.getDay());
            if (totaladult + totalchild > 2) {
              //console.log('family');
              return;
            } else {
              if (
                dStart.getDay() > 0 &&
                dStart.getDay() < 6 &&
                (dEnd.getDay() > 0 || dEnd.getDay() < 6)
              ) {
                if (totaladult + totalchild == 1) {
                  //console.log('business');
                  return;
                }
                if (totaladult + totalchild > 1) {
                  //console.log('leisure');
                  return;
                }
              }
            }
            //console.log('other');
          });
        /*
		var parent = guest.closest('.room');
		var sibling = guest.siblings('.counter');
		var current = sibling.attr('data');
		var adult = parent.find('.adult');
		var child = parent.find('.child');
		var totaladult = (adult.find('.counter').html()*1);
		var totalchild = (child.find('.counter').html()*1);
		if(current == "adult")
			totaladult = totaladult + ctr;
		else if(current == "child")
			totalchild = totalchild + ctr;

		*/
      }
    }

	function resetGuests(uid)
	{
		$('#' + uid + ' .jenGuestSelect .room')
		.each(function() {
			var process = false;
			var parent = $(this);
			var sibling = parent.siblings('.counter');
			var current = sibling.attr('data');
			var adult = parent.find('.adult');
			var child = parent.find('.child');
			adult.find('.counter').html('1');
			child.find('.counter').html('0');
			var totaladult = adult.find('.counter').html() * 1;
			var totalchild = child.find('.counter').html() * 1;
			
			var propertymaxoccupy = $(this).parents('#jenBookingDetails').find('#jenBHotels .jenSelect').find('.active').attr('data-propertymaxoccupy');
			
			if (propertymaxoccupy == undefined)
				propertymaxoccupy = 3;
				
		  if (totaladult + totalchild <= (propertymaxoccupy-0) && totaladult + totalchild >= 1) {
			if (totaladult >= 1 && totaladult <= (propertymaxoccupy-0) && (totalchild >= 0 && totalchild < ((propertymaxoccupy-0)-1))) {
			  if (totaladult == 1) {
				adult.find('.more').removeClass('disabled');
				adult.find('.less').addClass('disabled');
				process = true;
			  } else if (totaladult == (propertymaxoccupy-0)) {
				adult.find('.more').addClass('disabled');
				adult.find('.less').removeClass('disabled');
				process = true;
			  } else {
				adult.find('.more').removeClass('disabled');
				adult.find('.less').removeClass('disabled');
				process = true;
			  }
			}
			if (totalchild >= 0 && totalchild <= ((propertymaxoccupy-0)-1) && (totaladult > 0 && totaladult < (propertymaxoccupy-0))) {
			  if (totalchild == 0) {
				child.find('.more').removeClass('disabled');
				child.find('.less').addClass('disabled');
				process = true;
			  } else if (totalchild == ((propertymaxoccupy-0)-1)) {
				child.find('.more').addClass('disabled');
				child.find('.less').removeClass('disabled');
				process = true;
			  } else {
				child.find('.more').removeClass('disabled');
				child.find('.less').removeClass('disabled');
				process = true;
			  }
			}
		  }
		  if (totaladult + totalchild >= ((propertymaxoccupy-0))) {
			adult.find('.more').addClass('disabled');
			child.find('.more').addClass('disabled');
		  }
		});
	}
	
    function updateGuests(guest, ctr) {

      var process = false;
      /* I have duplicated this code below for pulling the number of guests */
      var parent = guest.closest('.room');
      var sibling = guest.siblings('.counter');
      var current = sibling.attr('data');
      var adult = parent.find('.adult');
      var child = parent.find('.child');
      var totaladult = adult.find('.counter').html() * 1;
      var totalchild = child.find('.counter').html() * 1;
      if (current == 'adult') totaladult = totaladult + ctr;
      else if (current == 'child') totalchild = totalchild + ctr;
      /* END: I have duplicated this code below for pulling the number of guests */
		var propertymaxoccupy = guest.parents('#jenBookingDetails').find('#jenBHotels .jenSelect').find('.active').attr('data-propertymaxoccupy');
		
		if (propertymaxoccupy == undefined)
			propertymaxoccupy = 3;
			
      if (totaladult + totalchild <= (propertymaxoccupy-0) && totaladult + totalchild >= 1) {
        if (totaladult >= 1 && totaladult <= (propertymaxoccupy-0) && (totalchild >= 0 && totalchild < ((propertymaxoccupy-0)-1))) {
          if (totaladult == 1) {
            adult.find('.more').removeClass('disabled');
            adult.find('.less').addClass('disabled');
            process = true;
          } else if (totaladult == (propertymaxoccupy-0)) {
            adult.find('.more').addClass('disabled');
            adult.find('.less').removeClass('disabled');
            process = true;
          } else {
            adult.find('.more').removeClass('disabled');
            adult.find('.less').removeClass('disabled');
            process = true;
          }
        }
        if (totalchild >= 0 && totalchild <= ((propertymaxoccupy-0)-1) && (totaladult > 0 && totaladult < (propertymaxoccupy-0))) {
          if (totalchild == 0) {
            child.find('.more').removeClass('disabled');
            child.find('.less').addClass('disabled');
            process = true;
          } else if (totalchild == ((propertymaxoccupy-0)-1)) {
            child.find('.more').addClass('disabled');
            child.find('.less').removeClass('disabled');
            process = true;
          } else {
            child.find('.more').removeClass('disabled');
            child.find('.less').removeClass('disabled');
            process = true;
          }
        }
      }
      if (totaladult + totalchild >= ((propertymaxoccupy-0))) {
        adult.find('.more').addClass('disabled');
        child.find('.more').addClass('disabled');
      }
      return process;
    }

    function addRoom(obj, bookroomtext, bookadultstext, bookchildrentext) {
      obj = obj
        .parent()
        .parent()
        .find('.rooms');
      var roomno = obj.children('.room').length;
      if (roomno < 5) {
        var room = '                <div class="jenGuestRow room" room="' + (roomno + 1) + '">';
        room += '                 	<div class="jenGuestCol1">' + bookroomtext + ' ' + (roomno + 1) + '</div>';
        room += '                  <div class="jenGuestCol2 adult">';
        room += '                    <span class="less disabled">-</span>';
        room += '                    <span class="counter" data="adult">1</span>';
        room += '                    <span class="more">+</span>';
        room += '                  </div>';
        room += '                  <div class="jenGuestCol3 child">';
        room += '                    <span class="less disabled">-</span>';
        room += '                    <span class="counter" data="child">0</span>';
        room += '                    <span class="more">+</span>';
        room += '                  </div>';
        room += '                 	<div class="jenGuestCol4">X</div>';
        room += '                </div>';
        obj.append(room);
      }
      updateBRoom(obj, bookroomtext, bookadultstext, bookchildrentext);
    }

    function removeBRoom(room, bookroomtext, bookadultstext, bookchildrentext) {
      var roomParent = room.closest('.room').parent();
      room.closest('.room').remove();
      updateBRoom(roomParent, bookroomtext, bookadultstext, bookchildrentext);
    }

    function updateBRoom(obj, bookroomtext, bookadultstext, bookchildrentext) {
      var rooms = obj.children('.room');
      var roomno = obj.children('.room').length;
      if (roomno > 1) {
        $('#jenBookingDetails .jenGuestCol4').show();
      } else {
        $('#jenBookingDetails .jenGuestCol4').hide();
      }
      var ctr = 1;
      rooms.each(function() {
        $(this).attr('room', ctr);
        $(this)
          .find('.jenGuestCol1')
          .html(bookroomtext + ' ' + ctr);
        ctr++;
      });
      updatePolicy(obj, bookroomtext, bookadultstext, bookchildrentext);
    }

    function updatePolicy(obj, bookroomtext, bookadultstext, bookchildrentext) {
	
      var total_adult = 0;
      var total_child = 0;
      var rooms = obj.children('.room');
      rooms.each(function() {
        total_adult +=
          $(this)
            .children('.adult')
            .children('.counter')
            .html() * 1;
        total_child +=
          $(this)
            .children('.child')
            .children('.counter')
            .html() * 1;
      });
      if (total_child >= 1) {
        obj
          .parent()
          .find('.childPolicy')
          .css('display', 'block');
        if (total_adult >= 2) {
          obj
            .parent()
            .find('.groupPolicy')
            .css('display', 'block');
        } else {
          obj
            .parent()
            .find('.groupPolicy')
            .hide();
        }
      } else {
        obj
          .parent()
          .find('.childPolicy')
          .hide();
        obj
          .parent()
          .find('.groupPolicy')
          .hide();
      }
      var roomno = obj.children('.room').length;
      var status = roomno + ' ' + bookroomtext;
      if (roomno > 1) 
	  {
		if (bookroomtext.indexOf('room') != -1 || bookroomtext.indexOf('room') != -1)
			status += 's';
	  }
      status += ', ' + total_adult + ' ' + bookadultstext;
      //if (total_adult > 1) status += 's';
	  if (total_adult > 1) 
	  {
		//if ((bookadultstext.indexOf('Adult') != -1 || bookadultstext.indexOf('adult') != -1) &&
		//bookadultstext.indexOf('(s)') == -1)
		//	status += 's';
	  }
      status += ', ' + total_child + ' ' + bookchildrentext;
      if (total_child > 1)
	  {
		if (bookchildrentext.indexOf('child') != -1 || bookchildrentext.indexOf('child') != -1)
			status += 'ren';
	  }
      obj
        .parent()
        .parent()
        .parent()
        .find('.jenGuestDetails')
        .html(status);
    }
  },
  methods: {
	getDictionaryPhrase: function(key)
	{
		return getDictionaryPhrase(key, this);
	},
    isproperty: function(name) {
      if (this.fields != null && this.fields[name] != undefined) {
        return this.fields[name];
      } else {
        if (
          this.fields &&
          this.fields.data &&
          this.fields.data.datasource &&
          this.fields.data.datasource.fields
        ) {
          for (var i = 0; i < this.fields.data.datasource.fields.length; i++) {
            if (
              this.fields.data.datasource.fields[i].targetItems &&
              this.fields.data.datasource.fields[i].targetItems.length > 0
            ) {
              if (this.fields.data.datasource.fields[i].targetItems[0].textField)
                return this.fields.data.datasource.fields[i].targetItems[0].textField.value;
              else if (this.fields.data.datasource.fields[i].targetItems[0].title)
                return this.fields.data.datasource.fields[i].targetItems[0].textField.title;
              else return '';
            } else {
              return this.fields.data.datasource.fields[i].value;
            }
          }
        }
      }
      return null;
    },
  },
};
</script>
