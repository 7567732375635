<template>
  <div :id="this.uid" class="HJImageDemo">
	<div :class="'desktop ' + ismobiletext">
	<template v-if="graphqlresults['Image']">
		<img :src="graphqlresults['Image']['src']" alt="" />
	</template>
	</div>
	<div class="mobile">
	<template v-if="graphqlresults['Mobileimage']">
		<img :src="graphqlresults['Mobileimage']['src']" alt="" />
	</template>
	</div>
  </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import { Image } from '@sitecore-jss/sitecore-jss-vue';
import axios from 'axios';
import { dataFetcher } from '../dataFetcher.js';
import { layoutFetcher, layoutFetchAction } from '../layoutFetcher.js';
import {
  getContentByType,
  getObject,
  graphqlFetcherTestSSR,
  graphqlFetcher,
} from '../graphqlFetcher.js';
//import $ from 'jquery';
import ApolloClient from 'apollo-boost';
import gql from 'graphql-tag';
import { uuid } from 'vue-uuid';
import {
  enableExperimentalFragmentVariables,
  disableExperimentalFragmentVariables,
} from 'graphql-tag';
import Vue from 'vue';
import VueMeta from 'vue-meta';
import { trackingApi } from '@sitecore-jss/sitecore-jss-tracking';
import config from '../temp/config';

export default {
  name: 'HJImageDemo',
  components: {
    ScText: Text,
  },
  props: {
    fields: {
      type: Object,
      default: function() {},
    },
    currentcontext: {
      type: Object,
      default: null,
    },
    uid: {
      type: String,
      default: function() {
        return uuid.v1();
      },
    },
  },
  data() {
    return {
      loadingQueriesCount: 0,
      error: null,
      ismobiletext: '',
      graphqlresults: {},
    };
  },
  computed: {
    datasource: function() {
      return this.fields.data && this.fields.data.datasource;
    },
    contextItem: function() {
      return this.fields.data && this.fields.data.contextItem;
    },
  },
  mounted: function() {
    // ...

    let vm = this;
    let current_item = vm.$jss.sitecoreContext();

    var _routedata = vm.$jss.routeData();

    setTimeout(function() {
      var from = document.referrer;
    }, 500);

/*
    const trackingApiOptions = {
      host: config.sitecoreApiHost,
      querystringParams: {
        sc_apikey: config.sitecoreApiKey,
      },
      fetcher: dataFetcher,
    };
*/
    //trackingApi
    //  .trackEvent([{ pageId: '{36715B3C-7A6C-5576-9343-F6B504173D74}', url: '/hotels/beijing'}], trackingApiOptions)
    //  .then(() => console.log('Page event pushed'))
    //  .catch((error) => console.error(error));

    vm.graphqlresults = getObject(vm.datasource);
    //console.log(vm.graphqlresults);
    //console.log(vm.datasource);
    if (vm.graphqlresults['ismobile']) {
      if (vm.graphqlresults['ismobile'].value == '1') vm.ismobiletext = 'ismobile';
    }
  },
  updated() {},
  methods: {
    isproperty: function(name) {
      if (this.fields != null && this.fields[name] != undefined) {
        return this.fields[name];
      } else {
        if (this.fields.data.datasource && this.fields.data.datasource.fields) {
          for (var i = 0; i < this.fields.data.datasource.fields.length; i++) {
            if (this.fields.data.datasource.fields[i].name == name) {
              if (
                this.fields.data.datasource.fields[i].targetItems &&
                this.fields.data.datasource.fields[i].targetItems.length > 0
              ) {
                if (this.fields.data.datasource.fields[i].targetItems[0].TextField)
                  return this.fields.data.datasource.fields[i].targetItems[0].TextField.value;
                else if (this.fields.data.datasource.fields[i].targetItems[0].Title)
                  return this.fields.data.datasource.fields[i].targetItems[0].TextField.Title;
                else return '';
              } else {
                return this.fields.data.datasource.fields[i].value;
              }
            }
          }
        }
      }
      return null;
    },
  },
};
</script>
