<template>
	<div :id="this.uid" class="HJDetail">
		<template v-if="currentcontext">
				<template v-if="currentcontext.templatename == 'hjevent'">
					<div class="section events section-left">
						<div class="title">Address:</div>
						<div class="text">{{currentcontext.address.value}}</div>
						<div class="title">Email:</div>
						<div class="text">{{currentcontext.email.value}}</div>
						<div class="title">Phone:</div>
						<div class="text">{{currentcontext.phone.value}}</div>
					</div>
				</template>
				<template v-else-if="currentcontext.templatename == 'hjdealstay' || 
					currentcontext.templatename == 'hjdealeat'">
					<div class="section">
						<div class="text">{{currentcontext.description.value}}</div>
					</div>
				</template>				
				<template v-else-if="currentcontext.templatename == 'hjhost' || currentcontext.templatename == 'hjhostmeet' || currentcontext.templatename == 'hjhostcelebrate'">
								
								<div class="row no-gutters justify-content-center stay-content">
                                    <div class="col-12">
                                        <div class="container-fluid propertybooking-container">
                                            <div class="container-fluid pr-0 pl-0">
                                                <div class="row no-gutters justify-content-center">
                                                    <div class="col-12 col-xl-6">
                                                        <div class="container-fluid pl-0 pr-0">
                                                            <div class="row no-gutters mb-4 amenities-details mt-0">
                                                                <div class="col-11 col-md-12 mt-4 mt-md-0">
                                                                    <p id="roomdesc" class="text-left" v-html="currentcontext.description.value">Business conferences. Corporate off-sites. Inspiring meetings. Make important occasions the best that they can be with our versatile function spaces and full-service events planning. Let us create a tailored event just for you.</p>
                                                                    <!--<a href="" class="host-custom-btn">floor plan</a>-->
                                                                </div>
                                                            </div>
                                                            <!-- march -->
                                                            <div class="row no-gutters mb-4 justify-content-center mobile-hidden">
                                                                <div class="col-11 col-md-12 text-left">
                                                                   <!--<a href="#" class="btn btn-primary rfp-form-popup" :data-moduleid="moduleid">{{getDictionaryPhrase('RequestAProposal')}}</a>-->
                                                                   <!-- <a href="#" class="btn btn-primary rfp-trigger-cta" :data-moduleid="moduleid" >{{getDictionaryPhrase('RequestAProposal')}}</a> -->
																   <a href="#" class="btn btn-primary rfp-book-link" :data-moduleid="moduleid">{{getDictionaryPhrase('RequestAProposal')}}</a>
                                                                </div>
                                                            </div>
                                                            <!-- march -->
                                                            <div class="row no-gutters mb-0 mb-md-4 justify-content-center mobile-hidden">
                                                                <div class="col-11 col-md-12 text-left">
                                                                </div>
                                                            </div>
                                                            <!-- march -->
                                                            <a href="" class="view-less sub-view-less mobile-hidden">{{getDictionaryPhrase('ViewLess')}} <img :src="arrowUpIcon" class="vd-arrow-up"> </a>
                                                        </div>
                                                    </div>
                                                    <div class="col-11 col-xl-6">
                                                    </div>
                                                </div>
                                                <!-- march -->
                                                <div class="row no-gutters desktop-hidden">
                                                    <div class="col-12">
														<!-- <a href="#" class="btn btn-primary rfp-form-popup" :data-moduleid="moduleid">{{getDictionaryPhrase('request-a-proposal')}}</a> -->
                                                        <a href="#" class="btn btn-primary rfp-book-link" :data-moduleid="moduleid">{{getDictionaryPhrase('request-a-proposal')}}</a>
                                                        <a href="" class="view-less sub-view-less">{{getDictionaryPhrase('ViewLess')}} <img :src="arrowUpIcon" class="vd-arrow-up"> </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
								
				</template>
				<template v-else-if="currentcontext.templatename == 'hjrestaurant'">
							<div class="row no-gutters justify-content-center stay-content">
								<div class="col-12">
									<div class="container-fluid propertybooking-container pb-4 pb-md-5">
										<div class="container-fluid pr-0 pl-0">
											<div class="row no-gutters justify-content-center">
												<div class="col-11 col-md-10 col-lg-9 col-xl-8">
													<div class="container-fluid pr-0 pl-0">
														<div class="row no-gutters justify-content-start">
															<div class="col-12 col-xl-6">
																<div class="container-fluid pl-0 pr-0">
																	<div class="row no-gutters mb-4 restaurant-details mt-auto mt-md-4">
																		<div class="col-12">																			
																			<div class="image-container">
																				<img :src="timeIcon" class="res-icons" />
																			</div>
																			<ol>
																				<li class="main-list">			
																						<ul v-html="currentcontext.openingtimetext.value"></ul>
																				</li>
																			</ol>
																			<div class="restaurant-desc" >
																			<p class="text-left" v-html="currentcontext.description.value">
																				</p>
																			<a v-if="currentcontext.mainmenu && currentcontext.mainmenu.value != ''" :href="currentcontext.mainmenu.value" class="res-menu" target="_blank">main menu</a>
																			<a v-if="currentcontext.drinkmenu && currentcontext.drinkmenu.value != ''" :href="currentcontext.drinkmenu.value" class="res-menu" target="_blank">drink menu</a>
																			</div>
																		</div>
																	</div>
																	<div class="row no-gutters mb-4 justify-content-start mobile-hidden">
																		<div class="col-12">
																		</div>
																	</div>
																</div>
															</div>
															<div class="col-2">&nbsp;</div>
															<template v-if="currentcontext.isbooking.value != ''">
															<div class="col-12 col-md-4 mb-4 mb-md-0">
																<h-j-calendar :calendartype = "'fields'" :bookingurl="currentcontext.bookingurl.value" :isbooking="currentcontext.isbooking.value" :iseat2eat="currentcontext.iseat2eat.value"/>
															</div>
															</template>
															<template v-if="currentcontext.bookingurl.value != '' && currentcontext.isbooking.value === ''">
																<div class="col-12 text-center mb-5 desktop-hidden">
																	<a :href="[currentcontext.bookingurl.value != ''? currentcontext.bookingurl.value : '#']" class="btn btn-primary desktop-hidden detail-hidden reserve-button" target="_blank">{{getDictionaryPhrase('Reserve')}}</a>
																</div>
															</template>
															<a href="" id="view-less-eat" class="view-less">{{getDictionaryPhrase('ViewLess')}} <img :src="arrowUpIcon" class="vd-arrow-up" /> </a>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
       
						</template>
						
						
							<template v-else-if="currentcontext.templatename == 'hjhotel-template'">
								<div class="section hotels">
									<div class="title">Address:</div>
									<div class="text">{{currentcontext.address.value}}</div>
									<div class="title">Check-in:</div>
									<div class="text">{{currentcontext.checkin.value}}</div>
									<div class="title">Check-out:</div>
									<div class="text">{{currentcontext.checkout.value}}</div>
									<div class="title">Email:</div>
									<div class="text">{{currentcontext.email.value}}</div>
									<div class="title">Phone:</div>
									<div class="text">{{currentcontext.phone.value}}</div>
								</div>
							</template>
							
							<!-- Room Template -->
							<template v-else-if="currentcontext.templatename == 'hjroom-template'">								
								
								<div class="row no-gutters justify-content-center stay-content">
									<div class="col-12">
										<div class="container-fluid propertybooking-container">
											<div class="container-fluid pr-0 pl-0">
												<div class="row no-gutters mt-1 mt-md-5 mb-1 mb-md-5">
													<div class="col-4 line"></div>
													<div class="col-1">&nbsp;</div>
													<div class="col-7 line"></div>
												</div>
												<div class="row no-gutters justify-content-center">
													<div class="col-12 col-md-12 col-lg-9 col-xl-8">
														<div class="container-fluid pr-0 pl-0">
															<div class="row no-gutters justify-content-center stay-booking-details">
																<div class="col-11 col-xl-6">
																	<div class="container-fluid pl-0 pr-0 pb-4">
																		<div class="row no-gutters mb-4">
																			<div class="col-12">
																				<p class="text-left">
																					{{currentcontext.description.value}}
																				</p>
																			</div>
																		</div>
																		<template v-if="currentcontext.features && currentcontext.features['children'] && currentcontext.features['children'].length > 0">
																		<div class="row no-gutters mb-4 justify-content-start">
																			<div class="col-12">
																				<ol>
																					<li class="main-list mt-3">
																						<h5>{{getDictionaryPhrase('Feature')}}</h5>
																					</li>
																				</ol>
																			</div>
																		</div>
																		</template>
																		
																	<template v-if="currentcontext.features && currentcontext.features['children'] && currentcontext.features['children'].length > 0">
																		<template v-for="(feature, index) in currentcontext.features['children']">
																					<div class="row no-gutters mb-4 justify-content-start">
																						<div class="col-12">
																							<template v-if="feature.image && feature.image != ''">
																								<div class="image-container">
																									<img :src="feature.image" class="slider-icons" />
																								</div>
																							</template>
																							<template v-else>
																								<div class="image-container">
																									<img src="data:image/png;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" class="slider-icons" />
																								</div>
																							</template>
																							<!--{{feature}}-->
																								<ol>
																									<li class="main-list">
																										<ul v-html="feature['description']"></ul>
																									</li>
																								</ol>
																						</div>
																					</div>
																		</template>
																	</template>
																		
																		
																		<div class="row no-gutters justify-content-center desktop-hidden">
																			<div class="col-12 text-center">
																				<template v-if="currentcontext.templatename == 'hjroom-template'">								
																					<button type="button" class="btn btn-primary check-booking" :data-roomtypecode="currentcontext['roomtypecode'].value" :data-roomcode="currentcontext['roomcode'].value" :data-bookingurl="currentcontext['bookingurl']">{{getDictionaryPhrase('Book')}}</button>
																				</template>
																				<template v-else>
																					<button type="button" class="btn btn-primary check-booking">{{getDictionaryPhrase('Book')}}</button>
																				</template>
																			</div>
																		</div>
																	</div>
																</div>
																<div class="col-2 mobile-hidden custom-col-2">&nbsp;</div>
																<div class="col-11 col-md-4 mobile-hidden custom-col-4">
																	<h-j-calendar :calendartype = "'block'" />
																</div>
																<!-- view less march -->
																<a href="" id="view-less-stay" class="view-less">{{getDictionaryPhrase('ViewLess')}} <img :src="arrowUpIcon" class="vd-arrow-up" /> </a>
															</div>
														</div>
													</div>
												</div>
												<div class="row no-gutters mt-5 mb-5">
													<div class="col-7 line"></div>
													<div class="col-1">&nbsp;</div>
													<div class="col-4 line"></div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</template>
							<!-- CoWorking Space -->
							<template v-else-if="currentcontext.templatename == 'hjspace'">
								<div class="section space">
									<div class="description">
										{{currentcontext.description.value}}
									</div>
								</div>
							</template>
							
							<!-- Workout -->
							<template v-else-if="currentcontext.templatename == 'hjworkout'">
								 <div class="row no-gutters justify-content-center stay-content">
                <div class="col-12">
                    <div class="container-fluid propertybooking-container pb-5">
                        <div class="container-fluid pr-0 pl-0">
                            <div class="row no-gutters justify-content-center">
                                <div class="col-11 col-md-10 col-lg-9 col-xl-8">
                                    <div class="container-fluid pr-0 pl-0">
                                        <div class="row no-gutters justify-content-center mb-5 mb-md-0">
                                            <div class="col-12 col-xl-6">
                                                <div class="container-fluid pl-0 pr-0">
                                                    <div class="row no-gutters mb-4 amenities-details mt-0 mt-md-4">
                                                        <div class="col-12">
                                                            <div class="image-container">
                                                                <img :src="timeIcon" class="res-icons" />
                                                            </div>
                                                            <ol>
                                                                <li class="main-list">
																	<!--<template v-for="(_day, index) in currentcontext.openingtime['children']">-->
																		<ul v-html="currentcontext.openingtimetext.value"></ul>
																	<!--</template>-->
                                                                </li>
                                                            </ol>
                                                            <p class="text-left" v-html="currentcontext.description.value"></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-xl-6">
                                                <div class="container-fluid pl-0 pr-0 amenities-detailed-list">
                                                </div>
                                            </div>
                                            <a href="" id="view-less-fit" class="view-less">{{getDictionaryPhrase('ViewLess')}} <img :src="arrowUpIcon" class="vd-arrow-up" /> </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
							</template>
							
							<!--<template v-else-if="currentcontext.templatename == 'hjhostmeet'">

							</template>-->
							
							<!-- Meet -->

				
				
				<!-- Celebrate -->
		</template>
	</div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import { Image } from '@sitecore-jss/sitecore-jss-vue';
import axios from 'axios';
import { dataFetcher } from '../dataFetcher.js';
import { layoutFetcher, layoutFetchAction } from '../layoutFetcher.js';
import { getDictionaryPhrase,graphqlRemoveReserved, graphqlToObject, getObjectById, graphqlFetcher } from '../graphqlFetcher.js';
//import $ from 'jquery';
import ApolloClient from 'apollo-boost';
import gql from 'graphql-tag';
import { uuid } from 'vue-uuid';
import {
  enableExperimentalFragmentVariables,
  disableExperimentalFragmentVariables,
} from 'graphql-tag';
import HJCalendar from './HJCalendar';
import arrowupicon from '../assets/img/arrow_up.png';
import timeicon from '../assets/img/time.png';
import Vue from 'vue';
import config from '../temp/config';
import { bookhotel, getSortedContent, addAdhocImages } from '../hjutil.js';

/*
const client = new ApolloClient({
  uri: config.sitecoreApiHost + '/api/' + config.jssAppName + '?sc_apikey=' + config.sitecoreSCApiKey,
});
*/

export default {
  name: 'HJDetail',
  components: {
    ScText: Text,
	HJCalendar
  },
  props: {
    fields: {
      type: Object,
      default: function(){},
    },
    rendering: {
      type: Object,
    },
    uid: {
      type: String,
      default: () => uuid.v1(),
    },
    currentcontext: {
      type: Object,
      default: null,
    }, 
	moduleid: {
      type: String,
      default: null,
    },
	hoteldetail: {
	  type: Object,
      default: {},
	}
  },
  data: function() {
    return {
	  arrowUpIcon: arrowupicon,
	  timeIcon: timeicon,
      error: null,
    };
  },
//  computed: { 
//  },
  mounted: function() {
    let vm = this;
	//console.log(vm);
	//console.log(vm.datasource);
		var s = '';
		if (vm.currentcontext != null)
		{				
			s += '<div class="section">';
			if (vm.currentcontext.features && vm.currentcontext.features['children'] && vm.currentcontext.features['children'].length > 0)
			{	//console.log(vm.currentcontext.features['children']);
				for(var i = 0; i < vm.currentcontext.features['children'].length; i++)
				{
					if (vm.currentcontext.features['children'][i] != '')
					var feature = vm.currentcontext.features['children'][i];
					if (feature.title)
					{
						s += '<div class="title">' + feature.title + '</div>';
						s += '<div class="text">' + feature.moredescription + '</div>';
					}
				}
			}
			s += '</div>';
			s += '<div class="section">';
			if (vm.currentcontext.openingtime && vm.currentcontext.openingtime['children'] && vm.currentcontext.openingtime['children'].length > 0)
			{
				for(var i = 0; i < vm.currentcontext.openingtime['children'].length; i++)
				{
					{
					var _day = vm.currentcontext.openingtime['children'][i];
					if (_day.day)
						s += '<div class="title">' + _day.day.value + '</div>';
						s += '<div class="text">' + _day.open.value + '</div>';
					}
				}
			}
			s += '</div>';
		}		
		//$('#' + vm.uid + '_detail-wrapper').html(s);
		//console.log(vm.currentcontext.templatename);
	if (vm.currentcontext.templatename == 'hjrestaurant'){
		// console.log(vm.currentcontext);
	}
    $(document).ready(function() {
      setTimeout(function() {
      });
		$('#' + vm.uid).on('click', '.check-booking', function(e) {
		//$('.check-booking').on('click', function(e) {
			var language = 'en'; 
			if ( vm.$jss.routeData().itemLanguage == 'zh-CN' || vm.$jss.routeData().itemLanguage == 'zh'){
				language = 'cn'; 
			}else{
				language = vm.$jss.routeData().itemLanguage.substring(vm.$jss.routeData().itemLanguage.length-2).toLowerCase()			
			} 
			var key = vm.$jss.hjconfig().encryption_key;
			var iv = vm.$jss.hjconfig().encryption_iv;
			var propertycode = "";
			var propertycityalias = "";
			var propertyhotelalias = "";
			if ($(e.target).attr('data-bookingurl'))
			{
				propertycode = $(e.target).attr('data-bookingurl').split('|')[2];
				propertycityalias = $(e.target).attr('data-bookingurl').split('|')[0];
				propertyhotelalias = $(e.target).attr('data-bookingurl').split('|')[1];
			}
			var book_url = vm.$jss.hjconfig().book_url;
			if (propertycode != '' && propertycityalias != '' && propertyhotelalias != '')
			{
				bookhotel(key, iv, book_url, propertycode, propertycityalias, propertyhotelalias, null, null, null, null, 'None', '', $(e.target).attr('data-roomcode'), '', language);
			}
		});
		$('#' + vm.uid).on('click', '.rfp-book-link', function(e) {
			e.preventDefault();
			const hoteldetail = vm.hoteldetail || {};
			const hotelCode = hoteldetail.hotelcode && hoteldetail.hotelcode.value || '';
			const hotelName = hoteldetail.fullname && hoteldetail.fullname.value || '';
			var language = 'en'; 
			if ( vm.$jss.routeData().itemLanguage == 'zh-CN' || vm.$jss.routeData().itemLanguage == 'zh'){
				language = 'cn'; 
			}else{
				language = vm.$jss.routeData().itemLanguage.substring(vm.$jss.routeData().itemLanguage.length-2).toLowerCase()			
			} 
			if ($(this).attr('href') == '#'){
				window.open(vm.$jss.hjconfig().book_url + language + '/corporate/meetings-events/find-a-venue/?hotelCode=' + hotelCode + '&hotel=' + hotelName);
			}else{
				window.open($(this).attr('href'));
			}
		});
    });
  },
  updated: function() {
	var vm = this;
    $(document).ready(function() {
		//console.log(vm);
      setTimeout(function() {
      });
		$('#' + vm.uid).on('click', '.check-booking', function(e) {
		//$('.check-booking').on('click', function(e) {
			//console.log('aaa');
			var key = vm.$jss.hjconfig().encryption_key;
			var iv = vm.$jss.hjconfig().encryption_iv;
			var propertycode = "";
			var propertycityalias = "";
			var propertyhotelalias = "";
			if ($(e.target).attr('data-bookingurl'))
			{
				propertycode = $(e.target).attr('data-bookingurl').split('|')[2];
				propertycityalias = $(e.target).attr('data-bookingurl').split('|')[0];
				propertyhotelalias = $(e.target).attr('data-bookingurl').split('|')[1];
			}
			//console.log($(e.target).attr('data-bookingurl'));
			//console.log(propertycode);
			//console.log(propertycityalias);
			//console.log(propertyhotelalias);
			var book_url = vm.$jss.hjconfig().book_url;
			if (propertycode != '' && propertycityalias != '' && propertyhotelalias != '')
			{
				bookhotel(key, iv, book_url, propertycode, propertycityalias, propertyhotelalias, null, null, null, null, 'None', '', $(e.target).attr('data-roomcode'), '');
			}
		});
    });
  },
  methods: {
	getDictionaryPhrase: function(key)
	{
		return getDictionaryPhrase(key, this);
	},
    isproperty: function(name) {
		if (this.fields != null && this.fields[name] != undefined)
		{
			return this.fields[name];
		}
		else
		{
			if (this.fields && this.fields.data && this.fields.data.datasource && this.fields.data.datasource.fields)
			{
				for (var i=0; i < this.fields.data.datasource.fields.length; i++)
				{
					if (this.fields.data.datasource.fields[i].name == name)
					{
						if (this.fields.data.datasource.fields[i].targetItems && 
							this.fields.data.datasource.fields[i].targetItems.length > 0) {
							if (this.fields.data.datasource.fields[i].targetItems[0].textField)
								return this.fields.data.datasource.fields[i].targetItems[0].textField.value;
							else if (this.fields.data.datasource.fields[i].targetItems[0].title)
								return this.fields.data.datasource.fields[i].targetItems[0].textField.title;
							else
								return "";
						}
						else
						{
							return this.fields.data.datasource.fields[i].value;
						}
					}
				}
			}
		}
		return null;
		var o = {};
		o.value = '';
		return o;
	},
  },
};
</script>
