<template>
  <div>
    <p>HJCTA Component</p>
    <sc-text :field="fields.heading" />
  </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import { axios } from 'axios';
//import $ from 'jquery';
import ApolloClient from 'apollo-boost';
import gql from 'graphql-tag';
import { uuid } from 'vue-uuid';
import { getSSR, graphqlRemoveReserved, graphqlFetcherTestSSR, graphqlFetcher } from '../graphqlFetcher.js';
import Vue from 'vue';
import config from '../temp/config';
import { serverBus } from '../event-bus.js';

/*
const client = new ApolloClient({
  uri:
    config.sitecoreApiHost + '/api/' + config.jssAppName + '?sc_apikey=' + config.sitecoreSCApiKey,
  defaultOptions: {
    fetchPolicy: 'no-cache',
  },
});
*/
export default {
  name: 'HJCTA',
  components: {
    ScText: Text,
  },
  props: {
    fields: {
      type: Object,
      default: function() {},
    },
  },
  data: function() {
    return {
      loadingQueriesCount: 0,
      error: null,
    };
  },
  computed: {
    datasource: function() {
      return this.fields.data && this.fields.data.datasource;
    },
    contextItem: function() {
      return this.fields.data && this.fields.data.contextItem;
    },
  },
  methods: {},
  mounted: function() {
    if (this.fields.data && this.fields.data.datasource) {
      graphqlRemoveReserved(this.fields.data.datasource);
    }
  },
  created: function() {},
  updated: function() {},
};
</script>
