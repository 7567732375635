import Vue from 'vue';
// A simple wrapper around the native
// `fetch()` function.
import quickFetch from './quick-fetch';

const ENDPOINTS = {
  // Getter and random ID is used for demonstration purposes only.
  get todo() {
    return `https://jsonplaceholder.typicode.com/todos/${Math.floor(
      Math.random() * Math.floor(6)
    ) || 1}`;
  },
};

async function withPolling(callback, interval) {
  const data = await callback();

  // If no polling interval is given we
  // return a regular object with the data.
  if (!interval) return { data };

  // Otherwise, we create a new `Vue.observable`
  // instance and refetch the data according to
  // the specified polling interval.
  const observableData = Vue.observable({ data });

  const poll = () => {
    setTimeout(async () => {
      observableData.data = { ...(await callback()) };
      poll();
    }, interval);
  };
  poll();

  return observableData;
}

export default function api({ endpoint, interval }) {
  return withPolling(() => quickFetch(ENDPOINTS[endpoint]), interval);
}
