<template>
	<div :id="this.uid" :class="'HJTabbedContentBlockList pl-0 pr-0 '  + isproperty('cssclass')" :data-propertyid="hoteldetail && hoteldetail['id'] ? hoteldetail['id'] :''" :data-propertycode="hoteldetail && hoteldetail['hotelcode'] && hoteldetail['hotelcode'].value ? hoteldetail['hotelcode'].value : ''" :data-propertycityalias="hoteldetail && hoteldetail['cityalias'] && hoteldetail['cityalias'].value ? hoteldetail['cityalias'].value : ''"  :data-propertyhotelalias="hoteldetail && hoteldetail['hotelalias'] && hoteldetail['hotelalias'].value ? hoteldetail['hotelalias'].value : ''">
		<template v-if="tabs && tabs.length > 0">
		<div :id="uid + '_tabs'" :class="'components tabs '">
		<template v-if="tabs.length > 2">
		 <div class="row no-gutters justify-content-center">
                <div class="col-12 col-md-12 col-lg-7 col-xl-5">
                    <div :id="uid + '-slider-default-thumbs'" class="swiper-container-thumb gallery-thumbs slider-default-thumbs">
                        <div  class="swiper-wrapper ">
						<template v-for="(tab, index) in tabs">			
							<template v-if="tab && tab.title && tab.type">
								<div :id="'galleryTab'+index" :data-toslide='index' data-series="serie1" :class="'tab-' + index + ' main-tabs swiper-slide galleryTab '+ [index == 0? 'active swiper-slide-thumb-active' : '']" :data-datatype="tab.type" :data-moduleid="uid" :data-maintab="index">
                                <div class="block-selection">
                                    <hr/>
                                    <h4>{{tab.title}}</h4>
                                </div>
                            </div>
							</template>
						</template>
                        </div>
                        <div class="swiper-button-next"></div>
                        <div class="swiper-button-prev"></div>
                    </div>
                </div>
            </div>
		</template>
		<template v-else>
		<div class="row no-gutters justify-content-center pt-0 mt-0 pb-0 pb-md-4">
		 <div class="col-12 mt-0 pt-md-5 ">
			<ul class="nav offers-nav justify-content-center">
				<template v-for="(tab, index) in tabs">			
					<template v-if="tab && tab.title && tab.type">
					<li class="nav-item">										
					<a :href="'#' + uid + '_tabs-' + index" :class="'tab-' + index + ' main-tabs galleryTab nav-link slider-menu-society '+ [index == 0? 'active' : '']" :data-datatype="tab.type" :data-moduleid="uid" :data-maintab="index">
					{{tab.title}}
					<input :id="'section-'+tab.title" :name="'section-'+tab.title" style="visibility:hidden;position: absolute;top:0;" />			
					<input type="hidden" class="deals-info" :value="tab.title" /></a>
					</li>
					</template><!--<template v-else>
					<li><a :href="'#' + uid + '_tabs-' + index" :class="'tab-' + index + ' main-tabs '+ [index == 0? 'active' : '']" :data-datatype="" :data-moduleid="uid" :data-maintab="index"></a></li>
					</template>-->
				</template>
			</ul>
			</div>
			</div>		
		</template>
		<!-- Carousel Layout -->
		<template v-if="isproperty('iscarousel') == '1'">
			<!-- New Carousel -->
			<template v-for="(graphqlresult, index) in graphqlresults">			
					<!--<div :id="uid + '_tabs-' + index" :class="[index > 0 ? 'hidden' : '', 'tab-contents']" :data-datatype="tabs[index].type" >-->
					<div :id="uid + '_tabs-' + index" :class="[index > 0 ? 'hidden' : '', 'tab-contents']" :data-datatype="tabs[index].type" >		
					 <div class="row no-gutters justify-content-center mobile-hidden">
						<div class="col-12 col-md-12 col-lg-9 col-xl-8">
							<div :id="'gallery_'+ index +'_'+uid" class="swiper-container-full gallery-top slider-default-top gallery-society active">
								<div class="swiper-wrapper">
									<template v-for="(item, itemindex) in graphqlresult">
									<div class="swiper-slide firstslide">
									<template v-if="item['children'] && item['children'].length > 0">
										<template v-for="(subitem, subindex) in item['children']">
											<template v-if="subitem['templatename'] == 'hjimage'">
												<template v-if="subitem['image'].src != ''">
													<div :class="'gallery-container lazyload ' + [subindex > 0?'hidden':'']" :data-bg="subitem['image']['src']"></div>
												</template>
											</template>
										</template>
									</template>
									<h4 class="gallery-title">
									{{item['title'].value}} <br> 
									<span>
										<template v-if="item['property'] && item['property']['children'] && item['property']['children'].length > 0 && item['property']['children'][0]['title']">
											{{ item['property']['children'][0]['title'].value }}
                                       </template></span>
									</h4>
									<div class="img-overlay2"></div>
									</div>
									</template>									
								</div>
								<div class="swiper-pagination swiper-pagination-first"></div>
								<div class="swiper-button-next"></div>
								<div class="swiper-button-prev"></div>
							</div>
							</div>
					</div>
					</div>
			</template>
			
			<!-- End: New Carousel -->
		</template>
		<!-- End: Carousel Layout --
		
		<!-- List Layout -->
		<template v-else>
			
			
			<!-- New List Layout -->
			<div class="container-fluid">
            <div class="row no-gutters justify-content-center">
               <div class="col-11 col-xl-10 col-xxl-9">
			  
                <div class="container-fluid pr-0 pl-0" >
				
					
					 <template v-for="(graphqlresult, index) in graphqlresults">
					<!-- List Items -->			
					<div :id="uid + '_tabs-' + index" :class="[index > 0 ? 'hidden' : '', ' tab-contents row no-gutters justify-content-center offer-containers']" :data-datatype="tabs[index].type">
						
					<div class="filter_wrapper">
						<template v-if="isproperty('haspropertyfilter') == '1'">
							<select class="filter dropdownFilter">
								<option value="">{{getDictionaryPhrase('All')}} <span style="color:#A2AAAD"></span></option>
								<template v-if="hotelnames && hotelnames['children']">
								<template v-for="(hotelname, index) in hotelnames['children']">
									<template v-if="!(hotelname['isdisabled'] && hotelname['isdisabled'].value && hotelname['isdisabled'].value == '1')">
										<option :value="hotelname['id']">{{hotelname["title"].value}}</option>
									</template>
								</template>
								</template>
							</select>
						</template>
					</div>

						
						<template v-for="(item, itemindex) in graphqlresult"> 
							<div class="col-12 col-md-5 ml-0 ml-md-3 item-block" :data-itemid="item['id']">
									<div class="container-fluid pr-0 pl-0">
										<div class="row no-gutters justify-content-center">
											<div class="col-12">
											<template v-if="item['children'] && item['children'].length > 0">
											<template v-for="(subitem, subindex) in item['children']">
												<template v-if="subitem['templatename'] == 'hjimage'">
													<template v-if="subitem['image'].src != ''">												
														<div :class="[subindex != 0?'hidden':''] + ' image-container lazyload'" :data-bg="subitem['image']['src']"></div>
													</template>
												</template>
											</template>
									</template>
												
											</div>
										</div>
										<div class="row no-gutters justify-content-center">
											<div class="col-12 col-xl-7 text-left">
												<template v-if="item['property'] && item['property']['children'] && item['property']['children'].length > 0 && item['property']['children'][0]['title']">
													<h5>{{ item['property']['children'][0]['title'].value }}</h5>
												</template>
												<!--<h5>Jen BEIJING</h5>-->
												<h4 v-html="item['title'].value"></h4>
												<p v-html="item['description'].value"></p>
												<a :href="item['cta'].value" class="view-details">{{getDictionaryPhrase('ViewDetails')}}<div class="vd-arrow-down-div"></div></a>
											</div>
											<div class="col-12 col-md-5 text-right mobile-hidden">
												<template v-if="item['price']">
													<template v-if="item['price'].value && item['price'].value > 0">
														<h3>{{getDictionaryPhrase('From')}}&nbsp;<span>{{updatePriceString(item["price"].value)}}&nbsp;<small>{{curcurrency}}</small></span> <br/> {{getDictionaryPhrase('PerNight')}}</h3>
													</template>
													<template v-else-if="item['price'] > 0">
														<h3>{{getDictionaryPhrase('From')}}&nbsp;<span>{{updatePriceString(item["price"])}}&nbsp;<small>{{curcurrency}}</small></span> <br/> {{getDictionaryPhrase('PerNight')}}</h3>
													</template>
													<template v-if="item['property'] && item['property']['children'] && item['property']['children'].length > 0 && 
													item['room'] &&
													item['room']['children'] && item['room']['children'].length > 0 &&
													item['room']['children'][0]['roomtypecode'] && item['room']['children'][0]['roomcode'] && item['bookingurl']">
														<a href="#" class="book btn btn-primary hasPrice" :data-urlalias="item['property']['children'][0]['urlalias'] ? item['property']['children'][0]['urlalias'].value : ''" :data-roomtypecode="item['room']['children'][0]['roomtypecode']"  :data-rateplancode="item['room']['children'][0]['rateplancode']" :data-roomcode="item['room']['children'][0]['roomcode']" :data-bookingurl="item['bookingurl']" :data-offerurl="[item['offerurl'] && item['offerurl'].value ? item['offerurl'].value : '']"/>
													</template>
													<template v-else>
														<a href="#" class="book btn btn-primary hasPrice">{{getDictionaryPhrase('Book')}}</a>
													</template>
												</template>
												<template v-else>
												<a href="#" class="btn btn-primary">{{getDictionaryPhrase('Book')}}</a>
												</template>
											</div>
											<div class="col-12 desktop-hidden mt-4 mb-5">
												<a href="#" class="btn btn-primary hasPrice dd">{{getDictionaryPhrase('Book')}}</a>
												<template v-if="item['price']">
													<template v-if="item['price'].value && item['price'].value > 0">
														<h3 class="text-left">{{getDictionaryPhrase('From')}} <br/><span>{{updatePriceString(item["price"].value)}} <small>{{curcurrency}}</small></span> <br/> {{getDictionaryPhrase('PerNight')}}</h3>
													</template>
													<template v-else-if="item['price'] > 0">
														<h3 class="text-left">{{getDictionaryPhrase('From')}} <br/><span>{{updatePriceString(item["price"])}} <small>{{curcurrency}}</small></span> <br/> {{getDictionaryPhrase('PerNight')}}t</h3>
													</template>
													{{item}}
													<template v-if="item['property'] && item['property']['children'] && item['property']['children'].length > 0 && 
													item['urlalias'] &&
													item['room'] &&
													item['room']['children'] && item['room']['children'].length > 0 &&
													item['property']['children'][0]['urlalias'] && item['room']['children'][0]['roomtypecode'] && item['room']['children'][0]['rateplancode'] && item['room']['children'][0]['roomcode'] && item['bookingurl']">
														<a href="#" class="book btn btn-primary hasPrice ee" :data-urlalias="item['urlalias'] ? item['urlalias'].value : ''" :data-roomtypecode="item['room']['children'][0]['roomtypecode']"  :data-rateplancode="item['room']['children'][0]['rateplancode']" :data-roomcode="item['room']['children'][0]['roomcode']" :data-bookingurl="item['bookingurl']":data-offerurl="[item['offerurl'] && item['offerurl'].value ? item['offerurl'].value : '']"/>
													</template>
													<template v-else>
														<a href="#" class="btn btn-primary hasPrice ff">{{getDictionaryPhrase('Book')}}</a>
													</template>
												</template>
												<template v-else>
												<a href="#" class="btn btn-primary gg">{{getDictionaryPhrase('Book')}}</a>
												</template>
											</div>
										</div>
									</div>
									<div class="itemid" style="display:none">{{item['id']}} </div>
							</div> <!-- End: list Item -->
						</template>
					</div>
						</template>
                       </div>
                    </div>
                </div>
            </div>
        </div>
			<!-- End: New List Layout -->
		</template> <!-- END: iscarousel Check-->
			
			<!-- Service Section for TabbedContentBlockList  -->
			<template v-for="(graphqlservice, _index) in graphqlservices">
			<div :id="uid + '_tabs-' + _index.toString() + '_services'" :class="'services-content ' + [_index == 0? '': 'hidden']">
				<div :id="uid+'_owl-carousel-'+_index" class="owl-carousel carousel-services owl-theme">
					<template v-for="(item, index) in graphqlservice">
						<div class="item contenttile" :data-itemid="item['id']">
							<div class="hero hovertranslate">
								<template v-if="item['children'] && item['children'].length > 0">
									<template v-for="(subitem, subindex) in item['children']">
										<template v-if="subitem['templatename'] == 'hjimage'">
											<template v-if="subitem['image'].src != ''">
												<!--<div class="owl-imgbg-tab" :style="'background-image: url('+ subitem['image']['src'] +')'"></div>-->
												<div class="owl-imgbg-tab lazyload" :data-bg="subitem['image']['src']"></div>
												<!-- <img :src="subitem['image']['src']" alt="" /> -->
											</template>
										</template>
									<div class="owl-content">

									<div class="grid-content">
										<!--<div class="title" v-html="item['title'].value"></div>-->
										<template v-if="item['price']">
											<template v-if="item['price'].value && item['price'].value > 0">
												<div class="title" style="line-height: 1em;">{{updatePriceString(item["price"].value)}}<span style="font-size:0.8em"> {{curcurrency}}</span> {{getDictionaryPhrase('Nightly')}} </div>
											</template>
											<template v-else-if="item['price'] > 0">
												<div class="title" style="line-height: 1em;">{{updatePriceString(item["price"])}}<span style="font-size:0.8em"> {{curcurrency}}</span> {{getDictionaryPhrase('Nightly')}} </div>
											</template>
										</template>
									</div>
									</div>
									<div class="fav-btn"></div>
									</template>
								</template>
							</div>
							<div class="description">
							<div class="item-sections item-section-left">
								<div class="item-section-intro">
								<template v-if="item['property'] && item['property']['children'] && item['property']['children'].length > 0 && item['property']['children'][0]['title']">
								{{ item['property']['children'][0]['title'].value }}
                                </template>
								</div>
								<div class="item-section-title">{{ item['title'].value }}</div>
								<div class="item-section-desc">{{ item['description'].value }}</div>
							</div>
							<div class="actionbutton item-sections item-section-right">
								<template v-if="item['price']">
									<template v-if="item['price'].value && item['price'].value > 0">
										<div class="pricing-tag">{{getDictionaryPhrase('From')}} <span class="price">{{updatePriceString(item["price"].value)}}</span> <span style="color:#343944;"> {{curcurrency}}</span> {{getDictionaryPhrase('PerNight')}} </div>
									</template>
									<template v-else-if="item['price'] > 0">
										<div class="pricing-tag">{{getDictionaryPhrase('From')}} <span class="price">{{updatePriceString(item["price"])}}</span> <span style="color:#343944;"> {{curcurrency}}</span> {{getDictionaryPhrase('PerNight')}} </div>
									</template>
								</template>
								<input type="button" value="getDictionaryPhrase('Book')" />
							</div>
							</div>

							<div class="itemid" style="display:none">{{item['id']}} </div>
						</div>
					</template>
					<!-- end: graphservice -->
				</div>
			</div>
			</div>
			</template>
			<!-- End: Service Section for TabbedContentBlockList -->
		</div>	
	</template>		
	</template>		
	</div> <!-- end:HJContentBlockList -->
</template>

<script>
import { axios } from 'axios';
import ApolloClient from 'apollo-boost';
import gql from 'graphql-tag';
import {
  enableExperimentalFragmentVariables
} from 'graphql-tag';
import { uuid } from 'vue-uuid';
import { 
getObjectById,
getDictionaryPhrase,
updatePriceString,
getKeys, graphqlToObject, searchMoreContentByType, graphqlRemoveReserved, getContentByType, getContentByType_new, getObject, graphqlFetcherTestSSR, graphqlFetcher } from '../graphqlFetcher.js';
import { bookhotel, getSortedContent, addAdhocImages } from '../hjutil.js';
import Vue from 'vue';
import config from '../temp/config';
import { serverBus } from '../event-bus.js';
import arrowdownicon from '../assets/img/arrow_down.png';
import { getContent, getContentByProperty, loadmore } from '../HJContentBlockList.js';
import 'lazysizes';
// import a plugin
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

export default {
  name: 'HJTabbedContentBlockList',
  components: {
  },
  props: {
    fields: {
      type: Object,
      default: function(){},
    },
    rendering: {
      type: Object,
    },
    uid: {
      type: String,
      default: function(){return uuid.v1();},
    },
    currentcontext: {
      type: String,
      default: function(){},
    },	
    lastitemid: {
      type: Object,
      default: [],
      },
    datacontext: {	// current context is itself
      type: Object,
      default: null,
    },
  },
  data: function() {
    return {
	  isrefresh: null,
      loadingQueriesCount: 0,
      error: null,
	  tempresults: [],
	  graphqlresults: [],
	  graphqlresults_all: [],
	  graphqlservices: [],
	  lastitemid:[],
	  hotelnames:[],
        tabs: [],
      hassubtabs: true,
	  monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
	  hjsettings: null,
	  arrowDownIcon: arrowdownicon,
	  hoteldetail: null,
	  curcurrency: null
    };
  },
  computed: {
  
	currency: function(){
		return this.$jss.currency();
	},
    datasource: function() {
	  if (this.currentcontext != null)
		return this.currentcontext;
	  else
		return this.fields && this.fields.data && this.fields.data.datasource;
    },
    contextItem: function() {
      return this.fields && this.fields.data && this.fields.data.contextItem;
    },
	
    templates: function() {
		if (this.$root.templates)
			return this.$root.templates; 
		else
		{
			return {};
		}
	},
	hotel_prices: function () {
		if (this.$jss.prices() != undefined && this.$jss.prices() != null )
			return this.$jss.prices(); 
		else
			return null;
	},
    propertydata: function() {
	  if (this.datacontext != null)
	  {
		return this.datacontext;
	  } else {
			return {};
	  }
	},
  },
  watch: {
	currency : function(){
		//console.log(this.$jss.currency());
		this.getdata();
	}
  },
  methods: {
	getDictionaryPhrase: function(key)
	{
		return getDictionaryPhrase(key, this);
	},
	updatePriceString: function(price){
		return updatePriceString(price);
	},
	sitecoredatetodate: function(datestring)
	{
		var date = new Date(datestring.substring(0,4)+"-"+datestring.substring(4,6)+"-"+datestring.substring(6,8));
		return date;
	},
    isproperty: function(name) {
		if (this.fields != null && this.fields[name] != undefined)
		{
			return this.fields[name];
		}
		else
		{
			if (this.fields != null && this.fields.data.datasource && this.fields.data.datasource.fields)
			{
				for (var i=0; i < this.fields.data.datasource.fields.length; i++)
				{
					if (this.fields.data.datasource.fields[i].name == name)
					{
						if (this.fields.data.datasource.fields[i].targetItems && 
							this.fields.data.datasource.fields[i].targetItems.length > 0) {
							if (this.fields.data.datasource.fields[i].targetItems[0].textField)
								return this.fields.data.datasource.fields[i].targetItems[0].textField.value;
							else if (this.fields.data.datasource.fields[i].targetItems[0].title)
								return this.fields.data.datasource.fields[i].targetItems[0].textField.title;
							else
								return "";
						}
						else
						{
							return this.fields.data.datasource.fields[i].value;
						}
					}
				}
			}
			else if (this.currentcontext && this.currentcontext.fields)
			{
				var keys = getKeys(this.currentcontext);
				for (var i=0; i < keys.length; i++)
				{
					if (keys[i].toLowerCase() === name.toLowerCase())
					{
						if (this.currentcontext[keys[i]].targetItems && 
							this.currentcontext[keys[i]].targetItems.length > 0) {
							if (this.currentcontext[keys[i]].targetItems[0].textField)
								return this.currentcontext[keys[i]].targetItems[0].textField.value;
							else if (this.currentcontext[keys[i]].targetItems[0].title)
								return this.currentcontext[keys[i]].targetItems[0].title.value;
							else
								return "";
						}
						else
						{
							if (this.currentcontext[keys[i]].value != undefined)
								return this.currentcontext[keys[i]].value;
							else
								return this.currentcontext[keys[i]];
						}
					}
				}
				for (var i=0; i < this.currentcontext.fields.length; i++)
				{
					if (this.currentcontext.fields[i].name.toLowerCase() == name.toLowerCase())
					{
						if (this.currentcontext.fields[i].targetItems && 
							this.currentcontext.fields[i].targetItems.length > 0) {
							if (this.currentcontext.fields[i].targetItems[0].textField)
								return this.currentcontext.fields[i].targetItems[0].textField.value;
							else if (this.currentcontext.fields[i].targetItems[0].title)
								return this.currentcontext.fields[i].targetItems[0].title.value;
							else
								return "";
						}
						else
						{
							if (this.currentcontext.fields[i].value)
								return this.currentcontext.fields[i].value;
							else
								return this.currentcontext.fields[i];
						}
					}
				}
			}
		}
		return null;
	},
	getdata: function(){		
		let vm = this;
	
		//set currency
		var cur_currency = localStorage.getItem('curcurrency')
		var currentCurrency = ''

		if(cur_currency){
			vm.$jss.store.setCurrency(cur_currency);
		}else {
			vm.$jss.store.setCurrency('USD')
		}
		//set currency end

		if (this.fields && this.fields.data && this.fields.data.datasource)
		{
			graphqlRemoveReserved(this.fields.data.datasource);
		}
		if (vm.$jss.hotelnames() == null || !(vm.$jss.hotelnames() != null && vm.$jss.hotelnames().children && vm.$jss.hotelnames().children.length > 0))
			vm.$jss.store.setHotelnames(getObject(vm.hotels, vm));
		vm.hotelnames = vm.$jss.hotelnames();
		
		if (vm.$jss.prices() == null)
		{
			$.getJSON( "/api/gethotelprices", function( data ) {
				vm.$jss.store.setPrices(data);
				vm.hotel_prices = data;
			});			
		}
		else
		{
			vm.hotel_prices = vm.$jss.prices();
		}
		
		//var updateResults = function(data, _vm, ar, type) {			
			vm.lastitemid = [];
			if (vm.isrefresh == null)
				vm.isrefresh = false;
			if (!vm.isrefresh)
			{
				vm.tabs = [];
			}
			else
			{
			}
			if (vm.datasource["contentCategory"] != null && vm.datasource["contentCategory"].targetItems.length > 0)
			{
				for (var i=0; i < vm.datasource["contentCategory"].targetItems.length; i++)
				{
					var pagesize = -1;
					if (vm.datasource["contentCategory"].targetItems[i].value.value.toLowerCase() === "hjevent" ||
					vm.datasource["contentCategory"].targetItems[i].value.value.toLowerCase() === "hjdealeat" || vm.datasource["contentCategory"].targetItems[i].value.value.toLowerCase() === "hjdealstay" ||  vm.datasource["contentCategory"].targetItems[i].value.value.toLowerCase() === "hjdealspace" || vm.datasource["contentCategory"].targetItems[i].value.value.toLowerCase() === "hjdealwedding")
						pagesize = -1;
					
					var title = vm.datasource["contentCategory"].targetItems[i].title.value;
					if (vm.datasource.children && vm.datasource.children.length > i && vm.datasource.children[i].template && vm.datasource.children[i].template.name && vm.datasource.children[i].template.name.toLowerCase() == "hjtab")
					{
						//title = vm.datasource.children[i]['heading'].value;
					}
					if (!vm.isrefresh)	{
						vm.tabs.push({title: title, description: vm.datasource["contentCategory"].targetItems[i].description.value, type: vm.datasource["contentCategory"].targetItems[i].value.value.toLowerCase() });
						//vm.tabs.push({title: title, description: vm.datasource["contentCategory"].targetItems[i].description.value, type: vm.datasource["contentCategory"].targetItems[i].value.value.toLowerCase(), section: vm.datasource["contentCategory"].targetItems[i].section.value.toLowerCase(), urlalias: vm.datasource["contentCategory"].targetItems[i].urlalias.value.toLowerCase() });
					}else
					{
						if (vm.tabs.length < i)
						{
							 //console.log(vm.datasource["contentCategory"].targetItems[i]);
							//vm.tabs.push({title: title, description: vm.datasource["contentCategory"].targetItems[i].description.value, type: vm.datasource["contentCategory"].targetItems[i].value.value.toLowerCase()});
							vm.tabs.push({title: title, description: vm.datasource["contentCategory"].targetItems[i].description.value, type: vm.datasource["contentCategory"].targetItems[i].value.value.toLowerCase(), section: vm.datasource["contentCategory"].targetItems[i].section.value.toLowerCase(), urlalias: vm.datasource["contentCategory"].targetItems[i].urlalias.value.toLowerCase() });
						}
						else
							//vm.tabs[i] = {title: title, description: vm.datasource["contentCategory"].targetItems[i].description.value, type: vm.datasource["contentCategory"].targetItems[i].value.value.toLowerCase(), section: vm.datasource["contentCategory"].targetItems[i].section.value.toLowerCase(), urlalias: vm.datasource["contentCategory"].targetItems[i].urlalias.value.toLowerCase() };
							vm.tabs[i] = {title: vm.datasource["contentCategory"].targetItems[i].title.value, description: vm.datasource["contentCategory"].targetItems[i].description.value, type: vm.datasource["contentCategory"].targetItems[i].value.value.toLowerCase() };
						
					}

					if (vm.graphqlresults.length < vm.datasource["contentCategory"].targetItems.length)
					{
						for (var j=vm.graphqlresults.length; j < vm.datasource["contentCategory"].targetItems.length; j++)
						{
							vm.graphqlresults.push([]);
							vm.graphqlresults_all.push([]);
						}
					}
					if (vm.datasource["contentCategory"].targetItems[i].value.value.toLowerCase() != "hjroom-template" && (vm.propertydata && vm.propertydata != undefined && vm.propertydata != null && vm.propertydata.children && vm.propertydata.children.length > 0))
					{
						var type = vm.datasource["contentCategory"].targetItems[i].value.value.toLowerCase();
						var _propdata = null;
						if (vm.propertydata.children[0].template.name.toLowerCase() === "folder")
							_propdata = vm.propertydata.children.find(x=>x.name.toLowerCase() == type.substring(2).toLowerCase());
						else
							_propdata = vm.propertydata;
						
						var propdata = getObject(_propdata, vm);
						if (type.toLowerCase() == "hjevent" || type.toLowerCase() == "hjroom-template")
						{
							if (type.toLowerCase() == "hjroom-template")
								vm.graphqlresults_all[i] = getContentByType(vm.$jss.rooms(), type, vm, false, true, vm.$jss.travellertype(), false, 20, vm.$jss.property());
							else
								vm.graphqlresults_all[i] = getContentByType(propdata, type, vm, false, true, vm.$jss.travellertype(), false, 20, vm.$jss.property());
							vm.graphqlresults[i] = [];
							if (type.toLowerCase() == "hjevent")
								vm.graphqlresults_all[i] = getSortedContent(vm.graphqlresults_all[i], "startdate", true, false);
							addLikedOrClicked(vm.graphqlresults_all[i], vm.graphqlresults[i], 10);
						}
						else if (type.toLowerCase() == "hjcustom")
						{
							//vm.graphqlresults[i] = getContentByType(propdata, type, vm, false, false, vm.$jss.travellertype());
							if (vm.datasource.children[i].children && vm.datasource.children[i].children.length > 0)
							{
								addAdhocImages(vm.graphqlresults[i], getObject(vm.datasource.children[i], vm));
							}
						}
						else
						{
							vm.graphqlresults[i] = getContentByType(propdata, type, vm, false, true, vm.$jss.travellertype(), false, 20, vm.$jss.property());
							//vm.graphqlresults[i] = getSortedContent(vm.graphqlresults[i], "orderindex", false, false);
						}				

						if (vm.isproperty('iscarousel') == '1')
						{
							var owl = $('#gallery_'+j.toString()+'_'+vm.uid+' .swiper-wrapper');
							if (vm.graphqlresults[i] && vm.graphqlresults[i].length > 0)
							{
								getContent(vm.graphqlresults[i], vm, "tabbedcontenttile", false, owl, true);
							}
						}
						else
						{
							var s = getContent(vm.graphqlresults[i], vm, "tabbedcontenttile");
							var tab = $('#' + vm.uid + '_tabs-' + i.toString());
							$(tab).find('.item-block').remove();
							if ($(tab).find('.filter_wrapper').length > 0)
							{
								$(s).insertAfter($(tab).find('.filter_wrapper'));
							}
							else
							{
								$(tab).append(s);
							}
							//if (vm.isproperty('haspropertyfilter') != '1') {
								if (!(vm.graphqlresults[j] && vm.graphqlresults[j].length > 0))
								{
									if (vm.datasource["contentCategory"].targetItems.length > 2)
									{
										$('#' + vm.uid + ' .galleryTab#galleryTab'+i.toString()).css("display", "none");
										if ($('#' + vm.uid + ' .slider-default-thumbs').hasClass('swiper-container-initialized')){
											try
											{
												//var prevswiper = document.querySelector('#' + vm.uid + ' .slider-default-thumbs').swiper
												var prevswiper = document.querySelector('#' + vm.uid + '-slider-default-thumbs').swiper;
												if (prevswiper != undefined && prevswiper != null)
												{
													prevswiper.update();
												}
											}
											catch(err)
											{
											}
										}
									}
									else
									{
										$('#' + vm.uid + ' .galleryTab.tab-'+j.toString()).css("display", "none");
									}
								}
							//}
						}
						
						if (type.toLowerCase() == "hjhost" || 
							type.toLowerCase() == "hjhostcelebrate" || 
							type.toLowerCase() == "hjhostmeet" 
						)
						{
							/*
							if (vm.graphqlservices.length < vm.datasource["contentCategory"].targetItems.length)
							{
								for (var j=vm.graphqlservices.length-1; j < vm.datasource["contentCategory"].targetItems.length; j++)
								{
									vm.graphqlservices.push([]);
								}
							}
							vm.graphqlservices[j] = getContentByType(vm.services, type.toLowerCase(), vm, true, true, vm.$jss.travellertype(), true, -1, vm.$jss.property());
							*/
							if (vm.datasource.children && vm.datasource.children.length > i && vm.datasource.children[i].children && vm.datasource.children[i].children.length > 0)
							{
								addAdhocImages(vm.graphqlresults[i], getObject(vm.datasource.children[i], vm));
							}
							
						}
						//vm.lastitemid.push(vm.graphqlresults[i][vm.graphqlresults[j].length-1]["id"]);
					}
					else
					{
						searchMoreContentByType(null, vm.datasource["contentCategory"].targetItems[i].value.value, vm, 
							function(data, _vm, ar, type, property, event, context, isloadmore, graphqlresults, searchresults, targetobject){
								console.log(ar);
								for (var j=0; j < _vm.datasource["contentCategory"].targetItems.length; j++)
								{
									if (_vm.datasource["contentCategory"].targetItems[j].value.value.toLowerCase() === type.toLowerCase())
									{
										if (type.toLowerCase() == "hjevent" || type.toLowerCase() == "hjroom-template")
										{
											_vm.graphqlresults_all[j] = getContentByType(ar, type.toLowerCase(), _vm, false, true, _vm.$jss.travellertype(), false, -1, vm.$jss.property());
											_vm.graphqlresults[j] = [];
											if (type.toLowerCase() == "hjevent")
												_vm.graphqlresults_all[j] = getSortedContent(_vm.graphqlresults_all[j], "startdate", true, false);
											addLikedOrClicked(_vm.graphqlresults_all[j], _vm.graphqlresults[j], 10);
										}
										else if (type.toLowerCase() == "hjcustom")
										{
											_vm.graphqlresults[j] = getContentByType(ar, type.toLowerCase(), _vm, false, false, _vm.$jss.travellertype());
											if (_vm.datasource.children && _vm.datasource.children.length > j && _vm.datasource.children[j].children && _vm.datasource.children[j].children.length > 0)
											{
												addAdhocImages(_vm.graphqlresults[j], getObject(_vm.datasource.children[j], vm));
											}
										}
										else
										{
											_vm.graphqlresults[j] = getContentByType(ar, type.toLowerCase(), _vm, false, true, _vm.$jss.travellertype(), false, -1, vm.$jss.property(), null, null, null, null, false);
											//_vm.graphqlresults[j] = getSortedContent(_vm.graphqlresults[j], "orderindex", false, false);
										}				

										if (_vm.isproperty('iscarousel') == '1')
										{
											var owl = $('#gallery_'+j.toString()+'_'+vm.uid+' .swiper-wrapper');
											if (searchresults[j] && searchresults[j].length > 0)
											{
												getContent(searchresults[j], _vm, "tabbedcontenttile", false, owl, true);
											}
										}
										else
										{
											//var s = getContent(searchresults[j], _vm, "tabbedcontenttile");
											var s = getContent(_vm.graphqlresults[j], _vm, "tabbedcontenttile");
											var tab = $('#' + _vm.uid + '_tabs-' + j.toString());
											$(tab).find('.item-block').remove();
											if ($(tab).find('.filter_wrapper').length > 0)
											{
												$(s).insertAfter($(tab).find('.filter_wrapper'));
											}
											else
											{
												$(tab).append(s);
											}
											if (_vm.isproperty('haspropertyfilter') != '1') {
												if (!(_vm.graphqlresults[j] && _vm.graphqlresults[j].length > 0))
												{
													if (_vm.datasource["contentCategory"].targetItems.length > 2)
													{
														$('#' + _vm.uid + ' .galleryTab#galleryTab'+j.toString()).css("display", "none");
														if ($('#' + _vm.uid + ' .slider-default-thumbs').hasClass('swiper-container-initialized')){
															try
															{
																//var prevswiper = document.querySelector('#' + _vm.uid + ' .slider-default-thumbs').swiper
																var prevswiper = document.querySelector('#' + _vm.uid + '-slider-default-thumbs').swiper
																if (prevswiper != undefined && prevswiper != null)
																{
																	prevswiper.update();
																}
															}
															catch(err)
															{
																//$('#' + _vm.uid + ' .galleryTab#galleryTab'+j.toString()).css("display", "block");
															}
														}
													}
													else
													{
														$('#' + _vm.uid + ' .galleryTab.tab-'+j.toString()).css("display", "none");
													}
												}
											}
										}
									}
								}
								if (type.toLowerCase() == "hjhost" || 
									type.toLowerCase() == "hjhostcelebrate" || 
									type.toLowerCase() == "hjhostmeet" 
								)
								{
									if (_vm.graphqlservices.length < _vm.datasource["contentCategory"].targetItems.length)
									{
										for (var j=_vm.graphqlservices.length-1; j < _vm.datasource["contentCategory"].targetItems.length; j++)
										{
											_vm.graphqlservices.push([]);
										}
									}
									for (var j=0; j < _vm.datasource["contentCategory"].targetItems.length; j++)
									{
										if (_vm.datasource["contentCategory"].targetItems[j].value.value.toLowerCase() == type.toLowerCase())
										{
											_vm.graphqlservices[j] = getContentByType(_vm.services, type.toLowerCase(), _vm, true, true, _vm.$jss.travellertype(), true, -1, _vm.$jss.property());
											if (_vm.datasource.children && _vm.datasource.children.length > j && _vm.datasource.children[j].children && _vm.datasource.children[j].children.length > 0)
											{
												addAdhocImages(_vm.graphqlresults[j], getObject(_vm.datasource.children[j], _vm));
											}
										}
									}
								}
								
/*								
								if (_vm.lastitemid.length < _vm.datasource["contentCategory"].targetItems.length)
								{
									for (var j=_vm.lastitemid.length-1; j < _vm.datasource["contentCategory"].targetItems.length; j++)
									{
										_vm.lastitemid.push(null);
									}
								}
								for (var j=0; j < _vm.datasource["contentCategory"].targetItems.length; j++)
								{
									if (_vm.datasource["contentCategory"].targetItems[j].value.value.toLowerCase() == type.toLowerCase())
									{
										if (_vm.graphqlresults[j].length > 0 && _vm.graphqlresults[j] && _vm.graphqlresults[j]["id"])
										{
											_vm.lastitemid[j] = _vm.graphqlresults[j][_vm.graphqlresults[j].length-1]["id"];
										}
									}
								} */
							}, pagesize, null, vm.$jss.property(), false, vm.$jss.travellertype(), null, "", null, null, vm.graphqlresults);
					}
						
				}
			}			
		//};
		//graphqlFetcher('', vm, '', null, '', null, updateResults);			
	},
  },
  mounted: function() {
	if (true)	//	this code will be used for later lazy loading
	{
	let vm = this;
	
	//set currency
	var cur_currency = localStorage.getItem('curcurrency')
	var currentCurrency = ''

	if(cur_currency){
		vm.$jss.store.setCurrency(cur_currency);
		vm.currency = cur_currency;
	}else {
		vm.$jss.store.setCurrency('USD')
		vm.currency = 'USD';
	}
	//set currency end

	vm.hotelnames = vm.$jss.hotelnames();
	//console.log(vm.$jss.prices())
	if (vm.$jss.prices() === null)
	{
		$.getJSON( "/api/gethotelprices", function( data ) {
			vm.hotel_prices = data;
			vm.$jss.store.setPrices(data);
		});			
	}
	else
	{
		vm.hotel_prices = vm.$jss.prices();
	}
	if (vm.$jss.property() && vm.$jss.property() != ''){
		var propertyid = vm.$jss.property();
		var hotelDetails = {};
		if (propertyid && propertyid != null){		
		propertyid = propertyid.replace(/\-/g,"").toUpperCase();
		hotelDetails = getObject(getObjectById(vm.$jss.hotels(),propertyid), vm);
		}else{
		vm.hjcity = '';
		}
		vm.hoteldetail = hotelDetails;
	}
	
		
    window.setTimeout(function() {
		vm.getdata();		
		if (vm.tabs.length > 2){
			var galleryThumbs = new Swiper('.slider-default-thumbs', {
				centerInsufficientSlides: true,
				// slidesPerView: '3',
				slidesPerView: 'auto',
				freeMode: true,
				//preventClicks: true,
			   // preventClicksPropagation: true,
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
				breakpoints: {
					1025: {
						slidesPerView: 3,
					}
				},
			});
		
		}
		

		
		if (vm.isproperty('iscarousel') == '1')
		{
			// var gallerysociety = new Swiper('.gallery-society', {
			// 			centerInsufficientSlides: true,
			// 			observer: true,
            // observeParents: true,
			// 			observeSlideChildren: true,
			// 			spaceBetween: 25,
			// 			loop:true,
			// 			navigation: {
			// 				nextEl: '.swiper-button-next',
			// 				prevEl: '.swiper-button-prev',
			// 			},
			// 			pagination: {
			// 				el: '.swiper-pagination-first',
			// 				clickable: true,							
							
			// 			},
						
			// 		});
		}
		//var button = '#' + vm.uid + '_tabs-0 ' + ' .loadmore';
		
		
	},1);
    //}, 200);

    $(document).ready(function() {
		//console.log('mounted document raedy');
		$('.fav-btn').on('click', function(){
			setLike(this);
			setClicked(this);
		});
		$('.calendar-btn').on('click', function(){
			setClicked(this);
			//addEventToCalendar("subject", "description", "location", "12/1/2020", "12/6/2020");
			addEventToCalendar(this);
		});
		$('share-btn').on('click', function(){
			setClicked(this);
		});
		$('#' + vm.uid).on('click', '.book', function(e) {
			var language = 'en'; 
			if ( vm.$jss.routeData().itemLanguage == 'zh-CN' || vm.$jss.routeData().itemLanguage == 'zh'){
				language = 'cn'; 
			}else{
				language = vm.$jss.routeData().itemLanguage.substring(vm.$jss.routeData().itemLanguage.length-2).toLowerCase()			
			} 
			e.preventDefault();
			var key = vm.$jss.hjconfig().encryption_key;
			var iv = vm.$jss.hjconfig().encryption_iv;
			var propertycode = "";
			var propertycityalias = "";
			var propertyhotelalias = "";
			if ($(this).attr('data-bookingurl'))
			{
				propertycode = $(e.target).attr('data-bookingurl').split('|')[2];
				propertycityalias = $(e.target).attr('data-bookingurl').split('|')[0];
				propertyhotelalias = $(e.target).attr('data-bookingurl').split('|')[1];
			}
			var book_url = vm.$jss.hjconfig().book_url;
			if (propertycode != '' && propertycityalias != '' && propertyhotelalias != '')
			{
				bookhotel(key, iv, book_url, propertycode, propertycityalias, propertyhotelalias, null, null, null, null, 'None', '', $(this).attr('data-roomcode'), '', language);
			}
		});
		window.addEventListener("load", function(vm) {
			//console.log(vm.uid);
			//$('#' + vm.uid + ' .slider-default-thumbs').updateContainerSize();
			console.log('HJTabbedContentBlockList fully loaded');
		});
	});	
 }

  },
  created: function() {
  },
  updated: function() {
  
  	let vm = this;
	
	//add simple support for background images:
	document.addEventListener('lazybeforeunveil', function(e){
		var bg = e.target.getAttribute('data-bg');
		if(bg){
			e.target.style.backgroundImage = 'url(' + bg + ')';
		}
	});
	
	//set currency
	var cur_currency = localStorage.getItem('curcurrency')
	var currentCurrency = ''

	if(cur_currency){
		vm.$jss.store.setCurrency(cur_currency);
		vm.currency = cur_currency;
	}else {
		vm.$jss.store.setCurrency('USD')
		vm.currency = 'USD';
	}
	//set currency end

	//console.log(vm);
	if (vm.tabs.length > 2){
			var galleryThumbs = new Swiper('.slider-default-thumbs', {
				centerInsufficientSlides: true,
				slidesPerView: '3',
				freeMode: true,
				//preventClicks: true,
			   // preventClicksPropagation: true,
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
			});
		
		}
	//console.log('Can Search? ' + vm.isproperty('cansearch'));
	
    $(document).ready(function() {
		var likes = {likes:[]};
		if (getCookie('HJ_GLOBAL_COOKIE') == null) {
			likes = {likes:[]};
		} else {
			var likes_json = getCookie('HJ_GLOBAL_COOKIE');			
			likes = JSON.parse(likes_json);
		}
		//console.log($('#' + vm.uid + ' .contenttile'));
		$('#' + vm.uid + ' .contenttile').each(function(){ 
			var itemid = $(this).attr('data-itemid');
			if (likes.likes.length > 0)
			{
				for (var i = likes.likes.length-1; i >= 0; i--)
				{
					if (likes.likes[i]["id"] == itemid)
					{
						//console.log(likes.likes[i]["id"]);
						$(this).find('.fav-btn.icon').addClass('active');
					}
				}
			}
		});
		$('#' + vm.uid).on('click', '.view-details', function(e){
		//$('#' + vm.uid + ' .view-details').unbind('click').bind('click', function(e){
			//if ($(this).attr('href') != undefined && $(this).attr('href') != '')
			//{
				e.preventDefault();
				setViewDetailClick(this);
				//e.stopImmediatePropagation();
				//window.location = $(this).attr('href');
				window.open($(this).attr('href'));
			//}
		});
    });

	if (vm.isproperty('iscarousel') == '1')
	{  
	  //FadeIn Animation
		/*for (var i=0; i < vm.graphqlresults.length; i++)
		{

			if ($('#' + vm.uid + '_tabs-' + i.toString() + '_owl-carousel').hasClass('slick-initialized')){
			$('#' + vm.uid + '_tabs-' + i.toString() + '_owl-carousel').slick('unslick');
			}
			$('#' + vm.uid + '_tabs-' + i.toString() + '_owl-carousel').not('.slick-initialized').slick({
				dots: true,
				infinite: true,
				speed: 300,
				slidesToShow: 1,
				arrows: true,
				centerMode: true,
				centerPadding: '10%',
				//slidesToShow: 1,
				//asNavFor: '.' + vm.uid + '_slick',
				responsive: [
					{
					  breakpoint: 768,
					  settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						infinite: false,
						dots: true,
						arrows: false,
						centerMode: false,							
					  }
					},
					{
					  breakpoint: 1000,
					  settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						infinite: false,
						dots: true,
						arrows: false,					
					  }
					},
					]
				  
			});
		}*/
		/*var swiperContent = '';
		
		for (var i=0; i < vm.graphqlresults.length; i++)
			{
				
				//console.log(vm.graphqlresults[i]);

			if (vm.graphqlresults[i].children && vm.graphqlresults[i].children.length > 0){
				for (var j = 0; j <	vm.graphqlresults[i].children.length; j++){	
				swiperContent = '<div class="swiper-slide firstslide">';
				if (vm.graphqlresults[i]['children'][j]['children'] && vm.graphqlresults[i]['children'][j]['children'].length >0){
				 if (vm.graphqlresults[i]['children'][j]['children'][0]['templatename'] == 'hjimage' && vm.graphqlresults[i]['children'][j]['children'][0]['image'].src != ''){
						swiperContent += '	<div class="gallery-container lazyload" :data-bg="'+ vm.graphqlresults[i]['children'][j]['children'][0]['image'].src+'"></div>	';		
				 }
				}
				swiperContent += '<h4 class="gallery-title"> ';
				swiperContent += vm.graphqlresults[i]['children'][j]['title'].value + '<br> ';	
				if (vm.graphqlresults[i]['children'][j]['property'] && vm.graphqlresults[i]['children'][j]['property']['children'] && vm.graphqlresults[i]['children'][j]['property']['children'].length > 0 && vm.graphqlresults[i]['children'][j]['property']['children'][0]['title']){
					swiperContent += '<span>'+ vm.graphqlresults[i]['children'][j]['property']['children'][0]['title'] + '</span> ';	
				}
				swiperContent += '<div class="img-overlay2"></div>';
				swiperContent += '</div>';
				}
			
			}
			
			//add to swiper-wrapper 
			//console.log(swiperContent);
			//console.log('#'+vm.uid+'_tabs-'+i + ' .swiper-wrapper');
			$('#'+vm.uid+'_tabs-'+i + ' .swiper-wrapper').html(swiperContent);
			
			// initiate corresponding swiper
			// clear swiperContent
			}*/
			// if($('.gallery-top').hasClass('active')){
			// 	setTimeout(function(){
			// 	var gallerysociety = new Swiper('.gallery-society', {
			// 		centerInsufficientSlides: true,
			// 		// observer: true,
			// 		// observeParents: true,
			// 		// observeSlideChildren: true,
			// 		spaceBetween: 25,
			// 		loop:true,
			// 		loopAdditionalSlides: 1,
			// 		navigation: {
			// 			nextEl: '.swiper-button-next',
			// 			prevEl: '.swiper-button-prev',
			// 		},
			// 		pagination: {
			// 			el: '.swiper-pagination-first',
			// 			clickable: true,							
						
			// 		},
			// 	});
			// 	},5000)		
			// }
	}
	$('#' + vm.uid + ' .galleryTab').unbind('click').bind('click', function(e){
		e.preventDefault();
		var relatedContentId = $(this).attr('href');
		var moduleId = $(this).data('moduleid');
		var mainTab = $(this).data('maintab');
		//console.log('relatedContent ID: ' +relatedContentId+'; moduleID: '+moduleId+'_tabs');
		$('#'+moduleId + '_tabs .main-tabs').removeClass('active');
		$('#'+moduleId + '_tabs .main-tabs').removeClass('swiper-slide-thumb-active');
		$(this).addClass('active');
		$(this).addClass('swiper-slide-thumb-active');
		$('#'+moduleId+'_tabs .tab-contents').addClass('hidden');
		//console.log('#'+moduleId+'_tabs-'+mainTab);
		$('#'+moduleId+'_tabs-'+mainTab).removeClass('hidden');
		$('.services-content').addClass('hidden');
		$('#'+moduleId+'_tabs-'+mainTab+'_services').removeClass('hidden');
		
		
		if (vm.isproperty('iscarousel') == '1'){
		/*if ($('#' + vm.uid + '_tabs-' + mainTab + '_owl-carousel').hasClass('slick-initialized')){
			$('#' + vm.uid + '_tabs-' + mainTab + '_owl-carousel').slick('unslick');
			}
		*/
		/*$('#' + vm.uid + '_tabs-' + mainTab + '_owl-carousel').not('.slick-initialized').slick({
				dots: true,
				infinite: true,
				speed: 300,
				slidesToShow: 1,
				arrows: true,
				centerMode: true,
				centerPadding: '10%',

				responsive: [
					{
					  breakpoint: 768,
					  settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						infinite: false,
						dots: true,
						arrows: false,	
						centerMode: false,						
					  }
					},
					{
					  breakpoint: 1000,
					  settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						infinite: false,
						dots: true,
						arrows: false,					
					  }
					},
					]
				  
			});*/
			
			if ($('#gallery_'+mainTab+'_'+moduleId)){
					if ($('#gallery_'+mainTab+'_'+moduleId).hasClass('swiper-container-initialized')){
						var prevswiper = document.querySelector('#gallery_'+mainTab+'_'+moduleId).swiper;
						if (prevswiper != undefined && prevswiper != null)
						{
							prevswiper.destroy();
						}
					}
						var swiper = new Swiper('#gallery_'+mainTab+'_'+moduleId, {
							centerInsufficientSlides: true,
							// observer: true,
            				// observeParents: true,
							// observeSlideChildren: true,
							// spaceBetween: 25,
							slidesPerView: 1,
							spaceBetween: 25,
							slidesPerGroup: 1,
							loop:true,
							loopAdditionalSlides: 1,
							navigation: {
								nextEl: '.swiper-button-next',
								prevEl: '.swiper-button-prev',
							},
							pagination: {
								el: '.swiper-pagination-first',
								clickable: true,							
								
							},
						});	
				}	
			
		}
			
		if ($('#' + vm.uid + '_tabs-' + mainTab + '_services .carousel-services').hasClass('slick-initialized')){
			$('#' + vm.uid + '_tabs-' + mainTab + '_services .carousel-services').slick('unslick');
			}
			$('#' + vm.uid + '_tabs-' + mainTab + '_services .carousel-services').not('.slick-initialized').slick({
				dots: true,
				infinite: true,
				speed: 300,
				slidesToShow: 1,
				arrows: true,
				centerMode: true,
				centerPadding: '10%',
				/*slidesToShow: 1,*/
				//asNavFor: '.' + vm.uid + '_slick',
				responsive: [
					{
					  breakpoint: 1025,
					  settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						infinite: false,
						dots: true,
						arrows: false,	
						centerMode: false,						
					  }
					},
					// {
					//   breakpoint: 1000,
					//   settings: {
					// 	slidesToShow: 1,
					// 	slidesToScroll: 1,
					// 	infinite: false,
					// 	dots: true,
					// 	arrows: false,					
					//   }
					// },
					]
				  
			});
		
		
	});
	
	$('#' + vm.uid + '_tabs' + ' .loadmore').off().on('click', function(e){
	//$('.loadmore').on('click', function(e){
		
		var tab = $(e.target).parents('.tab-contents')[0];
		var index = $(e.target).parents('.tab-contents')[0].id.toString().substring($(e.target).parents('.tab-contents')[0].id.toString().indexOf("_tabs-")+6);
		if (vm.graphqlresults && vm.graphqlresults.length > (index-0) && vm.graphqlresults[(index-0)].length > 0)
		{
			var lastitemid = vm.graphqlresults[(index-0)][vm.graphqlresults[(index-0)].length-1]["id"];	
			var graphchildren = "(first: 3 after:\""+ lastitemid.toString() + "\")";
			var optionSelected = $(tab).find('.filter').find("option:selected");
			var valueSelected = optionSelected.val();
			//console.log(valueSelected);

			if (vm.datasource["contentCategory"].targetItems[(index-0)].value.value != "")
			{
				loadmore(vm.graphqlresults[(index-0)], e, vm.datasource["contentCategory"].targetItems[(index-0)].value.value.toLowerCase(), vm, '', '', null, valueSelected, null, 3, lastitemid);
			}
		}
		else
		{
			if (vm.datasource["contentCategory"].targetItems[(index-0)].value.value != "")
			{
				loadmore(vm.graphqlresults[(index-0)], e, vm.datasource["contentCategory"].targetItems[(index-0)].value.value.toLowerCase(), vm, '', '', null, valueSelected, null, 3, null);
			}
		}
    });

      if (vm.isproperty('haspropertyfilter') == '1') {
          $('#' + vm.uid + '_tabs' + ' .filter').off().on('change', function (e) {
              //$('.filter' ).on('change', function(e){
              var tab = $(e.target).parents('.tab-contents')[0];
              var index = $(e.target).parents('.tab-contents')[0].id.toString().substring($(e.target).parents('.tab-contents')[0].id.toString().indexOf("_tabs-") + 6);
              //var lastitemid = vm.graphqlresults[(index - 0)][vm.graphqlresults[(index - 0)].length - 1]["id"];

              var optionSelected = $(this).find("option:selected");
							var valueSelected = optionSelected.val();
							
							if (valueSelected === '') {
								valueSelected = '*';
							}

              if (vm.datasource["contentCategory"].targetItems[(index - 0)].value.value != "") {
				console.log(vm.datasource["contentCategory"].targetItems[(index - 0)].value.value.toLowerCase());
                  getContentByProperty(vm.graphqlresults[(index - 0)], e, vm.datasource["contentCategory"].targetItems[(index - 0)].value.value.toLowerCase(), vm, valueSelected, null, "tabbedcontenttile");
              }
          });
      }
	
	if (vm.isproperty('cansearch') == '1'){		
		for (var i=0; i < vm.tabs.length; i++)
		{
			var target = '#' + vm.uid + '_tabs-' + i.toString() + '_jenSearchPicker #selectDate';
			if ($('#' + vm.uid + '_tabs-' + i.toString() + '_jenSearchPicker #selectDate').length != 0){
			$('#' + vm.uid + '_tabs-' + i.toString() + '_jenSearchPicker #selectDate').dateRangePicker(
			{
				//format: 'DD MMM (ddd)',
				format: 'YYYY-MM-DD',
				container: '#' + vm.uid + '_tabs-' + i.toString() + '_jenSearchPicker #jenSearchCalender',
				separator : '-',
				getValue: function()
				{
					return $(this).val();
				},
				setValue: function(s)
				{
					$(this).val(s);
					vm.reservedDate = s;
				},
				inline:false,
				autoClose: true,
				alwaysOpen: false,
				singleDate : true,
				startDate: new Date(),		
				selectForward: true,
				updatePicker: false,
				showTopbar: false,
				singleMonth: '1',
				startOfWeek: 'monday',
			});
			
			$('#' + vm.uid + '_tabs-' + i.toString() + '_jenSearch_form').submit(function(e){
				//console.log('TimePicker form submit');
				vm.reservedTime = 	$('#'+vm.uid + '_tabs-' + i.toString() + '_jenSearchPicker #selectTime').val();
				//console.log('TimePicker form submit, ReservedDate: '+ vm.reservedDate + ',Reserved Time: ' + vm.reservedTime + ',Adults: ' + vm.reservedAdult + ',Children: ' + vm.reservedChildren );
				//e.preventDefault();
				
			});
			}
		}
		
		
		  var updateResults = function(data, _vm, _ar, type, property, e) {
			  
			var tab = $($(e.target).parents('.tab-contents')[0]);
			var date = $($(e.target).parents('.tab-contents')[0]).find('.jenSearchPicker #selectDate').val();
			var optionSelected = $($(e.target).parents('.tab-contents')[0]).find('#searchType option:selected');
			var valueSelected  = optionSelected.val();
			var result = [];
			if (type.toLowerCase() == 'hjevent' || type.toLowerCase() == 'hdealstay' || type.toLowerCase() == 'hjdealeat' || type.toLowerCase() == 'hjdealspace' || type.toLowerCase() == 'hjdealwedding')
			{			
				//result = getContentByType(_ar, type, _vm, false, true, _vm.$jss.travellertype(), false, 30, _vm.$jss.property(), valueSelected, date);
				//result = getSortedContent(result, "startdate", true, false);
			}
			else
				//result = getContentByType(_ar, type, _vm, false, true, _vm.$jss.travellertype(), false, 10, _vm.$jss.property(), valueSelected, date);

			for (var i=0; i < _vm.datasource["contentCategory"].targetItems.length; i++)
			{
				if (_vm.datasource["contentCategory"].targetItems[i].value.value.toLowerCase() === type.toLowerCase())
				{
				_vm.graphqlresults[i] = [];
				if (type.toLowerCase() == 'hjevent')	
				{
					for(var j=0; j < 6; j++)
					{
						if (j < result.length)
							_vm.graphqlresults[i].push(result[j]);
					}
				}
				else
				{
					for(var j=0; j < result.length; j++)
					{
						_vm.graphqlresults[i].push(result[j]);
					}
				}
				
				var owl = $($(e.target).parents('.tab-contents')[0]).find('.owl-carousel');
				//console.log($($(e.target).parents('.tab-contents')[0]));
				//console.log(owl);
				//$(owl).slick('removeSlide', null, null, true);
				
				var maxcount = 10;
				getContent(result, _vm, "tabbedcontenttile", false, owl, true, 10, valueSelected, date);
				
				setTimeout(function(){
				//console.log('rebind');
				$('.fav-btn').off('click').on('click', function(){
					setLike(this);
					setClicked(this);
				});
				$('.calendar-btn').off('click').on('click', function(){
					setClicked(this);
					//addEventToCalendar("subject", "description", "location", "12/1/2020", "12/6/2020");
					addEventToCalendar(this);
				});
				$('share-btn').off('click').on('click', function(){
					setClicked(this);
				});
				}, 1);
				//}, 300);
				
				break;
				}
			}
		  };

			$('.jenSubmit.searchSubmit').on('click', function(e){
			//console.log('TimePicker form submit');
			//var reservedDate = 	$('#'+vm.uid+'_tabs-' + index + '_jenSearchPicker #selectDate').val();
			var reservedDate = 	$($(this).parents('.jenSearchPicker')[0]).find('#selectDate').val();
			var type = $($(this).parents('.jenSearchPicker')[0]).find('#searchType option:selected').text();
			//console.log('TimePicker form submit, ReservedDate: '+ reservedDate + ',Reserved Type: ' + type );
			e.preventDefault();
		
			var tab = $($(e.target).parents('.tab-contents')[0]);
			var date = $($(e.target).parents('.tab-contents')[0]).find('.jenSearchPicker #selectDate').val();
			var optionSelected = $($(e.target).parents('.tab-contents')[0]).find('#searchType option:selected');
			var valueSelected  = optionSelected.val();
			var condition = '';
			if (tab.attr('data-datatype').toLowerCase() == 'hjevent' || tab.attr('data-datatype').toLowerCase() == 'hjdealstay' || tab.attr('data-datatype').toLowerCase() == 'hjdealeat' || tab.attr('data-datatype').toLowerCase() == 'hjdealspace' || tab.attr('data-datatype').toLowerCase() == 'hjdealwedding')
			{
				searchMoreContentByType(null, tab.attr('data-datatype'), vm, updateResults, -1, null, vm.$jss.property(), false, vm.$jss.travellertype(), null, condition, e);
			}
			else
				searchMoreContentByType(null, tab.attr('data-datatype'), vm, updateResults, 10, null, vm.$jss.property(), false, vm.$jss.travellertype(), null, condition, e);
		});		
		}
	
    $(document).ready(function() {
		console.log('update document raedy');
		$('.fav-btn').on('click', function(){
			setLike(this);
			setClicked(this);
		});
		$('.calendar-btn').on('click', function(){
			setClicked(this);
			//addEventToCalendar("subject", "description", "location", "12/1/2020", "12/6/2020");
			addEventToCalendar(this);
		});
		$('share-btn').on('click', function(){
			setClicked(this);
		});
		$('#' + vm.uid + ' .book').on('click', function(e) {
			e.preventDefault();
			var language = 'en'; 
			if ( vm.$jss.routeData().itemLanguage == 'zh-CN' || vm.$jss.routeData().itemLanguage == 'zh'){
				language = 'cn'; 
			}else{
				language = vm.$jss.routeData().itemLanguage.substring(vm.$jss.routeData().itemLanguage.length-2).toLowerCase()			
			} 
			var key = vm.$jss.hjconfig().encryption_key;
			var iv = vm.$jss.hjconfig().encryption_iv;
			var propertycode = "";
			var propertycityalias = "";
			var propertyhotelalias = "";
			if ($(this).attr('data-bookingurl'))
			{
				propertycode = $(this).attr('data-bookingurl').split('|')[2];
				propertycityalias = $(this).attr('data-bookingurl').split('|')[0];
				propertyhotelalias = $(this).attr('data-bookingurl').split('|')[1];
			}
			var book_url = vm.$jss.hjconfig().book_url;
			if (propertycode != '' && propertycityalias != '' && propertyhotelalias != '')
			{
				bookhotel(key, iv, book_url, propertycode, propertycityalias, propertyhotelalias, null, null, null, null, 'None', '', $(this).attr('data-roomcode'), '', language);
			}

		});
	});
	
	if (vm.isproperty('haspropertyfilter') != '1') {
		$('#'  + vm.uid + ' .filter_wrapper').css('display', 'none');
	}
	else
	{
		$('#'  + vm.uid + ' .filter_wrapper').css('display', 'block');
	}

  },
};
</script>

