import { render, staticRenderFns } from "./HJContainer.vue?vue&type=template&id=dac20b06&"
import script from "./HJContainer.vue?vue&type=script&lang=js&"
export * from "./HJContainer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "HJContainer.vue"
export default component.exports