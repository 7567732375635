import axios from 'axios';
import ApolloClient from 'apollo-boost';
import gql from 'graphql-tag';
import { enableExperimentalFragmentVariables } from 'graphql-tag';


//import graphqlToObject from '.\graphqlToObject.js';
//import 'babel-polyfill';
import config from './temp/config';

export function getpricebyid(propertyid, hotels, roomtypecode, prices, currency, travellertype) {
	if (hotels != null && hotels.children && hotels.children.length > 0)
	{
		var hotel = hotels.children.find(x=>x.id.toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim() === propertyid.toLowerCase().replace(/-/g, "").replace(/{/g, "").replace(/}/g, "").trim());
		if (hotel != undefined && hotel != null)
		{
			return getprice(hotel['hotelcode'].value, roomtypecode, prices, currency, travellertype);
		}		
	}
	return 0;
}

export function getprice(hotelcode, roomtypecode, prices, currency, travellertype) {
  if (travellertype == undefined || travellertype.toLowerCase() == 'other' || travellertype == '') {
    travellertype = 'Other';
  }

  if (hotelcode != undefined && hotelcode != null && hotelcode.value && hotelcode.value != '')
	  hotelcode = hotelcode.value;
  var sourcerate = 1;
  var targetrate = 1;
  // get target currency rate
  if (prices.currencies != undefined && prices.currencies != null && prices.currencies.length > 0) {
    for (var h = 0; h < prices.currencies.length; h++) {
      if (prices.currencies[h].code.toLowerCase() == currency.toLowerCase()) {
        if (prices.currencies[h].exchangerate != null) {
          if (prices.currencies[h].exchangerate > 0) {
            targetrate = prices.currencies[h].exchangerate;
            break;
          }
        }
      }
    }

    if (prices != undefined && prices != null) {
      for (var i = 0; i < prices.hotels.length; i++) {
        if (prices.hotels[i].code.toLowerCase() == hotelcode.toLowerCase()) {
          for (var h = 0; h < prices.currencies.length; h++) {
            if (
              prices.currencies[h].code.toLowerCase() == prices.hotels[i].currency.toLowerCase()
            ) {
              if (prices.currencies[h].exchangerate != null) {
                if (prices.currencies[h].exchangerate > 0) {
                  sourcerate = prices.currencies[h].exchangerate;
                  break;
                }
              }
            }
          }
          for (var j = 0; j < prices.hotels[i].rooms.length; j++) {
            if (prices.hotels[i].rooms[j].name.toLowerCase() === roomtypecode.toLowerCase() ||
			(prices.hotels[i].rooms[j].name.length >= 2 && roomtypecode.length >= 2 && prices.hotels[i].rooms[j].name.toLowerCase().substring(0,2) === roomtypecode.toLowerCase().substring(0,2))) {
              for (var k = 0; k < prices.hotels[i].rooms[j].prices.length; k++) {
                if (
                  prices.hotels[i].rooms[j].prices[k]['travellertype']
                    .toLowerCase()
                    .replace(/traveller/g, '')
                    .trim() ==
                  travellertype
                    .toLowerCase()
                    .replace(/traveller/g, '')
                    .trim()
                ) {
					if (prices.hotels[i].rooms[j].prices[k].price > 0)
					{
					  return Math.round(
						(prices.hotels[i].rooms[j].prices[k].price / sourcerate) * targetrate
					  );
					}
                }
              }
              //break; //	loop until get price
            }
          }
          break;
        }
      }
    }
  }
  return 0;
}


export function getminprice(hotelcode, prices, currency, travellertype, hotels) {
  if (travellertype == undefined || travellertype.toLowerCase() == 'other' || travellertype == '') {
    travellertype = 'Other';
  }

  if (hotelcode != undefined && hotelcode != null && hotelcode.value && hotelcode.value != '')
	  hotelcode = hotelcode.value;

  var minprices = [];
  var roomcode = "";
  var price = 0;
  var sourcerate = 1;
  var targetrate = 1;
  
  // get target currency rate
  if (prices != null && prices.currencies != undefined && prices.currencies != null && prices.currencies.length > 0) {
    for (var h = 0; h < prices.currencies.length; h++) {
      if (prices.currencies[h].code.toLowerCase() == currency.toLowerCase()) {
        if (prices.currencies[h].exchangerate != null) {
          if (prices.currencies[h].exchangerate > 0) {
            targetrate = prices.currencies[h].exchangerate;
            break;
          }
        }
      }
    }

    if (prices != undefined && prices != null) {
		//	for price.hotels
      for (var i = 0; i < prices.hotels.length; i++) {
        if (hotelcode == null || prices.hotels[i].code.toLowerCase() == hotelcode.toLowerCase()) {
          for (var h = 0; h < prices.currencies.length; h++) {
            if (
              prices.currencies[h].code.toLowerCase() == prices.hotels[i].currency.toLowerCase()
            ) {
              if (prices.currencies[h].exchangerate != null) {
                if (prices.currencies[h].exchangerate > 0) {
                  sourcerate = prices.currencies[h].exchangerate;
                  break;
                }
              }
            }
          }
          for (var j = 0; j < prices.hotels[i].rooms.length; j++) {
            for (var k = 0; k < prices.hotels[i].rooms[j].prices.length; k++) {
              if (
                prices.hotels[i].rooms[j].prices[k].price != undefined &&
                prices.hotels[i].rooms[j].prices[k].price != null
              ) {
                if (
                  prices.hotels[i].rooms[j].prices[k]['travellertype']
                    .toLowerCase()
                    .replace(/traveller/g, '')
                    .trim() ==
                  travellertype
                    .toLowerCase()
                    .replace(/traveller/g, '')
                    .trim()
                ) {
                  if (price == 0) {
                    price = prices.hotels[i].rooms[j].prices[k].price;
					roomcode = prices.hotels[i].rooms[j].name.length >= 2 ?  prices.hotels[i].rooms[j].name.substring(0,2) : prices.hotels[i].rooms[j].name;
                  } else {
                    if (prices.hotels[i].rooms[j].prices[k].price > 0)
					{
						if (prices.hotels[i].rooms[j].prices[k].price < price)
							roomcode = prices.hotels[i].rooms[j].name.length >= 2 ?  prices.hotels[i].rooms[j].name.substring(0,2) : prices.hotels[i].rooms[j].name;
						price = Math.min(price, prices.hotels[i].rooms[j].prices[k].price);
					}
                  }
                }
              }
            }
          }
		  if (hotelcode != null)
			break;
		  else
		  {
			  var hotel = null;
			  if (hotels != null)
			  {
				for(var j=0; j < hotels.length; j++)
				{
					if (hotels[j]['hotelcode'].value.toLowerCase() === prices.hotels[i].code.toLowerCase())
					{
						hotel = hotels[j];
						break;
					}
				}
			  }
			  minprices.push({id:hotel != null ? hotel.id : null, hotelcode:prices.hotels[i].code.toLowerCase(), roomcode:roomcode, price:Math.round((price / sourcerate) * targetrate)});
			  price = 0;
			  roomcode = "";
		  }
        }
      }	//	end: for prices.hotels
      if (hotelcode != null && price > 0) {
		//console.log(Math.round((price / sourcerate) * targetrate));
        return Math.round((price / sourcerate) * targetrate);
      }
    }
  }
  if (hotelcode != null)
	return 0;
  else
  {
	if (hotels != undefined)
	{
		if (hotels != null && hotels.length > 0)
		{
			for (var i=0; i < minprices.length; i++)
			{
				for (var j=0; j < hotels.length; j++)
				{
					if (hotels[j].hotelcode.value.toLowerCase() === minprices[i].hotelcode.toLowerCase())
					{
						minprices[i]['id'] = hotels[j].id;
						break;
					}
				}
			}
		}
		return minprices;
	}
	else
		return minprices;
  }
}

export function getCurrencyPrice(
  price,
  hotelcode,
  prices,
  sourcecurrency,
  currency,
  travellertype
) {
  if (hotelcode != undefined && hotelcode != null && hotelcode.value && hotelcode.value != '')
	  hotelcode = hotelcode.value;

  if (travellertype == undefined || travellertype.toLowerCase() == 'other' || travellertype == '') {
    travellertype = 'Other';
  }

  var sourcerate = 1;
  var targetrate = 1;
  // get target currency rate
  if (prices.currencies != undefined && prices.currencies != null && prices.currencies.length > 0) {
    for (var h = 0; h < prices.currencies.length; h++) {
      if (prices.currencies[h].code.toLowerCase() == currency.toLowerCase()) {
        if (prices.currencies[h].exchangerate != null) {
          if (prices.currencies[h].exchangerate > 0) {
            targetrate = prices.currencies[h].exchangerate;
            break;
          }
        }
      }
    }

    if (sourcecurrency != undefined && sourcecurrency != null && sourcecurrency != '') {
      for (var h = 0; h < prices.currencies.length; h++) {
        if (prices.currencies[h].code.toLowerCase() == sourcecurrency.toLowerCase()) {
          if (prices.currencies[h].exchangerate != null) {
            if (prices.currencies[h].exchangerate > 0) {
              sourcerate = prices.currencies[h].exchangerate;
              break;
            }
          }
        }
      }
    } else {
      if (prices != undefined && prices != null) {
        for (var i = 0; i < prices.hotels.length; i++) {
          if (prices.hotels[i].code.toLowerCase() == hotelcode.toLowerCase()) {
            for (var h = 0; h < prices.currencies.length; h++) {
              if (
                prices.currencies[h].code.toLowerCase() == prices.hotels[i].currency.toLowerCase()
              ) {
                if (prices.currencies[h].exchangerate != null) {
                  if (prices.currencies[h].exchangerate > 0) {
                    sourcerate = prices.currencies[h].exchangerate;
                    break;
                  }
                }
              }
            }
            break;
          }
        }
      }
    }
    if (price > 0) {
      return Math.round((price / sourcerate) * targetrate);
    }
  }
  return 0;
}
export function getHotelCurrencyPrice(price, hotelcode, prices, currency, travellertype) {
  if (travellertype == undefined || travellertype.toLowerCase() == 'other' || travellertype == '') {
    travellertype = 'Other';
  }

  if (hotelcode != undefined && hotelcode != null && hotelcode.value && hotelcode.value != '')
	  hotelcode = hotelcode.value;

  var sourcerate = 1;
  var targetrate = 1;
  // get target currency rate
  if (prices.currencies != undefined && prices.currencies != null && prices.currencies.length > 0) {
    for (var h = 0; h < prices.currencies.length; h++) {
      if (prices.currencies[h].code.toLowerCase() == currency.toLowerCase()) {
        if (prices.currencies[h].exchangerate != null) {
          if (prices.currencies[h].exchangerate > 0) {
            targetrate = prices.currencies[h].exchangerate;
            break;
          }
        }
      }
    }

    if (prices != undefined && prices != null) {
      for (var i = 0; i < prices.hotels.length; i++) {
        if (prices.hotels[i].code.toLowerCase() == hotelcode.toLowerCase()) {
          for (var h = 0; h < prices.currencies.length; h++) {
            if (
              prices.currencies[h].code.toLowerCase() == prices.hotels[i].currency.toLowerCase()
            ) {
              if (prices.currencies[h].exchangerate != null) {
                if (prices.currencies[h].exchangerate > 0) {
                  sourcerate = prices.currencies[h].exchangerate;
                  break;
                }
              }
            }
          }
          break;
        }
      }
      if (price > 0) {
        // console.log('targetrate: '+targetrate + ' sourcerate: '+sourcerate + ' price: '+price)
        // console.log(Math.round((price * targetrate)))
        return Math.round((price));
		
      }
    }
  }
  return 0;
}
