<template>
  <div :id="this.uid" class="HJLegacy">
	<template v-if="legacycontent && legacycontent.html" >
		<div v-html="legacycontent.html"></div>
	</template>
  </div>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import { Image } from '@sitecore-jss/sitecore-jss-vue';
import axios from 'axios';
import { dataFetcher } from '../dataFetcher.js';
import { layoutFetcher, layoutFetchAction } from '../layoutFetcher.js';
import { graphqlToObject, getObjectById, graphqlFetcher } from '../graphqlFetcher.js';
//import $ from 'jquery';
import ApolloClient from 'apollo-boost';
import gql from 'graphql-tag';
import { uuid } from 'vue-uuid';
import {
  enableExperimentalFragmentVariables,
  disableExperimentalFragmentVariables,
} from 'graphql-tag';
import Vue from 'vue';
import VueMeta from 'vue-meta';
import { trackingApi } from '@sitecore-jss/sitecore-jss-tracking';
import config from '../temp/config';

export default {
  name: 'HJLegacy',
  components: {
    ScText: Text,
  },
  props: {
    fields: {
      type: Object,
      default: function() {},
    },
    currentcontext: {
      type: Object,
      default: null,
    },
    uid: {
      type: String,
      default: function() {
        return uuid.v1();
      },
    },
  },
  data() {
    return {
      loadingQueriesCount: 0,
      error: null,
      legacycontent: {},
    };
  },
  computed: {
    datasource: function() {
      return this.fields.data && this.fields.data.datasource;
    },
    contextItem: function() {
      return this.fields.data && this.fields.data.contextItem;
    },
  },
  mounted: function() {
    // ...

    let vm = this;

    setTimeout(function() {
      var from = document.referrer;
    }, 500);
    vm.legacycontent = {};
    if (vm != null) {
      vm.legacycontent.html = vm
        .isproperty('Html')
        .replace(/,-/g, '-')
        .replace(/,_/g, '_')
        .replace(/&#58;/g, ':')
        .replace(/.jpg/g, '.ashx')
        .replace(/\/uploadedImages\//g, '/-/media/project/hoteljen/legacy/uploadedImages/')
        .replace(/\/uploadedFiles\//g, '/-/media/project/hoteljen/legacy/uploadedFiles/')
		.replace(/(<|%3C)nav class="breadcrumbs[\s\S]*?(>|%3E)[\s\S]*?(<|%3C)(\/|%2F)nav[\s\S]*?(>|%3E)/g, "");
      vm.legacycontent.linktags = vm.isproperty('LinkTags');
      vm.legacycontent.scripts = vm.isproperty('Scripts');

      const script = document.createElement('script');
      script.src = `/layouts/system/VisitorIdentification.js`;
      script.type = 'text/javascript';
      document.getElementsByTagName('head')[0].appendChild(script);

      var result = vm.legacycontent.scripts.match(
        /(<|%3C)script[\s\S]*?(>|%3E)[\s\S]*?(<|%3C)(\/|%2F)script[\s\S]*?(>|%3E)/g
      );
      for (var i = 0; i < result.length; i++) {
        $('head').append(result[i].replace(/https/g, 'http').replace(/&#58;/g, ':'));
      }
      result = vm.legacycontent.linktags.match(/(<|%3C)link[\s\S]*?(>|%3E)/g);
      for (var i = 0; i < result.length; i++) {
        $('head').append(result[i].replace(/https/g, 'http').replace(/&#58;/g, ':'));
      }
    }
  },
  updated() {},
  methods: {
    isproperty: function(name) {
      if (this.fields != null && this.fields[name] != undefined) {
        if (this.fields[name].value) return this.fields[name].value;
        else return this.fields[name];
      } else {
        if (this.fields.data.datasource && this.fields.data.datasource.fields) {
          for (var i = 0; i < this.fields.data.datasource.fields.length; i++) {
            if (this.fields.data.datasource.fields[i].name == name) {
              //console.log(this.fields.data.datasource.fields[i].value);
              if (
                this.fields.data.datasource.fields[i].targetItems &&
                this.fields.data.datasource.fields[i].targetItems.length > 0
              ) {
                if (this.fields.data.datasource.fields[i].targetItems[0].textField)
                  return this.fields.data.datasource.fields[i].targetItems[0].textField.value;
                else if (this.fields.data.datasource.fields[i].targetItems[0].title)
                  return this.fields.data.datasource.fields[i].targetItems[0].textField.title;
                else return '';
              } else {
                return this.fields.data.datasource.fields[i].value;
              }
            }
          }
        }
      }
      return null;
    },
  },
};
</script>
