<template>
  <div :id="this.uid" class="HJContentBlockListIntegrated">
  <div>
  </div>
</template>

<style>
</style>
<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import { Image } from '@sitecore-jss/sitecore-jss-vue';
import axios from 'axios';
import { dataFetcher } from '../dataFetcher.js';
import { layoutFetcher, layoutFetchAction } from '../layoutFetcher.js';
import { graphqlRemoveReserved, graphqlFetcher, graphqlFetcherTestSSR } from '../graphqlFetcher.js';
import { loadmore } from '../HJContentBlockList.js';
// for importing external libraries
//import $ from 'jquery1';
// END: for importing external libraries
import ApolloClient from 'apollo-boost';
import gql from 'graphql-tag';
import { uuid } from 'vue-uuid';
import {
  enableExperimentalFragmentVariables,
  disableExperimentalFragmentVariables,
} from 'graphql-tag';
import HJForm from '../components/HJForm.vue';
import Vue from 'vue';
import VueMeta from 'vue-meta';
import { trackingApi } from '@sitecore-jss/sitecore-jss-tracking';
import config from '../temp/config';
//if (global.process.browser) {var TimelineLite = require('gsap');}
//if (typeof window !== 'undefined'){var TimelineLite = require('gsap/TimelineLite');}
//if (typeof window !== 'undefined'){var VueperSlides = require('vueperslides');}
//if (typeof window !== 'undefined'){var VueperSlide = require('vueperslides');}
//import {VueperSlides, VueperSlide} from 'vueperslides';
//import VueperSlides from 'vueperslides/components/vueperslides/vueperslides'
//import VueperSlide from 'vueperslides/components/vueperslides/vueperslide'
import LoadScript from 'vue-plugin-load-script';
Vue.use(LoadScript);

export default {
  name: 'HJContentBlockListIntegrated',
  components: {
    ScText: Text,
    HJForm,
  },
  props: {
    fields: {
      type: Object,
      //default: function(){},
    },
    rendering: {
      type: Object,
    },
    uid: {
      type: String,
      default: () => uuid.v1(),
    },
    lastitemid: {
      type: String,
      default: null,
    },
    graphqlresults: {
      type: Object,
      default: null,
    },
  },
  data: function() {
    return {
      loadingQueriesCount: 0,
      error: null,
    };
  },
  computed: {
    datasource: function() {
      return this.fields.data && this.fields.data.datasource;
    },
    contextItem: function() {
      return this.fields.data && this.fields.data.contextItem;
    },
    hotels: function() {
      if (this.$root.hotels) return this.$root.hotels;
      else {
        return {};
      }
    },
    allcontent: function() {
      if (this.$root.allcontent) return this.$root.allcontent;
      else {
        return {};
      }
    },
    constants: function() {
      if (this.$root.constants) return this.$root.constants;
      else {
        return {};
      }
    },
  },
  mounted: function() {
    let vm = this;
    if (this.fields.data && this.fields.data.datasource) {
      graphqlRemoveReserved(this.fields.data.datasource);
    }
  },
};
</script>
